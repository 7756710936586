/* eslint-disable */
import { useEffect, useState } from 'react';
import { getDateOfMonth, getDateOfWeek, getMonthOfYear, getYear } from '../../../../utils/Times';

import envConstants from '../../../../config/key';
import { useParams } from 'react-router-dom';
import IMAGES_EVENT11 from '../images';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import { statusConvert } from '../../../_index/helper';
import  Sponsor from '../components/Sponsor';

const TiltlePage = ({
    data = {},
    guest,
    guestFullname = '',
    salutation = '',
    guestTitle = '',
    eventConfig,
    show
}) => {
    const dataCopy = {
        invitation: data.invitation,
        titleEvent: data.titleEvent,
        nameEvent: data.nameEvent,
        logo: data.logo && data.logo[0],
        placeHold: data.placeHold,
        addressHold: data.addressHold,
        date: data.dateHold,
        time: data.timeHold,
        linkGgMap: data?.linkGgMap ?? '',
        sponsors: data?.sponsors ?? [],
        dress_1: data?.dress_1 ? data?.dress_1 : '',
        dress_2: data?.dress_2 ? data?.dress_2 : '',
    };
    const { url_share, url_guest } = useParams();
    
    const [pathImgLogo, setPathImgLogo] = useState('');

    useEffect(() => {
        if (
            !/^blob:/.test(dataCopy?.logo) &&
            !/^data:image/.test(dataCopy?.logo) &&
            !/^\/static\/media/.test(dataCopy?.logo) &&
            dataCopy?.logo !== undefined &&
            dataCopy?.logo !== '' &&
            dataCopy?.logo !== null &&
            dataCopy?.logo !== false
        ) {
            setPathImgLogo(envConstants.SERVER_URL + dataCopy?.logo);
        } else if (dataCopy?.logo) {
            setPathImgLogo(dataCopy?.logo);
        } else {
            setPathImgLogo('');
        }
    }, [dataCopy?.logo]);

    return (
        <>
            <div className="relative mx-auto  min-h-screen max-w-screen-xl w-full ">
                <div
                    className="relative mx-auto flex flex-col items-center  min-h-screen w-full  bg-contain bg-no-repeat bg-white "
                    style={{ backgroundImage: `url(${IMAGES_EVENT11.BG_TOP})` }}
                >
                    <div className="mt-10 md:mt-20">
                        {pathImgLogo && (
                            <img
                                className="mx-auto w-[150px] md:w-[200px]"
                                src={pathImgLogo}
                                loading="lazy"
                                alt="logo"
                            />
                        )}
                    </div>
                    <div className={'mt-6 text-center '}>
                        <span className="text-6xl font-greatVibes font-medium  text-[#ab7628]">
                            {dataCopy?.titleEvent}
                        </span>
                    </div>
                    <div className="mx-auto my-2 ">
                        <div className="w-full mx-auto  mb-1">
                            <p className="text-lg text-gray-700 font-semibold text-center uppercase font-Comfortaa">
                                {salutation}
                            </p>
                        </div>
                        <div className="font-Comfortaa mx-auto mb-2 mt-1 text-center   text-[#ab7628]">
                            <p className=" break-words text-lg md:text-xl font-semibold font-greatVibes leading-tight">
                                {guestTitle || '................................................'}
                            </p>
                            <p className="text-xl md:text-2xl font-semibold uppercase break-words ">
                                {guestFullname || '...............................'}
                            </p>
                        </div>
                    </div>
                    {/* loi moi */}
                    <div
                        className={
                            'text-center font-Comfortaa text-gray-700 font-semibold uppercase mt-2 '
                        }
                    >
                        <span className="text-lg md:text-2xl ">{dataCopy?.invitation}</span>
                    </div>
                    <div className="flex justify-center mt-2">
                        <div
                            className={
                                'text-center  font-notoSerif italic text-3xl md:text-5xl text-[#ab7628]  '
                            }
                        >
                            {dataCopy?.nameEvent}
                        </div>
                    </div>
                    <div className="flex items-center gap-[20px] justify-center">
                        {url_guest && url_share && eventConfig?.auto_response && (
                            <a
                                href={'/' + url_share + '/' + url_guest + '/xac-nhan'}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <button className="mt-2 flex items-center rounded bg-red-500 px-4 py-1.5 text-[calc(0.5rem_+_0.7vh)] font-semibold text-white transition hover:bg-red-700 md:mt-5">
                                    {/* <FaEnvelopeOpenText className="mr-2" /> Xác nhận tham dự */}
                                    {statusConvert(guest?.status)}
                                </button>
                            </a>
                        )}

                        {!url_guest && url_share && eventConfig?.auto_join && (
                            <button
                                className="mt-2 flex items-center rounded bg-red-500 px-4 py-1.5 text-[calc(0.5rem_+_0.7vh)] font-semibold text-white transition-all hover:bg-red-700 md:mt-5"
                                data-bs-toggle="modal"
                                data-bs-target="#formModal"
                            >
                                <FaEnvelopeOpenText className="mr-2" /> Đăng kí tham dự
                            </button>
                        )}
                    </div>

                    <div className="w-full text-center mt-4">
                        {(dataCopy?.time || dataCopy?.date) && (
                            <p className="w-full font-Comfortaa font-semibold text-gray-700 text-xl md:text-2xl text-center">
                                Thời gian
                            </p>
                        )}
                        <p className="mt-1 w-full text-sm md:text-lg  text-gray-700 font-medium italic text-center">
                            {dataCopy?.time && dataCopy?.time + ', '}
                            {dataCopy?.date &&
                                getDateOfWeek(dataCopy?.date) +
                                    ' ngày ' +
                                    getDateOfMonth(dataCopy?.date) +
                                    ' tháng ' +
                                    getMonthOfYear(dataCopy?.date) +
                                    ' năm ' +
                                    getYear(dataCopy?.date)}
                        </p>
                    </div>
                    <div className="w-full text-center mt-4">
                        {(dataCopy?.placeHold || dataCopy?.addressHold) && (
                            <p className="w-full font-Comfortaa font-semibold text-gray-700 text-xl md:text-2xl text-center">
                                Địa điểm
                            </p>
                        )}
                        <p className="mt-1 w-full text-lg md:text-xl  text-gray-700 font-medium italic text-center">
                            {dataCopy?.placeHold}
                        </p>
                        <p className="w-full text-sm md:text-lg  text-gray-700 font-medium italic text-center">
                            {dataCopy?.addressHold}{' '}
                            {(dataCopy?.addressHold || dataCopy?.linkGgMap) && (
                                <a
                                    href={`${
                                        dataCopy?.linkGgMap
                                            ? dataCopy?.linkGgMap
                                            : `${
                                                  'https://www.google.com/maps/search/?api=1&query=' +
                                                  dataCopy?.addressHold
                                              }`
                                    }`}
                                    rel="noreferrer"
                                    target="_blank"
                                    className={
                                        'ml-1 border-[#ab7628] text-[17px] text-[#ab7628] hover:border-b '
                                    }
                                    title="Xem trên bản đồ"
                                >
                                    <i className="fas fa-map-marker-alt"></i>
                                </a>
                            )}
                        </p>
                    </div>
                    {dataCopy?.dress_1 || dataCopy?.dress_2 ? (
                        <div className="w-full text-center mt-10">
                            <p className="w-full font-Comfortaa font-semibold text-gray-700  text-lg md:text-xl text-center">
                                Trang phục
                            </p>
                            {dataCopy?.dress_1 ? (
                                <p className="w-full text-center text-[16px] font-medium mt-2  text-gray-700">
                                    {dataCopy?.dress_1}
                                </p>
                            ) : null}
                            {dataCopy?.dress_2 ? (
                                <p className="w-full text-center text-[16px] font-medium mt-2  text-gray-700">
                                    {dataCopy?.dress_2}
                                </p>
                            ) : null}
                        </div>
                    ) : null}

                    <div className="mx-auto mb-5 mt-5 text-center text-[15px] md:text-[16px]">
                        {(data?.phoneSupporter || data?.nameSupporter) && (
                            <>
                                <p className="text-lg font-semibold text-gray-700 md:text-xl">
                                    Liên hệ hỗ trợ
                                </p>
                                <div className="flex">
                                    <p
                                        className={
                                            'text-right font-medium mt-2 w-1/2 text-gray-700'
                                        }
                                    >
                                        <i className="mr-1 text-lg fas fa-user-alt text-[#ab7628] md:text-xl "></i>{' '}
                                        {data?.nameSupporter && data?.nameSupporter}
                                    </p>

                                    <a href={`tel:${data?.phoneSupporter}`} className="w-1/2 ">
                                        <p
                                            className={
                                                'text-left flex font-medium mt-2.5  text-gray-700 hover:text-gray-900 '
                                            }
                                        >
                                            <i className="fas fa-phone-alt ml-5 mr-1.5 text-lg text-[#ab7628] md:text-xl"></i>
                                            {data?.phoneSupporter && data?.phoneSupporter}
                                        </p>
                                    </a>
                                </div>
                            </>
                        )}
                    </div>
                    {(show && data?.sponsors && data?.sponsors?.length > 0 ? (
                        <Sponsor data={data} />
                    ) : null) }
                </div>
            </div>
        </>
    );
};

export default TiltlePage;
