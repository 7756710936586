const LANGUAGE = {
    Vietnamese: {
        NAVBAR_GENERAL: 'Thông tin chung',
        NAVBAR_CONTENT: 'Nội dung',
        NAVBAR_CONTACT: 'Liên hệ',
        SALUTATION: 'Trân trọng kính mời',
        SALUTATION_TIME: 'đón tiếp từ',
        INVITATION: 'tới tham dự',
        GROUP_NAME: 'Công ty TNHH liên doanh phần mềm AKB Software',
        NAME_EVENT: 'Lễ kỷ niệm 15 năm thành lập',
        PLACE_EVENT: 'Phòng hội thảo 3 - tầng 2 - Khách sạn công đoàn',
        ADDRESS_EVENT: 'Số 14, Trần Bình Trọng, Hoàn Kiếm, Hà Nội',
        MONTH: 'Tháng',
        DAY: 'Ngày',
        HOUR: 'Giờ',
        MINUTE: 'Phút',
        SECOND: 'Giây',
        TITLE_MAP: 'Xem trên bản đồ',
        TIMELINE: 'Nội dung chương trình',
        CONTACT: 'Liên hệ hỗ trợ',
        GREETING: 'Sự hiện diện của quý khách là niềm vinh dự với chúng tôi!',
        REMIND: 'Quý khách vui lòng mang theo thiệp để check-in tham dự',
        TITLE_PAGE: 'title-page',
        TIMELINE_PAGE: 'timeline-page',
        SUPPORT_PAGE: 'support-page',
        VIEW_DETAIL: 'Xem chi tiết'
    },
    Japanese: {
        NAVBAR_GENERAL: 'イベントの情報',
        NAVBAR_CONTENT: 'プログラムの内容',
        NAVBAR_CONTACT: '問い合わせ',
        SALUTATION: 'ご参加いただけますようお願い申し上げます',
        SALUTATION_TIME: 'から受付開始',
        INVITATION: '出席',
        GROUP_NAME: 'エーケービーソフトウエア株式合弁会社',
        NAME_EVENT: '会社創立 15周年を祝う',
        PLACE_EVENT: 'コン・ドアイン　ホテル、2階、3ミーティング室',
        ADDRESS_EVENT: 'No 14, Tran Binh Trong, Hoan Kiem, Ha Noi',
        MONTH: '月',
        DAY: '日',
        HOUR: '時',
        MINUTE: '分',
        SECOND: '秒',
        TITLE_MAP: '地図で見る',
        TIMELINE: 'プログラムの内容',
        CONTACT: 'お問い合わせ',
        GREETING:
            'おいそがしいところ まことに恐縮ではございますが, ご出席くださいますようお願い申し上げます',
        REMIND: '当日、チェックインの際にカードをご持参ください',
        TITLE_PAGE: '題名-ページ',
        TIMELINE_PAGE: 'タイムライン-ページ',
        SUPPORT_PAGE: 'サポート-ページ',
        VIEW_DETAIL: '詳細を見る'
    },
};

export default LANGUAGE;
