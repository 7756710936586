/* eslint-disable */
import { useCallback, useEffect, useRef, useState } from 'react';

// import IMAGES_WEDDING from '../../resource/wedding/images/_index';

// import Templates from '../events/components/Templates';
import { useParams, useNavigate, Link } from 'react-router-dom';

import PAGE from '../../../../config/path.route';

import EventsService from '../../../events/Events.service';
import { MySubTemplate } from '../../../../resource/_index/sub.template';
import envConstants from '../../../../config/key';
import ToastNotify from '../../../../utils/Toast';
import { toBlob, toPng } from 'html-to-image';
import {
    FaChartPie,
    FaDownload,
    FaExternalLinkAlt,
    FaImages,
    FaInfoCircle,
    FaQrcode,
} from 'react-icons/fa';
import Loading from './components/Loading';
import ShortcutMenu from '../../components/ShortcutMenu';
import QrModal from './components/QrModal';
import Products from '../publish.news/products/Products';
import TimelineShortcutMenu from '../../components/TimelineShortcutMenu';
import QuestionTitle from '../publish.question/QuestionTitle';
import QuestionModal from '../publish.question/QuestionModal';
import Header from './components/Header';
import UserEventsService from '../../../user.events/User.events.service';
import Blank from './components/Blank';
import InputValidatePublic from '../pushish.config/component/InputValidatePublic';
import { useSelector } from 'react-redux';

export default function PublishSub() {
    const navigate = useNavigate();
    const { eventId } = useParams();
    const [loading, setLoading] = useState(false);
    const [event, setEvent] = useState();
    const refFrame = useRef(null);
    const qrRef = useRef(null);
    const [loadingDownloadBtn, setLoadingDownloadBtn] = useState();
    const [loadingCopyBtn, setLoadingCopyBtn] = useState();
    const [loadingQrBtn, setLoadingQrBtn] = useState();

    const [checkFullData, setCheckFullData] = useState();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const { loggedIn } = useSelector((state) => state.auth);

    useEffect(() => {
        setLoading(true);
        getData();
    }, []);

    const getData = async () => {
        await EventsService.getOneEventById(eventId)
            .then(({ data }) => {
                // console.log(data);
                setEvent(data);
            })
            .catch((error) => {
                console.log(error);
                const AuthorizeError = 404;
                if (error.status === AuthorizeError) {
                    navigate(PAGE.dashboard);
                }
                const ServerError = 500;
                if (error.status === ServerError) {
                    navigate(PAGE.dashboard);
                }
            });

        await UserEventsService.checkExport(eventId)
            .then(() => {
                setCheckFullData(true);
            })
            .catch(() => {
                setCheckFullData(false);
            });

        setLoading(false);
    };

    const makeUrlShare = useCallback((urlSlug, guestKey = undefined) => {
        let rawUrl =
            envConstants.CLIENT_URL.slice(0, envConstants.CLIENT_URL.lastIndexOf('/')) +
            PAGE.publish;
        if (urlSlug) {
            rawUrl = rawUrl.replace(':url_share', urlSlug);
            if (guestKey) {
                return rawUrl + '/' + guestKey;
            }
            return rawUrl;
        }
        return '';
    }, []);

    const onCopyImage = (e) => {
        if (refFrame.current === null) {
            ToastNotify.Error('Có lỗi xảy ra vui lòng thử lại sau ít phút!');
            return;
        }
        setLoadingCopyBtn(true);
        const oldHTML = e.currentTarget.innerHTML;

        async function convertFrameToImage() {
            return toBlob(refFrame.current, { cacheBust: false }).then(async (blob) => {
                if (navigator.clipboard && window.isSecureContext) {
                    navigator.clipboard
                        .write([
                            // eslint-disable-next-line no-undef
                            new ClipboardItem({
                                [blob.type]: blob,
                            }),
                        ])
                        .catch((error) => console.log(error))
                        .finally(() => {
                            e.target.innerHTML = oldHTML;
                            setLoadingCopyBtn(false);
                        });
                } else {
                    throw Error('Trình duyệt không hỗ trợ sao chép ảnh!');
                }
            });
        }

        setTimeout(() => {
            ToastNotify.Promise(
                convertFrameToImage(),
                'Đang chuẩn bị ảnh...',
                'Sao chép ảnh thành công'
            );
        }, 300);
    };
    const onDownloadTemplate = (e) => {
        setLoadingDownloadBtn(true);
        if (refFrame.current === null) {
            ToastNotify.Error('Có lỗi xảy ra vui lòng thử lại sau ít phút!');
            return;
        }
        const oldHTML = e.currentTarget.innerHTML;
        async function htmlToPng() {
            return toPng(refFrame.current, { cacheBust: false })
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.download = event?.name + '.png';
                    link.href = dataUrl;
                    link.click();
                })
                .finally(() => {
                    e.target.innerHTML = oldHTML;
                    setLoadingDownloadBtn(false);
                });
        }
        setTimeout(() => {
            ToastNotify.Promise(htmlToPng(), 'Đang chuẩn bị ảnh...', 'Tải ảnh thành công.');
        }, 300);
    };
    const onDownloadQr = (e) => {
        setLoadingQrBtn(true);
        if (qrRef.current === null) {
            ToastNotify.Error('Có lỗi xảy ra vui lòng thử lại sau ít phút!');
            return;
        }
        const oldHTML = e.currentTarget.innerHTML;
        async function htmlToPng() {
            return toPng(qrRef.current, { cacheBust: false })
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.download = event?.name + ' qr.png';
                    link.href = dataUrl;
                    link.click();
                })
                .finally(() => {
                    e.target.innerHTML = oldHTML;
                    setLoadingQrBtn(false);
                });
        }
        setTimeout(() => {
            ToastNotify.Promise(htmlToPng(), 'Đang chuẩn bị ảnh...', 'Tải ảnh thành công.');
        }, 300);
    };

    useEffect(() => {
        if (!loggedIn) {
            const beforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = true;
            };
            window.addEventListener('beforeunload', beforeUnload);
            return () => {
                window.removeEventListener('beforeunload', beforeUnload);
            };
        }
    }, []);

    return (
        <>
            <div className="flex flex-col items-center gap-[1.5rem] pb-[2rem]">
                <Header event={event} isExpired={event?.isExpired} />
                <div className=" w-full flex 2xl:justify-center justify-evenly 2xl:gap-[200px] mt-[80px]">
                    <div className="lg:flex hidden relative w-[250px]">
                        <TimelineShortcutMenu screen={'sub-event'} />
                    </div>
                    {loading ? (
                        <Loading />
                    ) : (
                        <div className="w-[100vw]  rounded-lg border-[1px] border-slate-200 bg-white-box shadow-lg lg:w-[48rem] flex flex-col">
                            <div className="flex w-full items-center justify-between border-b-[1px] border-slate-200 px-3 py-2">
                                <p className="text-lg font-bold text-black">
                                    Thiệp mời dạng ảnh
                                </p>
                            </div>
                            {checkFullData && (
                                <div className="w-full flex justify-start items-center gap-4 py-2 border-b border-gray-300 px-6">
                                    <>
                                        <button
                                            disabled={loadingDownloadBtn}
                                            className={`${
                                                loadingDownloadBtn
                                                    ? 'opacity-40'
                                                    : 'hover:bg-blue-700'
                                            } px-4 py-2 text-sm bg-blue-500 transition-all text-white rounded-md flex items-center gap-2`}
                                            onClick={onDownloadTemplate}
                                        >
                                            <FaDownload />
                                            Tải ảnh thiệp
                                        </button>

                                        <button
                                            disabled={loadingCopyBtn}
                                            className={`${
                                                loadingCopyBtn
                                                    ? 'opacity-40 text-white'
                                                    : 'hover:bg-blue-700 text-white'
                                            } px-4 py-2 text-sm bg-blue-500  transition-all  rounded-md flex items-center gap-2`}
                                            onClick={onCopyImage}
                                        >
                                            <FaImages />
                                            Sao chép ảnh thiệp
                                        </button>
                                        <button
                                            className="px-4 py-2 text-sm bg-blue-500 hover:bg-blue-700 transition-all text-white rounded-md flex items-center gap-2"
                                            data-bs-toggle={'modal'}
                                            data-bs-target={'#qrModal'}
                                        >
                                            <FaQrcode />
                                            Mã QR cho Website
                                        </button>
                                    </>
                                </div>
                            )}
                            <div className="w-full p-4 ">
                                {checkFullData ? (
                                    <div className="w-full lg:scale-50 xl:scale-[0.80] origin-top 2xl:scale-100">
                                        <MySubTemplate
                                            alias={event?.template?.alias}
                                            props={{
                                                data: event?.form_data,
                                                refFrame: refFrame,
                                                urlShare: makeUrlShare(event?.url_slug),
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <Blank />
                                )}
                            </div>
                            {/* <ShortcutMenu screen={'sub-event'} /> */}
                        </div>
                    )}
                    <div className="lg:flex hidden relative w-[250px]">
                        <Products />
                    </div>
                </div>
            </div>
            <QrModal
                url={makeUrlShare(event?.url_slug)}
                qrRef={qrRef}
                onDownloadQr={onDownloadQr}
                loading={loadingQrBtn}
            />
            <InputValidatePublic eventId={eventId} isOpen={isOpenModal} reload={true} />
        </>
    );
}
