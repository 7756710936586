import EventTemplate from '../../api/event.template.api';

const UserEventsService = {
    shareEventTemplate: async (id, data) => {
        const result = await EventTemplate.export(id, data);
        return result;
    },

    getEventTemplateBySlug: async (data) => {
        const result = await EventTemplate.findBySlug(data);
        return result;
    },
    deleteEventTemplate: async (id) => {
        const result = await EventTemplate.delete(id);
        return result;
    },
    checkExport: async (id) => {
        const result = await EventTemplate.checkExport(id);
        return result;
    },
};

export default UserEventsService;
