/* eslint-disable */
import { Link } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { removeAllSpace } from '../../../utils/Capitalize';
import LoadingButtonIcon from '../../../components/LoadingButton/LoadingButtonIcon';

const FormSignIn = ({
    formikLogin,
    changeMode,
    setMode,
    googleLogin,
    facebookLogin,
    isOpen,
    isLoading,
}) => {
    const focusInput = useRef(null);

    const [showPassword, setShowPassword] = useState(false);
    const toogleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const [emailInputStarted, setEmailInputStarted] = useState(false);
    const [passwordInputStarted, setPasswordInputStarted] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                focusInput.current.focus();
            }, 100);
        }
    }, [isOpen]);

    return (
        <div className="mx-auto md:mx-0 md:w-full lg:w-9/12 max-w-[700px] lg:min-w-[500px] form-login h-full">
            <div className="flex flex-col w-full p-5 bg-white max-md:rounded-b-xl md:rounded-r-xl md:p-10">
                <h2 className="mb-5 text-2xl font-bold text-left text-gray-800">Đăng nhập</h2>
                <form onSubmit={formikLogin.handleSubmit} className="w-full">
                    <div id="input" className="flex flex-col w-full my-5">
                        <label htmlFor="email" className="mb-2 text-left text-gray-700">
                            Email / Tên đăng nhập <span className="text-red-500">(*)</span>
                        </label>
                        <input
                            ref={focusInput}
                            type="text"
                            id="login-email"
                            name="email"
                            placeholder="Nhập email / tên đăng nhập"
                            className={`border-1 appearance-none rounded-lg px-4 py-3 text-black placeholder:text-gray-400 focus:shadow-lg focus:outline-none focus:ring-1 ${
                                formikLogin.errors.email && emailInputStarted
                                    ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                                    : 'border-gray-300 focus:border-blue-600 focus:ring-blue-600'
                            }`}
                            value={formikLogin.values.email}
                            onChange={(e) => formikLogin.handleChange(e)}
                            helperText={formikLogin.errors.email}
                        />
                        {emailInputStarted && formikLogin.errors.email ? (
                            typeof `${formikLogin.errors.email}` === 'string' ? (
                                <span
                                    className="mt-1 text-sm text-left text-red-500 text-[13px]"
                                    id="email-helper-error"
                                >
                                    {formikLogin.errors.email}
                                </span>
                            ) : (
                                formikLogin.errors.email
                            )
                        ) : null}
                    </div>
                    <div id="input" className="flex flex-col w-full my-5">
                        <label htmlFor="password" className="mb-2 text-left text-gray-700">
                            Mật khẩu <span className="text-red-500">(*)</span>
                        </label>
                        <div className="flex w-full">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="login-password"
                                name="password"
                                placeholder="Nhập mật khẩu"
                                className={`border-1 w-full appearance-none rounded-lg px-4 py-3 text-black placeholder:text-gray-400 focus:shadow-lg focus:outline-none focus:ring-1 ${
                                    formikLogin.errors.password && passwordInputStarted
                                        ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                                        : 'border-gray-300 focus:border-blue-600 focus:ring-blue-600'
                                }`}
                                value={formikLogin.values.password}
                                onChange={formikLogin.handleChange}
                                // onChange={(e) => {
                                //     if (!passwordInputStarted) {
                                //         setPasswordInputStarted(true)
                                //     }
                                //     formikLogin.setFieldValue('password', removeAllSpace(e.target.value))
                                // }
                                // }
                            />
                            <i
                                className={
                                    'text-black mt-2.5 -ml-9 text-lg cursor-pointer fas fa-eye' +
                                    (showPassword ? '' : '-slash')
                                }
                                id="toogle-password"
                                onClick={toogleShowPassword}
                            ></i>
                        </div>
                        {passwordInputStarted && (
                            <span
                                className="mt-1 text-sm text-left text-red-500"
                                id="password-helper-error"
                            >
                                {formikLogin.errors.password}
                            </span>
                        )}
                        <p
                            onClick={() => {
                                setMode('forgot-password');
                            }}
                            id="forgot-link"
                            className="text-end font-medium underline cursor-pointer text-main-500"
                        >
                            Quên mật khẩu?
                        </p>
                    </div>
                    <div className="flex flex-col w-full my-2">
                        <button
                            id="submit-btn"
                            type="submit"
                            disabled={isLoading}
                            onClick={() => {
                                setEmailInputStarted(true);
                                setPasswordInputStarted(true);
                            }}
                            className="w-full rounded-lg bg-gradient-to-r from-[#51ADF5] to-[#2196F3] py-4 text-white hover:from-[#2196F3] hover:to-[#51ADF5]  hover:shadow-lg "
                            // style={{
                            //     background:
                            //         'linear-gradient(131.27deg, #51ADF5 8.95%, #2196F3 85.94%)',
                            // }}
                        >
                            <div className="flex flex-row items-center justify-center">
                                {isLoading && <LoadingButtonIcon />}
                                <div className="font-semibold text-white capitalize">Đăng nhập</div>
                            </div>
                        </button>
                        <button
                            type="button"
                            disabled={isLoading}
                            onClick={() => googleLogin()}
                            className="mt-3 w-full rounded-lg bg-white py-3 sm:py-3.5 text-white hover:bg-gray-100 border border-gray-300 hover:shadow-md "
                        >
                            <div className="relative flex flex-row items-center justify-center">
                                <div className="font-semibold text-gray-600 ">
                                    Đăng nhập với Google
                                </div>
                                <div className="absolute sm:left-2 left-1">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 120 120"
                                        width="34px"
                                        height="34px"
                                        baseProfile="basic"
                                    >
                                        <path
                                            d="M107.145,55H100H87.569H60v18h27.569c-1.852,5.677-5.408,10.585-10.063,14.118 C72.642,90.809,66.578,93,60,93c-12.574,0-23.278-8.002-27.299-19.191C31.6,70.745,31,67.443,31,64 c0-3.839,0.746-7.505,2.101-10.858C37.399,42.505,47.823,35,60,35c7.365,0,14.083,2.75,19.198,7.273l13.699-13.21 C84.305,20.969,72.736,16,60,16c-18.422,0-34.419,10.377-42.466,25.605C14,48.291,12,55.912,12,64c0,7.882,1.9,15.32,5.267,21.882 C25.223,101.389,41.372,112,60,112c12.382,0,23.668-4.688,32.182-12.386C101.896,90.831,108,78.128,108,64 C108,60.922,107.699,57.917,107.145,55z"
                                            opacity=".35"
                                        />
                                        <path
                                            fill="#44bf00"
                                            d="M17.267,81.882C25.223,97.389,41.372,108,60,108c12.382,0,23.668-4.688,32.182-12.386L77.506,83.118 C72.642,86.809,66.577,89,60,89c-12.574,0-23.278-8.002-27.299-19.191L17.267,81.882z"
                                        />
                                        <path
                                            d="M77.506,83.118c-0.684,0.553-1.685,1.158-2.398,1.638l14.711,12.846 c0.807-0.641,1.6-1.298,2.363-1.988L77.506,83.118z"
                                            opacity=".35"
                                        />
                                        <path
                                            fill="#0075ff"
                                            d="M92.182,95.614C101.896,86.83,108,74.128,108,60c0-3.078-0.301-6.083-0.855-9H100H87.569H60v18 h27.569c-1.852,5.677-5.408,10.585-10.063,14.118L92.182,95.614z"
                                        />
                                        <path
                                            d="M32.701,69.809L17.267,81.882c0.486,0.948,1.004,1.877,1.551,2.787l15.3-11.576 C33.63,72.181,33.05,70.804,32.701,69.809z"
                                            opacity=".35"
                                        />
                                        <path
                                            fill="#ffc400"
                                            d="M17.267,81.882C13.9,75.32,12,67.882,12,60c0-8.088,2-15.709,5.534-22.395l15.568,11.537 C31.746,52.496,31,56.161,31,60c0,3.443,0.6,6.745,1.701,9.809L17.267,81.882z"
                                        />
                                        <path
                                            d="M17.534,37.605c-0.482,0.844-1.169,2.36-1.564,3.251l16.059,11.491 c0.299-1.095,0.653-2.167,1.072-3.205L17.534,37.605z"
                                            opacity=".35"
                                        />
                                        <path
                                            fill="#ff1200"
                                            d="M33.101,49.142C37.399,38.505,47.823,31,60,31c7.365,0,14.083,2.75,19.198,7.273l13.699-13.21 C84.305,16.969,72.736,12,60,12c-18.422,0-34.419,10.377-42.466,25.605L33.101,49.142z"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </button>

                        {/* <div className="flex justify-center items-center mt-6"> */}
                        {/* <div className="font-semibold text-center mr-3">
                                Bạn cũng có thể đăng nhập bằng
                            </div>
                            <div
                                className="rounded-lg p-1 bg-gradient-to-r from-[#51ADF5] to-[#2196F3] mr-4 hover:shadow-lg cursor-pointer hover:opacity-90"
                                onClick={() => googleLogin()}
                            >

                            </div> */}
                        {/* <FacebookLogin
                                appId={process.env.REACT_APP_FACEBOOK_AUTH_ID}
                                onFail={(error) => {
                                    console.log('Login FB Failed!', error);
                                }}
                                onSuccess={(response) => {
                                    facebookLogin(response);
                                }}
                                children={
                                    <div className="rounded-lg p-1 bg-gradient-to-r from-[#51ADF5] to-[#2196F3] hover:shadow-lg cursor-pointer hover:opacity-90">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 48 48"
                                            width="40px"
                                            height="40px"
                                        >
                                            <path
                                                fill="#3F51B5"
                                                d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                                            />
                                            <path
                                                fill="#FFF"
                                                d="M34.368,25H31v13h-5V25h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H35v4h-2.287C31.104,17,31,17.6,31,18.723V21h4L34.368,25z"
                                            />
                                        </svg>
                                    </div>
                                }
                            /> */}
                        {/* </div> */}

                        <div className="flex justify-center mt-5">
                            {/* <p
                                onClick={() => {
                                    setMode('forgot-password');
                                }}
                                id="forgot-link"
                                className="mr-1 font-medium underline cursor-pointer text-main-500"
                            >
                                Quên mật khẩu?
                            </p> */}
                            <p className="flex items-center">
                                Bạn chưa có tài khoản?
                                <p
                                    // to="#"
                                    id="register-link"
                                    className="ml-1 font-medium text-main-500 cursor-pointer"
                                    onClick={() => {
                                        setMode('register');
                                        changeMode();
                                    }}
                                >
                                    Đăng ký
                                </p>
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FormSignIn;
