/* eslint-disable */
import axios from '../../../api/axios';
import { checkIsExpired } from '../../events/Events.service';

export const eventAnalyze = async (id, params) => {
    let response = await axios({
        url: '/event/analysis/' + id,
        method: 'GET',
        params,
    });
    response.data.data = {
        ...response.data.data,
        template: response.data.data.template_info
    }
    const isExpired = checkIsExpired(response.data.data);    
    response.data.data.isExpired = isExpired;
    return response.data;
};

export const updateGuest = async (id, payload) => {
    const response = await axios({
        url: '/event-guest/' + id,
        method: 'PATCH',
        data: payload,
    });
    return response.data;
};

export const getChartUrlData = async (url, begin, end) => {
    const response = await axios({
        url: '/url/chart',
        method: 'GET',
        params: {
            url,
            begin,
            end,
        },
    });
    return response.data.data;
};
