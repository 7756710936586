/* eslint-disable */
import { useDispatch, useSelector } from 'react-redux'

import ModalLogin from '../../views/auth/ModalLogin'
import ModalLogout from '../../views/auth/ModalLogout'
import PAGE from '../../config/path.route'
import React from 'react'
import modalAction from '../../redux/modal/modal.action'
import modalSelector from '../../redux/modal/modal.selector'
import { useNavigate } from 'react-router-dom'
import ModalDelete from '../../views/user.events/components/ModalDelete'
import { useState, useEffect } from 'react';

const ModalManagement = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const modalLogin = useSelector(modalSelector.modalLogin)
    const modalSignUp = useSelector(modalSelector.modalSignUp)
    const modalLogout = useSelector(modalSelector.modalLogout)
    const modalDelete = useSelector(modalSelector.modalDelete)
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	useEffect(() => {
		const onScreenWidthChange = () => {
			setScreenWidth(window.innerWidth);
		}
		window.addEventListener('resize', onScreenWidthChange);
		return () => window.removeEventListener('resize', onScreenWidthChange);
	}, [])

    const closeModalLogin = () => {
        const pathname = window.location.pathname
        if (pathname !== PAGE.dashboard) {
            setTimeout(() => {
                dispatch(modalAction.closeModalLogin())
            }, 20)
            // navigate(-1)
            return
        }
        dispatch(modalAction.closeModalLogin())
    }

    const closeModalSignUp = () => {
        const pathname = window.location.pathname
        if (pathname !== PAGE.dashboard) {
            setTimeout(() => {
                dispatch(modalAction.closeModalSignUp())
            }, 20)
            navigate(-1)
            return
        }
        dispatch(modalAction.closeModalSignUp())
    }
    const closeModalLogout = () => {
        dispatch(modalAction.closeModalLogout())
    }

    const closeModalDelete = () => {
        dispatch(modalAction.closeModalDelete())
    }

    return (
        <>
			<ModalLogin
				isOpen={modalLogin.isOpen}
				funToggle={closeModalLogin}
				activeLoginTab={modalLogin.isOpen}
				defaultMode={'login'}
				screenWidth={screenWidth}
			/>
			<ModalLogin
				isOpen={modalSignUp.isOpen}
				funToggle={closeModalSignUp}
				activeLoginTab={false}
				defaultMode={'register'}
				screenWidth={screenWidth}
			/>
            {modalLogout.isOpen && (
                <ModalLogout isOpen={modalLogout.isOpen} funToggle={closeModalLogout} />
            )}
            {modalDelete.isOpen && (
                <ModalDelete isOpen={modalDelete.isOpen} funToggle={closeModalDelete} />
            )}
        </>
    )
}

export default ModalManagement
