/* eslint-disable */
import { Link, useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import PAGE from '../../../config/path.route';
import userService from '../../user/User.service';
import ToastNotify from '../../../utils/Toast';
import LoadingButtonIcon from '../../../components/LoadingButton/LoadingButtonIcon';

const FormForgotPassword = ({ formikForgotPassword, setMode, changeMode, isLoading }) => {
    const focusInput = useRef(null);

    useEffect(() => {
        focusInput.current.focus();
    }, []);

    return (
        <div
            className="mx-auto w-full md:mx-0 max-w-[700px] lg:min-w-[500px] form-forgot-password"
            id="forgot-modal"
        >
            <div className="flex flex-col w-full p-5 bg-white max-md:rounded-b-xl md:rounded-r-xl md:p-10">
                <h2 className="mb-5 text-2xl font-bold text-left text-gray-800">Quên mật khẩu</h2>

                <div id="input" className="flex flex-col w-full my-5">
                    <label htmlFor="username" className="mb-2 text-left text-gray-700">
                        Nhập email để tìm kiếm tài khoản của bạn{' '}
                        <span className="text-red-500">(*)</span>
                    </label>
                    <input
                        ref={focusInput}
                        type="text"
                        id="confirm_email"
                        name="confirm_email"
                        placeholder="Nhập email"
                        className={`border-1 appearance-none rounded-lg px-4 py-3 text-black placeholder:text-gray-400 focus:shadow-lg focus:outline-none focus:ring-1 ${formikForgotPassword.errors.email
                            ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300 focus:border-blue-600 focus:ring-blue-600'
                            }`}
                        value={formikForgotPassword.values.email}
                        onChange={(e) =>
                            formikForgotPassword.setFieldValue('email', e.target.value.trim())
                        }
                    />
                    <span className="mt-1 text-sm text-left text-red-500">
                        {formikForgotPassword.errors.email}
                    </span>
                </div>

                <div className="flex flex-col w-full my-5">
                    <button
                        disabled={isLoading}
                        className="w-full rounded-lg bg-gradient-to-r from-[#51ADF5] to-[#2196F3] py-4 text-white hover:from-[#2196F3] hover:to-[#51ADF5]  hover:shadow-lg "
                        onClick={formikForgotPassword.handleSubmit}
                    >
                        <div className="flex flex-row items-center justify-center">
                            {isLoading && <LoadingButtonIcon />}
                            <div className="font-bold text-white capitalize">Tìm kiếm</div>
                        </div>
                    </button>

                    <button
                        type="button"
                        className="w-full py-4 mt-3 text-white bg-gray-300 rounded-lg hover:shadow-lg "
                        onClick={() => {
                            setMode('login');
                            changeMode();
                        }}
                    >
                        <div className="flex flex-row items-center justify-center">
                            <div className="font-bold text-gray-700 capitalize">Trở lại</div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FormForgotPassword;
