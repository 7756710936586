/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { getDateOfMonth, getDateOfWeek, getMonthOfYear, getYear } from '../../../utils/Times';

import { Capitalize } from '../../../utils/Capitalize';
import { QRCodeSVG } from 'qrcode.react';
import { convertNgayDuongAm } from '../../../utils/LichAm';
import { useCallback } from 'react';
import IMAGES_WEDDING_2 from './images/_index';
import bg from './images/sub-bg.png';
import envConstants from '../../../config/key';

function SubTemplate7({ data, guestName, salutation, refFrame, urlShare }) {
    const [pathImgGroom, setPathImgGroom] = useState('');
    const [pathImgBride, setPathImgBride] = useState('');

    useEffect(() => {
        if (data?.avatarGroom?.length > 0) {
            setPathImgGroom(envConstants.SERVER_URL + data?.avatarGroom[0]);
        }
    }, []);

    useEffect(() => {
        if (data?.avatarBride?.length > 0) {
            setPathImgBride(envConstants.SERVER_URL + data?.avatarBride[0]);
        }
    }, []);
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768); // Thay 768 bằng giá trị cụ thể của width mà bạn muốn xem là mobile
        }

        window.addEventListener('resize', handleResize);

        // Kiểm tra một lần khi component được render
        handleResize();

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div
            className="relative bg-white "
            tabIndex="0"
            ref={refFrame}
            style={{
                background: `url('${bg}')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100%',
            }}
        >
            <div className="p-7">
                <div className={'relative h-auto py-5'}>
                    <div className="mx-auto mt-7 w-1/4">
                        <img src={IMAGES_WEDDING_2.ICON_KISS_TOP} alt=".." />
                    </div>
                    <div className="mx-auto mt-1 w-full text-slate-700 ">
                        <p className="text-center font-greatVibes text-4xl">{salutation}</p>
                    </div>
                    <div className="mx-auto mt-3 w-full text-slate-700">
                        <p className="text-center font-Merriweather text-sm uppercase">
                            {guestName || '....................................................'}
                        </p>
                    </div>
                    <div className="mx-auto mt-1 w-full text-slate-700">
                        <p className="text-center font-Merriweather text-sm uppercase">
                            Tới dự lễ thành hôn của chúng tôi
                        </p>
                    </div>
                    {/** Co dau chu re */}
                    <div className="mx-auto mt-7 w-full">
                        <p className="text-center font-greatVibes text-5xl text-red-400">
                            {Capitalize(data.nameGroom)}
                        </p>
                    </div>
                    <div className="mx-auto mt-0 flex w-full">
                        <div className="mx-auto w-5/12 ">
                            {pathImgGroom !== '' && (
                                <div className="mx-auto w-1/3 rounded-full border border-gray-300 shadow-xl">
                                    <img className="rounded-full" alt="..." src={pathImgGroom} />
                                </div>
                            )}
                        </div>
                        <div className="mt-4 w-2/12">
                            <svg
                                className="mx-auto  w-1/2  fill-current text-red-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 411.6 336.84"
                            >
                                <path
                                    d="M234.19,1.47c-.28-3.91-.72-7.86-1.32-11.74A111,111,0,0,0,188.5-83.36a111,111,0,0,0-83.05-20.31A111,111,0,0,0,28.77-54.11a111,111,0,0,0-88-24.18,111,111,0,0,0-73.09,44.37,111,111,0,0,0-20.31,83.05C-137.95,144.72-33.3,195.28,27.77,216.91a6,6,0,1,0,4-11.23C-104.68,157.35-135,85.71-140.9,47.31A99.91,99.91,0,0,1-57.46-66.52,99.25,99.25,0,0,1,26.11-40.59a6,6,0,0,0,5,1.54,6,6,0,0,0,4.29-3,99.26,99.26,0,0,1,71.88-49.87,99.14,99.14,0,0,1,74.2,18.14A99.14,99.14,0,0,1,221.09-8.46c.55,3.56,1,7.19,1.21,10.77a6,6,0,1,0,11.88-.85Zm0,0"
                                    transform="translate(155 106)"
                                ></path>
                                <path
                                    d="M192.17,8.15a84.32,84.32,0,0,0-67,10.51,84.87,84.87,0,0,0-117.65-33A84.31,84.31,0,0,0-31.68,37.4c-6,23.07-4.4,47.61,4.84,72.91,7.27,19.93,19.26,40.38,35.64,60.77A344.89,344.89,0,0,0,68.08,228.7a6,6,0,0,0,3.5,1.14,6.13,6.13,0,0,0,.7,0,344.41,344.41,0,0,0,79.88-21.27c24.26-9.78,44.72-21.76,60.8-35.59,20.43-17.56,33.84-38.17,39.87-61.24A85,85,0,0,0,192.17,8.15Zm49.14,100.54c-5.39,20.65-17.5,39.19-36,55.13-15.06,13-34.36,24.27-57.36,33.57a343.37,343.37,0,0,1-74.74,20.27,343.54,343.54,0,0,1-55.29-54.22C2.41,144.07-8.91,124.79-15.7,106.11c-8.35-22.94-9.84-45-4.45-65.69A73,73,0,0,1,50.3-14.13a73.28,73.28,0,0,1,18.55,2.4,72.54,72.54,0,0,1,48.54,41.64,6,6,0,0,0,9.08,2.37,72.95,72.95,0,0,1,114.85,76.41Zm0,0"
                                    transform="translate(155 106)"
                                ></path>
                            </svg>
                        </div>
                        <div className="mx-auto w-5/12 ">
                            {pathImgBride && pathImgBride !== 'undefined' && (
                                <div className="mx-auto w-1/3 rounded-full border border-gray-300 shadow-xl">
                                    <img className="rounded-full" alt="..." src={pathImgBride} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mx-auto mt-0 w-full">
                        <p className="text-center font-greatVibes text-5xl text-red-400">
                            {Capitalize(data.nameBride)}
                        </p>
                    </div>
                    <div className="mx-auto mt-10 w-full text-slate-700">
                        {data?.placeCeremony && (
                            <p className="text-center font-Merriweather text-sm uppercase">
                                Hôn lễ được tổ chức tại {data?.placeCeremony}
                            </p>
                        )}
                        <p className=" mt-1 text-center text-xs font-bold">
                            ({Capitalize(data?.addressCeremony)})
                        </p>
                    </div>
                    {/** Thoi gian */}
                    <div className="mx-auto w-5/6 px-0">
                        <p className=" mt-3 text-center text-xl">
                            <b>{data?.timeCeremony}</b>
                        </p>
                        <div className="mx-auto -mt-2 flex w-4/6">
                            <div className="w-1/3   text-slate-700">
                                <span className="float-right my-5 block w-full border-y-2 border-black p-2">
                                    <p className=" text-center text-xs font-bold uppercase">
                                        {getDateOfWeek(data?.dateCeremony)}
                                    </p>
                                </span>
                            </div>
                            <div className="w-1/3  ">
                                <p className="text-center text-6xl font-bold text-red-400">
                                    {data?.dateCeremony && getDateOfMonth(data?.dateCeremony)}
                                </p>
                            </div>
                            <div className="w-1/3  text-slate-700">
                                <span className="float-left my-5 block w-full border-y-2 border-black p-2">
                                    <p className=" text-center text-xs font-bold uppercase">
                                        {data.dateCeremony &&
                                            ' THÁNG ' + getMonthOfYear(data.dateCeremony)}
                                    </p>
                                </span>
                            </div>
                        </div>
                        <p className=" mt-0 text-center text-sm font-bold uppercase  text-slate-700">
                            {data.dateCeremony && ' NĂM ' + getYear(data.dateCeremony)}
                        </p>
                        {data.dateCeremony && (
                            <p className=" mt-0 text-center text-xs text-slate-700">
                                (Nhằm ngày{' '}
                                {data.dateCeremony
                                    ? ' ' + convertNgayDuongAm(data.dateCeremony).day + ' '
                                    : ' 30 '}
                                tháng{' '}
                                {data.dateCeremony
                                    ? ' ' + convertNgayDuongAm(data.dateCeremony).month + ' '
                                    : ' 12 '}
                                năm{' '}
                                {data.dateCeremony
                                    ? ' ' + convertNgayDuongAm(data.dateCeremony).year
                                    : ' Nhâm Dần'}
                                )
                            </p>
                        )}
                    </div>
                    {/**  */}
                    <div className="mx-auto mt-20 w-full">
                        {/* <p className="text-center font-greatVibes text-3xl text-red-300">
                            Rất hân hạnh được đón tiếp!
                        </p> */}
                    </div>

                    {/** QR code */}
                    {urlShare && (
                        <div className="absolute right-2 top-2 z-2">
                            <QRCodeSVG
                                value={urlShare}
                                fgColor="#ae6565"
                                className="h-20 w-20"
                                renderAs="canvas"
                            />
                        </div>
                    )}
                </div>
            </div>

            {/** bg image */}
            {/* <div className=" absolute left-0 top-0 w-1/3">
                <img className="w-full" src={IMAGES_WEDDING_2.BG_SUB_TEMP2_1} alt=".." />
            </div>
            <div className=" absolute bottom-0 right-0 flex w-1/3 justify-end">
                <img className="w-full" src={IMAGES_WEDDING_2.BG_SUB_TEMP2_2} alt=".." />
            </div> */}
        </div>
    );
}

export default SubTemplate7;
