import 'react-toastify/dist/ReactToastify.css';

import { toast } from 'react-toastify';

const ToastNotify = {
    Success: (mess, position = 'top-right') => {
        toast.success(mess, { position });
    },
    Error: (mess, position = 'top-right') => {
        toast.error(mess, { position });
    },
    Info: (mess, position = 'top-right', autoClose) => {
        toast.info(mess, {
            position,
            ...(autoClose && { autoClose })
        });
    },
    Warning: (mess, position = 'top-right') => toast.warning(mess, { position }),
    Update: (toastId, mess, type = 'success') => toast.update(toastId, {
        render: mess,
        type,
        isLoading: false,
        autoClose: 2000
    }),
    Loading: (mess = 'Đang lưu...') => toast.loading(mess),
    DismissLoading: (id) => toast.dismiss(id),
    Promise: (fn, msgPending = 'Đang lưu...', msgSuccess = 'Thành công') => toast.promise(fn, {
        pending: msgPending,
        success: msgSuccess,
        error: {
            render({ data }) {
                return data.message;
            },
        },
    }),
};

export default ToastNotify;
