/* eslint-disable */
import { useEffect, useState } from 'react';
import { TYPE_PACKAGE } from './PackagesUsing';
import { FaCrown } from 'react-icons/fa';
import { formatDatetime } from '../../../utils/Times';

export default function TransactionHistoryDetail({ data }) {
    const [detailOrder, setDetailOrder] = useState();

    useEffect(() => {
        if (data) {
            console.log(data);
            setDetailOrder(data);
        }
    }, [data]);

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="modalTransactionHistoryDetail"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered  modal-xl">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between bg-gray-200 p-4 border-b border-gray-300 modal-header shrink-0 rounded-t-md">
                        <h5 className="text-[16px] font-bold leading-normal text-gray-800">
                            Chi tiết đơn hàng{' '}
                        </h5>
                        <button
                            type="button"
                            title="Đóng"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex flex-col items-center justify-between w-full border-b border-gray-200 modal-content shrink-0 rounded-t-md p-[20px] gap-[10px] overflow-y-auto overflow-x-hidden h-auto">
                        <div className="w-full mt-2">
                            <p className="block antialiased font-sans text-[15px] font text-gray-700 font-semibold leading-normal mb-2 ">
                                Mã đơn hàng:{' '}
                                <span className="text-blue-500 font-bold">
                                    {' '}
                                    {detailOrder?.order_alias_string +
                                        detailOrder?.order_alias_number
                                            ?.toString()
                                            ?.padStart(5, '0')}
                                </span>
                            </p>
                            <div className="flex items-center gap-2 mb-2">
                                <p className="block antialiased font-sans text-[15px] font text-gray-700 font-semibold leading-normal ">
                                    Ngày tạo:
                                </p>

                                <p className="block antialiased text-end font-sans text-[14px] font-semibold text-gray-500">
                                    {formatDatetime(detailOrder?.createdAt).split('ngày')[0]}
                                    {formatDatetime(detailOrder?.createdAt).split('ngày')[1]}
                                </p>
                            </div>
                            {detailOrder?.status === 'completed' && (
                                <div className="flex items-center gap-2 mb-3">
                                    <p className="block antialiased font-sans text-[15px] font text-gray-700 font-semibold leading-normal ">
                                        Ngày hoàn thành:
                                    </p>

                                    <p className="block antialiased text-end font-sans text-[14px] font-semibold text-gray-500">
                                        {formatDatetime(detailOrder?.updatedAt).split('ngày')[0]}
                                        {formatDatetime(detailOrder?.updatedAt).split('ngày')[1]}
                                    </p>
                                </div>
                            )}
                            <div className='w-full overflow-x-auto'>
                                <table className="w-full min-w-[640px] table-auto border border-gray-300  shadow-lg">
                                    <thead className="bg-blue-500">
                                        <tr>
                                            <th className="border-b  border-gray-300 py-3 px-2.5 text-center">
                                                <p className=" antialiased font-sans text-sm font-bold uppercase text-white">
                                                    STT
                                                </p>
                                            </th>
                                            <th className="border-b  border-gray-300 py-3 px-2.5 text-center">
                                                <p className="block antialiased font-sans text-sm font-bold uppercase text-white">
                                                    Gói dịch vụ
                                                </p>
                                            </th>
                                            <th className="border-b  border-gray-300 py-3 px-2.5 text-center">
                                                <p className="block antialiased font-sans text-sm font-bold uppercase text-white">
                                                    Tài nguyên sử dụng
                                                </p>
                                            </th>
                                            <th className="border-b  border-gray-300 py-3 px-2.5 text-center">
                                                <p className="block antialiased font-sans text-sm font-bold uppercase text-white">
                                                    Số lượng
                                                </p>
                                            </th>
                                            <th className="border-b  border-gray-300 py-3 px-2.5 text-center">
                                                <p className="block antialiased font-sans text-sm font-bold uppercase text-white">
                                                    Đơn giá
                                                </p>
                                            </th>
                                            <th className="border-b border-gray-300 py-3 px-2.5 text-center">
                                                <p className="block antialiased font-sans text-sm font-bold uppercase text-white">
                                                    Thành tiền
                                                </p>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detailOrder?.detail?.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td className="py-3 pl-1 border-b  border-gray-300">
                                                        <p className=" antialiased text-center font-sans text-sm leading-normal  font-semibold text-gray-800">
                                                            {index + 1}
                                                        </p>
                                                    </td>
                                                    <td className="py-3 pl-1 border-b  border-gray-300">
                                                        <div className="flex justify-start items-center gap-4">
                                                            <p className="flex antialiased font-sans text-sm leading-normal  font-semibold text-gray-800">
                                                                {item.type === TYPE_PACKAGE.BASE && (
                                                                    <span className="text-sm text-yellow-500 rounded-full border border-amber-400 bg-white p-0.5 mr-0.5">
                                                                        <FaCrown />
                                                                    </span>
                                                                )}
                                                                {item?.package_name}
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td className="py-3 pl-1 border-b  border-gray-300">
                                                        <div className={`relative flex flex-col`}>
                                                            <p className="">
                                                                <i className="text-sm mr-1.5 ml-[3px] text-blue-500 fas fa-database"></i>
                                                                <span className="text-sm">
                                                                    Dung lượng:{' '}
                                                                </span>
                                                                <span className="text-lg text-black font-semibold">
                                                                    {' '}
                                                                    {item?.file_storage}{' '}
                                                                </span>
                                                                <span className="text-sm text-gray-600 font-semibold">
                                                                    {' '}
                                                                    MB
                                                                </span>
                                                            </p>
                                                            <p className="">
                                                                <i className="text-sm mr-1 text-blue-500 fas fa-users"></i>
                                                                <span className="text-sm">
                                                                    Khách mời:{' '}
                                                                </span>
                                                                <span className="text-lg text-black font-semibold">
                                                                    {' '}
                                                                    {item?.event_guest}
                                                                </span>
                                                                <span className="text-sm text-gray-600 font-semibold">
                                                                    {' '}
                                                                    khách
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td className="py-3  border-b  border-gray-300">
                                                        <p className="block antialiased text-center font-sans text-sm font-semibold text-gray-800">
                                                            {item?.quantity}
                                                        </p>
                                                    </td>
                                                    <td className="py-3  pr-1 border-b  border-gray-300 ">
                                                        <p className="block antialiased text-end font-sans text-sm font-semibold text-gray-800">
                                                            {new Intl.NumberFormat('vi-VN').format(
                                                                item?.price
                                                            )}{' '}
                                                            VNĐ
                                                        </p>
                                                    </td>
                                                    <td className="py-3  pr-1 border-b border-gray-300 ">
                                                        <p className="block antialiased text-end font-sans text-sm font-semibold text-gray-800">
                                                            {new Intl.NumberFormat('vi-VN').format(
                                                                item?.price * item?.quantity
                                                            )}{' '}
                                                            VNĐ
                                                        </p>
                                                    </td>
                                                </tr>
                                            );
                                        })}

                                        <tr>
                                            <td className="py-3 pl-2 border-b border-gray-300">
                                                <div className="flex justify-start items-center gap-4">
                                                    <p className="block antialiased font-sans text-lg font-bold text-black leading-normal ">
                                                        Tổng tiền:
                                                    </p>
                                                </div>
                                            </td>
                                            <td
                                                colSpan={5}
                                                className="py-3  pr-2 border-b border-gray-300 "
                                            >
                                                <p className="block antialiased text-end font-sans text-lg font-bold text-blue-600">
                                                    {new Intl.NumberFormat('vi-VN').format(
                                                        detailOrder?.total
                                                    )}{' '}
                                                    VNĐ
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover:bg-gray-50 transition-all focus:bg-gray-50 active:bg-gray-50  inline-block rounded bg-white border-[1px] border-gray-300 px-5 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                        >
                            Đóng
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
