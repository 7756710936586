/* eslint-disable */
import { QRCodeSVG } from 'qrcode.react';
import React from 'react';
import TiltlePage from './components/TitlePage';

export default function SubEvent7({
    data,
    guestTitle,
    guestFullname,
    salutation,
    refFrame,
    urlShare,
}) {
    return (
        <>
            <div
                tabIndex="0"
                ref={refFrame}
                className="relative flex h-auto flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none "
            >
                <div className="border-1 relative h-auto w-auto">
                    <TiltlePage
                        data={data}
                        guestFullname={guestFullname}
                        guestTitle={guestTitle}
                        salutation={salutation}
                        isSubEvent={true}
                    />
                </div>
                {/** QR code */}
                <div className="absolute right-10 top-10 w-[15%] rounded-md bg-gray-700 p-1.5">
                    {urlShare && (
                        <div className="rounded-md bg-white p-2">
                            <QRCodeSVG
                                value={urlShare}
                                bgColor={'#FFAA1D'}
                                fgColor={'#ffffff'}
                                className="h-1/2 w-full"
                            />
                        </div>
                    )}
                    <div className="mt-1.5 flex">
                        <div className="w-full text-center text-white">
                            <i className="fas fa-camera mr-1 text-lg"></i>{' '}
                            {/* <span className="text-[12px]">Xem chi tiết</span> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
