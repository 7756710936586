/* eslint-disable */
import axios from './axios';

const EventTemplate = {
    addNew: async (data) => {
        return axios({
            url: '/event',
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: data,
        });
    },
    updateContent: async (id, data) => {
        return axios({
            url: `/event/updateFormdata/${id}`,
            method: 'PATCH',
            data: data,
        });
    },
    updateFile: async (id, data) => {
        return axios({
            url: `/event/updateFormdataFile/${id}`,
            method: 'PATCH',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: data,
        });
    },
    updateDataFile: async (id, data) => {
        return axios({
            url: `/event/updateFileSponsor/${id}`,
            method: 'PATCH',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: data,
        })
    },

    update: async (id, data) => {
        return axios({
            url: `/event/${id}`,
            method: 'PATCH',
            // headers: { 'Content-Type': 'multipart/form-data' },
            data: data,
        });
    },
    getAll: (query = {}) => {
        return axios({
            url: '/event', //
            method: 'GET',
            params: query,
        });
    },
    getOne: async (id) => {
        return axios({
            url: `/event/${id}`,
            method: 'GET',
        });
    },
    export: async (id, data) => {
        return axios({
            url: `/event/${id}/export`,
            method: 'POST',
            data: data,
        });
    },
    checkExport: async (id) => {
        return axios({
            url: `/event/${id}/check-export`,
            method: 'GET',
        });
    },
    // findBySlug: async (data) => {
    //     return axios({
    //         url: '/event/public/event',
    //         method: 'POST',
    //         data: {
    //             slug: data
    //         },
    //     })
    // },
    getPublicEvent: async (payload) => {
        return axios({
            url: '/event/public/event',
            method: 'POST',
            data: payload,
        });
    },
    delete: async (id) => {
        return axios({
            url: `/event/${id}`,
            method: 'DELETE',
        });
    },
    screenshot: async (link, location) => {
        return axios({
            url: `/url/screenshot`,
            method: 'GET',
            params: {
                link,
                location,
            },
        });
    },
};

export default EventTemplate;
