/* eslint-disable */
const PAGE = {
    dashboard: '/',
    aboutus: '/ve-chung-toi',
    policy: '/chinh-sach',
    terms: '/dieu-khoan',
    createEvent: '/tao-thiep',
    templates: '/loai-thiep/:alias',
    allTemplates: '/loai-thiep/tat-ca',
    templateView: '/xem-thiep/:alias',
    userEvents: '/thiep-cua-toi',
    publish: '/:url_share',
    publishGuest: '/:url_share/:url_guest',
    managerEventGeneral: '/su-kien/:eventId',
    error404: '/trang-khong-ton-tai-404',
    profile: '/tai-khoan',
    publishThumbnail: '/thumbnail/:url_share',
    publishThumbnailGuest: '/thumbnail/:url_share/:url_guest',
    guestManager: '/quan-li-khach-moi/:id',
    configEvent: '/cau-hinh-su-kien/:id',
    RSVP: '/:url_share/:url_guest/xac-nhan',
    resetPassword: 'reset-password/:id',
    verifyUser: 'verify-user/:token',
    suggest: '/goi-y',
    templateDetail: '/chi-tiet/:alias',
    pricing: '/bang-gia',
    help: '/huong-dan',
    feature: '/feature',

    feedback: '/feedback',
};

export default PAGE;
