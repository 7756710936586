/* eslint-disable */
import { useEffect, useState, useRef, MouseEvent } from 'react';

import { Chart as ChartJs, Tooltip, Title, ArcElement, Legend, InteractionItem } from 'chart.js';

import { Pie, getDatasetAtEvent, getElementAtEvent, getElementsAtEvent } from 'react-chartjs-2';
import {
    FaArrowDown,
    FaCaretDown,
    FaChartBar,
    FaEnvelope,
    FaEnvelopeOpenText,
    FaHourglass,
    FaInfo,
    FaUser,
    FaUserCheck,
    FaUserClock,
    FaUserTie,
    FaUserTimes,
} from 'react-icons/fa';

ChartJs.register(Tooltip, Title, ArcElement, Legend);

const reverseStatus = (status) => {
    switch (status) {
        case 'Chưa gửi lời mời':
            return ' UNINVITED';
        case 'Đang gửi lời mời':
            return 'SENDING';
        case 'Đã gửi lời mời':
            return 'INVITED';
        case 'Đồng ý tham dự':
            return 'ACCEPT';
        case 'Từ chối tham dự':
            return 'DECLINE';
        case 'Đang chờ xác nhận':
            return 'VERIFYING';
        case 'Chấp nhận yêu cầu tham dự':
            return 'USER_ACCEPT';
        case 'Từ chối yêu cầu tham dự':
            return 'USER_DENY';
        default:
            return;
    }
};

const data = {
    datasets: [
        {
            data: [10, 20, 30],
            backgroundColor: ['red', 'blue', 'yellow'],
        },
    ],
    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ['Red', 'Yellow', 'Blue'],
};

export default function GuestChartModal({
    groups,
    guestQuery,
    statisticalData,
    resetAndAddGuestQuery,
}) {
    const [mode, setMode] = useState('status');
    const [guestData, setGuestData] = useState(statisticalData[0]);

    useEffect(() => {
        setGuestData(statisticalData[0]);
    }, [statisticalData[0]]);

    const percentGuest = (sl, type) => {
        const totalTypeGuest = guestData?.guestVip + guestData?.guestNormal;
        const totalVerifiedGuest =
            guestData?.guestUninvited +
            guestData?.guestInvited +
            guestData?.guestSending +
            guestData?.guestAccept +
            guestData?.guestDecline;
        const totalVerifyingGuest =
            guestData?.guestVerifying + guestData?.guestUserAccept + guestData?.guestUserDeny;
        if (type == 'status') {
            if (totalVerifiedGuest == 0) {
                return 0;
            } else {
                return ((sl / totalVerifiedGuest) * 100).toFixed(1);
            }
        }
        if (type == 'type') {
            if (totalTypeGuest == 0) {
                return 0;
            } else {
                return ((sl / totalTypeGuest) * 100).toFixed(1);
            }
        }
        if (type == 'verify_status') {
            if (totalVerifyingGuest == 0) {
                return 0;
            } else {
                return ((sl / totalVerifyingGuest) * 100).toFixed(1);
            }
        }
    };

    const checkMode = (mode) => {
        if (mode == 'status') {
            return {
                text: 'Trạng thái phản hồi',
                icon: <FaEnvelope className="mr-1" />,
            };
        }
        if (mode == 'type') {
            return {
                text: 'Loại khách',
                icon: <FaUser className="mr-1" />,
            };
        }
        if (mode == 'verify_status') {
            return {
                text: 'Trạng thái xác nhận',
                icon: <FaUserClock className="mr-1" />,
            };
        }
    };

    const options = [
        {
            alias: 'status',
            name: 'Trạng thái phản hồi',
            icon: <FaEnvelope className="mr-1" />,
            action: () => {
                setMode('status');
            },
        },
        {
            alias: 'type',
            name: 'Loại khách',
            icon: <FaUser className="mr-1" />,
            action: () => {
                setMode('type');
            },
        },
        {
            alias: 'verify_status',
            name: 'Trạng thái xác nhận',
            icon: <FaUserClock className="mr-1" />,
            action: () => {
                setMode('verify_status');
            },
        },
    ];

    const type = [
        {
            name: 'Khách thường',
            icon: <FaUser className="mr-1 text-gray-700" />,
            quantity: guestData?.guestNormal,
            percent: percentGuest(guestData?.guestNormal, 'type'),
            action: () => {
                resetAndAddGuestQuery({ vip: 'false' });
            },
            color: ['#cbd5e1', '#64748b'],
        },
        {
            name: 'Khách VIP',
            icon: <FaUserTie className="mr-1 text-violet-500" />,
            quantity: guestData?.guestVip,
            percent: percentGuest(guestData?.guestVip, 'type'),
            action: () => {
                resetAndAddGuestQuery({ vip: 'true' });
            },
            color: ['#ddd6fe', '#8b5cf6'],
        },
    ];
    const status = [
        {
            name: 'Chưa gửi lời mời',
            icon: <FaEnvelope className="mr-1 text-amber-500" />,
            quantity: guestData?.guestUninvited,
            percent: percentGuest(guestData?.guestUninvited, 'status'),
            action: () => {
                resetAndAddGuestQuery({ status: 'UNINVITED' });
            },
            color: ['#fef9c3', '#f59e0b'],
        },
        {
            name: 'Đang gửi lời mời',
            icon: <FaHourglass className="mr-1 text-sky-500" />,
            quantity: guestData?.guestSending,
            percent: percentGuest(guestData?.guestSending, 'status'),
            action: () => {
                resetAndAddGuestQuery({ status: 'SENDING' });
            },
            color: ['#e0f2fe', '#0ea5e9'],
        },
        {
            name: 'Đã gửi lời mời',
            icon: <FaEnvelopeOpenText className="mr-1 text-blue-500" />,
            quantity: guestData?.guestInvited,
            percent: percentGuest(guestData?.guestInvited, 'status'),
            action: () => {
                resetAndAddGuestQuery({ status: 'INVITED' });
            },
            color: ['#dbeafe', '#3b82f6'],
        },
        {
            name: 'Đồng ý tham dự',
            icon: <FaUserCheck className="mr-1 text-green-500" />,
            quantity: guestData?.guestAccept,
            percent: percentGuest(guestData?.guestAccept, 'status'),
            action: () => {
                resetAndAddGuestQuery({ status: 'ACCEPT' });
            },
            color: ['#dcfce7', '#22c55e'],
        },
        {
            name: 'Từ chối tham dự',
            icon: <FaUserTimes className="mr-1 text-red-500" />,
            quantity: guestData?.guestDecline,
            percent: percentGuest(guestData?.guestDecline, 'status'),
            action: () => {
                resetAndAddGuestQuery({ status: 'DECLINE' });
            },
            color: ['#fee2e2', '#ef4444'],
        },
    ];
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="chartModal"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered modal-lg">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-xl font-bold leading-normal text-gray-800"
                            id="exampleModalScrollableLabel"
                        >
                            <FaChartBar className="mr-1" /> Thống kê (
                            {guestQuery.group
                                ? `Nhóm ${
                                      groups.find((group) => group._id == guestQuery.group).fullname
                                  }`
                                : 'Tất cả khách mời'}
                            )
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:opacity-200 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="relative px-4 modal-body flex flex-col gap-[40px] py-[20px] ">
                        {/* Loại khách */}
                        <div className="flex flex-col items-start gap-[20px]">
                            <p className="text-base font-bold text-black">Loại khách</p>
                            <div className="flex gap-[20px]">
                                {type.map((statusItem) => (
                                    <div className="w-[370px] flex flex-col h-[140px] shadow-xl p-4 bg-white rounded-md border border-gray-200 gap-[4px]">
                                        <div className="flex items-start justify-between w-full">
                                            <div className="flex items-center gap-4">
                                                <div
                                                    className="p-4 text-[24px] rounded-md "
                                                    style={{ backgroundColor: statusItem.color[0] }}
                                                >
                                                    {statusItem.icon}
                                                </div>
                                                <div className="flex flex-col gap-1">
                                                    <p className="text-base font-semibold text-black">
                                                        {statusItem.name}
                                                    </p>
                                                    <p className="text-2xl font-bold text-black">
                                                        {statusItem.quantity}
                                                    </p>
                                                </div>
                                            </div>
                                            <button
                                                className="text-sm font-bold text-blue-700"
                                                onClick={statusItem.action}
                                                data-bs-dismiss="modal"
                                            >
                                                Chi tiết
                                            </button>
                                        </div>
                                        <div className="w-full flex flex-col gap-[8px] items-end">
                                            <div
                                                className="px-2 py-1 text-sm rounded-full"
                                                style={{
                                                    backgroundColor: statusItem.color[0],
                                                    color: statusItem.color[1],
                                                }}
                                            >
                                                {statusItem.percent}%
                                            </div>
                                            <div
                                                className="flex w-full h-2 overflow-hidden text-xs rounded"
                                                style={{ backgroundColor: statusItem.color[0] }}
                                            >
                                                <div
                                                    style={{
                                                        width: `${statusItem.percent}%`,
                                                        backgroundColor: statusItem.color[1],
                                                    }}
                                                    className="flex flex-col justify-center shadow-none whitespace-nowrap"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* Trạng thái */}
                        <div className="flex flex-col items-start gap-[20px]">
                            <p className="text-base font-bold text-black">Trạng thái</p>
                            <div className="flex gap-[20px] flex-wrap">
                                {status.map((statusItem) => (
                                    <div className="w-[370px] flex flex-col h-[140px] shadow-xl p-4 bg-white rounded-md border border-gray-200 gap-[4px]">
                                        <div className="flex items-start justify-between w-full">
                                            <div className="flex items-center gap-4">
                                                <div
                                                    className="p-4 text-[24px] rounded-md "
                                                    style={{ backgroundColor: statusItem.color[0] }}
                                                >
                                                    {statusItem.icon}
                                                </div>
                                                <div className="flex flex-col gap-1">
                                                    <p className="text-base font-semibold text-black">
                                                        {statusItem.name}
                                                    </p>
                                                    <p className="text-2xl font-bold text-black">
                                                        {statusItem.quantity}
                                                    </p>
                                                </div>
                                            </div>
                                            <button
                                                className="text-sm font-bold text-blue-700"
                                                onClick={statusItem.action}
                                                data-bs-dismiss="modal"
                                            >
                                                Chi tiết
                                            </button>
                                        </div>
                                        <div className="w-full flex flex-col gap-[8px] items-end">
                                            <div
                                                className="px-2 py-1 text-sm rounded-full"
                                                style={{
                                                    backgroundColor: statusItem.color[0],
                                                    color: statusItem.color[1],
                                                }}
                                            >
                                                {statusItem.percent}%
                                            </div>
                                            <div
                                                className="flex w-full h-2 overflow-hidden text-xs rounded"
                                                style={{ backgroundColor: statusItem.color[0] }}
                                            >
                                                <div
                                                    style={{
                                                        width: `${statusItem.percent}%`,
                                                        backgroundColor: statusItem.color[1],
                                                    }}
                                                    className="flex flex-col justify-center shadow-none whitespace-nowrap"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const StatusTable = ({ guestData, percentGuest, resetAndAddGuestQuery }) => {
    return (
        <div className="my-5 bg-white rounded shadow-xl">
            <table className="w-full table-auto min-w-max">
                <thead>
                    <tr className="text-xs leading-normal text-gray-600 uppercase bg-gray-200">
                        <th className="px-6 py-3 text-center">Trạng thái phản hồi</th>
                        <th className="px-6 py-3 text-center">Số lượng</th>
                        <th className="px-6 py-3 text-center">Tỉ lệ</th>
                        <th className="px-6 py-3 text-center">Hành động</th>
                    </tr>
                </thead>
                <tbody className="text-sm font-light text-gray-600">
                    {status.map((statusItem) => (
                        <tr className="text-sm leading-normal text-black bg-white">
                            <th className="flex items-center justify-center px-6 py-3 font-bold text-center">
                                {statusItem.icon}
                                {statusItem.name}
                            </th>
                            <th className="px-6 py-3 text-center">{statusItem.quantity}</th>
                            <th className=" w-[150px] py-3 text-center">
                                <div className="flex items-center justify-center w-full ">
                                    <span className="mr-5 w-[20%]">{statusItem.percent}%</span>
                                    <div className="relative ml-1 w-[80%]">
                                        <div
                                            style={{ backgroundColor: statusItem.color[0] }}
                                            className="flex h-2 overflow-hidden text-xs rounded bg-amber-200"
                                        >
                                            <div
                                                style={{
                                                    width: `${statusItem.percent}%`,
                                                    backgroundColor: statusItem.color[1],
                                                }}
                                                className={
                                                    'flex flex-col justify-center whitespace-nowrap text-center text-white shadow-none '
                                                }
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </th>
                            <th className="px-6 py-3 text-center">
                                <button
                                    className="rounded bg-blue-500 px-3 py-1.5 text-white hover:bg-blue-600 focus:bg-blue-700"
                                    title="Chi tiết"
                                    data-bs-dismiss="modal"
                                    onClick={statusItem.action}
                                >
                                    <FaInfo />
                                </button>
                            </th>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const TypeTable = ({ guestData, percentGuest, resetAndAddGuestQuery }) => {
    const status = [
        {
            name: 'Khách thường',
            icon: <FaUser className="mr-1 text-gray-700" />,
            quantity: guestData?.guestNormal,
            percent: percentGuest(guestData?.guestNormal, 'type'),
            action: () => {
                resetAndAddGuestQuery({ vip: 'false' });
            },
            color: ['#cbd5e1', '#64748b'],
        },
        {
            name: 'Khách VIP',
            icon: <FaUserTie className="mr-1 text-violet-500" />,
            quantity: guestData?.guestVip,
            percent: percentGuest(guestData?.guestVip, 'type'),
            action: () => {
                resetAndAddGuestQuery({ vip: 'true' });
            },
            color: ['#ddd6fe', '#8b5cf6'],
        },
    ];

    return (
        <div className="my-5 bg-white rounded shadow-xl">
            <table className="w-full table-auto min-w-max">
                <thead>
                    <tr className="text-xs leading-normal text-gray-600 uppercase bg-gray-200">
                        <th className="px-6 py-3 text-center">Loại khách</th>
                        <th className="px-6 py-3 text-center">Số lượng</th>
                        <th className="px-6 py-3 text-center">Tỉ lệ</th>
                        <th className="px-6 py-3 text-center">Hành động</th>
                    </tr>
                </thead>
                <tbody className="text-sm font-light text-gray-600">
                    {status.map((statusItem) => (
                        <tr className="text-sm leading-normal text-black bg-white">
                            <th className="flex items-center justify-center px-6 py-3 font-bold text-center">
                                {statusItem.icon}
                                {statusItem.name}
                            </th>
                            <th className="px-6 py-3 text-center">{statusItem.quantity}</th>
                            <th className=" w-[150px] py-3 text-center">
                                <div className="flex items-center justify-center w-full ">
                                    <span className="mr-5 w-[20%]">{statusItem.percent}%</span>
                                    <div className="relative ml-1 w-[80%]">
                                        <div
                                            style={{ backgroundColor: statusItem.color[0] }}
                                            className="flex h-2 overflow-hidden text-xs rounded bg-amber-200"
                                        >
                                            <div
                                                style={{
                                                    width: `${statusItem.percent}%`,
                                                    backgroundColor: statusItem.color[1],
                                                }}
                                                className={
                                                    'flex flex-col justify-center whitespace-nowrap text-center text-white shadow-none '
                                                }
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </th>
                            <th className="px-6 py-3 text-center">
                                <button
                                    className="rounded bg-blue-500 px-3 py-1.5 text-white hover:bg-blue-600 focus:bg-blue-700"
                                    title="Chi tiết"
                                    data-bs-dismiss="modal"
                                    onClick={statusItem.action}
                                >
                                    <FaInfo />
                                </button>
                            </th>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
const VerifyTable = ({ guestData, percentGuest, resetAndAddGuestQuery }) => {
    const status = [
        {
            name: 'Đang chờ xác nhận',
            icon: <FaUserClock className="mr-1 text-violet-500" />,
            quantity: guestData?.guestVerifying,
            percent: percentGuest(guestData?.guestVerifying, 'verify_status'),
            action: () => {
                resetAndAddGuestQuery({ verify_status: 'VERIFYING' });
            },
            color: ['#ddd6fe', '#8b5cf6'],
        },
        {
            name: 'Đồng ý yêu cầu tham dự',
            icon: <FaUserCheck className="mr-1 text-green-500" />,
            quantity: guestData?.guestUserAccept,
            percent: percentGuest(guestData?.guestUserAccept, 'verify_status'),
            action: () => {
                resetAndAddGuestQuery({ verify_status: 'USER_ACCEPT' });
            },
            color: ['#bbf7d0', '#22c55e'],
        },
        {
            name: 'Từ chối yêu cầu tham dự',
            icon: <FaUserTimes className="mr-1 text-red-500" />,
            quantity: guestData?.guestUserDeny,
            percent: percentGuest(guestData?.guestUserDeny, 'verify_status'),
            action: () => {
                resetAndAddGuestQuery({ verify_status: 'USER_DENY' });
            },
            color: ['#fecaca', '#ef4444'],
        },
    ];

    return (
        <div className="my-5 bg-white rounded shadow-xl">
            <table className="w-full table-auto min-w-max">
                <thead>
                    <tr className="text-xs leading-normal text-gray-600 uppercase bg-gray-200">
                        <th className="px-6 py-3 text-center">Trạng thái xác nhận</th>
                        <th className="px-6 py-3 text-center">Số lượng</th>
                        <th className="px-6 py-3 text-center">Tỉ lệ</th>
                        <th className="px-6 py-3 text-center">Hành động</th>
                    </tr>
                </thead>
                <tbody className="text-sm font-light text-gray-600">
                    {status.map((statusItem) => (
                        <tr className="text-sm leading-normal text-black bg-white">
                            <th className="flex items-center justify-center px-6 py-3 font-bold text-center">
                                {statusItem.icon}
                                {statusItem.name}
                            </th>
                            <th className="px-6 py-3 text-center">{statusItem.quantity}</th>
                            <th className=" w-[150px] py-3 text-center">
                                <div className="flex items-center justify-center w-full ">
                                    <span className="mr-5 w-[20%]">{statusItem.percent}%</span>
                                    <div className="relative ml-1 w-[80%]">
                                        <div
                                            style={{ backgroundColor: statusItem.color[0] }}
                                            className="flex h-2 overflow-hidden text-xs rounded bg-amber-200"
                                        >
                                            <div
                                                style={{
                                                    width: `${statusItem.percent}%`,
                                                    backgroundColor: statusItem.color[1],
                                                }}
                                                className={
                                                    'flex flex-col justify-center whitespace-nowrap text-center text-white shadow-none '
                                                }
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </th>
                            <th className="px-6 py-3 text-center">
                                <button
                                    className="rounded bg-blue-500 px-3 py-1.5 text-white hover:bg-blue-600 focus:bg-blue-700"
                                    title="Chi tiết"
                                    data-bs-dismiss="modal"
                                    onClick={statusItem.action}
                                >
                                    <FaInfo />
                                </button>
                            </th>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
