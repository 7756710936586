/* eslint-disable  */
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import EventsService from '../../../events/Events.service';
import AnalysisEventGreeting from './components/AnalysisEventGreeting';
import ItemGreeting from './components/ItemGreeting';
import ModelAddGreeting from './components/ModelAddGreeting2';
import EventGreetingService from './PublishGreeting.service';
import { OWNER } from './asests/constants/key';
import ModalDelete from './components/ModalDelete';
import ToastNotify from '../../../../utils/Toast';
import ModelHistory from './components/ModelHistory2';
import {
    FaAngleDoubleLeft,
    FaAngleLeft,
    FaAngleRight,
    FaAngleDoubleRight,
    FaExternalLinkAlt,
    FaChartPie,
    // FaRegFrown,
} from 'react-icons/fa';
import ItemGreetingLoading from './components/ItemGreetingLoading';
import ModelApproval from './components/ModalApproval';
// import ShortcutMenu from '../../components/ShortcutMenu';
import Products from '../publish.news/products/Products';
import TimelineShortcutMenu from '../../components/TimelineShortcutMenu';
import ScrollToTop from './components/ScrollToTop';
import IMAGES from '../../../../assets/images/_index';
import QuestionTitle from '../publish.question/QuestionTitle';
import ImagePreview from './components/ImagePreview';
import PAGE from '../../../../config/path.route';
import { TEMPLATE_TYPE } from '../../../../config/constant/common.constant';
import { useSelector } from 'react-redux';

export const limitString = (str) => {
    if (!str) {
        return ''
    }
    if (str.length > 35) {
        return str.substring(0, 35) + '...';
    }
    return str;
}
export const checkTypeTemplate = (templateAlias) => {
    if (templateAlias?.includes(TEMPLATE_TYPE.WEDDING)) {
        return 'Đám cưới đã diễn ra';
    } else {
        return 'Sự kiện đã diễn ra';
    }
};

const dataDefault = {
    guestName: '',
    greeting: '',
    reply: '',
    isPublic: true,
    image: '',
    type: OWNER,
};

const SORT_BY = {
    CREATED_AT: 'createdAt',
    UPDATED_AT: 'updatedAt',
    ASC: 'asc',
    DESC: 'desc',
};

export default function PublishGreeting() {
    const navigate = useNavigate();
    const { eventId } = useParams();

    const [valueMeta, setValueMeta] = useState({
        title: '',
        thumbnail: '',
    });
    const [event, setEvent] = useState('');
    const [eventGreetings, setEventGreetings] = useState([]);
    const [rowDataEdit, setRowDataEdit] = useState({});
    const [rowDataDelete, setRowDataDelete] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [query, setQuery] = useState({
        searchName: '',
        pageNow: 1,
        limit: 10,
        sortField: SORT_BY.CREATED_AT,
        sortType: SORT_BY.DESC,
    });
    const [refreshAnalysis, setRefreshAnalysis] = useState(false);
    const [isExpiredEvent, setIsExpiredEvent] = useState(false);
    const [imagePreview, setImagePreview] = useState();
    const { loggedIn } = useSelector((state) => state.auth);

    const reloadDataTable = () => {
        setIsLoading(true);
        EventGreetingService.getAll(eventId, {
            page: query.pageNow,
            limit: query.limit,
            ...(query?.searchName && { guestName: query.searchName }),
            sortField: query.sortField,
            sortType: query.sortType,
        })
            .then((data) => {
                setQuery({
                    ...query,
                    pageNow: data.page,
                    hasPrevPage: data.hasPrevPage,
                    hasNextPage: data.hasNextPage,
                    totalPages: data.totalPages,
                });
                setEventGreetings(data.docs);
                setRefreshAnalysis(!refreshAnalysis);
            })
            .catch((err) => console.log(err))
            .finally(
                () =>
                    // setTimeout(() => {
                    setIsLoading(false)
                // }, 500)
            );
    };

    const showModalAdd = (type = 'add', item, index) => {
        if (type === 'edit') {
            setRowDataEdit({ ...item, index: index });
        } else if (type === 'add') {
            setRowDataEdit(dataDefault);
        }
    };

    const sendDataGreeting = (item) => {
        setRowDataDelete({ ...item });
    };

    const deleteGreeting = (id) => {
        EventGreetingService.delete(id)
            .then(() => {
                ToastNotify.Success(`Xóa thành công ${rowDataDelete?.guestName}!`);
                reloadDataTable();
            })
            .catch((err) => {
                ToastNotify.Error(err?.message || 'Xóa bị lỗi!');
            });
    };

    useEffect(() => {
        if (eventId) {
            EventsService.getOneEventById(eventId).then(({ data }) => {
                // console.log('data = ', data)
                setEvent(data);
                const title = data.form_data.titleMeta || data.name;
                const thumbnail = data.thumbnail_url;
                setValueMeta({
                    title,
                    thumbnail,
                });
                setIsExpiredEvent(data?.isExpired);
            });
            // reloadDataTable();
        }
    }, [eventId]);



    useEffect(() => {
        reloadDataTable();
    }, [query.pageNow, query.sortType]);

    useEffect(() => {
        const timeOutId = setTimeout(() => reloadDataTable(), 500);
        return () => clearTimeout(timeOutId);
    }, [query.searchName]);

    useEffect(() => {
        if (!loggedIn) {
            const beforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = true;
            };
            window.addEventListener('beforeunload', beforeUnload);
            return () => {
                window.removeEventListener('beforeunload', beforeUnload);
            };
        }
    }, []);

    return (
        <div>
            <Helmet>
                <title>{'Quản lý lời chúc' + ' | ' + valueMeta.title}</title>
                <meta property="og:image" content={valueMeta.thumbnail} />
            </Helmet>
            <ModelAddGreeting
                eventId={eventId}
                reloadData={reloadDataTable}
                rowDataEdit={rowDataEdit}
            />
            <ScrollToTop />
            <ImagePreview imageUrl={imagePreview} />
            <ModalDelete funDelete={deleteGreeting} data={rowDataDelete} />
            <ModelHistory data={rowDataDelete} />
            <ModelApproval eventId={eventId} reloadData={reloadDataTable} data={rowDataDelete} />
            <div className={'relative h-[100dvh] w-[100vw]  bg-gray-100 text-black '}>
                <div className="fixed top-0 z-[40] w-full bg-white md:bg-gray-50 md:px-4 lg:px-6">
                    <div className="relative flex w-full flex-wrap items-center justify-between gap-x-3 p-4 md:px-0 lg:flex-nowrap gap-2 lg:gap-4 lg:py-5">
                        <div className="flex md:flex-row flex-col md:gap-2">
                            <button
                                className="flex items-center"
                                type="button"
                                onClick={() => {
                                    navigate(`/${eventId}/mainmenu`);
                                }}
                                title="Trở về"
                            >
                                <i className=" fas fa-chevron-left"></i>
                                <p className="order-1 ml-3 font-bold text-black">
                                    {/* Quản lý lời chúc */}
                                    {limitString(event?.name)}
                                </p>
                            </button>
                            {
                                isExpiredEvent &&
                                <div className='h-5 rounded-[4px]  flex justify-center items-center bg-red-500 px-3 w-fit'>
                                    <span className='text-[9px] text-white uppercase'>
                                        {checkTypeTemplate(event?.template?.alias)}
                                    </span>
                                </div>
                            }
                            {
                                !loggedIn &&
                                <div className='h-5 rounded-[4px]  flex justify-center items-center bg-red-500 px-3 w-fit'>
                                    <span className='text-[9px] text-white uppercase'>
                                        Bạn chưa đăng nhập
                                    </span>
                                </div>
                            }
                        </div>
                        <div className="flex justify-center gap-2 overflow-hidden ">
                            {
                                loggedIn &&
                                <Link
                                    // target="_blank"
                                    className="inline-flex select-none gap-2 rounded-md bg-white border border-blue-500 px-3 py-1 text-center font-medium text-blue-500 transition-all duration-150 ease-linear hover:bg-blue-100 xl:px-4 text-sm"
                                    to={`..${PAGE.profile}`}
                                    state={{ isResourcePackage: true }}
                                >
                                    Quản lý gói dịch vụ
                                    <FaChartPie className="my-auto" />
                                </Link>
                            }
                            <button className="inline-flex w-max select-none rounded-md bg-blue-500 px-3 py-1 text-center font-medium text-white transition-all duration-150 ease-linear hover:bg-blue-600 xl:px-4 text-sm">
                                <Link
                                    target="_blank"
                                    className="flex gap-2"
                                    to={'../' + event?.url_slug}
                                >
                                    Xem thiệp{' '}
                                    <FaExternalLinkAlt className="my-auto" />
                                </Link>
                            </button>
                        </div>
                    </div>
                    <div className="w-full border-b border-gray-300"></div>
                </div>
                <div className=" w-full flex justify-evenly">
                    <div className="lg:flex hidden relative w-[250px] mt-[75px]">
                        <TimelineShortcutMenu screen={'greetings'} />
                    </div>
                    <div className=" mt-[80px] md:mt-[76px] bg-gray-100 shadow-md border border-gray-200 rounded-md w-full  px-4 md:px-3 lg:w-[48rem] 2xl:w-[60rem]">
                        <div className=" bg-gray-100 ">
                            <p className='font-bold text-black text-lg mt-1'>
                                Quản lý lời chúc
                            </p>
                            <QuestionTitle keyQues={'greeting'} />
                            <AnalysisEventGreeting eventId={eventId} refresh={refreshAnalysis} />
                            <div className=" flex  justify-between  bg-gray-100 pb-1">
                                <div className="w-1/2">
                                    <div className="ease-soft relative flex h-10 w-full flex-wrap items-stretch rounded-lg transition-all md:pr-2">
                                        <span className="ease-soft leading-5.6 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-r-none border border-r-0 border-transparent bg-transparent px-2.5 py-2 text-center text-sm font-normal text-slate-500 transition-all">
                                            <i className="fas fa-search" aria-hidden="true"></i>
                                        </span>
                                        <input
                                            type="text"
                                            value={query.searchName}
                                            onInput={(event) => {
                                                setIsLoading(true);
                                                setQuery({
                                                    ...query,
                                                    searchName: event.target.value,
                                                });
                                            }}
                                            className="focus:shadow-soft-primary-outline ease-soft w-1/100   leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pl-9 pr-3 text-sm text-gray-700 transition-all placeholder:text-gray-500 focus:border-[#2196F3] focus:outline-none focus:transition-shadow"
                                            placeholder="Tìm kiếm theo tên khách"
                                        />
                                    </div>
                                </div>
                                {!isExpiredEvent && (
                                    <div className="flex w-1/2 items-end">
                                        <button
                                            type="button"
                                            onClick={() => showModalAdd('add')}
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalAddGreeting"
                                            disabled={isExpiredEvent}
                                            // data-bs-toggle="offcanvas"
                                            // data-bs-target="#offcanvasEventGreeting"
                                            className="mx-auto mb-1 mr-0 items-center rounded-md bg-blue-500  px-4 py-2 text-center  font-sans text-sm text-white shadow outline-none transition-all duration-150 ease-linear hover:bg-blue-600 hover:shadow-md focus:bg-blue-600 focus:shadow-lg focus:outline-none active:bg-[#2563eb] sm:mr-0"
                                        >
                                            <i className="fas fa-plus-circle mr-2"></i>Thêm lời chúc
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="w-full flex justify-start gap-1 items-center mb-3 mt-1">
                            <p className="text-sm text-black"> Sắp xếp theo:</p>
                            <select
                                onChange={(e) => {
                                    setQuery({
                                        ...query,
                                        sortType: e.target.value,
                                    });
                                }}
                                value={query.sortType}
                                className=" bg-gray-100 w-[150px]  text-sm border-0 border-b border-gray-300  text-black font-semibold focus:ring-0 ring-0"
                            >
                                <option value={SORT_BY.DESC} selected>
                                    Tạo gần nhất
                                </option>
                                <option value={SORT_BY.ASC}>
                                    Tạo lâu nhất
                                </option>
                            </select>
                        </div>
                        <div className="indiana-scroll-container relative  w-full py-2">
                            {isLoading ? (
                                <div className="grid w-full grid-cols-1 flex-wrap gap-4 md:grid-cols-2 lg:flex-row 2xl:gap-6">
                                    {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                                        <ItemGreetingLoading key={index} />
                                    ))}
                                </div>
                            ) : eventGreetings.length !== 0 ? (
                                <div className="grid w-full grid-cols-1 flex-wrap gap-4 md:grid-cols-2 lg:flex-row 2xl:gap-6">
                                    {eventGreetings.map((item, index) => (
                                        <ItemGreeting
                                            key={index}
                                            index={index}
                                            data={item}
                                            showModalAdd={showModalAdd}
                                            sendDataGreeting={sendDataGreeting}
                                            isExpired={isExpiredEvent}
                                            setImagePreview={setImagePreview}
                                        />
                                    ))}
                                </div>
                            ) : query?.searchName ? (
                                <NotFoundGreeting
                                    removeSearchName={() => {
                                        setIsLoading(true);
                                        setQuery({
                                            ...query,
                                            searchName: '',
                                        });
                                    }}
                                />
                            ) : (
                                <EmptyGreeting
                                    createEvent={() => showModalAdd('add')}
                                    isExpiredEvent={isExpiredEvent}
                                />
                            )}
                        </div>

                        {eventGreetings.length !== 0 && (
                            <div className="my-8 flex w-full items-center justify-center ">
                                {query.pageNow !== 1 && (
                                    <button
                                        onClick={() => {
                                            setQuery({ ...query, pageNow: 1 });
                                        }}
                                        className=" p-1 rounded-full border-[1px] border-blue-700 text-blue-700"
                                    >
                                        <FaAngleDoubleLeft
                                            title="Chuyển về trang đầu"
                                            className="cursor-pointer text-[14px]"
                                        />
                                    </button>
                                )}
                                {query.hasPrevPage && (
                                    <button
                                        onClick={() => {
                                            setQuery({
                                                ...query,
                                                pageNow: (query.pageNow -= 1),
                                            });
                                        }}
                                        className="mx-1 p-1 rounded-full border-[1px] border-blue-700 text-blue-700"
                                    >
                                        <FaAngleLeft
                                            title="Chuyển về trang trước"
                                            className="cursor-pointer text-[14px]"
                                        />
                                    </button>
                                )}
                                <p className="text-[14px] font-semibold text-black">
                                    Trang: {query.pageNow}/{query.totalPages}
                                </p>
                                {query.hasNextPage && (
                                    <button
                                        onClick={() => {
                                            setQuery({
                                                ...query,
                                                pageNow: (query.pageNow += 1),
                                            });
                                        }}
                                        className="mx-1 p-1 rounded-full border-[1px] border-blue-700 text-blue-700"
                                    >
                                        <FaAngleRight
                                            title="Chuyển sang trang tiếp"
                                            className="cursor-pointer text-[14px]"
                                        />
                                    </button>
                                )}
                                {query.pageNow !== query.totalPages && (
                                    <button
                                        onClick={() => {
                                            setQuery({
                                                ...query,
                                                pageNow: query.totalPages,
                                            });
                                        }}
                                        className="p-1 rounded-full border-[1px] border-blue-700 text-blue-700"
                                    >
                                        <FaAngleDoubleRight
                                            title="Chuyển đến trang cuối"
                                            className="cursor-pointer text-[14px]"
                                        />
                                    </button>
                                )}
                            </div>
                        )}

                        <div className="h-4"></div>
                    </div>
                    <div className="lg:flex hidden relative w-[250px] mt-[75px]">
                        <Products />
                    </div>
                </div>
            </div>
            {/* <ShortcutMenu screen={'greetings'} /> */}
        </div >
    );
}

const NotFoundGreeting = ({ removeSearchName }) => {
    return (
        <div className='mt-6 flex flex-col justify-center items-center w-full '>
            <img
                src={IMAGES.GREETING_NOT_FOUND} alt='not found'
                className='w-[230px] opacity-70'
            />
            <h4 className="mt-5 text-xl md:text-2xl text-center text-blue-900">
                Rất tiếc, chúng tôi không tìm thấy kết quả phù hợp.
            </h4>
            <p className="mt-2 text-sm text-center text-black">
                Vui lòng thử lại với từ khóa khác hoặc{' '}
                <span onClick={removeSearchName} className="font-bold underline cursor-pointer">
                    bỏ từ khóa tìm kiếm
                </span>{' '}
                để xem tất cả
            </p>
        </div>
    );
};

const EmptyGreeting = ({ createEvent, isExpiredEvent }) => {
    return (
        <div className='mt-5 flex flex-col justify-center items-center w-full'>
            <img
                src={IMAGES.GREETING_NOT_FOUND} alt='not found'
                className='w-[230px] opacity-70'
            />
            <h4 className="mt-4 text-xl text-center text-blue-900">
                Những lời chúc là món quà quý giá nhất cho sự kiện của bạn.
            </h4>
            <p className='mt-1 text-sm text-center text-black'>
                Hãy bắt đầu thêm lời chúc hoặc gửi thiệp cho bạn bè để nhận được những lời chúc ấn tượng nhé!
            </p>
            {!isExpiredEvent && (
                <button
                    onClick={() => createEvent()}
                    data-bs-toggle="modal"
                    data-bs-target="#modalAddGreeting"
                    className="mt-1 text-sm font-bold underline cursor-pointer text-center text-blue-900 border-none"
                >
                    Thêm lời chúc
                </button>
            )}
        </div>
    );
};
