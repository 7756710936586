import FeedBack from '../../api/feedback.api';

const FeedBackService = {

    addNew: async ( data) => {
        const result = await FeedBack.addNew(data);
        return result;
    },
};

export default FeedBackService;