/* eslint-disable */
import CHIA_BUON from './chia-buon-2.png'
import GIA_PHA_DAI_VIET from './giaphadaiviet.jpg'
import GIA_PHA_WEB from './giaphaso.png'
import GIA_PHA_APP from './logo-gia-pha.jpg'
import NHAN_SO from './nhansodaiviet.webp'
import THANKYOU from './thankyou.gif'
// import FEATURE_1 from './feature_004.jpg'
import FEATURE_1 from './feature_01.jpg'
import FEATURE_2 from './feature_02.jpg'
import FEATURE_3 from './feature_03.jpg'
import FEATURE_4 from './feature_04.avif'
import FEATURE_5 from './feature_05.jpg'
import FEATURE_6 from './feature_06.png'

const IMAGES = {
    CHIA_BUON,
    GIA_PHA_DAI_VIET,
    GIA_PHA_WEB,
    GIA_PHA_APP,
    NHAN_SO,
    THANKYOU,
    FEATURE_1,
    FEATURE_2,
    FEATURE_3,
    FEATURE_4,
    FEATURE_5,
    FEATURE_6
};
export default IMAGES;