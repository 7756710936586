/* eslint-disable */

import { FaCrown } from "react-icons/fa";
import { useDispatch } from "react-redux";
import modalAction from "../../../redux/modal/modal.action";
import ModalManagement from "../../../components/ModalManagement/ModalManagement";
import ToastNotify from "../../../utils/Toast";

export const TYPE_BUTTON_UPGRADE = {
    UPGRADE_VIP: 'vip',
    UPGRADE_EXTRA: 'extra'
}

const TEXT_BUTTON = {
    [TYPE_BUTTON_UPGRADE.UPGRADE_VIP]: {
        title: 'Nâng cấp ngay',
        des: 'Hãy khám phá những đặc quyền dành riêng cho thành viên VIP, mở rộng hạn mức sử dụng tài nguyên và lưu giữ thiệp của bạn mãi mãi.'
    },
    [TYPE_BUTTON_UPGRADE.UPGRADE_EXTRA]: {
        title: 'Nâng cấp thêm',
        des: 'Mở rộng thêm tài nguyên lưu trữ và danh sách khách mời của bạn.'
    }
}

export default function ButtonUpgradeVip({ type = TYPE_BUTTON_UPGRADE.UPGRADE_VIP, loggedIn = true }) {
    const dispatch = useDispatch();

    const handleClick = () => {
        if (!loggedIn) {
            ToastNotify.Error("Bạn cần đăng nhập để thực hiện chức năng này.");
            dispatch(modalAction.openModalLogin());
        }
    }

    return (
        <>
            {/* <ModalManagement /> */}
            <button
                data-bs-toggle="modal"
                data-bs-target={loggedIn ? "#modalUpgradePackage" : ''}
                onClick={() => handleClick()}
                className="w-full mt-2 py-1.5 px-0.5 flex flex-col justify-center items-center bg-yellow-300 hover:bg-amber-300 border border-yellow-400 rounded-md ">
                <div className="flex justify-center items-center animate-autozoom transition ease-in-out delay-100">
                    <div className=" p-1 text-sm text-yellow-500 rounded-full border border-amber-400 bg-white mr-2">
                        <FaCrown />
                    </div>
                    <span className="text-sm uppercase text-gray-700 font-semibold">
                        {
                            TEXT_BUTTON[type].title
                        }
                    </span>
                </div>
                <div className="flex items-center mt-0.5">
                    <p className="text-gray-600 text-xs text-center">
                        {
                            TEXT_BUTTON[type].des
                        }
                    </p>
                </div>
            </button>
        </>
    )
};
