import LOGO_ACB from './logo_acb.png';
import LOGO_AGRIBANK from './logo_agribank.png';
import LOGO_MOMO from './momo_logo.png';
import LOGO_TECHCOMBANK from './logo_teckcombank.png';
import LOGO_TPBANK from './logo_tpbank.jpg';
import LOGO_VIETCOMBANK from './logo_vietcombank.png';
import LOGO_VNPAY from './logo_vnpay.jpg';
import LOGO_VPBANK from './logo_vpbank.png';
import QR_BANK from './qr_bank.jpg';

const IMAGES_WEDDING = {
    QR_BANK,
    LOGO_MOMO,
    LOGO_ACB,
    LOGO_VPBANK,
    LOGO_TPBANK,
    LOGO_AGRIBANK,
    LOGO_VIETCOMBANK,
    LOGO_TECHCOMBANK,
    LOGO_VNPAY,
};

export default IMAGES_WEDDING;
