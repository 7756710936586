import { getTempPaginate, getCategory, getTemps, getCategoryParent } from '../../api/templates.api';

export const getTemplatePaginate = ({ filter, category, search, alias }) =>
    getTempPaginate({ filter, category, search: search?.trim(), alias })
        .then(({ data }) => data.data)
        .catch((err) => err);

export const getTemplates = (query) =>
    getTemps(query)
        .then(({ data }) => data.data)
        .catch((err) => err);

export const getCate = ({ slug }) =>
    getCategory({ slug })
        .then(({ data }) => data.data)
        .catch((err) => err);

export const getCategoryParentCombobox = () =>
    getCategoryParent()
        .then(({ data }) => data)
        .catch((err) => err);
