/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { FaArrowLeft, FaCheckCircle, FaPaperPlane } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactQuill from 'react-quill';
import { useForm } from 'react-hook-form';
import ModalConfirm from './ModalConfirm';
import FeedBackService from './Feedback.service';
import './quill.css';
import 'react-quill/dist/quill.snow.css'; // Add css for snow theme
import ToastNotify from '../../utils/Toast';
import { Link, useSearchParams } from 'react-router-dom';
import envConstants from '../../config/key';

const schema = yup.object().shape({
    fullname: yup
        .string()
        .max(50, 'Họ và tên không được quá 50 ký tự!'),
    // .required('Vui lòng nhập họ tên của bạn!'),
    email: yup
        .string()
        .max(50, 'Email không được quá 50 ký tự!')
        .email('Email sử dụng các chữ cái (a-z), số (0-9), dấu chấm (.), và dấu a còng (@).')
});
const FeedBack = () => {
    const [queryParameters] = useSearchParams();
    const auth = useSelector((state) => state.auth);
    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        // watch
        setValue,
    } = useForm({ resolver: yupResolver(schema), mode: 'onSubmit', reValidateMode: 'onChange' });
    const [isOpenLeavinModal, setOpenLeavinModal] = useState(false);
    const [inputData, setInputData] = useState({
        fullname: '',
        birthday: null,
        email: '',
        rating: 5,
        priceRate: 1,
        social: 1,
        improveOption: '',
        inviteOption: 3,
    });
    const toastLoadingId = useRef(null);
    const [rating, setRating] = useState(5);
    const [isSent, setIsSent] = useState(false);
    const [hover, setHover] = useState(null);
    const totalStars = 5;
    const defaultSocial = [
        { id: 1, name: 'Khác' },
        { id: 2, name: 'Bạn bè/Người thân giới thiệu' },
        { id: 3, name: 'Google' },
        { id: 4, name: 'Facebook' },
        { id: 5, name: 'Tiktok' },
    ];
    const defaultpriceRate = [
        { id: 1, name: 'Phù hợp' },
        { id: 2, name: 'Nên giảm giá 10-15%' },
        { id: 3, name: 'Có thể tăng giá 10-15%' },
    ];
    const defaultinvite = [
        { id: 1, name: 'Không' },
        { id: 2, name: 'Sẽ suy nghĩ thêm' },
        { id: 3, name: 'Chắc chắn' },
    ];
    // const defaultRate = [
    //     {id: 1, name: ''}, 
    //     {id: 2, name: 'Bình thường'}, 
    //     {id: 3, name: 'Hữu ích'}, 
    //     {id: 4, name: 'Hữu ích'}, 
    //     {id: 5, name: 'Hữu ích'}, 
    // ];

    useEffect(() => {
        const fullname = queryParameters.get('fullname') || auth?.user?.fullname;
        const birthday = queryParameters.get('birthday') || auth?.user?.birthday;
        const email = queryParameters.get('email') || auth?.user?.email;

        setInputData({
            ...inputData,
            fullname,
            birthday: birthday ? (new Date(birthday)).toISOString().slice(0, 10) : null,
            email
        });
        setValue('fullname', fullname);
        setValue('email', email);
        setValue('birthday', birthday);
        setIsSent(false);
    }, [auth, queryParameters]);

    const submitHandle = async () => {
        setOpenLeavinModal(true);
    };

    const onChangeInput = (event) => {
        console.log(event.target.value);
        clearErrors(event.target.name);
        setInputData({ ...inputData, [event.target.name]: (event.target.name === 'social' ? Number(event.target.value) : event.target.value) });
    };

    const onChangeQuillInput = (value) => {
        setInputData({ ...inputData, improveOption: value });
    };

    const handleDateChange = (date) => {
        setInputData({ ...inputData, ['birthday']: date ? date.toISOString().slice(0, 10) : '' });
    };

    const submitForm = async () => {
        toastLoadingId.current = ToastNotify.Loading('Đang gửi...');
        await FeedBackService.addNew({
            ...(auth.user.id ? { userId: auth.user.id } : {}),
            fullname: inputData.fullname,
            birthday: inputData.birthday,
            email: inputData.email,
            social: defaultSocial.find(x => x.id === Number(inputData.social))?.name,
            pricerate: defaultpriceRate.find(x => x.id === Number(inputData.priceRate))?.name,
            qualityrate: inputData.rating,
            improveOption: inputData.improveOption,
            inviteOption: defaultinvite.find(x => x.id === (Number(inputData.inviteOption)))?.name,
        }).then(() => {
            ToastNotify.Update(toastLoadingId.current, 'Gửi đánh giá thành công!');
            setIsSent(true);
        }).catch((error) => {
            ToastNotify.Update(
                toastLoadingId.current,
                error?.message || 'Đã có lỗi xảy ra!',
                'error'
            );
        }).finally(() => {
            toastLoadingId.current = null;
        });
    };

    return (
        <>
            <div className="mt-[60px] flex w-full flex-col items-center gap-[20px] md:mt-[120px] lg:px-10">
                {!isSent
                    &&
                    <>
                        <img
                            alt='ChungVui'
                            src={envConstants.CLIENT_URL + 'logo_chungvui.png'}
                            className={'inline h-auto w-[10rem] '}
                        />
                        <div className='text-center text-4xl font-semibold text-black'>Phiếu đánh giá
                        </div>
                        <div className='break-words text-center text-sm font-light md:max-w-[50dvw] md:text-lg '>Cảm ơn bạn rất nhiều vì đã dành thời gian đánh giá về nền tảng tạo thiệp ChungVui. Chúng tôi rất trân trọng ý kiến của bạn, và không ngừng cải thiện để ngày một tốt hơn!</div>
                        <form
                            className='grid w-full grid-cols-2 gap-x-10 gap-y-5 p-4 md:w-[80dvw]'
                            onSubmit={(event) => {
                                event.preventDefault();
                                handleSubmit(submitHandle)(event).catch((err) => {
                                    console.log('handleSubmit err = ', err);
                                });
                            }}
                            noValidate={true}
                        >
                            <div className='col-span-2 md:col-span-1'>
                                <label htmlFor="fullname" className="block font-medium leading-6 text-gray-900">Họ và tên </label>
                                <div className="mt-2">
                                    <input id="fullname" name="fullname"
                                        placeholder='Nhập họ và tên của bạn...'
                                        className="block w-full rounded-md border-0 py-3.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:sm:leading-6"
                                        {...register('fullname')}
                                        value={inputData?.fullname}
                                        onInput={onChangeInput}
                                    />
                                </div>
                                <span className="text-xs font-medium text-red-500">
                                    {errors.fullname &&
                                        errors.fullname?.message}
                                </span>
                            </div>
                            <div className='col-span-2 md:col-span-1'>
                                <label htmlFor="birthday" className="block font-medium leading-6 text-gray-900">Ngày sinh </label>
                                <div className="mt-2">
                                    <ReactDatePicker
                                        id="birthday" name="birthday"
                                        placeholderText='Chọn ngày sinh của bạn...'
                                        shouldCloseOnSelect={true}
                                        showYearDropdown
                                        yearDropdownItemNumber={100}
                                        scrollableYearDropdown
                                        selected={inputData.birthday}
                                        onChange={(date) => handleDateChange(date)}
                                        locale="vi"
                                        dateFormat="dd/MM/yyyy"
                                        className='block w-full rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:sm:leading-6'
                                    />
                                </div>
                            </div>
                            <div className='col-span-2 md:col-span-1'>
                                <label htmlFor="email" className="block font-medium leading-6 text-gray-900">Email </label>
                                <div className="mt-2">
                                    <input id="email" name="email" placeholder='Nhập email của bạn...'
                                        value={inputData?.email}
                                        onInput={onChangeInput}
                                        {...register('email')}
                                        className="block w-full rounded-md border-0 py-3.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:sm:leading-6"></input>
                                </div>
                                <span className="text-xs font-medium text-red-500">
                                    {errors.email &&
                                        errors.email?.message}
                                </span>
                            </div>
                            <div className='col-span-2 md:col-span-1'>
                                <label htmlFor="social" className="block font-medium leading-6 text-gray-900">Bạn biết ChungVui qua kênh thông tin nào?</label>
                                <div className="mt-2">
                                    <select
                                        value={inputData.social}
                                        onChange={onChangeInput}
                                        id="social"
                                        name="social"
                                        className="block w-full rounded-md border-0 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:sm:leading-6">
                                        {defaultSocial.map(x => (
                                            <option value={x.id} key={x.id}>{x.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='col-span-2 w-full'>
                                <label htmlFor="priceRate" className="block font-medium leading-6 text-gray-900">Bạn thấy mức giá đối với các dịch vụ của ChungVui hiện nay có phù hợp không?</label>
                                <div className="mt-5">
                                    <div className="my-auto flex flex-col justify-center gap-2 md:flex-row md:gap-0">
                                        {
                                            defaultpriceRate.map(x => (
                                                <div className="inline-block min-h-[1.5rem] ps-[1.5rem] md:mb-[0.125rem] md:me-4" key={x.id}>
                                                    <input
                                                        className="border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                                                        type="radio"
                                                        name="priceRate"
                                                        id={'inlineRadio' + x.id}
                                                        checked={x.id === Number(inputData.priceRate)}
                                                        onChange={onChangeInput}
                                                        value={x.id} />
                                                    <label
                                                        className="ms-2 text-base text-gray-700"
                                                        htmlFor={'inlineRadio' + x.id}
                                                    >{x.name}</label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-2 w-full'>
                                <label htmlFor="rating" className="block font-medium leading-6 text-gray-900">Trên thang điểm 5, Bạn đánh giá chất lượng sản phẩm của ChungVui được: </label>
                                <div className="mx-auto mt-2 flex justify-center">
                                    {[...Array(totalStars)].map((star, index) => {
                                        const currentRating = index + 1;
                                        return (
                                            <label key={index}>
                                                <input
                                                    type="radio"
                                                    name="rating"
                                                    className='hidden'
                                                    value={currentRating}
                                                    onChange={() => { setRating(currentRating); setInputData({ ...inputData, rating: currentRating }); }}
                                                />
                                                <span
                                                    className="m-5 cursor-pointer text-4xl md:text-5xl"
                                                    style={{
                                                        color:
                                                            currentRating <= (hover || rating) ? '#ffc107' : '#e4e5e9'
                                                    }}
                                                    onMouseEnter={() => setHover(currentRating)}
                                                    onMouseLeave={() => setHover(null)}
                                                >
                                                    &#9733;
                                                </span>
                                            </label>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className='col-span-2 w-full'>
                                <label htmlFor="inviteOption" className="block font-medium leading-6 text-gray-900">Sau khi sử dụng, bạn có muốn giới thiệu ChungVui tới bạn bè và người thân không?</label>
                                <div className="mt-5">
                                    <div className="my-auto flex flex-col justify-center gap-2 md:flex-row md:gap-0">
                                        {
                                            defaultinvite.map(x => (
                                                <div className="inline-block min-h-[1.5rem] ps-[1.5rem] md:mb-[0.125rem] md:me-4" key={x.id}>
                                                    <input
                                                        className="border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                                                        type="radio"
                                                        name="inviteOption"
                                                        id={'inlineinviteOptionRadio' + x.id}
                                                        checked={x.id === Number(inputData.inviteOption)}
                                                        onChange={onChangeInput}
                                                        value={x.id} />
                                                    <label
                                                        className="ms-2 text-base text-gray-700"
                                                        htmlFor={'inlineinviteOptionRadio' + x.id}
                                                    >{x.name}</label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-2 w-full'>
                                <label htmlFor="improveOption" className="block font-medium leading-6 text-gray-900">Theo bạn thì chúng tôi cần làm thêm điều gì nữa để cải tiến chất lượng của ChungVui? </label>
                                <div className='mt-2'>
                                    <ReactQuill
                                        theme="snow"
                                        name='improveOption'
                                        // value={inputData.improveOption}
                                        onChange={onChangeQuillInput}
                                        rows={4}
                                        modules={{
                                            toolbar: [
                                                // [{ 'header': '1' }, { 'header': '2' }],
                                                [{ size: [] }],
                                                ['bold', 'italic', 'underline'],
                                            ],
                                        }}
                                        placeholder='Chúng tôi cần làm gì để đáp ứng mong muốn của bạn?'
                                        className='block w-full rounded-md border-0 pb-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:sm:leading-6'
                                    />
                                </div>
                            </div>
                            <div className='col-span-2 flex w-full justify-center'>
                                <button type='submit' className='mb-2 me-2 flex gap-3 rounded-lg bg-blue-700 px-7 py-4 text-lg font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>
                                    Gửi đánh giá <FaPaperPlane className='my-auto' />
                                </button>
                            </div>
                        </form>
                    </>
                }
                {
                    isSent &&
                    <>
                        <div>
                            <FaCheckCircle className='size-56 text-green-600' />
                        </div>
                        <div className='text-center text-4xl font-light text-black'>Hoàn thành đánh giá về <span className='text-blue-700'>ChungVui</span></div>
                        <div className='break-words text-center text-sm font-light md:max-w-[50dvw] md:text-lg'>Cảm ơn bạn rất nhiều vì đã dành thời gian đánh giá về nền tảng tạo thiệp ChungVui. Chúng tôi rất trân trọng ý kiến của bạn, và không ngừng cải thiện để ngày một tốt hơn!</div>
                        <div className='col-span-2 mb-[120px] flex w-full justify-center'>
                            <Link to={'../'} className='mb-2 me-2 flex cursor-pointer gap-3 rounded-lg bg-blue-600 px-7 py-3 text-lg font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300'>
                                <FaArrowLeft className='my-auto' /> Quay lại Trang chủ
                            </Link>
                        </div>
                    </>
                }
            </div>
            <ModalConfirm
                saveChanged={submitForm}
                isOpen={isOpenLeavinModal}
                confirmNavigation={() => {
                    setOpenLeavinModal(false);
                }}
                funToggle={() => {
                    setOpenLeavinModal(false);
                }}
            />
        </>
    );
};

export default FeedBack;