/* eslint-disable */
import { useEffect, useState } from 'react';
import { BsArrowReturnRight } from "react-icons/bs";
import { formatDatetime } from '../../../../../utils/Times';
import EventGreetingService from '../PublishGreeting.service';
import { FaRegFrown } from 'react-icons/fa';

const ItemHistory = ({ item, index, length }) => {
    return (
        <div className='relative'>
            <div className='absolute top-0 right-0.5'>
                {item.isPublic ?
                    <i className="fas fa-globe-americas text-[16px] text-sky-600" title='Công khai'></i>
                    :
                    <i className="fas fa-lock text-[15px] text-gray-600" title='Chỉ mình tôi'></i>
                }
            </div>
            <div className="self-stretch flex-col justify-start items-start gap-1 flex h-full">
                <div
                    className="self-stretch text-gray-900 text-lg font-semibold leading-tight mt-auto pr-5">
                    {item.guestName}
                </div>
                <div className="relative self-stretch min-h-16 bg-gray-200 p-1 rounded-md">
                    <span
                        className="text-gray-600 text-sm leading-snug break-words "
                    >
                        {item.greeting}
                    </span>
                    <p className='h-10'></p>
                    <span className="absolute bottom-1 right-1 text-gray-700 text-xs leading-none italic" >
                        Tạo lúc {formatDatetime(item.createdAt)}
                    </span>
                </div>
                {
                    item?.image &&
                    <div className=' mt-0.5 h-40'>
                        <img className='rounded-lg h-full'
                            src={process.env.REACT_APP_SERVER_URL + item.image} />
                    </div>
                }
                {
                    item?.reply &&
                    <div className="self-stretch mt-1 flex">
                        <span>
                            <BsArrowReturnRight className='font-black mr-1 text-xl' />
                        </span>
                        <span
                            className="bg-sky-100 px-2 py-1 rounded-md text-gray-700 text-sm leading-snug break-words " >
                            {item.reply}
                        </span>
                    </div>
                }
                {
                    index !== length - 1 &&
                    <div className='self-stretch border-t  border-gray-400 mt-2 mb-4 -mx-4'></div>
                }
            </div>
        </div>
    )
}
const ItemHistoryLoading = () => {
    return (
        <div className='relative mb-4'>
            <div className="self-stretch flex-col justify-start items-start gap-1 flex h-full">
                <div className="self-stretch h-4 text-gray-900 rounded-md text-lg font-semibold leading-tight mt-auto pr-5 bg-trueGray-300 animate-pulse"> </div>
                <div className="relative self-stretch h-20  p-1 rounded-md bg-trueGray-300 animate-pulse"> </div>
                <div className="relative self-stretch h-20 w-1/2 p-1 rounded-md bg-trueGray-300 animate-pulse"> </div>
            </div>
        </div>
    )
}

export default function ModelHistory({ data }) {

    const [greetingHistories, setGreetingHistories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (data?._id) {
            setIsLoading(true);
            EventGreetingService.getOne(data._id)
                .then((result) => {
                    setGreetingHistories(result.history);
                    // console.log("data getOne = ", result);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => setTimeout(() => {
                    setIsLoading(false);
                }, 200));
        }
    }, [data])

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="modalHistoryGreeting"
            // data-bs-backdrop="static"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-300 modal-header shrink-0 rounded-t-md">
                        <h5 className="text-[16px] font-bold leading-normal text-gray-800">
                            Lịch sử tạo lời chúc của khách mời
                        </h5>
                        <button
                            type="button"
                            title="Đóng"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex flex-col items-center justify-between w-full border-b border-gray-200 modal-content shrink-0 rounded-t-md p-[20px] gap-[10px] overflow-y-auto overflow-x-hidden h-[500px] md:h-fit md:min-h-[500px] md:max-h-[700px]">
                        <div className='w-full '>
                            {
                                isLoading
                                    ?
                                    [1, 2].map((item, index) => <ItemHistoryLoading key={index} />)
                                    :
                                    greetingHistories.length === 0
                                        ? <div className="flex items-center justify-center w-full">
                                            <h5 className="flex items-center py-20 text-center">
                                                <FaRegFrown className="mr-2" /> Chưa có lịch sử lời chúc nào!
                                            </h5>
                                        </div>
                                        :
                                        greetingHistories.map((item, index) =>
                                            <ItemHistory key={index} item={item} index={index} length={greetingHistories.length} />
                                        )
                            }
                        </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover:bg-gray-50 transition-all focus:bg-gray-50 active:bg-gray-50  inline-block rounded bg-white border-[1px] border-gray-300 px-5 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                        >
                            Đóng
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
