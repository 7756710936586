/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import guestManagerService from '../guestManager.service';
import { APPLY_FOR } from '../assets/KeyConstants';
import ToastNotify from '../../../../../utils/Toast';
import { formatDatetimelocalInput } from '../../../../../utils/Times';

export default function SetTimerSendMailModal({
    guests,
    eventId,
    guestId,
    refeshTable,
    isChangeTimer,
    setIsChangeTimer,
}) {
    const [timer, setTimer] = useState(0);
    const [applyFor, setApplyFor] = useState(APPLY_FOR.ONLY_USER);
    const [validTimer, setValidTimer] = useState('');
    const focusTimer = useRef(null);

    const handleSetTimer = async (e) => {
        if (validateTimer()) {
            e.preventDefault();
            const vnTime = `${timer}+07:00`;
            if (isChangeTimer.value) {
                guestManagerService
                    .changeTimer(eventId, guestId, vnTime, applyFor)
                    .then(() => {
                        ToastNotify.Success('Thay đổi thời gian hẹn giờ thành công.');
                    })
                    .catch((err) => {
                        ToastNotify.Error(err.message);
                    })
                    .finally(() => {
                        clearForm();
                        refeshTable(eventId);
                    });
            } else {
                guestManagerService
                    .sendInvition(eventId, [guestId], vnTime)
                    .then(() => {
                        ToastNotify.Success('Đã đặt hẹn giờ thành công.');
                    })
                    .catch((err) => {
                        ToastNotify.Error(err.message);
                    })
                    .finally(() => {
                        clearForm();
                        refeshTable(eventId);
                    });
            }
        }
    };

    const validateTimer = (isMessage = true, val = timer) => {
        let isValid = true;
        if (!val) {
            isMessage && setValidTimer('Vui lòng chọn ngày giờ!');
            isValid = false;
        } else {
            const timerNumber = new Date(val).getTime();
            const timeNowNumber = new Date().getTime();
            if (timerNumber <= timeNowNumber) {
                isMessage && setValidTimer('Hãy chọn thời gian sau thời gian hiện tại!');
                isValid = false;
            } else {
                isMessage && setValidTimer('');
            }
        }
        if (isMessage && !isValid) {
            if (focusTimer) {
                focusTimer.current.focus();
            }
        }
        return isValid;
    };

    const clearForm = () => {
        setTimer(0);
        setApplyFor(APPLY_FOR.ONLY_USER);
        setValidTimer('');
        setIsChangeTimer({ value: false });
    };

    useEffect(() => {
        if (isChangeTimer.value) {
            const guest = guests.find((item) => item._id === guestId);
            const guestTimer = guest?.status?.find(({ active }) => active === true)?.timer;
            guestTimer && setTimer(formatDatetimelocalInput(guestTimer));
        }
    }, [isChangeTimer]);

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="setTimerSendMailModal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-md modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            {isChangeTimer.value
                                ? 'Thay đổi hẹn giờ gửi email'
                                : 'Đặt hẹn giờ gửi email'}
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={clearForm}
                        ></button>
                    </div>
                    <div className="flex flex-col items-center p-4 modal-header shrink-0 rounded-t-md">
                        <div className="block w-full">
                            <span className="text-sm text-black font-normal">
                                Chọn ngày và giờ <span className="text-red-500">(*)</span>
                            </span>
                            <div className="flex w-full">
                                <div className="w-full">
                                    <input
                                        className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                        type="datetime-local"
                                        name="timer"
                                        min={new Date().toISOString().slice(0, 16)}
                                        onKeyDown={(e) => e.preventDefault()}
                                        value={timer}
                                        onInput={(e) => {
                                            setTimer(e.target.value);
                                            validateTimer(true, e.target.value);
                                        }}
                                        ref={focusTimer}
                                    />
                                    <span className="text-xs font-medium text-red-500">
                                        {validTimer && validTimer}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {isChangeTimer.value && (
                            <div className="block w-full mt-2">
                                <span className="text-sm text-black font-normal">Áp dụng cho:</span>
                                <div className="flex w-full">
                                    <div className="w-full">
                                        <select
                                            value={applyFor}
                                            name="applyFor"
                                            onChange={(e) => setApplyFor(e.target.value)}
                                            className="mt-1 rounded-[0.375rem] peer form-select w-full border border-gray-300 bg-white p-3 text-sm appearance-none font-normal  text-gray-700 transition  ease-in-out focus:outline-none"
                                        >
                                            <option
                                                key="1"
                                                value={APPLY_FOR.ONLY_USER}
                                                selected={applyFor === APPLY_FOR.ONLY_USER}
                                            >
                                                Chỉ khách mời này
                                            </option>
                                            <option
                                                key="2"
                                                value={APPLY_FOR.ALL_USER_WITH_TIMER_TOGETHER}
                                                selected={
                                                    applyFor ===
                                                    APPLY_FOR.ALL_USER_WITH_TIMER_TOGETHER
                                                }
                                            >
                                                Tất cả khách mời có cùng hẹn giờ
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                            onClick={clearForm}
                        >
                            Hủy
                        </button>
                        <button
                            type="button"
                            data-bs-dismiss={validateTimer(false) ? 'modal' : ''}
                            onClick={(e) => handleSetTimer(e)}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            Đặt {isChangeTimer.value && 'lại'} hẹn giờ
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
