/* eslint-disable */
import INPUT_ALIAS from '../../config/templates/input.templates';
import SubEvent1 from '../../resource/events/event-1/SubEvent1.page';
import SubEvent2 from '../../resource/events/event-2/SubEvent2.page';
import SubEvent3 from '../../resource/events/event-3/SubEvent3.page';
import SubEvent4 from '../../resource/events/event-4/SubEvent4.page';
import SubEvent5 from '../../resource/events/event-5/SubEvent5.page';
import SubEvent6 from '../../resource/events/event-6/SubEvent6.page';
import SubEvent7 from '../../resource/events/event-7/SubEvent7.page';
import SubEvent8 from '../../resource/events/event-8/SubEvent8.page';
import SubEvent9 from '../../resource/events/event-10/SubEvent10.page';
import SubEvent11 from '../../resource/events/event-11/SubEvent11.page';
import SubEvent12 from '../../resource/events/event-12/SubEvent12.page';
import SubEvent13 from '../../resource/events/event-13/SubEvent13.page';

import SubWedding1 from '../../resource/wedding/wedding-1/SubTemplate1';
import SubWedding2 from '../../resource/wedding/wedding-2/SubTemplate2';
import SubWedding3 from '../../resource/wedding/wedding-3/SubTemplate3';
import SubWedding6 from '../../resource/wedding/wedding-6/SubTemplate6';
import SubWedding5 from '../../resource/wedding/wedding-5/SubTemplate5';
import SubWedding8 from '../../resource/wedding/wedding-8/SubTemplate8';
import SubWedding7 from '../../resource/wedding/wedding-7/SubTemplate7';
import SubWedding9 from '../../resource/wedding/wedding-9/SubTemplate9';
import SubWedding10 from '../../resource/wedding/wedding-10/SubTemplate10';
import SubWedding12 from '../../resource/wedding/wedding-12/SubTemplate12';
import SubWedding13 from '../../resource/wedding/wedding-13/SubTemplate13';
import SubWedding14 from '../wedding/wedding-14/SubTemplate14.js';
import SubWedding15 from '../wedding/wedding-15/SubTemplate15.js';

const routerSubTemplates = {
    [INPUT_ALIAS.wedding_1]: SubWedding1,
    [INPUT_ALIAS.wedding_2]: SubWedding2,
    [INPUT_ALIAS.wedding_3]: SubWedding3,
    [INPUT_ALIAS.wedding_4]: SubWedding1,
    [INPUT_ALIAS.wedding_6]: SubWedding6,
    [INPUT_ALIAS.wedding_5]: SubWedding5,
    [INPUT_ALIAS.wedding_8]: SubWedding8,
    [INPUT_ALIAS.wedding_7]: SubWedding7,
    [INPUT_ALIAS.wedding_9]: SubWedding9,
    [INPUT_ALIAS.wedding_10]: SubWedding10,
    [INPUT_ALIAS.wedding_12]: SubWedding12,
    [INPUT_ALIAS.wedding_13]: SubWedding13,
    [INPUT_ALIAS.wedding_14]: SubWedding14,
    [INPUT_ALIAS.wedding_15]: SubWedding15,

    [INPUT_ALIAS.event_1]: SubEvent1,
    [INPUT_ALIAS.event_2]: SubEvent2,
    [INPUT_ALIAS.event_3]: SubEvent3,
    [INPUT_ALIAS.event_4]: SubEvent4,
    [INPUT_ALIAS.event_5]: SubEvent5,
    [INPUT_ALIAS.event_6]: SubEvent6,
    [INPUT_ALIAS.event_7]: SubEvent7,
    [INPUT_ALIAS.event_8]: SubEvent8,
    [INPUT_ALIAS.event_9]: SubEvent9,
    [INPUT_ALIAS.event_11]: SubEvent11,
    [INPUT_ALIAS.event_12]: SubEvent12,
    [INPUT_ALIAS.event_13]: SubEvent13,
};

export const MySubTemplate = ({ alias, props }) => {
    // console.log(props);
    if (!alias) {
        return <></>;
    }
    const Component = routerSubTemplates[alias];
    if (Component) {
        return <Component {...props} />;
    }
    return <>Không có dữ liệu</>;
};

export default routerSubTemplates;
