/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { getDateOfMonth, getDateOfWeek, getMonthOfYear, getYear } from '../../../utils/Times';

import IMAGES_EVENT from '../assets/_index';
import { QRCodeSVG } from 'qrcode.react';
import IMAGES_EVENT9 from './assets/images';
import envConstants from '../../../config/key';

export default function SubEvent9({
    data,
    guestTitle,
    guestFullname,
    salutation,
    refFrame,
    urlShare,
}) {
    // console.log(data)
    const [pathImgLogo, setPathImgLogo] = useState('');
    const checkIsImageValid = useCallback((str) => (
            !/^blob:/.test(str) && !/^data:image/.test(str) && !/^\/static\/media/.test(str) && str
        ), []);

    useEffect(() => {
        if (checkIsImageValid(data?.logo)) {
            setPathImgLogo(process.env.REACT_APP_SERVER_URL + data?.logo?.[0]);
        } else if (data?.logo) {
            setPathImgLogo(data?.logo?.[0]);
        } else {
            // setPathImgLogo(IMAGES_EVENT.LOGO_SUNSHINE_GARDEN)
        }
    }, [checkIsImageValid, data?.logo]);

    return (
        <>
            <div
                tabIndex="0"
                ref={refFrame}
                className="relative flex h-full flex-col items-center justify-center rounded-lg border-0 shadow-lg outline-none focus:outline-none "
            >
                <div
                    style={{
                        backgroundImage:
                            data.imageSlides.length != 0
                                ? `url(${envConstants.SERVER_URL + data.imageSlides[0]})`
                                : `url(${IMAGES_EVENT9.bg4})`,
                    }}
                    className="relative flex h-full w-full items-center justify-center bg-cover"
                >
                    <div
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                        className="mx-auto h-[100%]  w-[100%] border-8 pb-5"
                    >
                        {/* logo */}

                        <div className="mx-auto mt-10">
                            {pathImgLogo && (
                                <img
                                    className="mx-auto h-[100px] md:h-[150px]"
                                    src={pathImgLogo}
                                    loading="lazy"
                                    alt="logo"
                                />
                            )}
                        </div>
                        <div className="flex w-full justify-center ">
                            <p className="mt-5 max-w-[80%] text-center text-[calc(0.5rem_+_1.5vh)] font-bold uppercase leading-[calc(1rem_+_1.5vh)] text-white md:text-[calc(0.5rem_+_2vh)] md:leading-[calc(1rem_+_2vh)]">
                                {data.groupName && data.groupName}
                            </p>
                        </div>
                        {/* title */}
                        <div className="mx-auto mt-5 w-3/4 text-center font-crimson text-amber-500">
                            <span className="text-4xl font-extrabold">
                                {data.titleEvent ? data.titleEvent : 'Thiệp Điện Tử ChungVui'}
                            </span>
                        </div>

                        <div className="mx-auto my-5 w-2/3 border-y border-gray-300">
                            <div className="mx-auto mb-3 mt-5 w-full">
                                <p className="font-Comfortaa text-center text-lg font-extrabold uppercase text-white">
                                    {salutation || 'Trân trọng kính mời'}
                                </p>
                            </div>
                            <div className="font-Comfortaa mx-auto mb-5 mt-3 text-center text-lg text-white">
                                <p className="break-words font-crimson text-[calc(0.7rem_+_2vh)] font-bold leading-[calc(1rem_+_2vh)] text-amber-500 md:text-[calc(0.3rem_+_3vh)] md:leading-[calc(1rem_+_3vh)]">
                                    {guestFullname?.trim()
                                        ? (guestTitle ? guestTitle?.trim() : '') +
                                          ' ' +
                                          guestFullname.trim()
                                        : '............................'}
                                </p>
                            </div>
                        </div>

                        {/* loi moi */}

                        <div className="mx-auto flex w-3/4 justify-center ">
                            <div className="mx-auto flex-row text-center">
                                <p className="text-xl font-semibold text-white">
                                    {data.invitation ? data.invitation : 'Tới tham dự'}
                                </p>
                                <p className="my-5 font-greatVibes text-[calc(1.5rem_+_2vh)] font-semibold leading-[calc(1.5rem_+_2vh)] text-amber-600 md:text-[calc(2.5rem_+_2vh)] md:leading-[calc(2.5rem_+_2vh)]">
                                    {data.nameEvent ? data.nameEvent : 'LỄ KHAI TRƯƠNG'}
                                </p>
                            </div>
                        </div>

                        {/* Thoi gian */}
                        <div className="mx-auto mt-4 w-2/3 flex-row justify-center">
                            <div className="relative flex items-center text-xl ">
                                <div className="mt-3 grow border-b border-gray-400"></div>
                                <span className="mx-1 shrink font-semibold text-white">
                                    Thời gian
                                </span>
                                <div className="mt-3 grow border-b border-gray-400"></div>
                            </div>

                            <div className="mt-1 text-center">
                                <span className="mt-3 font-crimson text-[calc(1rem_+_1vh)] text-lg font-bold text-amber-500">
                                    {data.date
                                        ? getDateOfWeek(data.date)
                                        : getDateOfWeek(new Date())}
                                    , ngày{' '}
                                    {data.date
                                        ? getDateOfMonth(data.date)
                                        : getDateOfMonth(new Date())}{' '}
                                    tháng{' '}
                                    {data.date
                                        ? getMonthOfYear(data.date)
                                        : getMonthOfYear(new Date())}{' '}
                                    năm {data.date ? getYear(data.date) : getYear(new Date())}
                                </span>
                            </div>
                            {/* Dia diem */}

                            <div className="relative mt-5 flex items-center text-xl">
                                <div className="mt-3 grow border-b border-gray-400"></div>
                                <span className="mx-1 shrink font-semibold text-white">
                                    Địa điểm
                                </span>
                                <div className="mt-3 grow border-b border-gray-400"></div>
                            </div>

                            <div className="mx-auto mt-3 flex-row  text-center text-lg font-bold text-[#000B29]">
                                <p className="font-crimson text-[calc(1rem_+_1vh)] text-amber-500">
                                    {data.placeEvent ? data.placeEvent : 'Tầng 2 Trống Đồng Palace'}
                                </p>
                                <p className="font-crimson text-amber-500">
                                    {data.addressEvent
                                        ? data.addressEvent
                                        : 'Số 489 Hoàng Quốc Việt, Từ Liêm, Hà Nội'}
                                </p>
                            </div>

                            <div className="mt-5 grow border-t border-gray-400"></div>
                        </div>

                        {/* Thay mat ban lien lac*/}
                        {(data?.phoneSupporter || data?.nameSupporter) && (
                            <div className="mx-auto text-center">
                                <p className="mt-2 text-xl font-extrabold text-white">
                                    LIÊN HỆ HỖ TRỢ
                                </p>

                                <div className="flex justify-evenly">
                                    {data?.nameSupporter && (
                                        <p className={'mt-4 font-bold  text-white  '}>
                                            <i className="text-md fas fa-user-alt mr-1 text-white "></i>{' '}
                                            {data?.nameSupporter}
                                        </p>
                                    )}
                                    {data?.phoneSupporter && (
                                        <a href={`tel:${data?.phoneSupporter}`} className="">
                                            <p
                                                className={
                                                    'mt-4 font-bold  text-white hover:text-white '
                                                }
                                            >
                                                <i className="fas fa-phone-alt text-md ml-5 mr-1.5 text-white"></i>
                                                {data?.phoneSupporter}
                                            </p>
                                        </a>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {/** QR code */}
                <div className="absolute right-10 top-10 w-[15%] rounded-md bg-gray-700 p-1.5">
                    {urlShare && (
                        <div className="rounded-md bg-white p-3">
                            <QRCodeSVG
                                value={urlShare}
                                bgColor={'#ffffff'}
                                fgColor={'#EDB83D'}
                                className="h-1/2 w-full"
                            />
                        </div>
                    )}
                    <div className="mt-2 flex">
                        <div className="w-full text-center text-white">
                            <i className="fas fa-camera mr-1 text-lg"></i>{' '}
                            <span className="text-sm">Xem chi tiết</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
