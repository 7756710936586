/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import { Cropper } from 'react-mobile-cropper';
import IMAGES from '../../../../../assets/images/_index';
import BaseModal from '../../../../../components/BaseModal';
import PublishBrideService from '../PublishBride.service';
import { validateSizeOfFile } from '../../../../../utils/common';
import ToastNotify from '../../../../../utils/Toast';
import LoadingButtonIcon from '../../../../../components/LoadingButton/LoadingButtonIcon';
import ModalLeavingPage from '../../../../events/components/ModalLeavingPage';
import EventsService from '../../../../events/Events.service';
import { FaPen, FaSave } from 'react-icons/fa';

export const TYPE_NAME = {
    Groom: {
        name: 'avatarGroom',
    },
    Bride: {
        name: 'avatarBride',
    },
};

const CropperModal = ({ defaultsrc, eventId, load, setLoad, setOpen, name, tempImage }) => {
    const cropperRef = useRef(null);
    const [image, setImage] = useState(defaultsrc ? defaultsrc : tempImage);
    const [isOpenModal, setOpenModal] = useState(false);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const toastLoadingId = useRef(null);

    const submitHandle = async () => {
        setIsLoadingBtn(true);
        const file = await PublishBrideService.createFile(
            cropperRef.current.getCanvas()?.toDataURL('image/jpeg', 1),
            'img_' + name
        );
        if (
            validateSizeOfFile(
                file,
                10,
                'Dung lượng ảnh sau khi chỉnh sửa không được vượt quá 10Mb'
            )
        ) {
            setOpenModal(true);
        }
        setIsLoadingBtn(false);
    };
    const changeFile = (event) => {
        const val = event.target.files[0];
        event.target.value = '';
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setImage(image);
        });
        reader.readAsDataURL(val);
    };
    const submitImageForm = async () => {
        setIsLoadingBtn(true);
        toastLoadingId.current = ToastNotify.Loading('Đang lưu ảnh...');
        const file = await PublishBrideService.createFile(
            cropperRef.current.getCanvas()?.toDataURL(),
            'img_' + name
        );
        let index = null;
        let keyChild = null;
        PublishBrideService.saveFiles(eventId, name, file, index, keyChild)
            .then(() => {ToastNotify.Update(toastLoadingId.current, 'Lưu ảnh thành công!'); setIsLoadingBtn(false);})
            .catch((error) =>
                {
                    ToastNotify.Update(
                        toastLoadingId.current,
                        error?.message || 'Đã có lỗi xảy ra khi lưu ảnh!',
                        'error'
                    );
                    setIsLoadingBtn(false);
                }
            )
            .finally(() => {
                setLoad(!load);
                setOpenModal(false);
                setOpen(false);
                toastLoadingId.current = null;
            });
    };
    return (
        <div className="mx-1.5 appearance-none justify-center rounded-md border-gray-300 bg-white px-2 pt-2 transition  hover:border-gray-400 focus:outline-none">
            <Cropper
                stencilProps={{
                    aspectRatio: 1,
                }}
                ref={cropperRef}
                style={{ height: '500px', width: '500px' }}
                className="cropper h-[500px]"
                src={image}
            />
            <div className="m-2 flex justify-center gap-2">
                <label className="flex gap-[6px] items-center rounded-lg bg-blue-100 px-[16px] py-[8px] text-[16px] font-semibold text-blue-700 hover:bg-blue-200 cursor-pointer">
                    <input
                        className="hidden"
                        onChange={(event) => changeFile(event)}
                        type="file"
                        accept="image/*"
                    />
                    <FaPen /> Thay đổi
                </label>
                <button
                    className={`${isLoadingBtn ? 'bg-blue-500' : 'bg-blue-600 hover:bg-blue-700 '
                        } flex gap-[6px] items-center rounded-lg  px-[16px] py-[8px] text-[16px] font-semibold text-white `}
                    onClick={() => submitHandle('avatarBride')}
                    disabled={isLoadingBtn}
                >
                    {isLoadingBtn && <LoadingButtonIcon />}
                    {!isLoadingBtn && <FaSave />}
                    Lưu
                </button>
                <ModalLeavingPage
                    saveChanged={submitImageForm}
                    isOpen={isOpenModal}
                    confirmNavigation={() => {
                        setOpenModal(false);
                    }}
                    funToggle={() => {
                        setOpenModal(false);
                    }}
                />
            </div>
        </div>
    );
};

export default function AvatarUploader({ type = TYPE_NAME.Bride, eventId, isExpired }) {
    const [openModal, setOpenModal] = useState(false);
    const [imageToCrop, setImageToCrop] = useState(null);
    const [load, setLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const changeFile = (event) => {
        const val = event.target.files[0];
        event.target.value = '';
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setImageToCrop(image);
            setOpenModal(true);
        });
        reader.readAsDataURL(val);
    };

    useEffect(() => {
        setIsLoading(true);
        EventsService.getOneEventById(eventId)
            .then(({ data }) => {
                // const imageSrc = (type === 'bride' ? data.form_data.avatarBride : data.form_data.avatarGroom);
                const imageSrc = data.form_data[type.name];
                if (imageSrc && imageSrc.length > 0) {
                    setImageToCrop(process.env.REACT_APP_SERVER_URL + imageSrc[0]);
                } else {
                    setImageToCrop(null);
                }
            })
            .finally(() => setIsLoading(false));
    }, [load]);

    return (
        <>
            <div className="relative flex flex-col items-center justify-start gap-2 self-stretch rounded-t-lg bg-gray-300 ">
                <div className="group relative mx-auto h-60 w-full rounded-t-lg">
                    {isLoading ? (
                        <div className="mx-auto h-60 w-full rounded-t-lg animate-pulse"> </div>
                    ) : (
                        <>
                            <img
                                src={imageToCrop ?? (type.name === TYPE_NAME.Bride.name ? IMAGES.CO_DAU_4 : IMAGES.CHU_RE_4)}
                                className="mx-auto h-60 w-full rounded-t-lg object-cover"
                                alt="Avatar"
                            />
                            {
                                !imageToCrop &&
                                <label className="absolute -top-0 flex h-60 w-full flex-col items-center justify-center bg-black/30 opacity-0 backdrop-blur-sm duration-500 ease-out group-hover:opacity-100 cursor-pointer rounded-t-lg">
                                    <span className="fas fa-edit text-3xl text-slate-100"></span>
                                    <input
                                        className="hidden"
                                        disabled={isExpired}
                                        onChange={(event) => changeFile(event)}
                                        type="file"
                                        accept="image/*"
                                    />
                                </label>
                            }
                            {
                                imageToCrop &&
                                <div
                                    className="absolute -top-0 flex h-60 w-full flex-col items-center justify-center bg-black/30 opacity-0 backdrop-blur-sm duration-500 ease-out group-hover:opacity-100 cursor-pointer rounded-t-lg"
                                    onClick={() => {
                                        !isExpired && setOpenModal(true);
                                    }}
                                >
                                    <span className="fas fa-edit text-3xl text-slate-100"></span>
                                </div>
                            }
                        </>
                    )}
                </div>
                <div className="absolute bottom-0 flex h-20 w-full bg-gradient-to-b from-transparent via-slate-100 to-white ">
                    <p className="absolute bottom-0 my-auto mb-4 ml-2 flex justify-between gap-5 text-xl font-semibold text-black"></p>
                </div>
            </div>
            <BaseModal
                isOpen={openModal}
                onClose={() => {
                    setOpenModal(false);
                    setLoad(!load);
                }}
                modalTitle={"Chỉnh sửa ảnh " + (type === TYPE_NAME.Bride ? 'cô dâu':'')+ (type === TYPE_NAME.Groom ? 'chú rể': '')}
                width="max-w-[700px]"
            >
                {!imageToCrop && (
                    <>
                        <label className="mt-1.5 flex h-[500px] w-full cursor-pointer appearance-none justify-center border-2 border-dashed rounded-md border-gray-300 bg-white px-4 transition hover:border-gray-400 focus:outline-none">
                            <span className="flex items-center space-x-2 py-10">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-gray-600"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                    />
                                </svg>
                                <span className="text-sm font-bold text-gray-700">
                                    Tải ảnh lên (Giới hạn 10Mb)
                                </span>
                            </span>
                            <input
                                className="hidden"
                                onChange={(event) => changeFile(event)}
                                type="file"
                                accept="image/*"
                            />
                        </label>
                    </>
                )}
                {imageToCrop && (
                    <CropperModal
                        setOpen={setOpenModal}
                        defaultsrc={imageToCrop}
                        eventId={eventId}
                        load={load}
                        setLoad={setLoad}
                        name={type.name}
                    />
                )}
            </BaseModal>
        </>
    );
}
