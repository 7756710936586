/* eslint-disable */
import { Link } from 'react-router-dom';
import PAGE from '../config/path.route';
import envConstants from '../config/key';

export const NavbarLoggedInRoute = [
    {
        path: PAGE.dashboard,
        content: 'Trang chủ',
        element: Link,
    },
    {
        path: '/loai-thiep/tat-ca',
        content: 'Mẫu thiệp',
        element: Link,
    },
    {
        path: PAGE.userEvents,
        content: 'Thiệp của tôi',
        element: Link,
    },
    {
        path: PAGE.pricing,
        content: 'Bảng giá',
        element: Link,
    },
    {
        path: PAGE.profile,
        content: 'Quản lý gói dịch vụ',
        element: Link,
        state: { isResourcePackage: true },
    },
    {
        path: envConstants.BLOG_URL,
        content: <>Bài viết</>,
    },
];
const NavbarRoute = [
    {
        path: PAGE.dashboard,
        content: 'Trang chủ',
        element: Link,
    },
    {
        path: '/loai-thiep/tat-ca',
        isProtect: false,
        content: 'Mẫu thiệp',
        element: Link,
    },
    {
        path: PAGE.help,
        isProtect: false,
        content: 'Trung tâm trợ giúp',
        element: Link,
    },
    {
        path: PAGE.feature,
        isProtect: false,
        content: 'Giới thiệu',
        element: Link,
    },
    {
        path: PAGE.pricing,
        isProtect: false,
        content: 'Bảng giá',
        element: Link,
    },
    // {
    //     path: PAGE.userEvents,
    //     isProtect: true,
    //     content: 'Thiệp của tôi',
    //     element: Link,
    // },
    {
        path: envConstants.BLOG_URL,
        // path: '/',
        isProtect: false,
        content: (
            <>
                Bài viết
                {/* <span className="ml-2 rounded-full bg-sky-400/10 px-2 py-0.5 text-xs font-medium leading-5 text-sky-600  ">
                    New
                </span> */}
            </>
        ),
        // element: Link,
    },
];
export default NavbarRoute;
