
/* eslint-disable */
export default function PublishMainMenuLoading() {


    return (
        <>
            <div className="px-4 max-w-6xl mx-auto justify-start items-start flex flex-col relative">
                <div className="mt-3 rounded-md h-14 w-full md:w-[25rem] bg-trueGray-300 animate-pulse"></div>
                <div className="mt-2 rounded-md h-6 w-44 bg-trueGray-300 animate-pulse"></div>
                <div className="mt-2 flex lg:flex-row flex-col w-full gap-4">
                    <div className="lg:w-2/3 w-full h-56 rounded-md  bg-trueGray-300 animate-pulse"> </div>
                    <div className="lg:w-1/3 w-full h-56 rounded-md bg-trueGray-300 animate-pulse"> </div>
                </div>
                <div className="mt-6 grid grid-cols-1 w-full md:grid-cols-2 lg:grid-cols-3 flex-col gap-4">
                    <div className="rounded-md  h-32  bg-trueGray-300 animate-pulse"></div>
                    <div className="rounded-md  h-32  bg-trueGray-300 animate-pulse"></div>
                    <div className="rounded-md  h-32  bg-trueGray-300 animate-pulse"></div>
                    <div className="rounded-md  h-32  bg-trueGray-300 animate-pulse"></div>
                    <div className="rounded-md  h-32  bg-trueGray-300 animate-pulse"></div>
                    <div className="rounded-md  h-32  bg-trueGray-300 animate-pulse"></div>
                    <div className="rounded-md  h-32  bg-trueGray-300 animate-pulse"></div>
                    <div className="rounded-md  h-32  bg-trueGray-300 animate-pulse"></div>
                    <div className="rounded-md  h-32  bg-trueGray-300 animate-pulse"></div>
                </div>
            </div>
        </>
    );
}