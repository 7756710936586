/* eslint-disable */
import { logIn, authProviderLogIn, signUp, changePassword, setPassword, verifyEmail, guestSignUp } from '../api/auth.api'
import { USER_ROLE } from '../config/constant/common.constant'
import storageConstant from '../config/constant/storage.constant'

import Cookies from 'js-cookie'

const authService = {
    handleLoginIn: async (email, password, tokenCaptcha) => {
        email = email.trim()
        password = password.trim()
		const guestUser = localStorage.getItem(storageConstant.GUEST_USER);
		let guestId = null;
		if (guestUser) {
			guestId = JSON.parse(guestUser)?._id;
		}
        const { data } = await logIn(email, password, guestId, tokenCaptcha)


        if (data.code === 200) {
            const { user, accessToken, refreshToken } = data.data
			if (user?.roles?.includes(USER_ROLE.GUEST)) {
				localStorage.setItem(storageConstant.GUEST_USER, JSON.stringify(user))
			}
            else {
				localStorage.setItem(storageConstant.USER, JSON.stringify(user))
			}
            let date = new Date()
            date.setTime(date.getTime() + 3600 * 1000)

            Cookies.set(storageConstant.ACCESS_TOKEN, accessToken, { expires: date })
            Cookies.set(storageConstant.REFRESH_TOKEN, refreshToken, { expires: 1 }) // 1 day
            return user
        } else {
            const error = new Error()
            error.statusCode = data.code
            error.errorMessage = data.message
            return Promise.reject(error) // 401: MK sai, 404: TK ko ton tai
        }
    },

	handleAuthProviderLogin: async (authProvider, loginData) => {
		const guestUser = localStorage.getItem(storageConstant.GUEST_USER);
		let guestId = null;
		if (guestUser) {
			guestId = JSON.parse(guestUser)?._id;
		}
        const { data } = await authProviderLogIn(authProvider, loginData, guestId)


        if (data.code === 200) {
            const { user, accessToken, refreshToken } = data.data
            localStorage.setItem(storageConstant.USER, JSON.stringify(user))

            let date = new Date()
            date.setTime(date.getTime() + 3600 * 1000)

            Cookies.set(storageConstant.ACCESS_TOKEN, accessToken, { expires: date })
            Cookies.set(storageConstant.REFRESH_TOKEN, refreshToken, { expires: 1 }) // 1 day
            return user
        } else {
            const error = new Error()
            error.statusCode = data.code
            error.errorMessage = data.message
            return Promise.reject(error)
        }
	},

    handleSignUp: async (email, username, password, fullname, phone) => {
		email = email.trim()
        username = username.trim()
        fullname = fullname.trim()
        password = password.trim()
        phone = phone.trim()
        const { data } = await signUp(email, username, password, fullname, phone)

        if (data.code === 200) {
            const user = data.data
            localStorage.setItem(storageConstant.USER, JSON.stringify(user))
            return user
        } else {
            const error = new Error()
            error.statusCode = data.code
            error.errorMessage = data.message
			error.data = data.data
            return Promise.reject(error) // 400: Ten dang nhap da ton tai
        }
    },

    handleGuestSignUp: async () => {
        const { data } = await guestSignUp()


        if (data.code === 200) {
            const { user, accessToken, refreshToken } = data.data
            localStorage.setItem(storageConstant.GUEST_USER, JSON.stringify(user))

            let date = new Date()
            date.setTime(date.getTime() + 3600 * 1000)

            Cookies.set(storageConstant.ACCESS_TOKEN, accessToken, { expires: date })
            Cookies.set(storageConstant.REFRESH_TOKEN, refreshToken, { expires: 1 }) // 1 day
            return user
        } else {
            const error = new Error()
            error.statusCode = data.code
            error.errorMessage = data.message
            return Promise.reject(error) // 401: MK sai, 404: TK ko ton tai
        }
    },

    handleChangePassword: async (old_password, new_password) => {
        return changePassword({ oldPassword: old_password, newPassword: new_password })
            .then(({ data }) => {
                const { accessToken, refreshToken } = data.data

                let date = new Date()
                Cookies.set(storageConstant.ACCESS_TOKEN, accessToken, { expires: date.getTime() })
                Cookies.set(storageConstant.REFRESH_TOKEN, refreshToken, { expires: 1 })
                return Promise.resolve()
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    },
	handleSetPassword: async (password) => {
		return setPassword({ password })
		.then(({ data }) => {
			const { accessToken, refreshToken } = data.data

			let date = new Date()
			Cookies.set(storageConstant.ACCESS_TOKEN, accessToken, { expires: date.getTime() })
			Cookies.set(storageConstant.REFRESH_TOKEN, refreshToken, { expires: 1 })
			return Promise.resolve()
		})
		.catch((err) => {
			return Promise.reject(err)
		})
	},
	handleVerifyEmail: async () => {
		return verifyEmail()
		.then(() => {
			return Promise.resolve()
		})
		.catch((err) => {
			return Promise.reject(err)
		})
	}
}
export default authService
