/* eslint-disable */
import React from 'react';
import { FaArrowLeft, FaCheck } from 'react-icons/fa';
import PublicImg from '../assets/images/public.webp';

export default function UpgradeModal({ message, handlePublic }) {
    return (
        <div
            className={
                'fade modal fixed left-0 top-0 hidden size-full overflow-y-auto overflow-x-hidden outline-none'
            }
            id="upgradepublicModal"
            tabIndex="-1"
            aria-modal="true"
            role="dialog"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered pointer-events-none relative w-auto">
                <div className="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
                    <div className="modal-header flex shrink-0 flex-col items-center justify-between rounded-t-md  p-4 px-10">
                        <img src={PublicImg} className="w-[200px]" />
                        <h5
                            className={`text-xl leading-normal text-blue-500 mt-5 w-full text-center font-bold`}
                        >
                            Thông báo
                        </h5>
                        <p className="text-center mt-5 text-black font-semibold">{message}</p>
                    </div>
                    <div className="modal-footer flex shrink-0 flex-wrap items-center justify-center rounded-b-md p-4 pt-0">
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            className="ml-1 flex items-center gap-2 rounded bg-gray-200 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-300 active:shadow-lg"
                        >
                            <FaArrowLeft /> Trở lại
                        </button>
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={handlePublic}
                            className={`ml-1 flex items-center gap-2 rounded bg-blue-500 hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700  px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out  focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg`}
                        >
                            <FaCheck /> Xác nhận
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
