/* eslint-disable */
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import Regex from '../../../../../utils/Regex';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

export default function ModalInfo({ rootData, data, setSelectParty, submitForm, clearForm }) {
    // console.log(data)
    const INIT_FORM_STATE = {
        id: '',
        name: '',
        time: '',
        date: '',
        place: '',
        address: '',
        linkGgMap: '',
    };

    const [checkValid, setCheckValid] = useState(false);
    const formik = useFormik({
        initialValues: INIT_FORM_STATE,
        validationSchema: Yup.object({
            name: Yup.string()
                .trim()
                .required('Vui lòng nhập tên chương trình!')
                .max(100, 'Tên không vượt quá 100 ký tự!'),
            time: Yup.string().trim().required('Vui lòng chọn thời gian!'),
            date: Yup.string().trim().required('Vui lòng chọn ngày!'),
            place: Yup.string().trim(),
            address: Yup.string().trim(),
            linkGgMap: Yup.string().trim().matches(Regex.url, 'Đường dẫn không hợp lệ!'),
        }),
        onSubmit: () => {
            setCheckValid(false);
            setSelectParty(formik.values);
            submitForm(formik.values);
        },
    });
    useEffect(() => {
        formik.setValues({ ...data, place: data?.place ?? rootData?.placeCeremony, address: data?.address ?? rootData?.addressCeremony, linkGgMap: data?.linkGgMap ?? rootData?.linkGgMap });
    }, [data]);

    const handleClear = () => {
        setCheckValid(false);
        setSelectParty({});
        formik.setValues(INIT_FORM_STATE);
    };

    const handleDateChange = (date) => {
        formik.setFieldValue('date', date ? moment(date).format('YYYY-MM-DD') : '');
    };

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="modalContent"
            data-bs-backdrop="static"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-300 modal-header shrink-0 rounded-t-md">
                        <h5 className="text-[16px] font-bold leading-normal text-gray-800">
                            {data?.name ? 'Chỉnh sửa chương trình' : 'Thêm mới chương trình'}
                        </h5>
                        <button
                            type="button"
                            title="Đóng"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClear}
                        ></button>
                    </div>
                    <div className="flex flex-col items-center justify-between w-full border-b border-gray-200 modal-content shrink-0 rounded-t-md p-[20px] gap-[10px]">
                        <div className="flex flex-col w-full gap-[10px]">
                            <p className="font-bold text-[14px] text-black">
                                Tên chương trình <span className="text-red-500">(*)</span>
                            </p>
                            <input
                                name="name"
                                value={formik.values?.name || ''}
                                onChange={formik.handleChange}
                                type="text"
                                placeholder={`VD: "Lễ ăn hỏi"`}
                                className={` form-control m-0 block w-[100%] rounded border border-solid ${checkValid && formik?.errors.name
                                        ? 'border-red-500'
                                        : 'border-gray-300'
                                    } bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                            />
                            {checkValid && (
                                <p className="text-sm text-red-500">{formik?.errors.name}</p>
                            )}
                        </div>
                        <div className="flex flex-col w-full gap-[10px]">
                            <p className="font-bold text-[14px] text-black">
                                Thời gian <span className="text-red-500">(*)</span>
                            </p>
                            <div className="flex w-full">
                                <div className="flex flex-col w-1/2">
                                    <input
                                        name="time"
                                        value={formik.values?.time || ''}
                                        onChange={formik.handleChange}
                                        type="time"
                                        className={` form-control m-0 block w-[100%] rounded border border-solid ${checkValid && formik?.errors.time
                                                ? 'border-red-500'
                                                : 'border-gray-300'
                                            } bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                                    />

                                    {checkValid && (
                                        <span className="mt-2 text-sm text-red-500">
                                            {formik?.errors.time}
                                        </span>
                                    )}
                                </div>
                                <div className="flex flex-col w-1/2">
                                    {/* <input
                                        name="date"
                                        value={formik.values?.date || ''}
                                        onChange={formik.handleChange}
                                        type="date"
                                        className={` form-control ml-1 block w-[100%] rounded border border-solid ${
                                            checkValid && formik?.errors.date
                                                ? 'border-red-500'
                                                : 'border-gray-300'
                                        } bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                                    /> */}
                                    <DatePicker
                                        name="date"
                                        selected={formik.values?.date || ''}
                                        onChange={(date) => handleDateChange(date)}
                                        locale="vi"
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText={'dd/mm/yyyy'}
                                        className={` form-control ml-1 block w-[100%] rounded border border-solid ${checkValid && formik?.errors.date
                                                ? 'border-red-500'
                                                : 'border-gray-300'
                                            } bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                                    />
                                    {checkValid && (
                                        <span className="mt-2 ml-1 text-sm text-red-500">
                                            {formik?.errors.date}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-full gap-[10px]">
                            <p className="font-bold text-[14px] text-black">Địa điểm</p>
                            <input
                                name="place"
                                value={formik.values?.place || (rootData?.placeCeremony ?? '')}
                                onChange={formik.handleChange}
                                type="text"
                                placeholder={`VD: "Tư gia nhà trai"`}
                                className={` form-control m-0 block w-[100%] rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                            />
                        </div>
                        <div className="flex flex-col w-full gap-[10px]">
                            <p className="font-bold text-[14px] text-black">Địa chỉ</p>
                            <input
                                name="address"
                                value={formik.values?.address || (rootData?.addressCeremony ?? '')}
                                onChange={formik.handleChange}
                                type="text"
                                placeholder={`VD: "Nhân Chính, Thanh Xuân, Hà Nội"`}
                                className={` form-control m-0 block w-[100%] rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                            />
                        </div>
                        <div className="flex flex-col w-full gap-[10px]">
                            <p className="font-bold text-[14px] text-black">Đường dẫn bản đồ</p>
                            <input
                                name="linkGgMap"
                                value={formik.values?.linkGgMap || (rootData?.linkGgMap ?? '')}
                                onChange={formik.handleChange}
                                type="text"
                                placeholder={`VD: "https://maps.app.goo.gl/V7iKpMMo5sjrTiRr9"`}
                                className={` form-control m-0 block w-[100%] rounded border border-solid ${checkValid && formik?.errors.linkGgMap
                                        ? 'border-red-500'
                                        : 'border-gray-300'
                                    } bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                            />
                            {checkValid && (
                                <p className="text-sm text-red-500">{formik?.errors.linkGgMap}</p>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover:bg-gray-50 transition-all focus:bg-gray-50 active:bg-gray-50  inline-block rounded bg-white border-[1px] border-gray-300 px-5 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                            onClick={handleClear}
                        >
                            Thoát
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                setCheckValid(true);
                                formik.handleSubmit();
                            }}
                            data-bs-dismiss={Object.keys(formik.errors).length === 0 && 'modal'}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg  focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                        >
                            Lưu
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
