/* eslint-disable */
import EventConfigAPI from '../../../../api/event.config.api';
const EventConfigService = {
    findEventConfig: async (eventID) => {
        return await EventConfigAPI.findEventConfig(eventID)
            .then(({ data }) => {
                return Promise.resolve(data.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    },

    postEventConfig: async (eventID, dataConfig) => {
        return await EventConfigAPI.postEventConfig(eventID, dataConfig)
            .then(({ data }) => {
                return Promise.resolve(data.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    },
};

export default EventConfigService;
