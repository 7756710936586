import PublishMainMenu from './PublishMainMenu.page';
import PublishAlbum from './publish.mainmenu.detail/publish.album/PublishAlbum.page';
import PublishBank from './publish.mainmenu.detail/publish.bank/PublishBank.page';
import PublishBride from './publish.mainmenu.detail/publish.bride/PublishBride.page';
import PublishContent from './publish.mainmenu.detail/publish.content/PublishContent.page';
import publishPartyInfo from './publish.mainmenu.detail/publish.partyinfo/PublishInfoParty.page';
import PublishGroom from './publish.mainmenu.detail/publish.groom/PublishGroom.page';
import PublishSlide from './publish.mainmenu.detail/publish.slide/PublishSlide.page';
import PublishSummary from './publish.mainmenu.detail/publish.summary/PublishSummary.page';
import EventConfig from './publish.mainmenu.detail/pushish.config/EventConfig';
import PublishStory from './publish.mainmenu.detail/publish.story/PublishStory.page';
import GuestManager from './publish.mainmenu.detail/publish.guest/GuestManager';
import PublishGreeting from './publish.mainmenu.detail/publish.greeting/PublishGreeting.page';
import PublishSub from './publish.mainmenu.detail/publish.sub/PublishSub.page';
import PublishVideo from './publish.mainmenu.detail/publish.video/PublishVideo';
import PublishDress from './publish.mainmenu.detail/publish.dress/PublishDress';
import PublisSponsor from './publish.mainmenu.detail/publish.sponsor/PublishSponsor';
import PublishSubInfo from './publish.mainmenu.detail/publish.subinfo/PublishSubInfo.page';
import PublishTableSort from './publish.mainmenu.detail/publish.tablesort/PublishTableSort';

const PAGE = {
    publishMain: '/:eventId/mainmenu',
    publishBank: '/:eventId/bank',
    publishBride: '/:eventId/bride',
    publishGroom: '/:eventId/groom',
    publishContent: '/:eventId/content',
    publishImgSlide: '/:eventId/slide',
    publishGuestManage: '/:eventId/guest-manage',
    publishStory: '/:eventId/story',
    publishOther: '/:eventId/other',
    publishSumary: '/:eventId/summary',
    publishSubInfo: '/:eventId/subinfo',
    publishPartyInfo: '/:eventId/partyinfo',
    publishAlbum: '/:eventId/album',
    publishConfig: '/:eventId/config',
    publishGreeting: '/:eventId/greetings',
    publishSub: '/:eventId/sub-event',
    publishVideo: '/:eventId/video',
    publishDress: '/:eventId/dress',
    PublishSponsor: '/:eventId/sponsor',
    PublishTableSort: '/:eventId/table-sort',
};

const PublishRoute = [
    {
        path: PAGE.publishMain,
        element: PublishMainMenu,
        isProtect: true,
        layout: 'a',
    },
    {
        path: PAGE.publishBank,
        element: PublishBank,
        isProtect: true,
        layout: 'a',
    },
    {
        path: PAGE.publishSumary,
        element: PublishSummary,
        isProtect: true,
        layout: 'a',
    },

    {
        path: PAGE.publishImgSlide,
        element: PublishSlide,
        isProtect: true,
        layout: 'a',
    },
    {
        path: PAGE.publishBride,
        element: PublishBride,
        isProtect: true,
        layout: 'a',
    },
    {
        path: PAGE.publishGroom,
        element: PublishGroom,
        isProtect: true,
        layout: 'a',
    },
    {
        path: PAGE.publishPartyInfo,
        element: publishPartyInfo,
        isProtect: true,
        layout: 'a',
    },
    {
        path: PAGE.publishAlbum,
        element: PublishAlbum,
        isProtect: true,
        layout: 'a',
    },
    {
        path: PAGE.publishContent,
        element: PublishContent,
        isProtect: true,
        layout: 'a',
    },
    {
        path: PAGE.publishStory,
        element: PublishStory,
        isProtect: true,
        layout: 'a',
    },
    {
        path: PAGE.publishConfig,
        element: EventConfig,
        isProtect: true,
        layout: 'Empty',
    },
    {
        path: PAGE.publishGuestManage,
        element: GuestManager,
        isProtect: true,
        layout: 'Empty',
    },
    {
        path: PAGE.publishGreeting,
        element: PublishGreeting,
        isProtect: true,
        layout: 'Empty',
    },
    {
        path: PAGE.publishSub,
        element: PublishSub,
        isProtect: true,
        layout: 'Empty',
    },
    {
        path: PAGE.publishVideo,
        element: PublishVideo,
        isProtect: true,
        layout: 'Empty',
    },
    {
        path: PAGE.publishDress,
        element: PublishDress,
        isProtect: true,
        layout: 'Empty',
    },
    {
        path: PAGE.PublishSponsor,
        element: PublisSponsor,
        isProtect: true,
        layout: 'Empty',
    },
    {
        path: PAGE.publishSubInfo,
        element: PublishSubInfo,
        isProtect: true,
        layout: 'Empty',
    },
    {
        path: PAGE.PublishTableSort,
        element: PublishTableSort,
        isProtect: true,
        layout: 'Empty',
    },
];

export default PublishRoute;
