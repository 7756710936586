/* eslint-disable */
import { set } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

const checkValidText = (val) => {
    const regex = /^[^_\\.\\(\\)~`!@#$%^&*()+={}[\]|\?\/<>\\*]+$/g;
    if (!regex.test(val)) {
        return false;
    }
    return true;
};

export default function ModalInfo({ data, submitForm, clearForm, timeStart, setSelectProgram }) {
    // console.log('new program', data);
    const [programInfo, setProgramInfo] = useState(data || {});
    const [validName, setValidName] = useState('');
    const [validMinutes, setValidMinutes] = useState('');
    const focusMinutes = useRef(null);
    const focusName = useRef(null);

    useEffect(() => {
        if (data) {
            setProgramInfo(data);
        }
    }, [data]);

    const handleChange = (e) => {
        setProgramInfo({
            ...programInfo,
            ...{
                [e.target.name]: e.target.value,
            },
        });
    };

    const validateMinutes = (isMessage = true, val = programInfo?.minutes) => {
        let isValid = true;
        if (!val) {
            isMessage && setValidMinutes('Vui lòng nhập thời gian thực hiện!');
            isValid = false;
        } else {
            if (val <= 0 || val > 1000) {
                isMessage && setValidMinutes('Thời gian chỉ trong khoảng từ 1 đến 1000 phút!');
                isValid = false;
            } else {
                isMessage && setValidMinutes('');
            }
        }
        if (isMessage && !isValid) {
            if (focusMinutes) {
                focusMinutes.current.focus();
            }
        }
        return isValid;
    };

    const validateName = (isMessage = true, val = programInfo?.name) => {
        let isValid = true;
        if (!val) {
            isMessage && setValidName('Vui lòng nhập tên chương trình!');
            isValid = false;
        } else {
            if (val.length > 500) {
                isMessage && setValidName('Tên chỉ từ 1-500 ký tự chữ hoặc số!');
                isValid = false;
            } else {
                if (!checkValidText(val)) {
                    isMessage && setValidName('Tên chỉ từ 1-500 ký tự chữ hoặc số!');
                    isValid = false;
                } else {
                    isMessage && setValidName('');
                }
            }
        }
        if (isMessage && !isValid) {
            if (focusName) {
                focusName.current.focus();
            }
        }
        return isValid;
    };

    const handleSubmit = (e) => {
        if (validateName() && validateMinutes()) {
            e.preventDefault();
            if (programInfo.new === true) {
                const { new: _, ...programInfoWithoutNew } = programInfo;
                submitForm('add', programInfoWithoutNew, timeStart);
            } else {
                setSelectProgram(programInfo);
                submitForm('edit', programInfo, timeStart);
            }
            handleClear();
        }
    };

    const handleClear = () => {
        setValidName('');
        setValidMinutes('');
        clearForm();
    };

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="modalContent"
            data-bs-backdrop="static"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-300 modal-header shrink-0 rounded-t-md">
                        <h5 className="text-[16px] font-bold leading-normal text-gray-800">
                            {data?.name ? 'Chỉnh sửa chương trình' : 'Thêm mới chương trình'}
                        </h5>
                        <button
                            type="button"
                            title="Đóng"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClear}
                        ></button>
                    </div>
                    <div className="flex flex-col items-center justify-between w-full border-b border-gray-200 modal-content shrink-0 rounded-t-md p-[20px] gap-[10px]">
                        <div className="flex flex-col w-full gap-[10px]">
                            <p className="font-bold text-[14px] text-black">
                                Tên chương trình <span className="text-red-500">(*)</span>
                            </p>
                            <input
                                name="name"
                                value={programInfo?.name || ''}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                type="text"
                                ref={focusName}
                                placeholder={`VD: "Khai chương nhà hàng"`}
                                className={` form-control m-0 block w-[100%] rounded border border-solid ${
                                    validName ? 'border-red-500' : 'border-gray-300'
                                } bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                            />
                            <p className="text-sm text-red-500">{validName}</p>
                        </div>
                        <div className="flex flex-col w-full gap-[10px]">
                            <p className="font-bold text-[14px] text-black">
                                Số phút <span className="text-red-500">(*)</span>
                            </p>
                            <input
                                name="minutes"
                                value={programInfo?.minutes || ''}
                                ref={focusMinutes}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const regex = /^[0-9]*$/;
                                    if (regex.test(value) || value === '') {
                                        handleChange(e);
                                    }
                                }}
                                placeholder={`VD: "10"`}
                                className={` form-control m-0 block w-[100%] rounded border border-solid ${
                                    validMinutes ? 'border-red-500' : 'border-gray-300'
                                } bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                            />
                            <p className="text-sm text-red-500">{validMinutes}</p>
                        </div>
                        {/* <div className="flex flex-col w-full gap-[10px]">
                            <p className="font-bold text-[14px] text-black">Ngày</p>
                            <input
                                name="minutes"
                                value={programInfo?.date || ''}
                                ref={focusMinutes}
                                disabled
                                className={` form-control m-0 block w-[100%] rounded border border-solid ${
                                    validMinutes ? 'border-red-500' : 'border-gray-300'
                                } bg-gray-100 bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                            />
                        </div> */}
                        {/* <div className="flex flex-col w-full gap-[10px]">
                            <p className="font-bold text-[14px] text-black">Địa điểm</p>
                            <input
                                name="place"
                                value={data?.place || ''}
                                onChange={handleChange}
                                type="text"
                                placeholder={`VD: "Nhà hàng A"`}
                                className={` form-control m-0 block w-[100%] rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                            />
                        </div>
                        <div className="flex flex-col w-full gap-[10px]">
                            <p className="font-bold text-[14px] text-black">Địa chỉ</p>
                            <input
                                name="address"
                                value={data?.address || ''}
                                onChange={handleChange}
                                type="text"
                                placeholder={`VD: "Nhân Chính, Thanh Xuân, Hà Nội"`}
                                className={` form-control m-0 block w-[100%] rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                            />
                        </div>
                        <div className="flex flex-col w-full gap-[10px]">
                            <p className="font-bold text-[14px] text-black">Đường dẫn bản đồ</p>
                            <input
                                name="linkGgMap"
                                value={data?.linkGgMap || ''}
                                onChange={handleChange}
                                type="text"
                                placeholder={`VD: "https://maps.app.goo.gl/V7iKpMMo5sjrTiRr9"`}
                                className={` form-control m-0 block w-[100%] rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                            />
                        </div> */}
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover:bg-gray-50 transition-all focus:bg-gray-50 active:bg-gray-50  inline-block rounded bg-white border-[1px] border-gray-300 px-5 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                            onClick={handleClear}
                        >
                            Thoát
                        </button>
                        <button
                            type="button"
                            onClick={handleSubmit}
                            data-bs-dismiss={
                                validateName(false) && validateMinutes(false) ? 'modal' : ''
                            }
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg  focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                        >
                            Lưu
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
