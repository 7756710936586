/* eslint-disable */

import { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import envConstants from '../../../../config/key';
import PAGE from '../../../../config/path.route';
import EventConfigService from './Event_config.service';
import {
    FaComment,
    FaEnvelope,
    FaEnvelopeOpenText,
    FaGlobeAsia,
    FaItunesNote,
    FaLanguage,
    FaRegCalendarAlt,
    FaRegSnowflake,
    FaTrashAlt,
    FaUserPlus,
    FaUserTag,
    FaUsers,
} from 'react-icons/fa';
import ToastNotify from '../../../../utils/Toast';
import EventsService, { checkIsExpired } from '../../../events/Events.service';
import SettingEmailModal from './component/SettingEmailModal';
import ComposeContentEmail from './component/ComposeContentEmail';
import SkeletonContent from './component/SkeletonContent';
import UserEventsService from '../../../user.events/User.events.service';
import ShortcutMenu from '../../components/ShortcutMenu';
import InputValidatePublic from './component/InputValidatePublic';
import modalAction from '../../../../redux/modal/modal.action';
import ModalManagement from '../../../../components/ModalManagement/ModalManagement';
import Products from '../publish.news/products/Products';
import TimelineShortcutMenu from '../../components/TimelineShortcutMenu';
import QuestionTitle from '../publish.question/QuestionTitle';
import PublicModal from './component/PublicModal';
import Header from '../publish.sub/components/Header';
import ModalDelete from '../../../user.events/components/ModalDelete';

export default function EventConfig() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { eventId } = useParams();
    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState([]);
    const [isChanged, setIsChanged] = useState(false);
    const [event, setEvent] = useState();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const btnOpenModal = useRef();
    const btnOpenSubmitPublic = useRef();
    const [isPublic, setIsPublic] = useState();
    const [isExpired, setIsExpired] = useState(false);
    const { loggedIn } = useSelector((state) => state.auth);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        await EventConfigService.findEventConfig(eventId)
            .then((data) => {
                handleSettingToArr(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
        await EventsService.getOneEventById(eventId)
            .then(({ data }) => {
                // if (data.isExpired) {
                //     ToastNotify.Info('Bạn không được truy cập trang này khi sự kiện đã kết thúc');
                //     navigate(`/${eventId}/mainmenu`);
                // }
                setEvent(data);
                const isExpiredEvent = checkIsExpired(data);
                setIsExpired(isExpiredEvent);
            })
            .catch((err) => {
                console.log(err.message);
            });

        setIsPublic(event?.isPublic);
        setLoading(false);
    };

    const handleSettingToArr = (data) => {
        let arrSetting = [];

        arrSetting.push({
            key: 'auto_sound',
            value: data.auto_sound,
            name: 'Nhạc nền',
            icon: <FaItunesNote />,
            des: 'Cho phép khách mời bật / tắt nhạc nền có trong thiệp.',
            show: ['wedding-3'],
        });
        arrSetting.push({
            key: 'auto_trans',
            value: data.auto_trans,
            name: 'Tự động dịch',
            icon: <FaLanguage />,
            show: [
                'event-1',
                'event-2',
                'event-3',
                'event-6',
                'event-7',
                'event-8',
                'event-11',
                'event-12',
            ],
            des: 'Tự động dịch văn bản bằng Google dịch để thiệp của bạn có thể sử dụng cho nhiều ngôn ngữ',
        });

        setSettings(arrSetting);
    };

    // eslint-disable-next-line id-length
    const handleChange = (e) => {
        const { checked, name } = e.target;
        setIsChanged(true);
        const changeSetting = settings.map((item) => {
            if (item.key === name) {
                return { ...item, value: checked };
            }
            return item;
        });

        setSettings(changeSetting);
    };

    const limitString = (str) => {
        if (!str) {
            return '';
        }
        if (str.length > 250) {
            return str.substring(0, 250) + '...';
        }
        return str;
    };

    const handleSave = () => {
        EventConfigService.postEventConfig(eventId, handleSettingToObj())
            .then(() => {
                ToastNotify.Success('Đã đổi thông tin cài đặt');
            })
            .catch((err) => {
                ToastNotify.Error(err?.message || 'Có lỗi xảy ra vui lòng thử lại sau!');
            });
    };

    const handleSettingToObj = () =>
        settings.reduce((acc, item) => {
            acc[item.key] = item.value;
            return acc;
        }, {});

    useEffect(() => {
        if (isChanged) {
            handleSave();
        }
    }, [settings]);

    const makeUrlShare = useCallback((urlSlug) => {
        let rawUrl =
            envConstants.CLIENT_URL.slice(0, envConstants.CLIENT_URL.lastIndexOf('/')) +
            PAGE.publish;
        if (urlSlug) {
            rawUrl = rawUrl.replace(':url_share', urlSlug);

            return rawUrl;
        }
        return '';
    }, []);

    const onCopyInvite = () => {
        // const urlSlug = eventTemplate?.url_slug;

        const urlShare = makeUrlShare(event?.url_slug);

        const text = `Trân trọng kính mời quý khách\nTới tham dự sự kiện: ${event.name} tại đường link sau: ${urlShare}`;

        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(text).then(() => {
                ToastNotify.Info('Đã sao chép lời mời');
            });
        } else {
            let textArea = document.createElement('textarea');
            textArea.value = text;
            textArea.style.position = 'fixed';
            textArea.style.left = '-999999px';
            textArea.style.top = '-999999px';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            new Promise((res, rej) => {
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            }).then(() => {
                ToastNotify.Info('Đã sao chép lời mời');
            });
        }
    };

    const onCopyLink = () => {
        const urlSlug = event?.url_slug;

        let urlShare = makeUrlShare(urlSlug);

        if (!urlShare) {
            return;
        }
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(urlShare).then(() => {
                ToastNotify.Info('Đã sao chép đường dẫn');
            });
        } else {
            let textArea = document.createElement('textarea');
            textArea.value = urlShare;
            textArea.style.position = 'fixed';
            textArea.style.left = '-999999px';
            textArea.style.top = '-999999px';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            new Promise((res, rej) => {
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            }).then(() => {
                ToastNotify.Info('Đã sao chép đường dẫn');
            });
        }
    };
    const handleCheckPublic = () => {
        UserEventsService.checkExport(event.id)
            .then(({ data }) => {
                btnOpenSubmitPublic.current.click();
            })
            .catch(async (err) => {
                if (err?.status === 402) {
                    await dispatch(modalAction.openModalLogin());
                } else if (err?.status === 400) {
                    btnOpenModal.current.click();
                    setIsOpenModal(!isOpenModal);
                }
                ToastNotify.Error(err.message);
            });
    };
    const handlePublic = () => {
        UserEventsService.shareEventTemplate(event.id, {
            isPublic: !event?.is_public,
        })
            .then(({ data }) => {
                setEvent({ ...event, is_public: data?.data?.event.is_public });
                if (event?.is_public) {
                    ToastNotify.Success('Đã hủy chia sẻ công khai');
                } else {
                    ToastNotify.Success('Đã chia sẻ công khai');
                }
            })
            .catch(async (err) => {
                if (err?.status === 402) {
                    await dispatch(modalAction.openModalLogin());
                } else if (err?.status === 400) {
                    btnOpenModal.current.click();
                    setIsOpenModal(!isOpenModal);
                }
                ToastNotify.Error(err.message);
            });
    };

    const deleteTemplateEvent = async (id) => {
        UserEventsService.deleteEventTemplate(id)
            .then((data) => {
                navigate(PAGE.userEvents);
                ToastNotify.Success(`Xóa thành công ${event?.name}!`);
            })
            .catch((err) => {
                ToastNotify.Error(err?.message || 'Xóa bị lỗi!');
            });
    };

    useEffect(() => {
        if (!loggedIn) {
            const beforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = true;
            };
            window.addEventListener('beforeunload', beforeUnload);
            return () => {
                window.removeEventListener('beforeunload', beforeUnload);
            };
        }
    }, []);

    return (
        <>
            <ModalManagement />
            <InputValidatePublic eventId={eventId} isOpen={isOpenModal} />
            <div className="relative flex flex-col items-center gap-[1.5rem] pb-[2rem] 2xl:pt-[6rem] pt-[5rem]">
                <Header event={event} isExpired={isExpired} />
                <div className=" w-full flex justify-evenly">
                    <div className="lg:flex hidden relative w-[250px]">
                        <TimelineShortcutMenu screen={'config'} />
                    </div>
                    {loading ? (
                        <SkeletonContent />
                    ) : (
                        <>
                            <div className="w-full rounded-lg border-[1px] border-slate-200 bg-white-box shadow-lg lg:w-[48rem] 2xl:w-[60rem]">
                                <div className="flex w-full items-center justify-between border-b-[1px] border-slate-200 px-3 py-2">
                                    <p className="text-lg font-bold text-black">Cấu hình sự kiện</p>
                                </div>

                                <div className="flex w-full flex-col items-start gap-5 border-t-[1px] border-gray-300 px-3 py-5 md:px-6">
                                    <p className="text-left text-[14px] font-semibold text-black">
                                        Trạng thái sự kiện
                                    </p>
                                    <div
                                        className={`flex w-full items-center justify-between ${
                                            event?.is_public ? 'bg-blue-50' : 'bg-gray-100'
                                        }  p-[1rem] shadow-md transition-all`}
                                    >
                                        <div
                                            className={`flex items-center gap-[20px] ${
                                                event?.is_public ? 'text-blue-700' : 'text-black'
                                            } `}
                                        >
                                            <FaGlobeAsia />
                                            <div className="flex flex-col">
                                                <p className="max-w-[200px] text-[14px] font-semibold md:max-w-[50rem]">
                                                    {event?.is_public
                                                        ? 'Thiệp của bạn đã được công khai'
                                                        : 'Thiệp của bạn chưa được công khai'}
                                                </p>
                                                <p className="max-w-[200px] text-[12px]  md:max-w-[50rem]">
                                                    {event?.is_public
                                                        ? 'Bạn có thể chia sẻ đường dẫn cho mọi người và họ có thể xem thiệp'
                                                        : 'Chỉ mình bạn có thể xem thiệp'}
                                                </p>
                                            </div>
                                        </div>

                                        <button
                                            onClick={() => {
                                                handleCheckPublic();
                                                setIsPublic(
                                                    event?.is_public === true ? false : true
                                                );
                                            }}
                                            disabled={event?.isExpired}
                                            className={`text-[14px] font-bold text-blue-700 transition-all  ${
                                                event?.isExpired
                                                    ? 'opacity-40 lg:flex hidden'
                                                    : 'flex hover:text-blue-400'
                                            }`}
                                        >
                                            {event?.isExpired
                                                ? 'Bạn không thể sửa thiêp này'
                                                : event?.is_public === true
                                                ? 'Hủy công khai'
                                                : 'Công khai'}
                                        </button>

                                        <button
                                            type="button"
                                            ref={btnOpenModal}
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalInputValidatePublic"
                                            className="hidden"
                                        ></button>
                                        <button
                                            type="button"
                                            ref={btnOpenSubmitPublic}
                                            data-bs-toggle="modal"
                                            data-bs-target="#publicModal"
                                            className="hidden"
                                        ></button>
                                    </div>
                                </div>
                                {settings?.filter(
                                    (settingItem) =>
                                        (settingItem.show &&
                                            settingItem?.show?.includes(event?.template?.alias)) ||
                                        !settingItem?.show
                                )?.length !== 0 && (
                                    <div className="flex w-full flex-col items-start gap-5 border-t-[1px] border-gray-300 px-3 py-5 md:px-6">
                                        <p className="text-left text-[14px] font-semibold text-black">
                                            Cài đặt sự kiện
                                        </p>
                                        {settings?.map(
                                            (settingItem) =>
                                                ((settingItem.show &&
                                                    settingItem?.show?.includes(
                                                        event?.template?.alias
                                                    )) ||
                                                    !settingItem?.show) && (
                                                    <div
                                                        className={`flex w-full justify-between ${
                                                            settingItem.value
                                                                ? 'bg-blue-50'
                                                                : 'bg-gray-100'
                                                        } items-center p-[1rem] shadow-md transition-all`}
                                                    >
                                                        <div
                                                            className={`flex max-w-[250px] items-center gap-[20px] md:max-w-[50rem] ${
                                                                settingItem.value
                                                                    ? 'text-blue-600'
                                                                    : 'text-black'
                                                            }`}
                                                        >
                                                            {settingItem.icon}
                                                            <div className="flex flex-col">
                                                                <p className="text-[14px] font-semibold">
                                                                    {settingItem.name}
                                                                </p>
                                                                <p className="text-[12px] ">
                                                                    {settingItem.des}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <label
                                                            className={`relative ${
                                                                event?.isExpired
                                                                    ? 'opacity-40'
                                                                    : 'cursor-pointer'
                                                            } `}
                                                        >
                                                            <input
                                                                disabled={event?.isExpired}
                                                                type="checkbox"
                                                                className="peer sr-only"
                                                                name={settingItem.key}
                                                                value={settingItem.value}
                                                                checked={settingItem.value}
                                                                onChange={handleChange}
                                                            />
                                                            <div className="peer flex h-3 w-11 items-center rounded-full bg-gray-300 after:absolute after:left-0 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all peer-checked:bg-blue-600 peer-checked:after:-left-1 peer-checked:after:translate-x-full"></div>
                                                        </label>
                                                    </div>
                                                )
                                        )}
                                    </div>
                                )}

                                {event?.is_public && (
                                    <div className="flex w-full flex-col items-start gap-5 border-y-[1px] border-gray-300 px-3 py-5 md:px-6">
                                        <p className="text-[14px] font-semibold text-black">
                                            Lời mời
                                        </p>
                                        <div className="flex w-full items-center justify-between bg-gray-100 p-[1rem] shadow-md transition-all">
                                            <div className="flex items-center gap-[12px] ">
                                                <p className="max-w-[260px] text-[14px] md:max-w-[36rem]">
                                                    {limitString(` Trân trọng kính mời quý khách tới tham dự sự
                                                    kiện: ${event?.name} tại đường link sau: 
                                                    ${makeUrlShare(event?.url_slug)}`)}
                                                </p>
                                            </div>
                                            <button
                                                className="text-[14px] font-bold text-blue-700 transition-all hover:text-blue-400"
                                                onClick={onCopyInvite}
                                            >
                                                Sao chép
                                            </button>
                                        </div>
                                    </div>
                                )}
                                {event?.is_public && (
                                    <div className="flex w-full flex-col items-start gap-5 border-y-[1px] border-gray-300 px-3 py-5 md:px-6">
                                        <p className="text-[14px] font-semibold text-black">
                                            Đường dẫn
                                        </p>
                                        <div className="flex w-full items-center justify-between bg-gray-100 p-[1rem] shadow-md transition-all">
                                            <div className="flex items-center gap-[12px] ">
                                                <p className="max-w-[50rem] text-[14px]">
                                                    {makeUrlShare(event?.url_slug)}
                                                </p>
                                            </div>
                                            <button
                                                className="text-[14px] font-bold text-blue-700 transition-all hover:text-blue-400"
                                                onClick={onCopyLink}
                                            >
                                                Sao chép
                                            </button>
                                        </div>
                                    </div>
                                )}

                                <div className="flex w-full flex-col items-start gap-5 border-y-[1px] border-gray-300 px-3 py-5 md:px-6">
                                    <div className="flex w-full items-center justify-between bg-red-50 p-[1rem] shadow-md transition-all">
                                        <div
                                            className={`flex max-w-[250px] items-center gap-[20px] md:max-w-[50rem]
                                                            settingItem.value
                                                                 'text-black'
                                                        `}
                                        >
                                            <FaTrashAlt className="text-black" />
                                            <div className="flex flex-col ">
                                                <p className="text-[14px] font-semibold text-black">
                                                    Xóa thiệp mời
                                                </p>
                                                <p className="text-[12px] text-black ">
                                                    Hành động này không thể hoàn tác
                                                </p>
                                            </div>
                                        </div>
                                        <button
                                            className={`text-[14px] font-bold ${
                                                isExpired
                                                    ? 'text-red-400 cursor-default lg:flex hidden '
                                                    : 'text-red-500 hover:text-red-600 flex '
                                            }  transition-all   items-center gap-2`}
                                            disabled={event?.isExpired}
                                            data-bs-toggle="modal"
                                            data-bs-target={isExpired ? '' : '#confirmDeleteModal'}
                                        >
                                            {isExpired
                                                ? 'Bạn không thể xóa thiệp này'
                                                : 'Xóa thiệp'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <SettingEmailModal eventTemplate={event} />
                            <ComposeContentEmail eventTemplate={event} />
                            <PublicModal isPublic={isPublic} handlePublic={handlePublic} />
                            <ModalDelete funDelete={deleteTemplateEvent} data={event} />
                        </>
                    )}
                    <div className="lg:flex hidden relative w-[250px]">
                        {' '}
                        <Products />
                    </div>
                </div>
                {/* <ShortcutMenu screen={'config'} /> */}
            </div>
        </>
    );
}
