export default function SummarySkeleton() {
    return (
        <>
            {Array.from({ length: 2 }).map((index) => (
                <div key={index} className="mx-auto flex lg:w-[48rem] 2xl:w-[60rem] flex-col">
                    <div className="mb-5 w-full animate-pulse scroll-mt-[100px] rounded-lg bg-white shadow">
                        <div className="flex items-center justify-between border-b border-gray-200 p-4 md:p-6">
                            <div className="mb-0 h-6 w-full rounded-lg bg-gray-300 font-bold"></div>
                        </div>
                        <div className="flex flex-col gap-4 p-4">
                            <div className="h-10 w-full rounded-lg bg-gray-200"></div>
                            <div className="h-10 w-full rounded-lg bg-gray-200"></div>
                            <div className="h-10 w-full rounded-lg bg-gray-200"></div>
                            <div className="h-10 w-full rounded-lg bg-gray-200"></div>
                            <div className="h-10 w-full rounded-lg bg-gray-200"></div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}
