/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/jsx-key */
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Sponsor = ({ data }) => {
    const dataCopy = {
        sponsors: data?.sponsors ?? [],
    };
    const settings = {
        infinite: data?.sponsors?.length <= 3 ? false : true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 4000,
        cssEase: 'linear',
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const openTab = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    };

    return (
        <div className="relative mx-auto  mt-5 w-full max-w-screen-xl">
            {dataCopy?.sponsors && dataCopy?.sponsors?.length > 0 ? (
                <div className="flex w-full flex-col items-center justify-center">
                    <p className="w-full text-center text-xs font-extrabold tracking-[1px] text-yellow-100 lg:text-sm ">
                        Nhà đồng hành
                    </p>
                    <div className="mb-4 mt-2 flex w-full items-center justify-center bg-transparent">
                        <Slider {...settings} arrows={false} className="w-full">
                            {dataCopy?.sponsors.map((product, index) => (
                                <div
                                    onClick={() => openTab(product?.web)}
                                    className="sliderclass flex h-[100px] flex-row items-center justify-center px-2 pt-1"
                                >
                                    <img
                                        title={product?.name ?? ''}
                                        style={{ cursor: 'pointer' }}
                                        alt="product"
                                        key={index}
                                        src={`${process.env.REACT_APP_SERVER_URL}${product.image[0]}`}
                                        className="h-full  "
                                    />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Sponsor;
