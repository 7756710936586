/* eslint-disable */
import { useCallback, useEffect, useRef, useState } from 'react';

// import IMAGES_WEDDING from '../../resource/wedding/images/_index';

// import Templates from '../events/components/Templates';
import { useParams, useNavigate } from 'react-router-dom';

import PAGE from '../../../../config/path.route';

import EventsService from '../../../events/Events.service';

import InputBasic from '../../../../components/InputBasic/InputBasic';
import {
    FaInfoCircle,
    FaTimes,
    FaSave,
    FaRegObjectUngroup,
    FaThLarge,
    FaGripVertical,
    FaMale,
    FaUserTie,
    FaUserEdit,
    FaTrashAlt,
    FaPlus,
    FaUserPlus,
    FaEdit,
    FaPeopleArrows,
} from 'react-icons/fa';
import ToastNotify from '../../../../utils/Toast';

import Products from '../publish.news/products/Products';
import TimelineShortcutMenu from '../../components/TimelineShortcutMenu';

import QuestionTitle from '../publish.question/QuestionTitle';
import Header from '../publish.sub/components/Header';
import { useSelector } from 'react-redux';
import SortTableModal from './components/SortTableModal';
import CoditionTableModal from './components/CodititonModal';

import { MdTableBar } from 'react-icons/md';
import InfoTableModal from './components/InfoTableModal';

import SelectGuestModal from './components/SelectGuestModal';
import ConfirmDeleteGuest from './components/ConfirmDeleteGuest';
import ListSwapGuestModal from './components/ListSwapGuestModal';
import ConfirmDeleteTable from './components/ConfirmDeleteTable';
import ReactTooltip from 'react-tooltip';
import InfoguestModal from './components/InfoGuestModal';

export default function PublishTableSort() {
    const navigate = useNavigate();
    const { eventId } = useParams();
    const [loading, setLoading] = useState(false);
    const [event, setEvent] = useState();
    const [coditionList, setCoditionList] = useState([]);
    const [tableEvent, setTableEvent] = useState();
    const [listTable, setListTable] = useState([]);
    const [selectTable, setSelectTable] = useState();
    const [selectGuest, setSelectGuest] = useState();
    const [listGuest, setListGuest] = useState([]);
    const [unTableListGuest, setUnTableListGuest] = useState([]);
    const [trigger, setTrigger] = useState(false);
    const { loggedIn } = useSelector((state) => state.auth);

    useEffect(() => {
        getData();
    }, [trigger]);

    const getData = async () => {
        setLoading(true);
        if (eventId) {
            await EventsService.getOneEventById(eventId)
                .then(({ data }) => {
                    setEvent(data);
                })
                .catch((error) => {
                    console.log(error);
                    const AuthorizeError = 404;
                    if (error.status === AuthorizeError) {
                        navigate(PAGE.dashboard);
                    }
                    const ServerError = 500;
                    if (error.status === ServerError) {
                        navigate(PAGE.dashboard);
                    }
                });
            await EventsService.getTableCodition()
                .then(({ data }) => {
                    setCoditionList(data.data);
                })
                .catch((error) => {
                    console.log(error);
                });
            await EventsService.getEvTable(eventId)
                .then(({ data }) => {
                    setTableEvent(data.data);
                    setListTable(data.data.table_list);
                })
                .catch((error) => {
                    console.log(error);
                });
            await EventsService.getEvAcceptGuest(eventId)
                .then(({ data }) => {
                    setListGuest(data.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        setLoading(false);
    };

    useEffect(() => {
        handleGetUnTableGuest();
    }, [listGuest]);

    const handleGetUnTableGuest = () => {
        const unTableGuest = listGuest.filter((guest) => {
            return !guest.table;
        });
        setUnTableListGuest(unTableGuest);
    };

    useEffect(() => {
        if (!loggedIn) {
            const beforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = true;
            };
            window.addEventListener('beforeunload', beforeUnload);
            return () => {
                window.removeEventListener('beforeunload', beforeUnload);
            };
        }
    }, []);

    const handleCreateATable = async (data) => {
        await EventsService.createTable({
            ...data,
            id: tableEvent._id,
            ...(selectTable && { table_id: selectTable._id }),
        })
            .then(({ data }) => {
                ToastNotify.Success('Tạo bàn thành công');
            })
            .catch((error) => {
                console.log(error);
            });
        setTrigger(!trigger);
    };

    const handleAddGuestToTable = async (listGuest) => {
        setLoading(true);
        await EventsService.addGuestToTable({
            list_guest: handleIndexListGuest(listGuest),
            table_id: selectTable._id,
        })
            .then(({ data }) => {
                ToastNotify.Success('Thêm khách vào bàn thành công');
            })
            .catch((error) => {
                console.log(error);
            });
        setLoading(false);
        setTrigger(!trigger);
    };

    const handleIndexListGuest = (listArr) => {
        let newList;
        let listGuestInTable = listGuest.filter((item) => item.table === selectTable._id);
        console.log('listGuestInTable', listGuestInTable);
        if (listGuestInTable?.length === 0) {
            console.log('1');
            newList = listArr.map((item, index) => ({
                _id: item,
                index: index + 1,
            }));
        } else {
            console.log('2');
            const maxTableIndex = Math.max(...listGuestInTable.map((item) => item.table_index));
            newList = listArr.map((item, index) => ({
                _id: item,
                index: maxTableIndex + index + 1,
            }));
        }
        return newList;
    };

    const handleSortTable = async (evCondition) => {
        setLoading(true);
        await EventsService.sortTable({ event_id: eventId, condition: evCondition })
            .then(({ data }) => {
                ToastNotify.Success('Đã sắp xếp lại bàn tiệc');
            })
            .catch((error) => {
                console.log(error);
            });
        setLoading(false);
        setTrigger(!trigger);
    };

    const handleDeleteGuest = async () => {
        setLoading(true);
        await EventsService.deleteGuest({ guest_id: selectGuest._id })
            .then(({ data }) => {
                ToastNotify.Success('Đã xóa khách khỏi bàn tiệc');
            })
            .catch((error) => {
                console.log(error);
            });
        setLoading(false);
        setTrigger(!trigger);
    };

    const handleDeleteTable = async () => {
        setLoading(true);
        await EventsService.deleteTable({ event_id: eventId, table_id: selectTable._id })
            .then(() => {
                ToastNotify.Success('Đã xóa bàn tiệc');
            })
            .catch((error) => {
                console.log(error);
            });
        setLoading(false);
        setTrigger(!trigger);
    };

    const handleSwapTable = async (swapGuest) => {
        setLoading(true);
        await EventsService.swapGuest({
            guest_from: selectGuest._id,
            guest_to: swapGuest._id,
        })
            .then(({}) => {
                ToastNotify.Success('Đã đổi vị trí khách');
            })
            .catch((error) => {
                console.log(error);
            });
        setLoading(false);
        setTrigger(!trigger);
    };

    return (
        <>
            <div className="flex flex-col items-center gap-[1.5rem] pb-[2rem] relative">
                <Header event={event} isExpired={event?.isExpired} />
                <div className=" w-full flex justify-evenly mt-20">
                    <div className="lg:flex hidden relative w-[250px]">
                        <TimelineShortcutMenu screen={'table'} />
                    </div>

                    {loading ? (
                        <></>
                    ) : (
                        <>
                            <div className=" rounded-lg border-[1px] border-slate-200 bg-white-box shadow-lg w-full lg:w-[48rem] 2xl:w-[72rem] flex flex-col">
                                <div className="w-full p-2 px-3 border-b border-gray-300 flex flex-col">
                                    <p className="text-black font-bold text-lg mb-1">
                                        Sắp xếp bàn tiệc
                                    </p>
                                    <QuestionTitle keyQues={'video'} />
                                </div>
                                <div className="w-full p-4 flex items-center gap-2">
                                    <button
                                        className="bg-blue-500 px-4 py-1 rounded text-white text-sm flex items-center gap-2"
                                        data-bs-toggle={'modal'}
                                        data-bs-target={'#modalInfoTable'}
                                        onClick={() => {
                                            setSelectTable({});
                                        }}
                                    >
                                        <FaPlus /> Thêm bàn tiệc
                                    </button>
                                    <button
                                        className="bg-blue-500 px-4 py-1 rounded text-white text-sm flex items-center gap-2"
                                        data-bs-toggle={'modal'}
                                        data-bs-target={'#sortTableModal'}
                                    >
                                        <FaRegObjectUngroup /> Xem sơ đồ bàn tiệc
                                    </button>
                                    <button
                                        className="bg-blue-500 px-4 py-1 rounded text-white text-sm flex items-center gap-2"
                                        data-bs-toggle={'modal'}
                                        data-bs-target={'#coditionTableModal'}
                                    >
                                        <FaThLarge /> Tự động xếp bàn
                                    </button>
                                </div>
                                <div className="flex flex-col gap-3 w-full px-3 pb-3 items-center">
                                    {unTableListGuest?.length > 0 && (
                                        <p className="text-sm text-black font-bold">
                                            {`Có ${unTableListGuest?.length} khách chưa được xếp bàn`}
                                            <span
                                                className="text-blue-500 cursor-pointer"
                                                data-bs-toggle={'modal'}
                                                data-bs-target={'#coditionTableModal'}
                                            >
                                                {' '}
                                                Tự động xếp
                                            </span>
                                        </p>
                                    )}
                                    {listTable.map((table, index) => (
                                        <TableItem
                                            key={index}
                                            table={table}
                                            setSelectTable={setSelectTable}
                                            listGuest={listGuest.filter(
                                                (guest) => guest.table === table._id
                                            )}
                                            setSelectGuest={setSelectGuest}
                                        />
                                    ))}
                                </div>
                            </div>
                            <SelectGuestModal
                                listGuest={
                                    selectTable?._id
                                        ? listGuest.filter((item) => item.table !== selectTable._id)
                                        : listGuest
                                }
                                handleAddGuestToTable={handleAddGuestToTable}
                            />
                            <SortTableModal listTable={listTable} listGuest={listGuest} />
                            <CoditionTableModal
                                handleSortTable={handleSortTable}
                                evTableCondition={tableEvent?.condition}
                            />
                            <InfoTableModal
                                handleCreateATable={handleCreateATable}
                                selectTable={selectTable}
                            />
                            <ConfirmDeleteGuest handleDeleteGuest={handleDeleteGuest} />
                            <ListSwapGuestModal
                                handleSwapGuest={handleSwapTable}
                                listGuest={
                                    selectGuest?._id
                                        ? listGuest.filter((item) => item._id !== selectGuest._id)
                                        : listGuest
                                }
                            />
                            <ConfirmDeleteTable handleDeleteTable={handleDeleteTable} />
                            <InfoguestModal data={selectGuest} />
                        </>
                    )}
                    <div className="lg:flex hidden relative w-[250px]">
                        <Products />
                    </div>
                </div>
            </div>
        </>
    );
}

const TableItem = ({ table, setSelectTable, listGuest, setSelectGuest }) => {
    return (
        <div className="w-full flex flex-col gap-2">
            <div className="w-full py-3 flex justify-between bg-blue-500 text-white text-sm font-bold rounded-lg px-4 mb-3 ">
                <div className="flex items-center gap-2">
                    <MdTableBar /> {table.name}
                </div>
                <div className="flex gap-2 text-lg">
                    <div className="relative group">
                        <FaUserPlus
                            data-bs-toggle={'modal'}
                            data-bs-target={'#modalSelectGuest'}
                            data-tip
                            data-for={`${table._id}-add-guest`}
                            onClick={() => {
                                setSelectTable(table);
                            }}
                            className="cursor-pointer"
                        />
                        <ReactTooltip id={`${table._id}-add-guest`} place="top" effect="solid">
                            Thêm khách mời vào bàn
                        </ReactTooltip>
                    </div>
                    <div className="relative group">
                        <FaEdit
                            onClick={() => {
                                setSelectTable(table);
                            }}
                            data-bs-toggle={'modal'}
                            data-bs-target={'#modalInfoTable'}
                            className="cursor-pointer"
                            data-tip
                            data-for={`${table._id}-edit-table`}
                        />
                        <ReactTooltip id={`${table._id}-edit-table`} place="top" effect="solid">
                            Sửa thông tin bàn
                        </ReactTooltip>
                    </div>
                    <div className="relative group">
                        <FaTrashAlt
                            className="cursor-pointer"
                            data-bs-toggle={'modal'}
                            data-bs-target={'#confirmDeleteTable'}
                            onClick={() => {
                                setSelectTable(table);
                            }}
                            data-tip
                            data-for={`${table._id}-delete-table`}
                        />
                        <ReactTooltip id={`${table._id}-delete-table`} place="top" effect="solid">
                            Xóa bàn
                        </ReactTooltip>
                    </div>
                </div>
            </div>
            {listGuest
                .sort((a, b) => a.table_index - b.table_index)
                .map((guest, index) => (
                    <div className="w-full py-2 flex justify-start items-center text-black text-sm font-bold px-4 border-b-[1px] border-gray-300">
                        <div className="flex items-center gap-2 w-[30%] text-wrap">
                            {guest?.title} {guest.fullname}
                        </div>
                        <div className="flex justify-center items-center w-[15%]">
                            <div
                                className={`flex px-4 py-1 items-center gap-2 ${
                                    guest?.gender === 'FEMALE' ? 'bg-pink-500' : 'bg-blue-500'
                                } rounded-full text-white font-normal`}
                            >
                                Giới tính: {guest?.gender === 'MALE' ? 'Nam' : 'Nữ'}
                            </div>
                        </div>
                        <div className="flex justify-center items-center w-[15%]">
                            <div
                                className={`flex px-4 py-1 items-center gap-2 ${
                                    guest?.is_important ? 'bg-violet-500' : 'bg-gray-500'
                                } rounded-full text-white font-normal`}
                            >
                                Độ ưu tiên: {guest?.is_important ? 'VIP' : 'Thường'}
                            </div>
                        </div>
                        <div className="flex justify-end items-center w-[40%] gap-2 text-lg">
                            <div className="relative group">
                                <FaInfoCircle
                                    className=" text-blue-500 cursor-pointer"
                                    data-tip
                                    data-for={`${guest._id}-info-guest`}
                                    data-bs-toggle={'modal'}
                                    data-bs-target={'#infoGuestModal'}
                                />

                                <ReactTooltip
                                    id={`${guest._id}-info-guest`}
                                    place="top"
                                    effect="solid"
                                >
                                    Thông tin khách
                                </ReactTooltip>
                            </div>
                            <div className="relative group">
                                <FaPeopleArrows
                                    data-bs-toggle={'modal'}
                                    data-bs-target={'#listSwapGuestModal'}
                                    className=" text-blue-500 cursor-pointer"
                                    onClick={() => {
                                        setSelectGuest(guest);
                                    }}
                                    data-tip
                                    data-for={`${guest._id}-swap-guest`}
                                />

                                <ReactTooltip
                                    id={`${guest._id}-swap-guest`}
                                    place="top"
                                    effect="solid"
                                >
                                    Đổi vị trí khách
                                </ReactTooltip>
                            </div>
                            <div className="relative group">
                                <FaTrashAlt
                                    className=" text-red-500 cursor-pointer"
                                    onClick={() => {
                                        setSelectGuest(guest);
                                    }}
                                    data-bs-toggle={'modal'}
                                    data-bs-target={'#confirmDeleteGuest'}
                                    data-tip
                                    data-for={`${guest._id}-delete-guest`}
                                />

                                <ReactTooltip
                                    id={`${guest._id}-delete-guest`}
                                    place="top"
                                    effect="solid"
                                >
                                    Xóa khách khỏi bàn
                                </ReactTooltip>
                            </div>
                        </div>
                    </div>
                ))}

            <div className="w-full mt-2 h-[2px] bg-blue-500"></div>
        </div>
    );
};
