import { questionList } from './Question';
import QuestionModal from './QuestionModal';
export default function QuestionTitle({ keyQues }) {
    const quesSelect = questionList.find((item) => item.key === keyQues);
    return (
        <>
            <div className="w-full py-1 bg-blue-50 flex justify-end rounded-lg px-2">
                <span className="text-[14px] font-normal w-full text-right">
                    Có thể bạn đã biết,{' '}
                    <span
                        className="text-blue-500 underline cursor-pointer font-bold"
                        data-bs-toggle={'modal'}
                        data-bs-target={`#${keyQues}`}
                    >
                        {quesSelect?.ques}
                    </span>
                </span>
            </div>
            <QuestionModal keyQues={keyQues} />
        </>
    );
}
