/* eslint-disable */
import { data } from 'autoprefixer';
import { useEffect, useState } from 'react';
import guestManagerService from '../guestManager.service';
import {
    FaCheck,
    FaClock,
    FaCog,
    FaEnvelope,
    FaExclamationTriangle,
    FaHotel,
    FaInfo,
    FaInfoCircle,
    FaMailBulk,
    FaPen,
    FaPhoneAlt,
    FaTimes,
    FaTrash,
    FaUser,
    FaUserClock,
    FaUserTie,
    FaUserTimes,
} from 'react-icons/fa';
import ToastNotify from '../../../../../utils/Toast';

export default function VerifyTable({
    guests,
    handleGetVerifyGuest,
    eventTemplate,
    trigger,
    setTrigger,
}) {
    const [verifyGuests, setVerifyGuests] = useState(guests);
    useEffect(() => {
        setVerifyGuests(guests);
    }, [guests]);
    const [selectOneGuest, setSelectOneGuest] = useState();
    const [actionMode, setActionMode] = useState('');
    const [guestSelect, setGuestSelect] = useState([]);

    const handleSelectGuest = (e) => {
        const { value, checked } = e.target;
        let guestSelected = [...guestSelect];
        if (checked) {
            setActionMode('many');
            guestSelected.push(guests.find((guest) => guest._id == value));
            setGuestSelect(guestSelected);
        } else {
            setActionMode('many');
            guestSelected = guestSelected.filter(
                (guestSelectedItem) => guestSelectedItem._id != value
            );
            setGuestSelect(guestSelected);
        }
    };

    const handelSelectAll = () => {
        setGuestSelect(
            guests?.filter(
                (guest) =>
                    guest?.group[0]?.status.find((status) => status.active).status !==
                        'UNINVITED' &&
                    guest?.guest_invite?.status.find((status) => status.active).status !==
                        'UNINVITED'
            )
        );
    };
    const checkIsSelectAll = () => {
        const canSelectList = guests?.filter(
            (guest) =>
                guest?.group[0]?.status.find((status) => status.active).status !== 'UNINVITED' &&
                guest?.guest_invite?.status.find((status) => status.active).status !== 'UNINVITED'
        );
        console.log(canSelectList);

        if (guestSelect?.length === canSelectList?.length) {
            return true;
        } else {
            return false;
        }
    };
    const checkCanSelectAll = () => {
        const canSelectList = guests?.filter(
            (guest) =>
                guest?.group[0]?.status.find((status) => status.active).status !== 'UNINVITED' &&
                guest?.guest_invite?.status.find((status) => status.active).status !== 'UNINVITED'
        );
        if (canSelectList?.length > 0) {
            return true;
        } else {
            return false;
        }
    };
    const handleVerifyOne = (guest, verify_status) => {
        guestManagerService
            .VerifyGuest({
                ids: [guest._id],
                verify_status: verify_status,
                event_id: eventTemplate.id,
            })
            .then(() => {
                if (verify_status == 'USER_ACCEPT') {
                    ToastNotify.Success(
                        `Đã đồng ý yêu cầu tham dự của ${guest.title + ' ' + guest.fullname}`
                    );
                } else {
                    ToastNotify.Success(
                        `Đã từ chối yêu cầu tham dự của ${guest.title + ' ' + guest.fullname}`
                    );
                }
                handleGetVerifyGuest();
                setTrigger(!trigger);
            });
    };

    const handleVerifyMany = (verify_status) => {
        if (actionMode == 'many') {
            guestManagerService
                .VerifyGuest({
                    ids: guestSelect.map(({ _id }) => _id),
                    verify_status: verify_status,
                    event_id: eventTemplate.id,
                })
                .then(() => {
                    if (verify_status == 'USER_ACCEPT') {
                        ToastNotify.Success(
                            `Đã đồng ý yêu cầu tham dự của ${guestSelect.length} khách mời`
                        );
                    } else {
                        ToastNotify.Success(
                            `Đã từ chối yêu cầu tham dự của ${guestSelect.length} khách mời`
                        );
                    }
                    setGuestSelect([]);
                    handleGetVerifyGuest();
                    setTrigger(!trigger);
                });
        } else {
            guestManagerService
                .VerifyGuest({
                    verify_status: verify_status,
                    event_id: eventTemplate.id,
                })
                .then(() => {
                    if (verify_status == 'USER_ACCEPT') {
                        ToastNotify.Success('Đã đồng ý yêu cầu tham dự của tất cả khách mời');
                    } else {
                        ToastNotify.Success('Đã từ chối yêu cầu tham dự của tất cả khách mời');
                    }
                    setGuestSelect([]);
                    setActionMode('');
                    handleGetVerifyGuest();
                    setTrigger(!trigger);
                });
        }
    };

    console.log(guestSelect);

    return (
        <>
            <VerifyList
                guests={verifyGuests}
                setSelectOneGuest={setSelectOneGuest}
                setActionMode={setActionMode}
                handleSelectGuest={handleSelectGuest}
                guestSelect={guestSelect}
                setGuestSelect={setGuestSelect}
                handleVerifyMany={handleVerifyMany}
                handelSelectAll={handelSelectAll}
                checkIsSelectAll={checkIsSelectAll}
                checkCanSelectAll={checkCanSelectAll}
            />
            <ConfirmAcceptRequest
                actionMode={actionMode}
                selectOne={selectOneGuest}
                handleVerifyOne={handleVerifyOne}
                guestSelect={guestSelect}
                handleVerifyMany={handleVerifyMany}
            />
            <ConfirmDenyRequest
                actionMode={actionMode}
                selectOne={selectOneGuest}
                handleVerifyOne={handleVerifyOne}
                guestSelect={guestSelect}
                handleVerifyMany={handleVerifyMany}
            />
        </>
    );
}

function VerifyList({
    guests,
    setSelectOneGuest,
    setActionMode,
    guestSelect,
    handleSelectGuest,
    handleVerifyMany,
    setGuestSelect,
    handelSelectAll,
    checkIsSelectAll,
    checkCanSelectAll,
}) {
    // console.log(guests);

    const showCreateBy = (guest) => {
        if (guest.createdMethod === 'GUEST') {
            return 'Khách mời tự đăng ký';
        }
        if (guest.createdMethod === 'GUEST_INVITE') {
            return `khách mời ${
                guest?.guest_invite?.fullname ? guest?.guest_invite?.fullname : 'khách khác'
            }`;
        }
        if (guest.createdMethod === 'GROUP') {
            return `nhóm ${guest?.group[0]?.fullname}`;
        }
    };

    const checkVerifyStatus = (guest) => {
        if (guest.createdMethod === 'GUEST') {
            return 'VERIFYING';
        }
        if (guest.createdMethod === 'GUEST_INVITE') {
            if (guest.guest_invite.status.find((status) => status.active).status === 'ACCEPT') {
                return 'VERIFYING';
            }
            if (guest.guest_invite.status.find((status) => status.active).status === 'DECLINE') {
                return 'CANCEL';
            }
            return 'PROCESS';
        }
        if (guest.createdMethod === 'GROUP') {
            if (guest.group[0].status.find((status) => status.active).status === 'ACCEPT') {
                return 'VERIFYING';
            }
            if (guest.group[0].status.find((status) => status.active).status === 'DECLINE') {
                return 'CANCEL';
            }
            return 'PROCESS';
        }
    };

    const convertStatus = (status) => {
        switch (status) {
            case 'VERIFYING':
                return {
                    text: 'Đang chờ xác nhận',
                    icon: <FaUserClock className="text-violet-500" />,
                };
            case 'CANCEL':
                return { text: 'Đã hủy', icon: <FaUserTimes className="text-red-500" /> };
            case 'PROCESS':
                return { text: 'Chưa hoàn thành', icon: <FaClock className="text-blue-500" /> };
            default:
                return status;
        }
    };

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="verifyTable"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered modal-xl">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Danh sách chờ duyệt
                        </h5>

                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:opacity-200 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                setGuestSelect([]);
                            }}
                        ></button>
                    </div>

                    <div className="flex flex-col gap-5 items-start p-4">
                        {guests?.length != 0 ? (
                            <>
                                {checkCanSelectAll() && (
                                    <div className="flex justify-between w-full">
                                        <label
                                            className="flex items-center gap-2"
                                            htmlFor="select-all"
                                        >
                                            <input
                                                type="checkbox"
                                                id="select-all"
                                                className={'cursor-pointer rounded '}
                                                checked={checkIsSelectAll()}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        handelSelectAll();
                                                    } else {
                                                        setGuestSelect([]);
                                                    }
                                                }}
                                            />
                                            <p className="text-sm text-black">Chọn tất cả</p>
                                        </label>
                                        <div className="flex items-center gap-2">
                                            <button
                                                disabled={guestSelect?.length === 0}
                                                className={`px-[10px] py-[4px] rounded-md text-sm text-white font-normal flex items-center gap-2 bg-blue-500  ${
                                                    guestSelect?.length === 0
                                                        ? 'opacity-70 cursor-default'
                                                        : 'hover:bg-blue-600'
                                                }`}
                                                data-bs-toggle="modal"
                                                data-bs-target="#confirmAcceptRequest"
                                                onClick={() => {
                                                    setActionMode('many');
                                                }}
                                            >
                                                <FaCheck /> Chấp nhận tham dự
                                            </button>
                                            <button
                                                disabled={guestSelect?.length === 0}
                                                data-bs-toggle="modal"
                                                data-bs-target="#confirmDenyRequest"
                                                onClick={() => {
                                                    setActionMode('many');
                                                }}
                                                className={`px-[10px] py-[4px] rounded-md text-sm text-white font-normal flex items-center gap-2 bg-red-500  ${
                                                    guestSelect?.length === 0
                                                        ? 'opacity-70 cursor-default'
                                                        : 'hover:bg-red-600'
                                                }`}
                                            >
                                                <FaTimes /> Từ chối tham dự
                                            </button>
                                        </div>
                                    </div>
                                )}

                                <div className="modal-body flex max-h-[600px] w-full flex-col items-center overflow-y-auto   gap-2">
                                    {guests?.map((guest) => (
                                        <div className="form-check flex flex-col w-full items-center px-4 py-2 rounded-lg border-[1px] border-gray-300">
                                            <label
                                                htmlFor={guest._id}
                                                className="flex items-center gap-2 h-full w-full mb-4 cursor-pointer "
                                            >
                                                {checkVerifyStatus(guest) === 'VERIFYING' && (
                                                    <input
                                                        type="checkbox"
                                                        id={guest._id}
                                                        className={'cursor-pointer rounded '}
                                                        value={guest._id}
                                                        checked={
                                                            !!guestSelect.find(
                                                                (guestItem) =>
                                                                    guestItem._id == guest._id
                                                            )
                                                        }
                                                        onChange={handleSelectGuest}
                                                    />
                                                )}
                                                <p className="text-base font-bold text-blue-500">
                                                    {guest.title} {guest.fullname}
                                                </p>
                                            </label>
                                            <div className="flex items-center w-full">
                                                <div className="flex items-center w-full font-bold text-black">
                                                    <div className="flex w-full justify-between items-center">
                                                        <div className="flex flex-col gap-2  h-full min-w-[100px]">
                                                            <p className="text-xs text-gray-700 font-medium ">
                                                                SĐT:
                                                            </p>
                                                            <p className="text-xs text-black font-semibold">
                                                                {guest.phone_number}
                                                            </p>
                                                        </div>
                                                        <div className="flex flex-col gap-2  h-full min-w-[200px]">
                                                            <p className="text-xs text-gray-700 font-medium ">
                                                                Email:
                                                            </p>
                                                            <p className="text-xs text-black font-semibold">
                                                                {guest.email}
                                                            </p>
                                                        </div>
                                                        <div className="flex flex-col gap-2  h-full min-w-[200px]">
                                                            <p className="text-xs text-gray-700 font-medium ">
                                                                Tạo bởi:
                                                            </p>
                                                            <p className="text-xs text-black font-semibold">
                                                                {showCreateBy(guest)}
                                                            </p>
                                                        </div>
                                                        <div className="flex flex-col gap-2  h-full min-w-[150px]">
                                                            <p className="text-xs text-gray-700 font-medium ">
                                                                Trạng thái:
                                                            </p>
                                                            <p className="text-xs text-black font-semibold flex gap-2 items-center">
                                                                {
                                                                    convertStatus(
                                                                        checkVerifyStatus(guest)
                                                                    ).icon
                                                                }
                                                                {
                                                                    convertStatus(
                                                                        checkVerifyStatus(guest)
                                                                    ).text
                                                                }
                                                            </p>
                                                        </div>
                                                        <>
                                                            <button
                                                                className={` dropdown dropdown-toggle relative flex items-center whitespace-nowrap rounded ${
                                                                    checkVerifyStatus(guest) ===
                                                                    'PROCESS'
                                                                        ? 'bg-gray-500 cursor-default'
                                                                        : 'bg-blue-600 hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:text-white active:shadow-lg'
                                                                }  px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-lg transition duration-150 ease-in-out  `}
                                                                type="button"
                                                                id="dropdownMenuButton4"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                <FaCog />
                                                                <svg
                                                                    aria-hidden="true"
                                                                    focusable="false"
                                                                    data-prefix="fas"
                                                                    data-icon="caret-down"
                                                                    className="w-2 ml-2"
                                                                    role="img"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 320 512"
                                                                >
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                                                                    ></path>
                                                                </svg>
                                                            </button>

                                                            <ul
                                                                className="dropdown-menu absolute z-[999] float-left m-0 mt-1 hidden min-w-max list-none rounded border-none bg-white bg-clip-padding py-2 text-left text-base shadow-lg"
                                                                aria-labelledby="dropdownMenuButton4"
                                                            >
                                                                {checkVerifyStatus(guest) ===
                                                                    'VERIFYING' && (
                                                                    <li>
                                                                        <span
                                                                            className="flex items-center w-full px-4 py-2 text-sm font-normal text-blue-500 bg-transparent cursor-pointer dropdown-item whitespace-nowrap hover:text-blue-600 hover:bg-gray-100"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#confirmAcceptRequest"
                                                                            onClick={() => {
                                                                                setSelectOneGuest(
                                                                                    guest
                                                                                );
                                                                                setActionMode('');
                                                                            }}
                                                                        >
                                                                            <FaCheck className="mr-1" />
                                                                            Chấp nhận tham dự
                                                                        </span>
                                                                    </li>
                                                                )}
                                                                {checkVerifyStatus(guest) ===
                                                                    'VERIFYING' && (
                                                                    <li>
                                                                        <span
                                                                            className="flex items-center w-full px-4 py-2 text-sm font-normal text-red-500 bg-transparent cursor-pointer dropdown-item whitespace-nowrap hover:text-red-600 hover:bg-gray-100"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#confirmDenyRequest"
                                                                            onClick={() => {
                                                                                setSelectOneGuest(
                                                                                    guest
                                                                                );
                                                                                setActionMode('');
                                                                            }}
                                                                        >
                                                                            <FaTimes className="mr-1" />
                                                                            Từ chối tham dự
                                                                        </span>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </>
                                                    </div>
                                                </div>
                                            </div>
                                            {guest?.note !== '' && (
                                                <div className="w-full py-2 border-t-[1px] border-gray-300">
                                                    <p className="text-xs text-gray-700">
                                                        <span className="text-black font-semibold">
                                                            Ghi chú:
                                                        </span>{' '}
                                                        {guest?.note}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <p className="py-4 font-bold text-center w-full">Không có khách nào</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ConfirmAcceptRequest({
    actionMode,
    guestSelect,
    selectOne,
    handleVerifyOne,
    handleVerifyMany,
}) {
    console.log(actionMode);
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmAcceptRequest"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            <FaInfoCircle className="mr-2 text-blue-500" />
                            Xác nhận đồng ý yêu cầu tham dự
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        {actionMode == 'many' && (
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                Đồng ý yêu cầu tham dự của {guestSelect?.length} khách mời
                            </h5>
                        )}
                        {actionMode == '' && (
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                Đồng ý yêu cầu tham dự của{' '}
                                {selectOne?.title + ' ' + selectOne?.fullname}
                            </h5>
                        )}
                        {actionMode == 'all' && (
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                Đồng ý yêu cầu tham dự của tất cả khách mời
                            </h5>
                        )}
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            data-bs-dismiss="modal"
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                if (actionMode == '') {
                                    handleVerifyOne(selectOne, 'USER_ACCEPT');
                                } else {
                                    handleVerifyMany('USER_ACCEPT');
                                }
                            }}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            Tiếp tục
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ConfirmDenyRequest({
    actionMode,
    guestSelect,
    selectOne,
    handleVerifyOne,
    handleVerifyMany,
}) {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmDenyRequest"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            <FaExclamationTriangle className="mr-2 text-amber-500" />
                            Xác nhận từ chối yêu cầu tham dự
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        {actionMode == 'many' && (
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                Từ chối yêu cầu tham dự của {guestSelect?.length} khách mời
                            </h5>
                        )}
                        {actionMode == '' && (
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                Từ chối yêu cầu tham dự của{' '}
                                {selectOne?.title + ' ' + selectOne?.fullname}
                            </h5>
                        )}
                        {actionMode == 'all' && (
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                Từ chối yêu cầu tham dự của tất cả khách mời
                            </h5>
                        )}
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                if (actionMode == '') {
                                    handleVerifyOne(selectOne, 'USER_DENY');
                                } else {
                                    handleVerifyMany('USER_DENY');
                                }
                            }}
                            data-bs-dismiss="modal"
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            Tiếp tục
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
