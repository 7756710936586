/* eslint-disable id-length */
/* eslint-disable tailwindcss/no-custom-classname */
const length = 8;
export default function SkeletonContent() {
    return (
        <div className="lg:w-[48rem] 2xl:w-[60rem] rounded-lg border-[1px] border-slate-200 bg-white-box shadow-lg">
            <div className="flex w-full items-center justify-between border-b-[1px] border-slate-200 px-6 py-5">
                <p className="text-[16px] font-bold text-black">Trạng thái sự kiện</p>
            </div>
            <div className="flex w-full flex-col items-center gap-5 px-6 py-5">
                {Array.from({ length }, (_, index) => (
                    <div
                        key={index}
                        className={`w-full flex justify-between bg-gray-100 h-[60px] animate-pulse'
                         p-[1rem] shadow-md items-center transition-all`}
                    >
                        <div className="flex gap-[8px] items-center"></div>
                    </div>
                ))}
            </div>
        </div>
    );
}
