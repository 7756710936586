/* eslint-disable */

export default function ModalConfirmResendPaidEmail({ handleConfirm, data }) {

    return (
        <div
            className="fade modal fixed left-0 top-0 hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
            id="modalConfirmResendPaidEmail"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="modal-dialog modal-dialog-centered pointer-events-none relative w-auto">
                <div className="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
                    <div className="modal-header flex shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4">
                        <h5 className="text-xl font-semibold leading-normal text-gray-800" >
                            Gửi email thông báo tới quản trị viên
                        </h5>
                        <button
                            type="button"
                            className="btn-close box-content h-4 w-4 rounded-none border-none p-1 text-black opacity-50 hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-header flex flex-col shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4">
                        <h5 className="text-left text-lg font-normal leading-normal text-gray-800" >
                            Bạn có muốn chúng tôi gửi lại email thông tin đã thanh toán của đơn hàng {' '}
                            <span className="font-bold">
                                {data?.order_alias_string + data?.order_alias_number?.toString()?.padStart(5, '0')}
                            </span>
                            {' '} tới quản trị viên ChungVui không?
                        </h5>
                    </div>
                    <div className="modal-footer flex shrink-0 flex-wrap items-center justify-end rounded-b-md border-t border-gray-200 p-4">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            Thoát
                        </button>
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={handleConfirm}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            Xác nhận
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
