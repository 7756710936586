/* eslint-disable */
import { useEffect, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';

export default function SortTableModal({ listTable, listGuest }) {
    const [newListTable, setNewListTable] = useState(listTable);
    const handleListTable = () => {
        const newList = listTable.map((table) => {
            const newTable = { ...table };
            newTable.guests = listGuest.filter((guest) => guest.table === table._id);
            return newTable;
        });
        setNewListTable(newList);
    };
    useEffect(() => {
        handleListTable();
    }, [listTable, listGuest]);
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="sortTableModal"
            tabIndex="-1"
            aria-modal="true"
            role="dialog"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="relative pointer-events-none modal-dialog modal-dialog-centered modal-xl max-w-[100vw] m-0">
                <div className="h-[100vh] m-0 relative flex flex-col w-full text-current bg-white border-none shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-[16px] font-bold leading-normal text-gray-800"
                            id="staticBackdropLabel"
                        >
                            Sơ đồ bàn tiệc
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="w-full h-full relative ">
                        <div className="flex justify-evenly w-full h-full pt-[20px]">
                            {newListTable.map((table) => (
                                <Draggable bounds="parent">
                                    <div className="px-2 py-1 rounded overflow-hidden h-fit text-center cursor-move w-[250px]">
                                        <p className="font-bold text-sm bg-blue-500 text-white py-1">
                                            {table.name}
                                        </p>
                                        <div className="flex flex-col border-[1px] border-blue-500">
                                            {table.guests
                                                ?.sort((a, b) => a.table_index - b.table_index)
                                                ?.map((guest) => (
                                                    <div className="flex justify-between px-2 py-1 border-b-[1px] border-blue-500">
                                                        <p className="text-sm">{guest.fullname}</p>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </Draggable>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
