/* eslint-disable */

import { FaArrowLeft, FaCheck } from 'react-icons/fa';

import PublicImg from './imgs/public.webp';
import UnPublicImg from './imgs/unpublic.webp';

export default function PublicModal({ isPublic, handlePublic }) {
    return (
        <div
            className={`fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal`}
            id="publicModal"
            tabIndex="-1"
            aria-modal="true"
            role="dialog"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered pointer-events-none relative w-auto">
                <div className="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
                    <div className="modal-header flex flex-col shrink-0 items-center justify-between rounded-t-md  p-4 px-10">
                        {isPublic ? (
                            <img src={PublicImg} className="w-[200px]" />
                        ) : (
                            <img src={UnPublicImg} className="w-[200px]" />
                        )}
                        <h5
                            className={`text-xl leading-normal ${
                                isPublic ? 'text-blue-500' : 'text-red-500'
                            } font-bold text-center mt-5 w-full`}
                        >
                            {isPublic
                                ? 'Bạn có muốn công khai thiệp này với mọi người không? '
                                : 'Bạn có muốn hủy công khai thiệp này với mọi người không? '}
                        </h5>
                        <p className="text-center mt-5 text-black font-semibold">
                            {isPublic
                                ? 'Khi công khai, bạn có thể chia sẻ đường dẫn cho mọi người và họ sẽ có thể xem thiệp của bạn ngay.'
                                : 'Khi hủy công khai, chỉ mình bạn có thể truy cập đường dẫn và xem thiệp.'}
                        </p>
                        <p className="text-center mt-5 text-gray-600 text-[12px]">
                            Bạn vẫn có thể thay đổi trạng thái thiệp cho đến khi sự kiện bắt đầu,
                            sau khi sự kiện đã diễn ra thì bạn sẽ không thể thay đổi trạng thái.
                        </p>
                    </div>
                    <div className="modal-footer flex shrink-0 flex-wrap items-center justify-center rounded-b-md p-4 pt-0">
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            className="ml-1 flex items-center gap-2 rounded bg-gray-200 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-300 active:shadow-lg"
                        >
                            <FaArrowLeft /> Trở lại
                        </button>
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={handlePublic}
                            className={`ml-1 flex items-center gap-2 rounded ${
                                isPublic
                                    ? 'bg-blue-500 hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700'
                                    : 'bg-red-500 hover:bg-red-700 hover:shadow-lg focus:bg-red-700'
                            }  px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out  focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg`}
                        >
                            <FaCheck /> Xác nhận
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
