/* eslint-disable */
import { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { MdAlternateEmail } from 'react-icons/md';
import IMAGES from '../assets/images';
import {
    FaCog,
    FaRegImages,
    FaRegClone,
    FaLink,
    FaFileDownload,
    FaEnvelope,
    FaEdit,
    FaTrash,
    FaInfo,
    FaUserTie,
    FaAngleDown,
    FaAngleUp,
    FaAngleDoubleLeft,
    FaAngleDoubleRight,
    FaAngleLeft,
    FaAngleRight,
    FaHourglass,
    FaEnvelopeOpenText,
    FaUserCheck,
    FaUserTimes,
    FaChartPie,
    FaSortAmountDown,
    FaLongArrowAltDown,
    FaLongArrowAltUp,
    FaTimes,
    FaUsers,
    FaUser,
    FaEnvelopeOpen,
    FaRedo,
    FaCheckCircle,
    FaTimesCircle,
    FaRegCheckSquare,
    FaFileExcel,
    FaChartBar,
    FaList,
    FaPhoneAlt,
    FaUserCircle,
    FaPenFancy,
    FaMailBulk,
    FaThLarge,
    FaCheck,
    FaUserPlus,
    FaSlidersH,
    FaCaretDown,
    FaFileImport,
    FaFileExport,
    FaDownload,
    FaToggleOn,
    FaUndoAlt,
} from 'react-icons/fa';
import ToastNotify from '../../../../../utils/Toast';
import image from '../../../../../assets/images/_index';
import Blank from './Blank';
import { RiMailSettingsFill } from 'react-icons/ri';

const statusColor = (status) => {
    switch (status) {
        case 'UNINVITED':
            return { from: '#fbbf24', to: '#facc15' };
        case 'INVITED':
            return { from: '#60a5fa', to: '#22d3ee' };
        case 'ACCEPT':
            return { from: '#4ade80', to: '#34d399' };
        case 'DECLINE':
            return { from: '#f87171', to: '#fb7185' };
        case 'SENDING':
            return { from: '#38bdf8', to: '#5eead4' };
        case 'VIP':
            return '#a78bfa';
    }
};

const statusConvert = (status = '') => {
    switch (status) {
        case 'UNINVITED':
            return 'Chưa gửi lời mời';
        case 'SENDING':
            return 'Đang gửi lời mời';
        case 'INVITED':
            return 'Đã gửi lời mời';
        case 'ACCEPT':
            return 'Đồng ý tham dự';
        case 'DECLINE':
            return 'Từ chối tham dự';
        case 'VERIFYING':
            return 'Đang chờ xác nhận';
        case 'USER_ACCEPT':
            return 'Chấp nhận yêu cầu tham dự';
        case 'USER_DENY':
            return 'Từ chối yêu cầu tham dự';
        case 'true':
            return 'Khách VIP';
        case 'false':
            return 'Khách thường';
        case 'USER':
            return 'Thêm bởi chủ sự kiện';
        case 'GROUP':
            return 'Thêm bởi nhóm khách';
        case 'GUEST':
            return 'Khách tự đăng ký';
        case 'GUEST_INVITE':
            return 'Được thêm bởi khách';
        default:
            return;
    }
};

export default function GroupList({
    query,
    setQuery,
    groups,
    eventTemplate,
    onCopyInvite,
    onCopyLink,
    onCopyImage,
    onDownloadTemplate,
    selectGroups,
    setSelectGroups,
    setActionMode,
    setSelectOne,
    onUpdateGroup,
    onReadGroup,
    getGroups,
    guestQuery,
    setGuestQuery,
    setViewMode,
    setMode,
    actionMode,
    handleCreateGroup,
    onOpenChooseFile,
    onDownloadExcel,
    onDownloadExcelTemplate,
    refFile,
    onChangeFileSelect,
    resetAndAddGuestQuery,
    statisticalData,
    trigger,
    setTrigger,
}) {
    const onCopyImageInside = (e) => {
        e.stopPropagation();
        onCopyImage(e);
    };
    const onDownloadTemplateInside = (e) => {
        e.stopPropagation();
        onDownloadTemplate(e);
    };

    const [firstCall, setFirstCall] = useState(false);

    useEffect(() => {
        if (firstCall) {
            const timeOutId = setTimeout(() => getGroups(eventTemplate.id), 500);
            return () => clearTimeout(timeOutId);
        } else {
            setFirstCall(true);
        }
    }, [query.search]);

    const selectGroup = (e) => {
        e.stopPropagation();
        const { value, checked } = e.target;

        let selectedGroup = [...selectGroups];
        if (checked) {
            setActionMode('many');
            selectedGroup.push(groups.find((group) => group._id == value));
            setSelectGroups(selectedGroup);
        } else {
            setActionMode('many');
            selectedGroup = selectedGroup.filter(
                (groupSelectedItem) => groupSelectedItem._id != value
            );
            setSelectGroups(selectedGroup);
        }
    };
    const checkedAllSelected = (groups) =>
        groups.every((group) => selectGroups.map(({ _id }) => _id).includes(group._id));
    const selectAll = () => {
        let selected = [...selectGroups];
        groups.map((group) => {
            if (!selectGroups.map(({ _id }) => _id).includes(group._id)) {
                selected.push(group);
            }
        });
        setSelectGroups(selected);
    };

    const removeSelectAll = () => {
        setActionMode('many');
        setSelectGroups([]);
    };

    const checkStatus = (status) => {
        switch (status) {
            case 'UNINVITED':
                return {
                    color: 'amber-500',
                    bg: 'yellow-100',
                    icon: <FaEnvelope />,
                    name: 'Chưa gửi lời mời',
                };
            case 'SENDING':
                return {
                    color: 'sky-500',
                    bg: 'sky-100',
                    icon: <FaHourglass />,
                    name: 'Đang gửi lời mời',
                };
            case 'INVITED':
                return {
                    color: 'blue-500',
                    bg: 'blue-100',
                    icon: <FaEnvelopeOpenText />,
                    name: 'Đã gửi lời mời',
                };
            case 'ACCEPT':
                return {
                    color: 'green-500',
                    bg: 'green-100',
                    icon: <FaUserCheck />,
                    name: 'Đồng ý tham dự',
                };
            case 'DECLINE':
                return {
                    color: 'red-500',
                    bg: 'red-100',
                    icon: <FaUserTimes />,
                    name: 'Từ chối tham dự',
                };
        }
    };

    function countFilter() {
        const filter = {
            status: query.status,
            vip: query.vip,
        };
        let count = 0;

        for (const key in filter) {
            if (filter.hasOwnProperty(key) && filter[key] !== '') {
                count++;
            }
        }

        return count;
    }

    const handleRemoveFilter = (key) => {
        setQuery({ ...query, [key]: '' });
    };

    const handleClearSearch = () => {
        setQuery({ ...query, search: '', status: '', vip: '' });
    };

    const handleReload = () => {
        setTrigger(!trigger);
        ToastNotify.Success('Đã làm mới danh sách!');
    };

    return (
        <div className="relative flex flex-col justify-start mb-6 break-words">
            <div className="mb-0 border-0 rounded-t">
                <div className="flex flex-col items-start w-full">
                    <div className="flex flex-col mt-[10px] gap-[10px] w-full">
                        <div className="flex items-center justify-between gap-2 max-md:flex-col">
                            <div className="relative flex flex-wrap items-stretch md:w-[400px] w-[250px] transition-all rounded-lg ease-soft ">
                                <span className="ease-soft leading-5.6 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-r-none border border-r-0 border-transparent bg-transparent px-2.5 py-2 text-center text-sm font-normal text-slate-500 transition-all">
                                    <i className="fas fa-search" aria-hidden="true"></i>
                                </span>
                                <input
                                    type="text"
                                    value={query.search}
                                    onChange={(e) => setQuery({ ...query, search: e.target.value })}
                                    className="focus:shadow-soft-primary-outline ease-soft w-1/100 leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 pl-9 pr-3 text-sm text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-600 focus:outline-none focus:transition-shadow"
                                    placeholder="Tìm kiếm theo tên nhóm"
                                />
                                <div className="flex gap-1 items-center">
                                    <button
                                        onClick={handleReload}
                                        className="absolute group top-1/2 -translate-y-1/2 right-[36px] flex items-center gap-2 px-2 py-2 text-sm text-white bg-blue-500 rounded-md"
                                    >
                                        <FaUndoAlt />
                                        <span className="absolute -top-3 left-[50%] hidden -translate-y-full translate-x-[-50%] rounded-lg bg-gray-700  px-2 py-1 text-center text-sm font-thin text-white after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-gray-700 after:content-[''] group-hover:flex w-[76px]">
                                            Làm mới
                                        </span>
                                    </button>

                                    <button
                                        className="absolute group top-1/2 -translate-y-1/2 right-[4px] flex items-center gap-2 px-2 py-2 text-sm text-white bg-blue-500 rounded-md"
                                        data-bs-toggle="modal"
                                        data-bs-target="#filterModal"
                                    >
                                        <div className="absolute inline-flex items-center justify-center w-5 h-5 text-[10px] text-white bg-red-500 border-2 border-white rounded-full -right-2 -top-1">
                                            {countFilter()}
                                        </div>
                                        <FaSlidersH />
                                        <span className="absolute -top-3 left-[50%] hidden -translate-y-full translate-x-[-50%] rounded-lg bg-gray-700  px-2 py-1 text-center text-sm font-thin text-white after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-gray-700 after:content-[''] group-hover:flex">
                                            Lọc
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="flex bg-gray-100 gap-1 rounded-md">
                                <button
                                    onClick={() => {
                                        resetAndAddGuestQuery({ verify_status: '' });
                                    }}
                                    className={`py-3 px-4 text-xs text-black font-bold rounded-md `}
                                >
                                    Khách mời
                                </button>
                                <button
                                    className={`py-3 px-4 text-xs  font-bold rounded-md bg-blue-500 text-white`}
                                >
                                    Nhóm khách
                                </button>
                                <button
                                    onClick={() => {
                                        resetAndAddGuestQuery({ verify_status: 'VERIFYING' });
                                    }}
                                    className={`py-3 px-4 text-xs text-black font-bold rounded-md flex items-center gap-1`}
                                >
                                    Chờ duyệt
                                    <div className="flex justify-center items-center w-[24px] h-[24px] rounded-full bg-red-500">
                                        <span className="text-xs text-white">
                                            {statisticalData[0]?.guestVerifying}
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="flex items-center gap-[12px]">
                            {query.status !== '' && (
                                <div className="py-1 px-4 bg-gray-400 text-sm text-white rounded-full flex items-center gap-[8px]">
                                    {statusConvert(query.status)}
                                    <button
                                        onClick={() => {
                                            handleRemoveFilter('status');
                                        }}
                                    >
                                        <FaTimes />
                                    </button>
                                </div>
                            )}
                            {query.vip !== '' && (
                                <div className="py-1 px-4 bg-gray-400 text-sm text-white rounded-full flex items-center gap-[8px]">
                                    {statusConvert(query.vip)}
                                    <button
                                        onClick={() => {
                                            handleRemoveFilter('vip');
                                        }}
                                    >
                                        <FaTimes />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="w-full flex md:flex-row flex-col justify-start gap-[10px] my-[10px]">
                        <button
                            className={`px-[10px] py-[6px] rounded-md text-xs text-white  font-normal flex items-center gap-2 ${
                                eventTemplate?.isExpired ? 'bg-gray-400' : 'bg-blue-500'
                            }`}
                            onClick={handleCreateGroup}
                            disabled={eventTemplate?.isExpired}
                        >
                            <FaUserPlus /> Thêm nhóm khách
                        </button>
                        <div className="flex items-center gap-[8px]">
                            <button
                                id="dropdownMenuButtonExcel"
                                aria-expanded="false"
                                data-bs-toggle="dropdown"
                                className={`  bg-blue-500 cursor-pointer flex items-center gap-2 px-[10px] py-[6px] rounded-md text-xs text-white  font-normal`}
                            >
                                <FaFileExcel />
                                File Excel <FaCaretDown className="ml-1 mt-0.5" />
                            </button>
                            <ul
                                className="dropdown-menu absolute z-[999] float-left m-0 mt-1 hidden min-w-max list-none rounded border bg-white bg-clip-padding py-2  text-center text-base shadow-lg"
                                aria-labelledby="dropdownMenuButtonExcel"
                            >
                                <li>
                                    <button
                                        onClick={() => {
                                            onOpenChooseFile();
                                        }}
                                        disabled={eventTemplate?.isExpired}
                                        style={{
                                            backgroundColor:
                                                eventTemplate?.isExpired &&
                                                'rgb(243 244 246 / var(--tw-bg-opacity))',
                                        }}
                                        className={`flex items-center ${
                                            eventTemplate?.isExpired
                                                ? 'bg-gray-100'
                                                : 'cursor-pointer hover:bg-blue-100'
                                        } gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700 bg-transparent  dropdown-item whitespace-nowrap `}
                                    >
                                        <FaFileImport /> Import
                                    </button>
                                </li>
                                <li>
                                    <span
                                        onClick={onDownloadExcel}
                                        className={`flex items-center cursor-pointer hover:bg-blue-100
                                             gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700 bg-transparent  dropdown-item whitespace-nowrap `}
                                    >
                                        <FaFileExport /> Export
                                    </span>
                                </li>
                                <li>
                                    <span
                                        onClick={onDownloadExcelTemplate}
                                        className={`flex items-center cursor-pointer hover:bg-blue-100
                                             gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700 bg-transparent  dropdown-item whitespace-nowrap `}
                                    >
                                        <FaDownload /> Tải mẫu
                                    </span>
                                </li>
                            </ul>
                            <input
                                ref={refFile}
                                onChange={onChangeFileSelect}
                                name="file"
                                type="file"
                                className="hidden"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
                        </div>
                        {eventTemplate?.is_public && (
                            <div className="flex items-center gap-[8px]">
                                <button
                                    id="dropdownMenuButtonSendMail"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    className={` 
                                            bg-blue-500 cursor-pointer
                                                   
                                             flex items-center gap-2 px-[10px] py-[6px] rounded-md text-xs text-white  font-normal`}
                                >
                                    <FaEnvelope />
                                    Gửi lời mời qua email <FaCaretDown className="ml-1 mt-0.5" />
                                </button>
                                <ul
                                    className="dropdown-menu absolute z-[999] float-left m-0 mt-1 hidden min-w-max list-none rounded bg-white bg-clip-padding py-2  text-center text-base shadow-lg"
                                    aria-labelledby="dropdownMenuButtonSendMail"
                                >
                                    <li>
                                        <button
                                            data-bs-toggle={selectGroups?.length > 0 ? 'modal' : ''}
                                            data-bs-target={
                                                eventTemplate?.email_host != '' &&
                                                eventTemplate?.email_host
                                                    ? '#confirmSendEmail'
                                                    : '#confirmAddEmailModal'
                                            }
                                            disabled={
                                                eventTemplate?.isExpired ||
                                                selectGroups?.length === 0
                                            }
                                            style={{
                                                backgroundColor:
                                                    (eventTemplate?.isExpired ||
                                                        selectGroups?.length === 0) &&
                                                    'rgb(243 244 246 / var(--tw-bg-opacity))',
                                            }}
                                            className={`flex items-center ${
                                                eventTemplate?.isExpired ||
                                                selectGroups?.length === 0
                                                    ? 'bg-gray-100'
                                                    : 'cursor-pointer hover:bg-blue-100'
                                            } gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700 bg-white  dropdown-item whitespace-nowrap `}
                                        >
                                            <FaEnvelope className="mr-1.5" />
                                            Gửi ngay
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        )}
                        <div className="flex items-center gap-[8px]">
                            <button
                                id="dropdownMenuButtonSendMail"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                className={` 
                                            bg-blue-500 cursor-pointer
                                                   
                                             flex items-center gap-2 px-[10px] py-[6px] rounded-md text-xs text-white  font-normal`}
                            >
                                <RiMailSettingsFill />
                                Cài đặt sự kiện <FaCaretDown className="ml-1 mt-0.5" />
                            </button>
                            <ul
                                className="dropdown-menu absolute z-[999] float-left m-0 mt-1 hidden min-w-max list-none rounded bg-white bg-clip-padding py-2  text-center text-base shadow-lg"
                                aria-labelledby="dropdownMenuButtonSendMail"
                            >
                                <li>
                                    <button
                                        // disabled={eventTemplate?.isExpired}
                                        data-bs-toggle="modal"
                                        data-bs-target="#settingEmailModal"
                                        className={`flex items-center cursor-pointer hover:bg-blue-100
                                                 gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700 bg-white  dropdown-item whitespace-nowrap `}
                                    >
                                        <FaCog />
                                        Cài đặt email
                                    </button>
                                </li>
                                <li>
                                    <button
                                        // disabled={eventTemplate?.isExpired}
                                        data-bs-toggle="modal"
                                        data-bs-target="#contentEmailModal"
                                        className={`flex items-center 
                                                  
                                                        cursor-pointer hover:bg-blue-100
                                                 gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700 bg-white  dropdown-item whitespace-nowrap `}
                                    >
                                        <FaEnvelopeOpenText />
                                        Nội dung email
                                    </button>
                                </li>
                                <li>
                                    <button
                                        // disabled={eventTemplate?.isExpired}
                                        data-bs-toggle="modal"
                                        data-bs-target="#remindModal"
                                        className={`flex items-center cursor-pointer hover:bg-blue-100
                                             gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700 bg-white  dropdown-item whitespace-nowrap `}
                                    >
                                        <FaToggleOn />
                                        Cài đặt
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {actionMode == 'all' && (
                        <div className="flex flex-col items-center justify-center w-full mt-6 mb-5 xl:flex-row">
                            <p className="text-[16px] font-bold leading-[25px] text-black">
                                Bạn đã chọn tất cả {query.totalDocs} nhóm khách mời !
                            </p>
                            <p
                                className="ml-3 cursor-pointer text-[16px] font-bold leading-[25px] text-red-500"
                                onClick={() => {
                                    setSelectGroups([]);
                                    setActionMode('many');
                                }}
                            >
                                Bỏ chọn.
                            </p>
                        </div>
                    )}
                </div>
            </div>

            <div className="block w-full pb-4 overflow-x-auto">
                {actionMode != 'all' && selectGroups.length != 0 && (
                    <div className="flex flex-col items-center justify-center w-full mt-6 mb-5 xl:flex-row">
                        <p className="mr-1 text-center text-[16px] font-bold leading-[25px] text-black">
                            Đã chọn {selectGroups.length}/{query.totalDocs} nhóm khách mời!
                        </p>
                        <p
                            className="text-blue cursor-pointer text-center text-[16px] font-bold leading-[25px] text-blue-500"
                            onClick={() => {
                                setActionMode('all');
                                selectAll(groups);
                            }}
                        >
                            Chọn tất cả
                        </p>
                        <p className="ml-1 text-[16px] font-bold leading-[25px] text-black ">
                            hoặc
                        </p>
                        <p
                            className="ml-1 cursor-pointer text-[16px] font-bold leading-[25px] text-red-500"
                            onClick={() => {
                                removeSelectAll();
                            }}
                        >
                            bỏ chọn.
                        </p>
                    </div>
                )}

                <div className="flex flex-wrap w-full gap-4 p-2 lg:px-0 px-2 mx-auto">
                    {groups.map((group, i) => (
                        <div
                            key={i}
                            className={`flex flex-col md:w-[300px] w-full rounded-lg bg-white py-2 px-4 shadow-xl cursor-pointer ${
                                !!selectGroups.find((groupItem) => groupItem._id == group._id) &&
                                'border-[2px] border-blue-500'
                            }`}
                            onClick={() => {
                                resetAndAddGuestQuery({ group: group._id, verify_status: '' });
                            }}
                        >
                            <div
                                className="flex items-center justify-between w-full py-2"
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <input
                                    type="checkbox"
                                    id={group._id}
                                    className={'mr-2 cursor-pointer rounded-full'}
                                    value={group._id}
                                    checked={
                                        !!selectGroups.find(
                                            (groupItem) => groupItem._id == group._id
                                        )
                                    }
                                    onChange={selectGroup}
                                />
                                <div className="flex items-center">
                                    <button
                                        className=" dropdown dropdown-toggle relative flex items-center whitespace-nowrap rounded-full px-2 py-1.5 text-xs font-medium uppercase leading-tight text-black transition duration-150 ease-in-out hover:bg-[rgba(192,192,192,0.7)] "
                                        type="button"
                                        title="Chi tiết"
                                        onClick={(e) => {
                                            onReadGroup(group._id);
                                        }}
                                    >
                                        <FaInfo className="text-[16px]" />
                                    </button>
                                    <div>
                                        <button
                                            className=" dropdown dropdown-toggle relative flex items-center whitespace-nowrap rounded-full px-2 py-1.5 text-xs font-medium uppercase leading-tight text-black transition duration-150 ease-in-out hover:bg-[rgba(192,192,192,0.7)] "
                                            type="button"
                                            id={group._id}
                                            title="Hành động"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <FaCog className="text-[16px]" />
                                        </button>
                                        <ul
                                            className="dropdown-menu absolute z-[999] float-left m-0 mt-1 hidden min-w-max list-none rounded border-none bg-white bg-clip-padding py-2 text-left text-base shadow-lg"
                                            aria-labelledby={group._id}
                                        >
                                            {eventTemplate?.is_public && (
                                                <>
                                                    <li>
                                                        <span
                                                            className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-transparent cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                            itemID={group._id}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onCopyInvite(group._id);
                                                            }}
                                                        >
                                                            <FaRegClone className="mr-1" />
                                                            Sao chép lời mời
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span
                                                            className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-transparent cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                            itemID={group._id}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onCopyLink(group._id);
                                                            }}
                                                        >
                                                            <FaLink className="mr-1" />
                                                            Sao chép đường dẫn thiệp
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span
                                                            className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-transparent cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                            itemID={group._id}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onCopyLink(group._id, true);
                                                            }}
                                                        >
                                                            <FaLink className="mr-1" />
                                                            Sao chép đường dẫn xác nhận tham dự
                                                        </span>
                                                    </li>
                                                    {!eventTemplate?.isExpired && (
                                                        <li>
                                                            <span
                                                                className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-transparent cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                                itemID={group._id}
                                                                data-bs-toggle={
                                                                    group.email != '' && 'modal'
                                                                }
                                                                data-bs-target={
                                                                    eventTemplate.email_host !=
                                                                        '' &&
                                                                    eventTemplate.email_host
                                                                        ? '#confirmSendOnlyGuest'
                                                                        : '#confirmAddEmail4Only'
                                                                }
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    if (
                                                                        group.email != '' ||
                                                                        group.email
                                                                    ) {
                                                                        setSelectOne(group._id);
                                                                    } else {
                                                                        ToastNotify.Error(
                                                                            `${group.fullname} chưa có email!`
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <FaEnvelope className="mr-1" />
                                                                Gửi lời mời qua email
                                                            </span>
                                                        </li>
                                                    )}
                                                </>
                                            )}
                                            <li>
                                                <span
                                                    className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-transparent cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                    itemID={group._id}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onReadGroup(group._id);
                                                    }}
                                                >
                                                    <FaInfo className="mr-1" />
                                                    Chi tiết
                                                </span>
                                            </li>
                                            {!eventTemplate?.isExpired && (
                                                <>
                                                    <li>
                                                        <span
                                                            className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-transparent cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                            itemID={group._id}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                onUpdateGroup(group._id);
                                                            }}
                                                        >
                                                            <FaEdit className="mr-1" />
                                                            Sửa
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span
                                                            className="flex items-center w-full px-4 py-2 text-sm font-normal text-red-600 bg-transparent cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                            itemID={group._id}
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#confirmDeleteOne"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setSelectOne(group._id);
                                                            }}
                                                        >
                                                            <FaTrash className="mr-1" />
                                                            Xóa
                                                        </span>
                                                    </li>
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={`flex items-center gap-4 py-1`}>
                                <div className="flex items-center">
                                    <div
                                        className={`relative bg-${
                                            checkStatus(
                                                group.status?.find(({ active }) => active === true)
                                                    ?.status
                                            ).bg
                                        } text-${
                                            checkStatus(
                                                group.status?.find(({ active }) => active === true)
                                                    ?.status
                                            ).color
                                        } w-[60px] h-[60px] flex justify-center items-center text-[28px] rounded-md`}
                                    >
                                        {
                                            checkStatus(
                                                group.status?.find(({ active }) => active === true)
                                                    ?.status
                                            ).icon
                                        }
                                        {group.is_important && (
                                            <div className="absolute inline-flex items-center justify-center p-1 text-[10px] font-bold text-white bg-violet-500 border-2 border-white rounded-full -right-2 -top-1">
                                                <FaUserTie />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex w-[250px] flex-col justify-between p-1">
                                    <div className="w-full h-full rounded-xl">
                                        <div className="flex items-center justify-between w-full mt-1 md:items-start ">
                                            <div className="flex items-center py-1.5">
                                                <p
                                                    className="flex items-center text-[15px] font-bold text-black"
                                                    title={group.fullname}
                                                >
                                                    {group.fullname.length > 15
                                                        ? group.fullname.substring(0, 12) + '...'
                                                        : group.fullname}{' '}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center w-full gap-1">
                                        <div
                                            className={`bg-${
                                                checkStatus(
                                                    group.status?.find(
                                                        ({ active }) => active === true
                                                    )?.status
                                                ).bg
                                            } text-${
                                                checkStatus(
                                                    group.status?.find(
                                                        ({ active }) => active === true
                                                    )?.status
                                                ).color
                                            } px-2 py-1 text-[12px] rounded-full`}
                                        >
                                            {
                                                checkStatus(
                                                    group.status?.find(
                                                        ({ active }) => active === true
                                                    )?.status
                                                ).name
                                            }
                                        </div>
                                        {group.is_important && (
                                            <div
                                                className={`bg-violet-100 text-violet-700 px-2 py-1 text-[12px] rounded-full`}
                                            >
                                                VIP
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="w-full p-4 border-t-[1px] boder-gray-300 flex gap-4 text-sm">
                                <FaPhoneAlt /> {group.phone_number || 'Chưa có'}
                            </div>
                            <div className="w-full p-4 border-t-[1px] boder-gray-300 flex gap-4 text-sm">
                                <FaMailBulk /> {group.email || 'Chưa có'}
                            </div>
                        </div>
                    ))}
                </div>

                {groups.length == 0 &&
                    (query.search?.trim() === '' && countFilter() === 0 ? (
                        <div className="flex w-full flex-col items-center justify-center mt-10">
                            <img
                                src={image.GUEST_MANAGER}
                                alt="not found"
                                className="mx-auto w-1/3 opacity-70"
                            />
                            <h4 className="mt-5 text-center text-xl text-blue-900 ">
                                Chưa có nhóm khách mời nào
                            </h4>
                            {/* <p className="mt-2 text-center text-sm text-black">
                                Vui lòng nhập thông tin khách mời tại đây để bạn có thể gửi lời mời
                                trân trọng nhất đến từng người nhận.
                            </p> */}
                        </div>
                    ) : (
                        <Blank handleClear={handleClearSearch} />
                    ))}
            </div>
        </div>
    );
}
