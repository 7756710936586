/* eslint-disable */
import { FaExclamationTriangle, FaInfoCircle, FaTrash } from 'react-icons/fa';

export function ConfirmDeleteOneGroup({ group, deleteOneGroup }) {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmDeleteOneGroupModal"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận xóa nhóm khách
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-base font-medium leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Thao tác này sẽ xóa toàn bộ khách trong nhóm. Bạn có muốn tiếp tục?
                        </h5>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            onClick={deleteOneGroup}
                            data-bs-dismiss="modal"
                            className="ml-1 flex items-center rounded bg-red-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg"
                        >
                            Xóa
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ConfirmAddEmailModal() {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmAddEmailModal"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận gửi email
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-base font-medium leading-normal text-left text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Chưa có email nhận phản hồi. Bạn có thể tiếp tục gửi hoặc thêm email
                            phản hồi.
                        </h5>
                    </div>
                    {/* <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                        <button
                            type="button"
                            class="inline-block px-6 py-2.5  text-black bg-gray-300 font-medium text-xs leading-tight uppercase rounded shadow-md hover: hover:shadow-lg focus: focus:shadow-lg focus:outline-none focus:ring-0 active: active:shadow-lg transition duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            data-bs-toggle={'modal'}
                            data-bs-target={'#confirmSendEmail'}
                            class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                        >
                            Thêm email nhận phản hòi
                        </button>
                    </div> */}
                    <div className="flex flex-col flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            data-bs-toggle={'modal'}
                            data-bs-target={'#settingEmailModal'}
                            className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-600 rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg "
                        >
                            Thêm email nhận phản hồi
                        </button>

                        <button
                            type="button"
                            data-bs-toggle={'modal'}
                            data-bs-target={'#confirmSendEmail'}
                            className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-600 rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg "
                        >
                            Tiếp tục gửi email
                        </button>
                        <button
                            type="button"
                            className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-gray-700 uppercase transition duration-150 ease-in-out bg-gray-200 rounded shadow-xl hover: focus: active: hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-2xl"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ConfirmImportModal({
    onOpenChooseFile,
    refFile,
    refBtnImportText,
    onChangeFileSelect,
}) {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmImportModal"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-md modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận thêm file excel
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-center p-4 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-base font-medium leading-normal text-left text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Bạn muốn chèn file excel?
                        </h5>
                    </div>
                    <div className="flex flex-col flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            ref={refBtnImportText}
                            onClick={() => {
                                onOpenChooseFile();
                            }}
                            data-bs-dismiss="modal"
                            className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-600 rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg "
                        >
                            Bổ sung vào danh sách
                        </button>

                        <button
                            type="button"
                            ref={refBtnImportText}
                            data-bs-dismiss="modal"
                            onClick={() => {
                                onOpenChooseFile();
                            }}
                            className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-600 rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg "
                        >
                            Thay thế toàn bộ danh sách
                        </button>
                        <button
                            type="button"
                            className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-gray-700 uppercase transition duration-150 ease-in-out bg-gray-200 rounded shadow-xl hover: focus: active: hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-2xl"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                        <input
                            ref={refFile}
                            onChange={onChangeFileSelect}
                            name="file"
                            type="file"
                            className="hidden"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ConfirmGuest({ mode, formik, handleSubmitGuest }) {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmGuest"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận {mode == 'guest-update' ? 'sửa' : 'thêm'}
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-base font-medium leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            {mode == 'guest-update' ? 'Sửa thông tin' : 'Thêm'}{' '}
                            {formik.values.title && formik.values.title + ' '}
                            {formik.values.fullname}?
                        </h5>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={handleSubmitGuest}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            {mode == 'guest-update' ? 'Lưu' : 'Thêm'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ConfirmGroup({ mode, formik, handleSubmitGroup }) {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmGroup"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalS.crollableLabel"
                        >
                            Xác nhận {mode == 'group-update' ? 'sửa' : 'tạo'} nhóm
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-base font-medium leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            {mode == 'group-update' ? 'Sửa thông tin' : 'Tạo'} nhóm{' '}
                            {formik.values.fullname}?
                        </h5>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={handleSubmitGroup}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            {mode == 'group-update' ? 'Lưu' : 'Tạo'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ConfirmAddEmail4Only() {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmAddEmail4Only"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận gửi email
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-base font-medium leading-normal text-left text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Chưa có email nhận phản hồi. Bạn có thể tiếp tục gửi hoặc thêm email
                            phản hồi
                        </h5>
                    </div>

                    <div className="flex flex-col flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            data-bs-toggle={'modal'}
                            data-bs-target={'#settingEmailModal'}
                            className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-600 rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg "
                        >
                            Thêm email nhận phản hồi
                        </button>

                        <button
                            type="button"
                            data-bs-toggle={'modal'}
                            data-bs-target={'#confirmSendOnlyGuest'}
                            className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-600 rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg "
                        >
                            Tiếp tục gửi email
                        </button>
                        <button
                            type="button"
                            className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-gray-700 uppercase transition duration-150 ease-in-out bg-gray-200 rounded shadow-xl hover: focus: active: hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-2xl"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export function ConfirmAddEmail4OnlyGroup() {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmAddEmail4OnlyGroup"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận gửi email
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-base font-medium leading-normal text-left text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Chưa có email nhận phản hồi. Bạn có thể tiếp tục gửi hoặc thêm email
                            phản hồi
                        </h5>
                    </div>

                    <div className="flex flex-col flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            data-bs-toggle={'modal'}
                            data-bs-target={'#settingEmailModal'}
                            className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-600 rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg "
                        >
                            Thêm email nhận phản hồi
                        </button>

                        <button
                            type="button"
                            data-bs-toggle={'modal'}
                            data-bs-target={'#confirmSendOnlyGroup'}
                            className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-600 rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg "
                        >
                            Tiếp tục gửi email
                        </button>
                        <button
                            type="button"
                            className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-gray-700 uppercase transition duration-150 ease-in-out bg-gray-200 rounded shadow-xl hover: focus: active: hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-2xl"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export function ConfirmBackModal({ viewMode, setMode, formik, mode, onReadGuest, onReadGroup }) {
    const onClearForm = () => {
        // formik.resetForm();
        // setMode(
        //     viewMode === 'guest'
        //         ? mode === 'guest-update'
        //             ? 'guest-read'
        //             : 'default'
        //         : 'group-default'
        // );
        if (viewMode === 'guest') {
            if (mode !== 'guest-update') {
                formik.resetForm();
                setMode('default');
            } else {
                onReadGuest(formik.values._id);
            }
        } else {
            if (mode !== 'group-update') {
                formik.resetForm();
                setMode('group-default');
            } else {
                onReadGroup(formik.values._id);
            }
        }
    };
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmBackModal"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận thoát
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-base font-medium leading-normal text-left text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Dữ liệu sẽ bị mất bạn có chắc muốn tiếp tục thoát ra ngoài?
                        </h5>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={onClearForm}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            Tiếp tục
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ConfirmDeleteModal({
    guestSelect,
    deleteManyGuest,
    guests,
    actionMode,
    viewMode,
    deleteManyGroup,
    groups,
    selectGroups,
}) {
    const newSelect =
        viewMode == 'guest'
            ? guestSelect.filter((select) => guests.map(({ _id }) => _id).includes(select._id))
            : selectGroups.filter((select) => groups.map(({ _id }) => _id).includes(select._id));
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmDeleteModal"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            {viewMode == 'guest'
                                ? 'Xác nhận xóa khách mời'
                                : 'Xác nhận xóa nhóm khách mời'}
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    {viewMode == 'guest' ? (
                        <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                {actionMode == 'many' &&
                                    `Xóa ${newSelect.length} khách mời khỏi danh sách?`}
                                {actionMode == 'all' && 'Xóa tất khách mời khỏi danh sách?'}
                            </h5>
                        </div>
                    ) : (
                        <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                {actionMode == 'many' && `Xóa ${newSelect.length} nhóm khách mời?`}
                                {actionMode == 'all' && 'Xóa tất cả nhóm khách mời?'}
                            </h5>
                        </div>
                    )}
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            onClick={viewMode == 'guest' ? deleteManyGuest : deleteManyGroup}
                            data-bs-dismiss="modal"
                            className="ml-1 inline-block rounded bg-red-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg"
                        >
                            Xóa
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export function ConfirmDeleteOne({ deleteOnlyGuest, deleteOneGroup, guests, selectOne, viewMode }) {
    const selectedGuest = guests.find((guest) => guest._id === selectOne);

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmDeleteOne"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            {viewMode == 'guest'
                                ? 'Xác nhận xóa khách mời'
                                : 'Xác nhận xóa nhóm khách mời'}
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-base font-medium leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            {viewMode == 'guest'
                                ? `Xóa ${
                                      selectedGuest?.title + ' ' + selectedGuest?.fullname
                                  } khỏi danh
                            sách?`
                                : 'Xác nhận xóa nhóm khách mời?'}
                        </h5>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            onClick={viewMode == 'guest' ? deleteOnlyGuest : deleteOneGroup}
                            data-bs-dismiss="modal"
                            className="ml-1 inline-block rounded bg-red-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg"
                        >
                            Xóa
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export function ConfirmDeleteAll({ deleteAllGuest }) {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmDeleteAll"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận làm mới tất cả danh sách
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <p
                            className="text-base font-medium leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Thao tác này sẽ xóa hết tất cả khách mời và nhóm khách mời bạn có muốn
                            tiếp tục?
                        </p>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            onClick={deleteAllGuest}
                            data-bs-dismiss="modal"
                            className="ml-1 inline-block rounded bg-blue-500 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            Tiếp tục
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export function ConfirmRemoveGuestModal({ guest, group, onRemoveFromGroup }) {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmRemoveFromGroup"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận xóa khách mời khỏi nhóm
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-base font-medium leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xóa {guest?.title + ' ' + guest?.fullname} khỏi nhóm {group?.fullname}
                        </h5>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            onClick={onRemoveFromGroup}
                            data-bs-dismiss="modal"
                            className="ml-1 inline-block rounded bg-red-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg"
                        >
                            Xóa
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export function ConfirmSendOnlyGuest({ guests, handleSendToOne, selectOne, viewMode, groups }) {
    const selected =
        viewMode == 'guest'
            ? guests.find((guest) => guest._id === selectOne)
            : groups.find((group) => group._id === selectOne);
    const checkInvited =
        selected?.status?.find(({ active }) => active === true).status !== 'UNINVITED';
    // console.log(selectOne)
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmSendOnlyGuest"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-md modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận gửi email
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center p-4 modal-header shrink-0 rounded-t-md">
                        {viewMode == 'guest' ? (
                            <h5
                                className="text-base font-medium leading-normal text-left text-black"
                                id="exampleModalScrollableLabel"
                            >
                                {checkInvited
                                    ? `${
                                          selected?.title + ' ' + selected?.fullname
                                      } đã được gửi lời mời từ trước bạn có muốn gửi lại?`
                                    : `Gửi lời mời tới ${
                                          selected?.title + ' ' + selected?.fullname
                                      }?`}
                            </h5>
                        ) : (
                            <h5
                                className="text-base font-medium leading-normal text-left text-black"
                                id="exampleModalScrollableLabel"
                            >
                                {checkInvited
                                    ? `${selected?.fullname} đã được gửi lời mời từ trước bạn có muốn gửi lại?`
                                    : `Gửi lời mời tới ${selected?.fullname}?`}
                            </h5>
                        )}
                    </div>

                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                viewMode == 'guest' ? handleSendToOne() : handleSendToOne();
                            }}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            {checkInvited ? 'Gửi lại' : 'Gửi'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export function ConfirmSendOnlyGroup({ handleSendToOne, selectOne, groups }) {
    const selected = groups.find((group) => group._id === selectOne);
    const checkInvited =
        selected?.status?.find(({ active }) => active === true).status !== 'UNINVITED';
    // console.log(selectOne)
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmSendOnlyGroup"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-md modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận gửi email
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center p-4 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-base font-medium leading-normal text-left text-black"
                            id="exampleModalScrollableLabel"
                        >
                            {checkInvited
                                ? `${selected?.fullname} đã được gửi lời mời từ trước bạn có muốn gửi lại?`
                                : `Gửi lời mời tới ${selected?.fullname}?`}
                        </h5>
                    </div>

                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={handleSendToOne}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            {checkInvited ? 'Gửi lại' : 'Gửi'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export function ConfirmSetTimerAgain({ handleSend }) {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmSetTimerAgain"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-md modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận đặt hẹn giờ
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center p-4 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-base font-medium leading-normal text-left text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Khách mời đã được gửi mời. Bạn có muốn tiếp tục đặt hẹn giờ để gửi lại
                            không?
                        </h5>
                    </div>

                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            Hủy
                        </button>
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={handleSend}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            Xác nhận
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export function ConfirmSendEmail({
    guestSelect,
    selectGroups,
    handleSendInvition,
    guests,
    groups,
    actionMode,
    handleSendToAll,
    selectOne,
    viewMode,
}) {
    const selected =
        viewMode == 'guest'
            ? guests.find((guest) => guest._id === selectOne)
            : groups.find((group) => group._id === selectOne);

    const check =
        actionMode === 'many'
            ? !!guestSelect.find(
                  (guest) =>
                      guest.status?.find(({ active }) => active === true).status !== 'UNINVITED'
              )
            : selected?.status?.find(({ active }) => active === true).status !== 'UNINVITED';

    const groupCheck =
        actionMode === 'many'
            ? !!selectGroups.find(
                  (group) =>
                      group.status?.find(({ active }) => active === true).status !== 'UNINVITED'
              )
            : selected?.status?.find(({ active }) => active === true).status !== 'UNINVITED';

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmSendEmail"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-md modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận gửi email
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-center p-4 modal-header shrink-0 rounded-t-md">
                        {viewMode == 'guest' ? (
                            <h5
                                className="text-base font-medium leading-normal text-center text-black"
                                id="exampleModalScrollableLabel"
                            >
                                {actionMode === 'many' &&
                                    check &&
                                    guestSelect.length != 1 &&
                                    'Có khách đã gửi lời mời từ trước bạn có muốn gửi lại?'}
                                {actionMode === 'many' &&
                                    !check &&
                                    guestSelect.length != 1 &&
                                    'Thao tác này chỉ gửi lời mời tới những khách có email bạn có muốn tiếp tục?'}
                                {actionMode === 'many' &&
                                    !check &&
                                    guestSelect.length == 1 &&
                                    `Gửi lời mời tới ${
                                        guestSelect[0]?.title + ' ' + guestSelect[0]?.fullname
                                    } ? `}
                                {actionMode === 'many' &&
                                    check &&
                                    guestSelect.length == 1 &&
                                    ` ${
                                        guestSelect[0]?.title + ' ' + guestSelect[0]?.fullname
                                    } đã được gửi lời mời từ trước bạn có muốn gửi lại? `}
                                {actionMode === 'all' && 'Gửi lời mời tới tất cả khách? '}
                            </h5>
                        ) : (
                            <h5
                                className="text-base font-medium leading-normal text-left text-black"
                                id="exampleModalScrollableLabel"
                            >
                                {actionMode === 'many' &&
                                    groupCheck &&
                                    'Có nhóm đã gửi lời mời từ trước bạn có muốn gửi lại?'}
                                {actionMode === 'many' &&
                                    !groupCheck &&
                                    'Thao tác này sẽ chỉ gửi lời mời đến những khách đã có email bạn có muốn tiếp tục?'}

                                {actionMode === 'all' && 'Gửi lời mời tới tất cả nhóm khách? '}
                            </h5>
                        )}
                    </div>
                    <div className="flex flex-col flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        {actionMode === 'all' && (
                            <>
                                <button
                                    type="button"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        handleSendToAll();
                                    }}
                                    className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-600 rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg "
                                >
                                    Gửi tới tất cả
                                </button>
                                <button
                                    type="button"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        handleSendToAll('NOT_SENT');
                                    }}
                                    className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-600 rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg "
                                >
                                    {viewMode == 'guest'
                                        ? 'Chỉ gửi những khách chưa được gửi'
                                        : 'Chỉ gửi những nhóm khách chưa được gửi'}
                                </button>
                            </>
                        )}
                        {check ? (
                            <>
                                {actionMode === 'many' && guestSelect.length != 1 ? (
                                    <button
                                        type="button"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            handleSendInvition();
                                        }}
                                        className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-600 rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg "
                                    >
                                        {viewMode == 'guest'
                                            ? 'Gửi cho tất cả khách đánh dấu'
                                            : 'Gửi cho tất cả nhóm khách đánh dấu'}
                                    </button>
                                ) : (
                                    actionMode === 'many' &&
                                    guestSelect.length == 1 && (
                                        <button
                                            type="button"
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                                handleSendInvition();
                                            }}
                                            className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-600 rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg "
                                        >
                                            Gửi lại
                                        </button>
                                    )
                                )}
                                {actionMode === 'many' && guestSelect.length != 1 && (
                                    <button
                                        type="button"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            handleSendInvition('not_send_before');
                                        }}
                                        className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-600 rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg "
                                    >
                                        {viewMode == 'guest'
                                            ? 'Chỉ gửi cho khách mời chưa được gửi'
                                            : 'Chỉ gửi cho nhóm khách chưa được gửi'}
                                    </button>
                                )}
                                {actionMode === 'only' && (
                                    <button
                                        type="button"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            handleSendInvition();
                                        }}
                                        className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-600 rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg "
                                    >
                                        Gửi lại
                                    </button>
                                )}
                            </>
                        ) : (
                            <button
                                type="button"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    handleSendInvition();
                                }}
                                className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-600 rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg "
                            >
                                Gửi
                            </button>
                        )}

                        <button
                            type="button"
                            className="inline-block w-full py-3 mb-3 text-xs font-medium leading-tight text-black uppercase transition duration-150 ease-in-out bg-gray-300 rounded shadow-xl hover: focus: active: hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-2xl"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
// export function ConfirmSendEmailToManyGroups({

//     selectGroups,
//     handleSendInvition,

//     groups,
//     actionMode,
//     handleSendToAll,
//     selectOne,
//     viewMode,
// }) {
//     const selected =
//         viewMode == 'guest'
//             ? guests.find((guest) => guest._id === selectOne)
//             : groups.find((group) => group._id === selectOne)

//     const check =
//         actionMode === 'many'
//             ? !!guestSelect.find(
//                   (guest) =>
//                       guest.status?.find(({ active }) => active === true).status !== 'UNINVITED'
//               )
//             : selected?.status?.find(({ active }) => active === true).status !== 'UNINVITED'

//     const groupCheck =
//         actionMode === 'many'
//             ? !!selectGroups.find(
//                   (group) =>
//                       group.status?.find(({ active }) => active === true).status !== 'UNINVITED'
//               )
//             : selected?.status?.find(({ active }) => active === true).status !== 'UNINVITED'

//     return (
//         <div
//             class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
//             id="confirmSendEmail"
//             tabindex="-1"
//             aria-labelledby="exampleModalCenterTitle"
//             aria-modal="true"
//             role="dialog"
//         >
//             <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
//                 <div class="modal-content border-none shadow-lg modal-md relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
//                     <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
//                         <h5
//                             class="text-base font-bold leading-normal flex items-center text-black"
//                             id="exampleModalScrollableLabel"
//                         >
//
//                             Xác nhận gửi email
//                         </h5>
//                         <button
//                             type="button"
//                             class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
//                             data-bs-dismiss="modal"
//                             aria-label="Close"
//                         ></button>
//                     </div>
//                     <div class="modal-header flex flex-shrink-0 items-center justify-center p-4  rounded-t-md">

//                             <h5
//                                 class="text-base font-medium leading-normal text-black text-center"
//                                 id="exampleModalScrollableLabel"
//                             >
//                                 Thao tác này sẽ gửi lời mời dến
//                             </h5>

//                     </div>
//                     <div class="modal-footer flex flex-shrink-0 flex-col flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
//                         {actionMode === 'all' && (
//                             <>
//                                 <button
//                                     type="button"
//                                     data-bs-dismiss="modal"
//                                     onClick={() => {
//                                         handleSendToAll()
//                                     }}
//                                     class="inline-block  py-3 mb-3 w-full bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out "
//                                 >
//                                     Gửi tới tất cả
//                                 </button>
//                                 <button
//                                     type="button"
//                                     data-bs-dismiss="modal"
//                                     onClick={() => {
//                                         handleSendToAll('NOT_SENT')
//                                     }}
//                                     class="inline-block  py-3 mb-3 w-full bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out "
//                                 >
//                                     {viewMode == 'guest'
//                                         ? 'Chỉ gửi những khách chưa được gửi'
//                                         : 'Chỉ gửi những nhóm khách chưa được gửi'}
//                                 </button>
//                             </>
//                         )}
//                         {check ? (
//                             <>
//                                 {actionMode === 'many' && guestSelect.length != 1 ? (
//                                     <button
//                                         type="button"
//                                         data-bs-dismiss="modal"
//                                         onClick={() => {
//                                             handleSendInvition()
//                                         }}
//                                         class="inline-block  py-3 mb-3 w-full bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out "
//                                     >
//                                         {viewMode == 'guest'
//                                             ? 'Gửi cho tất cả khách đánh dấu'
//                                             : 'Gửi cho tất cả nhóm khách đánh dấu'}
//                                     </button>
//                                 ) : (
//                                     actionMode === 'many' &&
//                                     guestSelect.length == 1 && (
//                                         <button
//                                             type="button"
//                                             data-bs-dismiss="modal"
//                                             onClick={() => {
//                                                 handleSendInvition()
//                                             }}
//                                             class="inline-block  py-3 mb-3 w-full bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out "
//                                         >
//                                             Gửi lại
//                                         </button>
//                                     )
//                                 )}
//                                 {actionMode === 'many' && guestSelect.length != 1 && (
//                                     <button
//                                         type="button"
//                                         data-bs-dismiss="modal"
//                                         onClick={() => {
//                                             handleSendInvition('not_send_before')
//                                         }}
//                                         class="inline-block  py-3 mb-3 w-full bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out "
//                                     >
//                                         {viewMode == 'guest'
//                                             ? 'Chỉ gửi cho khách mời chưa được gửi'
//                                             : 'Chỉ gửi cho nhóm khách chưa được gửi'}
//                                     </button>
//                                 )}
//                                 {actionMode === 'only' && (
//                                     <button
//                                         type="button"
//                                         data-bs-dismiss="modal"
//                                         onClick={() => {
//                                             handleSendInvition()
//                                         }}
//                                         class="inline-block  py-3 mb-3 w-full bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out "
//                                     >
//                                         Gửi lại
//                                     </button>
//                                 )}
//                             </>
//                         ) : (
//                             <button
//                                 type="button"
//                                 data-bs-dismiss="modal"
//                                 onClick={() => {
//                                     handleSendInvition()
//                                 }}
//                                 class="inline-block  py-3 mb-3 w-full bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out "
//                             >
//                                 Gửi
//                             </button>
//                         )}

//                         <button
//                             type="button"
//                             class="inline-block  w-full py-3 mb-3 bg-gray-300  text-black font-medium text-xs leading-tight uppercase rounded shadow-xl hover: hover:shadow-lg focus: focus:shadow-lg focus:outline-none focus:ring-0 active: active:shadow-2xl transition duration-150 ease-in-out"
//                             data-bs-dismiss="modal"
//                         >
//                             Trở lại
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }
export function ConfirmReplaceByExcel({ refFile, onChangeFileSelect, onOpenChooseFile }) {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmReplaceByExcel"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-md modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận thay thế danh sách bằng file excel
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-start p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-medium leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Thao tác này sẽ xóa toàn bộ khách mời trong danh sách trước đó bạn có
                            muốn tiếp tục?
                        </h5>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={onOpenChooseFile}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            Tiếp tục
                        </button>
                        <input
                            ref={refFile}
                            onChange={onChangeFileSelect}
                            name="file"
                            type="file"
                            className="hidden"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ConfirmAcceptRequest({
    actionMode,
    guestSelect,
    selectOne,
    handleVerifyOne,
    handleVerifyMany,
}) {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmAcceptRequest"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận đồng ý yêu cầu tham dự
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        {actionMode == 'many' && (
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                Đồng ý yêu cầu tham dự của {guestSelect?.length} khách mời
                            </h5>
                        )}
                        {actionMode == '' && (
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                Đồng ý yêu cầu tham dự của{' '}
                                {selectOne?.title + ' ' + selectOne?.fullname}
                            </h5>
                        )}
                        {actionMode == 'all' && (
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                Đồng ý yêu cầu tham dự của tất cả khách mời
                            </h5>
                        )}
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                if (actionMode == '') {
                                    handleVerifyOne(selectOne, 'USER_ACCEPT');
                                } else {
                                    handleVerifyMany('USER_ACCEPT');
                                }
                            }}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            Tiếp tục
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export function ConfirmDenyRequest({
    actionMode,
    guestSelect,
    selectOne,
    handleVerifyOne,
    handleVerifyMany,
}) {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmDenyRequest"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận từ chối yêu cầu tham dự
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        {actionMode == 'many' && (
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                Từ chối yêu cầu tham dự của {guestSelect?.length} khách mời
                            </h5>
                        )}
                        {actionMode == '' && (
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                Từ chối yêu cầu tham dự của{' '}
                                {selectOne?.title + ' ' + selectOne?.fullname}
                            </h5>
                        )}
                        {actionMode == 'all' && (
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                Từ chối yêu cầu tham dự của tất cả khách mời
                            </h5>
                        )}
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                if (actionMode == '') {
                                    handleVerifyOne(selectOne, 'USER_DENY');
                                } else {
                                    handleVerifyMany('USER_DENY');
                                }
                            }}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            Tiếp tục
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
