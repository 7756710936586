/* eslint-disable */
import { data } from 'autoprefixer';
import { useEffect, useState } from 'react';
import guestManagerService from '../guestManager.service';
import { FaUsers, FaUsersSlash } from 'react-icons/fa';

import image from '../../../../../assets/images/_index';

import { updateGuest } from '../../../../user.events/services/modalService';
export default function GroupListModal({
    open,
    setOpen,
    selectOne,
    eventTemplate,
    mode,
    formik,
    getGuests,
    groups,
}) {
    const [selectGroup, setSelectGroup] = useState();
    const [guest, setGuest] = useState();

    useEffect(() => {
        if (mode == 'guest-add' || mode == 'guest-update') {
            setSelectGroup(formik.values.group[0]);
        }
    }, [formik.values]);
    useEffect(() => {
        if (open && selectOne && mode == 'default') {
            guestManagerService.getGuestById(selectOne).then((data) => {
                setGuest(data);
                setSelectGroup(data.group[0]);
            });
        }
        setSelectGroup([]);
    }, [open, selectOne]);

    const handleUpdateGuest = async () => {
        const payload = {
            ...guest,
            ...(selectGroup ? { group: selectGroup._id } : { group: [] }),
            status: guest.status?.find(({ active }) => active === true)?.status,
        };

        await updateGuest(guest._id, payload);
        await getGuests(eventTemplate.id);
        setOpen({});
    };

    const handleSelectGroup = (e) => {
        const { value, checked } = e.target;
        if (value !== 'none') {
            setSelectGroup(groups.find((group) => group._id == value));
        } else {
            setSelectGroup();
        }
    };

    return (
        <div
            className="fade modal fixed left-0 top-0 hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
            id="groupListModal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="modal-dialog modal-dialog-centered pointer-events-none relative w-auto">
                <div className="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
                    <div className="modal-header flex shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4">
                        {mode == 'guest-add' && (
                            <h5
                                className="text-xl font-bold leading-normal text-gray-800"
                                id="exampleModalScrollableLabel"
                            >
                                Thêm vào nhóm
                            </h5>
                        )}
                        {(mode == 'guest-update' || mode == 'default') && (
                            <h5
                                className="text-xl font-bold leading-normal text-gray-800"
                                id="exampleModalScrollableLabel"
                            >
                                Chỉnh sửa nhóm
                            </h5>
                        )}
                        <button
                            type="button"
                            className="focus:opacity-200 btn-close box-content h-4 w-4 rounded-none border-none p-1 text-black opacity-50 hover:text-black hover:no-underline hover:opacity-75 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                setOpen({});
                            }}
                            aria-label="Close"
                        ></button>
                    </div>

                    <div className="flex max-h-[40vh] w-full flex-col items-center overflow-y-auto">
                        {groups.length != 0 ? (
                            <>
                                <div className="form-check flex w-full items-center px-4 py-2 ">
                                    <input
                                        className="form-check-input float-left mr-2 mt-1 h-4 w-4 cursor-pointer appearance-none rounded-full border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600 focus:outline-none"
                                        type="radio"
                                        value="none"
                                        id={0}
                                        checked={!selectGroup}
                                        onChange={handleSelectGroup}
                                    />

                                    <label
                                        className="form-check-label flex w-full cursor-pointer items-center rounded-lg border-[1px] border-gray-300 p-2 font-bold text-black"
                                        htmlFor={0}
                                    >
                                        <FaUsersSlash className="mr-2" /> Không chọn nhóm khách mời
                                        nào
                                    </label>
                                </div>
                                {groups.map((groupItem) => (
                                    <div className="form-check flex w-full items-center px-4 py-2 ">
                                        <input
                                            className="form-check-input float-left mr-2 mt-1 h-4 w-4 cursor-pointer appearance-none rounded-full border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600 focus:outline-none"
                                            type="radio"
                                            value={groupItem._id}
                                            id={groupItem._id}
                                            checked={selectGroup?._id == groupItem._id}
                                            onChange={handleSelectGroup}
                                        />

                                        <label
                                            className="form-check-label flex w-full cursor-pointer items-center rounded-lg border-[1px] border-gray-300 p-2 font-bold text-black"
                                            htmlFor={groupItem._id}
                                        >
                                            <FaUsers className="mr-2" /> {groupItem.fullname}
                                        </label>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <div className="flex w-full flex-col items-center justify-center my-5 px-4">
                                <img
                                    src={image.GUEST_MANAGER}
                                    alt="not found"
                                    className="mx-auto w-1/3 opacity-70"
                                />
                                <h4 className="mt-5 text-center text-lg text-blue-900 ">
                                    Vui lòng tạo thêm nhóm khách mời để chọn khách mời vào nhóm.
                                </h4>
                            </div>
                        )}
                    </div>

                    {/* {mode == 'guest-update' && (
                        <div className="flex flex-col items-center w-full max-h-[40vh] overflow-y-auto">
                            {groups.length != 0 ? (
                                groups.map((groupItem) => (
                                    <div className="flex items-center w-full px-4 py-2 form-check ">
                                        <input
                                            class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-full bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                            type="radio"
                                            value={groupItem._id}
                                            id={groupItem._id}
                                            checked={selectGroup._id == groupItem._id}
                                            onChange={handleSelectGroup}
                                        />

                                        <label
                                            class="form-check-label cursor-pointer rounded-lg flex items-center w-full px-2 py-2 border-[1px] border-gray-300 text-black font-bold"
                                            for={groupItem._id}
                                        >
                                            <FaUsers className="mr-2" /> {groupItem.fullname}
                                        </label>
                                    </div>
                                ))
                            ) : (
                                <p className="py-4 font-bold">Không có nhóm khách mời nào</p>
                            )}
                        </div>
                    )} */}

                    <div className="modal-footer flex shrink-0 flex-wrap items-center justify-end rounded-b-md border-t border-gray-200 p-4">
                        <button
                            type="button"
                            onClick={() => {
                                setOpen({});
                            }}
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss={'modal'}
                        >
                            Trở lại
                        </button>
                        {mode == 'default' && groups?.length != 0 && (
                            <button
                                type="button"
                                onClick={handleUpdateGuest}
                                data-bs-dismiss={'modal'}
                                className={
                                    'ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg'
                                }
                            >
                                Lưu
                            </button>
                        )}
                        {mode == 'guest-update' && groups?.length != 0 && (
                            <button
                                type="button"
                                onClick={() => {
                                    formik.setValues({
                                        ...formik.values,
                                        ...(selectGroup ? { group: [selectGroup] } : { group: [] }),
                                    });
                                }}
                                data-bs-dismiss={'modal'}
                                className={
                                    'ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg'
                                }
                            >
                                Xong
                            </button>
                        )}
                        {mode == 'guest-add' && groups?.length != 0 && (
                            <button
                                type="button"
                                onClick={() => {
                                    formik.setValues({
                                        ...formik.values,
                                        group: selectGroup ? [selectGroup] : [],
                                    });
                                }}
                                data-bs-dismiss={'modal'}
                                // disabled={selectGroup.length == 0}
                                className={
                                    'ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg'
                                }
                            >
                                Thêm
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
