/* eslint-disable */
import EventTemplate from '../../api/event.template.api';
import Events from '../../api/events.api';
import { getOneTempCategory } from '../../api/templates.api';
import moment from 'moment';
import { TEMPLATE_TYPE } from '../../config/constant/common.constant';

function isDatePassed(dateString) {
    const currentDate = moment();
    const targetDate = moment(dateString, 'YYYY-MM-DD');
    return targetDate.isBefore(currentDate, 'day');
}
const getMaxDate = (dateSingle, arrayDates) => {
    if (arrayDates?.length === 0) {
        return dateSingle;
    }
    const dates = arrayDates?.map((item) => moment(item.date, 'YYYY-MM-DD'));
    dates?.push(moment(dateSingle, 'YYYY-MM-DD'));
    const maxDate = moment.max(dates);
    return maxDate?.format('YYYY-MM-DD');
};
export const checkIsExpired = (event) => {
    const alias = event?.template?.alias;
    let isExpiredOfTypeTemplate = false;
    if (alias?.includes(TEMPLATE_TYPE.WEDDING)) {
        isExpiredOfTypeTemplate =
            event?.form_data?.dateCeremony &&
            isDatePassed(getMaxDate(event?.form_data?.dateCeremony, event?.form_data?.infoParty));
    } else if (alias?.includes(TEMPLATE_TYPE.EVENT)) {
        isExpiredOfTypeTemplate =
            event?.form_data?.dateHold && isDatePassed(event?.form_data?.dateHold);
    }
    // return isExpiredOfTypeTemplate;
    return isExpiredOfTypeTemplate && event?.is_public; //Thêm điều kiện đã công khai
};

const EventsService = {
    addNewEventTemplate: async (eventTemplate) => {
        // for (var pair of eventTemplate.entries()) {
        //     console.log(pair[0], pair[1]);
        // }

        const result = await EventTemplate.addNew(eventTemplate);

        return result;
    },
    updateEventTemplate: async (id, eventTemplate) => {
        const result = await EventTemplate.update(id, eventTemplate);
        // for (var pair of eventTemplate.entries()) {
        //     console.log(pair[0], pair[1]);
        // }

        return result;
    },
    getOneEventTemplateById: async (id) => {
        const { data } = await EventTemplate.getOne(id);
        // console.log("data getOneEventTemplateById = ", data)
        return data;
    },

    getAllEvents: async () => {
        const { data } = await Events.getAll();
        return data;
    },
    updateEmailEvent: async (id, email, emailCcArr, emailBCcArr, signature = '', name_sender) =>
        Events.update(id, {
            email_host: email,
            email_cc: emailCcArr,
            email_bcc: emailBCcArr,
            signature: signature,
            name_sender: name_sender,
        })
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),

    getAllEventTemplates: async ({ sort_field = 'createdAt', sort_type = 'desc' }) => {
        // const { data } = await EventTemplate.getAll({
        const { data } = await Events.getAll({
            // includes: ['event', 'template'],
            includes: ['template'],
            // select_field: ['-updatedAt', '-thumbnail_url'],
            sort_field: sort_field,
            sort_type: sort_type,
        });
        return data;
    },

    getOneEventById: async (id) => {
        let { data } = await Events.getOne(id);
        const isExpired = checkIsExpired(data.data);
        data.data.isExpired = isExpired;
        return data;
    },

    initEvent: async (nameTemplate, template_id) => {
        try {
            const { data } = await Events.init(nameTemplate, template_id);
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    createEvent: async (payload) => {
        try {
            const result = await Events.create(payload);
            return result;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    getOneTemplateCategory: async (id) => {
        const data = await getOneTempCategory(id);
        // console.log("data getOneTempCategory = ", data)
        return data;
    },

    getScreenshot: async (link, location) => {
        const data = await EventTemplate.screenshot(link, location);
        return data;
    },

    uploadFileEmail: async (bodyData) =>
        Events.uploadFileEmail(bodyData)
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),

    removeFileEmail: async (payload) =>
        Events.removeFileEmail(payload)
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),

    updateFormdataWithFile: async (id, payload) =>
        EventTemplate.updateFile(id, payload)
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),

    updateFormdata: async (id, payload) => {
        const result = await EventTemplate.updateContent(id, payload);
        return result;
    },

    deleteByGuestId: async () => {
        return await Events.deleteByGuestId();
    },

    checkCanCreateEvent: async () => {
        return await Events.checkCanCreateEvent();
    },

    getTableCodition: async () => {
        return await Events.getTableCodition();
    },
    getEvTable: async (id) => {
        return await Events.getEvTable(id);
    },
    getEvAcceptGuest: async (id) => {
        return await Events.getEvAcceptGuest(id);
    },
    createTable: async (payload) => {
        return await Events.createTable(payload);
    },
    addGuestToTable: async (payload) => {
        return await Events.addGuestToTable(payload);
    },
    sortTable: async (payload) => {
        return await Events.sortTable(payload);
    },
    deleteGuest: async (payload) => {
        return await Events.deleteGuest(payload);
    },

    deleteTable: async (payload) => {
        return await Events.deleteTable(payload);
    },

    swapGuest: async (payload) => {
        return await Events.swapGuest(payload);
    },
};

export default EventsService;
