import axios from './axios';

const FeedBack = {
    addNew: async (data) => axios({
            url: '/feedback/addNew',
            method: 'POST',
            data: data,
        }),
};

export default FeedBack;