/* eslint-disable */
export default function ItemGreetingLoading() {


    return (
        <>
            <div className="px-5 pt-5 pb-3 bg-white rounded-lg shadow justify-start items-start flex relative w-full">
                <div className="w-[35%] relative group mr-4  items-center rounded-md h-40 bg-trueGray-300 animate-pulse">
                </div>
                <div className='w-[65%] relative'>
                    <div className="self-stretch flex-col justify-start items-start gap-1 flex h-full">
                        <div className="self-stretch text-gray-500 text-xs rounded-md leading-none pb-1 h-5 bg-trueGray-300 animate-pulse"></div>
                        <div
                            className="self-stretch text-gray-900 text-lg font-semibold rounded-md leading-tight mt-auto line-clamp-1 bg-trueGray-300 h-6 animate-pulse">
                        </div>
                        <div className="self-stretch mt-0.5 h-16 bg-trueGray-300 p-1 rounded-md animate-pulse">
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
