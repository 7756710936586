/* eslint-disable */
import LayoutEmpty from '../components/Layout/LayoutEmpty';
import LayoutLanding from '../components/Layout/LayoutLanding';
import PAGE from '../config/path.route';
import { lazy } from 'react';

import ResetPassword from '../views/ResetPassword/ResetPassword';
import PublishMainMenu from '../views/publish.mainmenu/PublishMainMenu.page.js';
import PublishBank from '../views/publish.mainmenu/publish.mainmenu.detail/publish.bank/PublishBank.page.js';
import EventConfig from '../views/publish.mainmenu/publish.mainmenu.detail/pushish.config/EventConfig.jsx';
import VerifyUser from '../views/VerifyUser/VerifyUser.jsx';
import Help from '../views/help/Help.jsx';
import FeedBack from '../views/feedback/FeedBack.jsx';

const Dashboard = lazy(() => import('../views/dashboard/Dashboard.page'));
const AbouUs = lazy(() => import('../views/aboutus/Aboutus.page'));
const Policy = lazy(() => import('../views/policy/Policy.page'));
const Terms = lazy(() => import('../views/terms/Terms.jsx'));
const UserInfo = lazy(() => import('../views/user/User.page'));
const PageNotFound = lazy(() => import('../views/404/404.page'));
const Events = lazy(() => import('../views/events/Events.page'));
const Templates = lazy(() => import('../views/templates/index'));
const UserEvents = lazy(() => import('../views/user.events/User.events.page'));
const TemplateView = lazy(() => import('../views/template.view/TemplateView.page.jsx'));
const Publish = lazy(() => import('../views/publish/Publish.page'));
const EventDetail = lazy(() => import('../views/EventDetail'));
const Suggest = lazy(() => import('../views/suggest/Suggest.jsx'));
const PublishThumbnail = lazy(() => import('../views/publish.thumbnail/PublishThumbnail'));
const RSVP = lazy(() => import('../views/rsvp.view/RSVP'));
const TemplateDetail = lazy(() => import('../views/template.detail/TemplateDetail.jsx'));
const Pricing = lazy(() => import('../views/pricing/Pricing.page'));
const Feature = lazy(() => import('../views/feature/Feature.page'));

const MainRoute = [
    {
        path: PAGE.dashboard,
        // layout: LayoutLanding,
        element: Dashboard,
        isProtect: false,
    },
    {
        path: PAGE.aboutus,
        element: AbouUs,
        isProtect: false,
    },
    {
        path: PAGE.policy,
        element: Policy,
        isProtect: false,
    },
    {
        path: PAGE.terms,
        element: Terms,
        isProtect: false,
    },
    {
        path: PAGE.profile,
        element: UserInfo,
        isProtect: true,
        isRequireAuthUser: true,
    },
    {
        path: PAGE.templates,
        element: Templates,
        isProtect: false,
    },
    {
        path: PAGE.suggest,
        element: Suggest,
        isProtect: false,
        layout: LayoutEmpty,
    },
    {
        path: PAGE.userEvents,
        element: UserEvents,
        isProtect: true,
        isRequireAuthUser: true,
    },
    {
        path: PAGE.templateView,
        element: TemplateView,
        isProtect: false,
        layout: 'a',
    },
    {
        path: PAGE.templateDetail,
        element: TemplateDetail,
        isProtect: false,
        layout: 'a',
    },
    // {
    //     path: PAGE.createEvent,
    //     element: Events,
    //     isProtect: true,
    //     layout: LayoutEmpty,
    // },
    {
        path: PAGE.managerEventGeneral,
        element: EventDetail,
        isProtect: true,
        layout: LayoutEmpty,
    },
    {
        path: PAGE.publish,
        element: Publish,
        isProtect: false,
        layout: 'a',
    },
    {
        path: PAGE.publishGuest,
        element: Publish,
        isProtect: false,
        layout: 'a',
    },
    {
        path: '*',
        element: PageNotFound,
        isProtect: false,
    },
    {
        path: PAGE.publishThumbnail,
        element: PublishThumbnail,
        isProtect: false,
        layout: LayoutEmpty,
    },
    {
        path: PAGE.publishThumbnailGuest,
        element: PublishThumbnail,
        isProtect: false,
        layout: LayoutEmpty,
    },
    {
        path: PAGE.configEvent,
        element: EventConfig,
        isProtect: true,
    },

    {
        path: PAGE.RSVP,
        element: RSVP,
        layout: LayoutEmpty,
    },
    {
        path: PAGE.resetPassword,
        element: ResetPassword,
        layout: LayoutEmpty,
    },
    {
        path: PAGE.verifyUser,
        element: VerifyUser,
        layout: LayoutEmpty,
    },
    {
        path: PAGE.help,
        element: Help,
        // layout: LayoutEmpty,
    },
    {
        path: PAGE.pricing,
        element: Pricing,
    },
    {
        path: PAGE.feature,
        element: Feature,
    },
    {
        path: PAGE.feedback,
        element: FeedBack,
        isProtect: false,
    },
];

export default MainRoute;
