/* eslint-disable */
import { FaTimes, FaSave, FaTrashAlt, FaArrowLeft } from 'react-icons/fa';
export default function ModalDelete({ handleDelteEv }) {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmDeleteEvent"
            // aria-labelledby="exampleModalCenterTitle"
            data-bs-backdrop="static"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5 className="text-xl font-bold leading-normal text-red-500">
                            Xác nhận xóa chương trình
                        </h5>
                        <button
                            type="button"
                            title="Đóng"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5 className="my-2 text-[16px] font-medium leading-normal text-gray-800">
                            Bạn có chắc muốn xóa chương trình này không?
                        </h5>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover:bg-gray-50 transition-all focus:bg-gray-50 active:bg-gray-50  flex items-center rounded bg-white border-[1px] border-gray-300 px-5 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                        >
                            <FaArrowLeft /> 
                            Trở lại
                        </button>
                        <button
                            type="button"
                            onClick={handleDelteEv}
                            data-bs-dismiss="modal"
                            className="ml-1 flex items-center gap-2 rounded bg-red-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg"
                        >
                            <FaTrashAlt />
                            Xóa
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
