/* eslint-disable */

import img from './imgs/video.webp';

const Blank = () => {
    return (
        <div className=" flex flex-col justify-center items-center w-full ">
            <img src={img} alt="not found" className="w-[250px] opacity-70" />
            {/* <h4 className="mt-5 text-center text-xl text-blue-900 md:text-2xl">
                Thêm video để chia sẻ khoảnh khắc hạnh phúc của bạn nhé.
            </h4> */}
            <p className=" text-center text-blue-900 font-bold py-6 max-w-[600px]">
                Hãy chia sẻ video về hình ảnh cưới của bạn tại đây để mọi người cùng chiêm ngưỡng
                những khoảnh khắc hạnh phúc ấy!
            </p>
        </div>
    );
};

export default Blank;
