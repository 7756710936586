/* eslint-disable */
import { useFormik } from 'formik';
import { FaEdit, FaVial } from 'react-icons/fa';

import * as Yup from 'yup';
import { useEffect, useState } from 'react';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import InputBasic from '../../../../../components/InputBasic/InputBasic';
import ToastNotify from '../../../../../utils/Toast';
import EventsService from '../../../../events/Events.service';
import Regex from '../../../../../utils/Regex';

export default function SettingEmailModal({ eventTemplate, trigger, setTrigger }) {
    const [mode, setMode] = useState('view');
    const [check, setCheck] = useState(false);
    const createInit = () => {
        const html = eventTemplate?.signature || '';
        const blocksFromHtml = htmlToDraft(html);

        const content = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks);

        const value = EditorState.createWithContent(content);

        return value;
    };

    const [editorState, setEditorState] = useState(createInit());
    const [emailCcArr, setEmailCcArr] = useState(eventTemplate?.email_cc || []);
    const [emailBCcArr, setEmailBCcArr] = useState(eventTemplate?.email_bcc || []);
    const formik = useFormik({
        initialValues: {
            email_host: eventTemplate?.email_host || '',
            email_cc: '',
            email_bcc: '',
            name_sender: eventTemplate?.name_sender || '',
        },
        validationSchema: Yup.object({
            email_host: Yup.string()
                .matches(
                    Regex.email,
                    'Email: sử dụng các chữ cái (a-z), số (0-9), dấu chấm (.), và dấu a còng (@).'
                )
                .max(100, 'Vui lòng nhập email không vượt quá 100 kí tự'),
            email_cc: Yup.string()
                .matches(
                    Regex.email,
                    'Email: sử dụng các chữ cái (a-z), số (0-9), dấu chấm (.), và dấu a còng (@).'
                )
                .max(100, 'Vui lòng nhập email không vượt quá 100 kí tự'),
            email_bcc: Yup.string()
                .matches(
                    Regex.email,
                    'Email: sử dụng các chữ cái (a-z), số (0-9), dấu chấm (.), và dấu a còng (@).'
                )
                .max(100, 'Vui lòng nhập email không vượt quá 100 kí tự'),
            name_sender: Yup.string()
                .required('Vui lòng nhập tên người gửi!')
                .max(100, 'Vui lòng nhập tên người gửi không vượt quá 100 kí tự!'),
        }),
    });

    useEffect(() => {
        if (mode === 'edit' && formik.values.name_sender === '') {
            formik.setValues({
                ...formik.values,
                name_sender:
                    eventTemplate?.form_data?.nameGroom && eventTemplate?.form_data?.nameBride
                        ? `${eventTemplate?.form_data?.nameGroom} - ${eventTemplate?.form_data?.nameBride}`
                        : `${eventTemplate?.form_data?.nameEvent}`,
            });
        }
    }, [mode]);

    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
        const contentState = newEditorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const html = draftToHtml(rawContentState);
    };
    function convertEditorStateToRichText(editorState) {
        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const html = draftToHtml(rawContentState);
        return html;
    }
    const handleAddEmailCc = () => {
        console.log('emailCc', formik.values.email_cc.toLowerCase());
        setCheck(true);
        if (
            emailCcArr.find((item) => item.toLowerCase() == formik.values.email_cc.toLowerCase()) ||
            emailBCcArr.find((item) => item.toLowerCase() == formik.values.email_cc.toLowerCase())
        ) {
            ToastNotify.Error('Đã tồn tại email!');
        } else {
            setEmailCcArr([...emailCcArr, formik.values.email_cc]);
            formik.setValues({ ...formik.values, email_cc: '' });
        }
    };
    const handleAddEmailBCc = () => {
        setCheck(true);
        console.log('emailBCc', formik.values.email_bcc.toLowerCase());
        if (
            emailBCcArr.find(
                (item) => item.toLowerCase() == formik.values.email_bcc.toLowerCase()
            ) ||
            emailCcArr.find((item) => item.toLowerCase() == formik.values.email_bcc.toLowerCase())
        ) {
            ToastNotify.Error('Đã tồn tại email!');
        } else {
            setEmailBCcArr([...emailBCcArr, formik.values.email_bcc]);
            formik.setValues({ ...formik.values, email_bcc: '' });
        }
    };
    const handleDeleteEmailCc = (email) => {
        setEmailCcArr(emailCcArr.filter((item) => item != email));
    };
    const handleDeleteEmailBCc = (email) => {
        setEmailBCcArr(emailBCcArr.filter((item) => item != email));
    };

    const checkValid = () => {
        if (
            !!formik.errors.email_host ||
            !!formik.errors.name_sender ||
            editorState.getCurrentContent().getPlainText().length >= 300 ||
            !!formik.errors.email_cc ||
            !!formik.errors.email_bcc
        ) {
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        if (checkValid()) {
            EventsService.updateEmailEvent(
                eventTemplate?._id,
                formik.values.email_host,
                emailCcArr,
                emailBCcArr,
                convertEditorStateToRichText(editorState),
                formik.values.name_sender
            )
                .then(() => {
                    ToastNotify.Success('Lưu email thành công!');
                })
                .catch((err) => {
                    ToastNotify.Error(err.message);
                })
                .finally(() => {
                    setTrigger(!trigger);
                });
        }
    };

    const handleResetForm = () => {
        setCheck(false);
        formik.resetForm();
        setEmailCcArr(eventTemplate?.email_cc);
        setEmailBCcArr(eventTemplate?.email_bcc);
        setEditorState(eventTemplate?.signature ? createInit() : EditorState.createEmpty());
    };

    return (
        <>
            <div
                className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
                id="settingEmailModal"
                tabIndex="-1"
                aria-modal="true"
                role="dialog"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                    <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                        <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                            <h5
                                className="text-[16px] font-bold leading-normal text-gray-800"
                                id="staticBackdropLabel"
                            >
                                Thông tin chủ sự kiện
                            </h5>
                            <button
                                type="button"
                                className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                data-bs-dismiss={mode === 'view' && 'modal'}
                                data-bs-toggle={mode === 'edit' && 'modal'}
                                data-bs-target={mode === 'edit' && '#confirmBackSettingModal'}
                                onClick={handleResetForm}
                            ></button>
                        </div>
                        <div className="relative px-4 mt-5 modal-body">
                            <InputBasic
                                id="name_sender"
                                label="Tên người gửi"
                                value={formik.values.name_sender}
                                name="name_sender"
                                type="text"
                                size="small"
                                onChange={formik.handleChange}
                                disable={mode === 'view'}
                                require
                            />
                            <div className="my-3">
                                {check && formik.errors.name_sender && (
                                    <span className="text-xs text-red-500">
                                        {formik.errors.name_sender || ''}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="relative px-4 modal-body">
                            <InputBasic
                                id="email_host"
                                name="email_host"
                                label="Email nhận phản hồi"
                                placeholder={mode === 'edit' && 'VD: "nguyena@gmail.com"'}
                                value={formik.values.email_host}
                                onChange={formik.handleChange}
                                type="text"
                                size="small"
                                disable={mode === 'view'}
                            />
                            <div className="my-3">
                                {check && formik.errors.email_host ? (
                                    <span className="text-xs text-red-500">
                                        {formik.errors.email_host || ''}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="relative px-4 modal-body">
                            <label className="inline-block mb-2 text-sm font-bold text-gray-700 form-label">
                                Email CC
                            </label>
                            <div className="flex items-center w-full">
                                {mode === 'edit' && (
                                    <input
                                        id="email_cc"
                                        name="email_cc"
                                        label="Email cc"
                                        placeholder={mode === 'edit' && 'VD: "nguyena@gmail.com"'}
                                        value={formik.values.email_cc}
                                        onChange={formik.handleChange}
                                        type="text"
                                        disabled={mode === 'view'}
                                        className={
                                            ' form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding py-1 text-sm font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none '
                                        }
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                setCheck(true);
                                                if (
                                                    formik.errors.email_cc ||
                                                    !formik.values.email_cc
                                                ) {
                                                } else {
                                                    handleAddEmailCc();
                                                }
                                            }
                                        }}
                                    />
                                )}
                                {mode === 'edit' && (
                                    <button
                                        onClick={() => {
                                            setCheck(true);
                                            if (formik.errors.email_cc || !formik.values.email_cc) {
                                            } else {
                                                handleAddEmailCc();
                                            }
                                        }}
                                        className="px-5 py-1 ml-2 text-sm text-white bg-blue-600 border rounded"
                                    >
                                        Thêm
                                    </button>
                                )}
                                {mode === 'view' && emailCcArr?.length === 0 && (
                                    <div
                                        onClick={() => {
                                            setCheck(true);
                                            if (formik.errors.email_cc || !formik.values.email_cc) {
                                            } else {
                                                handleAddEmailCc();
                                            }
                                        }}
                                        className="px-2 py-1 text-black text-xs bg-gray-100 border rounded-full"
                                    >
                                        Chưa có email CC nào
                                    </div>
                                )}
                            </div>
                            {check && formik.errors.email_cc && (
                                <div className="my-3">
                                    <span className="text-xs text-red-500">
                                        {formik.errors.email_cc || ''}
                                    </span>
                                </div>
                            )}
                            <div className="flex flex-wrap mt-2">
                                {emailCcArr.map((emailItem, i) => (
                                    <span
                                        key={i}
                                        className="flex px-2 py-1 mb-3 mr-2 text-xs text-white transition duration-300 bg-blue-500 rounded-full cursor-pointer align-center ease w-max active:bg-gray-300"
                                    >
                                        {emailItem}
                                        {!eventTemplate?.isExpired && mode === 'edit' && (
                                            <button
                                                className="bg-transparent hover focus:outline-none"
                                                onClick={() => {
                                                    handleDeleteEmailCc(emailItem);
                                                }}
                                            >
                                                <svg
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    data-prefix="fas"
                                                    data-icon="times"
                                                    className="w-2 ml-3"
                                                    role="img"
                                                    xmlns="http:www.w3.org/2000/svg"
                                                    viewBox="0 0 352 512"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                                                    ></path>
                                                </svg>
                                            </button>
                                        )}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <div className="relative px-4 modal-body">
                            <label className="inline-block mb-2 text-sm font-bold text-gray-700 form-label">
                                Email BCC
                            </label>
                            <div className="flex items-center w-full">
                                {mode === 'edit' && (
                                    <input
                                        id="email_bcc"
                                        name="email_bcc"
                                        label="Email Bcc"
                                        placeholder={mode === 'edit' && 'VD: "nguyenb@gmail.com"'}
                                        value={formik.values.email_bcc}
                                        onChange={formik.handleChange}
                                        type="text"
                                        disabled={mode === 'view'}
                                        className={
                                            ' form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding py-1 text-sm font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none '
                                        }
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                setCheck(true);
                                                if (
                                                    formik.errors.email_bcc ||
                                                    !formik.values.email_bcc
                                                ) {
                                                } else {
                                                    handleAddEmailBCc();
                                                }
                                            }
                                        }}
                                    />
                                )}
                                {mode === 'edit' && (
                                    <button
                                        onClick={() => {
                                            setCheck(true);
                                            if (
                                                formik.errors.email_bcc ||
                                                !formik.values.email_bcc
                                            ) {
                                            } else {
                                                handleAddEmailBCc();
                                            }
                                        }}
                                        className="px-5 py-1 ml-2 text-sm text-white bg-blue-600 border rounded"
                                    >
                                        Thêm
                                    </button>
                                )}
                                {mode === 'view' && emailBCcArr?.length === 0 && (
                                    <div
                                        onClick={() => {
                                            setCheck(true);
                                            if (formik.errors.email_cc || !formik.values.email_cc) {
                                            } else {
                                                handleAddEmailCc();
                                            }
                                        }}
                                        className="px-2 py-1 text-black text-xs bg-gray-100 border rounded-full"
                                    >
                                        Chưa có email BCC nào
                                    </div>
                                )}
                            </div>
                            <div className="my-3">
                                {check && formik.errors.email_bcc && (
                                    <span className="text-xs text-red-500">
                                        {formik.errors.email_bcc || ''}
                                    </span>
                                )}
                            </div>
                            <div className="flex flex-wrap -mt-1">
                                {emailBCcArr.map((emailItem, i) => (
                                    <span
                                        key={i}
                                        className="flex px-2 py-1 mb-3 mr-2 text-xs  text-white transition duration-300 bg-violet-500 rounded-full cursor-pointer align-center ease w-max active:bg-gray-300"
                                    >
                                        {emailItem}
                                        {!eventTemplate?.isExpired && mode === 'edit' && (
                                            <button
                                                className="bg-transparent hover focus:outline-none"
                                                onClick={() => {
                                                    handleDeleteEmailBCc(emailItem);
                                                }}
                                            >
                                                <svg
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    data-prefix="fas"
                                                    data-icon="times"
                                                    className="w-2 ml-3"
                                                    role="img"
                                                    xmlns="http:www.w3.org/2000/svg"
                                                    viewBox="0 0 352 512"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                                                    ></path>
                                                </svg>
                                            </button>
                                        )}
                                    </span>
                                ))}
                            </div>
                        </div>
                        <div className="relative px-4 mt-1 modal-body">
                            <label className="inline-block mb-2 text-sm font-bold text-gray-700 form-label">
                                Chữ ký
                            </label>
                            <Editor
                                wrapperStyle={{
                                    padding: '5px',
                                    border:
                                        editorState.getCurrentContent().getPlainText().length < 300
                                            ? '1px solid #ccc'
                                            : '1px solid #ef4444',
                                    borderRadius: '5px',
                                }}
                                editorStyle={{
                                    height: '150px',
                                }}
                                placeholder={
                                    mode === 'edit' && 'Nhập chữ ký cho email gửi thiệp mời'
                                }
                                editorState={editorState}
                                onEditorStateChange={handleEditorChange}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                readOnly={mode === 'view'}
                            />
                            <div className="my-3">
                                <span className="text-xs text-red-500">
                                    {/* {formik.errors.email_host || ''} */}
                                </span>
                            </div>
                            {editorState.getCurrentContent().getPlainText().length >= 300 && (
                                <p className="text-sm text-red-500">
                                    Chữ kí không vượt quá 300 ký tự
                                </p>
                            )}
                        </div>
                        <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                            {mode === 'view' && !eventTemplate?.isExpired && (
                                <button
                                    className="ml-1 inline-block rounded bg-blue-600 mr-2 px-6 py-2.5 text-[14px] font-medium leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                                    onClick={() => {
                                        setMode('edit');
                                    }}
                                >
                                    Sửa thông tin
                                </button>
                            )}
                            <button
                                type="button"
                                className="hover:bg-gray-50 transition-all focus:bg-gray-50 active:bg-gray-50  inline-block rounded bg-white border-[1px] border-gray-300 px-5 py-2.5 text-[14px] leading-tight text-gray-800 duration-150 ease-in-out font-medium"
                                data-bs-dismiss={mode === 'view' && 'modal'}
                                data-bs-toggle={mode === 'edit' && 'modal'}
                                data-bs-target={mode === 'edit' && '#confirmBackSettingModal'}
                                onClick={handleResetForm}
                            >
                                Trở lại
                            </button>
                            {mode === 'edit' && (
                                <button
                                    type="button"
                                    onClick={() => {
                                        setCheck(true);
                                        handleSubmit();
                                    }}
                                    data-bs-dismiss={checkValid() && 'modal'}
                                    className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-[14px] font-medium leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                                >
                                    Lưu
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmBack setMode={setMode} handleResetForm={handleResetForm} />
        </>
    );
}

const ConfirmBack = ({ setMode, handleResetForm }) => {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmBackSettingModal"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận thoát
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-toggle={'modal'}
                            data-bs-target={'#settingEmailModal'}
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-base font-medium leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Dữ liệu chưa được lưu bạn có chắc muốn thoát?
                        </h5>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-toggle={'modal'}
                            data-bs-target={'#settingEmailModal'}
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                setMode('view');
                                handleResetForm();
                            }}
                            className="ml-1 inline-block rounded bg-blue-500 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            Tiếp tục
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
