/* eslint-disable */
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import IMAGES from '../assets/images/_index';
import { useLocation } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export const PRODUCT_LIST = [
    {
        name: 'Gia phả số',
        image: IMAGES.GIA_PHA_WEB,
        link: 'https://giaphaso.com/',
        des: `Gia phả Số Đại Việt trực tuyến là giải pháp trong lĩnh vực văn hóa Việt Nam, 
                áp dụng công nghệ phát triển website gia phả cho các dòng họ.`,
    },
    {
        name: 'Gia phả Đại Việt',
        image: IMAGES.GIA_PHA_DAI_VIET,
        link: 'https://giaphadaiviet.com/',
        des: `Với hơn 10 năm nghiên cứu văn hóa và phát triển các công nghệ giúp xây dựng gia phả thuận tiện thông minh, 
                Gia Phả Đại Việt vinh dự cung cấp dịch vụ về xây dựng gia phả cho các dòng họ tại Việt Nam.`,
    },
    {
        name: 'Chia buồn Điện tử',
        image: IMAGES.CHIA_BUON,
        des: `Các cáo phó bằng giấy dần đã được thay thế bằng cáo phó điện tử. 
        Không những giúp bạn tiết kiệm về thời gian, chi phí, mà còn tiện lợi, hiện đại.`,
        link: 'https://chiabuon.vn/',
    },
    {
        name: 'Nhân số Đại Việt',
        image: IMAGES.NHAN_SO,
        des: `Mục tiêu số 1 của công cụ tra cứu nhân số học Nhân Số Đại Việt là hỗ trợ bạn 
                trong hành trình nhận thức sâu sắc về bản ngã và trở nên tự tin hơn.`,
        link: 'https://nhansodaiviet.com/',
    },
    {
        name: 'Phần mềm Gia phả',
        image: IMAGES.GIA_PHA_APP,
        des: `Chúng tôi tự hào cung cấp một phương thức thân thiện để giúp các gia đình làm
                và quản lý gia phả bằng các ứng dụng phần mềm công nghệ thông minh.`,
        link: 'https://giapha.akb.com.vn/',
    },
];

const MIN_PRODUCT_ARR_LENGTH = 3;

const settingCarousel = {
    additionalTransfrom: 0,
    // arrows: true,
    autoPlay: true,
    autoPlaySpeed: 1,
    centerMode: false,
    className: "mx-auto flex max-w-screen-xl gap-4 pb-10",
    containerClass: "container-with-dots",
    dotListClass: false,
    draggable: false,
    focusOnSelect: false,
    infinite: false,
    itemClass: "",
    keyBoardControl: false,
    minimumTouchDrag: 1000,
    pauseOnHover: false,
    renderArrowsWhenDisabled: false,
    renderButtonGroupOutside: false,
    renderDotsOutside: false,
    responsive: {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024
            },
            items: 3,
            partialVisibilityGutter: 40
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 0
            },
            items: 1,
            partialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464
            },
            items: 2,
            partialVisibilityGutter: 30
        }
    },
    rewind: false,
    rewindWithAnimation: false,
    rtl: false,
    shouldResetAutoplay: true,
    showDots: false,
    sliderClass: "",
    slidesToSlide: 1,
    swipeable: false
}
const settings = {
    dots: true,
    customPaging: function () {
        return (
            <span className="mx-2 block h-4 w-4 cursor-pointer rounded-full transition-all"></span>
        );
    },
    dotsClass: 'slick-dots w-max absolute mt-20  ',
    infinite: true,
    speed: 500,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 1000,
    cssEase: 'linear',
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const OurProducts = () => {
    const location = useLocation();
    const [sliderRef, setSliderRef] = useState(null);

    useEffect(() => {
        if (location.hash) {
            const elem = document.getElementById(location.hash.slice(1));
            if (elem) {
                // setTimeout(() => {
                elem.scrollIntoView({ behavior: 'smooth' });
                // }, 100);
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }, [location]);

    return (
        <section id="ourproduct" className="pb-6 bg-white">
            <div className="mx-auto flex max-w-screen-xl items-center justify-center px-4 pb-3 pt-20 lg:px-6 lg:pb-5">
                <div className="mx-auto mt-4 flex w-auto justify-between text-center">
                    <h2 className="mb-4 text-3xl font-bold leading-tight tracking-tight text-main-500 ">
                        Sản phẩm khác của chúng tôi
                    </h2>
                </div>
            </div>
            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className="mx-auto flex max-w-screen-xl gap-5 mb-10"
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 3,
                        partialVisibilityGutter: 40
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                    }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {PRODUCT_LIST?.map((product, index) => (
                    <div className="flex h-auto flex-col px-5" key={index}>
                        <div className="h-[550px] flex flex-col items-center rounded-2xl bg-white border-2 border-main-300 px-8 text-center gap-2 py-10">
                            <img
                                alt="anh-san-pham"
                                src={product.image}
                                className="h-[200px] rounded-md"
                            />
                            <h3 className="mt-9 text-main-500">{product.name}</h3>
                            <p
                                title={product.des}
                                className="my-6 mb-3 font-normal leading-relaxed line-clamp-4 tracking-wide text-blue-900 ">
                                {product.des}
                            </p>
                            <a
                                href={product.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="cursor-pointer rounded-full bg-main-500 px-4 py-2 text-white transition hover:bg-main-600"
                            >
                                Xem chi tiết
                            </a>
                        </div>
                    </div>
                ))}
            </Carousel>
            {/* <Slider
                {...settings}
                arrows={false}
                ref={setSliderRef}
                className="mx-auto flex max-w-screen-xl gap-5 mb-10"
            >
                {PRODUCT_LIST.map((product, index) => (
                    <div className="flex h-auto flex-col px-5" key={index}>
                        <div className="h-[550px] flex flex-col items-center rounded-2xl bg-white border-2 border-main-300 px-8 text-center gap-2 py-10">
                            <img
                                alt="anh-san-pham"
                                src={product.image}
                                className="h-[200px] rounded-md"
                            />
                            <h3 className="mt-9 text-main-500">{product.name}</h3>
                            <p className="my-6 mb-3 font-normal leading-relaxed tracking-wide text-blue-900 ">
                                {product.des}
                            </p>
                            <a
                                href={product.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="cursor-pointer rounded-full bg-main-500 px-4 py-2 text-white transition hover:bg-main-600"
                            >
                                Xem chi tiết
                            </a>
                        </div>
                    </div>
                ))}
            </Slider> */}
            {/* {PRODUCT_LIST.length > MIN_PRODUCT_ARR_LENGTH && (
                <div className="mx-auto flex max-w-screen-xl items-center justify-end px-4 pb-5 lg:px-6 lg:pb-10">
                    <div className="mt-4 flex w-auto flex-none justify-between">
                        <button
                            className="group mx-4 flex h-14 w-14 cursor-pointer items-center justify-center rounded-full border-2 border-main-500 transition-all hover:bg-main-500 "
                            onClick={
                                sliderRef?.slickPrev
                            }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="50"
                                height="50"
                                fill="currentColor"
                                className="bi bi-arrow-left-short text-main-500 hover:text-white"
                                viewBox="0 0 16 16"
                                id="IconChangeColor"
                            >
                                {' '}
                                <path
                                    d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                                    id="mainIconPathAttribute"
                                ></path>{' '}
                            </svg>
                        </button>
                        <button
                            className="flex h-14 w-14 cursor-pointer items-center justify-center rounded-full border-2 border-main-500 transition-all hover:bg-main-500"
                            onClick={
                                sliderRef?.slickNext
                            }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="50"
                                height="50"
                                fill="currentColor"
                                className="bi bi-arrow-right-short text-main-500 hover:text-white"
                                viewBox="0 0 16 16"
                                id="IconChangeColor"
                            >
                                {' '}
                                <path
                                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                                    id="mainIconPathAttribute"
                                ></path>{' '}
                            </svg>
                        </button>
                    </div>
                </div>
            )} */}
        </section>
    );
};

export default OurProducts;
