/* eslint-disable */
import ModalManagement from '../../ModalManagement/ModalManagement'
import React from 'react'

const LayoutEmpty = ({ children }) => {
    return (
        <div>
            <ModalManagement />
            {children}
        </div>
    )
}

export default LayoutEmpty
