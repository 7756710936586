/* eslint-disable */
import Avatar from '../Avatar'
import { Link, useLocation } from 'react-router-dom'
import NavbarRoute from '../../routes/Navbar.route'
import React from 'react'
import PAGE from '../../config/path.route'

const TippyMenu = ({ loggedIn, user, visible, onClose, showLoginForm, showRegisterForm, userRole }) => {
    const { pathname } = useLocation()
    return (
        visible && (
            <div className="fixed z-[999] inset-0 lg:hidden" role="dialog" aria-modal="true">
                <div
                    className="fixed inset-0 bg-black/20 backdrop-blur-sm "
                    id="headlessui-dialog-overlay-23"
                    aria-hidden="true"
                >
                    <div className="fixed top-4 right-4 w-full max-w-xs bg-white rounded-lg shadow-lg p-6 text-base font-semibold text-slate-900 ">
                        <button
                            type="button"
                            className="absolute top-5 right-5 w-8 h-8 flex items-center justify-center text-slate-500 hover:text-slate-600 "
                            tabindex="0"
                            onClick={onClose}
                        >
                            <span className="sr-only">Đóng</span>
                            <svg
                                viewBox="0 0 10 10"
                                className="w-2.5 h-2.5 overflow-visible"
                                aria-hidden="true"
                            >
                                <path
                                    d="M0 0L10 10M10 0L0 10"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                ></path>
                            </svg>
                        </button>
                        <ul className="space-y-6">
                            {NavbarRoute.map((navbar) => {
                                if (navbar.element) {
                                    const Element = navbar.element
                                    // const isActive = navbar.path === pathname
                                    const isActive = false
                                    return (
                                        <li>
                                            <Element
                                                to={navbar.path}
                                                className={`hover:text-sky-500 ${isActive && 'text-main-400'
                                                    }`}
                                            >
                                                {navbar.content}
                                            </Element>
                                        </li>
                                    )
                                }
                                return (
                                    <li>
                                        <a href={navbar.path} className="hover:text-sky-500 ">
                                            {navbar.content}
                                        </a>
                                    </li>
                                )
                            })}
                            {loggedIn &&
                                <>
                                    <li>
                                        <Link
                                            to={PAGE.userEvents}
                                            className={`hover:text-sky-500 `}
                                        >
                                            Thiệp của tôi
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={PAGE.profile}
                                            state={{ isResourcePackage: true }}
                                            className={`hover:text-sky-500 `}
                                        >
                                            Quản lý gói dịch vụ
                                        </Link>
                                    </li>
                                    <li>
                                        <p
                                            className="hover:text-sky-500"
                                            data-bs-toggle="modal"
                                            data-bs-target="#logOutModal"
                                        >
                                            Đăng xuất
                                        </p>
                                    </li>
                                </>
                            }
                        </ul>
                        {loggedIn && (
                            <div className="mt-6 pt-6 border-t border-slate-200 ">
                                <div className="flex items-center justify-start">
                                    <Link to={PAGE.profile} className="flex flex-row justify-center">
                                        <label
                                            for="account"
                                            className="text-slate-700 font-normal "
                                        >
                                            Đi tới Tài khoản
                                        </label>
                                        <div className="ml-6 block text-slate-400 hover:text-slate-500 ">
                                            <span className="sr-only">Avatar User</span>
                                            <Avatar
                                                username={user.user_name}
                                                avatarUrl={user.avatarUrl}
                                                userRole={userRole}
                                            />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )}
                        {!loggedIn && (
                            <>
                                <div className="mt-6 pt-6 border-t border-slate-200 ">
                                    <div className="flex items-center justify-start">
                                        <Link
                                            className="hover:text-sky-500 "
                                            to="#"
                                            onClick={showLoginForm}
                                        >
                                            Đăng nhập
                                        </Link>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <div className="flex items-center justify-start">
                                        <button
                                            className="focus:outline-none focus:ring-2 text-white bg-main-400 font-semibold h-8 px-3 rounded-lg w-full flex items-center justify-center"
                                            onClick={showRegisterForm}
                                        >
                                            Đăng ký
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        )
    )
}

export default TippyMenu
