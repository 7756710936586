/* eslint-disable */
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Loading from '../../components/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import userService from '../user/User.service';
import ToastNotify from '../../utils/Toast';
import { useDispatch } from 'react-redux';
import modalAction from '../../redux/modal/modal.action';
import ConfirmExitModal from './components/ConfirmExitModal';
import Regex from '../../utils/Regex';

const INIT_FORM_RESSETPASSWORD = {
    password: '',
    confirm_password: '',
};
const SCHEMA_RESSETPASSWORD = Yup.object({
    password: Yup.string()
        .required('Mật khẩu mới không được bỏ trống!')
        // .matches(
        //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,20}$/,
        //     'Mật khẩu gồm 6-20 ký tự chữ thường, chữ in hoa, và ký tự số'
        // ),
        .matches(
            Regex.password,
            'Mật khẩu gồm ít nhất 6 ký tự'
        ),
    confirm_password: Yup.string()
        .required('Mật khẩu xác nhận không được bỏ trống!')
        .oneOf([Yup.ref('password'), null], 'Mật khẩu xác nhận phải giống mật khẩu mới!'),
});

export default function ResetPassword() {
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [mode, setMode] = useState('default');
    useEffect(() => {
        userService
            .checkUser(id)
            .then((data) => {
                setUser(data);
            })
            .catch((err) => {
                ToastNotify.Error(err.message);
                navigate('/');
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);
    const formikResetPassword = useFormik({
        initialValues: INIT_FORM_RESSETPASSWORD,
        validationSchema: SCHEMA_RESSETPASSWORD,
        onSubmit: () => handleResetPassword(),
    });
    const handleResetPassword = () => {
        userService
            .resetPassword(id, formikResetPassword.values.password)
            .then(() => {
                ToastNotify.Success('Cập nhập mật khẩu thành công!');
                navigate('/');
                dispatch(modalAction.openModalLogin());
                // setMode('success')
            })
            .catch((err) => {
                ToastNotify.Error(err.message);
                navigate('/');
            });
    };
    const [showPassword, setShowPassWord] = useState({
        password: false,
        confirm_password: false,
    });
    const [check, setCheck] = useState({
        password: false,
        confirm_password: false,
    });
    if (loading) {
        return <Loading></Loading>;
    }
    return (
        <>
            <div className="flex h-[100vh] w-[100vw] items-center justify-center bg-gray-200">
                <div className="w-full max-w-lg">
                    <form
                        className="mb-4 flex flex-col rounded-xl bg-white px-8 pb-8 pt-6 shadow-md"
                        onSubmit={formikResetPassword.handleSubmit}
                    >
                        <div
                            className={'relative mx-auto inline-flex h-20 w-20 items-center justify-center  overflow-hidden rounded-full bg-[#7265e6] text-center '}
                        >
                            <span className="text-3xl font-medium text-white ">
                                {user.user_name.split('').slice(0, 2).join('').toUpperCase()}
                            </span>
                        </div>
                        <p className="mx-auto mt-3 text-2xl font-bold text-black">
                            {user.user_name}
                        </p>
                        <div id="input" className="my-2 flex w-full flex-col">
                            <label htmlFor="password" className="mb-2 text-left text-gray-500">
                                Mật khẩu mới
                            </label>
                            <div className="relative flex items-center">
                                <input
                                    type={showPassword.password ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    placeholder="Nhập mật khẩu mới"
                                    className={`border-1 w-full rounded-lg px-4 py-3 text-black placeholder:text-gray-300 focus:shadow-lg focus:outline-none focus:ring-1 ${
                                        formikResetPassword.errors.password &&
                                        check.password &&
                                        'border-red-500'
                                    }`}
                                    onBlur={() => {
                                        setCheck({ ...check, password: true });
                                    }}
                                    value={formikResetPassword.values.password}
                                    onChange={(e) =>
                                        formikResetPassword.setFieldValue(
                                            'password',
                                            e.target.value.trim()
                                        )
                                    }
                                    helperText={formikResetPassword.errors.password}
                                />
                                {showPassword.password ? (
                                    <FaEyeSlash
                                        className="absolute right-3 cursor-pointer"
                                        onClick={() => {
                                            setShowPassWord({
                                                ...showPassword,
                                                password: false,
                                            });
                                        }}
                                    />
                                ) : (
                                    <FaEye
                                        className="absolute right-3 cursor-pointer"
                                        onClick={() => {
                                            setShowPassWord({
                                                ...showPassword,
                                                password: true,
                                            });
                                        }}
                                    />
                                )}
                            </div>
                            {check.password && formikResetPassword.errors.password && (
                                <div className="mb-3">
                                    <span className="mt-1 text-left text-sm text-red-500">
                                        {formikResetPassword.errors.password}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div id="input" className="my-2 flex w-full flex-col">
                            <label
                                htmlFor="confirm_password"
                                className="mb-2 text-left  text-gray-500"
                            >
                                Xác nhận lại mật khẩu
                            </label>
                            <div className="relative flex items-center">
                                <input
                                    type={showPassword.confirm_password ? 'text' : 'password'}
                                    id="confirm_password"
                                    name="confirm_password"
                                    placeholder="Nhập mật khẩu xác nhận"
                                    className={`border-1  w-full rounded-lg px-4 py-3 text-black placeholder:text-gray-300 focus:shadow-lg focus:outline-none focus:ring-1 ${
                                        formikResetPassword.errors.confirm_password &&
                                        check.confirm_password &&
                                        'border-red-500'
                                    }`}
                                    onBlur={() => {
                                        setCheck({ ...check, confirm_password: true });
                                    }}
                                    value={formikResetPassword.values.confirm_password}
                                    onChange={(e) =>
                                        formikResetPassword.setFieldValue(
                                            'confirm_password',
                                            e.target.value
                                        )
                                    }
                                />
                                {showPassword.confirm_password ? (
                                    <FaEyeSlash
                                        className="absolute right-3 cursor-pointer"
                                        onClick={() => {
                                            setShowPassWord({
                                                ...showPassword,
                                                confirm_password: false,
                                            });
                                        }}
                                    />
                                ) : (
                                    <FaEye
                                        className="absolute right-3 cursor-pointer"
                                        onClick={() => {
                                            setShowPassWord({
                                                ...showPassword,
                                                confirm_password: true,
                                            });
                                        }}
                                    />
                                )}
                            </div>
                            {check.confirm_password && formikResetPassword.errors.confirm_password && (
                                <div className="mb-3">
                                    <span className="mt-1 text-left text-sm text-red-500">
                                        {formikResetPassword.errors.confirm_password}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="my-5 flex w-full flex-col">
                            <button
                                type="submit"
                                className="w-full rounded-lg bg-gradient-to-r from-[#51ADF5] to-[#2196F3] py-4 text-white hover:from-[#2196F3] hover:to-[#51ADF5]  hover:shadow-lg "
                            >
                                <div className="flex flex-row items-center justify-center">
                                    <div className="font-bold capitalize text-white">Xác nhận</div>
                                </div>
                            </button>
                            <button
                                type="button"
                                className="mt-3 w-full rounded-lg bg-gray-300 py-4 text-white  hover:shadow-lg "
                                // onClick={() => {
                                //     navigate('/')
                                // }}
                                data-bs-toggle="modal"
                                data-bs-target="#confirmExitModal"
                            >
                                <div className="flex flex-row items-center justify-center">
                                    <div className="font-bold capitalize text-gray-700">Hủy</div>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <ConfirmExitModal navigate={navigate} />
        </>
    );
}
