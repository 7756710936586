/* eslint-disable */

import { useEffect, useRef, useState } from "react";
import userService from "../User.service";
import { FaCrown, FaInfoCircle } from "react-icons/fa";
import { USER_ROLE } from "../../../config/constant/common.constant";
import { useSelector } from "react-redux";
import ModalConfirmPayment from "./ModalConfirmPayment";
import ToastNotify from "../../../utils/Toast";
import { formatDTSlash, formatNewDate } from "../../../utils/Times";
import { getHighestRole } from "../../../utils/common";
import ReactTooltip from "react-tooltip";
import ModalPaymentInfo from "./ModalPaymentInfo";
import { useLocation, useNavigate } from "react-router-dom";
import PAGE from "../../../config/path.route";

const calTotalLimitOfExtraPackage = (userData) => {
    // Tinh tong limit cua cac goi phụ user đang sử dụng
    let totalStorage = 0;
    let totalGuest = 0;
    userData?.user_package?.extra?.map(item => {
        totalStorage += (item.file_storage * item.quantity);
        totalGuest += (item.event_guest * item.quantity);
    })
    return {
        totalStorage,
        totalGuest
    }
}

// Tinh lại tiền gói phụ = Giá gói 1 ngày * Số ngày còn lại (Từ ngày mua hiện tại đến ngày hết hạn gói chính)
const calPriceOfPackageExtra = (priceExtraOrigin, cycleExtra, expiredAtBase) => {
    if (!expiredAtBase) {
        return priceExtraOrigin;
    }
    // tinh tong so ngay trong chu ky
    const startDayCycle = new Date();
    const endDayCycle = new Date(new Date().setMonth(new Date().getMonth() + cycleExtra)).setUTCHours(23, 59, 59, 999);
    const totalDayInCycleExtra = Math.round((endDayCycle - startDayCycle.getTime()) / (1000 * 3600 * 24));
    const pricePerDay = priceExtraOrigin / totalDayInCycleExtra

    // tinh so ngay con lai cua goi chinh
    const dayExpiredAtBase = new Date(expiredAtBase.toString()).setUTCHours(23, 59, 59, 999);
    const totalDayRemainBase = Math.round((dayExpiredAtBase - startDayCycle.getTime()) / (1000 * 3600 * 24));

    const pricePkExtra = Math.ceil(pricePerDay * totalDayRemainBase);
    return pricePkExtra
}

export default function ModalUpgradePackage({ isReloadTable, setIsReloadTable }) {
    const navigate = useNavigate();
    const location = useLocation();
    const userData = useSelector(state => state.auth.user)
    const [packageBaseUsing, _] = useState(userData?.user_package?.base);
    const [userRole, setUserRole] = useState(getHighestRole(userData?.roles))
    const [packages, setPackages] = useState(
        { base: [], extra: [] }
    );
    const [packageBaseSelected, setPackageBaseSelected] = useState()
    const [packageExtraSelected, setPackageExtraSelected] = useState([])
    const resourceAfterUpgradeDefault = {
        limitStorageBase: userData?.limitResource?.limitStorage - calTotalLimitOfExtraPackage(userData)?.totalStorage,
        limitGuestBase: userData?.limitResource?.limitGuest - calTotalLimitOfExtraPackage(userData)?.totalGuest,
        limitStorage: userData?.limitResource?.limitStorage,
        limitGuest: userData?.limitResource?.limitGuest
    }
    const expiredAtBase = userData?.user_package?.base?.expiredAt;
    const [resourceAfterUpgrade, setResourceAfterUpgrade] = useState(resourceAfterUpgradeDefault)
    const [totalAmount, setTotalAmount] = useState(0)
    const [isResetForm, setIsResetForm] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const toastLoadingId = useRef(null);
    const btnCloseRef = useRef();

    const [isOpenConfirm, setIsOpenConfirm] = useState(false)
    const funToggleConfirm = () => {
        setIsOpenConfirm(!isOpenConfirm);
    };
    const [isOpenPaymentInfo, setIsOpenPaymentInfo] = useState(false)
    const funTogglePaymentInfo = () => {
        if (!location.pathname.includes(PAGE.profile)) {
            navigate(PAGE.profile, {
                state: { isResourcePackage: true },
            })
        }
        setIsOpenPaymentInfo(!isOpenPaymentInfo);
    };
    const [orderInfo, setOrderInfo] = useState()

    const handleIncrement = (event) => {
        const packageId = event.currentTarget.value;
        const pkSelected = packages.extra.find(item => item._id === packageId)
        setPackageExtraSelected(prev => prev.map((item, index) => {
            if (item._id === packageId) {
                return {
                    ...item,
                    quantity: item.quantity + 1
                }
            } else {
                return item;
            }
        }));
        setResourceAfterUpgrade(prev => {
            return {
                ...prev,
                limitGuest: prev.limitGuest + pkSelected.event_guest,
                limitStorage: prev.limitStorage + pkSelected.file_storage
            }
        });
    }

    const handleDecrement = (event) => {
        const packageId = event.currentTarget.value;
        const pkSelected = packages.extra.find(item => item._id === packageId)
        setPackageExtraSelected(prev => prev.map((item, index) => {
            if (item._id === packageId) {
                return {
                    ...item,
                    quantity: item.quantity - 1
                }
            } else {
                return item;
            }
        }));
        setResourceAfterUpgrade(prev => {
            return {
                ...prev,
                limitGuest: prev.limitGuest - pkSelected.event_guest,
                limitStorage: prev.limitStorage - pkSelected.file_storage
            }
        });
    }

    const handleChangePackageBase = (e) => {
        const pkSelected = packages.base.find(item => item._id === e.target.id)
        setPackageBaseSelected(pkSelected)
        setResourceAfterUpgrade(prev => {
            return {
                limitGuestBase: pkSelected.event_guest,
                limitStorageBase: pkSelected.file_storage,
                limitGuest: prev.limitGuest - prev.limitGuestBase + pkSelected.event_guest,
                limitStorage: prev.limitStorage - prev.limitStorageBase + pkSelected.file_storage
            }
        });
    }

    const handlePayment = () => {
        setIsLoading(true);
        toastLoadingId.current = ToastNotify.Loading('Đang xử lý thông tin hóa đơn...');
        let packagesPayment = [];
        packageBaseSelected && packagesPayment.push({
            ...packageBaseSelected,
            quantity: 1,
            type: 'base'
        })
        packageExtraSelected?.map(pkExtra => {
            if (pkExtra?.quantity > 0) {
                packagesPayment.push({
                    ...pkExtra,
                    price: calPriceOfPackageExtra(pkExtra?.price,
                        pkExtra?.cycle,
                        (packageBaseSelected?.cycle ? formatNewDate(new Date().setMonth(new Date().getMonth() + packageBaseSelected.cycle)) : expiredAtBase)
                    ),
                    type: 'extra'
                })
            }
        })
        const payload = {
            user_id: userData._id,
            detail: packagesPayment
        }
        userService.postAdminCreateOder(payload)
            .then(({ data }) => {
                setOrderInfo(data.data);
                ToastNotify.Update(toastLoadingId.current, 'Hóa đơn đã gửi thành công. Vui lòng kiểm tra email của bạn!');
                if (setIsReloadTable) {
                    setIsReloadTable(!isReloadTable);
                }
                setIsOpenPaymentInfo(true);
            })
            .catch(({ response }) => {
                console.log('err postAdminCreateOder = ', response)
                ToastNotify.Update(toastLoadingId.current, response?.data?.message || 'Đã có lỗi xảy ra!', 'error')
            })
            .finally(() => {
                setIsLoading(false);
                toastLoadingId.current = null;
                btnCloseRef.current.click();
                setIsOpenConfirm(false);
            })
    }

    const findPricePackageBaseOfUser = () => {
        let price = 0;
        const pkBaseIdOfUser = packageBaseUsing?._id;
        const pkBaseOfUser = packages?.base?.find(item => item._id === pkBaseIdOfUser);
        if (pkBaseOfUser) {
            price = pkBaseOfUser.price
        }
        return price;
    }

    const resetForm = () => {
        setIsResetForm(!isResetForm);
        setTotalAmount(0);
    };

    useEffect(() => {
        let total = 0;
        if (packageBaseSelected) {
            total += packageBaseSelected.price
        }
        packageExtraSelected?.map(item => (total += calPriceOfPackageExtra(item.price,
            item.cycle,
            (packageBaseSelected?.cycle ? formatNewDate(new Date().setMonth(new Date().getMonth() + packageBaseSelected.cycle)) : expiredAtBase)
        ) * item.quantity));
        setTotalAmount(total);
    }, [packageBaseSelected, packageExtraSelected])


    useEffect(() => {
        userService.getAdminPackages()
            .then(({ data }) => {
                if (data?.data) {
                    setPackages(data.data)
                    const [basePkFirst] = data.data.base?.filter(item => item.role === USER_ROLE.VIP)
                    if (basePkFirst && userRole !== USER_ROLE.VIP) {
                        setPackageBaseSelected(basePkFirst);
                        setResourceAfterUpgrade({
                            limitGuestBase: basePkFirst.event_guest,
                            limitStorageBase: basePkFirst.file_storage,
                            limitGuest: basePkFirst.event_guest,
                            limitStorage: basePkFirst.file_storage
                        });
                    } else {
                        setPackageBaseSelected('');
                        setResourceAfterUpgrade(resourceAfterUpgradeDefault);
                    }
                    setPackageExtraSelected(data.data.extra.map(item => {
                        return {
                            ...item,
                            quantity: 0
                        }
                    }))
                }
            })
            .catch(err => console.log(err))
            .finally()
    }, [isResetForm])

    const removePackageBase = () => {
        const pkSelected = packageBaseSelected;
        setPackageBaseSelected('');
        setResourceAfterUpgrade(prev => {
            return {
                ...resourceAfterUpgradeDefault,
                limitGuest: prev.limitGuest - pkSelected.event_guest + resourceAfterUpgradeDefault.limitGuestBase,
                limitStorage: prev.limitStorage - pkSelected.file_storage + resourceAfterUpgradeDefault.limitStorageBase
            }
        });
    };
    const removePackageExtra = (extraId) => {
        const pkSelected = packages.extra.find(item => item._id === extraId)
        let quantityOfRemoveExtra = 0;
        setPackageExtraSelected(prev => prev.map((item) => {
            if (item._id === extraId) {
                quantityOfRemoveExtra = item.quantity;
                return {
                    ...item,
                    quantity: 0
                }
            } else {
                return item;
            }
        }));
        setResourceAfterUpgrade(prev => {
            return {
                ...prev,
                limitGuest: prev.limitGuest - pkSelected.event_guest * quantityOfRemoveExtra,
                limitStorage: prev.limitStorage - pkSelected.file_storage * quantityOfRemoveExtra
            }
        });
    }


    return (
        <>
            <ModalConfirmPayment isOpen={isOpenConfirm}
                funToggle={funToggleConfirm}
                handleConfirm={handlePayment}
                isLoading={isLoading}
            />
            <ModalPaymentInfo isOpen={isOpenPaymentInfo} funToggle={funTogglePaymentInfo} orderInfo={orderInfo} setIsReloadTable={() => setIsReloadTable(!isReloadTable)} />
            <div
                className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
                id="modalUpgradePackage"
                aria-modal="true"
                data-bs-backdrop="static"
                role="dialog"
            >
                <div className="relative pointer-events-none modal-dialog modal-dialog-centered modal-xl">
                    <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                        <div className="flex items-center justify-between p-4 border-b border-gray-300 bg-yellow-300 modal-header shrink-0 rounded-t-md">
                            <h5 className="text-[16px] font-bold leading-normal text-gray-800 uppercase">
                                Nâng cấp gói dịch vụ
                            </h5>
                            <button
                                type="button"
                                title="Đóng"
                                className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={resetForm}
                                ref={btnCloseRef}
                            ></button>
                        </div>
                        <div className="flex flex-col sm:flex-row  border-b border-gray-200 modal-content shrink-0 rounded-t-md px-5 gap-[10px] overflow-y-auto overflow-x-hidden min-h-[500px] md:min-h-[700px]">
                            <div className='w-full sm:w-[60%] h-full flex flex-col py-5 px-2'>
                                {
                                    packages?.base?.length > 0 &&
                                    <>
                                        <p className="text-black mb-2 font-semibold">
                                            Nâng cấp gói VIP
                                        </p>
                                        <div className="w-full grid sm:grid-cols-2 gap-5">
                                            {
                                                packages?.base?.map((item, index) => {
                                                    if (item.role === USER_ROLE.VIP) {
                                                        const isDisabled = findPricePackageBaseOfUser() > item.price;
                                                        const isUsing = packageBaseUsing ? packageBaseUsing?.package_id === item?._id : false;
                                                        return (
                                                            <label for={item._id}
                                                                className={`relative flex flex-col items-center bg-white p-5 border border-gray-200 rounded-lg shadow-md cursor-pointer ${isDisabled && 'opacity-20 border-gray-400'}`}>
                                                                <p className="w-full font-semibold text-gray-800 leading-tight uppercase mb-3">
                                                                    {item.package_name}
                                                                </p>
                                                                <p className="w-full ">
                                                                    <i className="text-[16px] mr-1.5 ml-[3px] text-blue-500 fas fa-database"></i>
                                                                    <span className="text-[15px]">Dung lượng: </span>
                                                                    <span className="text-lg text-black font-semibold"> {item.file_storage} </span>
                                                                    <span className="text-sm text-gray-600 font-semibold"> MB</span>
                                                                </p>
                                                                <p className="w-full ">
                                                                    <i className="text-[16px] mr-1 text-blue-500 fas fa-users"></i>
                                                                    <span className="text-[15px]">Khách mời: </span>
                                                                    <span className="text-lg text-black font-semibold"> {item.event_guest}</span>
                                                                    <span className="text-sm text-gray-600 font-semibold"> Khách</span>
                                                                </p>
                                                                <p className="w-full ">
                                                                    <i className="text-[16px] mr-1 ml-0.5 text-blue-500 fas fa-history"></i>
                                                                    <span className="text-[15px]">Thời hạn: </span>
                                                                    <span className="text-lg text-black font-semibold"> {item.cycle}</span>
                                                                    <span className="text-sm text-gray-600 font-semibold"> Tháng</span>
                                                                </p>
                                                                <p className="w-full mt-2">
                                                                    <span className="text-2xl text-black font-semibold">
                                                                        {/* {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item.price)} */}
                                                                        {new Intl.NumberFormat('vi-VN').format(item.price)}
                                                                    </span>
                                                                    <span className="text-[16px] text-gray-600 font-semibold"> VNĐ</span>
                                                                </p>
                                                                {
                                                                    !isDisabled &&
                                                                    <input type="radio" name="packageBase" id={item._id} value={item._id} className="hidden" checked={packageBaseSelected?._id === item._id}
                                                                        onChange={(e) => handleChangePackageBase(e)}
                                                                    />
                                                                }
                                                                <span aria-hidden="true" className="hidden absolute inset-0 border-2 border-blue-500 bg-blue-200 bg-opacity-10 rounded-lg">
                                                                    <span className="absolute top-4 right-4 h-6 w-6 inline-flex items-center justify-center rounded-full bg-blue-200">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5 text-blue-600">
                                                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                                                        </svg>
                                                                    </span>
                                                                </span>
                                                                <div className="absolute right-2 bottom-2 p-1.5 text-lg text-yellow-500 rounded-full border border-amber-400 bg-white">
                                                                    <FaCrown />
                                                                </div>
                                                                {
                                                                    isUsing &&
                                                                    <div className="absolute -top-[15px]">
                                                                        <span className="bg-blue-500  font-medium px-2.5 py-[3px] rounded text-white text-[11px]">Đang sử dụng</span>
                                                                    </div>
                                                                }
                                                            </label>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </>
                                }
                                {
                                    packageExtraSelected?.length > 0 &&
                                    <>
                                        <p className="w-full border-t border-gray-300 pt-2 text-black mt-5 mb-2 font-semibold">
                                            Nâng cấp gói phụ
                                        </p>
                                        <div className="w-full grid sm:grid-cols-2 gap-5">
                                            {
                                                packageExtraSelected?.map((item, index) => {
                                                    return (
                                                        <div className={`relative flex flex-col bg-white p-5 border-2 rounded-lg shadow-md ${item.quantity > 0 && 'border-blue-500'}`}>
                                                            <span className="font-semibold text-gray-800 leading-tight uppercase mb-3">
                                                                {item.package_name}
                                                            </span>
                                                            <p className="">
                                                                <i className="text-[16px] mr-1.5 ml-[3px] text-blue-500 fas fa-database"></i>
                                                                <span className="text-[15px]">Dung lượng: </span>
                                                                <span className="text-lg text-black font-semibold"> {item.file_storage} </span>
                                                                <span className="text-sm text-gray-600 font-semibold"> MB</span>
                                                            </p>
                                                            <p className="">
                                                                <i className="text-[16px] mr-1 text-blue-500 fas fa-users"></i>
                                                                <span className="text-[15px]">Khách mời: </span>
                                                                <span className="text-lg text-black font-semibold"> {item.event_guest}</span>
                                                                <span className="text-sm text-gray-600 font-semibold"> Khách</span>
                                                            </p>
                                                            <p className="mt-2">
                                                                <span className="text-2xl text-black font-semibold">
                                                                    {new Intl.NumberFormat('vi-VN').format(item.price)}
                                                                </span>
                                                                <span className="text-[16px] text-gray-600 font-semibold"> VNĐ</span>
                                                                <span className="text-[16px] text-gray-600 font-semibold"> ({item.cycle} Tháng)</span>
                                                            </p>
                                                            <p className="text-xs text-red-900 italic mt-0 mb-3">
                                                                * Giá tiền sẽ được tính lại theo thời gian sử dụng còn lại của gói VIP.
                                                            </p>
                                                            {/* Button them */}
                                                            <div className="absolute right-2 bottom-2 " >
                                                                <div className="flex items-center gap-x-1.5">
                                                                    <button type="button"
                                                                        title="Tối thiểu 0"
                                                                        id={`decrement_${item._id}`}
                                                                        value={item._id}
                                                                        onClick={handleDecrement}
                                                                        disabled={item.quantity === 0}
                                                                        className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-300 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                                                                    >
                                                                        <svg className="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path d="M5 12h14"></path>
                                                                        </svg>
                                                                    </button>
                                                                    <input className="p-0 w-6 text-lg bg-transparent border-0 text-blue-500 font-bold text-center focus:ring-0"
                                                                        type="text"
                                                                        value={item.quantity}
                                                                        disabled />
                                                                    <button type="button"
                                                                        id={`increment_${item._id}`}
                                                                        value={item._id}
                                                                        title="Thêm tối đa 5"
                                                                        disabled={item.quantity === 5}
                                                                        onClick={handleIncrement}
                                                                        className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-300 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                                                                    >
                                                                        <svg className="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path d="M5 12h14"></path>
                                                                            <path d="M12 5v14"></path>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="relative w-full sm:w-[40%] min-h-[500px] py-5 sm:border-l sm:border-t-0 border-t border-gray-500 flex justify-center items-start ">
                                <div className="w-full h-full sm:pl-5">
                                    <p className="text-black text-lg font-semibold">
                                        Thông tin tài nguyên
                                    </p>
                                    <table className="w-full mt-1 table-auto border border-gray-200  shadow-lg">
                                        <thead>
                                            <tr>
                                                <th className="border-b border-gray-300 py-3 px-2.5 text-center">
                                                    <p className="block antialiased font-sans text-xs font-bold uppercase text-gray-600"></p>
                                                </th>
                                                <th className="border-b border-gray-300 py-3 px-2.5 text-center">
                                                    <p className="block antialiased font-sans text-xs font-bold uppercase text-gray-600">Hiện tại</p>
                                                </th>
                                                <th className="border-b border-gray-300 py-3 px-2.5 text-center">
                                                    <p className="block antialiased font-sans text-xs font-bold uppercase text-gray-600">Sau nâng cấp</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="py-3 px-2.5 border-b border-blue-gray-50">
                                                    <div className="flex justify-center items-center gap-4">
                                                        <p className="block antialiased font-sans text-xs leading-normal  font-semibold text-gray-600">
                                                            Dung lượng
                                                        </p>
                                                    </div>
                                                </td>
                                                <td className="py-3 px-2.5 border-b border-blue-gray-50">
                                                    <p className="block antialiased text-center font-sans text-xs font-semibold text-blue-gray-600">
                                                        {userData?.limitResource?.limitStorage} MB
                                                    </p>
                                                </td>
                                                <td className="py-3 px-2.5 border-b border-blue-gray-50">
                                                    <p className="block antialiased text-center font-sans text-sm font-bold text-gray-800">
                                                        {resourceAfterUpgrade.limitStorage} MB
                                                        {resourceAfterUpgrade.limitStorage - userData?.limitResource?.limitStorage > 0 &&
                                                            <span className="text-emerald-500 ml-1 text-xs font-semibold">
                                                                +{resourceAfterUpgrade.limitStorage - userData?.limitResource?.limitStorage}
                                                            </span>
                                                        }
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-3 px-2.5 border-b border-blue-gray-50">
                                                    <div className="flex justify-center items-center gap-4">
                                                        <p className="block antialiased font-sans text-xs leading-normal  font-semibold text-gray-600">
                                                            Khách mời
                                                        </p>
                                                    </div>
                                                </td>
                                                <td className="py-3 px-2.5 border-b border-blue-gray-50">
                                                    <p className="block antialiased text-center font-sans text-xs font-semibold text-blue-gray-600">
                                                        {userData?.limitResource?.limitGuest} Khách
                                                    </p>
                                                </td>
                                                <td className="py-3 px-2.5 border-b border-blue-gray-50">
                                                    <p className="block antialiased text-center font-sans text-sm font-bold text-gray-800">
                                                        {resourceAfterUpgrade.limitGuest} Khách
                                                        {resourceAfterUpgrade.limitGuest - userData?.limitResource?.limitGuest > 0 &&
                                                            <span className="text-emerald-500 ml-1 text-xs font-semibold">
                                                                +{resourceAfterUpgrade.limitGuest - userData?.limitResource?.limitGuest}
                                                            </span>
                                                        }
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="py-3 px-2.5 border-b border-blue-gray-50">
                                                    <div className="flex justify-center items-center gap-4">
                                                        <p className="block antialiased font-sans text-xs leading-normal  font-semibold text-gray-600">
                                                            Thời hạn
                                                        </p>
                                                    </div>
                                                </td>
                                                <td className="py-3 px-2.5 border-b border-blue-gray-50">
                                                    <p className="block antialiased text-center font-sans text-xs font-semibold text-gray-600">
                                                        {expiredAtBase
                                                            ? formatDTSlash(expiredAtBase)
                                                            : formatDTSlash(new Date())}
                                                    </p>
                                                </td>
                                                <td className="py-3 px-2.5 border-b border-blue-gray-50">
                                                    <p className="block antialiased text-center font-sans text-sm font-bold text-gray-800">
                                                        {
                                                            packageBaseSelected?.cycle
                                                                ? formatDTSlash(new Date().setMonth(new Date().getMonth() + packageBaseSelected.cycle))
                                                                : (
                                                                    expiredAtBase
                                                                        ? formatDTSlash(expiredAtBase)
                                                                        : formatDTSlash(new Date())
                                                                )
                                                        }
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="mt-6 flex flex-col min-h-[50%]">
                                        <p className="text-black text-lg font-semibold">
                                            Thông tin hóa đơn
                                        </p>
                                        <div className="max-h-[350px] flex flex-col justify-between overflow-y-auto mb-[100px]">
                                            <table className="w-full table-auto ">
                                                <tbody>
                                                    {packageBaseSelected &&
                                                        <tr className="relative">
                                                            <td className="py-3 ">
                                                                <div className="flex flex-col justify-start items-start">
                                                                    <p className="block antialiased font-sans text-sm leading-normal font-semibold uppercase text-gray-600">
                                                                        {packageBaseSelected?.package_name}
                                                                    </p>
                                                                    <p className="block antialiased font-sans mt-0.5 text-[11px] text-red-900 italic leading-normal  font-semibold">
                                                                        (Thời hạn: {formatDTSlash(new Date())} - {
                                                                            packageBaseSelected?.cycle
                                                                                ? formatDTSlash(new Date().setMonth(new Date().getMonth() + packageBaseSelected.cycle))
                                                                                : (
                                                                                    expiredAtBase
                                                                                        ? formatDTSlash(expiredAtBase)
                                                                                        : formatDTSlash(new Date())
                                                                                )
                                                                        })
                                                                    </p>
                                                                </div>
                                                            </td>
                                                            <td className="pt-0 pb-4">
                                                                <p className="top-0 block antialiased text-center font-sans text-sm font-semibold text-gray-600">
                                                                    x1
                                                                </p>
                                                            </td>
                                                            <td className="pt-0 pb-4">
                                                                <p className="block antialiased text-end font-sans text-sm font-semibold text-gray-600">
                                                                    {new Intl.NumberFormat('vi-VN').format(packageBaseSelected?.price)}
                                                                    <span className="text-xs"> VNĐ</span>
                                                                </p>
                                                            </td>
                                                            {
                                                                userRole === USER_ROLE.VIP &&
                                                                <>
                                                                    <button
                                                                        data-tip
                                                                        data-for="button-remove-package-base"
                                                                        onClick={() => removePackageBase()}
                                                                        className="absolute bottom-3 right-0 text-xs text-red-500  hover:text-red-600"
                                                                    >
                                                                        <i className="fas fa-trash"></i>
                                                                    </button>
                                                                    <ReactTooltip
                                                                        id="button-remove-package-base"
                                                                        place="top"
                                                                        type="dark"
                                                                    >Xóa
                                                                    </ReactTooltip>
                                                                </>
                                                            }
                                                        </tr>
                                                    }
                                                    {
                                                        packageExtraSelected?.map((pkExtra, index) => {
                                                            if (pkExtra?.quantity > 0) {
                                                                return (
                                                                    <tr className="relative">
                                                                        <td className="py-3 ">
                                                                            <div className="flex flex-col justify-start items-start ">
                                                                                <p className="block antialiased font-sans text-sm leading-normal  font-semibold uppercase text-gray-600">
                                                                                    {pkExtra?.package_name}
                                                                                </p>
                                                                                <p className="block antialiased font-sans mt-0.5 text-[11px] text-red-900 italic leading-normal  font-semibold">
                                                                                    (Thời hạn: {formatDTSlash(new Date())} - {
                                                                                        packageBaseSelected?.cycle
                                                                                            ? formatDTSlash(new Date().setMonth(new Date().getMonth() + packageBaseSelected.cycle))
                                                                                            : (
                                                                                                expiredAtBase
                                                                                                    ? formatDTSlash(expiredAtBase)
                                                                                                    : formatDTSlash(new Date())
                                                                                            )
                                                                                    })
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                        <td className="pt-0 pb-5">
                                                                            <p className="block antialiased text-center font-sans text-sm font-semibold text-gray-600">
                                                                                x{pkExtra?.quantity}
                                                                            </p>
                                                                        </td>
                                                                        <td className="pt-0 pb-5">
                                                                            <p className="block antialiased text-end font-sans text-sm font-semibold text-gray-600">
                                                                                {new Intl.NumberFormat('vi-VN')
                                                                                    .format(calPriceOfPackageExtra(pkExtra?.price,
                                                                                        pkExtra?.cycle,
                                                                                        (packageBaseSelected?.cycle ? formatNewDate(new Date().setMonth(new Date().getMonth() + packageBaseSelected.cycle)) : expiredAtBase)
                                                                                    ) * pkExtra?.quantity)}
                                                                                <span className="text-xs"> VNĐ</span>
                                                                            </p>
                                                                        </td>
                                                                        <button
                                                                            data-tip
                                                                            data-for={`button-remove-package-extra-${pkExtra._id}`}
                                                                            onClick={() => removePackageExtra(pkExtra?._id)}
                                                                            className="absolute bottom-3 right-0 text-xs text-red-500  hover:text-red-600"
                                                                        >
                                                                            <i className="fas fa-trash"></i>
                                                                        </button>
                                                                        <ReactTooltip
                                                                            id={`button-remove-package-extra-${pkExtra._id}`}
                                                                            place="top"
                                                                            type="dark"
                                                                        >Xóa
                                                                        </ReactTooltip>
                                                                    </tr>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute bottom-0 sm:pl-5 pb-2 w-full flex flex-col justify-center">
                                    {
                                        totalAmount >= 0 &&
                                        <table className=" w-full table-auto ">
                                            <tbody>
                                                <tr>
                                                    <td className="py-3 border-t border-gray-300">
                                                        <div className="flex justify-start items-center gap-1">
                                                            <p className="block antialiased font-sans text-lg leading-normal  font-semibold text-gray-800">
                                                                Tổng tiền
                                                            </p>
                                                            <div className="relative group">
                                                                <button
                                                                    data-tip
                                                                    data-for="helper-payment"
                                                                    className="group relative flex items-center rounded-full border-[1px] border-blue-500 bg-white text-xs font-medium leading-tight text-white shadow-md transition duration-150 ease-in-out "
                                                                >
                                                                    <FaInfoCircle className="text-sm text-blue-500" />
                                                                </button>
                                                                <ReactTooltip
                                                                    id="helper-payment"
                                                                    place="top"
                                                                    type="light"
                                                                    border={true}
                                                                // effect="solid"
                                                                >
                                                                    <p className="text-black text-lg font-bold">Lưu ý: </p>
                                                                    <p>- Thời gian bắt đầu, kết thúc của gói <span className="font-bold">không thay đổi</span>  và hiệu lực của</p>
                                                                    <p>  gói chỉ được áp dụng <span className="font-bold">khi đơn đã hoàn thành</span>. </p>
                                                                    <p>- Vui lòng thanh toán trong ngày sớm nhất để hưởng trọn vẹn quyền lợi. </p>
                                                                    <p>- Nếu quá thời hạn vui lòng tạo đơn mới</p>
                                                                </ReactTooltip>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="py-3  border-t border-gray-200">
                                                        <p className="block antialiased text-end font-sans text-lg font-semibold text-blue-600">
                                                            {new Intl.NumberFormat('vi-VN').format(totalAmount)} VNĐ
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                    <button
                                        type="button"
                                        disabled={totalAmount <= 0}
                                        onClick={() => setIsOpenConfirm(true)}
                                        className={`bg-blue-600 text-white hover:bg-blue-700 focus:bg-blue-700 inline-block rounded  px-10 py-2.5 text-sm font-medium uppercase leading-tight  shadow-md transition duration-150 ease-in-out  hover:shadow-lg  disabled:opacity-50 disabled:pointer-events-none focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg`}
                                    >
                                        Thanh toán
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div className="flex flex-wrap items-center justify-end p-4 modal-footer shrink-0 rounded-b-md">
                            <button
                                type="button"
                                className="transition-all focus:bg-gray-50 active:bg-gray-50  inline-block rounded bg-white hover:bg-gray-200 border-[1px] border-gray-300 px-5 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 duration-150 ease-in-out"
                                data-bs-dismiss="modal"
                                onClick={resetForm}
                            >
                                Thoát
                            </button>
                        </div>
                    </div>
                </div>
                <style>
                    {
                        '\
                    input[type="radio"]:checked + span {\
                        display: block;\
                    };\
                    input[type="radio"]:not(:checked) + span {\
                        display: hidden;\
                    };\
                    '
                    }
                </style>
            </div>
        </>
    );
}