/* eslint-disable */
import moment from 'moment';
import 'moment/locale/vi.js';
moment.locale('vi');

export const getDateOfWeek = (date) => {
    const weekday = ['Chủ nhật', 'Thứ Hai', 'Thứ Ba', 'Thứ Tư', 'Thứ Năm', 'Thứ Sáu', 'Thứ Bảy'];
    const d = new Date(date);
    let day = weekday[d.getDay()];
    return day;
};
export const getDateOfMonth = (date) => 
    // const d = new Date(date)
    // return d.getDate()
     moment(date).format('DD');

export const getMonthOfYear = (date) => 
    // const d = new Date(date)
    // return d.getMonth() + 1
     moment(date).format('MM');

export const getYear = (date) => {
    const d = new Date(date);
    return d.getFullYear();
};

export const formatDatetime = (dateTime) => moment(dateTime).format('HH:mm [ngày] DD/MM/YYYY');

export const formatDatetimeOld = (dateTime) => moment(dateTime).fromNow();

export const formatDT = (dateTime) => moment(dateTime).format('DD-MM-YYYY');
export const formatDTSlash = (dateTime) => moment(dateTime).format('DD/MM/YYYY');
export const formatNewDate = (dateTime) => moment(dateTime).format('YYYY-MM-DD');

export const formatDatetimelocalInput = (dateTime) => moment(dateTime).format('YYYY-MM-DDTHH:mm');
