/* eslint-disable */
import axios from './axios'

export const getTempPaginate = ({ filter, category, search, alias }) => {
    return axios.post('/template/paginate', {
        ...(!filter || JSON.stringify(filter) == '{}' ? {} : { attributes: filter }),
        ...(!category || category.length == 0 ? {} : { category: category }),
        ...(!search || search == '' ? {} : { search: search }),
        ...(!alias || alias == '' ? {} : { alias: alias }),
    })
}
export const getTemps = (query = {}) => {
    return axios.get('/template', {
        params: query,
    })
}

export const getCategory = ({ slug }) => {
    return axios.get('/template-categories', {
        params: { mode: 'group', ...(!slug || slug == '' ? {} : { slug: slug }) },
    })
}

export const getOneTempCategory = (id) => {
    return axios.get(`/template-categories/${id}`)
}

export const getCategoryParent = () => {
    return axios.get('/template-categories/get-parent/combobox')
}
