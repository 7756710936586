/* eslint-disable */
import { FaCheckCircle, FaClock, FaEnvelopeOpenText, FaTimesCircle } from 'react-icons/fa';
import PAGE from '../../../config/path.route';
import envConstants from '../../../config/key';

const statusData = [
    {
        key: 'completed',
        icon: <FaCheckCircle className="text-green-600 text-[80px] mx-auto mb-3" />,
        title: 'Đơn hàng của bạn đã hoàn thành',
        des: 'Chúng tôi rất vui vì nhận được sự ủng hộ của bạn. Bạn đã có thể sử dụng các gói dịch vụ đã đăng ký.',
        helper: (
            <p className="text-gray-600 text-left mt-4 text-[12px]">
                Nếu bạn cần tư vấn thêm thông tin, vui lòng{' '}
                <a
                    href={envConstants.CLIENT_URL + PAGE.aboutus.replace('/', '')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer text-blue-500 underline font-bold text-center text-[14px] mt-5"
                >
                    liên hệ
                </a>{' '}
                với chúng tôi để được hỗ trợ sớm nhất.
            </p>
        ),
    },
    {
        key: 'pending',
        icon: <FaClock className="text-blue-600 text-[80px] mx-auto mb-3" />,
        des: 'Cảm ơn bạn đã tin tưởng sử dụng dịch vụ của chúng tôi. Vui lòng thực hiện thanh toán để hoàn thành đơn hàng',
        title: 'Đơn hàng của bạn đang được xử lý',
        helper: (
            <p className="text-gray-600 text-left mt-4 text-[12px]">
                Nếu bạn cần tư vấn thêm thông tin, vui lòng{' '}
                <a
                    href={envConstants.CLIENT_URL + PAGE.aboutus.replace('/', '')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer text-blue-500 underline font-bold text-center text-[14px] mt-5"
                >
                    liên hệ
                </a>{' '}
                với chúng tôi để được hỗ trợ sớm nhất.
            </p>
        ),
    },
    {
        key: 'canceled',
        icon: <FaTimesCircle className="text-red-600 text-[80px] mx-auto mb-3" />,
        des: 'Rất tiếc! Đơn hàng của bạn đã bị hủy, chúng tôi hi vọng sớm được phục vụ bạn trong đơn hàng tiếp theo',
        title: 'Đơn hàng của bạn đã bị hủy',
        helper: (
            <p className="text-gray-600 text-left mt-4 text-[12px]">
                Nếu bạn cần tư vấn thêm thông tin, vui lòng{' '}
                <a
                    href={envConstants.CLIENT_URL + PAGE.aboutus.replace('/', '')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer text-blue-500 underline font-bold text-center text-[14px] mt-5"
                >
                    liên hệ
                </a>{' '}
                với chúng tôi để được hỗ trợ sớm nhất.
            </p>
        ),
    },
    {
        key: 'paid',
        icon: <FaEnvelopeOpenText className="text-violet-600 text-[80px] mx-auto mb-3" />,
        des: 'Cảm ơn bạn đã xác nhận đơn hàng đã được thanh toán. Chúng tôi sẽ kiểm tra và hoàn thành đơn hàng của bạn trong thời gian sớm nhất',
        title: 'Xác nhận đơn hàng đã được thanh toán',
        helper: (
            <p className="text-gray-600 text-left mt-4 text-[12px]">
                Nếu đơn hàng chưa kịp xử lý hoặc bạn muốn ưu tiên xử lý đơn hàng, vui lòng{' '}
                <a
                    href={envConstants.CLIENT_URL + PAGE.aboutus.replace('/', '')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer text-blue-500 underline font-bold text-center text-[14px] mt-5"
                >
                    liên hệ
                </a>{' '}
                với chúng tôi để được hỗ trợ sớm nhất.
            </p>
        ),
    },
];

export default function StatusModal({
    status,
    setStatus,
    detail,
    setOrderInfo,
    setIsOpenPaymentInfo,
}) {
    const statusSelect = statusData.find((item) => item.key === status);
    return (
        <div
            className={`fade modal fixed left-0 top-0 z-[1056]  h-full w-full overflow-y-auto overflow-x-hidden outline-none ${
                status ? 'show' : ''
            } backdrop-brightness-50`}
            tabIndex="-1"
            style={{
                margin: 0,
                visibility: status ? 'visible' : 'hidden',
                transition: 'opacity 0.15s linear, visibility 0.5s linear',
            }}
            aria-modal="true"
            role="dialog"
        >
            <div className="modal-dialog modal-dialog-centered pointer-events-none relative w-auto">
                <div className="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
                    <div className="modal-header flex flex-col shrink-0 justify-between rounded-t-md mt-5 p-4 px-10">
                        {statusSelect?.icon}
                        <h5 className=" text-xl leading-normal text-gray-800 font-bold text-center mt-5">
                            {statusSelect?.title}
                        </h5>
                        <p className="text-left mt-5 text-gray-600 text-sm">{statusSelect?.des}</p>
                        <p className="text-black text-left mt-4 font-bold">
                            Mã đơn hàng:{' '}
                            <span className="text-blue-500">
                                {detail?.order_alias_string +
                                    detail?.order_alias_number.toString().padStart(5, '0')}
                            </span>
                        </p>
                        <p className="text-black text-left mt-1 font-bold">
                            Số tiền:{' '}
                            <span className="text-blue-500">
                                {new Intl.NumberFormat('vi-VN').format(detail?.total)} VNĐ
                            </span>
                        </p>
                        {statusSelect?.helper}
                    </div>
                    <div className="modal-footer flex shrink-0 flex-wrap items-center justify-center rounded-b-md p-8 pt-0 mt-5">
                        {status !== 'pending' && (
                            <button
                                type="button"
                                onClick={() => {
                                    setStatus();
                                }}
                                className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                            >
                                Đóng
                            </button>
                        )}
                        {status === 'pending' && (
                            <>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setStatus();
                                    }}
                                    className="ml-1 inline-block rounded bg-white border-[1px] border-gray-500 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-gray-700 shadow-md transition duration-150 ease-in-out hover:bg-gray-100 hover:shadow-lg "
                                >
                                    Đóng
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setStatus();
                                        setOrderInfo(detail);
                                        setIsOpenPaymentInfo(true);
                                    }}
                                    className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                                >
                                    Thông tin thanh toán
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
