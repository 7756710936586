/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import { date } from 'yup';
import ToastNotify from '../../../../../utils/Toast';
import Regex from '../../../../../utils/Regex';

const INITIAL_STATE = {
    time: '',
    date: '',
    name: '',
    place: '',
    address: '',
    linkGgMap: '',
};

export default function ModalTimeStart({ handleAddStartItem, selectDay, checkExistDate }) {
    const [timeStart, setTimeStart] = useState();

    useEffect(() => {
        if (selectDay) {
            setTimeStart(selectDay);
        } else {
            setTimeStart(INITIAL_STATE);
        }
    }, [selectDay]);
    const closeRef = useRef(null);

    const handleChange = (e) => {
        const timeStartCopy = { ...timeStart, [e.target.name]: e.target.value };
        setTimeStart(timeStartCopy);
    };

    const handleCloseModal = () => {
        if (selectDay) {
            setTimeStart(selectDay);
        } else {
            setTimeStart(INITIAL_STATE);
        }
    };

    const handleSubmit = () => {
        if (!timeStart.time) {
            ToastNotify.Error('Vui lòng chọn thời gian bắt đầu sự kiện!');
            return;
        }

        if (!timeStart.date) {
            ToastNotify.Error('Vui lòng chọn ngày bắt đầu sự kiện!');
            return;
        }

        if (timeStart.linkGgMap !== '' && !Regex.url.test(timeStart.linkGgMap)) {
            ToastNotify.Error('Vui lòng nhập đường dẫn hợp lệ!');
            return;
        }

        if (handleAddStartItem(timeStart)) {
            closeRef.current.click();
            handleCloseModal();
        }
    };

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="modalTimeStart"
            data-bs-backdrop="static"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-300 modal-header shrink-0 rounded-t-md">
                        <h5 className="text-[16px] font-bold leading-normal text-gray-800">
                            Ngày sự kiện
                        </h5>
                        <button
                            type="button"
                            title="Đóng"
                            ref={closeRef}
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleCloseModal}
                        ></button>
                    </div>
                    <div className="flex flex-col items-center w-full border-b border-gray-200 modal-content shrink-0 rounded-t-md p-[20px] gap-[10px]">
                        <div className="flex flex-col w-full gap-[10px]">
                            {/* <p className="-mb-2 text-[12px] text-red-500 italic">
                                * Lưu ý những ngày sự kiện không có chủ đề sẽ không hiển thị trên
                                thiệp!
                            </p> */}
                            <p className="font-bold text-[14px] text-black">Chủ đề</p>
                            <input
                                name="name"
                                value={timeStart?.name || ''}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                type="text"
                                placeholder={`VD: "Lễ khai mạc"`}
                                className={`form-control m-0 block w-[100%] rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none`}
                            />
                        </div>
                        <div className="flex w-full flex-col gap-2 mt-2">
                            <p className="font-bold text-[14px] text-black">
                                Thời gian<span className="text-red-500"> (*)</span>
                            </p>
                            <div className="flex w-full gap-2">
                                <div className="flex flex-col w-1/2 gap-[10px] ">
                                    <input
                                        name="time"
                                        value={timeStart?.time || ''}
                                        onChange={handleChange}
                                        type="time"
                                        placeholder="Nhập thời gian bắt đầu chương trình"
                                        className={` form-control m-0 block w-[100%] rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                                    />
                                </div>
                                <div className="flex flex-col w-1/2 gap-[10px]">
                                    <input
                                        name="date"
                                        value={timeStart?.date || ''}
                                        onChange={handleChange}
                                        type="date"
                                        placeholder="Nhập thời gian bắt đầu chương trình"
                                        className={` form-control m-0 block w-[100%] rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-full gap-[10px] mt-2">
                            <p className="font-bold text-[14px] text-black">Địa điểm</p>
                            <input
                                name="place"
                                value={timeStart?.place || ''}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                type="text"
                                placeholder={`VD: "Nhà Hàng A"`}
                                className={`form-control m-0 block w-[100%] rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none`}
                            />
                        </div>
                        <div className="flex flex-col w-full gap-[10px] mt-2">
                            <p className="font-bold text-[14px] text-black">Địa chỉ</p>
                            <input
                                name="address"
                                value={timeStart?.address || ''}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                type="text"
                                placeholder={`VD: "Số 1xx Thanh Xuân, Hà Nội"`}
                                className={`form-control m-0 block w-[100%] rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none`}
                            />
                        </div>
                        <div className="flex flex-col w-full gap-[10px] mt-2">
                            <p className="font-bold text-[14px] text-black">Đường dẫn bản đồ</p>
                            <input
                                name="linkGgMap"
                                value={timeStart?.linkGgMap || ''}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                type="text"
                                placeholder={`VD: "https://maps.app.goo.gl/V7iKpMMo5sjrTiRr9"`}
                                className={`form-control m-0 block w-[100%] rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none`}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover:bg-gray-50 transition-all focus:bg-gray-50 active:bg-gray-50  inline-block rounded bg-white border-[1px] border-gray-300 px-5 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                            onClick={handleCloseModal}
                        >
                            Thoát
                        </button>
                        <button
                            type="button"
                            onClick={handleSubmit}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg  focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                        >
                            Lưu
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
