/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-magic-numbers */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { useEffect, useState, useRef } from 'react';

// import IMAGES_WEDDING from '../../resource/wedding/images/_index';
// import Templates from '../events/components/Templates';
import { useParams, useNavigate } from 'react-router-dom';
import BaseModal from '../../../../../components/BaseModal';
import EventsService from '../../../../events/Events.service';
import PAGE from '../../../../../config/path.route';
import { FaPlus, FaSave } from 'react-icons/fa';
import IMAGES from '../../../../../assets/images/_index';
// import { setContentMeta } from '../../utils/MetaTag';
// import { CropperCanvas } from 'react-advanced-cropper';
// import Banks from '../../../../resource/wedding/_input/components/Banks';

// import { FaBirthdayCake, FaInfoCircle, FaUserCircle, FaMinusCircle, FaPlusCircle} from 'react-icons/fa';

const parseJsonData = (formData) => {
    // parse json
    const dataCopy = formData;
    let arrParse = [];
    let arrCopy = [];
    let keyName = '';
    // infoParty
    if (dataCopy?.infoParty) {
        arrCopy = dataCopy.infoParty;
        keyName = 'infoParty';
    } else {
        //  programes
        arrCopy = dataCopy.programes;
        keyName = 'programes';
    }
    if (Array.isArray(arrCopy)) {
        for (var idx = 0; idx < arrCopy.length; idx++) {
            if (typeof arrCopy[idx] === 'string') {
                arrParse.push(JSON.parse(arrCopy[idx]));
            } else {
                arrParse.push(arrCopy[idx]);
            }
        }
    } else {
        if (arrCopy) {
            arrParse.push(JSON.parse(arrCopy));
        }
    }
    return { ...dataCopy, [keyName]: arrParse };
};

export default function PreviewAlbum({ open, handleClose, handlePass }) {
    const navigate = useNavigate();
    const { eventId, url_guest: urlGuest } = useParams();
    const [formData, setFormData] = useState({});
    const [templateAlias, setTemplateAlias] = useState('');
    const [normalObj, setNormalObj] = useState({});
    const [loadData, setLoadData] = useState(false);

    useEffect(() => {
        if (eventId && open) {
            EventsService.getOneEventById(eventId)
                .then(({ data }) => {
                    //     const { templateAlias, formData, guest, title, thumbnail, data, eventConfig
                    // } =
                    //         res;
                    const title = data.form_data.titleMeta || data.name;
                    const thumbnail = data.thumbnail_url;
                    const templateAlias = data.template.alias;
                    const formData = data.form_data;
                    // const jsonData = parseJsonData({ ...data, ...formData });
                    // setContentMeta({ title, image: thumbnail })
                    setTemplateAlias(templateAlias);
                    // setGuest(guest);
                    setNormalObj({
                        album:
                            formData?.album?.length > 0
                                ? formData.album.map((x) => ({
                                    image: process.env.REACT_APP_SERVER_URL + x.image,
                                    id: x.id,
                                    index: x?.index,
                                }))
                                : [],
                    });
                    setFormData(parseJsonData({ ...data, ...formData }));
                    // setEventConfig(eventConfig);
                })
                .catch((error) => {
                    const AuthorizeError = 404;
                    if (error.status === AuthorizeError) {
                        navigate(PAGE.dashboard);
                    }
                    const ServerError = 500;
                    if (error.status === ServerError) {
                        navigate(PAGE.dashboard);
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                    }, 500);
                });
        }
    }, [open, loadData, eventId, urlGuest, navigate, templateAlias]);

    return (
        <>
            {
                open && (
                    <BaseModal
                        isOpen={open}
                        onClose={handleClose}
                        modalTitle={'Chọn từ danh sách ảnh trong Album'}
                        width="xl:!w-[70%]"
                    >
                        <div className="overflow-y-auto max-h-[70vh]">
                        <ul className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 md:gap-4">
                            {normalObj?.album?.length > 0 && (
                                normalObj?.album?.map((item,index)=>(
                                    <li
                                    key={index}
                                    className="group w-full">
                                        <div
                                            key={index}
                                            className="relative flex h-[200px] w-full justify-center rounded-xl border border-gray-300 bg-blue-100 backdrop-blur-md"
                                        >
                                            <img
                                                src={
                                                    item.image
                                                }
                                                className="max-h-[200px] w-full rounded-xl object-scale-down"
                                                alt="Cô dâu"
                                            />
                                            <button
                                                type="button"
                                                key={index}
                                                className="absolute -top-0 flex h-full w-full flex-col items-center justify-center rounded-lg bg-black/30 opacity-0 backdrop-blur-sm duration-500 ease-out group-hover:opacity-100"
                                                onClick={() => {
                                                    handlePass(item.image);
                                                }}
                                            >
                                                {/* <span className="fas fa-edit cursor-pointer text-3xl text-slate-100"></span> */}
                                                <FaPlus className="cursor-pointer text-3xl text-slate-100"/>
                                            </button>
                                        </div>
                                    </li>
                                ))
                            )}
                        </ul>
                        {normalObj?.album?.length === 0 &&
                            <div className="flex w-full flex-col items-center justify-center">
                                <img
                                    src={IMAGES.ALBUM}
                                    alt="not found"
                                    className="mx-auto w-1/3 opacity-70"
                                />
                                <h4 className="mt-5 text-center text-xl text-blue-900 ">
                                    Bạn chưa bổ sung danh sách ảnh trong Album!
                                </h4>
                            </div>           
                        }
                        </div>
                    </BaseModal>
                )
            }
        </>
    );
}
