/* eslint-disable */

import { useEffect, useState } from 'react';
import EventConfigService from '../../pushish.config/Event_config.service';
import { useParams } from 'react-router-dom';
import ToastNotify from '../../../../../utils/Toast';
import {
    FaComment,
    FaEnvelope,
    FaEnvelopeOpenText,
    FaGlobeAsia,
    FaItunesNote,
    FaLanguage,
    FaRegCalendarAlt,
    FaRegSnowflake,
    FaTrashAlt,
    FaUserPlus,
    FaUserTag,
    FaUsers,
} from 'react-icons/fa';

export function RemindModal({ eventTemplate }) {
    const { eventId } = useParams();
    const [oldData, setOldData] = useState({});

    const [autoRemind, setAutoRemind] = useState();
    const [autoGroup, setAutoGroup] = useState();
    const [autoInvite, setAutoInvite] = useState();
    const [autoResponse, setAutoResponse] = useState();
    const [autoJoin, setAutoJoin] = useState();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        await EventConfigService.findEventConfig(eventId)
            .then((data) => {
                setAutoRemind(data.auto_remind);
                setAutoGroup(data.auto_group);
                setAutoInvite(data.auto_invite);
                setAutoResponse(data.auto_response);
                setAutoJoin(data.auto_join);
                setOldData(data);
                console.log(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const handleSave = () => {
        EventConfigService.postEventConfig(eventId, {
            auto_remind: autoRemind,
            auto_group: autoGroup,
            auto_invite: autoInvite,
            auto_response: autoResponse,
            auto_join: autoJoin,
        })
            .then((data) => {
                setOldData(data);
                ToastNotify.Success('Đã lưu thông tin cài đặt');
            })
            .catch((err) => {
                ToastNotify.Error(err?.message || 'Có lỗi xảy ra vui lòng thử lại sau!');
            });
    };

    const handleReset = () => {
        setAutoGroup(oldData.auto_group);
        setAutoJoin(oldData.auto_join);
        setAutoInvite(oldData.auto_invite);
        setAutoResponse(oldData.auto_response);
        setAutoRemind(oldData.auto_remind);
    };

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="remindModal"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Cài đặt khách mời
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleReset}
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <div className="flex flex-col">
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                Tự động nhắc nhở
                            </h5>
                            <p className="text-[12px] text-black">
                                Thêm file lịch để nhắc nhở khách mời
                            </p>
                        </div>

                        <label className={`relative cursor-pointer`}>
                            <input
                                type="checkbox"
                                className="peer sr-only"
                                name={'auto_remind'}
                                value={autoRemind}
                                checked={autoRemind}
                                onChange={() => {
                                    setAutoRemind(!autoRemind);
                                }}
                                disabled={eventTemplate?.isExpired}
                            />
                            <div
                                style={{
                                    opacity: eventTemplate?.isExpired ? '50%' : '',
                                    cursor: eventTemplate?.isExpired ? 'not-allowed' : 'pointer',
                                }}
                                className="peer flex h-3 w-11 items-center rounded-full bg-gray-300 after:absolute after:left-0 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all peer-checked:bg-blue-600 peer-checked:after:-left-1 peer-checked:after:translate-x-full"
                            ></div>
                        </label>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <div className="flex flex-col">
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                Nhóm khách mời
                            </h5>
                            <p className="text-[12px] text-black pr-1">
                                Các nhóm khách mời của bạn có thể tự đăng ký thông tin thành viên sẽ
                                tham dự qua thiệp
                            </p>
                        </div>
                        <label className={`relative cursor-pointer`}>
                            <input
                                type="checkbox"
                                className="peer sr-only"
                                name={'auto_group'}
                                value={autoGroup}
                                checked={autoGroup}
                                onChange={() => {
                                    setAutoGroup(!autoGroup);
                                }}
                                disabled={eventTemplate?.isExpired}
                            />
                            <div
                                style={{
                                    opacity: eventTemplate?.isExpired ? '50%' : '',
                                    cursor: eventTemplate?.isExpired ? 'not-allowed' : 'pointer',
                                }}
                                className="peer flex h-3 w-11 items-center rounded-full bg-gray-300 after:absolute after:left-0 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all peer-checked:bg-blue-600 peer-checked:after:-left-1 peer-checked:after:translate-x-full"
                            ></div>
                        </label>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <div className="flex flex-col">
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                Người cùng tham dự
                            </h5>
                            <p className="text-[12px] text-black pr-1">
                                Khách mời của bạn có thể đăng ký thêm thông tin người cùng tham dự
                                qua thiệp
                            </p>
                        </div>

                        <label className={`relative cursor-pointer`}>
                            <input
                                type="checkbox"
                                className="peer sr-only"
                                name={'auto_invite'}
                                value={autoInvite}
                                checked={autoInvite}
                                onChange={() => {
                                    setAutoInvite(!autoInvite);
                                }}
                                disabled={eventTemplate?.isExpired}
                            />
                            <div
                                style={{
                                    opacity: eventTemplate?.isExpired ? '50%' : '',
                                    cursor: eventTemplate?.isExpired ? 'not-allowed' : 'pointer',
                                }}
                                className="peer flex h-3 w-11 items-center rounded-full bg-gray-300 after:absolute after:left-0 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all peer-checked:bg-blue-600 peer-checked:after:-left-1 peer-checked:after:translate-x-full"
                            ></div>
                        </label>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <div className="flex flex-col">
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                Phản hồi tham dự
                            </h5>
                            <p className="text-[12px] text-black pr-1">
                                Khách mời của bạn có thể gửi phản hồi xác nhận hoặc từ chối tham dự
                                qua thiệp
                            </p>
                        </div>

                        <label className={`relative cursor-pointer`}>
                            <input
                                type="checkbox"
                                className="peer sr-only"
                                name={'auto_response'}
                                value={autoResponse}
                                checked={autoResponse}
                                onChange={() => {
                                    setAutoResponse(!autoResponse);
                                }}
                                disabled={eventTemplate?.isExpired}
                            />
                            <div
                                style={{
                                    opacity: eventTemplate?.isExpired ? '50%' : '',
                                    cursor: eventTemplate?.isExpired ? 'not-allowed' : 'pointer',
                                }}
                                className="peer flex h-3 w-11 items-center rounded-full bg-gray-300 after:absolute after:left-0 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all peer-checked:bg-blue-600 peer-checked:after:-left-1 peer-checked:after:translate-x-full"
                            ></div>
                        </label>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <div className="flex flex-col">
                            <h5
                                className="text-base font-medium leading-normal text-black"
                                id="exampleModalScrollableLabel"
                            >
                                Tự đăng ký tham dự
                            </h5>
                            <p className="text-[12px] text-black pr-1">
                                Người dùng bất kỳ có thể tự gửi thông tin đăng ký tham dự qua thiệp
                                công khai
                            </p>
                        </div>
                        <label className={`relative cursor-pointer`}>
                            <input
                                type="checkbox"
                                className="peer sr-only"
                                name={'auto_join'}
                                value={autoJoin}
                                checked={autoJoin}
                                onChange={() => {
                                    setAutoJoin(!autoJoin);
                                }}
                                disabled={eventTemplate?.isExpired}
                            />
                            <div
                                style={{
                                    opacity: eventTemplate?.isExpired ? '50%' : '',
                                    cursor: eventTemplate?.isExpired ? 'not-allowed' : 'pointer',
                                }}
                                className="peer flex h-3 w-11 items-center rounded-full bg-gray-300 after:absolute after:left-0 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all peer-checked:bg-blue-600 peer-checked:after:-left-1 peer-checked:after:translate-x-full"
                            ></div>
                        </label>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                            onClick={handleReset}
                        >
                            Trở lại
                        </button>
                        {!eventTemplate?.isExpired && (
                            <button
                                type="button"
                                data-bs-dismiss="modal"
                                onClick={handleSave}
                                className="ml-1 inline-block rounded bg-blue-500 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                            >
                                Lưu
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
