/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import PAGE from '../../config/path.route';
import { useDispatch } from 'react-redux';
import modalAction from '../../redux/modal/modal.action';
import { Link } from 'react-router-dom';
import { FaUser, FaThList, FaInfoCircle, FaCrown } from 'react-icons/fa';
import { MdStorage } from 'react-icons/md';
import { USER_ROLE } from '../../config/constant/common.constant';

const Avatar = ({ username, avatarUrl, userRole }) => {
    let avatar;
    if (!avatarUrl) {
        avatar = (
            <div
                className={`inline-flex overflow-hidden relative justify-center items-center w-11 h-11 bg-blue-500 rounded-full `}
            >
                <span className="font-medium text-xs text-white ">
                    {username ? username.split('').slice(0, 2).join('').toUpperCase() : 'User'}
                </span>
            </div>
        )
    } else {
        avatar = <img className="w-11 h-11 rounded-full" src={avatarUrl} alt="Avatar User" />
    }

    return (
        <>
            <div className="relative inline-block w-12 h-12 "
                title={avatarUrl && 'Xem ảnh'}
                data-bs-toggle="modal"
                data-bs-target={avatarUrl && "#modalAvatarPreview"}
            >
                <div className={`w-12 h-12 rounded-full ${userRole === USER_ROLE.VIP && 'border-2 border-[#ffd700] ring-1 ring-yellow-500'}`}>
                    {avatar}
                </div>
                {
                    userRole === USER_ROLE.VIP &&
                    <div className="absolute -top-0.5 right-1/2 left-1/2 w-full h-full items-center gap-1 -translate-x-1/2 -translate-y-1/2 flex rounded-full text-yellow-500 ">
                        <FaCrown className='text-lg mx-auto' />
                    </div>
                }
            </div>
        </>)
}

const Popover = ({ username, userAvatar, setIsOpenPopper, userRole, fullname }) => {
    const dispatch = useDispatch();
    const boxRef = useRef();

    const showLogoutForm = (e) => {
        e.preventDefault();
        // funToggle()
        dispatch(modalAction.openModalLogout());
    };

    const setPopper = (val) => {
        setIsOpenPopper(val);
    };

    return (
        <div
            className="z-[9999] border border-gray-300 shadow-lg rounded-lg bg-white"
            style={{
                position: 'absolute',
                inset: '10px 0px auto auto',
                margin: '0px',
                transform: 'translate(0px, 21px)',
            }}
            onMouseEnter={() => setPopper(true)}
            onMouseLeave={() => setPopper(false)}
        >
            <ul
                className=" bg-white rounded-lg "
                style={{
                    borderRadius: '10px',
                    margin: '0',
                    overflow: 'hidden',
                    transition: 'all .3s ease-in-out',
                    willChange: 'opacity,transform',
                }}
            >
                <div className="py-4 pl-5 flex pr-10 items-center justify-center">
                    <Avatar
                        username={username}
                        avatarUrl={userAvatar}
                        userRole={userRole}
                    />
                    {/* {userAvatar ? (
                        <div className="inline-flex overflow-hidden relative justify-center items-center w-11 h-11 rounded-full">
                            <img src={userAvatar} alt="avatar" className="w-full h-full" />
                        </div>
                    ) : (
                        <div
                            className={`inline-flex overflow-hidden relative justify-center items-center w-11 h-11 bg-blue-500 rounded-full `}
                        >
                            <span className="text-sm font-medium text-white ">
                                {username
                                    ? username.split('').slice(0, 2).join('').toUpperCase()
                                    : 'User'}
                            </span>
                        </div>
                    )} */}
                    <div className="flex flex-col pl-3 cursor-default">
                        <p className={` text-lg font-bold text-black whitespace-nowrap ${fullname && 'leading-none'}`}>
                            {fullname || username}
                        </p>
                        {
                            fullname &&
                            <p className="pl-0.5 mt-1 mb-1.5 text-xs font-semibold text-gray-500 whitespace-nowrap ">
                                <i className="fas fa-user text-[10px] mr-1 "></i>{username}
                            </p>
                        }
                        {userRole === USER_ROLE.VIP ? (
                            <span className=" items-center w-max rounded-full text-[10px] leading-tight text-white bg-yellow-500 py-0 px-2">
                                Tài khoản VIP
                            </span>
                        ) : (
                            <span className=" items-center w-max rounded-full text-[10px] leading-tight text-black bg-gray-300 py-0 px-2">
                                Tài khoản thường
                            </span>
                        )}
                    </div>
                </div>
                <hr />
                {/* <p className="py-3 pl-5 pr-4  text-sm text-gray-600 cursor-pointer hover:text-main-500 hover:bg-gray-100" > */}
                <Link
                    to={PAGE.profile}
                    className="flex py-3 pl-5 pr-4  text-sm text-gray-600 cursor-pointer hover:text-main-500 hover:bg-gray-100"
                >
                    <FaUser className="mr-2 mt-0.5" />
                    Trang cá nhân
                </Link>
                {/* </p> */}
                <hr />
                {/* <Link to={PAGE.profile} state={{ isResourcePackage: true }}
                    className='flex py-3 pl-[19px] pr-4  text-sm text-gray-600 cursor-pointer hover:text-main-500 hover:bg-gray-100'>
                    <MdStorage className='text-[17px] mr-[7px] mt-0.5' />
                    Quản lý gói dịch vụ
                </Link>
                <hr />
                <Link to={PAGE.userEvents}
                    className='flex py-3 pl-5 pr-4  text-sm text-gray-600 cursor-pointer hover:text-main-500 hover:bg-gray-100'>
                    <FaThList className='mr-2 mt-0.5' />
                    Thiệp của tôi
                </Link> */}
                <Link
                    to={PAGE.help}
                    className="flex py-3 pl-5 pr-4  text-sm text-gray-600 cursor-pointer hover:text-main-500 hover:bg-gray-100"
                >
                    <FaInfoCircle className="text-[16px] mr-2 mt-0.5" />
                    Trung tâm trợ giúp
                </Link>
                <hr />
                <Link
                    to={PAGE.feature}
                    className="flex py-3 pl-5 pr-4  text-sm text-gray-600 cursor-pointer hover:text-main-500 hover:bg-gray-100"
                >
                    <FaThList className="mr-2 mt-0.5" />
                    Giới thiệu
                </Link>
                <hr />
                <p
                    className="py-3 pl-5 pr-4 text-sm text-gray-600 cursor-pointer hover:text-main-500 hover:bg-gray-100"
                    data-bs-toggle="modal"
                    data-bs-target="#logOutModal"
                >
                    <i className="fas fa-sign-out-alt mr-2"></i>Đăng xuất
                </p>
            </ul>
        </div>
    );
};

export default Popover;
