/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable id-length */
/* eslint-disable quotes */
/* eslint-disable no-magic-numbers */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';

// import IMAGES_WEDDING from '../../resource/wedding/images/_index';
import PublishInfoPartyService from './PublishInfoParty.service';
// import Templates from '../events/components/Templates';
import { useParams, useNavigate } from 'react-router-dom';
import themeCommon from '../../PublishMainMenu.config';

import { Helmet } from 'react-helmet-async';
import PAGE from '../../../../config/path.route';
import { Cropper } from 'react-mobile-cropper';
import BaseModal from '../../../../components/BaseModal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
    FaEdit,
    FaTrash,
    FaPlus,
    FaCamera,
    FaClock,
    FaArrowRight,
    FaMapMarkerAlt,
    FaMapMarkedAlt,
    FaPen,
    FaMeh,
    FaImage,
    FaSave,
} from 'react-icons/fa';
import ModalInfo from './components/ModalInfo';
import ToastNotify from '../../../../utils/Toast';
import ModalDelete from './components/ModalConfirmDelete';
import EventsService from '../../../events/Events.service';
import ContentSkeleton from './components/ContentSkeleton';
import { formatDT } from '../../../../utils/Times';
import { validateSizeOfFile } from '../../../../utils/common';
import LoadingButtonIcon from '../../../../components/LoadingButton/LoadingButtonIcon';
import ShortcutMenu from '../../components/ShortcutMenu';
import IMAGES from '../../../../assets/images/_index';
import ReactTooltip from 'react-tooltip';
import Products from '../publish.news/products/Products';
import TimelineShortcutMenu from '../../components/TimelineShortcutMenu';
import QuestionTitle from '../publish.question/QuestionTitle';
import Header from '../publish.sub/components/Header';
import { useSelector } from 'react-redux';

const parseJsonData = (formData) => {
    // parse json
    const dataCopy = formData;
    let arrParse = [];
    let arrCopy = [];
    let keyName = '';
    // infoParty
    if (dataCopy?.infoParty) {
        arrCopy = dataCopy.infoParty;
        keyName = 'infoParty';
    } else {
        //  programes
        arrCopy = dataCopy.programes;
        keyName = 'programes';
    }
    if (Array.isArray(arrCopy)) {
        for (var idx = 0; idx < arrCopy.length; idx++) {
            if (typeof arrCopy[idx] === 'string') {
                arrParse.push(JSON.parse(arrCopy[idx]));
            } else {
                arrParse.push(arrCopy[idx]);
            }
        }
    } else {
        if (arrCopy) {
            arrParse.push(arrCopy);
        }
    }
    return { ...dataCopy, [keyName]: arrParse };
};

export default function PublishContent() {
    const navigate = useNavigate();
    const { eventId, url_guest: urlGuest } = useParams();
    const [formData, setFormData] = useState({});
    const [partyEvent, setEventParty] = useState([]);
    const [selectParty, setSelectParty] = useState();
    const [templateAlias, setTemplateAlias] = useState('');
    const [imageToCropParty, setImageToCropParty] = useState(null);
    const [idxSelect, setIdxSelect] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isExpiredEvent, setIsExpiredEvent] = useState(false);
    const [event, setEvent] = useState();
    const { loggedIn } = useSelector((state) => state.auth);
    // const [guest, setGuest] = useState(undefined);
    // const [eventConfig, setEventConfig] = useState();
    const [valueMeta, setValueMeta] = useState({
        title: '',
        thumbnail: '',
    });

    // const handleSort = (arr) => {
    //     let newArr = arr;
    //     newArr.sort((a, b) => {
    //         const timeA = a.time.split(':');
    //         const timeB = b.time.split(':');

    //         const hourA = parseInt(timeA[0]);
    //         const hourB = parseInt(timeB[0]);

    //         if (hourA !== hourB) {
    //             return hourA - hourB;
    //         } else {
    //             const minuteA = parseInt(timeA[1]);
    //             const minuteB = parseInt(timeB[1]);
    //             return minuteA - minuteB;
    //         }
    //     });
    //     return newArr;
    // };

    const handleSort = (arr) => {
        let newArr = arr;
        newArr.sort((a, b) => {
            const timeA = new Date(`${a.date}T${a.time}:00`).getTime();
            const timeB = new Date(`${b.date}T${b.time}:00`).getTime();
            return timeA - timeB;
        });
        return newArr;
    };

    useEffect(() => {
        setLoading(true);
        if (eventId) {
            EventsService.getOneEventById(eventId)
                .then(({ data }) => {
                    setEvent(data);
                    setIsExpiredEvent(data?.isExpired);
                    const title = data.form_data.titleMeta;
                    const thumbnail = data.thumbnail_url;
                    const templateAlias = data.template.alias;
                    const formData = data.form_data;
                    // setContentMeta({ title, image: thumbnail })
                    setValueMeta({
                        title,
                        thumbnail,
                    });
                    setTemplateAlias(templateAlias);
                    // setGuest(guest);

                    setFormData(parseJsonData({ ...data, ...formData }));

                    if (Array.isArray(formData.infoParty)) {
                        setEventParty(
                            formData.infoParty.sort((a, b) => {
                                const timeA = a.time.split(':');
                                const timeB = b.time.split(':');

                                const hourA = parseInt(timeA[0]);
                                const hourB = parseInt(timeB[0]);

                                if (hourA !== hourB) {
                                    return hourA - hourB;
                                } else {
                                    const minuteA = parseInt(timeA[1]);
                                    const minuteB = parseInt(timeB[1]);
                                    return minuteA - minuteB;
                                }
                            })
                        );
                    } else {
                        let copyArr = [];
                        copyArr.push(formData.infoParty);
                        setEventParty(copyArr);
                    }

                    // setEventConfig(eventConfig);
                })
                .catch((error) => {
                    console.log(error);
                    const AuthorizeError = 404;
                    if (error.status === AuthorizeError) {
                        navigate(PAGE.dashboard);
                    }
                    const ServerError = 500;
                    if (error.status === ServerError) {
                        navigate(PAGE.dashboard);
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                });
        }
    }, [eventId, urlGuest, navigate, templateAlias]);

    const renderImg = (src) => process.env.REACT_APP_SERVER_URL + src;

    const changeFile = (event) => {
        const val = event.target.files[0];
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setImageToCropParty(image);
            // setCroppedImageGroom(image)
            setFormData({ ...formData, avatarGroom: [image] });
        });
        reader.readAsDataURL(val);
    };

    const submitForm = async (payload) => {
        // console.log(normalObj);

        await PublishInfoPartyService.submitForm(formData._id, convertObjectToArray(payload))
            .then(({ data }) => {
                // setFormData(parseJsonData(data?.form_data));

                if (Array.isArray(data?.form_data.infoParty)) {
                    setEventParty(data?.form_data.infoParty);
                } else {
                    let copyArr = [];
                    copyArr.push(data?.form_data.infoParty);
                    setEventParty(copyArr);
                }
                ToastNotify.Success('Lưu thành công!');
            })
            .catch((err) => ToastNotify.Error(err?.message || 'Đã có lỗi xảy ra.'));
        // window.location.reload();

        // await PublishGroomService.saveFiles(normalObj);
    };

    function convertObjectToArray(obj) {
        // Khởi tạo một mảng rỗng để lưu kết quả
        var result = [];

        // Duyệt qua các thuộc tính của đối tượng
        for (var key in obj) {
            // Tạo một đối tượng mới có các thuộc tính key, keyChild, value và elementId
            if (key !== 'id' && key !== 'image') {
                var newObj = {
                    key: 'infoParty', // Đặt key là infoParty
                    keyChild: key, // Đặt keyChild là tên của thuộc tính hiện tại
                    value: obj[key], // Đặt value là giá trị của thuộc tính hiện tại
                    elementId: obj.id.toString(), // Đặt elementId là giá trị của thuộc tính id của đối tượng
                };

                // Thêm đối tượng mới vào mảng kết quả
                result.push(newObj);
            }
        }

        // Trả về mảng kết quả
        return result;
    }

    const findMaxIdx = () => {
        // Khởi tạo một biến để lưu giá trị id lớn nhất
        var maxId = 0;

        // Duyệt qua các phần tử trong mảng
        for (var i = 0; i < partyEvent.length; i++) {
            // Lấy giá trị id của phần tử hiện tại
            var id = Number(partyEvent[i].id);

            // So sánh với giá trị id lớn nhất hiện có
            if (id > maxId) {
                // Nếu lớn hơn, cập nhật giá trị id lớn nhất
                maxId = id;
            }
        }

        // Cộng giá trị id lớn nhất với 1
        var result = maxId + 1;

        // Trả về kết quả
        return result;
    };
    const clearForm = () => {
        console.log('clear');
        setSelectParty({});
    };

    const addFile = (event, index) => {
        const val = event.target.files[0];
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setOpenModal(true);
            setImageToCropParty(image);
            setIdxSelect(index);
            // setImage(image);
            // setCroppedImageGroom(image)
        });
        reader.readAsDataURL(val);
    };

    const handleDelteEv = async () => {
        const payload = [{ key: 'infoParty', value: selectParty.id, isDeleted: true }];
        await PublishInfoPartyService.submitForm(formData._id, payload)
            .then(({ data }) => {
                // setFormData(parseJsonData(data?.form_data));

                if (Array.isArray(data?.form_data.infoParty)) {
                    setEventParty(data?.form_data.infoParty);
                } else {
                    let copyArr = [];
                    copyArr.push(data?.form_data.infoParty);
                    setEventParty(copyArr);
                }
                ToastNotify.Success('Xóa thành công!');
            })
            .catch((err) => ToastNotify.Error(err?.message || 'Đã có lỗi xảy ra.'));
    };

    useEffect(() => {
        if (!loggedIn) {
            const beforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = true;
            };
            window.addEventListener('beforeunload', beforeUnload);
            return () => {
                window.removeEventListener('beforeunload', beforeUnload);
            };
        }
    }, []);

    return (
        <div className="flex flex-col items-center gap-[1.5rem] pb-[2rem]">
            <Header event={event} isExpired={isExpiredEvent} />
            <div className="flex w-full justify-evenly mt-20">
                <div className="relative hidden w-[250px] lg:flex">
                    <TimelineShortcutMenu screen={'partyinfo'} />
                </div>
                {loading ? (
                    <ContentSkeleton />
                ) : (
                    <div className="w-[100vw] rounded-lg border-[1px] border-slate-200 bg-white-box shadow-lg lg:w-[48rem] 2xl:w-[60rem] ">
                        <div className="flex flex-col w-full  border-b-[1px] border-slate-200 px-3 py-2">
                            <p className="text-lg font-bold text-black ">
                                Danh sách chương trình
                            </p>
                            <QuestionTitle keyQues={'events'} />
                        </div>
                        <div className="flex w-full flex-col items-center gap-5 px-6 py-5">
                            {!isExpiredEvent && (
                                <>
                                    <div className=' w-full flex justify-end'>
                                        <button
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalContent"
                                            onClick={() => {
                                                setSelectParty({ id: findMaxIdx() });
                                            }}
                                            className="hidden gap-[6px] rounded-lg bg-blue-600 px-[12px] py-[8px] text-sm font-semibold text-white hover:bg-blue-700 md:flex "
                                        >
                                            <FaPlus className="mr-0.5 mt-0.5" />
                                            Thêm chương trình
                                        </button>
                                        <button
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalContent"
                                            onClick={() => {
                                                setSelectParty({ id: findMaxIdx() });
                                            }}
                                            className="block gap-[6px] rounded-lg bg-blue-600 px-[12px] py-[8px] text-sm font-semibold text-white hover:bg-blue-700 md:hidden"
                                        >
                                            <FaPlus />
                                        </button>
                                    </div>
                                </>
                            )}
                            {partyEvent?.length !== 0 ? (
                                handleSort(partyEvent)?.map((partyItem, index) => (
                                    <div
                                        key={index}
                                        className="relative flex w-full flex-col items-center gap-[28px] rounded-md bg-white p-6 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] md:flex-row md:items-start"
                                    >
                                        <div className="h-full">
                                            <div className="group relative h-[12em] w-[12rem]">
                                                {partyItem?.image ? (
                                                    <img
                                                        src={renderImg(partyItem?.image)}
                                                        className="h-full w-full rounded-md object-cover"
                                                        alt="Ảnh bữa tiệc"
                                                    />
                                                ) : (
                                                    <label className="mx-auto flex h-full w-full cursor-pointer appearance-none flex-col items-center justify-center gap-[20px] rounded-lg border-2 border-dashed border-gray-300 bg-gray-300 text-[12px] text-white transition hover:border-gray-400 focus:outline-none">
                                                        <FaCamera className="text-[32px]" />
                                                        {/* <span className="text-[14px] font-semibold text-violet-500">
                                                            Thêm ảnh{' '}
                                                            <span className="text-[14px] font-medium text-gray-400">
                                                                (Giới hạn 10Mb)
                                                            </span>
                                                        </span> */}

                                                        <input
                                                            type="file"
                                                            className="hidden"
                                                            // onChange={(event) => changeFile(event)}
                                                            accept="image/*"
                                                        />
                                                    </label>
                                                )}
                                                {partyItem?.image && (
                                                    <button
                                                        className="absolute -top-0 flex h-full w-full flex-col items-center justify-center rounded-md bg-black/30 opacity-0 backdrop-blur-sm duration-500 ease-out group-hover:opacity-100"
                                                        onClick={() => {
                                                            setOpenModal(true);
                                                            setImageToCropParty(
                                                                renderImg(partyItem?.image)
                                                            );
                                                            setIdxSelect(partyItem?.id);
                                                        }}
                                                        disabled={isExpiredEvent}
                                                    >
                                                        {partyItem?.image ? (
                                                            <FaEdit className="cursor-pointer text-xl text-slate-100" />
                                                        ) : (
                                                            <FaPlus className="cursor-pointer text-xl text-slate-100" />
                                                        )}
                                                    </button>
                                                )}
                                                {!partyItem?.image && (
                                                    <label className="absolute -top-0 flex h-full w-full flex-col items-center justify-center rounded-md bg-black/30 opacity-0 backdrop-blur-sm duration-500 ease-out group-hover:opacity-100">
                                                        <FaPlus className="cursor-pointer text-xl text-slate-100" />
                                                        <input
                                                            type="file"
                                                            className="hidden"
                                                            onChange={(event) =>
                                                                addFile(event, partyItem?.id)
                                                            }
                                                            disabled={isExpiredEvent}
                                                            accept="image/*"
                                                        />
                                                    </label>
                                                )}
                                                <div className="absolute -right-3 -top-3 inline-flex h-8 w-8 items-center justify-center rounded-full border-2 border-white bg-red-500 text-xs font-bold text-white">
                                                    {partyItem?.image ? <FaPen /> : <FaPlus />}
                                                </div>
                                            </div>
                                            <div className="text-[11px] italic text-slate-600 ">
                                                Chú ý*: Dung lượng ảnh tối đa 10 Mb
                                            </div>
                                        </div>
                                        <div className="relative flex w-full flex-col gap-[28px] lg:w-[28rem] 2xl:w-[40rem]">
                                            {/* {
                                                !isExpiredEvent &&
                                                <div className="absolute right-[0px] top-[0px]  flex gap-[4px]  ">
                                                    <button
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#modalContent"
                                                        onClick={() => {
                                                            setSelectParty(partyItem);
                                                        }}
                                                        className="flex items-center gap-[4px] rounded-md px-[8px] py-[4px] text-sm font-semibold text-blue-600 transition-all hover:bg-blue-100"
                                                    >
                                                        <FaEdit /> Sửa{' '}
                                                    </button>
                                                    <button
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#confirmDeleteEvent"
                                                        onClick={() => {
                                                            setSelectParty(partyItem);
                                                        }}
                                                        className="flex items-center gap-[4px] rounded-md px-[8px] py-[4px] text-sm font-semibold text-red-500 transition-all hover:bg-red-100"
                                                    >
                                                        <FaTrash /> Xóa{' '}
                                                    </button>
                                                </div>
                                            } */}
                                            {/* <div className="flex w-full items-center justify-start gap-[16px]">
                                                <div className="flex flex-col gap-[8px]">
                                                    <p className="text-[16px] font-bold text-black">
                                                        {partyItem?.name}
                                                    </p>
                                                </div>
                                            </div> */}
                                            <div className="relative flex w-full flex-col gap-[28px] lg:w-[28rem] 2xl:w-[40rem]">
                                                {!isExpiredEvent && (
                                                    <div className="absolute right-[0px] top-[0px]  flex gap-[4px]  ">
                                                        <button
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#modalContent"
                                                            onClick={() => {
                                                                setSelectParty(partyItem);
                                                            }}
                                                            className="flex items-center gap-[4px] rounded-md px-[8px] py-[4px] text-sm font-semibold text-blue-600 transition-all hover:bg-blue-100"
                                                        >
                                                            <FaEdit /> Sửa{' '}
                                                        </button>
                                                        <button
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#confirmDeleteEvent"
                                                            onClick={() => {
                                                                setSelectParty(partyItem);
                                                            }}
                                                            className="flex items-center gap-[4px] rounded-md px-[8px] py-[4px] text-sm font-semibold text-red-500 transition-all hover:bg-red-100"
                                                        >
                                                            <FaTrash /> Xóa{' '}
                                                        </button>
                                                    </div>
                                                )}
                                                <div className="flex w-full items-center justify-start gap-[16px]">
                                                    <div className="flex flex-col gap-[8px]">
                                                        <p className="text-[16px] font-bold text-black">
                                                            {partyItem?.name}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`flex h-[50px] w-full items-center justify-start gap-[16px] rounded-md bg-slate-100 ${!partyItem?.time
                                                        ? 'text-red-400'
                                                        : 'text-gray-600'
                                                        } p-4 text-sm `}
                                                >
                                                    <FaClock />{' '}
                                                    {partyItem?.time
                                                        ? `Chương trình diễn ra lúc ${partyItem?.time
                                                        } ${partyItem?.date
                                                            ? ` ngày ${formatDT(
                                                                partyItem.date
                                                            )}`
                                                            : ''
                                                        }`
                                                        : 'Chưa có thời gian'}
                                                </div>

                                                <div className="flex w-full flex-col items-start justify-between gap-[20px] rounded-md bg-slate-100 p-4 md:flex-row md:items-center md:gap-0">
                                                    <div className="flex h-full max-w-[20rem] flex-col justify-between gap-[8px]">
                                                        <p
                                                            className={`flex items-center gap-[16px] text-sm ${!partyItem?.place
                                                                ? 'text-red-400'
                                                                : 'text-gray-600'
                                                                }`}
                                                        >
                                                            <FaMapMarkerAlt />{' '}
                                                            {partyItem?.place
                                                                ? `Chương trình diễn ra tại
                                                ${partyItem?.place}`
                                                                : 'Chưa có địa điểm '}
                                                        </p>

                                                        <p
                                                            className={`flex items-center gap-[16px] text-sm ${!partyItem?.address
                                                                ? 'text-red-400'
                                                                : 'text-gray-600'
                                                                }`}
                                                        >
                                                            <FaMapMarkedAlt />{' '}
                                                            {partyItem?.address
                                                                ? `Địa chỉ: ${partyItem?.address}`
                                                                : 'Chưa có địa chỉ'}
                                                        </p>
                                                    </div>
                                                    {/* {(partyItem?.address || partyItem?.linkGgMap) && (
                                                        <a
                                                            className="flex items-center gap-[4px] text-sm font-semibold text-blue-600 hover:text-blue-400"
                                                            href={
                                                                partyItem?.linkGgMap ||
                                                                'https://www.google.com/maps/search/?api=1&query=' +
                                                                partyItem?.address
                                                            }
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            Xem địa chỉ <FaArrowRight />
                                                        </a>
                                                    )} */}
                                                    {(partyItem?.address ||
                                                        partyItem?.linkGgMap) && (
                                                            <>
                                                                <a
                                                                    data-tip
                                                                    data-for={
                                                                        `icon-address` + partyItem?.id
                                                                    }
                                                                    className="flex items-center gap-[4px] text-sm font-semibold text-blue-600 hover:text-blue-400"
                                                                    href={
                                                                        partyItem?.linkGgMap ||
                                                                        'https://www.google.com/maps/search/?api=1&query=' +
                                                                        partyItem?.address
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    Xem địa chỉ <FaArrowRight />
                                                                </a>
                                                                <ReactTooltip
                                                                    id={`icon-address` + partyItem?.id}
                                                                    place="top"
                                                                    type="dark"
                                                                    delayShow={100}
                                                                    className="w-max"
                                                                >
                                                                    Địa chỉ được sử dụng trực tiếp từ
                                                                    đường dẫn bản đồ hoặc tìm kiếm theo
                                                                    tên địa chỉ.
                                                                </ReactTooltip>
                                                            </>
                                                        )}
                                                </div>
                                            </div>
                                            {/* <div className="flex justify-end gap-[4px] md:hidden md:flex-none">
                                                <button
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#modalContent"
                                                    onClick={() => {
                                                        setSelectParty(partyItem);
                                                    }}
                                                    className="flex items-center gap-[4px] rounded-md px-[8px] py-[4px] text-sm font-semibold text-blue-600 transition-all hover:bg-blue-100"
                                                >
                                                    <FaEdit /> Sửa{' '}
                                                </button>
                                                <button
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#confirmDeleteEvent"
                                                    onClick={() => {
                                                        setSelectParty(partyItem);
                                                    }}
                                                    className="flex items-center gap-[4px] rounded-md px-[8px] py-[4px] text-sm font-semibold text-red-500 transition-all hover:bg-red-100"
                                                >
                                                    <FaTrash /> Xóa{' '}
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                // ) : (
                                //     <div className="flex w-full flex-col items-center justify-center gap-[1rem]">
                                //         <FaMeh className="text-[32px] text-black" />
                                //         <p className="w-full text-center font-semibold text-black">
                                //             Chưa có chương trình nào
                                //         </p>
                                //     </div>
                                // ))
                                // <div className="flex w-full flex-col items-center justify-center gap-[1rem]">
                                //     <img
                                //         className='mx-auto w-1/2'
                                //         alt='Chưa có lịch trình nào'
                                //         src={IMAGES.TIMETABLE}
                                //     />
                                //     <p className="w-full text-center text-lg font-semibold text-slate-500">
                                //         Hãy nhập thông tin về chương trình đám cưới nhé!
                                //     </p>
                                //     <p className="w-full text-center text-slate-500">
                                //         Điều này sẽ giúp khách mời của bạn dễ dàng tham dự đám cưới của bạn.
                                //     </p>
                                // </div>
                                <div className="flex w-full flex-col items-center justify-center">
                                    <img
                                        src={IMAGES.TIMETABLE}
                                        alt="not found"
                                        className="mx-auto w-1/3 opacity-70"
                                    />
                                    <h4 className="mt-5 text-center text-base text-blue-900 ">
                                        Hãy{' '}
                                        {
                                            !isExpiredEvent ?
                                                <div
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#modalContent"
                                                    onClick={() => {
                                                        setSelectParty({ id: findMaxIdx() });
                                                    }}
                                                    className="inline-block cursor-pointer font-bold text-blue-500 underline"
                                                >
                                                    nhập thông tin
                                                </div>
                                                : <div className="inline-block font-bold">nhập thông tin</div>
                                        }
                                        {' '}
                                        về chương trình đám cưới nhé. Điều này sẽ giúp khách mời của bạn dễ dàng tham dự đám cưới của bạn!
                                    </h4>
                                    {/* <p className='mt-2 text-center text-sm text-black'>
										Hãy nhập thông tin về chương trình đám cưới nhé. Điều này sẽ giúp khách mời của bạn dễ dàng tham dự đám cưới của bạn
                                    </p> */}
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <div className="lg:flex hidden relative w-[250px]">
                    <Products />
                </div>
            </div>
            {/* </div> */}

            <ModalInfo
                data={selectParty}
                setSelectParty={setSelectParty}
                submitForm={submitForm}
                clearForm={clearForm}
                rootData={formData}
            />

            <ModalDelete handleDelteEv={handleDelteEv} />

            <BaseModal
                isOpen={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                modalTitle={`Bổ sung hình ảnh cho chương trình ${partyEvent[idxSelect - 1]?.name}`}
                width="max-w-[700px]"
            >
                {!imageToCropParty && (
                    <>
                        <label className="w-500-px mt-1.5 flex h-500-px w-full cursor-pointer appearance-none justify-center rounded-md border-2 border-dashed border-gray-300 bg-white px-4 transition hover:border-gray-400 focus:outline-none">
                            <span className="flex items-center space-x-2 py-10">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-gray-600"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                    />
                                </svg>
                                <span className="text-sm font-bold text-gray-700">Tải ảnh lên</span>
                            </span>
                            <input
                                className="hidden"
                                onChange={(event) => changeFile(event)}
                                type="file"
                                accept="image/*"
                            />
                        </label>
                    </>
                )}
                {imageToCropParty && (
                    <CropperModal
                        defaultsrc={imageToCropParty}
                        eventId={formData?._id}
                        templateAlias={templateAlias}
                        key={formData?._id}
                        partyIdx={idxSelect}
                        setOpenModal={setOpenModal}
                        setEventParty={setEventParty}
                    />
                )}
            </BaseModal>

            {/* <ShortcutMenu screen={'partyinfo'} /> */}
        </div>
    );
}

const CropperModal = ({
    defaultsrc,
    eventId,
    templateAlias,
    partyIdx,
    setOpenModal,
    setEventParty,
}) => {
    const cropperRef = useRef(null);
    const [image, setImage] = useState(defaultsrc);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const toastLoadingId = useRef(null);

    const changeFile = (event) => {
        const val = event.target.files[0];
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setImage(image);
            // setCroppedImageGroom(image)
        });
        reader.readAsDataURL(val);
    };

    const submitImageForm = async (name) => {
        setIsLoadingBtn(true);
        const file = await PublishInfoPartyService.createFile(
            cropperRef.current.getCanvas()?.toDataURL('image/jpeg', 1),
            'img_party'
        );

        if (
            validateSizeOfFile(
                file,
                10,
                'Dung lượng ảnh sau khi chỉnh sửa không được vượt quá 10Mb'
            )
        ) {
            toastLoadingId.current = ToastNotify.Loading('Đang lưu ảnh...');
            const fileOrigin = await PublishInfoPartyService.createFile(
                cropperRef.current.getCanvas()?.toDataURL(),
                'img_content'
            );
            PublishInfoPartyService.saveFiles(eventId, name, fileOrigin, partyIdx)
                .then(({ data }) => {
                    ToastNotify.Update(toastLoadingId.current, 'Cập nhật ảnh thành công!');
                    if (Array.isArray(data?.form_data.infoParty)) {
                        setEventParty(data?.form_data.infoParty);
                    } else {
                        let copyArr = [];
                        copyArr.push(data?.form_data.infoParty);
                        setEventParty(copyArr);
                    }
                    setIsLoadingBtn(false);
                })
                .catch((error) => {
                    ToastNotify.Update(
                        toastLoadingId.current,
                        error?.message || 'Đã có lỗi xảy ra khi lưu ảnh!',
                        'error'
                    );
                    setIsLoadingBtn(false);
                })
                .finally(() => {
                    setOpenModal(false);
                    toastLoadingId.current = null;
                });

            // const { data } = await PublishInfoPartyService.saveFiles(eventId, name, file, partyIdx);
            // ToastNotify.Success('Cập nhật ảnh thành công');
            // if (Array.isArray(data?.form_data.infoParty)) {
            //     setEventParty(data?.form_data.infoParty);
            // } else {
            //     let copyArr = [];
            //     copyArr.push(data?.form_data.infoParty);
            //     setEventParty(copyArr);
            // }
            // setOpenModal(false);
        }
    };
    return (
        <div className="mx-1.5 appearance-none justify-center rounded-md  bg-white p-2 transition focus:outline-none">
            {image.indexOf('undefined') === -1 ? (
                <Cropper
                    stencilProps={{
                        aspectRatio: 1,
                    }}
                    ref={cropperRef}
                    style={{ height: '500px', width: '500px' }}
                    className="cropper h-[500px] rounded-md"
                    src={image}
                />
            ) : (
                <label className="mx-auto flex h-[500px] w-full cursor-pointer appearance-none flex-col items-center justify-center gap-[20px] rounded-lg border-2 border-dashed border-gray-300 bg-white transition hover:border-gray-400 focus:outline-none">
                    <FaImage className="text-[60px] text-gray-500" />
                    <span className="text-[16px] font-semibold text-violet-500">
                        Thêm ảnh{' '}
                        <span className="text-[16px] font-medium text-gray-400">
                            (Giới hạn 10Mb)
                        </span>
                    </span>

                    <input
                        type="file"
                        className="hidden"
                        onChange={(event) => changeFile(event)}
                        accept="image/*"
                    />
                </label>
            )}
            {image.indexOf('undefined') === -1 && (
                <div className="m-2 mt-[20px] flex justify-center gap-2">
                    <label
                        className={`flex cursor-pointer items-center gap-[6px] rounded-lg bg-blue-100 px-[16px] py-[8px] text-[16px] font-semibold text-blue-700 hover:bg-blue-200 ${isLoadingBtn
                            ? 'cursor-auto bg-slate-100 text-slate-700 hover:bg-blue-100'
                            : ''
                            }`}
                    // onClick={() => btnRemoveImagePreview('avatarGroom')}
                    >
                        <input
                            className="hidden"
                            disabled={isLoadingBtn}
                            onChange={(event) => changeFile(event)}
                            type="file"
                            accept="image/*"
                        />
                        <FaPen /> Thay đổi
                    </label>
                    <button
                        className={`${isLoadingBtn ? 'bg-blue-500' : 'bg-blue-600 hover:bg-blue-700 '
                            } flex items-center gap-[6px] rounded-lg  px-[16px] py-[8px] text-[16px] font-semibold text-white disabled:bg-slate-700 `}
                        onClick={() => submitImageForm('infoParty')}
                        disabled={isLoadingBtn}
                    >
                        {isLoadingBtn && <LoadingButtonIcon />}
                        {!isLoadingBtn && <FaSave />}
                        Lưu
                    </button>
                </div>
            )}
        </div>
    );
};
