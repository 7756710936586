/* eslint-disable */
import IMAGES_WEDDING from '../../resource/wedding/images/_index';
import Templates from '../events/components/Templates';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getTemplates, getTemplatePaginate } from '../templates/Templates.service';

export default function TemplateView() {
    const [initData, setInitData] = useState();
    const [loading, setLoading] = useState(true);
    const searchParams = useParams();
    useEffect(() => {
        getTemplates({ select_field: ['form_data'], limit: 1, alias: searchParams.alias })
            .then((data) => {
                const dataCopy = data[0].form_data;
                let arrParse = [];
                let arrCopy = [];
                let keyName = '';
                // infoParty
                if (dataCopy?.infoParty) {
                    arrCopy = dataCopy.infoParty;
                    keyName = 'infoParty';
                } else if (dataCopy.programes) {
                    //  programes
                    arrCopy = dataCopy.programes;
                    keyName = 'programes';
                } else {
                    arrCopy = [];
                    keyName = 'programes';
                }
                if (Array.isArray(arrCopy)) {
                    for (var i = 0; i < arrCopy.length; i++) {
                        if (typeof arrCopy[i] === 'string') {
                            arrParse.push(JSON.parse(arrCopy[i]));
                        } else {
                            arrParse.push(arrCopy[i]);
                        }
                    }
                } else {
                    arrParse.push(JSON.parse(arrCopy));
                }
                // setInitData(data[0].form_data)
                setInitData({ ...dataCopy, [keyName]: arrParse });
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <></>;
    }
    return (
        <div className="h-[100dvh] w-[100vw] text-[20px] lg:text-[30px] ">
            {searchParams && (
                <Templates
                    templateAlias={searchParams.alias}
                    data={initData}
                    isView={true}
                    guest={undefined}
                />
            )}
        </div>
    );
}
