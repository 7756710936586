/* eslint-disable  */

import { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EventsService from '../../events/Events.service';
import { menuList } from '../PublishMainMenu.config';
import IMAGES from '../../../assets/images/_index';
import ToastNotify from '../../../utils/Toast';
import EventGreetingService from '../publish.mainmenu.detail/publish.greeting/PublishGreeting.service';
import { INIT_GET_NEW_GREETING, newGreetingReducer } from '../PublishMainMenu.page';
import { FaMapMarkerAlt } from 'react-icons/fa';

const ACTIVE_TAB = {
    top: 1,
    management: 2,
    infor: 3,
};
function CircleIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            // fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="12" cy="12" r="10" />
        </svg>
    );
}

const TimelineShortcutMenu = ({ screen, isEdit = false }) => {
    const navigate = useNavigate();
    const { eventId } = useParams();
    const [templateAlias, setTemplateAlias] = useState();
    const [openTab, setOpenTab] = useState();
    const [activeIcon, setActiveIcon] = useState();
    const [isExpiredEvent, setIsExpiredEvent] = useState(false);
    const [newGreeting, dispatchNewGreeting] = useReducer(
        newGreetingReducer,
        INIT_GET_NEW_GREETING
    );
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleClickMenu = (pathMenu) => {
        if (isEdit) {
            ToastNotify.Info('Bạn cần lưu thông tin trước nhé.');
        } else {
            navigate(pathMenu);
        }
    };

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (templateAlias) {
            const currentMenu = menuList(templateAlias).find((x) => x.path.includes(screen));
            if (currentMenu?.management) {
                setOpenTab(ACTIVE_TAB.management);
                setActiveIcon(ACTIVE_TAB.management);
            } else {
                if (currentMenu?.infor) {
                    setOpenTab(ACTIVE_TAB.infor);
                    setActiveIcon(ACTIVE_TAB.infor);
                }
            }
        }
    }, [templateAlias, screen]);

    useEffect(() => {
        if (eventId) {
            EventsService.getOneEventById(eventId)
                .then(async ({ data }) => {
                    const templateAlias = data.template.alias;
                    setTemplateAlias(templateAlias);
                    setIsExpiredEvent(data?.isExpired);
                })
                .catch((error) => {
                    console.log(error);
                });
            // get new greetings
            EventGreetingService.getNew(eventId)
                .then(({ data }) => {
                    dispatchNewGreeting({
                        type: 'get',
                        payload: { total: data?.length || 0 },
                    });
                })
                .catch((err) => console.log(err));
        }
    }, []);

    return (
        <>
            <div
                style={{ top: `${scrollPosition}px` }}
                className="absolute left-1/2 -translate-x-1/2 px-2 w-full max-w-64 z-10 "
            >
                <div className="w-full flex flex-col justify-start items-center p-3 bg-white border min-h-[85vh] border-gray-200 rounded-md shadow-md ">
                    {/* <p className="text-sm text-blue-900 font-bold text-center px-2">
                        Bạn đang hoàn thành thông tin của sự kiện
                    </p> */}
                    {/* <img src={IMAGES.TITLE_SHORTCUT_TIMELINE}
                        className="w-14 mt-0.5"
                    /> */}
                    <ul className="mt-1 w-full flex list-none flex-row flex-wrap " role="tablist">
                        {/* <li className="-mb-px  flex-auto text-center last:mr-0">
                            <a
                                className={
                                    'text-[11px] flex flex-row justify-center font-bold uppercase py-1  leading-normal ' +
                                    (openTab === ACTIVE_TAB.top
                                        ? 'text-blue-600  border-b-2 border-blue-600'
                                        : 'text-gray-400')
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpenTab(ACTIVE_TAB.top);
                                }}
                                data-toggle="tab"
                                href="#noi-bat"
                                role="tablist"
                            >
                                Nổi bật
                            </a>
                        </li> */}
                        <li className=" -mb-px  flex-auto text-center last:mr-0">
                            <a
                                className={
                                    'relative text-[11px] flex flex-row justify-center font-bold uppercase  py-1 leading-normal ' +
                                    (openTab === ACTIVE_TAB.infor
                                        ? 'text-blue-600  border-b-2 border-blue-600'
                                        : 'text-gray-400')
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpenTab(ACTIVE_TAB.infor);
                                }}
                                data-toggle="tab"
                                href="#thong-tin"
                                role="tablist"
                            >
                                Thông tin
                                {
                                    activeIcon === ACTIVE_TAB.infor &&
                                    <CircleIcon
                                        className={`text-blue-500 h-3 w-3 absolute top-0 right-2`}
                                        fill={'rgb(59 130 246)'}
                                    />
                                }
                            </a>
                        </li>
                        <li className="-mb-px  flex-auto text-center last:mr-0">
                            <a
                                className={
                                    'relative text-[11px] flex flex-row justify-center font-bold uppercase  py-1  leading-normal ' +
                                    (openTab === ACTIVE_TAB.management
                                        ? 'text-blue-600  border-b-2 border-blue-600'
                                        : 'text-gray-400')
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpenTab(ACTIVE_TAB.management);
                                }}
                                data-toggle="tab"
                                href="#cau-hinh"
                                role="tablist"
                            >
                                Cấu hình
                                {
                                    activeIcon === ACTIVE_TAB.management &&
                                    <CircleIcon
                                        className={`text-blue-500 h-3 w-3 absolute top-0 right-2`}
                                        fill={'rgb(59 130 246)'}
                                    />
                                }
                            </a>
                        </li>
                    </ul>
                    <div className="mt-2 w-full ">
                        <div
                            className={openTab === ACTIVE_TAB.top ? 'block w-full' : 'hidden'}
                            id="noi-bat"
                        >
                            <div className="w-full flex flex-col ">
                                {menuList(templateAlias)
                                    .filter((x) => x.top)
                                    .sort((prev, cur) => prev.index - cur.index)
                                    .map((menu, index, elements) => {
                                        // menu.disable =
                                        //     menu.disable ||
                                        //     (menu.path === '/:eventID/config' && isExpiredEvent);
                                        const isFirst = index === 0;
                                        const isLast = elements[index + 1] ? false : true;
                                        const isActive = menu.path.includes(screen);
                                        return (
                                            !menu.hidden && (
                                                <ItemMenu
                                                    menu={menu}
                                                    isFirst={isFirst}
                                                    isLast={isLast}
                                                    isActive={isActive}
                                                    isDisable={menu.disable}
                                                    handleClick={() => {
                                                        const path = menu.path
                                                            .replace(':urlShare', eventId)
                                                            .replace(':eventID', eventId);
                                                        !isActive &&
                                                            !menu.disable &&
                                                            handleClickMenu(path);
                                                    }}
                                                />
                                            )
                                        );
                                    })}
                            </div>
                        </div>
                        <div
                            className={openTab === ACTIVE_TAB.management ? 'block' : 'hidden'}
                            id="cau-hinh"
                        >
                            <div className="w-full flex flex-col ">
                                {menuList(templateAlias)
                                    .filter((x) => x.management && !x.hidden)
                                    .sort((prev, cur) => prev.index - cur.index)
                                    .map((menu, index, elements) => {
                                        // menu.disable =
                                        //     menu.disable ||
                                        //     (menu.path === '/:eventID/config' && isExpiredEvent);
                                        const isFirst = index === 0;
                                        const isLast = elements[index + 1] ? false : true;
                                        const isActive = menu.path.includes(screen);
                                        return (
                                            <ItemMenu
                                                menu={menu}
                                                isFirst={isFirst}
                                                isLast={isLast}
                                                isActive={isActive}
                                                isDisable={menu.disable}
                                                handleClick={() => {
                                                    const path = menu.path
                                                        .replace(':urlShare', eventId)
                                                        .replace(':eventID', eventId);
                                                    !isActive &&
                                                        !menu.disable &&
                                                        handleClickMenu(path);
                                                }}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                        <div
                            className={openTab === ACTIVE_TAB.infor ? 'block' : 'hidden'}
                            id="thong-tin"
                        >
                            <div className="w-full flex flex-col ">
                                {menuList(templateAlias)
                                    .filter((x) => x.infor && !x.hidden)
                                    .sort((prev, cur) => prev.index - cur.index)
                                    .map((menu, index, elements) => {
                                        menu.disable =
                                            menu.disable ||
                                            (menu.path === '/:eventID/config' && isExpiredEvent);
                                        const isFirst = index === 0;
                                        const isLast = elements[index + 1] ? false : true;
                                        const isActive = menu.path.includes(screen);
                                        return (
                                            <ItemMenu
                                                menu={menu}
                                                isFirst={isFirst}
                                                isLast={isLast}
                                                isActive={isActive}
                                                isDisable={menu.disable}
                                                handleClick={() => {
                                                    const path = menu.path
                                                        .replace(':urlShare', eventId)
                                                        .replace(':eventID', eventId);
                                                    !isActive &&
                                                        !menu.disable &&
                                                        handleClickMenu(path);
                                                }}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const ItemMenu = ({ menu, isFirst, isLast, isActive, isDisable, handleClick }) => {
    return (
        <div className={`flex items-center w-full mb-0.5 ${isDisable && 'opacity-60 '}`}>
            <div className="flex flex-col items-center mr-2">
                <div className={`w-px h-6 ${isFirst ? 'bg-white' : ' border border-dashed border-blue-300'} `} />
                <CircleIcon
                    className={`${isActive ? 'text-blue-500' : 'text-blue-300'} h-4 w-4`}
                    fill={isActive ? 'rgb(59 130 246)' : 'none'}
                />
                <div className={`w-px h-6 ${isLast ? 'bg-white' : 'border border-dashed border-blue-300'}`} />
            </div>
            <div
                title={menu.des}
                onClick={handleClick}
                className={`flex items-center justify-between w-full p-2  transition duration-500   ring-blue-400
                     ${isActive
                        ? 'bg-blue-200 ring-1 '
                        : isDisable
                            ? 'bg-gray-200'
                            : 'hover:ring-1 hover:scale-105 cursor-pointer bg-gray-200'
                    }  rounded-lg my-1`}
            >
                <div className="rounded-full p-2 bg-white">
                    <span
                        className={`${isActive ? 'text-blue-500' : 'text-gray-500'
                            } h-[23px] w-[22px] mx-auto flex items-center justify-center overflow-hidden border-none `}
                    >
                        {menu.icon}
                    </span>
                </div>
                <div className="ml-2 flex flex-col items-start justify-between w-full ">
                    <div
                        // title={menu.label}
                        className={` ${isActive ? 'text-blue-800' : 'text-black'
                            } font-semibold text-[13px] break-words line-clamp-2 leading-tight`}
                    >
                        {menu.label}
                    </div>
                    {/* <span
                        title={menu.des}
                        className={`text-[9px] ${isActive ? 'text-gray-700' : 'text-gray-500'
                            } mt-1  leading-tight break-words line-clamp-1`}
                    >
                        {menu.des}
                    </span> */}
                    {/* {
                        isActive &&
                        <span
                            className={`flex items-center text-[9px]  text-red-600 mt-0.5 leading-tight`}
                        >
                            <FaMapMarkerAlt className="text-red-600 text-xs mr-0.5" />
                            Bạn đang ở đây
                        </span>
                    } */}
                </div>
            </div>
        </div>
    );
};

export default TimelineShortcutMenu;
