/* eslint-disable */
import React from 'react'

const HeaderModalWithTitle = ({ title, onClose }) => (
    <div className="modal-header flex flex-shrink-0 items-center justify-between px-4 py-3 border-b border-gray-200 rounded-t-md">
        <h5 className="text-md font-medium leading-normal text-gray-800" id="exampleModalLabel">
            {title}
        </h5>
        <button
            type="button"
            className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={onClose}
        />
    </div>
)

const HeaderModalNoTitle = ({ onClose }) => (
    <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md ">
        <button
            type="button"
            className="btn-close box-content w-4 h-4 text-black border-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline absolute -right-8 -top-3 rounded-full bg-white"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={onClose}
        />
    </div>
)

const BaseModal = ({
    isOpen,
    onClose,
    modalTitle,
    children,
    modalActions,
    width = 'max-w-2xl',
	mt = null,
}) => {
    return (
        <>
            {isOpen && (
                <>
                    <div className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ${mt ? `mt-[${mt}]`: ''}`}>
                        <div className={`relative py-3 px-10 w-full ${width} h-full`}>
                            <div className="border-0 rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none mt-4">
                                {!modalTitle && <HeaderModalNoTitle onClose={onClose} />}
                                {modalTitle && 
                                    <div className="flex items-center justify-between p-4 border-b border-gray-300 modal-header shrink-0 rounded-t-md">
                                        <h5 className="text-[16px] font-bold leading-normal text-gray-800">
                                            {modalTitle}
                                        </h5>
                                        <button
                                            type="button"
                                            title="Đóng"
                                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={onClose}
                                        ></button>
                                    </div>
                                }
                                <div className="relative p-6 ">{children}</div>
                                {modalActions && (
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                        {modalActions}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 z-40 bg-overlay" />
                </>
            )}
        </>
    )
}

export default BaseModal
