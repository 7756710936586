/* eslint-disable */
/* eslint-disable */
import { getDateOfMonth, getDateOfWeek, getMonthOfYear, getYear } from '../../../../utils/Times';
import { useContext, useEffect, useState } from 'react';

import { FocusContext } from '../../../../context/Focus.context';
import { HighlightContext } from '../../../../context/Highlight.context';
import envConstants from '../../../../config/key';
import IMAGES_EVENT8 from '../assets/images';
import moment from 'moment';

import Snowfall from 'react-snowfall';
import { useParams } from 'react-router-dom';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import { statusConvert } from '../../../_index/helper';
import Sponsor from './Sponsor';

const TiltlePage = ({
    data = {},
    guest,
    guestFullname = '',
    salutation = '',
    guestTitle = '',
    isSubEvent = false,
    eventConfig,
    show,
}) => {
    const { url_share, url_guest } = useParams();
    const dateTimeData = moment(data?.dateHold + ' ' + data?.timeHold, 'YYYY-MM-DD HH:mm');
    const dataCopy = {
        invitation: data.invitation,
        titleEvent: data.titleEvent,
        nameEvent: data.nameEvent,
        logo: data.logo && data.logo[0],
        placeEvent: data.placeHold,
        addressEvent: data.addressHold,
        date: data.dateHold,
        time: data.timeHold,
        datetime: dateTimeData,
        nameSupporter: data.nameSupporter,
        phoneSupporter: data.phoneSupporter,
        linkGgMap: data?.linkGgMap ?? '',
        dress_1: data?.dress_1 ? data?.dress_1 : '',
        dress_2: data?.dress_2 ? data?.dress_2 : '',
    };

    const focusContext = useContext(FocusContext);
    const [highlightContext, setHighlightContext] = useContext(HighlightContext);

    const [pathImgLogo, setPathImgLogo] = useState('');

    useEffect(() => {
        if (
            !/^blob:/.test(dataCopy.logo) &&
            !/^data:image/.test(dataCopy.logo) &&
            !/^\/static\/media/.test(dataCopy.logo) &&
            dataCopy.logo !== undefined &&
            dataCopy.logo !== '' &&
            dataCopy.logo !== null &&
            dataCopy.logo !== false
        ) {
            setPathImgLogo(envConstants.SERVER_URL + dataCopy.logo);
        } else if (dataCopy.logo) {
            setPathImgLogo(dataCopy.logo);
        } else {
            setPathImgLogo('');
        }
    }, [dataCopy.logo]);

    useEffect(() => {
        if (!isSubEvent) {
            document
                .getElementById('audioID')
                .play()
                .catch((error) => {
                    document.addEventListener(
                        'click',
                        () => {
                            document.getElementById('audioID').play();
                        },
                        { once: true }
                    );
                });
        }
    }, []);

    const flower1 = document.createElement('img');
    flower1.src = IMAGES_EVENT8.CANH_HOA_1;
    const flower2 = document.createElement('img');
    flower2.src = IMAGES_EVENT8.CANH_HOA_2;
    const flower3 = document.createElement('img');
    flower3.src = IMAGES_EVENT8.CANH_HOA_3;
    const flower4 = document.createElement('img');
    flower4.src = IMAGES_EVENT8.CANH_HOA_4;
    const flower5 = document.createElement('img');
    flower5.src = IMAGES_EVENT8.CANH_HOA_5;

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return (
        <>
            {/* <iframe
                src={IMAGES_EVENT8.NHAC}
                type="audio/mp3"
                allow="autoplay"
                id="audio"
                style="display:none"
            ></iframe> */}
            <div
                className="relative mx-auto  min-h-screen w-full  bg-cover bg-center bg-no-repeat text-white"
                // className="w-full h-fit min-h-[100vh] mx-auto text-white bg-no-repeat bg-cover font-Comfortaa"
                style={{ backgroundImage: `url(${IMAGES_EVENT8.BG})` }}
            >
                {!isSubEvent && (
                    <>
                        <div className="absolute left-0 top-[30%]">
                            <img className="w-full" src={IMAGES_EVENT8.PHAO_HOA_VANG} />
                        </div>

                        <div className="absolute right-0 top-[50%] ">
                            <img className="w-0 md:w-full" src={IMAGES_EVENT8.PHAO_HOA_XANH} />
                        </div>
                    </>
                )}

                <div
                    className={`${isSubEvent ? '' : ''}
                        flex min-h-[100vh] w-full items-center justify-center py-3`}
                >
                    {!isSubEvent && (
                        <audio src={IMAGES_EVENT8.NHAC} id="audioID" loop autoPlay></audio>
                    )}
                    {/* {!isSubEvent && (
                        <Snowfall
                            images={[flower1, flower2, flower3, flower4, flower5]}
                            radius={[3.0, 15.0]}
                            speed={[1.0, 2.0]}
                        />
                    )} */}
                    <div className="mx-auto w-full" ref={focusContext.formGeneral}>
                        {isSubEvent ? (
                            <div className="-mt-1 flex h-[calc(5rem_+_2vh)] w-auto justify-center">
                                {pathImgLogo && (
                                    <img
                                        className=" h-[calc(5rem_+_2vh)] rounded-lg"
                                        src={pathImgLogo}
                                        loading="lazy"
                                        alt="logo"
                                    />
                                )}
                            </div>
                        ) : (
                            <div className="-mt-1 flex h-[calc(5rem_+_2vh)] w-auto justify-center">
                                {pathImgLogo && (
                                    <img
                                        className=" h-[calc(5rem_+_2vh)] rounded-lg"
                                        src={pathImgLogo}
                                    />
                                )}
                            </div>
                        )}

                        <div
                            className={
                                'mt-5 text-center ' +
                                (highlightContext === 'titleEvent' && ' animate-bounce ')
                            }
                        >
                            <span className="text-2xl uppercase font-bold md:text-3xl">
                                {dataCopy.titleEvent ? dataCopy.titleEvent : 'THƯ MỜI'}
                            </span>
                        </div>

                        {url_guest && url_share ? (
                            <>
                                <div className="mx-auto w-[90%] md:mt-3 md:w-2/3">
                                    <div className="mx-auto mb-3 mt-5 w-full">
                                        <p className="text-center text-[calc(0.7rem_+_1vh)] font-extrabold  leading-[28px] tracking-[2px] md:text-[calc(1rem_+_1vh)]">
                                            {salutation} {guestTitle}
                                        </p>
                                    </div>

                                    <div className="font-Comfortaa mx-auto mb-5 mt-3 text-center text-[16px] text-amber-200 md:text-lg">
                                        <p className="flex flex-col items-center justify-center break-words font-nautigal text-[calc(2rem_+_1vh)] font-extrabold leading-[50px] md:text-[calc(2rem_+_3vh)]">
                                            {guestFullname}
                                        </p>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="mx-auto w-[90%] md:mt-3 md:w-2/3">
                                <div className="mx-auto  mt-5 w-full">
                                    <p className="text-center text-[calc(0.7rem_+_1vh)] font-extrabold  leading-[28px] tracking-[2px] md:text-[calc(1rem_+_1vh)]">
                                        ..................
                                    </p>
                                </div>

                                <div className="font-Comfortaa mx-auto mb-5 mt-1 text-center text-[16px] text-amber-200 md:text-lg">
                                    <p className="flex flex-col items-center justify-center break-words font-nautigal text-[calc(2rem_+_1vh)] font-extrabold leading-[50px] md:text-[calc(2rem_+_3vh)]">
                                        ....................
                                    </p>
                                </div>
                            </div>
                        )}

                        {/* loi moi */}
                        <div
                            className={
                                'text-center mt-7 ' +
                                (highlightContext === 'invitation' && ' animate-bounce ')
                            }
                        >
                            <span className=" text-[calc(10px_+_1vh)] md:text-[calc(20px_+_1vh)] font-bold">
                                {dataCopy.invitation ? dataCopy.invitation : 'Tới tham dự'}
                            </span>
                        </div>
                        <div className="flex justify-center ">
                            <div
                                className={
                                    (!isSubEvent &&
                                        'text-center  text-[calc(30px_+_1vh)] lg:text-[calc(40px_+_3vh)] font-medium leading-[60px]  my-5 font-greatVibes text-amber-200 py-4') +
                                    (isSubEvent &&
                                        ' text-center  text-[calc(20px_+_1vh)] lg:text-[calc(18px_+_3vh)] font-medium leading-[50px] my-5 font-greatVibes text-amber-200 py-4 ') +
                                    (highlightContext === 'nameEvent' && ' animate-bounce')
                                }
                            >
                                {dataCopy.nameEvent ? dataCopy.nameEvent : 'Đại Tiệc Tất Niên'}
                            </div>
                        </div>

                        {/* <div className="relative mx-auto my-1 mt-5 flex w-[85%] max-w-[500px] justify-center sm:w-[50%] md:mt-10 md:w-[40%]">
                            <img
                                className="h-[auto] max-h-[200px] w-[100%] sm:h-auto sm:max-h-[2000px]"
                                src={IMAGES_EVENT8.HOA_TIET}
                                style={{ height: 'auto' }}
                            />
                            <img
                                className="absolute bottom-[12%] left-[22.5%] w-[30%] max-w-[150px]"
                                src={IMAGES_EVENT8.MEO}
                            />
                        </div> */}

                        {/* <div className="flex justify-center md:mt-2">
                            <div className="mb-3 flex flex-col items-center border-b border-gray-300 pb-3 pr-4 text-center text-amber-200">
                                <span
                                    className={
                                        'text-[calc(0.5rem_+_1vh)] md:text-[calc(1rem_+_1vh)] leading-[28px]  tracking-[2px] font-extrabold ' +
                                        (highlightContext === 'dateHold' && ' animate-bounce ')
                                    }
                                >
                                    {dateTimeData
                                        ? dateTimeData.locale('vi').format('HH:mm')
                                        : dataCopy.time
                                            ? dataCopy.time
                                            : moment(new Date()).format('HH:mm')}
                                    {' - '}
                                    {dateTimeData
                                        ? capitalizeFirstLetter(
                                            dateTimeData
                                                .locale('vi')
                                                .format('dddd, Do MMMM, YYYY')
                                        )
                                        : dataCopy.dateHold
                                            ? dataCopy.dateHold
                                            : moment(new Date()).format('dddd, MMMM Do, YYYY')}
                                </span>
                            </div>
                        </div> */}

                        {dataCopy?.time && dataCopy?.date && (
                            <div className="mt-2 flex justify-center">
                                <div className="relative flex flex-col px-2 md:px-6 pt-5 md:pt-7 pb-2 md:pb-4 items-center border rounded-md border-amber-200 text-center ">
                                    <span
                                        className={
                                            (!isSubEvent &&
                                                'text-[calc(0.4rem_+_1vh)] md:text-[calc(0.9rem_+_1vh)] leading-[28px]  font-bold ') +
                                            (isSubEvent &&
                                                'text-[calc(0.3rem_+_1vh)] md:text-[calc(0.7rem_+_1vh)] leading-[28px]  font-bold ')
                                        }
                                    >
                                        {dataCopy?.time && (
                                            <>
                                                <span className="text-amber-200">
                                                    {dataCopy?.time}
                                                </span>
                                                <span className="mx-1">-</span>
                                            </>
                                        )}
                                        {dataCopy?.date && (
                                            <>
                                                <span className="text-amber-200">
                                                    {getDateOfWeek(dataCopy?.date)}
                                                </span>
                                                <span className="text-gray-100 font-medium ">
                                                    , ngày
                                                </span>
                                                <span className="text-amber-200 mx-1">
                                                    {getDateOfMonth(dataCopy?.date)}
                                                </span>
                                                <span className="text-gray-100 font-medium ">
                                                    tháng
                                                </span>
                                                <span className="text-amber-200 mx-1">
                                                    {getMonthOfYear(dataCopy?.date)}
                                                </span>
                                                <span className="text-gray-100 font-medium ">
                                                    năm
                                                </span>
                                                <span className="text-amber-200 ml-1">
                                                    {getYear(dataCopy?.date)}
                                                </span>
                                            </>
                                        )}
                                    </span>
                                    <span className="absolute -top-4 md:-top-[17px] left-0 right-0 mx-auto px-8 md:px-10 py-1 text-sm md:text-[16px] bg-amber-200 text-red-700 w-fit tracking-[2px] font-extrabold rounded-sm">
                                        THỜI GIAN
                                    </span>
                                </div>
                            </div>
                        )}

                        <div className="flex justify-center mt-2">
                            <div className="flex flex-col items-center text-center">
                                {dataCopy?.placeEvent && (
                                    <span
                                        className={
                                            ' text-[calc(0.4rem_+_1vh)] md:text-[calc(0.8rem_+_1vh)] leading-[28px] tracking-[2px]  font-extrabold ' +
                                            (highlightContext === 'placeEvent' &&
                                                ' animate-bounce ')
                                        }
                                    >
                                        {' '}
                                        {dataCopy?.placeEvent}
                                    </span>
                                )}
                                <span
                                    className={
                                        ' text-[calc(0.2rem_+_1vh)] md:text-[calc(0.6rem_+_1vh)] leading-[28px] font-semibold  ' +
                                        (highlightContext === 'addressEvent' && ' animate-bounce ')
                                    }
                                >
                                    {dataCopy.addressEvent && dataCopy.addressEvent}{' '}
                                    {(dataCopy.addressEvent || dataCopy?.linkGgMap) && (
                                        <a
                                            href={`${
                                                dataCopy?.linkGgMap
                                                    ? dataCopy?.linkGgMap
                                                    : `${
                                                          'https://www.google.com/maps/search/?api=1&query=' +
                                                          dataCopy?.addressEvent
                                                      }`
                                            }`}
                                            rel="noreferrer"
                                            target="_blank"
                                            className={
                                                'ml-2 mr-1 border-amber-200 text-[16px] text-amber-200 hover:border-b '
                                            }
                                            title="View on map"
                                        >
                                            <i className="fas fa-map-marker-alt"></i>
                                        </a>
                                    )}
                                </span>
                                {url_guest && url_share && eventConfig?.auto_response && (
                                    <a
                                        href={'/' + url_share + '/' + url_guest + '/xac-nhan'}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <button className="mt-2 flex items-center rounded bg-amber-200 px-4 py-1.5 text-[calc(0.5rem_+_0.7vh)] font-semibold text-red-800 md:mt-4">
                                            {/* <FaEnvelopeOpenText className="mr-2" /> Xác nhận tham dự */}
                                            {statusConvert(guest?.status)}
                                        </button>
                                    </a>
                                )}
                                {!url_guest && url_share && eventConfig?.auto_join && (
                                    <button
                                        className="mt-2 flex items-center rounded bg-amber-200 px-4 py-1.5 text-[calc(0.5rem_+_0.7vh)] font-semibold text-red-800 transition-all md:mt-4"
                                        data-bs-toggle="modal"
                                        data-bs-target="#formModal"
                                    >
                                        <FaEnvelopeOpenText className="mr-2" /> Đăng ký tham dự
                                    </button>
                                )}
                                {dataCopy?.dress_1 || dataCopy?.dress_2 ? (
                                    <div className="w-full text-center mt-10">
                                        <p className="mt-8 w-full text-center text-[calc(0.3rem_+_1vh)] text-white md:text-[calc(0.5rem_+_1vh)]">
                                            Trang phục
                                        </p>
                                        {dataCopy?.dress_1 ? (
                                            <p className="w-full text-sm font-Comfortaa text-center font-medium mt-2  text-white">
                                                {dataCopy?.dress_1}
                                            </p>
                                        ) : null}
                                        {dataCopy?.dress_2 ? (
                                            <p className="w-full text-sm font-Comfortaa text-center font-medium mt-2  text-white">
                                                {dataCopy?.dress_2}
                                            </p>
                                        ) : null}
                                    </div>
                                ) : null}
                                {(dataCopy?.phoneSupporter || dataCopy?.nameSupporter) && (
                                    <p className="mt-8 w-full text-center text-[calc(0.3rem_+_1vh)] text-white md:text-[calc(0.5rem_+_1vh)]">
                                        Liên hệ hỗ trợ:{' '}
                                        <a
                                            className="cursor-pointer text-[calc(0.3rem_+_1vh)] font-bold text-white underline transition-all hover:text-amber-200 md:text-[calc(0.5rem_+_1vh)]"
                                            href={`tel:${dataCopy?.phoneSupporter}`}
                                        >
                                            {dataCopy?.phoneSupporter}
                                        </a>{' '}
                                        ({dataCopy?.nameSupporter})
                                    </p>
                                )}
                            </div>
                        </div>
                        {show && data?.sponsors && data?.sponsors?.length > 0 ? (
                            <Sponsor data={data} />
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TiltlePage;
