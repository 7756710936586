/* eslint-disable */
import GRAND_OPENNING from '../event-1/assets/images/open.png'
// import LOGO_AKB from '../event-2/assets/images/akb-software-logo.png'
import LOGO_SUNSHINE_GARDEN from '../event-2/assets/images/logo-sunshine-garden.png'
import ICON_EVENT_TOP from '../event-2/assets/images/icon-event1-top.png'
import ICON_EVENT_BOTTOM from '../event-2/assets/images/icon-event1-bottom.png'
import ICON_BACK from '../event-2/assets/images/tech-bg.png'

const IMAGES_EVENT = {
    // LOGO_AKB,
    LOGO_SUNSHINE_GARDEN,
    ICON_EVENT_BOTTOM,
    ICON_EVENT_TOP,
    ICON_BACK,
    GRAND_OPENNING
}

export default IMAGES_EVENT