/* eslint-disable */
import { set } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import ToastNotify from '../../../../../utils/Toast';

const checkValidText = (val) => {
    const regex = /^[^_\\.\\(\\)~`!@#$%^&*()+={}[\]|\?\/<>\\*]+$/g;
    if (!regex.test(val)) {
        return false;
    }
    return true;
};

export default function InfoTableModal({ handleCreateATable, selectTable }) {
    const closeRef = useRef(null);
    const [tableData, setTableData] = useState({});
    const [oldTableData, setOldTableData] = useState({});

    useEffect(() => {
        setTableData(selectTable);
        setOldTableData(selectTable);
    }, [selectTable]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setTableData({
            ...tableData,
            [name]: value,
        });
    };
    const handleSubmit = () => {
        if (tableData?.name === '' || tableData?.name === undefined) {
            ToastNotify.Error('Vui lòng nhập tên bàn!');
            return;
        }
        if (checkValidText(tableData?.name) === false) {
            ToastNotify.Error('Vui lòng không nhập ký tự đặc biệt vào tên bàn!');
            return;
        }
        handleCreateATable(tableData);
        closeRef.current.click();
    };
    const handleClear = () => {
        setTableData(oldTableData);
    };
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="modalInfoTable"
            data-bs-backdrop="static"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-300 modal-header shrink-0 rounded-t-md">
                        <h5 className="text-[16px] font-bold leading-normal text-gray-800">
                            {selectTable?._id ? 'Sửa thông tin bàn' : 'Thêm bàn mới'}
                        </h5>
                        <button
                            type="button"
                            title="Đóng"
                            ref={closeRef}
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClear}
                        ></button>
                    </div>
                    <div className="flex flex-col items-center justify-between w-full border-b border-gray-200 modal-content shrink-0 rounded-t-md p-[20px] gap-[10px]">
                        <div className="flex flex-col w-full gap-[10px]">
                            <p className="font-bold text-[14px] text-black">
                                Tên bàn <span className="text-red-500">(*)</span>
                            </p>
                            <input
                                name="name"
                                value={tableData?.name || ''}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                type="text"
                                placeholder={`VD: "Bàn 1"`}
                                className={` form-control m-0 block w-[100%] rounded border border-solid bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                                // className={` form-control m-0 block w-[100%] rounded border border-solid ${
                                //     validName ? 'border-red-500' : 'border-gray-300'
                                // } bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                            />
                            {/* <p className="text-sm text-red-500">{validName}</p> */}
                        </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover:bg-gray-50 transition-all focus:bg-gray-50 active:bg-gray-50  inline-block rounded bg-white border-[1px] border-gray-300 px-5 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                            onClick={handleClear}
                        >
                            Thoát
                        </button>
                        <button
                            type="button"
                            onClick={handleSubmit}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg  focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                        >
                            Lưu
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
