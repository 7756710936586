const modalTypes = {
    OPEN_MODAL_LOGIN: 'OPEN_MODAL_LOGIN',
    CLOSE_MODAL_LOGIN: 'CLOSE_MODAL_LOGIN',
    
    OPEN_MODAL_SIGNUP: 'OPEN_MODAL_SIGNUP',
    CLOSE_MODAL_SIGNUP: 'CLOSE_MODAL_SIGNUP',

    OPEN_MODAL_LOGOUT: 'OPEN_MODAL_LOGOUT',
    CLOSE_MODAL_LOGOUT: 'CLOSE_MODAL_LOGOUT',

    OPEN_MODAL_DELETE: 'OPEN_MODAL_DELETE',
    CLOSE_MODAL_DELETE: 'CLOSE_MODAL_DELETE',
};

export default modalTypes;
