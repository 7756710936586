/* eslint-disable */

import { FaUserCheck, FaEnvelopeOpenText, FaUserTimes } from 'react-icons/fa';

export const statusConvert = (status) => {
    const statusActive = status?.find(({ active }) => active === true)?.status;
    switch (statusActive) {
        case 'ACCEPT':
            return (
                <span className="flex items-center gap-2">
                    <FaUserCheck /> Bạn đã đồng ý tham dự
                </span>
            );
        case 'DECLINE':
            return (
                <span className="flex items-center gap-2">
                    <FaUserTimes /> Bạn đã từ chối tham dự
                </span>
            );
        default:
            return (
                <span className="flex items-center gap-2">
                    <FaEnvelopeOpenText /> Xác nhận tham dự
                </span>
            );
    }
};
