/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';

import IMAGES from '../../../assets/images/_index';
import { QRCodeSVG } from 'qrcode.react';
import envConstants from '../../../config/key';
import Slider from './components/Slider';
import SubEvent from './components/SubEvent';

export default function SubEvent3({
    data = {},
    guestKey = '',
    guestTitle = '',
    guestFullname = '',
    urlShare,
    guestName = '',
    salutation = '',
    refFrame,
    urlSlug = '',
}) {
    const [url, setUrl] = useState('');

    const makeUrlShare = useCallback((urlSlug, guestKey) => {
        if (urlSlug) {
            if (guestKey) {
                return (
                    process.env.REACT_APP_CLIENT_URL +
                    'chia-se/' +
                    urlSlug +
                    '?khachmoi=' +
                    guestKey
                );
            }
            return process.env.REACT_APP_CLIENT_URL + 'chia-se/' + urlSlug;
        }
        return '';
    }, []);

    useEffect(() => {
        const urlMake = makeUrlShare(urlSlug, guestKey);
        setUrl(urlMake);
    }, [urlSlug, guestKey, makeUrlShare]);

    return (
        <>
            <div
                tabIndex="0"
                ref={refFrame}
                className="relative flex h-auto flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none "
            >
                <div className="border-1 relative h-auto w-auto">
                    <SubEvent
                        data={data}
                        guestFullname={guestFullname}
                        guestTitle={guestTitle}
                        salutation={salutation}
                        isSubEvent={true}
                    />
                </div>
                {/** QR code */}
                <div className="absolute right-3 top-3 w-[15%] rounded-md  bg-gray-700 p-1.5">
                    {urlShare && (
                        <div className="rounded-md bg-white p-2">
                            <QRCodeSVG
                                value={urlShare}
                                bgColor={'rgb(59 130 246)'}
                                fgColor={'#ffffff'}
                                className="h-1/2 w-full"
                            />
                        </div>
                    )}
                    <div className="mt-1 flex">
                        <div className="w-full text-center text-white">
                            <i className="fas fa-camera  text-lg"></i>{' '}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="relative my-auto h-[100%] w-[100%] overflow-hidden" tabIndex="0" ref={refFrame}>
                <img className="h-full w-full object-cover" alt="_blank" src={IMAGES.BLANK} />
                {urlShare && (
                    <div className="absolute left-[5%] top-0 z-[9] mt-[1%] min-h-[100px] w-[15%] rounded-xl bg-[#3d4378] p-1">
                        <div className="w-full rounded-xl bg-white p-2">
                            <QRCodeSVG
                                value={urlShare}
                                bgColor={'#ffffff'}
                                fgColor={'#ccccc'}
                                className="h-auto w-full"
                            />
                        </div>
                        <div className="mt-2 flex">
                            <div className="w-full text-center text-white">
                                <i className="fas fa-camera mr-1 text-lg"></i>{' '}
                                <span className="text-sm">Xem chi tiết</span>
                            </div>
                        </div>
                    </div>
                )}
                <div className="absolute left-0 top-0 flex h-full w-full items-center justify-end bg-[url('./resource/events/event-3/assets/imgs/slider-bg.jpg')] bg-cover bg-center">
                    <div className="z-99 flex h-[50%] w-[60%] flex-col items-center justify-center ">
                        {data.logo[0] && (
                            <img
                                className="mb-10 h-auto w-[15%] lg:w-[120px]"
                                src={envConstants.SERVER_URL + data.logo[0]}
                            />
                        )}
                        <h2
                            className={'mb-5 text-center font-pacifico text-[25px] font-thin tracking-[5px] text-[#3d4378]'}
                        >
                            {salutation ? salutation : data.titleEvent}
                        </h2>
                        {guestFullname && (
                            <h2 className=" mb-10 max-w-[90%] text-center font-Merriweather text-[30px] leading-[1.2] tracking-[2px] text-[black]">
                                {guestTitle ? guestTitle : ''} {guestFullname ? guestFullname : ''}
                            </h2>
                        )}
                        <h1 className=" text mb-5 max-w-[90%] break-words text-center font-pacifico text-[25px] font-thin tracking-[3px] text-[#3d4378]">
                            {data.invitation || ''}
                        </h1>
                        <h2 className=" mb-10 max-w-[80%] text-center font-pacifico text-[25px] font-thin leading-[1.5] tracking-[2px] text-[blue]">
                            {data.nameEvent ? data.nameEvent : ''}
                        </h2>
                        <p className=" text max-w-[90%] break-words text-center font-pacifico text-[13px] font-thin tracking-[2px] text-[black] ">
                            ( Quét mã QR để xem chi tiết )
                        </p>
                    </div>
                </div>
            </div> */}
        </>
    );
}
