/* eslint-disable */
// modal hiển thị thông báo khi user xác nhận thanh toán nhưng đơn hàng đã đc admin duyệt hoàn thành hoặc hủy

import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { formatDatetime } from '../../../utils/Times';
import envConstants from '../../../config/key';
import PAGE from '../../../config/path.route';

const STATUS_ORDER = {
    COMPLETED: 'completed',
    CANCELED: 'canceled',
};

export default function ModalStatusConfirmError({ isOpen, close, detail }) {
    const [orderDetail, setOrderDetail] = useState({})
    useEffect(() => {
        if (detail?.code?.message) {
            setOrderDetail(detail.code.message)
        }
    }, [detail])

    return (
        <div
            className={`fade modal fixed left-0 top-0 z-[1056]  h-full w-full overflow-y-auto overflow-x-hidden outline-none ${isOpen ? 'show' : ''
                } backdrop-brightness-50`}
            tabIndex="-1"
            style={{
                margin: 0,
                visibility: isOpen ? 'visible' : 'hidden',
                transition: 'opacity 0.15s linear, visibility 0.5s linear',
            }}
            aria-modal="true"
            role="dialog"
        >
            <div className="modal-dialog modal-dialog-centered pointer-events-none relative w-auto">
                <div className="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
                    <div className="modal-header flex flex-col shrink-0 items-start justify-between rounded-t-md  p-4 px-10">

                        {orderDetail?.status === STATUS_ORDER.COMPLETED &&
                            <>
                                <FaCheckCircle className="text-green-600 text-[80px] mx-auto mb-3  mt-2" />
                                <h5 className="text-xl leading-normal text-gray-800 font-bold text-center mt-5 w-full">
                                    Đơn hàng đã được hoàn thành
                                </h5>
                                <p className="text-left mt-5 text-gray-600">
                                    Đơn hàng{' '}
                                    <span className='text-black font-bold'>
                                        {orderDetail?.order_alias_string + orderDetail?.order_alias_number?.toString()?.padStart(5, '0')}
                                    </span>{' '}
                                    của bạn đã được duyệt hoàn thành vào lúc {' '}
                                    <span className='text-gray-900 font-semibold'>
                                        {formatDatetime(orderDetail?.updatedAt)}
                                    </span>{' '}
                                </p>

                            </>
                        }
                        {orderDetail?.status === STATUS_ORDER.CANCELED &&
                            <>
                                <FaTimesCircle className="text-red-600 text-[80px] mx-auto mb-3 mt-2" />
                                <h5 className="text-xl leading-normal text-gray-800 font-bold text-center mt-5 w-full">
                                    Đơn hàng đã bị hủy
                                </h5>
                                <p className="text-left mt-5 text-gray-600">
                                    Đơn hàng{' '}
                                    <span className='text-black font-bold'>
                                        {orderDetail?.order_alias_string + orderDetail?.order_alias_number?.toString()?.padStart(5, '0')}
                                    </span>{' '}
                                    của bạn đã bị hủy với nội dung như sau:
                                </p>
                                <p className="text-black text-left mt-4 font-bold">
                                    Lý do:{' '}
                                    <span className="font-normal">
                                        {orderDetail?.reason}
                                    </span>
                                </p>
                                <p className="text-black text-left mt-2 font-bold">
                                    Thời gian:{' '}
                                    <span className="font-normal">
                                        {formatDatetime(orderDetail?.updatedAt)}
                                    </span>
                                </p>
                            </>
                        }
                        <p className="text-gray-600 text-left mt-6 text-xs italic">
                            Nếu bạn có thắc mắc về thông tin đơn hàng, vui lòng liên hệ với chúng tôi {' '}
                            <a
                                href={envConstants.CLIENT_URL + PAGE.aboutus.replace('/', '')}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="cursor-pointer text-blue-500 underline font-bold text-center text-[13px] "
                            >
                                tại đây
                            </a>{' '}
                            để được hỗ trợ sớm nhất.
                        </p>
                    </div>
                    <div className="modal-footer flex shrink-0 flex-wrap  items-center justify-end rounded-b-md p-4 pt-0">
                        <button
                            type="button"
                            onClick={close}
                            className="mt-5 inline-block rounded bg-gray-100 border border-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:bg-gray-200 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg"
                        >
                            Đóng
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
