import BG_TOP from './bg-top.png';
import BG_BOTTOM from './bg-bottom.png';
import BALLOON_1 from './balloon_1.png';
import BALLOON_2 from './balloon_2.png';

const IMAGES_EVENT11 = {
    BG_TOP,
    BG_BOTTOM,
    BALLOON_1,
    BALLOON_2,
};

export default IMAGES_EVENT11;
