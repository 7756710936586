/* eslint-disable */

import { useEffect, useRef, useState } from 'react';
import { Cropper } from 'react-mobile-cropper';
import { FaImage, FaPen, FaSave } from 'react-icons/fa';
import BaseModal from '../../../../../components/BaseModal';
import EventsService from '../../../../events/Events.service';
import ModalLeavingPage from '../../../../events/components/ModalLeavingPage';
import { validateSizeOfFile } from '../../../../../utils/common';
import ToastNotify from '../../../../../utils/Toast';
import PublishBrideService from '../PublishBride.service';
import LoadingButtonIcon from '../../../../../components/LoadingButton/LoadingButtonIcon';

export const TYPE = {
    Groom: {
        id: '1',
        name: 'groom',
    },
    Bride: {
        id: '2',
        name: 'bride',
    },
};

const CropperModal = ({ defaultsrc, eventId, load, setLoad, setOpen, name, type }) => {
    const cropperRef = useRef(null);
    const [image, setImage] = useState(defaultsrc);
    const [isOpenModal, setOpenModal] = useState(false);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const toastLoadingId = useRef(null);

    const submitHandle = async () => {
        setIsLoadingBtn(true);
        const file = await PublishBrideService.createFile(
            cropperRef.current.getCanvas()?.toDataURL('image/jpeg', 1),
            'img_' + name
        );
        if (
            validateSizeOfFile(
                file,
                10,
                'Dung lượng ảnh sau khi chỉnh sửa không được vượt quá 10Mb'
            )
        ) {
            setOpenModal(true);
        }
		setIsLoadingBtn(false);
    };
    const changeFile = (event) => {
        const val = event.target.files[0];
        event.target.value = '';
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setImage(image);
        });
        reader.readAsDataURL(val);
    };
    const submitImageForm = async () => {
        toastLoadingId.current = ToastNotify.Loading('Đang lưu ảnh...');
        const file = await PublishBrideService.createFile(
            cropperRef.current.getCanvas()?.toDataURL(),
            'img_' + name
        );
        const index = type.id;
        let keyChild = 'imageQR';
        PublishBrideService.saveFiles(eventId, name, file, index, keyChild)
            .then(() => ToastNotify.Update(toastLoadingId.current, 'Lưu ảnh thành công!'))
            .catch((error) =>
                ToastNotify.Update(
                    toastLoadingId.current,
                    error?.message || 'Đã có lỗi xảy ra khi lưu ảnh!',
                    'error'
                )
            )
            .finally(() => {
                setLoad(!load);
                setOpenModal(false);
                setOpen(false);
                toastLoadingId.current = null;
            });
    };
    return (
        <div className="mx-1.5 appearance-none justify-center rounded-md border-gray-300 bg-white px-2 pt-2 transition  hover:border-gray-400 focus:outline-none">
            <Cropper
                stencilProps={{
                    aspectRatio: 1,
                }}
                ref={cropperRef}
                style={{ height: '500px', width: '500px' }}
                className="cropper h-[500px]"
                src={image}
            />
            <div className="m-2 flex justify-center gap-2">
                <label className={`flex cursor-pointer items-center gap-[6px] rounded-lg bg-blue-100 px-[16px] py-[8px] text-[16px] font-semibold text-blue-700 hover:bg-blue-200 ${isLoadingBtn ? 'cursor-auto bg-slate-100 text-slate-700 hover:bg-blue-100' : ''}`}>
                    <input
                        className="hidden"
                        onChange={(event) => changeFile(event)}
                        type="file"
                        accept="image/*"
                    />
                    <FaPen/> Thay đổi
                </label>
                <button
                    className={`${isLoadingBtn ? 'bg-blue-500' : 'bg-blue-600 hover:bg-blue-700 '} flex items-center gap-[6px] rounded-lg  px-[16px] py-[8px] text-[16px] font-semibold text-white disabled:bg-slate-700 `}
                    onClick={() => submitHandle()}
                    disabled={isLoadingBtn}
                >
                    {isLoadingBtn && <LoadingButtonIcon />}
					{!isLoadingBtn && <FaSave/>}
                    Lưu
                </button>
                <ModalLeavingPage
                    saveChanged={submitImageForm}
                    isOpen={isOpenModal}
                    confirmNavigation={() => {
                        setOpenModal(false);
                    }}
                    funToggle={() => {
                        setOpenModal(false);
                    }}
                />
            </div>
        </div>
    );
};

export default function ImageQRUploader({ type = TYPE.Bride, eventId, isExpired, onDelete, isDelete, setDelete, load, setLoad }) {
    const [openModal, setOpenModal] = useState(false);
    const [imageToCropBank, setImageToCropBank] = useState(null);
    // const [load, setLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const changeBankFile = (event) => {
        const val = event.target.files[0];
        event.target.value = '';
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setImageToCropBank(image);
			setOpenModal(true);
        });
        reader.readAsDataURL(val);
    };

    useEffect(() => {
		setIsLoading(true);
        EventsService.getOneEventById(eventId)
            .then(({ data }) => {
                const bankObj = data.form_data.bank.find((item) => {
                    return item.id === type.id;
                });
                const imageSrc = bankObj?.imageQR;
                if (imageSrc && imageSrc.length > 0) {
                    setImageToCropBank(process.env.REACT_APP_SERVER_URL + imageSrc[0]);
                } else {
                    setImageToCropBank(null);
                }
            })
            .finally(() => setIsLoading(false));
    }, [load]);

	useEffect(() => {
		if(isDelete)
		{
			setImageToCropBank(null);
			setDelete(false);
		}
    }, [isDelete]);

    return (
        <>
            <div className="">
                {isLoading ? (
                    <div className="h-[300px] w-[300px] bg-gray-200 rounded-lg animate-pulse">
                        {' '}
                    </div>
                ) : (
                    <>
                        {!imageToCropBank && (
                            <label
                                // onClick={() => !isExpired && setOpenModal(true)}
                                className={`mx-auto px-4 h-[300px] w-[300px] flex flex-col gap-[20px] appearance-none justify-center items-center rounded-lg border-2 border-dashed border-gray-300 bg-white transition ${!isExpired ? 'hover:border-gray-400 cursor-pointer': '' } focus:outline-none`}
                            >
                                <FaImage className="text-[60px] text-gray-500" />
                                <span className="text-blue-600 text-[16px] font-semibold">
                                    Thêm ảnh{' '}
                                    <span className="text-gray-400 text-[16px] font-medium">
                                        (Giới hạn 10Mb)
                                    </span>
									<input
										className="hidden"
                                        disabled={isExpired}
										onChange={(event) => changeBankFile(event)}
										type="file"
										accept="image/*"
									/>
                                </span>
                            </label>
                        )}
                        {imageToCropBank && (
                            <div className="group relative mx-auto h-[300px] w-[300px] rounded-lg">
                                <img
                                    src={imageToCropBank}
                                    className="mx-auto h-full w-full rounded-lg object-cover"
                                    alt="QR code"
                                />
                                {
                                    !isExpired && 
                                    <div
                                        onClick={() =>
                                            onDelete()
                                        }
                                        className="absolute right-3 top-1 z-[10] w-[33px] cursor-pointer rounded-full border bg-slate-100 p-2 text-black shadow-lg hover:bg-gray-400"
                                    >
                                        <svg
                                            width="15px"
                                            height="15px"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill="none"
                                                stroke="#000"
                                                strokeWidth="2"
                                                d="M3,3 L21,21 M3,21 L21,3"
                                            />
                                        </svg>
                                    </div>
                                }
                                <div
                                    className="absolute -top-0 z-[9] flex h-full w-full cursor-pointer rounded-lg flex-col items-center justify-center bg-black/30 opacity-0 backdrop-blur-sm duration-500 ease-out group-hover:opacity-100"
                                    onClick={() => {
                                        !isExpired && setOpenModal(true);
                                    }}
                                >
                                    <span className="fas fa-edit  text-3xl text-slate-100"></span>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
            <BaseModal
                isOpen={openModal}
                onClose={() => {
                    setOpenModal(false);
                    setLoad(!load);
                }}
                modalTitle="Chỉnh sửa Ảnh mã QR"
                width="max-w-[700px]"
            >
                {!imageToCropBank && (
                    <>
                        <label className="mt-1.5 flex h-[500px] w-full cursor-pointer appearance-none justify-center rounded-md border-2 border-dashed border-gray-300 bg-white px-4 transition hover:border-gray-400 focus:outline-none">
                            <span className="flex items-center space-x-2 py-10">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-gray-600"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                    />
                                </svg>
                                <span className="text-sm font-bold text-gray-700">
                                    Tải ảnh lên (Giới hạn 10Mb)
                                </span>
                            </span>
                            <input
                                className="hidden"
                                onChange={(event) => changeBankFile(event)}
                                type="file"
                                accept="image/*"
                            />
                        </label>
                    </>
                )}
                {imageToCropBank && (
                    <CropperModal
                        setOpen={setOpenModal}
                        defaultsrc={imageToCropBank}
                        eventId={eventId}
                        load={load}
                        setLoad={setLoad}
                        name={'bank'}
                        type={type}
                    />
                )}
            </BaseModal>
        </>
    );
}
