/* eslint-disable */
import routerTemplates from '../../../resource/_index/templates';

const Templates = ({ templateAlias, ...props }) => (
        <>
            {routerTemplates.map(({ k, v }) => {
                if (k === templateAlias) {
                    const Component = v;
                    return <Component {...props} />;
                }
                return null;
            })}
        </>
    );

export default Templates;
