/* eslint-disable */
import { useEffect, useReducer } from 'react';
import userService from '../../../../user/User.service';
import EventGreetingService from '../PublishGreeting.service';

export const INIT_ANALYSIS = {
    total: 0,
    reply: 0,
    not_public: 0,
};
export function analysisReducer(state, action) {
    const { type, payload } = action;
    switch (type) {
        case 'get':
            return { ...state, ...payload };
        default:
            return state;
    }
}
export default function AnalysisEventGreeting({ eventId, refresh }) {
    const [analysisUser, dispatch] = useReducer(analysisReducer, INIT_ANALYSIS);

    useEffect(() => {
        EventGreetingService.analysis(eventId).then(({ data }) => dispatch({ type: 'get', payload: data }));
    }, [refresh]);

    return (
        <div className=' grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2 md:gap-3 lg:gap-4 mb-2 md:mb-4 items-start mt-4 bg-gray-100'>
            <div className="border-black/10 bg-clip-borde mx-auto flex w-full min-w-0  break-words  rounded-lg shadow-lg  border  bg-white p-4 items-center">
                <div className='bg-indigo-100 rounded px-4 py-3 mr-6 relative text-sky-600'>
                    <i className="fas fa-th-list"></i>
                </div>
                <div className='flex flex-col'>
                    <p className="text-body text-gray-900">Tổng số lời chúc</p>
                    <h4 className="text-sky-600">{analysisUser.total}</h4>
                </div>
            </div>
            <div className="border-black/12.5 bg-clip-borde mx-auto flex w-full min-w-0  break-words  rounded-lg shadow-lg  border border-solid bg-white p-4  items-center">
                <div className='bg-indigo-100 rounded px-4 py-3 mr-6 relative text-sky-600'>
                    <i className="fas fa-comment-dots"></i>
                </div>
                <div className='flex flex-col'>
                    <p className="text-body text-gray-900">Đã trả lời</p>
                    <h4 className="text-sky-600">{analysisUser.reply}</h4>
                </div>
            </div>
            <div className="border-black/12.5 bg-clip-borde mx-auto flex w-full min-w-0  break-words  rounded-lg shadow-lg  border border-solid bg-white p-4 items-center">
                <div className='bg-indigo-100 rounded px-4 py-3 mr-6 relative text-sky-600'>
                    <i className="fas fa-lock"></i>
                </div>
                <div className='flex flex-col'>
                    <p className="text-body text-gray-900">Chưa công khai</p>
                    <h4 className="text-sky-600">{analysisUser.not_public}</h4>
                </div>
            </div>
        </div>
    );
}
