/* eslint-disable */

import { useEffect, useRef, useState } from 'react';

// import IMAGES_WEDDING from '../../resource/wedding/images/_index';
import PublishContentService from './PublishContent.service';
// import Templates from '../events/components/Templates';
import { useParams, useNavigate } from 'react-router-dom';
import themeCommon from '../../PublishMainMenu.config';

import { Helmet } from 'react-helmet-async';
import PAGE from '../../../../config/path.route';
import { Cropper } from 'react-mobile-cropper';
import BaseModal from '../../../../components/BaseModal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
    FaEdit,
    FaTrash,
    FaPlus,
    FaCamera,
    FaClock,
    FaArrowRight,
    FaMapMarkerAlt,
    FaMapMarkedAlt,
    FaPen,
    FaMeh,
    FaImage,
    FaSave,
    FaTimes,
    FaGripVertical,
    FaTrashAlt,
} from 'react-icons/fa';
import ModalInfo from './components/ModalInfo';
import ToastNotify from '../../../../utils/Toast';
import { ModalDelete, ModalDeleteADay } from './components/ModalConfirmDelete';
import EventsService from '../../../events/Events.service';
import ContentSkeleton from './components/ContentSkeleton';
import ModalTimeStart from './components/ModalTimeStart';
import { validateSizeOfFile } from '../../../../utils/common';
import LoadingButtonIcon from '../../../../components/LoadingButton/LoadingButtonIcon';
import ShortcutMenu from '../../components/ShortcutMenu';
import Products from '../publish.news/products/Products';
import TimelineShortcutMenu from '../../components/TimelineShortcutMenu';
import IMAGES from '../../../../assets/images/_index';
import QuestionTitle from '../publish.question/QuestionTitle';
import ReactTooltip from 'react-tooltip';
import Header from '../publish.sub/components/Header';
import { useSelector } from 'react-redux';
import ModalLeavingPage from '../../../events/components/ModalLeavingPage';
import { set } from 'lodash';
import { date } from 'yup';
import moment from 'moment';

const parseJsonData = (formData) => {
    // parse json
    const dataCopy = formData;
    let arrParse = [];
    let arrCopy = [];
    let keyName = '';
    // programes
    if (dataCopy?.programes) {
        arrCopy = dataCopy.programes;
        keyName = 'programes';
    } else {
        //  programes
        arrCopy = dataCopy.programes;
        keyName = 'programes';
    }
    if (Array.isArray(arrCopy)) {
        for (var idx = 0; idx < arrCopy.length; idx++) {
            if (typeof arrCopy[idx] === 'string') {
                arrParse.push(JSON.parse(arrCopy[idx]));
            } else {
                arrParse.push(arrCopy[idx]);
            }
        }
    } else {
        if (arrCopy) {
            arrParse.push(arrCopy);
        }
    }
    return { ...dataCopy, [keyName]: arrParse };
};

function sortByIndex(arr) {
    // Sắp xếp mảng theo giá trị của thuộc tính "index"
    arr.sort((a, b) => parseInt(a.index) - parseInt(b.index));
    return arr;
}

export default function PublishContent() {
    const navigate = useNavigate();
    const { eventId, url_guest: urlGuest } = useParams();
    const [timeStart, setTimeStart] = useState();
    const [formData, setFormData] = useState({});
    const [programes, setProgrames] = useState([]);

    const [loading, setLoading] = useState(false);
    const [isExpiredEvent, setIsExpiredEvent] = useState(false);

    const [event, setEvent] = useState();
    const { loggedIn } = useSelector((state) => state.auth);

    // Hàm so sánh giờ và phút

    const submitForm = async (mode, item, timeStart) => {
        if (mode === 'edit') {
            console.log('timeStart - edit', timeStart);
            console.log('item - edit', item);
            await PublishContentService.submitForm(
                eventId,
                handleTimeProgramWhenEdit(programes, item)
            )
                .then(({ data }) => {
                    if (Array.isArray(data?.form_data.programes)) {
                        setProgrames(
                            data?.form_data.programes.filter((item) => item.type === 'program')
                        );
                        setFormData({ ...formData, programes: data.form_data.programes });
                    } else {
                        let copyArr = [];
                        copyArr.push(data?.form_data.programes);
                        setProgrames(copyArr);
                    }

                    ToastNotify.Success('Lưu thành công!');
                })
                .catch((err) => ToastNotify.Error(err?.message || 'Đã có lỗi xảy ra.'));
        } else {
            setLoading(true);
            await PublishContentService.submitForm(
                eventId,
                convertObjectToArray(handleTimeProgramWhenCreate(item, timeStart))
            )
                .then(({ data }) => {
                    if (Array.isArray(data?.form_data.programes)) {
                        setProgrames(
                            data?.form_data.programes.filter((item) => item.type === 'program')
                        );
                        setFormData({ ...formData, programes: data.form_data.programes });
                    } else {
                        let copyArr = [];
                        copyArr.push(data?.form_data.programes);
                        setProgrames(copyArr);
                    }

                    ToastNotify.Success('Lưu thành công!');
                })
                .catch((err) => ToastNotify.Error(err?.message || 'Đã có lỗi xảy ra.'));
            setLoading(false);
        }
    };

    const handleTimeProgramWhenCreate = (obj, timeStart) => {
        console.log('timeStart - create', timeStart);
        if (programes.length === 0) {
            const minutes = parseInt(obj.minutes);
            const startTime = timeStart.split(':').map(Number); // [hours, minutes]

            const totalStartMinutes = startTime[0] * 60 + startTime[1];

            const totalEndMinutes = totalStartMinutes + minutes;

            const endHours = Math.floor(totalEndMinutes / 60) % 24;
            const endMinutes = totalEndMinutes % 60;

            const timeEnd = [endHours, endMinutes]
                .map((part) => (part < 10 ? `0${part}` : `${part}`))
                .join(':');

            const newObject = {
                ...obj,
                timeStart: timeStart,
                timeEnd: timeEnd,
                date: obj.date,
                type: 'program',
            };

            return newObject;
        } else {
            // Tìm ra timeEnd lớn nhất trong mảng programes
            const maxTimeEnd = programes
                .filter((item) => item.groupId === obj.groupId)
                .reduce((max, program) => {
                    const programEndTime = program.timeEnd;
                    return programEndTime > max ? programEndTime : max;
                }, timeStart);

            // Chuyển đổi thời gian kết thúc thành đối tượng Date để tính toán
            const maxTimeEndDate = new Date(`2000-01-01T${maxTimeEnd}`);
            const newProgramMinutes = parseInt(obj.minutes); // Số phút mới

            // Thêm số phút mới vào thời gian kết thúc lớn nhất để tạo ra timeStart và timeEnd mới
            maxTimeEndDate.setMinutes(maxTimeEndDate.getMinutes() + newProgramMinutes);
            const newTimeEnd = maxTimeEndDate.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
            });

            const newTimeStart = maxTimeEnd;
            const newObject = {
                ...obj,
                timeStart: newTimeStart,
                timeEnd: newTimeEnd,
                type: 'program',
            };

            return newObject;
        }
    };
    const handleTimeProgramWhenEdit = (arr, programItem = selectProgram) => {
        console.log('programItem', programItem);
        console.log('arr list', arr);
        let samedayProgramArr = arr
            .filter((item) => item.groupId === programItem?.groupId && item.type === 'program')
            .sort((a, b) => a.index - b.index);
        console.log('samedayProgramArr', samedayProgramArr);

        if (programItem) {
            let indexToReplace = samedayProgramArr.findIndex((item) => item.id === programItem.id);

            // Nếu tìm thấy, thay thế phần tử đó bằng object mới
            console.log('indexToReplace', indexToReplace);
            if (indexToReplace !== -1) {
                samedayProgramArr[indexToReplace] = programItem;
            } else {
            }
        }

        let currentStartTime = new Date(`2020-01-01 ${selectTimeStart}`);

        let resultArray = [];

        for (let i = 0; i < samedayProgramArr.length; i++) {
            const minutesToAdd = parseInt(samedayProgramArr[i]?.minutes);

            const endTime = new Date(currentStartTime.getTime() + minutesToAdd * 60000);

            resultArray.push({
                timeStart: formatTime(currentStartTime),
                minutes: samedayProgramArr[i]?.minutes,
                timeEnd: formatTime(endTime),
                name: samedayProgramArr[i]?.name,
                id: samedayProgramArr[i]?.id,
                index: samedayProgramArr[i]?.index,
            });

            currentStartTime = endTime;
        }

        return mergeArraysV2(resultArray);
    };

    const handleTimeArr = (arr, timeStart, date = null) => {
        console.log('date', date);
        let currentStartTime = new Date(`2020-01-01 ${timeStart}`);

        let resultArray = [];

        for (let i = 0; i < arr.length; i++) {
            const minutesToAdd = parseInt(arr[i]?.minutes);

            const endTime = new Date(currentStartTime.getTime() + minutesToAdd * 60000);

            resultArray.push({
                timeStart: formatTime(currentStartTime),
                minutes: arr[i]?.minutes,
                timeEnd: formatTime(endTime),
                name: arr[i]?.name,
                id: arr[i]?.id,
                index: arr[i]?.index,
                date: date !== null ? date : arr[i]?.date,
            });

            currentStartTime = endTime;
        }

        return mergeArraysV2(resultArray);
    };

    function formatTime(date) {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    function mergeArraysV2(arr) {
        const newArr = arr.map((item) => convertObjectToArray(item));
        return newArr.reduce((mergedArray, currentArray) => {
            return mergedArray.concat(currentArray);
        }, []);
    }

    function convertObjectToArray(obj) {
        // Khởi tạo một mảng rỗng để lưu kết quả
        var result = [];

        // Duyệt qua các thuộc tính của đối tượng
        for (var key in obj) {
            // Tạo một đối tượng mới có các thuộc tính key, keyChild, value và elementId
            if (key !== 'id' && key !== 'image') {
                var newObj = {
                    key: 'programes', // Đặt key là programes
                    keyChild: key, // Đặt keyChild là tên của thuộc tính hiện tại
                    value: obj[key], // Đặt value là giá trị của thuộc tính hiện tại
                    elementId: obj.id.toString(), // Đặt elementId là giá trị của thuộc tính id của đối tượng
                };

                // Thêm đối tượng mới vào mảng kết quả
                result.push(newObj);
            }
        }

        // Trả về mảng kết quả
        return result;
    }

    const findMaxIdx = () => {
        // Khởi tạo một biến để lưu giá trị id lớn nhất
        var maxId = 0;
        console.log('formData - programes', formData.programes);

        // Duyệt qua các phần tử trong mảng
        for (var i = 0; i < formData?.programes.length; i++) {
            // Lấy giá trị id của phần tử hiện tại
            var id = Number(formData?.programes[i].id);

            // So sánh với giá trị id lớn nhất hiện có
            if (id > maxId) {
                // Nếu lớn hơn, cập nhật giá trị id lớn nhất
                maxId = id;
            }
        }

        // Cộng giá trị id lớn nhất với 1
        var result = maxId + 1;

        // Trả về kết quả
        return result;
    };

    const handleDeleteEv = async () => {
        const payload = [{ key: 'programes', value: selectProgram.id, isDeleted: true }];
        let newData;
        console.log('formData', formData);
        await PublishContentService.submitForm(formData._id, payload)
            .then(({ data }) => {
                newData = data?.form_data.programes;
            })
            .catch((err) => ToastNotify.Error(err?.message || 'Đã có lỗi xảy ra.'));
        await PublishContentService.submitForm(eventId, handleTimeProgramWhenEdit(newData))
            .then(({ data }) => {
                setProgrames(data?.form_data.programes.filter((item) => item.type === 'program'));
                setFormData({ ...formData, programes: data.form_data.programes });
                ToastNotify.Success('Xóa thành công!');
            })
            .catch((err) => {
                console.log(err);
            });
    };

    function updateIndex(arr) {
        arr.forEach((item, index) => {
            item.index = index + 1;
        });

        // Trả về mảng đã được xử lý
        return arr;
    }

    // Xu li time

    useEffect(() => {
        // setLoading(true);

        setTimeStart(findTimeStart(programes));
        // setLoading(false);
    }, [programes]);

    function compareTimes(time1, time2) {
        if (time1 !== undefined && time2 !== undefined) {
            let [hours1, minutes1] = time1.split(':').map(Number);
            let [hours2, minutes2] = time2.split(':').map(Number);

            [hours1, minutes1] = time1.split(':').map(Number);
            [hours2, minutes2] = time2.split(':').map(Number);
            if (hours1 !== hours2) {
                return hours1 - hours2;
            } else {
                return minutes1 - minutes2;
            }
        }
    }

    function findTimeStart(array) {
        if (!Array.isArray(array) || array.length === 0) {
            return null; // Trả về null nếu mảng không hợp lệ hoặc rỗng
        }
        let minTimeStart = array[0].timeStart;
        for (let i = 1; i < array.length; i++) {
            const currentTimeStart = array[i].timeStart;
            // So sánh giờ và phút để xác định giá trị nhỏ nhất
            if (compareTimes(currentTimeStart, minTimeStart) < 0) {
                minTimeStart = currentTimeStart;
            }
        }
        return minTimeStart;
    }

    useEffect(() => {
        if (!loggedIn) {
            const beforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = true;
            };
            window.addEventListener('beforeunload', beforeUnload);
            return () => {
                window.removeEventListener('beforeunload', beforeUnload);
            };
        }
    }, []);

    //chuc nang moi

    const [startList, setStartList] = useState([]);

    const [selectProgram, setSelectProgram] = useState();
    const [selectTimeStart, setSelectTimeStart] = useState();

    const handleProgram = (mode, item, timeStart) => {
        submitForm(mode, item, timeStart);
    };
    const handleClear = () => {
        setSelectProgram();
    };

    useEffect(() => {
        setLoading(true);
        if (eventId) {
            EventsService.getOneEventById(eventId)
                .then(({ data }) => {
                    setEvent(data);

                    const templateAlias = data.template.alias;
                    const formData = data.form_data;

                    setIsExpiredEvent(data?.isExpired);

                    setFormData(parseJsonData({ ...data, ...formData }));

                    if (Array.isArray(formData.programes)) {
                        setProgrames(formData.programes.filter((item) => item.type === 'program'));
                        setStartList(formData.programes.filter((item) => item.type === 'date'));
                    } else {
                        let copyArr = [];
                        copyArr.push(formData.programes);
                        setProgrames(copyArr);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    const AuthorizeError = 404;
                    if (error.status === AuthorizeError) {
                        navigate(PAGE.dashboard);
                    }
                    const ServerError = 500;
                    if (error.status === ServerError) {
                        navigate(PAGE.dashboard);
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                });
        }
    }, []);
    const handleAddStartItem = async (item) => {
        setLoading(true);
        if (item.id) {
            await PublishContentService.submitForm(eventId, programWhenEditDay(item))
                .then(({ data }) => {
                    let copyArr = startList;
                    let indexToReplace = startList.findIndex((itemList) => itemList.id === item.id);
                    if (indexToReplace !== -1) {
                        copyArr[indexToReplace] = item;
                    }
                    setStartList(copyArr);
                    setFormData({ ...formData, programes: data.form_data.programes });
                    setProgrames(
                        data?.form_data.programes.filter((item) => item.type === 'program')
                    );
                    ToastNotify.Success('Đã sửa ngày sự kiện');
                })
                .catch((err) => {
                    ToastNotify.Error(err?.message || 'Đã có lỗi xảy ra.');
                });
        } else {
            const itemCopy = { ...item, id: findMaxIdx().toString(), type: 'date' };

            await PublishContentService.submitForm(eventId, convertObjectToArray(itemCopy))
                .then(({ data }) => {
                    setStartList([...startList, itemCopy]);
                    setFormData({ ...formData, programes: data.form_data.programes });
                    // console.log(data.data.form_data.programes)
                    ToastNotify.Success('Đã thêm ngày sự kiện');
                    return true;
                })
                .catch((err) => {
                    ToastNotify.Error(err?.message || 'Đã có lỗi xảy ra.');
                });
        }
        setLoading(false);
    };

    const programWhenEditDay = (day) => {
        const oldDate = startList.find((item) => item.id === day.id);
        console.log('oldDate', oldDate);
        let payload = convertObjectToArray(day);
        console.log('payload', payload);
        const programInDayArr = programes.filter((item) => item.groupId === oldDate.id);
        console.log(
            'program arr after edit day',
            handleTimeArr(programInDayArr, day.time, day.date)
        );
        const combinedArray = [...payload, ...handleTimeArr(programInDayArr, day.time, day.date)];
        return combinedArray;
    };

    const checkExistDate = (item) => {
        return startList.some((start) => start.date === item.date && item.id !== start.id);
    };

    const [selectDay, setSelectDay] = useState();

    const handleDeleteADay = async () => {
        let deleteArr = [];
        deleteArr.push({ key: 'programes', value: selectDay.id.toString(), isDeleted: true });
        for (let i = 0; i < programes.length; i++) {
            if (programes[i].groupId === selectDay.id) {
                deleteArr.push({ key: 'programes', value: programes[i].id, isDeleted: true });
            }
        }
        console.log('deleteArr', deleteArr);
        let newData;
        await PublishContentService.submitForm(formData._id, deleteArr)
            .then(({ data }) => {
                newData = data?.form_data.programes;
            })
            .catch((err) => ToastNotify.Error(err?.message || 'Đã có lỗi xảy ra.'));
        await PublishContentService.submitForm(eventId, handleTimeProgramWhenEdit(newData))
            .then(({ data }) => {
                setStartList(data?.form_data.programes.filter((item) => item.type === 'date'));
                setProgrames(data?.form_data.programes.filter((item) => item.type === 'program'));
                setFormData({ ...formData, programes: data.form_data.programes });
                ToastNotify.Success('Xóa thành công!');
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="flex flex-col items-center gap-[1.5rem] pb-[2rem]">
            <Header event={event} isExpired={isExpiredEvent} />

            <div className=" w-full flex justify-evenly mt-20">
                <div className="lg:flex hidden relative w-[250px]">
                    <TimelineShortcutMenu screen={'content'} />
                </div>
                {loading ? (
                    <ContentSkeleton />
                ) : (
                    <div className="w-full rounded-lg border-[1px] border-slate-200 bg-white-box shadow-lg lg:w-[48rem] 2xl:w-[60rem]">
                        <div className="flex w-full flex-col justify-between border-b-[1px] border-slate-200 px-6 py-5 md:flex-row md:items-center">
                            <p className="mb-2 text-[16px] font-bold text-black md:mb-0">
                                Nội dung chương trình
                            </p>
                            {!isExpiredEvent && (
                                <div className="flex items-center gap-[8px]">
                                    <button
                                        data-bs-toggle="modal"
                                        data-bs-target="#modalTimeStart"
                                        onClick={() => {
                                            setSelectDay();
                                        }}
                                        className="relative flex gap-[6px] rounded-lg bg-violet-500 px-[12px] py-[8px] text-sm font-semibold text-white hover:bg-violet-700"
                                    >
                                        <div className="absolute -right-2 -top-2 inline-flex h-5 w-5 items-center justify-center rounded-full border-2 border-white bg-red-500 text-[10px] font-bold text-white">
                                            <FaPlus />
                                        </div>
                                        Thêm ngày sự kiện
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="flex w-full flex-col items-center gap-5 px-6 py-5">
                            <QuestionTitle keyQues={'contents'} />

                            {startList?.length > 0 ? (
                                startList
                                    ?.sort((a, b) => {
                                        const dateA = new Date(a.date);
                                        const dateB = new Date(b.date);
                                        const timeA = moment(a.time, 'HH:mm');
                                        const timeB = moment(b.time, 'HH:mm');

                                        if (dateA.getTime() !== dateB.getTime()) {
                                            return dateA - dateB;
                                        } else {
                                            return timeA.diff(timeB);
                                        }
                                    })
                                    .map((item, index) => (
                                        <ListContentInADay
                                            key={index}
                                            timeStart={item}
                                            programes={programes}
                                            findMaxIdx={findMaxIdx}
                                            setSelectProgram={setSelectProgram}
                                            setSelectTimeStart={setSelectTimeStart}
                                            isExpiredEvent={isExpiredEvent}
                                            updateIndex={updateIndex}
                                            handleTimeArr={handleTimeArr}
                                            eventId={eventId}
                                            setSelectDay={setSelectDay}
                                        />
                                    ))
                            ) : (
                                <div className="flex w-full flex-col items-center justify-center gap-[1rem]">
                                    <div className="flex w-full flex-col items-center justify-center">
                                        <img
                                            src={IMAGES.TIMETABLE}
                                            alt="not found"
                                            className="mx-auto w-[400px] opacity-70"
                                        />
                                        <h4 className="mt-5 text-center text-xl text-blue-900 ">
                                            Hãy nhập thông tin về chương trình sự kiện nhé!
                                        </h4>
                                        <p className="mt-2 text-center text-sm text-black">
                                            Vui lòng thêm các sự kiện của bữa tiệc vào lịch trình,
                                            như thế sẽ giúp khách mời của bạn dễ dàng tham gia hơn.
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className="lg:flex hidden relative w-[250px]">
                    <Products />
                </div>
            </div>

            {/* programes[rubric.source.index]. */}

            <ModalDelete handleDeleteEv={handleDeleteEv} />
            <ModalDeleteADay handleDeleteADay={handleDeleteADay} />

            <ModalTimeStart
                handleAddStartItem={handleAddStartItem}
                selectDay={selectDay}
                checkExistDate={checkExistDate}
            />
            <ModalInfo
                data={selectProgram}
                submitForm={handleProgram}
                clearForm={handleClear}
                timeStart={selectTimeStart}
                setSelectProgram={setSelectProgram}
            />
        </div>
    );
}

const ListContentInADay = ({
    index,
    timeStart,
    programes,
    findMaxIdx,
    setSelectProgram,
    setSelectTimeStart,
    isExpiredEvent,
    updateIndex,
    handleTimeArr,
    eventId,
    setSelectDay,
}) => {
    const [selectPrograms, setSelectPrograms] = useState();

    useEffect(() => {
        console.log('timeStart', timeStart);
        console.log('programes', programes);
        console.log(
            'arr filter',
            programes.filter(
                (program) => program.groupId === timeStart.id && program.type === 'program'
            )
        );
        setSelectPrograms(
            programes.filter(
                (program) => program.groupId === timeStart.id && program.type === 'program'
            )
        );
        console.log(
            `chuong trinh trong ngay ${timeStart.date}`,
            programes.filter(
                (program) => program.groupId === timeStart.id && program.type === 'program'
            )
        );
    }, [programes]);

    const handleOpenModalAdd = () => {
        const newID = findMaxIdx();
        setSelectProgram({
            date: timeStart.date,
            id: newID,
            new: true,
            index: newID,
            groupId: timeStart.id.toString(),
        });
        setSelectTimeStart(timeStart.time);
    };

    const handleFormatDate = (date) => {
        const [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
    };

    const handleOnDragEnd = async (result) => {
        if (!result.destination) return;

        if (!timeStart?.time) {
            ToastNotify.Error('Vui lòng chọn thời gian bắt đầu cho ngày sự kiện');
            return;
        }

        const items = Array.from(selectPrograms);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        const newArr = updateIndex(items);

        console.log('new Arr after update index', newArr);

        if (result.destination.index !== result.source.index) {
            console.log('time sau khi keo tha', handleTimeArr(newArr, timeStart.time));
            await PublishContentService.submitForm(eventId, handleTimeArr(newArr, timeStart.time));

            setSelectPrograms(
                revvertToList(handleTimeArr(newArr, timeStart.time))
                    .map((x) => ({ ...programes.find((o) => o.id == x.id), ...x }))
                    .sort((a, b) => a.index - b.index)
            );
        }
    };

    const revvertToList = (arr = []) => {
        const map1 = arr.map((x) => ({ [x['keyChild']]: x.value, id: x.elementId }));
        const map2 = map1.reduce(function (groups, item) {
            const check = groups.findIndex((x) => x.id === item.id);
            if (check > -1) {
                Object.assign(groups[check], item);
            } else {
                groups.push(item);
            }
            return groups;
        }, []);
        return map2;
    };
    const truncateString = (str, num) => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + '...';
    };

    return (
        <>
            <div className="w-full flex flex-col gap-2" key={index}>
                <div className="px-2 py-2 flex justify-between items-center bg-blue-500 rounded-lg">
                    {timeStart?.time && timeStart?.date ? (
                        <div className="flex flex-col gap-1">
                            <p className="text-white text-sm">
                                {timeStart?.name ? (
                                    <span className="font-bold">{timeStart?.name}</span>
                                ) : (
                                    ''
                                )}{' '}
                                ({timeStart?.time} - {handleFormatDate(timeStart?.date)})
                            </p>
                            {(timeStart?.place || timeStart?.address) && (
                                <p className="text-white flex text-sm items-center max-w-[500px] gap-2">
                                    <FaMapMarkerAlt /> {timeStart?.place}{' '}
                                    {timeStart?.address &&
                                        `(${truncateString(timeStart?.address || '', 40)})`}
                                </p>
                            )}
                        </div>
                    ) : (
                        <p></p>
                    )}
                    <div className="flex items-center gap-2">
                        {(timeStart?.linkGgMap || timeStart?.address) && (
                            <>
                                <a
                                    data-tip
                                    data-for={`linkgg-${timeStart.id}`}
                                    className="text-white"
                                    href={
                                        timeStart?.linkGgMap ||
                                        'https://www.google.com/maps/search/?api=1&query=' +
                                            timeStart?.address
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    // title='Mở đường dẫn bản đồ trên tab mới'
                                >
                                    <FaMapMarkedAlt />
                                </a>
                                <ReactTooltip
                                    id={`linkgg-${timeStart.id}`}
                                    place="top"
                                    type="dark"
                                    delayShow={100}
                                    className="w-max"
                                >
                                    Xem địa chỉ
                                </ReactTooltip>
                            </>
                        )}
                        {!isExpiredEvent && (
                            <>
                                <button
                                    className="text-white"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalContent"
                                    onClick={handleOpenModalAdd}
                                    data-tip
                                    data-for={`add-${timeStart.id}`}
                                >
                                    <FaPlus />
                                </button>
                                <ReactTooltip
                                    id={`add-${timeStart.id}`}
                                    place="top"
                                    type="dark"
                                    delayShow={100}
                                    className="w-max"
                                >
                                    Thêm chương trình
                                </ReactTooltip>
                                <button
                                    className="text-white"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalTimeStart"
                                    onClick={() => {
                                        setSelectDay(timeStart);
                                    }}
                                    data-tip
                                    data-for={`edit-${timeStart.id}`}
                                >
                                    <FaEdit />
                                </button>
                                <ReactTooltip
                                    id={`edit-${timeStart.id}`}
                                    place="top"
                                    type="dark"
                                    delayShow={100}
                                    className="w-max"
                                >
                                    Sửa thông tin ngày sự kiện
                                </ReactTooltip>
                                <button
                                    className="text-white"
                                    data-bs-toggle="modal"
                                    data-bs-target="#confirmDeleteADay"
                                    onClick={() => {
                                        setSelectDay(timeStart);
                                    }}
                                    data-tip
                                    data-for={`delete-${timeStart.id}`}
                                >
                                    <FaTrashAlt />
                                </button>
                                <ReactTooltip
                                    id={`delete-${timeStart.id}`}
                                    place="top"
                                    type="dark"
                                    delayShow={100}
                                    className="w-max"
                                >
                                    Xóa ngày sự kiện
                                </ReactTooltip>
                            </>
                        )}
                    </div>
                </div>
                {selectPrograms?.length !== 0 ? (
                    <DragDropContext onDragEnd={handleOnDragEnd} className="w-full">
                        <Droppable
                            droppableId="selectPrograms"
                            mode="virtual"
                            isDropDisabled={isExpiredEvent}
                            renderClone={(provided, snapshot, rubric) => (
                                <div
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    style={provided.draggableProps.style}
                                    // key={index}
                                    className={`relative flex w-full gap-[10px] rounded-md bg-white p-6 shadow-lg ${
                                        snapshot.isDragging ? 'dragging' : ''
                                    }`}
                                >
                                    <div className="relative flex w-full flex-col gap-[28px]">
                                        {!isExpiredEvent && (
                                            <div className="absolute right-[0px] top-[0px] flex gap-[4px] ">
                                                <button
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#modalContent"
                                                    onClick={() => {
                                                        setSelectProgram(
                                                            selectPrograms[rubric.source.index]
                                                        );
                                                        setSelectTimeStart(timeStart.time);
                                                    }}
                                                    className="flex items-center gap-[4px] rounded-md px-[8px] py-[4px] text-sm font-semibold text-blue-600 transition-all hover:bg-blue-100"
                                                >
                                                    <FaEdit /> Sửa{' '}
                                                </button>
                                                <button
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#confirmDeleteEvent"
                                                    onClick={() => {
                                                        setSelectProgram(
                                                            selectPrograms[rubric.source.index]
                                                        );
                                                        setSelectTimeStart(timeStart.time);
                                                    }}
                                                    className="flex items-center gap-[4px] rounded-md px-[8px] py-[4px] text-sm font-semibold text-red-500 transition-all hover:bg-red-100"
                                                >
                                                    <FaTrash /> Xóa{' '}
                                                </button>
                                            </div>
                                        )}
                                        <div className="flex w-full items-center justify-start gap-[16px]">
                                            <div className="flex gap-[8px] items-center">
                                                <FaGripVertical />
                                                <div className="px-2 py-1 rounded ">
                                                    <p className="text-[14px] font-semibold flex gap-2 items-center text-black">
                                                        <FaClock />{' '}
                                                        {selectPrograms[rubric.source.index]
                                                            ?.timeStart ||
                                                        selectPrograms[rubric.source.index]?.timeEnd
                                                            ? `${
                                                                  selectPrograms[
                                                                      rubric.source.index
                                                                  ]?.timeStart || '--'
                                                              } - ${
                                                                  selectPrograms[
                                                                      rubric.source.index
                                                                  ]?.timeEnd || '--'
                                                              }`
                                                            : 'Chưa có thời gian'}
                                                    </p>
                                                </div>
                                                <div className="py-1">
                                                    <p className="text-[14px] flex gap-2 items-center text-blue-500 font-semibold">
                                                        {selectPrograms[rubric.source.index]?.name}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        >
                            {(provided) => (
                                <ul
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="w-full"
                                >
                                    {selectPrograms
                                        ?.sort((start, end) => {
                                            if (start?.index && end?.index) {
                                                return Number(start.index) - Number(end.index);
                                            } else {
                                                return Number(start.id) - Number(end.id);
                                            }
                                        })
                                        ?.map((partyItem, index) => (
                                            <Draggable
                                                key={index + 10}
                                                draggableId={(index + 10).toString()}
                                                index={index}
                                                isDragDisabled={isExpiredEvent}
                                            >
                                                {(provided) => (
                                                    <li
                                                        className="my-[10px] flex w-full items-center justify-center"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div
                                                            key={index}
                                                            className="relative flex w-full flex-col items-center gap-[10px] rounded-md bg-white p-6 shadow-lg md:flex-row md:items-start "
                                                        >
                                                            <div className="relative flex w-full flex-col gap-[28px] ">
                                                                {!isExpiredEvent && (
                                                                    <div className="absolute right-[0px] top-[0px] flex gap-[4px] ">
                                                                        <button
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#modalContent"
                                                                            disabled={
                                                                                isExpiredEvent
                                                                            }
                                                                            onClick={() => {
                                                                                setSelectProgram(
                                                                                    partyItem
                                                                                );
                                                                                setSelectTimeStart(
                                                                                    timeStart.time
                                                                                );
                                                                            }}
                                                                            className="flex items-center gap-[4px] rounded-md px-[8px] py-[4px] text-sm font-semibold text-blue-600 transition-all hover:bg-blue-100"
                                                                        >
                                                                            <FaEdit /> Sửa{' '}
                                                                        </button>
                                                                        <button
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#confirmDeleteEvent"
                                                                            disabled={
                                                                                isExpiredEvent
                                                                            }
                                                                            onClick={() => {
                                                                                setSelectProgram(
                                                                                    partyItem
                                                                                );
                                                                                setSelectTimeStart(
                                                                                    timeStart.time
                                                                                );
                                                                            }}
                                                                            className="flex items-center gap-[4px] rounded-md px-[8px] py-[4px] text-sm font-semibold text-red-500 transition-all hover:bg-red-100"
                                                                        >
                                                                            <FaTrash /> Xóa{' '}
                                                                        </button>
                                                                    </div>
                                                                )}
                                                                <div className="flex w-full items-center justify-start gap-[16px]">
                                                                    <div className="flex gap-[8px] items-center">
                                                                        <FaGripVertical />
                                                                        <div className="px-2 py-1 rounded font-semibold">
                                                                            <p className="text-[14px] flex gap-2 items-center text-black">
                                                                                <FaClock />{' '}
                                                                                {partyItem?.timeStart ||
                                                                                partyItem?.timeEnd
                                                                                    ? `${
                                                                                          partyItem?.timeStart ||
                                                                                          '--'
                                                                                      } - ${
                                                                                          partyItem?.timeEnd ||
                                                                                          '--'
                                                                                      }`
                                                                                    : 'Chưa có thời gian'}
                                                                            </p>
                                                                        </div>
                                                                        <div className="py-1">
                                                                            <p className="text-[14px] flex gap-2 items-center text-blue-500 font-semibold">
                                                                                {partyItem.name}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                ) : (
                    <div className="flex w-full flex-col items-center justify-center gap-[1rem]">
                        <div className="flex w-full flex-col items-center justify-center">
                            <img
                                src={IMAGES.TIMETABLE}
                                alt="not found"
                                className="mx-auto w-[120px] opacity-70"
                            />
                            <h4 className="mt-5 text-center text-lg text-blue-900 ">
                                Ngày này chưa có chương trình nào
                            </h4>
                            <p className="mt-2 text-center text-sm text-black">
                                Vui lòng thêm các sự kiện của bữa tiệc vào lịch trình, như thế sẽ
                                giúp khách mời của bạn dễ dàng tham gia hơn.
                            </p>
                        </div>
                    </div>
                )}
                <div className="w-full h-[2px] bg-blue-500 mt-5" />
            </div>
        </>
    );
};
