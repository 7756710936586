export default function ContentSkeleton() {
    return (
        <div className="lg:w-[48rem] 2xl:w-[60rem] rounded-lg border-[1px] border-slate-200 bg-white-box shadow-lg">
            <div className="flex w-full items-center justify-between border-b-[1px] border-slate-200 px-6 py-5">
                <p className="text-[16px] font-bold text-black">Danh sách chương trình</p>
            </div>
            <div className="flex w-full flex-col items-center gap-5 px-6 py-5">
                <div className="relative flex w-full animate-pulse gap-[28px] rounded-md bg-white p-6 shadow-lg">
                    <div className="group relative h-[12em] w-[12rem]">
                        <div className="bg-gray-300 flex h-full w-full flex-col items-center justify-center gap-[10px] rounded-md text-[12px] text-white"></div>
                    </div>
                    <div className="relative flex w-[40rem] flex-col gap-[28px]">
                        <div className="flex w-full items-center justify-start gap-[16px]">
                            <div className="flex flex-col gap-[8px]"></div>
                        </div>
                        <div
                            className={
                                'flex h-[50px] w-full items-center justify-start gap-[16px] rounded-md bg-slate-100 p-4 text-sm '
                            }
                        ></div>

                        <div className="flex w-full items-center justify-between rounded-md bg-slate-100 p-4">
                            <div className="flex h-full max-w-[20rem] flex-col justify-between gap-[8px]"></div>
                        </div>
                    </div>
                </div>
                <div className="relative flex w-full animate-pulse gap-[28px] rounded-md bg-white p-6 shadow-lg">
                    <div className="group relative h-[12em] w-[12rem]">
                        <div className="bg-gray-300 flex h-full w-full flex-col items-center justify-center gap-[10px] rounded-md text-[12px] text-white"></div>
                    </div>
                    <div className="relative flex w-[40rem] flex-col gap-[28px]">
                        <div className="flex w-full items-center justify-start gap-[16px]">
                            <div className="flex flex-col gap-[8px]"></div>
                        </div>
                        <div
                            className={
                                'flex h-[50px] w-full items-center justify-start gap-[16px] rounded-md bg-slate-100 p-4 text-sm '
                            }
                        ></div>

                        <div className="flex w-full items-center justify-between rounded-md bg-slate-100 p-4">
                            <div className="flex h-full max-w-[20rem] flex-col justify-between gap-[8px]"></div>
                        </div>
                    </div>
                </div>
                <div className="relative flex w-full animate-pulse gap-[28px] rounded-md bg-white p-6 shadow-lg">
                    <div className="group relative h-[12em] w-[12rem]">
                        <div className="bg-gray-300 flex h-full w-full flex-col items-center justify-center gap-[10px] rounded-md text-[12px] text-white"></div>
                    </div>
                    <div className="relative flex w-[40rem] flex-col gap-[28px]">
                        <div className="flex w-full items-center justify-start gap-[16px]">
                            <div className="flex flex-col gap-[8px]"></div>
                        </div>
                        <div
                            className={
                                'flex h-[50px] w-full items-center justify-start gap-[16px] rounded-md bg-slate-100 p-4 text-sm '
                            }
                        ></div>

                        <div className="flex w-full items-center justify-between rounded-md bg-slate-100 p-4">
                            <div className="flex h-full max-w-[20rem] flex-col justify-between gap-[8px]"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
