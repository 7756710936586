import bg from './bg.jpg';
import bg2 from './bg-2.jpg';
import bg3 from './bg-3.jpg';
import bg4 from './bg-4.jpg';
import bg5 from './bg-5.jpg';
import bg6 from './bg-6.jpg';
const IMAGES_EVENT9 = {
    bg,
    bg2,
    bg3,
    bg4,
    bg5,
    bg6,
};

export default IMAGES_EVENT9;
