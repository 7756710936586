import LOGO_AKB_15 from './logo-15.png';
import BG_CONTACT from './bg-contact.jpg';
import IMGAGE_MEMBER from './vechungtoi.jpg';
import BG_TRONG_DONG_4 from './bg-trong-dong-4.png';
import BG_VIEW_MOUNTAIN from './bg-view-nui.jpg';
import PHAO_HOA_XANH from './phao-hoa-xanh.gif';
import PHAO_HOA_VANG from './phao-hoa-vang.gif';
import LOGO_AKB_FLAG from './logo-akb.png';
import GIA_PHA_DV_2 from './giaphadaiviet2.jpg';
import LOGO_AKB_2 from './logo_AKB_2.png';
import LOGO_ANFA from './logo_Anfa.png';
import LOGO_AIC_2 from './logo-AIC-2.png';
import LOGO_KBS from './logo-kbs.png';
import LOGO_KUBO from './logo-kubo.png';

const IMAGES_EVENT4 = {
    LOGO_AKB_15,
    BG_CONTACT,
    LOGO_AKB_FLAG,
    IMGAGE_MEMBER,
    BG_VIEW_MOUNTAIN,
    PHAO_HOA_XANH,
    PHAO_HOA_VANG,
    BG_TRONG_DONG_4,
    LOGO_AKB_2,
    LOGO_ANFA,
    LOGO_AIC_2,
    LOGO_KBS,
    LOGO_KUBO,
    GIA_PHA_DV_2,
};

export default IMAGES_EVENT4;
