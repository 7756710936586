
import PLACE_PARTY_1 from './place_party_1.jpg';
import PLACE_PARTY_2 from './place_party_2.jpg';
import THANK_YOU from './thankyou.png';
import PARALLAX from './bg_parallax_final.jpg';
import BG_SUB_TEMP2_1 from './bg-sub-template2.png';
import BG_SUB_TEMP2_2 from './bg-sub-template2-2.png';
import ICON_KISS_TOP from './iconkiss-temp2-top.png';
import ICON_KISS_BOTTOM from './iconkiss-temp2-bottom.png';

const IMAGES_WEDDING_2 = {
    PLACE_PARTY_1,
    PLACE_PARTY_2,
    THANK_YOU,
    PARALLAX,
    BG_SUB_TEMP2_1,
    BG_SUB_TEMP2_2,
    ICON_KISS_TOP,
    ICON_KISS_BOTTOM
};

export default IMAGES_WEDDING_2;
