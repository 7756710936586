/* eslint-disable */

import { useEffect, useState } from 'react';
import { formatDTSlash, formatDatetime } from '../../../utils/Times';
import { FaCrown } from 'react-icons/fa';

export const TYPE_PACKAGE = {
    BASE: 'base',
    EXTRA: 'extra',
};

const calTotalDayInTwoDate = (dateEnd) => {
    const infoTotalDay = {
        totalDayRemain: 0,
        isNotifyDayRemain: false,
    };
    if (!dateEnd) {
        return infoTotalDay;
    }
    const dateNow = new Date();
    const endOfDateEnd = new Date(dateEnd.toString()).setUTCHours(23, 59, 59, 999);
    infoTotalDay.totalDayRemain = Math.round(
        (endOfDateEnd - dateNow.getTime()) / (1000 * 3600 * 24)
    );
    infoTotalDay.isNotifyDayRemain = infoTotalDay.totalDayRemain <= 10;
    return infoTotalDay;
};

export default function PackagesUsing({ userData, trigger }) {
    const [listPackageUsing, setListPackageUsing] = useState();

    useEffect(() => {
        if (userData?.user_package) {
            const userPackage = userData.user_package;
            let listPackages = [];
            if (userPackage?.base) {
                listPackages.push({
                    ...userPackage.base,
                    ...calTotalDayInTwoDate(userPackage.base.expiredAt),
                    type: TYPE_PACKAGE.BASE,
                });
            }
            userPackage?.extra.map((item) => {
                listPackages.push({
                    ...item,
                    ...calTotalDayInTwoDate(item.expiredAt),
                    type: TYPE_PACKAGE.EXTRA,
                });
            });
            setListPackageUsing(listPackages);
        }
    }, [userData, trigger]);

    return (
        <div className="w-full mt-2 ">
            <p className="text-black font-bold pt-3">Gói dịch vụ đang sử dụng</p>
            <div className="mt-1 overflow-x-auto  pb-2">
                <table className="w-full min-w-[640px] table-auto border border-gray-300  shadow-lg">
                    <thead className="bg-blue-500">
                        <tr>
                            <th className="border-b border-gray-300 py-4 px-5 text-center">
                                <p className="block antialiased font-sans text-xs font-bold uppercase text-white">
                                    STT
                                </p>
                            </th>
                            <th className="border-b border-gray-300 py-3 px-5 text-center">
                                <p className="block antialiased font-sans text-xs font-bold uppercase text-white">
                                    Mã đơn hàng
                                </p>
                            </th>
                            <th className="border-b border-gray-300 py-3 px-5 text-center">
                                <p className="block antialiased font-sans text-xs font-bold uppercase text-white">
                                    Gói dịch vụ
                                </p>
                            </th>
                            <th className="border-b border-gray-300 py-3 px-5 text-center">
                                <p className="block antialiased font-sans text-xs font-bold uppercase text-white">
                                    Thông tin Tài nguyên
                                </p>
                            </th>
                            <th className="border-b border-gray-300 py-3 px-5 text-center">
                                <p className="block antialiased font-sans text-xs font-bold uppercase text-white">
                                    Số lượng
                                </p>
                            </th>
                            {/* <th className="border-b border-gray-300 py-3 px-5 text-center">
                                <p className="block antialiased font-sans text-xs font-bold uppercase text-white">Ngày còn lại</p>
                            </th> */}
                            <th className="border-b border-gray-300 py-3 px-5 text-center">
                                <p className="block antialiased font-sans text-xs font-bold uppercase text-white">
                                    Ngày mua
                                </p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {listPackageUsing?.length > 0 ? (
                            listPackageUsing.map((item, index) => {
                                return (
                                    <tr className={`${index % 2 !== 0 && 'bg-gray-100'} `}>
                                        <td className="py-2 px-2 border-b border-gray-300">
                                            <div className="flex justify-center items-center gap-4">
                                                <p className="block antialiased font-sans text-sm leading-normal text-gray-600">
                                                    {index + 1}
                                                </p>
                                            </div>
                                        </td>
                                        <td className="py-2 px-2 border-b border-gray-300">
                                            <div className="flex justify-center items-center gap-4">
                                                <p className="block antialiased font-sans text-sm leading-normal font-medium text-gray-600">
                                                    {item.order_alias}
                                                </p>
                                            </div>
                                        </td>
                                        <td className="py-2 px-2 border-b border-gray-300">
                                            <div className="flex justify-start items-center gap-4">
                                                <p className="flex antialiased text-start font-sans text-sm leading-normal font-medium text-gray-600">
                                                    {item.type === TYPE_PACKAGE.BASE && (
                                                        <span className="text-sm text-yellow-500 rounded-full border border-amber-400 bg-white p-0.5 mr-0.5">
                                                            <FaCrown />
                                                        </span>
                                                    )}
                                                    {item.package_name}
                                                </p>
                                            </div>
                                        </td>
                                        <td className="py-2 px-2 border-b border-gray-300">
                                            <div className={`relative flex flex-col`}>
                                                <p className="">
                                                    <i className="text-xs mr-1.5 ml-[3px] text-blue-500 fas fa-database"></i>
                                                    <span className="text-xs">Dung lượng: </span>
                                                    <span className="text-sm text-black font-semibold">
                                                        {' '}
                                                        {item.file_storage}{' '}
                                                    </span>
                                                    <span className="text-xs text-gray-600 font-semibold">
                                                        {' '}
                                                        MB
                                                    </span>
                                                </p>
                                                <p className="">
                                                    <i className="text-xs mr-1 text-blue-500 fas fa-users"></i>
                                                    <span className="text-xs">Khách mời: </span>
                                                    <span className="text-sm text-black font-semibold">
                                                        {' '}
                                                        {item.event_guest}
                                                    </span>
                                                    <span className="text-xs text-gray-600 font-semibold">
                                                        {' '}
                                                        khách
                                                    </span>
                                                </p>
                                                <p className="">
                                                    <i className="text-xs mr-1 ml-0.5 text-blue-500 fas fa-history"></i>
                                                    <span className="text-xs">Thời hạn: </span>
                                                    <span className="text-sm text-black font-semibold">
                                                        {' '}
                                                        {formatDTSlash(item.startedAt)}
                                                    </span>
                                                    <span className="text-sm text-black font-normal">
                                                        {' '}
                                                        -{' '}
                                                    </span>
                                                    <span className="text-sm text-black font-semibold">
                                                        {' '}
                                                        {formatDTSlash(item.expiredAt)}
                                                    </span>
                                                </p>
                                                <p className="mt-0">
                                                    <span className="text-xs">
                                                        Số ngày sử dụng còn lại:{' '}
                                                    </span>
                                                    <span
                                                        className={`text-sm ${
                                                            item.isNotifyDayRemain
                                                                ? 'text-red-700'
                                                                : 'text-black'
                                                        }  font-semibold`}
                                                    >
                                                        {item.totalDayRemain}
                                                    </span>
                                                    <span className="text-xs text-gray-600 font-semibold">
                                                        {' '}
                                                        ngày
                                                    </span>
                                                </p>
                                            </div>
                                        </td>
                                        <td className="py-2 px-2 border-b border-gray-300">
                                            <div className="flex justify-center items-center gap-4">
                                                <p className="block antialiased font-sans text-sm leading-normal text-gray-600">
                                                    {item.quantity}
                                                </p>
                                            </div>
                                        </td>
                                        <td className="py-2 px-2 border-b border-gray-300">
                                            <p className="block antialiased text-center font-sans text-xs font-semibold text-gray-600">
                                                {formatDatetime(item.createdAt).split('ngày')[0]}
                                            </p>
                                            <p className="block antialiased text-center font-sans text-xs font-semibold text-gray-600">
                                                {formatDatetime(item.createdAt).split('ngày')[1]}
                                            </p>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colspan="6">
                                    <p className="w-full text-gray-800 font-semibold text-center my-8">
                                        Chưa có gói dịch vụ nào.
                                    </p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
