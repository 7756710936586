import {
    FaBirthdayCake,
    FaEnvelopeOpenText,
    FaImage,
    FaImages,
    FaInfoCircle,
    FaPaperPlane,
    FaPen,
} from 'react-icons/fa';
import IMAGES from '../../assets/images/_index';
import slider from './images/slider.jpg';
import imgDemo1 from './images/step-1-demo-1.png';
import imgDemo2 from './images/step-1-demo-2.png';
import ContactUs from './components/ContactUs';
import { useNavigate } from 'react-router-dom';
import PAGE from '../../config/path.route';
import Blog from './components/Blog';
import { useDispatch, useSelector } from 'react-redux';
import ToastNotify from '../../utils/Toast';
import modalAction from '../../redux/modal/modal.action';

export default function Help() {
    const { loggedIn } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClickEditEvent = () => {
        if (loggedIn) {
            navigate(PAGE.userEvents);
        } else {
            ToastNotify.Error('Bạn cần đăng nhập để thực hiện chức năng này.');
            dispatch(modalAction.openModalLogin());
        }
    };
    return (
        <div className="w-full flex flex-col py-[100px] lg:px-[100px] px-[20px] gap-[40px] items-center">
            <div
                className="w-full lg:h-[420px] h-[360px] rounded-lg flex justify-center items-center lg:px-[160px] px-[40px]"
                style={{
                    background: `url('${slider}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <p className="lg:text-6xl text-4xl text-center font-fairplay text-white italic leading-[100px]">
                    Xây dựng khoảnh khắc hạnh phúc của bạn
                </p>
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-[80px] lg:flex-row">
                <div className="flex max-w-[520px] flex-col gap-[10px]">
                    <p className="font-fairplay text-4xl text-black">Bước 1:</p>
                    <p className="font-fairplay text-4xl text-black">
                        Chọn ra mẫu thiệp mà bạn yêu thích
                    </p>
                    <p className=" text-black">
                        Việc chọn ra mẫu thiệp yêu thích của mình là một trải nghiệm tưởng chừng đơn
                        giản nhưng lại mang đầy sự quan trọng. Mỗi chi tiết trên thiệp, từ hình ảnh
                        đến thông điệp, đều phản ánh sự quan tâm và tình cảm của người gửi.
                    </p>

                    <button
                        className="px-8 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 mt-5 w-[240px] flex gap-2 justify-center items-center"
                        onClick={() => {
                            navigate(PAGE.allTemplates);
                        }}
                    >
                        <FaImages /> Chọn mẫu thiệp
                    </button>
                </div>
                <div className="flex gap-[20px] justify-center">
                    <img
                        src={imgDemo1}
                        alt="demo-1"
                        className="lg:max-w-[280px] lg:w-auto w-[45%] max-h-[400px] object-cover rounded-md"
                    />
                    <img
                        src={imgDemo2}
                        alt="demo-2"
                        className="lg:max-w-[280px] lg:w-auto w-[45%] max-h-[400px] object-cover rounded-md"
                    />
                </div>
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-[80px] lg:flex-row">
                <div className="flex gap-[20px]">
                    <div className="mt-10 flex lg:col-span-5 lg:mt-0">
                        <div className="relative h-full w-full">
                            <div className="mx-auto flex items-center justify-center rounded-2xl p-4 lg:float-end ">
                                <video
                                    className="rounded-3xl shadow-lg"
                                    width={360}
                                    id="video_demo"
                                    autoPlay={true}
                                    muted={true}
                                    playsInline={true}
                                    loop={true}
                                >
                                    <source
                                        type="video/webm"
                                        src={IMAGES.VIDEO_LANDING_WEBM}
                                    ></source>
                                    {/* <source type='video/mp4' src={IMAGES.VIDEO_LANDING_MP4}></source> */}
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex max-w-[520px] flex-col gap-[10px]">
                    <p className="font-fairplay text-4xl text-black">Bước 2:</p>
                    <p className="font-fairplay text-4xl text-black">
                        Cùng tạo ra sự kiện trọng đại của bạn
                    </p>
                    <p className=" text-black">
                        Không giới hạn thời gian trải nghiệm, không có phụ phí. Tạo tài khoản rồi
                        trải nghiệm, và thanh toán khi sẵn sàng để công khai sự kiện của bạn.
                    </p>
                    <p className="flex items-center gap-4 text-gray-700">
                        <FaInfoCircle className="text-lg text-blue-600" /> Nhập các thông tin quan
                        trọng trong sự kiện trọng đại của bạn
                    </p>
                    <p className="flex items-center gap-4 text-gray-700">
                        <FaImage className="text-lg text-blue-600" /> Thêm ảnh trình chiếu, album để
                        làm cho sự kiện trở nên rực rỡ hơn
                    </p>
                    <p className="flex items-center gap-4 text-gray-700">
                        <FaBirthdayCake className="text-lg text-blue-600" /> Các sự kiện chính là
                        những phần không thể thiếu để tạo nên một bữa tiệc hoành tráng
                    </p>
                    <p className="flex items-center gap-4 text-gray-700">
                        <FaEnvelopeOpenText className="text-lg text-blue-600" /> Cùng gửi lời mời
                        tới những người thân yêu nhất để cùng nhau trải nghiệm niềm vui
                    </p>
                    <p className="flex items-center gap-4 text-gray-700">
                        <FaPaperPlane className="text-lg text-blue-600" /> Hãy chia sẻ khoảnh khắc
                        hạnh phúc của bạn đến những người khác
                    </p>
                    <button
                        className="px-8 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 mt-5 w-[240px] flex gap-2 justify-center items-center"
                        onClick={() => handleClickEditEvent()}
                    >
                        <FaPen /> Tùy chỉnh sự kiện
                    </button>
                </div>
            </div>
            <ContactUs />
            <Blog />
        </div>
    );
}
