/* eslint-disable */

import Slider from 'react-slick';

import envConstants from '../../../../../config/key';
import { useState } from 'react';
import { useEffect } from 'react';
import { getTemplatePaginate } from '../../../../templates/Templates.service';
import { FaInfoCircle } from 'react-icons/fa';

export default function NewTemplate({ templateAlias }) {
    const [imgArr, setImgArr] = useState([]);
    const settings = {
        dots: true,
        customPaging: function () {
            return (
                <span className="block w-4 h-4 mx-2 transition-all rounded-full cursor-pointer"></span>
            );
        },
        dotsClass: 'slick-dots w-max absolute mt-20  ',
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: 'linear',
        // responsive: [
        //     {
        //         breakpoint: 770,
        //         settings: {
        //             slidesToShow: 2,
        //             slidesToScroll: 2,
        //             initialSlide: 2,
        //         },
        //     },
        //     {
        //         breakpoint: 480,
        //         settings: {
        //             slidesToShow: 1,
        //             slidesToScroll: 1,
        //         },
        //     },
        // ],
    };
    useEffect(() => {
        const isWedding = templateAlias.includes('wedding');
        getTemplatePaginate({}).then((data) => {
            if (isWedding) {
                setImgArr(
                    data?.templates
                        ?.filter((x) => x.alias.includes('wedding'))
                        .map((item) => envConstants.SERVER_URL + item.thumbnail)
                );
            } else {
                setImgArr(
                    data?.templates
                        ?.filter((x) => x.alias.includes('event'))
                        .map((item) => envConstants.SERVER_URL + item.thumbnail)
                );
            }
        });
    }, []);
    return (
        <div className="w-[200px] h-[80dvh] flex flex-col bg-gray-200 px-4 py-10 rounded-md gap-[40px] justify-center items-center">
            <p className="font-light text-black text-justify">
                Một hành trình đầy lãng mạn và sự sáng tạo, nơi mọi chi tiết được chăm chút tỉ mỉ để
                tạo ra những kỷ niệm đẹp đẽ và độc đáo cho ngày quan trọng của bạn.
            </p>
            <a href={envConstants.CLIENT_URL + 'loai-thiep/tat-ca'} className="w-fit">
                <button className="px-4 py-2 bg-blue-500 rounded-full text-white font-semibold transition-all flex gap-2 hover:scale-105">
                    <FaInfoCircle className="my-auto" /> Xem chi tiết
                </button>
            </a>
            <Slider
                {...settings}
                arrows={false}
                className="flex relative w-full min-h-[100px] bg-top z-10 lg:px-0 px-[40px] overflow-hidden"
            >
                {imgArr.map((img, index) => (
                    <img
                        alt="albumv2"
                        key={index}
                        src={img}
                        className="w-full object-cover rounded-md"
                    />
                ))}
            </Slider>

            {/* <img src={img1} className="w-full" /> */}
        </div>
    );
}
