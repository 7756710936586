/* eslint-disable */
import EventTemplate from '../../../../api/event.template.api';
import EventsService from '../../../events/Events.service';
import envConstants from '../../../../config/key';

const PublishSponsorService = {
    shareEventTemplate: async (id, data) => {
        const result = await EventTemplate.export(id, data);
        return result;
    },
    getPublic: async (eventId) => {
        const { data: response } = await EventsService.getOneEventById(eventId);
        const { data } = response;

        // means has Guest Info
        if (data.hasOwnProperty('event') && data.hasOwnProperty('guest')) {
            const { event, guest, eventConfig } = data;

            return {
                guest,
                eventConfig,
                ev: event,
                formData: event.form_data,
                templateAlias: event.template.alias,
                title: event.form_data.titleMeta,
                thumbnail: event.thumbnail_url,
            };
        }

        const { event, eventConfig } = data;
        return {
            eventConfig,
            data: event,
            formData: event.form_data,
            templateAlias: event.template.alias,
            title: event.title,
            thumbnail: event.thumbnail_url,
        };
    },

    saveContent: async (id, data) => {
        const objtoArr = Object.entries(data).map((x) => {
            return {
                // key: `bride`,
                key: x[0],
                value: x[1],
                isDeleted: true,
            };
        });
        const result = await EventTemplate.updateContent(id, objtoArr);
        return result;
    },
    createFile: async (url, key) => {
        if (
            url === '' ||
            url.startsWith(envConstants.SERVER_URL) ||
            url.startsWith('data:text/html;base64')
        ) {
            return '';
        }
        // if(url.startsWith(envConstants.SERVER_URL)){
        //     return url.replace(envConstants.SERVER_URL, '')
        // }
        let response = await fetch(url);
        let data = await response.blob();
        return new File([data], key + '_create_image.jpg', { type: 'image/jpeg' });
    },
    saveFiles: async (id, data, files, index, keyChild) => {
        const bodyFormData = new FormData();
        bodyFormData.append('key', data);
        bodyFormData.append('elementId', index);
        if (keyChild) {
            bodyFormData.append('keyChild', keyChild);
        }
        bodyFormData.append('value', files);

        const result = await EventTemplate.updateFile(id, bodyFormData);
        return result;
    },
    saveFileData: async (id, data, files, index, keyChild, name, web) => {
        const bodyFormData = new FormData();
        bodyFormData.append('key', data);
        bodyFormData.append('elementId', index);
        if (keyChild) {
            bodyFormData.append('keyChild', keyChild);
        }
        if (name) {
            bodyFormData.append('name', name);
        }
        if (web) {
            bodyFormData.append('web', web);
        }
        bodyFormData.append('value', files);

        const result = await EventTemplate.updateDataFile(id, bodyFormData);
        return result;
    }
};

export default PublishSponsorService;
