/* eslint-disable camelcase */
/* eslint-disable id-length */
/* eslint-disable quotes */
/* eslint-disable no-magic-numbers */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useState } from 'react';

// import IMAGES_WEDDING from '../../resource/wedding/images/_index';
import PublishStoryService from './PublisStory.service';
// import Templates from '../events/components/Templates';
import { useParams, useNavigate, Link } from 'react-router-dom';
import themeCommon from '../../PublishMainMenu.config';

import { Helmet } from 'react-helmet-async';
import PAGE from '../../../../config/path.route';
import { Cropper } from 'react-mobile-cropper';
import BaseModal from '../../../../components/BaseModal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
    FaEdit,
    FaTrash,
    FaPlus,
    FaCamera,
    FaClock,
    FaArrowRight,
    FaMapMarkerAlt,
    FaPenNib,
    FaPen,
    FaMeh,
    FaImage,
    FaSave,
    FaGripVertical,
    FaChartPie,
    FaExternalLinkAlt,
    FaTimes
} from 'react-icons/fa';
import ModalInfo from './components/ModalInfo';
import ToastNotify from '../../../../utils/Toast';
import ModalDelete from './components/ModalConfirmDelete';
import EventsService from '../../../events/Events.service';
import ContentSkeleton from './components/ContentSkeleton';
import { validateSizeOfFile } from '../../../../utils/common';
import LoadingButtonIcon from '../../../../components/LoadingButton/LoadingButtonIcon';
import ShortcutMenu from '../../components/ShortcutMenu';
import Products from '../publish.news/products/Products';
import TimelineShortcutMenu from '../../components/TimelineShortcutMenu';
import QuestionTitle from '../publish.question/QuestionTitle';
import IMAGES from '../../../../assets/images/_index';
import ModalLeavingPage from '../../../events/components/ModalLeavingPage';
import { checkTypeTemplate, limitString } from '../publish.greeting/PublishGreeting.page';
import { useSelector } from 'react-redux';

const parseJsonData = (formData) => {
    // parse json
    const dataCopy = formData;
    let arrParse = [];
    let arrCopy = [];
    let keyName = '';
    // story
    if (dataCopy?.story) {
        arrCopy = dataCopy.story;
        keyName = 'story';
    } else {
        //  story
        arrCopy = dataCopy.story;
        keyName = 'story';
    }
    if (Array.isArray(arrCopy)) {
        for (var idx = 0; idx < arrCopy.length; idx++) {
            if (typeof arrCopy[idx] === 'string') {
                arrParse.push(JSON.parse(arrCopy[idx]));
            } else {
                arrParse.push(arrCopy[idx]);
            }
        }
    } else {
        if (arrCopy) {
            arrParse.push(arrCopy);
        }
    }
    return { ...dataCopy, [keyName]: arrParse };
};

export default function PublishStory() {
    const navigate = useNavigate();
    const { eventId, url_guest: urlGuest } = useParams();
    const [formData, setFormData] = useState({});
    const [story, setStory] = useState([]);
    const [selectParty, setSelectParty] = useState();
    const [templateAlias, setTemplateAlias] = useState('');
    const [imageToCropParty, setImageToCropParty] = useState(null);
    const [idxSelect, setIdxSelect] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [isOpenLeavinModal, setOpenLeavinModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadData, setLoadData] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isExpiredEvent, setIsExpiredEvent] = useState(false);
    const { loggedIn } = useSelector((state) => state.auth);
    // const [guest, setGuest] = useState(undefined);
    // const [eventConfig, setEventConfig] = useState();
    const [valueMeta, setValueMeta] = useState({
        title: '',
        thumbnail: '',
    });
    const toastLoadingId = useRef(null);
    useEffect(() => {
        setLoading(true);
        if (eventId && loadData) {
            EventsService.getOneEventById(eventId)
                .then(({ data }) => {
                    //     const { templateAlias, formData, guest, title, thumbnail, data, eventConfig
                    // } =
                    //         res;
                    const title = data.form_data.titleMeta;
                    const thumbnail = data.thumbnail_url;
                    const templateAlias = data.template.alias;
                    const formData = data.form_data;
                    const url_slug = data.url_slug;
                    // const jsonData = parseJsonData({ ...data, ...formData });
                    // setContentMeta({ title, image: thumbnail })
                    setValueMeta({
                        title,
                        thumbnail,
                        url_slug,
                    });
                    setIsExpiredEvent(data?.isExpired);
                    setTemplateAlias(templateAlias);
                    // setGuest(guest);

                    setFormData(parseJsonData({ ...data, ...formData }));

                    if (Array.isArray(formData.story)) {
                        setStory(formData.story);
                    } else {
                        let copyArr = [];
                        copyArr.push(formData.story);
                        setStory(copyArr);
                    }

                    // setEventConfig(eventConfig);
                })
                .catch((error) => {
                    console.log(error);
                    const AuthorizeError = 404;
                    if (error.status === AuthorizeError) {
                        navigate(PAGE.dashboard);
                    }
                    const ServerError = 500;
                    if (error.status === ServerError) {
                        navigate(PAGE.dashboard);
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                });
            setLoadData(false);
        }
    }, [eventId, urlGuest, navigate, templateAlias, loadData]);

    const renderImg = (src) => process.env.REACT_APP_SERVER_URL + src;

    const changeFile = (event) => {
        const val = event.target.files[0];
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setImageToCropParty(image);
            // setCroppedImageGroom(image)
            setFormData({ ...formData, avatarGroom: [image] });
        });
        reader.readAsDataURL(val);
    };

    const submitForm = async () => {
        // console.log(normalObj);

        await PublishStoryService.submitForm(formData._id, convertObjectToArray(selectParty))
            .then(({ data }) => {
                // setFormData(parseJsonData(data?.form_data));

                if (Array.isArray(data?.form_data.story)) {
                    setStory(data?.form_data.story);
                } else {
                    let copyArr = [];
                    copyArr.push(data?.form_data.story);
                    setStory(copyArr);
                }
                ToastNotify.Success('Lưu thành công!');
            })
            .catch((err) => ToastNotify.Error(err?.message || 'Đã có lỗi xảy ra.'));
        // window.location.reload();

        // await PublishGroomService.saveFiles(normalObj);
    };

    const handlerUpdate = async () => {
        try {
            toastLoadingId.current = ToastNotify.Loading('Đang lưu ...');
            const data = await PublishStoryService.updateContent(formData._id, story);
            setIsEdit(false);
            setLoadData(true);
            if (data) {
                const title = data?.form_data?.titleMeta;
                const thumbnail = data?.thumbnail_url;
                const templateAlias = data?.template?.alias;
                const formData2 = data?.form_data;
                // setContentMeta({ title, image: thumbnail })
                setValueMeta({
                    title,
                    thumbnail,
                });
                setIsExpiredEvent(data?.isExpired);
                setTemplateAlias(templateAlias);
                // setGuest(guest);

                setFormData(parseJsonData({ ...data, ...formData2 }));

                if (Array.isArray(formData2?.story)) {
                    setStory(formData2?.story);
                } else {
                    let copyArr = [];
                    copyArr.push(formData2?.story);
                    setStory(copyArr);
                }
            }
            ToastNotify.Update(toastLoadingId.current, 'Lưu ảnh thành công!');
            toastLoadingId.current = null;
        } catch (error) {
            setIsEdit(false);
            ToastNotify.Update(
                toastLoadingId.current,
                error?.message || 'Đã có lỗi xảy ra. Vui lòng thử lại',
                'error'
            );
            toastLoadingId.current = null;
        }
    };

    function convertObjectToArray(obj) {
        // Khởi tạo một mảng rỗng để lưu kết quả
        var result = [];

        // Duyệt qua các thuộc tính của đối tượng
        for (var key in obj) {
            // Tạo một đối tượng mới có các thuộc tính key, keyChild, value và elementId
            if (key !== 'id' && key !== 'image') {
                var newObj = {
                    key: 'story', // Đặt key là story
                    keyChild: key, // Đặt keyChild là tên của thuộc tính hiện tại
                    value: obj[key], // Đặt value là giá trị của thuộc tính hiện tại
                    elementId: obj.id.toString(), // Đặt elementId là giá trị của thuộc tính id của đối tượng
                };

                // Thêm đối tượng mới vào mảng kết quả
                result.push(newObj);
            }
        }

        // Trả về mảng kết quả
        return result;
    }
    function updateIndex(arr) {
        arr.forEach((item, index) => {
            item.index = index + 1;
        });
        return arr;
    }
    const handleOnDragEnd = (result) => {
        // console.log('result ', result)
        if (!result.destination) return;

        const items = Array.from(story);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        const newArr = updateIndex(items);
        const checknewArr = newArr.reduce((prev, curr) => `${prev}|${curr.index}|${curr.id}`, '');
        const checkstory = story.reduce((prev, curr) => `${prev}|${curr.index}|${curr.id}`, '');
        if (checknewArr !== checkstory) {
            setIsEdit(true);
            setStory(newArr);
        }
        // setStory(items)
        // reCalculateTimeProgram(items);
    };
    const findMaxIdx = () => {
        // Khởi tạo một biến để lưu giá trị id lớn nhất
        var maxId = 0;

        // Duyệt qua các phần tử trong mảng
        for (var i = 0; i < story.length; i++) {
            // Lấy giá trị id của phần tử hiện tại
            var id = Number(story[i].id);

            // So sánh với giá trị id lớn nhất hiện có
            if (id > maxId) {
                // Nếu lớn hơn, cập nhật giá trị id lớn nhất
                maxId = id;
            }
        }

        // Cộng giá trị id lớn nhất với 1
        var result = maxId + 1;

        // Trả về kết quả
        return result;
    };
    const clearForm = () => {
        console.log('clear');
        setSelectParty({});
    };

    const addFile = (event, index) => {
        const val = event.target.files[0];
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setOpenModal(true);
            setImageToCropParty(image);
            setIdxSelect(index);
            // setImage(image);
            // setCroppedImageGroom(image)
        });
        reader.readAsDataURL(val);
    };

    const handleDelteEv = async () => {
        const payload = [{ key: 'story', value: selectParty.id, isDeleted: true }];
        await PublishStoryService.submitForm(formData._id, payload)
            .then(({ data }) => {
                // setFormData(parseJsonData(data?.form_data));

                if (Array.isArray(data?.form_data.story)) {
                    setStory(data?.form_data.story);
                } else {
                    let copyArr = [];
                    copyArr.push(data?.form_data.story);
                    setStory(copyArr);
                }
                ToastNotify.Success('Xóa thành công!');
            })
            .catch((err) => ToastNotify.Error(err?.message || 'Đã có lỗi xảy ra.'));
    };

    function convertDateFormat(inputDate) {
        // Chuyển đổi từ string sang đối tượng Date
        var dateObject = new Date(inputDate);

        // Lấy ngày, tháng và năm từ đối tượng Date
        var day = dateObject.getDate();
        var month = dateObject.getMonth() + 1; // Tháng bắt đầu từ 0
        var year = dateObject.getFullYear();

        // Định dạng lại chuỗi theo định dạng mong muốn
        var formattedDate =
            (day < 10 ? '0' : '') + day + '-' + (month < 10 ? '0' : '') + month + '-' + year;

        return formattedDate;
    }

    useEffect(() => {
        if (!loggedIn) {
            const beforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = true;
            };
            window.addEventListener('beforeunload', beforeUnload);
            return () => {
                window.removeEventListener('beforeunload', beforeUnload);
            };
        }
    }, []);

    return (
        <div className="relative flex flex-col items-center gap-[1.5rem] pb-[2rem] pt-[82px]">
            <div className={'fixed top-0 z-[9] h-[72px] w-[100vw] bg-gray-50 px-[20px]'}>
                <div className="relative flex w-full flex-wrap items-center justify-between gap-x-3 p-4 md:px-0 lg:flex-nowrap lg:gap-4 lg:py-5">
                    <div className="flex md:flex-row flex-col md:gap-2">
                        <button
                            className="flex items-center"
                            type="button"
                            onClick={() => {
                                navigate(`/${eventId}/mainmenu`);
                            }}
                            title="Trở về"
                        >
                            <i className=" fas fa-chevron-left"></i>
                            <p className="order-1 ml-3 font-bold text-black">
                                {limitString(formData?.name)}
                            </p>
                        </button>
                        {
                            isExpiredEvent &&
                            <div className='h-5 rounded-[4px]  flex justify-center items-center bg-red-500 px-3 w-fit'>
                                <span className='text-[9px] text-white uppercase'>
                                    {checkTypeTemplate(templateAlias)}
                                </span>
                            </div>
                        }
                        {
                            !loggedIn &&
                            <div className='h-5 rounded-[4px]  flex justify-center items-center bg-red-500 px-3 w-fit'>
                                <span className='text-[9px] text-white uppercase'>
                                    Bạn chưa đăng nhập
                                </span>
                            </div>
                        }
                    </div>
                    <div className="flex justify-center gap-2 overflow-hidden ">
                        {
                            loggedIn &&
                            <Link
                                // target="_blank"
                                className="inline-flex select-none gap-2 rounded-md bg-white border border-blue-500 px-3 py-1 text-center font-medium text-blue-500 transition-all duration-150 ease-linear hover:bg-blue-100 xl:px-4 text-sm"
                                to={`..${PAGE.profile}`}
                                state={{ isResourcePackage: true }}
                            >
                                Quản lý gói dịch vụ
                                <FaChartPie className="my-auto" />
                            </Link>
                        }
                        <button className="inline-flex w-max select-none rounded-md bg-blue-500 px-3 py-1 text-center font-medium text-white transition-all duration-150 ease-linear hover:bg-blue-600 xl:px-4 text-sm">
                            <Link
                                target="_blank"
                                className="flex gap-2"
                                to={'../' + valueMeta?.url_slug}
                            >
                                Xem thiệp{' '}
                                <FaExternalLinkAlt className="my-auto" />
                            </Link>
                        </button>
                    </div>
                </div>
                <div className="w-full border-b border-gray-300"></div>
            </div>
            <div className=" flex w-full justify-evenly px-2">
                <div className="relative hidden w-[250px] lg:flex">
                    <TimelineShortcutMenu screen={'story'} />
                </div>
                {loading ? (
                    <ContentSkeleton />
                ) : (
                    <div className="w-full rounded-lg border-[1px] border-slate-200 bg-white-box shadow-lg lg:w-[48rem] 2xl:w-[60rem]">
                        <div className="flex w-full flex-col border-b-[1px] border-slate-200 p-2 px-3">
                            <p className="text-lg font-bold text-black">Câu chuyện</p>
                            <QuestionTitle keyQues={'story'} />
                        </div>
                        <div className="flex w-full flex-col items-center gap-5 px-6 py-5">
                            {!isExpiredEvent && (
                                <>
                                    <div className=' w-full flex justify-end'>
                                        <button
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalContent"
                                            disabled={isExpiredEvent}
                                            onClick={() => {
                                                setSelectParty({ id: findMaxIdx() });
                                            }}
                                            className="hidden gap-[6px] rounded-lg bg-blue-600 px-[12px] py-[8px] text-sm font-semibold text-white hover:bg-blue-700 md:flex "
                                        >
                                            <FaPlus className="mr-0.5 mt-0.5" />
                                            Thêm câu chuyện
                                        </button>
                                        <button
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalContent"
                                            disabled={isExpiredEvent}
                                            onClick={() => {
                                                setSelectParty({ id: findMaxIdx() });
                                            }}
                                            className="block gap-[6px] rounded-lg bg-blue-600 px-[12px] py-[8px] text-sm font-semibold text-white hover:bg-blue-700 md:hidden "
                                        >
                                            <FaPlus />
                                        </button>
                                    </div>
                                </>
                            )}
                            {story?.length !== 0 ? (
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable
                                        droppableId="story"
                                        // mode="virtual"
                                        isDropDisabled={isExpiredEvent}
                                        // direction="horizontal"
                                        renderClone={(
                                            provided,
                                            snapshot,
                                            rubric
                                        ) => (
                                            <div
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                style={
                                                    provided.draggableProps.style
                                                }
                                                className={
                                                    snapshot.isDragging
                                                        ? 'dragging'
                                                        : ''
                                                }
                                            >
                                                <div
                                                    className="relative flex w-full flex-col items-center gap-[10px] rounded-md bg-white p-6 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] md:flex-row "
                                                >
                                                    <div
                                                        title="Kéo thả ảnh để thay đổi vị trí"
                                                        className={'w-max cursor-grab rounded-full border text-black/70 shadow-lg'}
                                                    >
                                                        <FaGripVertical />
                                                    </div>
                                                    <div className='mr-[18px] h-full'>
                                                        <div className="group relative h-[12em] w-[12rem]">
                                                            {story[rubric.source.index]?.image ? (
                                                                <img
                                                                    src={renderImg(story[rubric.source.index]?.image)}
                                                                    className="h-full w-full rounded-md object-cover"
                                                                    alt="Ảnh câu chuyện"
                                                                />
                                                            ) : (
                                                                <div className="flex h-full w-full flex-col items-center justify-center gap-[10px] rounded-md bg-gray-300 text-[12px] text-white">
                                                                    <FaCamera className="text-[32px]" />
                                                                </div>
                                                            )}
                                                            {story[rubric.source.index]?.image && <button
                                                                className="absolute -top-0 flex h-full w-full flex-col items-center justify-center rounded-md bg-black/30 opacity-0 backdrop-blur-sm duration-500 ease-out group-hover:opacity-100"
                                                                disabled={isExpiredEvent}
                                                            >
                                                                {story[rubric.source.index]?.image ? (
                                                                    <FaEdit className="cursor-pointer text-xl text-slate-100" />
                                                                ) : (
                                                                    <FaPlus className="cursor-pointer text-xl text-slate-100" />
                                                                )}
                                                            </button>
                                                            }
                                                            {!story[rubric.source.index]?.image &&
                                                                <label className="absolute -top-0 flex h-full w-full flex-col items-center justify-center rounded-md bg-black/30 opacity-0 backdrop-blur-sm duration-500 ease-out group-hover:opacity-100">
                                                                    <FaPlus className="cursor-pointer text-xl text-slate-100" />
                                                                </label>
                                                            }
                                                            <div className="absolute -right-3 -top-3 inline-flex h-8 w-8 items-center justify-center rounded-full border-2 border-white bg-red-500 text-xs font-bold text-white">
                                                                {story[rubric.source.index]?.image ? <FaPen /> : <FaPlus />}
                                                            </div>
                                                        </div>
                                                        <div className='text-[11px] italic text-slate-600 '>Chú ý*: Dung lượng ảnh tối đa 10 Mb</div>
                                                    </div>
                                                    <div className="relative flex w-full flex-col gap-[28px] md:w-[40rem]">
                                                        {!isExpiredEvent && (
                                                            <div className="absolute right-[0px] top-[0px] flex  gap-[4px] ">
                                                                <button
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#modalContent"
                                                                    disabled={isExpiredEvent}
                                                                    className="flex items-center gap-[4px] rounded-md px-[8px] py-[4px] text-sm font-semibold text-blue-600 transition-all hover:bg-blue-100"
                                                                >
                                                                    <FaEdit /> Sửa{' '}
                                                                </button>
                                                                <button
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#confirmDeleteEvent"
                                                                    disabled={isExpiredEvent}
                                                                    className="flex items-center gap-[4px] rounded-md px-[8px] py-[4px] text-sm font-semibold text-red-500 transition-all hover:bg-red-100"
                                                                >
                                                                    <FaTrash /> Xóa{' '}
                                                                </button>
                                                            </div>
                                                        )}
                                                        <div className="flex w-full items-center justify-start gap-[16px]">
                                                            <div className="flex flex-col gap-[8px]">
                                                                <p className="text-[16px] font-bold text-black">
                                                                    {story[rubric.source.index]?.name}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`flex h-[50px] w-full items-center justify-start gap-[16px] rounded-md bg-slate-100 ${!(story[rubric.source.index]?.time || story[rubric.source.index]?.date)
                                                                ? 'text-red-400'
                                                                : 'text-gray-600'
                                                                } p-4 text-sm `}
                                                        >
                                                            <FaClock />{' '}
                                                            {story[rubric.source.index]?.time || story[rubric.source.index]?.date
                                                                ? `Vào lúc ${story[rubric.source.index]?.time || '--'}  ${story[rubric.source.index]?.date
                                                                    ? convertDateFormat(story[rubric.source.index]?.date)
                                                                    : ''
                                                                }`
                                                                : 'Chưa có thời gian'}
                                                        </div>

                                                        <div className="flex w-full items-center justify-between rounded-md bg-slate-100 p-4">
                                                            <div className="flex h-full max-w-[20rem] flex-col justify-between gap-[8px]">
                                                                <p
                                                                    className={`flex items-center gap-[16px] text-sm ${!story[rubric.source.index]?.place
                                                                        ? 'text-red-400'
                                                                        : 'text-gray-600'
                                                                        }`}
                                                                >
                                                                    <FaMapMarkerAlt />{' '}
                                                                    {story[rubric.source.index]?.place
                                                                        ? `Tại
										${story[rubric.source.index]?.place}`
                                                                        : 'Chưa có địa điểm '}
                                                                </p>

                                                                <p
                                                                    className={`flex items-center gap-[16px] text-sm ${!story[rubric.source.index]?.content
                                                                        ? 'text-red-400'
                                                                        : 'text-gray-600'
                                                                        }`}
                                                                >
                                                                    <FaPenNib />{' '}
                                                                    {story[rubric.source.index]?.content
                                                                        ? `${story[rubric.source.index]?.content}`
                                                                        : 'Chưa có nội dung'}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {(provided) => (
                                            <ul
                                                className="flex w-full flex-col items-center gap-5"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {
                                                    story?.sort((start, end) => {
                                                        if (
                                                            start?.index &&
                                                            end?.index
                                                        ) {
                                                            return (
                                                                Number(
                                                                    start.index
                                                                ) -
                                                                Number(end.index)
                                                            );
                                                        } else {
                                                            return (
                                                                Number(start.id) -
                                                                Number(end.id)
                                                            );
                                                        }
                                                    })
                                                        .map((partyItem, index) => (
                                                            <Draggable
                                                                key={index + 10}
                                                                draggableId={(
                                                                    index + 10
                                                                ).toString()}
                                                                index={index}
                                                                isDragDisabled={
                                                                    isExpiredEvent
                                                                }
                                                            >
                                                                {(provided) => (
                                                                    <li
                                                                        key={index}
                                                                        className="relative flex w-full flex-col items-center gap-[10px] rounded-md bg-white p-6 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] md:flex-row "
                                                                        ref={
                                                                            provided.innerRef
                                                                        }
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <div
                                                                            title="Kéo thả ảnh để thay đổi vị trí"
                                                                            className={'w-max cursor-grab text-black/70'}
                                                                        >
                                                                            <FaGripVertical />
                                                                        </div>
                                                                        <div className='mr-[18px] h-full'>
                                                                            <div className="group relative h-[12em] w-[12rem]">
                                                                                {partyItem?.image ? (
                                                                                    <img
                                                                                        src={renderImg(partyItem?.image)}
                                                                                        className="h-full w-full rounded-md object-cover"
                                                                                        alt="Ảnh câu chuyện"
                                                                                    />
                                                                                ) : (
                                                                                    <div className="flex h-full w-full flex-col items-center justify-center gap-[10px] rounded-md bg-gray-300 text-[12px] text-white">
                                                                                        <FaCamera className="text-[32px]" />
                                                                                    </div>
                                                                                )}
                                                                                {partyItem?.image && <button
                                                                                    className="absolute -top-0 flex h-full w-full flex-col items-center justify-center rounded-md bg-black/30 opacity-0 backdrop-blur-sm duration-500 ease-out group-hover:opacity-100"
                                                                                    onClick={() => {
                                                                                        setOpenModal(true);
                                                                                        setImageToCropParty(renderImg(partyItem?.image));
                                                                                        setIdxSelect(partyItem?.id);
                                                                                    }}
                                                                                    disabled={isExpiredEvent}
                                                                                >
                                                                                    {partyItem?.image ? (
                                                                                        <FaEdit className="cursor-pointer text-xl text-slate-100" />
                                                                                    ) : (
                                                                                        <FaPlus className="cursor-pointer text-xl text-slate-100" />
                                                                                    )}
                                                                                </button>
                                                                                }
                                                                                {!partyItem?.image &&
                                                                                    <label className="absolute -top-0 flex h-full w-full flex-col items-center justify-center rounded-md bg-black/30 opacity-0 backdrop-blur-sm duration-500 ease-out group-hover:opacity-100">
                                                                                        <FaPlus className="cursor-pointer text-xl text-slate-100" />
                                                                                        <input
                                                                                            type="file"
                                                                                            className="hidden"
                                                                                            onChange={(event) => addFile(event, partyItem?.id)}
                                                                                            accept="image/*"
                                                                                        />
                                                                                    </label>
                                                                                }
                                                                                <div className="absolute -right-3 -top-3 inline-flex h-8 w-8 items-center justify-center rounded-full border-2 border-white bg-red-500 text-xs font-bold text-white">
                                                                                    {partyItem?.image ? <FaPen /> : <FaPlus />}
                                                                                </div>
                                                                            </div>
                                                                            <div className='text-[11px] italic text-slate-600 '>Chú ý*: Dung lượng ảnh tối đa 10 Mb</div>
                                                                        </div>
                                                                        <div className="relative flex w-full flex-col gap-[28px] md:w-[40rem]">
                                                                            {!isExpiredEvent && (
                                                                                <div className="absolute right-[0px] top-[0px] flex  gap-[4px] ">
                                                                                    <button
                                                                                        data-bs-toggle="modal"
                                                                                        data-bs-target="#modalContent"
                                                                                        onClick={() => {
                                                                                            setSelectParty(partyItem);
                                                                                        }}
                                                                                        disabled={isExpiredEvent}
                                                                                        className="flex items-center gap-[4px] rounded-md px-[8px] py-[4px] text-sm font-semibold text-blue-600 transition-all hover:bg-blue-100"
                                                                                    >
                                                                                        <FaEdit /> Sửa{' '}
                                                                                    </button>
                                                                                    <button
                                                                                        data-bs-toggle="modal"
                                                                                        data-bs-target="#confirmDeleteEvent"
                                                                                        onClick={() => {
                                                                                            setSelectParty(partyItem);
                                                                                        }}
                                                                                        disabled={isExpiredEvent}
                                                                                        className="flex items-center gap-[4px] rounded-md px-[8px] py-[4px] text-sm font-semibold text-red-500 transition-all hover:bg-red-100"
                                                                                    >
                                                                                        <FaTrash /> Xóa{' '}
                                                                                    </button>
                                                                                </div>
                                                                            )}
                                                                            <div className="flex w-full items-center justify-start gap-[16px]">
                                                                                <div className="flex flex-col gap-[8px]">
                                                                                    <p className="text-[16px] font-bold text-black">
                                                                                        {partyItem?.name}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className={`flex h-[50px] w-full items-center justify-start gap-[16px] rounded-md bg-slate-100 ${!(partyItem?.time || partyItem?.date)
                                                                                    ? 'text-red-400'
                                                                                    : 'text-gray-600'
                                                                                    } p-4 text-sm `}
                                                                            >
                                                                                <FaClock />{' '}
                                                                                {partyItem?.time || partyItem?.date
                                                                                    ? `Vào lúc ${partyItem?.time || '--'}  ${partyItem?.date
                                                                                        ? convertDateFormat(partyItem?.date)
                                                                                        : ''
                                                                                    }`
                                                                                    : 'Chưa có thời gian'}
                                                                            </div>

                                                                            <div className="flex w-full items-center justify-between rounded-md bg-slate-100 p-4">
                                                                                <div className="flex h-full max-w-[20rem] flex-col justify-between gap-[8px]">
                                                                                    <p
                                                                                        className={`flex items-center gap-[16px] text-sm ${!partyItem?.place
                                                                                            ? 'text-red-400'
                                                                                            : 'text-gray-600'
                                                                                            }`}
                                                                                    >
                                                                                        <FaMapMarkerAlt />{' '}
                                                                                        {partyItem?.place
                                                                                            ? `Tại
													${partyItem?.place}`
                                                                                            : 'Chưa có địa điểm '}
                                                                                    </p>

                                                                                    <p
                                                                                        className={`flex items-center gap-[16px] text-sm ${!partyItem?.content
                                                                                            ? 'text-red-400'
                                                                                            : 'text-gray-600'
                                                                                            }`}
                                                                                    >
                                                                                        <FaPenNib />{' '}
                                                                                        {partyItem?.content
                                                                                            ? `${partyItem?.content}`
                                                                                            : 'Chưa có nội dung'}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="flex gap-[4px] md:hidden md:flex-none ">
																	<button
																		data-bs-toggle="modal"
																		data-bs-target="#modalContent"
																		onClick={() => {
																			setSelectParty(
																				partyItem
																			);
																		}}
																		className="flex items-center gap-[4px] rounded-md px-[8px] py-[4px] text-sm font-semibold text-blue-600 transition-all hover:bg-blue-100"
																	>
																		<FaEdit /> Sửa{' '}
																	</button>
																	<button
																		data-bs-toggle="modal"
																		data-bs-target="#confirmDeleteEvent"
																		onClick={() => {
																			setSelectParty(
																				partyItem
																			);
																		}}
																		className="flex items-center gap-[4px] rounded-md px-[8px] py-[4px] text-sm font-semibold text-red-500 transition-all hover:bg-red-100"
																	>
																		<FaTrash /> Xóa{' '}
																	</button>
																</div> */}
                                                                        </div>
                                                                    </li>
                                                                )}
                                                            </Draggable>
                                                        ))
                                                }
                                            </ul>)}
                                    </Droppable>
                                </DragDropContext>
                            ) : (
                                <div className='flex w-full flex-col items-center justify-center'>
                                    <img
                                        src={IMAGES.STORY} alt='not found'
                                        className='mx-auto w-1/3 opacity-70'
                                    />
                                    <h4 className="mt-5 text-center text-xl text-blue-900 ">
                                        Ghi lại câu chuyện tình yêu của bạn {' '}
                                        {
                                            !isExpiredEvent &&
                                            <div
                                                data-bs-toggle="modal"
                                                data-bs-target="#modalContent"
                                                onClick={() => {
                                                    setSelectParty({ id: findMaxIdx() });
                                                }}
                                                className='inline-block cursor-pointer font-bold text-blue-500 underline'>
                                                {' '}tại đây.
                                            </div>
                                        }
                                    </h4>
                                    <p className='mt-2 text-center text-sm text-black'>
                                        Chia sẻ câu chuyện ngọt ngào ấy với mọi người để thiệp mời của bạn trở nên thật ý nghĩa!
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {!isExpiredEvent && (
                    <div
                        className={
                            (isEdit
                                ? 'bottom-0 opacity-100'
                                : '-bottom-80 opacity-0') +
                            ' fixed inset-x-0 z-[40] bg-gray-50 transition-all duration-500 md:px-6 lg:z-[40]'
                        }
                    >
                        <div className="flex items-center justify-between gap-3 border-t border-gray-300 px-6 py-4 md:px-0">
                            <p>Chưa lưu !</p>
                            <div className="flex gap-3">
                                <button
                                    type="button"
                                    className={
                                        'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-100 px-6 py-2 text-center font-semibold text-blue-500 transition-all duration-150 ease-linear hover:bg-indigo-200 '
                                    }
                                    onClick={() => {
                                        setIsEdit(false);
                                        setLoadData(true);
                                    }}
                                >
                                    <FaTimes />
                                    Hủy bỏ
                                </button>
                                <button
                                    onClick={() => setOpenLeavinModal(true)}
                                    className={
                                        'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-500 px-6 py-2 text-center font-semibold text-white transition-all duration-150 ease-linear hover:bg-blue-600 '
                                    }
                                >
                                    <FaSave />
                                    Hoàn tất
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="relative hidden w-[250px] lg:flex">
                    <Products />
                </div>
            </div>
            {/* story[rubric.source.index]. */}

            <ModalInfo
                data={selectParty}
                setSelectParty={setSelectParty}
                submitForm={submitForm}
                clearForm={clearForm}
            />

            <ModalDelete handleDelteEv={handleDelteEv} />

            <ModalLeavingPage
                saveChanged={handlerUpdate}
                isOpen={isOpenLeavinModal}
                confirmNavigation={() => {
                    setOpenLeavinModal(false);
                }}
                key={formData._id}
                funToggle={() => {
                    setOpenLeavinModal(false);
                }}
            />
            <BaseModal
                isOpen={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                modalTitle={`Bổ sung hình ảnh cho câu chuyện ${story[idxSelect - 1]?.name}`}
                width="max-w-[700px]"
            >
                {!imageToCropParty && (
                    <>
                        <label className="w-500-px mt-1.5 flex h-500-px w-full cursor-pointer appearance-none justify-center rounded-md border-2 border-dashed border-gray-300 bg-white px-4 transition hover:border-gray-400 focus:outline-none">
                            <span className="flex items-center space-x-2 py-10">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-gray-600"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                    />
                                </svg>
                                <span className="text-sm font-bold text-gray-700">Tải ảnh lên</span>
                            </span>
                            <input
                                className="hidden"
                                onChange={(event) => changeFile(event)}
                                type="file"
                                accept="image/*"
                            />
                        </label>
                    </>
                )}
                {imageToCropParty && (
                    <CropperModal
                        defaultsrc={imageToCropParty}
                        eventId={formData?._id}
                        templateAlias={templateAlias}
                        key={formData?._id}
                        partyIdx={idxSelect}
                        setOpenModal={setOpenModal}
                        setStory={setStory}
                    />
                )}
            </BaseModal>
            {/* <ShortcutMenu screen={'story'} /> */}
        </div>
    );
}

const CropperModal = ({ defaultsrc, eventId, templateAlias, partyIdx, setOpenModal, setStory }) => {
    const cropperRef = useRef(null);
    const [image, setImage] = useState(defaultsrc);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const toastLoadingId = useRef(null);

    const changeFile = (event) => {
        const val = event.target.files[0];
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setImage(image);
            // setCroppedImageGroom(image)
        });
        reader.readAsDataURL(val);
    };

    const submitImageForm = async (name) => {
        setIsLoadingBtn(true);
        const file = await PublishStoryService.createFile(
            cropperRef.current.getCanvas()?.toDataURL('image/jpeg', 1),
            'img_party'
        );

        if (
            validateSizeOfFile(
                file,
                10,
                'Dung lượng ảnh sau khi chỉnh sửa không được vượt quá 10Mb'
            )
        ) {
            toastLoadingId.current = ToastNotify.Loading('Đang lưu ảnh...');
            const fileOrigin = await PublishStoryService.createFile(
                cropperRef.current.getCanvas()?.toDataURL(),
                'img_content'
            );
            PublishStoryService.saveFiles(eventId, name, fileOrigin, partyIdx)
                .then(({ data }) => {
                    ToastNotify.Update(toastLoadingId.current, 'Cập nhật ảnh thành công!');
                    if (Array.isArray(data?.form_data.story)) {
                        setStory(data?.form_data.story);
                    } else {
                        let copyArr = [];
                        copyArr.push(data?.form_data.story);
                        setStory(copyArr);
                    }
                    setIsLoadingBtn(false);
                })
                .catch((error) => {
                    ToastNotify.Update(
                        toastLoadingId.current,
                        error?.message || 'Đã có lỗi xảy ra khi lưu ảnh!',
                        'error'
                    );
                    setIsLoadingBtn(false);
                }
                )
                .finally(() => {
                    setOpenModal(false);
                    toastLoadingId.current = null;
                });

            // const { data } = await PublishStoryService.saveFiles(eventId, name, file, partyIdx);
            // ToastNotify.Success('Cập nhật ảnh thành công');
            // if (Array.isArray(data?.form_data.story)) {
            //     setStory(data?.form_data.story);
            // } else {
            //     let copyArr = [];
            //     copyArr.push(data?.form_data.story);
            //     setStory(copyArr);
            // }
            // setOpenModal(false);
        }
    };
    return (
        <div className="mx-1.5 appearance-none justify-center rounded-md  bg-white p-2 transition focus:outline-none">
            {image.indexOf('undefined') === -1 ? (
                <Cropper
                    stencilProps={{
                        aspectRatio: 1,
                    }}
                    ref={cropperRef}
                    style={{ height: '500px', width: '500px' }}
                    className="cropper h-[500px] rounded-md"
                    src={image}
                />
            ) : (
                <label className="mx-auto flex h-[500px] w-full cursor-pointer appearance-none flex-col items-center justify-center gap-[20px] rounded-lg border-2 border-dashed border-gray-300 bg-white transition hover:border-gray-400 focus:outline-none">
                    <FaImage className="text-[60px] text-gray-500" />
                    <span className="text-[16px] font-semibold text-violet-500">
                        Thêm ảnh{' '}
                        <span className="text-[16px] font-medium text-gray-400">
                            (Giới hạn 10Mb)
                        </span>
                    </span>

                    <input
                        type="file"
                        className="hidden"
                        onChange={(event) => changeFile(event)}
                        accept="image/*"
                    />
                </label>
            )}
            {image.indexOf('undefined') === -1 && (
                <div className="m-2 mt-[20px] flex justify-center gap-2">
                    <label
                        className={`flex cursor-pointer items-center gap-[6px] rounded-lg bg-blue-100 px-[16px] py-[8px] text-[16px] font-semibold text-blue-700 hover:bg-blue-200 ${isLoadingBtn ? 'cursor-auto bg-slate-100 text-slate-700 hover:bg-blue-100' : ''}`}
                    // onClick={() => btnRemoveImagePreview('avatarGroom')}
                    >
                        <input
                            className="hidden"
                            onChange={(event) => changeFile(event)}
                            type="file"
                            accept="image/*"
                        />
                        <FaPen /> Thay đổi
                    </label>
                    <button
                        className={`${isLoadingBtn ? 'bg-blue-500' : 'bg-blue-600 hover:bg-blue-700 '} flex items-center gap-[6px] rounded-lg  px-[16px] py-[8px] text-[16px] font-semibold text-white disabled:bg-slate-700 `}
                        onClick={() => submitImageForm('story')}
                        disabled={isLoadingBtn}
                    >
                        {isLoadingBtn && <LoadingButtonIcon />}
                        {!isLoadingBtn && <FaSave />}
                        Lưu
                    </button>
                </div>
            )}
        </div>
    );
};
