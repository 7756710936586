/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { useEffect, useState, useRef } from 'react';

// import IMAGES_WEDDING from '../../resource/wedding/images/_index';
import PublishAlbumService from './PublishAlbum.service';
import BaseModal from '../../../../components/BaseModal';
// import Templates from '../events/components/Templates';
import { useParams, useNavigate, Link } from 'react-router-dom';
import themeCommon from '../../PublishMainMenu.config';
// import { setContentMeta } from '../../utils/MetaTag';
import { Helmet } from 'react-helmet-async';
import { Cropper } from 'react-mobile-cropper';
// import { CropperCanvas } from 'react-advanced-cropper';
import 'react-mobile-cropper/dist/style.css';
// import Banks from '../../../../resource/wedding/_input/components/Banks';
import ToastNotify from '../../../../utils/Toast';
import EventsService from '../../../events/Events.service';

import PAGE from '../../../../config/path.route';
import ModalLeavingPage from '../../../events/components/ModalLeavingPage';
import AlbumSkeleton from './AlbumSkeleton';
import { validateSizeOfFile } from '../../../../utils/common';
import ShortcutMenu from '../../components/ShortcutMenu';
// import { FaBirthdayCake, FaInfoCircle, FaUserCircle, FaMinusCircle, FaPlusCircle} from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { BsArrowsFullscreen } from 'react-icons/bs';
import ImageView from './components/ImageView';
import Products from '../publish.news/products/Products';
import TimelineShortcutMenu from '../../components/TimelineShortcutMenu';
import { FaArrowsAlt, FaExpand, FaPlus, FaGripVertical, FaPen, FaSave, FaChartPie, FaExternalLinkAlt, FaTimes } from 'react-icons/fa';
import IMAGES from '../../../../assets/images/_index';
import QuestionTitle from '../publish.question/QuestionTitle';
import { checkTypeTemplate, limitString } from '../publish.greeting/PublishGreeting.page';
import { useSelector } from 'react-redux';

const parseJsonData = (formData) => {
    // parse json
    const dataCopy = formData;
    let arrParse = [];
    let arrCopy = [];
    let keyName = '';
    // infoParty
    if (dataCopy?.infoParty) {
        arrCopy = dataCopy.infoParty;
        keyName = 'infoParty';
    } else {
        //  programes
        arrCopy = dataCopy.programes;
        keyName = 'programes';
    }
    if (Array.isArray(arrCopy)) {
        for (var idx = 0; idx < arrCopy.length; idx++) {
            if (typeof arrCopy[idx] === 'string') {
                arrParse.push(JSON.parse(arrCopy[idx]));
            } else {
                arrParse.push(arrCopy[idx]);
            }
        }
    } else {
        if (arrCopy) {
            arrParse.push(JSON.parse(arrCopy));
        }
    }
    return { ...dataCopy, [keyName]: arrParse };
};

const CropperModal = ({
    index,
    templateAlias,
    setOpenModal,
    setNormalObj,
    normalObj,
    setIsEdit,
    tempImg,
}) => {
    const cropperRef = useRef(null);
    const [image, setImage] = useState(normalObj?.album[index]?.image ?? tempImg);

    const changeFile = (event) => {
        const val = event.target.files[0];
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setImage(image);
        });
        reader.readAsDataURL(val);
    };

    const submitImageForm = async () => {
        const img = cropperRef.current.getCanvas()?.toDataURL();
        const change = Math.max(
            ...(normalObj?.album ?? []).map((x) => (isNaN(Number(x.id)) ? -1 : Number(x.id)))
        );
        const max = normalObj?.album?.length > 0 ? change : 1;
        setNormalObj({
            ...normalObj,
            ['album']:
                index !== null
                    ? (normalObj?.album ?? []).map((x, idx) =>
                        idx === index ? { image: img, id: x.id } : x
                    )
                    : normalObj?.album.concat([{ image: img, id: (max + 1).toString() }]),
        });
        setIsEdit(true);
        setOpenModal(false);
    };
    return (
        <div className="mx-1.5 appearance-none justify-center rounded-md border-2 border-dashed border-gray-300 bg-white px-2 pt-2 transition  hover:border-gray-400 focus:outline-none">
            <Cropper
                stencilProps={{
                    // eslint-disable-next-line no-magic-numbers
                    aspectRatio: 16 / 9,
                }}
                ref={cropperRef}
                style={{ height: '500px', width: '500px' }}
                className="cropper h-[500px]"
                src={image}
            />
            <div className="m-2 flex justify-center gap-2">
                <label
                    className={
                        'flex cursor-pointer items-center gap-[6px] rounded-lg bg-blue-100 px-[16px] py-[8px] text-[16px] font-semibold text-blue-700 hover:bg-blue-200'
                    }
                // onClick={() => btnRemoveImagePreview('avatarBride')}
                >
                    <input
                        className="hidden"
                        onChange={(event) => changeFile(event)}
                        type="file"
                        accept="image/*"
                    />
                    <FaPen /> Thay đổi
                </label>
                <button
                    className={`${'bg-blue-600 hover:bg-blue-700 '} flex items-center gap-[6px] rounded-lg  px-[16px] py-[8px] text-[16px] font-semibold text-white disabled:bg-slate-700 `}
                    onClick={() => submitImageForm('avatarBride')}
                >
                    <FaSave /> Lưu
                </button>
            </div>
        </div>
    );
};

export default function PublishAlbum() {
    const navigate = useNavigate();
    const { eventId, url_guest: urlGuest } = useParams();
    const [formData, setFormData] = useState({});
    const [templateAlias, setTemplateAlias] = useState('');
    const [normalObj, setNormalObj] = useState({});
    const [tempObj, setTempObj] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [load, setLoad] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [listDel, setListDel] = useState([]);
    const [isOpenLeavinModal, setOpenLeavinModal] = useState(false);
    const [imageView, setImageView] = useState(-1);
    const [valueMeta, setValueMeta] = useState({
        title: '',
        thumbnail: '',
    });
    const toastLoadingId = useRef(null);
    const [isExpiredEvent, setIsExpiredEvent] = useState(false);
    const { loggedIn } = useSelector((state) => state.auth);

    const confirmForm = (event) => {
        event.preventDefault();
        setOpenLeavinModal(true);
    };
    const submitForm = async () => {
        try {
            toastLoadingId.current = ToastNotify.Loading('Đang lưu ảnh...');
            const imgList = JSON.parse(JSON.stringify(normalObj?.album));
            const listDels = [...new Set(listDel)];
            if (listDels.length > 0) {
                for (let ind = 0; ind < listDels.length; ind++) {
                    await PublishAlbumService.saveContent(formData._id, { album: listDels[ind] });
                }
            }
            // eslint-disable-next-line no-magic-numbers
            if (imgList.length > 0) {
                // console.log('imgList = ', imgList);
                const max =
                    Math.max(...imgList.map((x) => (isNaN(Number(x.id)) ? -1 : Number(x.id)))) ?? 0;
                let step = 1;
                for (let ind = 0; ind < imgList.length; ind++) {
                    const file = await PublishAlbumService.createFile(imgList[ind].image, 'album');
                    if (file !== '') {
                        await PublishAlbumService.saveFiles(
                            formData._id,
                            'album',
                            file,
                            imgList[ind].id ?? (max + step).toString(),
                            'image'
                        );
                        step++;
                    }
                }
                await PublishAlbumService.updateContent(formData._id, imgList);
            }
            setIsEdit(false);
            setLoadData(!loadData);
            ToastNotify.Update(toastLoadingId.current, 'Lưu ảnh thành công!');
            toastLoadingId.current = null;
        } catch (error) {
            setIsEdit(false);
            setLoadData(!loadData);
            ToastNotify.Update(
                toastLoadingId.current,
                error?.message || 'Đã có lỗi xảy ra. Vui lòng thử lại',
                'error'
            );
            toastLoadingId.current = null;
        }
    };
    const changeFile = async (event) => {
        // if (validateSizeOfFile(event.target.files[0], 10, 'Dung lượng ảnh không được vượt quá 10Mb')) {
        const allFile = [];
        let totalImageOverLimitSize = 0;
        const filePromises = Object.values(event.target.files).map((file) => {
            if (validateSizeOfFile(file, 10)) {
                // Return a promise per file
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = async () => {
                        try {
                            const image = reader.result;
                            resolve(allFile.push(image));
                        } catch (err) {
                            reject(err);
                        }
                    };
                    reader.onerror = (error) => {
                        reject(error);
                    };
                    reader.readAsDataURL(file);
                });
            } else {
                totalImageOverLimitSize++;
                return null;
            }
        });
        const fileInfos = await Promise.all(filePromises);
        const change = Math.max(
            ...(normalObj?.album ?? []).map((x) => (isNaN(Number(x.id)) ? -1 : Number(x.id)))
        );
        const max = normalObj?.album?.length > 0 ? change : 1;
        setNormalObj({
            ...normalObj,
            ['album']: normalObj?.album?.concat(
                allFile.map((img, idx) => ({
                    image: img,
                    id: (max + idx + 1).toString(),
                }))
            ),
        });
        if (totalImageOverLimitSize !== 0) {
            if (totalImageOverLimitSize === 1 && event.target.files.length === 1) {
                ToastNotify.Error('Dung lượng ảnh không được vượt quá 10Mb');
            } else {
                ToastNotify.Info(
                    `Đã có ${totalImageOverLimitSize} ảnh không được tải lên vì dung lượng lớn hơn 10Mb`
                );
            }
        }
        event.target.value = '';
        return fileInfos;
        // }
    };

    const btnRemoveImage = (id) => {
        setListDel(listDel.concat(id));
        // console.log(listDel);
        setNormalObj({
            ...normalObj,
            album: normalObj.album.filter((x) => id !== x.id),
        });
        setIsEdit(true);
        // console.log(normalObj.album.filter(x=>!listDel.concat(id).includes(x.id)));
    };

    const btnImageView = (image) => {
        setOpenModal(true);
        setImageView(image);
        setTempObj(normalObj);
    };

    useEffect(() => {
        if (eventId) {
            setLoad(true);
            EventsService.getOneEventById(eventId)
                .then(({ data }) => {
                    //     const { templateAlias, formData, guest, title, thumbnail, data, eventConfig
                    // } =
                    //         res;
                    const title = data.form_data.titleMeta || data.name;
                    const thumbnail = data.thumbnail_url;
                    const templateAlias = data.template.alias;
                    const formData = data.form_data;
                    const url_slug = data.url_slug;
                    // const jsonData = parseJsonData({ ...data, ...formData });
                    // setContentMeta({ title, image: thumbnail })
                    setValueMeta({
                        title,
                        thumbnail,
                        url_slug,
                    });
                    setIsExpiredEvent(data?.isExpired);
                    setTemplateAlias(templateAlias);
                    // setGuest(guest);
                    setNormalObj({
                        dateCeremony: formData.dateCeremony,
                        timeCeremony: formData.timeCeremony,
                        addressCeremony: formData.addressCeremony,
                        placeCeremony: formData.placeCeremony,
                        nameSupporter: formData.nameSupporter,
                        phoneSupporter: formData.phoneSupporter,
                        album:
                            formData?.album?.length > 0
                                ? formData.album.map((x) => ({
                                    image: process.env.REACT_APP_SERVER_URL + x.image,
                                    id: x.id,
                                    index: x?.index,
                                }))
                                : [],
                    });
                    setListDel([]);

                    setFormData(parseJsonData({ ...data, ...formData }));
                    // setEventConfig(eventConfig);
                })
                .catch((error) => {
                    const AuthorizeError = 404;
                    if (error.status === AuthorizeError) {
                        navigate(PAGE.dashboard);
                    }
                    const ServerError = 500;
                    if (error.status === ServerError) {
                        navigate(PAGE.dashboard);
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoad(false);
                    }, 500);
                });
        }
    }, [loadData, eventId, urlGuest, navigate, templateAlias]);

    useEffect(() => {
        if (Number(normalObj?.album?.length) !== Number(formData?.album?.length)) {
            setIsEdit(true);
        } else if (Number(normalObj?.album?.length) === Number(formData?.album?.length)) {
            for (let index = 0; index < formData?.album?.length; index++) {
                if (
                    normalObj?.album[index]?.id !== formData?.album[index]?.id ||
                    normalObj?.album[index]?.image !==
                    process.env.REACT_APP_SERVER_URL + formData?.album[index]?.image[0]
                ) {
                    setIsEdit(true);
                    return;
                }
            }
            setIsEdit(false);
        }
    }, [formData, normalObj]);

    function updateIndex(arr) {
        arr.forEach((item, index) => {
            item.index = index + 1;
        });
        return arr;
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(normalObj.album);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        const newArr = updateIndex(items);
        setNormalObj({
            ...normalObj,
            album: newArr,
        });
    };

    useEffect(() => {
        if (!loggedIn) {
            const beforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = true;
            };
            window.addEventListener('beforeunload', beforeUnload);
            return () => {
                window.removeEventListener('beforeunload', beforeUnload);
            };
        }
    }, []);

    return (
        <div>
            <Helmet>
                <title>{'Album ảnh' + ' | ' + valueMeta.title}</title>
                <meta property="og:image" content={valueMeta.thumbnail} />
            </Helmet>
            <div className={'h-[100dvh] w-[100vw] bg-gray-50 text-black'}>
                <div className="sticky top-0 z-[40] bg-white md:bg-gray-50 md:px-4 lg:px-6">
                    <div className="relative flex w-full flex-wrap items-center justify-between gap-x-3 p-4 md:px-0 lg:flex-nowrap lg:gap-4 lg:py-5">
                        <div className="flex md:flex-row flex-col md:gap-2">
                            <button
                                className="flex items-center"
                                type="button"
                                onClick={() => {
                                    navigate(`/${eventId}/mainmenu`);
                                }}
                                title="Trở về"
                            >
                                <i className=" fas fa-chevron-left"></i>
                                <p className="order-1 ml-3 font-bold text-black">
                                    {limitString(formData?.name)}
                                </p>
                            </button>
                            {
                                isExpiredEvent &&
                                <div className='h-5 rounded-[4px]  flex justify-center items-center bg-red-500 px-3 w-fit'>
                                    <span className='text-[9px] text-white uppercase'>
                                        {checkTypeTemplate(templateAlias)}
                                    </span>
                                </div>
                            }
                            {
                                !loggedIn &&
                                <div className='h-5 rounded-[4px]  flex justify-center items-center bg-red-500 px-3 w-fit'>
                                    <span className='text-[9px] text-white uppercase'>
                                        Bạn chưa đăng nhập
                                    </span>
                                </div>
                            }
                        </div>
                        <div className="flex justify-center gap-2 overflow-hidden ">
                            {
                                loggedIn &&
                                <Link
                                    // target="_blank"
                                    className="inline-flex select-none gap-2 rounded-md bg-white border border-blue-500 px-3 py-1 text-center font-medium text-blue-500 transition-all duration-150 ease-linear hover:bg-blue-100 xl:px-4 text-sm"
                                    to={`..${PAGE.profile}`}
                                    state={{ isResourcePackage: true }}
                                >
                                    Quản lý gói dịch vụ
                                    <FaChartPie className="my-auto" />
                                </Link>
                            }
                            <button className="inline-flex w-max select-none rounded-md bg-blue-500 px-3 py-1 text-center font-medium text-white transition-all duration-150 ease-linear hover:bg-blue-600 xl:px-4 text-sm">
                                <Link
                                    target="_blank"
                                    className="flex gap-2"
                                    to={'../' + valueMeta?.url_slug}
                                >
                                    Xem thiệp{' '}
                                    <FaExternalLinkAlt className="my-auto" />
                                </Link>
                            </button>
                        </div>
                    </div>
                    <div className="w-full border-b border-gray-300"></div>
                </div>
                <form
                    className="p-4"
                    onSubmit={confirmForm}>
                    <div className="flex w-full justify-evenly">
                        <div className="relative hidden w-[250px] lg:flex">
                            <TimelineShortcutMenu screen={'album'} />
                        </div>
                        <div className=" w-full rounded-lg border-[1px] border-slate-200 bg-white-box shadow-lg lg:w-[48rem] 2xl:w-[60rem]">
                            <div className="mx-auto w-full scroll-mt-[100px] rounded-lg bg-white pb-3 shadow md:col-span-2">
                                <div className="flex  flex-col border-b border-gray-200 p-2  px-3">
                                    <p className="sticky mb-0 text-lg font-bold text-gray-900">
                                        Bộ sưu tập ảnh
                                    </p>
                                    <QuestionTitle keyQues={'album'} />
                                </div>
                                {load && <AlbumSkeleton />}
                                {!load && (
                                    <div className="p-5 sm:p-8">
                                        {!isExpiredEvent && (
                                            <>
                                                <div className=' w-full flex justify-end'>
                                                    <label
                                                        className="hidden gap-[6px] rounded-lg bg-blue-600 px-[12px] py-[8px] text-sm font-semibold text-white hover:bg-blue-700 md:flex "
                                                    >
                                                        <FaPlus className='mr-0.5 mt-0.5' />Thêm ảnh
                                                        <input
                                                            type="file"
                                                            className="hidden"
                                                            onChange={(event) => changeFile(event, 'album')}
                                                            multiple="multiple"
                                                            accept="image/*"
                                                            disabled={isExpiredEvent}
                                                        />
                                                    </label>
                                                    <label className="block cursor-pointer gap-[6px] rounded-lg bg-blue-600 px-[12px] py-[8px] text-sm font-semibold text-white hover:bg-blue-700 md:hidden">
                                                        <FaPlus />
                                                        <input
                                                            type="file"
                                                            className="hidden"
                                                            onChange={(event) => changeFile(event, 'album')}
                                                            multiple="multiple"
                                                            accept="image/*"
                                                            disabled={isExpiredEvent}
                                                        />
                                                    </label>
                                                </div>
                                            </>
                                        )}
                                        <div className=" mt-8 ">
                                            {/* {!(normalObj?.album?.length > 0) && (
                                                <label
                                                    multiple
                                                    className="block h-[200px] w-1/3 p-4 cursor-pointer justify-center rounded-lg border-2 border-dashed border-gray-300 bg-white transition hover:border-gray-400 focus:outline-none"
                                                >
                                                    <span className="my-auto flex h-full justify-center">
                                                        <svg
                                                            className="my-auto fill-gray-400"
                                                            width="32px"
                                                            height="32px"
                                                            viewBox="0 0 24 24"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <rect
                                                                x="0"
                                                                fill="none"
                                                                width="24"
                                                                height="24"
                                                            />
                                                            <g>
                                                                <path d="M23 4v2h-3v3h-2V6h-3V4h3V1h2v3h3zm-8.5 7c.828 0 1.5-.672 1.5-1.5S15.328 8 14.5 8 13 8.672 13 9.5s.672 1.5 1.5 1.5zm3.5 3.234l-.513-.57c-.794-.885-2.18-.885-2.976 0l-.655.73L9 9l-3 3.333V6h7V4H6c-1.105 0-2 .895-2 2v12c0 1.105.895 2 2 2h12c1.105 0 2-.895 2-2v-7h-2v3.234z" />
                                                            </g>
                                                        </svg>
                                                        <span className="my-auto text-gray-400 ">
                                                            Thêm Ảnh (Giới hạn 10Mb)
                                                        </span>
                                                        <input
                                                            type="file"
                                                            className="hidden"
                                                            onChange={(event) =>
                                                                changeFile(event, 'album')
                                                            }
                                                            multiple="multiple"
                                                            accept="image/*"
                                                            disabled={isExpiredEvent}
                                                        />
                                                    </span>
                                                </label>
                                            )} */}
                                            {normalObj?.album?.length > 0 && (
                                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                                    <Droppable
                                                        droppableId="albums"
                                                        // mode="virtual"
                                                        isDropDisabled={isExpiredEvent}
                                                        direction="horizontal"
                                                        renderClone={(
                                                            provided,
                                                            snapshot,
                                                            rubric
                                                        ) => (
                                                            <div
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                                style={
                                                                    provided.draggableProps.style
                                                                }
                                                                className={
                                                                    snapshot.isDragging
                                                                        ? 'dragging'
                                                                        : ''
                                                                }
                                                            >
                                                                <div className="relative flex h-[200px] w-full justify-center rounded-xl border border-gray-300 bg-blue-100 backdrop-blur-md">
                                                                    <img
                                                                        src={
                                                                            normalObj.album[
                                                                                rubric.source.index
                                                                            ].image
                                                                        }
                                                                        className="h-[200px] w-full rounded-xl object-scale-down"
                                                                        alt="Cô dâu"
                                                                    />
                                                                    <div
                                                                        title="Kéo thả ảnh để thay đổi vị trí"
                                                                        className={
                                                                            'absolute left-1 top-0 w-[33px] cursor-grab rounded-full border bg-gray-400 p-2 text-black/70 shadow-lg hover:bg-gray-400'
                                                                        }
                                                                    >
                                                                        <FaGripVertical />
                                                                    </div>
                                                                    {!isExpiredEvent && (
                                                                        <div
                                                                            title="Xóa ảnh"
                                                                            className="absolute right-1 top-1 w-[33px] cursor-pointer rounded-full border bg-slate-100 p-2 text-black shadow-lg hover:bg-gray-400"
                                                                        >
                                                                            <svg
                                                                                width="15px"
                                                                                height="15px"
                                                                                viewBox="0 0 24 24"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    fill="none"
                                                                                    stroke="#000"
                                                                                    strokeWidth="2"
                                                                                    d="M3,3 L21,21 M3,21 L21,3"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    )}
                                                                    <div
                                                                        title="Xem chi tiết"
                                                                        className="absolute right-1 top-10 w-[33px] cursor-pointer rounded-full border bg-slate-100 p-2 font-bold text-blue-500 shadow-lg hover:bg-blue-500 hover:text-white "
                                                                    >
                                                                        <FaExpand />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        {(provided) => (
                                                            <ul
                                                                className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 md:gap-4"
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                {!isExpiredEvent && (
                                                                    <label
                                                                        multiple
                                                                        className="block h-[200px] w-full cursor-pointer justify-center rounded-lg border-2 border-dashed border-gray-300 bg-white transition hover:border-gray-400 focus:outline-none"
                                                                    >
                                                                        <span className="my-auto flex h-full flex-row justify-center text-center md:flex-col md:justify-start 2xl:flex-row 2xl:justify-center">
                                                                            <svg
                                                                                className="my-auto fill-gray-400 md:mx-auto md:mb-0 2xl:mx-0 2xl:my-auto"
                                                                                width="32px"
                                                                                height="32px"
                                                                                viewBox="0 0 24 24"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <rect
                                                                                    x="0"
                                                                                    fill="none"
                                                                                    width="24"
                                                                                    height="24"
                                                                                />
                                                                                <g>
                                                                                    <path d="M23 4v2h-3v3h-2V6h-3V4h3V1h2v3h3zm-8.5 7c.828 0 1.5-.672 1.5-1.5S15.328 8 14.5 8 13 8.672 13 9.5s.672 1.5 1.5 1.5zm3.5 3.234l-.513-.57c-.794-.885-2.18-.885-2.976 0l-.655.73L9 9l-3 3.333V6h7V4H6c-1.105 0-2 .895-2 2v12c0 1.105.895 2 2 2h12c1.105 0 2-.895 2-2v-7h-2v3.234z" />
                                                                                </g>
                                                                            </svg>
                                                                            <span className="my-auto text-gray-400 md:mt-0 2xl:my-auto">
                                                                                Thêm Ảnh (Giới hạn
                                                                                10Mb)
                                                                            </span>
                                                                            <input
                                                                                type="file"
                                                                                className="hidden"
                                                                                onChange={(event) =>
                                                                                    changeFile(
                                                                                        event,
                                                                                        'album'
                                                                                    )
                                                                                }
                                                                                disabled={
                                                                                    isExpiredEvent
                                                                                }
                                                                                multiple="multiple"
                                                                                accept="image/*"
                                                                            />
                                                                        </span>
                                                                    </label>
                                                                )}
                                                                {normalObj?.album
                                                                    ?.sort((start, end) => {
                                                                        if (
                                                                            start?.index &&
                                                                            end?.index
                                                                        ) {
                                                                            return (
                                                                                Number(
                                                                                    start.index
                                                                                ) -
                                                                                Number(end.index)
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                Number(start.id) -
                                                                                Number(end.id)
                                                                            );
                                                                        }
                                                                    })
                                                                    ?.map((item, index) => (
                                                                        <Draggable
                                                                            key={index + 10}
                                                                            draggableId={(
                                                                                index + 10
                                                                            ).toString()}
                                                                            index={index}
                                                                            isDragDisabled={
                                                                                isExpiredEvent
                                                                            }
                                                                        >
                                                                            {(provided) => (
                                                                                <li
                                                                                    className="w-full cursor-pointer"
                                                                                    ref={
                                                                                        provided.innerRef
                                                                                    }
                                                                                    {...provided.draggableProps}
                                                                                >
                                                                                    <div
                                                                                        key={index}
                                                                                        className="relative flex h-[200px] w-full justify-center rounded-xl border border-gray-300 bg-blue-100 backdrop-blur-md"
                                                                                    >
                                                                                        <img
                                                                                            onClick={() =>
                                                                                                btnImageView(
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            src={
                                                                                                item.image
                                                                                            }
                                                                                            className="max-h-[200px] w-full rounded-xl object-scale-down"
                                                                                            alt="Cô dâu"
                                                                                        />
                                                                                        <div
                                                                                            {...provided.dragHandleProps}
                                                                                            title="Kéo thả ảnh để thay đổi vị trí"
                                                                                            className={
                                                                                                'absolute left-1 top-0 w-[33px] cursor-grab rounded-full bg-slate-100 p-2 font-bold text-black/70 shadow-lg hover:bg-gray-400 '
                                                                                            }
                                                                                        >
                                                                                            <FaGripVertical />
                                                                                        </div>
                                                                                        {!isExpiredEvent && (
                                                                                            <div
                                                                                                onClick={() =>
                                                                                                    btnRemoveImage(
                                                                                                        item.id
                                                                                                    )
                                                                                                }
                                                                                                disabled={
                                                                                                    isExpiredEvent
                                                                                                }
                                                                                                title="Xóa ảnh"
                                                                                                className="absolute right-1 top-1 w-[33px] cursor-pointer rounded-full border bg-slate-100 p-2 shadow-lg hover:bg-gray-400"
                                                                                            >
                                                                                                <svg
                                                                                                    width="15px"
                                                                                                    height="15px"
                                                                                                    viewBox="0 0 24 24"
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                >
                                                                                                    <path
                                                                                                        fill="none"
                                                                                                        stroke="#000"
                                                                                                        strokeWidth="2"
                                                                                                        d="M3,3 L21,21 M3,21 L21,3"
                                                                                                    />
                                                                                                </svg>
                                                                                            </div>
                                                                                        )}
                                                                                        <div
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target="#modalImageViewAlbum"
                                                                                            title="Xem ảnh"
                                                                                            onClick={() =>
                                                                                                btnImageView(
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            className={`absolute ${isExpiredEvent
                                                                                                ? 'top-1'
                                                                                                : 'top-10'
                                                                                                } right-1 w-[33px] cursor-pointer rounded-full border bg-slate-100 p-2 font-bold text-blue-500 shadow-lg hover:bg-blue-500 hover:text-white `}
                                                                                        >
                                                                                            <FaExpand />
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                            </ul>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {!load && !(normalObj?.album?.length > 0) && (
                                    <div className="flex w-full flex-col items-center justify-center">
                                        <img
                                            src={IMAGES.ALBUM}
                                            alt="not found"
                                            className="mx-auto w-1/3 opacity-70"
                                        />
                                        <h4 className="mt-5 text-center text-xl text-blue-900 ">
                                            Hãy bổ sung những bức ảnh tuyệt nhất vào Album ảnh cưới
                                            nhé!
                                        </h4>
                                        <p className="mt-2 text-center text-sm text-black">
                                            Đừng quên cập nhật ảnh vào album để chia sẻ những khoảnh
                                            khắc đặc biệt của bạn với mọi người nhé!{' '}
                                            {!isExpiredEvent && <label className="inline-block cursor-pointer font-bold text-blue-500 underline">
                                                Thêm ảnh ngay
                                                <input
                                                    type="file"
                                                    className="hidden"
                                                    onChange={(event) => changeFile(event, 'album')}
                                                    disabled={isExpiredEvent}
                                                    multiple="multiple"
                                                    accept="image/*"
                                                />
                                            </label>}
                                        </p>
                                    </div>
                                )}
                                {!isExpiredEvent && (
                                    <div
                                        className={
                                            (isEdit
                                                ? 'bottom-0 opacity-100'
                                                : '-bottom-80 opacity-0') +
                                            ' fixed inset-x-0 z-[40] bg-gray-50 transition-all duration-500 md:px-6 lg:z-[40]'
                                        }
                                    >
                                        <div className="flex items-center justify-between gap-3 border-t border-gray-300 px-6 py-4 md:px-0">
                                            <p>Chưa lưu !</p>
                                            <div className="flex gap-3">
                                                <button
                                                    type="button"
                                                    className={
                                                        'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-100 px-6 py-2 text-center font-semibold text-blue-500 transition-all duration-150 ease-linear hover:bg-indigo-200 '
                                                    }
                                                    onClick={() => {
                                                        setIsEdit(false);
                                                        setLoadData(!loadData);
                                                    }}
                                                >
                                                    <FaTimes />
                                                    Hủy bỏ
                                                </button>
                                                <button
                                                    type="submit"
                                                    className={
                                                        'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-500 px-6 py-2 text-center font-semibold text-white transition-all duration-150 ease-linear hover:bg-blue-600 '
                                                    }
                                                >
                                                    <FaSave />
                                                    Hoàn tất
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <ModalLeavingPage
                                    saveChanged={submitForm}
                                    isOpen={isOpenLeavinModal}
                                    confirmNavigation={() => {
                                        setOpenLeavinModal(false);
                                    }}
                                    key={formData._id}
                                    funToggle={() => {
                                        setOpenLeavinModal(false);
                                    }}
                                />
                                <ImageView
                                    key={imageView}
                                    index={imageView}
                                    albumList={normalObj?.album}
                                    open={openModal}
                                    handleClose={() => setOpenModal(false)}
                                />
                            </div>
                        </div>
                        <div className="relative hidden w-[250px] lg:flex">
                            <Products />
                        </div>
                    </div>
                </form>
                {/* <ShortcutMenu screen={'album'} /> */}
            </div>
        </div>
    );
}
