/* eslint-disable */
import Cookies from 'js-cookie';
import axios from 'axios';
import envConstants from '../config/key';
import storageConstant from '../config/constant/storage.constant';
import { toast } from 'react-toastify';
import userService from '../views/user/User.service';
import ToastNotify from '../utils/Toast';
import { store } from '..';
import userConstants from '../config/redux/user.redux';

toast.configure();

const instance = axios.create({
    baseURL: envConstants.API_URL,
    // withCredentials: true
});

instance.interceptors.request.use(
    async (config) => {
        const token = Cookies.get(storageConstant.ACCESS_TOKEN);
        config.headers.Authorization = token ? token : '';
        config.headers.PathName = window.location.pathname;
        config.headers.Search = window.location.search;
        if (config.headers['Content-Type'] === 'multipart/form-data') {
            for (const pair of config.data.entries()) {
                if (['value', 'image', 'file_0'].includes(pair[0]) && pair[1]?.size) {
                    config.headers.filesize = pair[1].size / (1024 * 1024);
                }
            }
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        // Thrown error for request with OK status code
        const { data } = response;
        const originalRequest = response.config;

        const isExpAccessToken = data.code === 401 && data.data === 'isUser';
        if (isExpAccessToken) {
            console.log('is user');
            // Is auto call get new access and refresh token
            if (data.isAuto) {
                originalRequest._retry = true;
                return userService
                    .getNewAccessAndRefreshToken()
                    .then(({ accessToken }) => {
                        // Set Authorization Header new value
                        axios.defaults.headers.common['Authorization'] = accessToken;
                        return instance(originalRequest);
                    })
                    .catch((error) => {
                        const guestUser = localStorage.getItem(storageConstant.GUEST_USER);
                        localStorage.clear();
                        if (guestUser) {
                            localStorage.setItem(storageConstant.GUEST_USER, guestUser);
                        }
                        const logout = () => ({
                            type: userConstants.LOGOUT,
                            payload: {},
                        });
                        store.dispatch(logout());
                        Cookies.remove(storageConstant.ACCESS_TOKEN);
                        Cookies.remove(storageConstant.REFRESH_TOKEN);
                        const tokenExpired = () => ({
                            type: userConstants.REFRESH_TOKEN_FAILURE,
                            payload: {},
                        });
                        store.dispatch(tokenExpired());
                        // ToastNotify.Error('Phiên đăng nhập đã hết hạn vui lòng đăng nhập lại!', 'top-left')
                        return Promise.reject(error);
                    });
            }
        }

        return response;
    },
    (error) => {
        if (error.code === 'ECONNABORTED') {
            return Promise.reject(error);
        }
        const { response } = error;
        const { data } = response;
        if (data?.message) {
            const error = new Error(data.message);
            error.status = response.status;
            error.data = data.data;
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
);

export default instance;
