/* eslint-disable */

import EventTemplate from '../../../../api/event.template.api';
import Events from '../../../../api/events.api';
import { checkIsExpired } from '../../../events/Events.service';

const guestManagerService = {
    getEvent: async (id) =>
        Events.getOne(id)
            .then(({ data }) => Promise.resolve(data.data))
            .catch(({ err }) => Promise.resolve(err)),
    getEventTemplateById: async (id) =>
        EventTemplate.getOne(id)
            .then(({ data }) => {
                let dataNew = data.data;
                const isExpired = checkIsExpired(data.data);
                dataNew.isExpired = isExpired;
                return Promise.resolve(dataNew);
            })
            .catch((err) => {
                Promise.reject(err);
            }),
    getEventTemplateByEventId: async (id) =>
        EventTemplate.getAll({ event_id: id, includes: ['template_id', 'event_id'] })
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => {
                Promise.reject(err);
            }),
    getAllGuests: async (query) => {
        return Events.getGuests({
            event_id: query.event_id,
            ...(query.vip && { vip: query.vip }),
            ...(query.search && { fullname: query.search }),
            ...(query.status && { status: query.status }),
            ...(query.verify_status && { verify_status: query.verify_status }),
            ...(query.group && { group: query.group }),
            ...(query.method && { method: query.method }),
            ...(query.all_status && { all_status: query.all_status }),
            ...(query.guest_invite && { guest_invite: query.guest_invite }),
            page: query.pageNow,
            limit: query.limit || 10,
        })
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err));
    },
    getAllByGuest: async (query) =>
        Events.getAllByGuest({
            event_id: query.event_id,

            ...(query.search && { fullname: query.search }),

            ...(query.group && { group: query.group }),

            page: query.pageNow,
            limit: 10,
        })
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),
    Register: (payload) =>
        Events.register(payload)
            .then(() => Promise.resolve())
            .catch((err) => Promise.reject(err)),
    VerifyGuest: (payload) =>
        Events.verifyGuest(payload)
            .then(() => Promise.resolve())
            .catch((err) => Promise.reject(err)),
    getGuestsByPaginate: async (id, page) => {},
    getGuestsBySlug: async (slug) =>
        Events.getGuestsBySlug({ slug: slug })
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => {
                // window.location.replace('/');
                return Promise.reject(err);
            }),
    sendInvition: async (event_id, ids = [], timer = '') =>
        Events.sendInvition({
            event_id: event_id,
            ...(ids.length != 0 && { guest_ids: ids }),
            ...(timer && { timer }),
        })
            .then(() => Promise.resolve())
            .catch((err) => Promise.reject(err)),
    sendInvitionWithTimer: async (event_id, guest_ids = [], timer = '', options = '') =>
        Events.sendInvition({
            event_id: event_id,
            ...(guest_ids.length != 0 && { guest_ids }),
            ...(timer && { timer }),
            ...(options && { options }),
        })
            .then(() => Promise.resolve())
            .catch((err) => Promise.reject(err)),
    changeTimer: async (eventId, guestId, timer, applyFor) =>
        Events.changeTimer({
            eventId,
            guestId,
            timer,
            applyFor,
        })
            .then(() => Promise.resolve())
            .catch((err) => Promise.reject(err)),
    sendInvitionToAll: async (event_id, options = '', status = '', fullname = '', vip = '') =>
        Events.sendInvition({
            event_id: event_id,
            ...(options != '' && { options: options }),
            ...(status != '' && { status: status }),
            ...(fullname != '' && { fullname: fullname }),
            ...(vip != '' && { vip: vip }),
        })
            .then(() => Promise.resolve())
            .catch((err) => Promise.reject(err)),
    feedback: async (id, feedback) =>
        Events.feedback(id, {
            status: feedback.status,
            ...(feedback.message && { message: feedback.message }),
        })
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),
    groupFeedback: async (id, feedback) =>
        Events.groupFeedback(id, {
            status: feedback.status,
            ...(feedback.message && { message: feedback.message }),
        })
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),
    deleteManyGuest: async (payload) =>
        Events.deleteManyGuest({
            event_id: payload.event_id,
            ...(payload.ids && { ids: payload.ids }),
            ...(payload.status != '' && { status: payload.status }),
            ...(payload.fullname != '' && { fullname: payload.fullname }),
            ...(payload.vip != '' && { vip: payload.vip }),
            ...(payload.group != '' && { group: payload.group }),
            ...(payload.verify_status != '' && { verify_status: payload.verify_status }),
            ...(payload.all_status && { all_status: payload.all_status }),
            ...(payload.guest_delete && { guest_delete: payload.guest_delete }),
        })
            .then(() => Promise.resolve())
            .catch((err) => Promise.reject(err)),
    deleteManyByGuest: async (payload) =>
        Events.deleteManyByGuest({
            event_id: payload.event_id,
            ...(payload.ids && { ids: payload.ids }),
        })
            .then(() => Promise.resolve())
            .catch((err) => Promise.reject(err)),
    getGuestById: async (id) =>
        Events.findGuest(id)
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),
    statistic: (query) =>
        Events.statistic({
            eventId: query.event_id,
            group: query.group,
            guest_invite: query.guest_invite,
        })
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),
    getAllGroups: (query) =>
        Events.getGroups({
            event_id: query.event_id,
            ...(query.vip && { vip: query.vip }),
            ...(query.search && { fullname: query.search }),
            ...(query.status && { status: query.status }),

            page: query.pageNow,

            limit: 100,
        })
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),
    getGroups: (query) =>
        Events.getAllGroups({
            event_id: query.event_id,
            ...(query.fullname && { fullname: query.fullname }),
            ...(query.notIn && { notIn: query.notIn }),
        })
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),
    findGroup: (id) =>
        Events.findGroup(id)
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),
    createGroup: (payload) =>
        Events.createGroup(payload)
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),
    updateGroup: (id, payload) =>
        Events.updateGroup(id, payload)
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),
    deleteGroup: (id) => {
        console.log(id);
        return Events.deleteGroup(id)
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err));
    },
    deleteManyGroup: async (event_id, ids = null, status = '', fullname = '', vip = '') =>
        Events.deleteManyGroup({
            event_id: event_id,
            ...(ids && { ids: ids }),
            ...(status != '' && { status: status }),
            ...(fullname != '' && { fullname: fullname }),
            ...(vip != '' && { vip: vip }),
        })
            .then(() => Promise.resolve())
            .catch((err) => Promise.reject(err)),
    sendInvitionToGroup: async (event_id, ids = []) =>
        Events.sendInvitionToGroup({
            event_id: event_id,
            ...(ids.length != 0 && { group_ids: ids }),
        })
            .then(() => Promise.resolve())
            .catch((err) => Promise.reject(err)),
    sendInvitionToAllToGroup: async (
        event_id,
        options = '',
        status = '',
        fullname = '',
        vip = ''
    ) =>
        Events.sendInvitionToGroup({
            event_id: event_id,
            ...(options != '' && { options: options }),
            ...(status != '' && { status: status }),
            ...(fullname != '' && { fullname: fullname }),
            ...(vip != '' && { vip: vip }),
        })
            .then(() => Promise.resolve())
            .catch((err) => Promise.reject(err)),
    groupStatistic: (id) =>
        Events.groupStatistic(id)
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),
    removeManyGuestFromGroup: (payload) =>
        Events.removeManyGuestFromGroup(payload)
            .then(({ data }) => Promise.resolve())
            .catch((err) => Promise.reject(err)),
    getCompareData: (event_id) =>
        Events.getCompareData(event_id)
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),
    getEmailTemplate: (id, alias) =>
        Events.getTemplateEmail(id, alias)
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),
    saveNewContentEmail: (id, type, content, subject = '') => {
        const payload = { type: type, content: content, subject };
        return Events.saveNewContent(id, payload)
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err));
    },
    deleteContentEmail: (id, type) =>
        Events.deleteContent(id, type)
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err)),
    testEmail: (id, type, content, email_to, subject = '') => {
        const payload = {
            event_id: id,
            type: type,
            content: content,
            email_to: email_to,
            ...(subject && { subject }),
        };
        return Events.testEmail(payload)
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err));
    },
    countGuest: (eventId) => {
        return Events.count(eventId)
            .then(({ data }) => Promise.resolve(data.data))
            .catch((err) => Promise.reject(err));
    },
    exportGuestQR: async (eventId, page, limit, guestSelect = []) => {
        try {
            const { data } = await Events.exportQR(eventId, page, limit, guestSelect);
            return await Promise.resolve(data.data);
        } catch (err) {
            return await Promise.reject(err);
        }
    },
};
export default guestManagerService;
