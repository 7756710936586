/* eslint-disable */
import userConstants from '../../config/redux/user.redux';

const user = localStorage.getItem('user');
const initialState = {
    loggedIn: !!user,
    user: user ? JSON.parse(user) : {},
    isTokenExpired: false,
};

function authReducer(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                loggedIn: false,
                user: {},
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                user: action.payload,
                isTokenExpired: false,
            };
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                loggedIn: false,
                user: {},
            };
        case userConstants.UPDATE_USER_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                // user: action.payload,
                user: {
                    ...state.user,
                    ...action.payload,
                },
            };
        case userConstants.LOGOUT:
            return {
                ...state,
                loggedIn: false,
                user: {},
                isTokenExpired: false,
            };
        case userConstants.REFRESH_TOKEN_FAILURE:
            return {
                ...state,
                isTokenExpired: true,
            };
        default:
            return state;
    }
}

export default authReducer;
