/* eslint-disable */
import { Link, useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import PAGE from '../../../config/path.route';
import userService from '../../user/User.service';
import ToastNotify from '../../../utils/Toast';
import LoadingButtonIcon from '../../../components/LoadingButton/LoadingButtonIcon';

const FormConfirm = ({ user, setMode, changeMode, handleSendEmailVerify, isLoading }) => (
    <div className="mx-auto w-full md:mx-0 max-w-[700px] lg:min-w-[500px] form-confirm ">
        <div className="flex flex-col w-full p-5 bg-white max-md:rounded-b-xl md:rounded-r-xl md:p-10">
            <h2 className="mb-5 text-2xl font-bold text-center text-gray-800">
                Xác nhận tài khoản
            </h2>

            <div id="input" className="flex flex-col w-full my-5">
                <div
                    className={
                        'relative mx-auto inline-flex h-20 w-20 items-center justify-center  overflow-hidden rounded-full bg-[#7265e6] text-center'
                    }
                >
                    {
                        user?.avatarUrl
                            ? <img
                                src={user.avatarUrl}
                                className="mx-auto h-full w-full rounded-full object-cover"
                                alt="Avatar"
                            />
                            : <span className="text-3xl font-medium text-white ">
                                {user.user_name.split('').slice(0, 2).join('').toUpperCase()}
                            </span>
                    }
                </div>
                <p className="mx-auto mt-3 text-2xl font-bold text-black">{user.user_name}</p>
            </div>

            <div className="flex flex-col w-full my-5">
                <button
                    disabled={isLoading}
                    className="w-full rounded-lg bg-gradient-to-r from-[#51ADF5] to-[#2196F3] py-4 text-white hover:from-[#2196F3] hover:to-[#51ADF5]  hover:shadow-lg "
                    onClick={() => {
                        handleSendEmailVerify(user.email);
                    }}
                >
                    <div className="flex flex-row items-center justify-center">
                        {isLoading && <LoadingButtonIcon />}
                        <div className="font-bold text-white capitalize">
                            Gửi email xác nhận đổi mật khẩu
                        </div>
                    </div>
                </button>
                <button
                    className="w-full py-4 mt-5 text-white bg-gray-300 rounded-lg hover:shadow-lg "
                    // onClick={formikForgotPassword.handleSubmit}
                    onClick={() => {
                        setMode('forgot-password');
                        changeMode();
                    }}
                >
                    <div className="flex flex-row items-center justify-center">
                        <div className="font-bold text-gray-700 capitalize">
                            Đây không phải tài khoản của tôi
                        </div>
                    </div>
                </button>
            </div>
        </div>
    </div>
);

export default FormConfirm;
