/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
import { useEffect, useReducer, useRef, useState } from 'react';
import IMAGES from '../../assets/images/_index';
// import IMAGES_WEDDING from '../../resource/wedding/images/_index';
import PublishMainMenuService from './PublishMainMenu.service';
// import Templates from '../events/components/Templates';
import { useParams, useNavigate, Link } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import themeCommon, { menuList } from './PublishMainMenu.config';
import img from '../../assets/images/icons8-party-96.png';
// import { setContentMeta } from '../../utils/MetaTag';
// import { Helmet } from 'react-helmet'
import { Helmet } from 'react-helmet-async';
import PAGE from '../../config/path.route';
import EventsService from '../events/Events.service';
import { FaExternalLinkAlt, FaChartPie, FaInfoCircle, FaEdit, FaPen } from 'react-icons/fa';
import eventLogo from '../../assets/images/event.png';
import { useDispatch, useSelector } from 'react-redux';
import ModalManagement from '../../components/ModalManagement/ModalManagement';
import modalAction from '../../redux/modal/modal.action';
import PublishMainMenuLoading from './components/PublishMainMenuLoading';
import NewTemplate from './publish.mainmenu.detail/publish.news/newTemplateWedding4/NewTemplate';
import Premium from './publish.mainmenu.detail/publish.news/premium/Premium';
import authService from '../../services/auth.service';
import ToastNotify from '../../utils/Toast';
import EventGreetingService from './publish.mainmenu.detail/publish.greeting/PublishGreeting.service';
import ReactTooltip from 'react-tooltip';
import QuestionModal from './publish.mainmenu.detail/publish.question/QuestionModal';
import PublicModal from './publish.mainmenu.detail/pushish.config/component/PublicModal';
import UserEventsService from '../user.events/User.events.service';
import InputValidatePublic from './publish.mainmenu.detail/pushish.config/component/InputValidatePublic';
import ButtonUpgradeVip from '../user/components/ButtonUpgradeVip';
import ModalUpgradePackage from '../user/components/ModalUpgradePackage';

const limitString = (str) => {
    if (!str) {
        return '';
    }
    if (str.length > 35) {
        return str.substring(0, 35) + '...';
    }
    return str;
};

const parseJsonData = (formData) => {
    // parse json
    const dataCopy = formData;
    let arrParse = [];
    let arrCopy = [];
    let keyName = '';
    // infoParty
    if (dataCopy?.infoParty) {
        arrCopy = dataCopy.infoParty;
        keyName = 'infoParty';
    } else {
        //  programes
        arrCopy = dataCopy.programes;
        keyName = 'programes';
    }
    if (Array.isArray(arrCopy)) {
        for (var idx = 0; idx < arrCopy.length; idx++) {
            if (typeof arrCopy[idx] === 'string') {
                arrParse.push(JSON.parse(arrCopy[idx]));
            } else {
                arrParse.push(arrCopy[idx]);
            }
        }
    } else {
        if (arrCopy) {
            arrParse.push(JSON.parse(arrCopy));
        }
    }
    return { ...dataCopy, [keyName]: arrParse };
};

export const INIT_GET_NEW_GREETING = {
    total: 0,
};
export function newGreetingReducer(state, action) {
    const { type, payload } = action;
    switch (type) {
        case 'get':
            return { ...state, ...payload };
        default:
            return state;
    }
}

export default function PublishMainMenu() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const [today, setToday] = useState(null);
    const { eventId, url_guest: urlGuest } = useParams();
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(true);
    const [isExpiredEvent, setIsExpiredEvent] = useState(false);
    const btnOpenSubmitPublic = useRef();
    const [isPublic, setIsPublic] = useState(formData?.is_public);
    const btnOpenModal = useRef();
    const [isOpenModal, setIsOpenModal] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [newGreeting, dispatchNewGreeting] = useReducer(
        newGreetingReducer,
        INIT_GET_NEW_GREETING
    );

    // eslint-disable-next-line no-unused-vars
    const [stats, setStats] = useState({
        hits: 0,
        // eslint-disable-next-line camelcase
        num_confirm_attend_no: 0,
        // eslint-disable-next-line camelcase
        num_confirm_attend_total: 0,
        // eslint-disable-next-line camelcase
        num_confirm_attend_yes: 0,
        // eslint-disable-next-line camelcase
        num_guests: 1,
    });
    const [greetingStats, setGreetingStats] = useState({
        total: 0,
        // eslint-disable-next-line camelcase
        reply: 0,
        // eslint-disable-next-line camelcase
        not_public: 0,
    });
    const [templateAlias, setTemplateAlias] = useState('');
    // const [guest, setGuest] = useState(undefined);
    // const [eventConfig, setEventConfig] = useState();
    const [valueMeta, setValueMeta] = useState({
        title: '',
        thumbnail: '',
        imageurl: '',
    });

    useEffect(() => {
        setLoading(true);
        setToday(
            new Date().toLocaleDateString('vi-VN', {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
            })
        );
        if (eventId) {
            EventsService.getOneEventById(eventId)
                .then(async ({ data }) => {
                    setIsExpiredEvent(data?.isExpired);
                    const title = data.form_data.titleMeta || data.title || 'Chung Vui';
                    const thumbnail = data.thumbnail_url;
                    const imageurl = data.image_url;
                    const templateAlias = data.template.alias;
                    const formData = data.form_data;

                    setValueMeta({
                        title,
                        thumbnail,
                        imageurl,
                    });
                    setTemplateAlias(templateAlias);
                    const newForm = parseJsonData({ ...data, ...formData });
                    setFormData(newForm);

                    const statsInfor = await PublishMainMenuService.getStatic(newForm?._id);
                    setStats(JSON.parse(statsInfor));
                })
                .catch((error) => {
                    const AuthorizeError = 404;
                    if (error.status === AuthorizeError) {
                        navigate(PAGE.dashboard);
                    }
                    const ServerError = 500;
                    if (error.status === ServerError) {
                        navigate(PAGE.dashboard);
                    }
                })
                .finally(() => setLoading(false));
            // get new greetings
            EventGreetingService.getNew(eventId)
                .then(({ data }) => {
                    dispatchNewGreeting({
                        type: 'get',
                        payload: { total: data?.length || 0 },
                    });
                })
                .catch((err) => console.log(err));
            EventGreetingService.analysis(eventId)
                .then(({ data }) => {
                    setGreetingStats(data);
                })
                .catch((err) => console.log(err));
        }
    }, [eventId, urlGuest, navigate, templateAlias]);

    const showLoginForm = async (event) => {
        event.preventDefault();
        await dispatch(modalAction.openModalLogin());
    };

    const verifyAccount = () => {
        authService
            .handleVerifyEmail()
            .then(() => {
                ToastNotify.Success(
                    // eslint-disable-next-line max-len
                    'Hệ thống đã gửi email xác thực tài khoản cho bạn. Vui lòng kiểm tra và làm theo hướng dẫn!'
                );
            })
            .catch((error) => {
                ToastNotify.Error(
                    error.message || 'Đã có lỗi xảy ra. Vui lòng thử lại sau ít phút.'
                );
            });
    };

    useEffect(() => {
        if (!auth?.loggedIn) {
            const beforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = true;
            };
            window.addEventListener('beforeunload', beforeUnload);
            return () => {
                console.log('removeEventListener');
                window.removeEventListener('beforeunload', beforeUnload);
            };

            // window.onbeforeunload = function (event) {
            //     event.preventDefault();
            //     event.returnValue = 'You have unsaved changes!';
            //     return event;
            // };
        }
    }, []);

    const handleCheckPublic = () => {
        UserEventsService.checkExport(eventId)
            .then(({ data }) => {
                btnOpenSubmitPublic.current.click();
            })
            .catch(async (err) => {
                if (err?.status === 402) {
                    await dispatch(modalAction.openModalLogin());
                } else if (err?.status === 400) {
                    btnOpenModal.current.click();
                    setIsOpenModal(!isOpenModal);
                }
                ToastNotify.Error(err.message);
            });
    };
    const handlePublic = () => {
        UserEventsService.shareEventTemplate(eventId, {
            isPublic: !formData.is_public,
        })
            .then(async ({ data }) => {
                if (data.code === 200) {
                    console.log(data.data);
                    const newformData = data.data.event.form_data;

                    // setValueMeta({
                    //     title,
                    //     thumbnail,
                    //     imageurl,
                    // });
                    // setTemplateAlias(templateAlias);
                    const newForm = parseJsonData({ ...data.data.event, ...newformData });
                    setFormData({ ...formData, ...newForm });
                    setIsPublic(data.data.event.is_public);
                    // const statsInfor = await PublishMainMenuService.getStatic(newForm?._id);
                    // setStats(JSON.parse(statsInfor));
                    if (!data.data.event.is_public) {
                        ToastNotify.Success('Đã hủy chia sẻ công khai');
                    } else {
                        ToastNotify.Success('Đã chia sẻ công khai');
                    }
                }
            })
            .catch(async (err) => {
                if (err?.status === 402) {
                    await dispatch(modalAction.openModalLogin());
                } else if (err?.status === 400) {
                    btnOpenModal.current.click();
                    setIsOpenModal(!isOpenModal);
                }
                ToastNotify.Error(err.message);
            });
    };

    const [keyQues, setKeyQues] = useState('main_info_wed');

    return (
        <div>
            <Helmet>
                <title>{valueMeta.title + ' | ' + 'Trang chủ'}</title>
                <meta property="og:image" content={valueMeta.thumbnail} />
            </Helmet>
            <div className={'h-[100dvh] w-[100vw] '}>
                <div className="sticky top-0 z-[40] bg-white md:bg-gray-50">
                    {!auth?.loggedIn && (
                        <div className="sticky top-0 z-[50] bg-gradient-to-r from-blue-600 via-blue-700 to-gray-800 p-3 text-center text-sm text-white md:text-lg">
                            Bạn chưa đăng nhập nên thiệp của bạn chưa được lưu.{' '}
                            <button
                                className="cursor-pointer font-bold underline"
                                onClick={showLoginForm}
                            >
                                Đăng nhập
                            </button>{' '}
                            ngay để lưu lại và tiếp tục tạo thiệp nhé!
                        </div>
                    )}
                    {isExpiredEvent && (
                        <div className="sticky top-0 z-[50] bg-gradient-to-r from-orange-400 to-rose-400 px-6 py-1 text-center text-xl text-white">
                            <div className="flex justify-around">
                                <img src={img} alt="icon" className="my-auto size-16" />
                                <div className="my-auto text-center">
                                    <div className="mb-2 animate-autozoom text-xl font-bold">
                                        Chúc mừng!{' '}
                                        {templateAlias.includes('wedding') ? 'Đám cưới' : 'Sự kiện'}{' '}
                                        của bạn đã diễn ra thành công.
                                    </div>
                                    <div className="text-sm">
                                        Để giữ lại những khoảnh khắc tuyệt vời, hãy nhớ rằng thiệp
                                        mời của bạn không thể xóa hoặc chỉnh sửa được nữa!
                                    </div>
                                </div>
                                <img
                                    src={img}
                                    alt="icon"
                                    className="my-auto size-16 scale-x-[-1]"
                                />
                            </div>
                        </div>
                    )}
                    {auth?.loggedIn && auth.user.status === 'unverified' && (
                        // eslint-disable-next-line
                        <div className="sticky top-0 z-[9998] bg-gradient-to-r from-pink-500 to-yellow-500 p-3 text-center text-sm md:text-lg text-white">
                            Tài khoản của bạn chưa được xác thực. Hãy{' '}
                            <button
                                className="cursor-pointer font-bold underline"
                                onClick={verifyAccount}
                            >
                                xác thực tài khoản
                            </button>{' '}
                            để sử dụng đầy đủ tính năng của ChungVui nhé!.
                        </div>
                    )}
                    <div className="relative flex w-full flex-wrap items-center gap-x-3 p-4 md:px-4 lg:flex-nowrap lg:gap-4 lg:px-6 lg:py-5">
                        <div className="flex flex-col items-start gap-3 md:flex-row">
                            <Link
                                to={!auth?.loggedIn ? '/' : '/thiep-cua-toi'}
                                className="flex items-center"
                            >
                                {!auth?.loggedIn ? (
                                    <p className="order-1 mr-6 font-bold text-black">Trang chủ</p>
                                ) : (
                                    <>
                                        <i className=" fas fa-chevron-left"></i>
                                        <p className="order-1 ml-3 font-bold text-black">
                                            Thiệp của tôi
                                        </p>
                                    </>
                                )}
                            </Link>
                            <div className="my-auto text-sm md:hidden">{today}</div>
                        </div>
                        {auth?.loggedIn ? (
                            <div className="order-2 flex flex-1 items-stretch justify-end gap-4 lg:order-3">
                                <div className="my-auto hidden md:block">{today}</div>
                                <div className="border-t border-gray-300 sm:border-s sm:border-t-0"></div>
                                <Link
                                    to="/"
                                    className="flex gap-2 rounded-md bg-indigo-100 px-6 py-1 text-[14px] font-semibold text-indigo-700 transition-all hover:bg-indigo-200"
                                >
                                    <i className="fas fa-home my-auto"></i> Trang chủ
                                </Link>
                            </div>
                        ) : (
                            <div className="order-2 flex flex-1 items-center justify-end gap-4 lg:order-3">
                                <div className="my-auto hidden md:block">{today}</div>
                                <button
                                    onClick={showLoginForm}
                                    className="flex gap-2 rounded-md bg-indigo-100 px-6 py-1 text-[14px] font-semibold text-indigo-700 transition-all hover:bg-indigo-200"
                                >
                                    <i className="fas fa-user my-auto"></i> Đăng nhập
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="w-full border-b border-gray-300"></div>
                </div>
                {loading ? (
                    <PublishMainMenuLoading />
                ) : (
                    <div className="relative overflow-x-hidden px-[1.5rem] py-[1rem]">
                        <div className="fixed left-10 hidden 3xl:flex">
                            <NewTemplate templateAlias={templateAlias} />
                        </div>
                        <div className="fixed right-10 hidden 3xl:flex">
                            <Premium templateAlias={templateAlias} />
                        </div>
                        <div className="mx-auto flex max-w-6xl flex-col gap-6">
                            {/* <div className="flex flex-col">
                                <p className={'text-left text-4xl font-bold text-gray-800'}>
                                    Chào {auth?.user?.user_name ?? 'bạn'},
                                </p>
                                <p className="text-[17px] text-gray-500">{today}</p>
                            </div> */}
                            <div className="mx-auto w-full grid-cols-10 flex-col items-stretch gap-5 min-[0px]:flex md:grid min-[768px]:grid">
                                <div className="relative z-30 flex h-max w-full flex-col items-center overflow-hidden rounded-3xl pt-2 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] sm:col-span-6">
                                    <div className="relative flex size-full flex-row pl-4 pt-1">
                                        <p className="text-wrap break-words text-xl font-semibold text-black/70">
                                            {formData?.name}
                                        </p>
                                    </div>
                                    <div className="relative flex size-full min-h-[160px] flex-row p-3">
                                        {formData?.avatarBride && (
                                            <Link
                                                className="w-2/5 "
                                                data-tip
                                                data-for="helper-bride"
                                                to={'../' + formData?.id + '/bride'}
                                            >
                                                <div
                                                    className="mx-auto w-full max-w-[120px] rounded-full px-1 md:px-0"
                                                    to={'../' + formData?.id + '/bride'}
                                                >
                                                    <div className="group relative">
                                                        <img
                                                            data-tip
                                                            data-for="helper-bride"
                                                            className="mx-auto w-full max-w-[120px] rounded-full object-cover"
                                                            alt={formData?.nameBride}
                                                            src={
                                                                // eslint-disable-next-line no-magic-numbers
                                                                formData?.avatarBride.length > 0
                                                                    ? process.env
                                                                          .REACT_APP_SERVER_URL +
                                                                      formData?.avatarBride
                                                                    : IMAGES.CO_DAU_4
                                                            }
                                                        />
                                                        <ReactTooltip
                                                            id="helper-bride"
                                                            place="bottom"
                                                            type="dark"
                                                            border={true}
                                                            borderColor="tranparent"
                                                            // effect="solid"
                                                        >
                                                            <p>
                                                                Xem thông tin chi tiết cô dâu{' '}
                                                                {' ' + formData?.nameBride}
                                                            </p>
                                                        </ReactTooltip>
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        ' mx-auto mt-2 w-full break-words text-center text-lg font-semibold text-red-500 md:text-xl'
                                                    }
                                                >
                                                    {limitString(formData?.nameBride)}{' '}
                                                    <div className="ml-1 inline-block no-underline">
                                                        <FaPen className="size-4 text-slate-500 hover:text-slate-600" />
                                                    </div>
                                                </div>
                                            </Link>
                                        )}
                                        {formData?.avatarBride && formData?.avatarGroom && (
                                            <div className="relative  mt-9 flex h-max w-1/3 items-center justify-center">
                                                <i
                                                    className={
                                                        themeCommon[templateAlias]?.component
                                                            ?.icon +
                                                        ' absolute text-[50px] animate-ping opacity-75'
                                                    }
                                                ></i>
                                                <i
                                                    className={
                                                        themeCommon[templateAlias]?.component
                                                            ?.icon +
                                                        ' relative inline-flex text-[48px]'
                                                    }
                                                ></i>
                                            </div>
                                        )}
                                        {formData?.logo && (
                                            <div className="mx-auto h-max w-1/5 min-w-[100px] max-w-[160px] object-cover">
                                                <img
                                                    alt={formData?.nameBride}
                                                    src={
                                                        formData?.logo[0]
                                                            ? process.env.REACT_APP_SERVER_URL +
                                                              formData?.logo[0]
                                                            : eventLogo
                                                    }
                                                />
                                            </div>
                                        )}

                                        {formData?.avatarGroom && (
                                            <>
                                                <Link
                                                    className="w-2/5 "
                                                    data-tip
                                                    data-for="helper-payment"
                                                    to={'../' + formData?.id + '/groom'}
                                                >
                                                    <div className="mx-auto w-full max-w-[120px] rounded-full px-1 md:px-0">
                                                        <div className="group relative">
                                                            <img
                                                                className="mx-auto w-full max-w-[120px] rounded-full object-cover"
                                                                alt={formData?.nameGroom}
                                                                src={
                                                                    // eslint-disable-next-line no-magic-numbers
                                                                    formData?.avatarGroom.length > 0
                                                                        ? process.env
                                                                              .REACT_APP_SERVER_URL +
                                                                          formData?.avatarGroom
                                                                        : IMAGES.CHU_RE_4
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            'mx-auto mt-2 w-full break-words text-center text-lg font-semibold text-red-500 md:text-xl '
                                                        }
                                                    >
                                                        {limitString(formData?.nameGroom)}{' '}
                                                        <div className="ml-1 inline-block no-underline">
                                                            <FaPen className="size-4 text-slate-500 hover:text-slate-600" />
                                                        </div>
                                                    </div>
                                                </Link>
                                                <ReactTooltip
                                                    id="helper-payment"
                                                    place="bottom"
                                                    type="dark"
                                                    border={true}
                                                    borderColor="tranparent"
                                                    // effect="solid"
                                                >
                                                    <p>
                                                        Xem thông tin chi tiết chú rể
                                                        {' ' + formData?.nameGroom}
                                                    </p>
                                                </ReactTooltip>
                                            </>
                                        )}
                                    </div>
                                    <div className="relative bottom-0 z-10 w-full overflow-hidden rounded-b-3xl border-t border-gray-300">
                                        {/* <div className="relative w-full h-max md:h-10"></div> */}
                                        <div className="flex flex-1 flex-col flex-wrap justify-between gap-4 overflow-hidden rounded-b-3xl p-2 pt-1 md:w-full md:flex-row md:px-6">
                                            <div className="flex w-full flex-col gap-2">
                                                <p className="w-full text-wrap break-words text-xl text-black/70">
                                                    {/* <p className="text-wrap break-words">
                                                        {formData?.name}
                                                    </p> */}
                                                    <div className=" flex flex-col md:flex-row md:justify-between">
                                                        <div className="flex items-baseline gap-2">
                                                            {formData.is_public ? (
                                                                <span className="text-[14px] font-medium text-gray-500">
                                                                    Đã công khai
                                                                </span>
                                                            ) : (
                                                                <span className="text-[14px] font-medium text-gray-500">
                                                                    Chưa công khai
                                                                </span>
                                                            )}
                                                            <label className="relative cursor-pointer">
                                                                <input
                                                                    disabled={isExpiredEvent}
                                                                    type="checkbox"
                                                                    className="peer sr-only"
                                                                    // name={settingItem.key}
                                                                    value={formData.is_public}
                                                                    checked={formData.is_public}
                                                                    onChange={() => {
                                                                        handleCheckPublic();
                                                                    }}
                                                                />
                                                                <div className="peer flex h-3 w-11 items-center rounded-full bg-gray-300 after:absolute after:left-0 after:size-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all peer-checked:bg-blue-700 peer-checked:after:-left-1 peer-checked:after:translate-x-full"></div>
                                                            </label>
                                                            <button
                                                                type="button"
                                                                ref={btnOpenModal}
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#modalInputValidatePublic"
                                                                className="hidden"
                                                            ></button>
                                                            <button
                                                                type="button"
                                                                ref={btnOpenSubmitPublic}
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#publicModal"
                                                                className="hidden"
                                                            ></button>
                                                        </div>
                                                        <div className="text-[14px] font-medium text-gray-500">
                                                            Mẫu thiệp: {'  '}
                                                            <Link
                                                                className="inline-block text-[14px] font-semibold text-gray-500 hover:text-blue-500 hover:underline"
                                                                target="_blank"
                                                                to={
                                                                    '../' +
                                                                    'chi-tiet/' +
                                                                    formData?.template?.alias
                                                                }
                                                            >
                                                                {formData?.template?.name}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </p>
                                            </div>

                                            {/* <div className="flex h-fit gap-2 self-end"> */}
                                            {/* <p className="ml-1 inline-block text-[14px] font-semibold text-gray-500">
                                                        {formData?.template?.name}
                                                    </p> */}
                                            {/* {!formData.is_public && !isExpiredEvent && (
                                                    <button className="ml-auto inline-flex w-max select-none rounded-md bg-blue-500 px-3 py-2 text-center font-semibold text-white transition-all duration-150 ease-linear hover:bg-blue-600 ">
                                                        <Link
                                                            className="flex gap-2"
                                                            to={'../' + formData?.id + '/config'}
                                                        >
                                                            Công khai{' '}
                                                            <FaGlobeAsia className="my-auto" />
                                                        </Link>
                                                    </button>
                                                )} */}
                                            {/* <button className="ml-auto inline-flex w-max select-none rounded-md bg-blue-500 px-3 py-2 text-center font-semibold text-white transition-all duration-150 ease-linear hover:bg-blue-600 "> */}
                                            {/* {formData.is_public ? ( */}
                                            {/* <Link
                                                        target="_blank"
                                                        className="flex gap-2"
                                                        to={'../' + formData?.url_slug}
                                                    >
                                                        Xem thiệp{' '}
                                                        <FaExternalLinkAlt className="my-auto" />
                                                    </Link> */}
                                            {/* ) : (
                                                    <Link
                                                        className="flex gap-2"
                                                        to={'../' + formData?.id + '/config'}
                                                    >
                                                        Công khai{' '}
                                                        <FaGlobeAsia className="my-auto" />
                                                    </Link>
                                                )} */}
                                            {/* </button> */}
                                            {/* </div> */}
                                        </div>
                                    </div>
                                    {/* {isExpiredEvent && (
                                        <div
                                            className={`absolute ${
                                                formData?.logo && ' left-0 '
                                            } top-0`}
                                        >
                                            <div className="h-8 rounded-sm  flex justify-center items-center bg-red-500 px-4 w-fit">
                                                <span className="text-xs text-white uppercase">
                                                    Sự kiện đã kết thúc
                                                </span>
                                            </div>
                                        </div>
                                    )} */}
                                </div>
                                <div className="relative z-10 flex h-[300px] w-full flex-col overflow-hidden rounded-3xl shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] transition-transform duration-300 sm:col-span-4 md:h-full">
                                    <div
                                        className={`relative size-full ${
                                            valueMeta.imageurl?.length > 0
                                                ? 'bg-cover'
                                                : 'bg-gradient-to-r from-blue-600 via-blue-700 to-gray-800'
                                        } p-10`}
                                        style={{
                                            ...(valueMeta.imageurl && valueMeta.imageurl?.length > 0
                                                ? {
                                                      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)), url(${valueMeta.imageurl})`,
                                                  }
                                                : {}),
                                        }}
                                    >
                                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                                            <div className="col-span-2 mb-5 flex gap-3 text-white">
                                                <p className="text-5xl font-bold">{stats.hits}</p>
                                                <p className="text-xl font-semibold">
                                                    lượt truy cập
                                                </p>
                                            </div>
                                            <div className="col-span-1 flex gap-3 text-white">
                                                <p className="text-5xl font-bold">
                                                    {stats.num_guests}
                                                </p>

                                                <p className="text-xl font-semibold">khách mời</p>
                                            </div>
                                            <div className="col-span-1 flex gap-3 text-white">
                                                <p className="text-5xl font-bold">
                                                    {greetingStats.total}
                                                </p>

                                                <p className="text-xl font-semibold">lời chúc</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="absolute bottom-0 z-10 w-full overflow-hidden rounded-b-3xl">
                                        <div className="relative h-10 w-full"></div>
                                        <div className="flex w-full justify-center gap-4 overflow-hidden rounded-b-3xl px-1 py-5 md:p-5">
                                            <button className="inline-flex w-max select-none rounded-md bg-blue-500 px-3 py-2 text-center font-semibold text-white transition-all duration-150 ease-linear hover:bg-blue-600 xl:px-6 xl:py-2 ">
                                                <Link
                                                    target="_blank"
                                                    className="flex gap-2"
                                                    to={'../' + formData?.url_slug}
                                                >
                                                    Xem thiệp{' '}
                                                    <FaExternalLinkAlt className="my-auto" />
                                                </Link>
                                            </button>
                                            <Link
                                                className="inline-flex select-none gap-2 rounded-md bg-white px-3 py-2 text-center font-semibold text-indigo-500 transition-all duration-150 ease-linear hover:bg-indigo-100 xl:px-6 xl:py-2"
                                                to={'../su-kien/' + eventId}
                                            >
                                                Xem thống kê
                                                <FaChartPie className="my-auto" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="rounded-lg px-3 pb-3 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
                                <div className="pb-1 pt-2 text-lg font-bold text-slate-800">
                                    Chức năng nổi bật
                                </div>
                                <div className="mx-auto w-full grid-cols-4 flex-col gap-3 min-[0px]:flex min-[768px]:grid">
                                    {menuList(templateAlias)
                                        .filter((x) => x.top)
                                        .sort((prev, cur) => prev.index - cur.index)
                                        .map(
                                            (x, index) =>
                                                !x.hidden && (
                                                    <div key={index} className="h-20 w-full">
                                                        <button
                                                            data-tip
                                                            data-for={x?.keyQues}
                                                            onClick={() => {
                                                                const path = x.path
                                                                    .replace(':urlShare', eventId)
                                                                    .replace(
                                                                        ':eventID',
                                                                        formData?._id
                                                                    );
                                                                navigate(path);
                                                            }}
                                                            disabled={x.disable}
                                                            className={`${
                                                                x.disable
                                                                    ? 'cursor-default grayscale'
                                                                    : 'hover:scale-105 hover:border-red-500 hover:shadow-md hover:ring-[0.5]'
                                                            } group relative ${
                                                                x.mobileHidden
                                                                    ? 'hidden md:block'
                                                                    : ''
                                                            } h-20 w-full items-start gap-4 rounded-md border-[1px] border-gray-300 bg-red-500/25  ring-0 ring-red-500 transition duration-500 `}
                                                        >
                                                            <div className="flex h-full text-center">
                                                                <div className="mx-auto aspect-square h-full self-center rounded-l-lg border-blue-500/25 bg-red-500 shadow-inner group-hover:bg-red-300/25 ">
                                                                    <span className="mx-auto box-border flex size-full items-center justify-center overflow-hidden rounded-l-lg border-none text-xl text-slate-100 opacity-100 group-hover:text-red-500">
                                                                        {x.icon}
                                                                    </span>
                                                                </div>
                                                                <div className="my-auto mb-1 flex h-full w-3/4 items-center gap-2">
                                                                    <p
                                                                        className={`mx-auto text-center text-sm font-semibold ${
                                                                            x.disable
                                                                                ? 'text-gray-500'
                                                                                : 'text-gray-900 group-hover:text-red-500'
                                                                        }  `}
                                                                    >
                                                                        {x.label}{' '}
                                                                    </p>
                                                                </div>
                                                                {/* {x?.keyQues && (
																	<button
																		onClick={(ev) => {
																			ev.stopPropagation();
																			setKeyQues(x?.keyQues);
																		}}
																		data-bs-toggle={'modal'}
																		data-bs-target={`#${keyQues}`}
																		className="text-black hover:text-red-500"
																	>
																		<FaInfoCircle />
																	</button>
																)} */}
                                                            </div>
                                                            {/* <p className="mt-2 ml-2 text-left text-[12px] text-gray-500">
																{x.des}
															</p> */}
                                                            {/* <div className="flex-1">
																	{x.disable && (
																		<p className="text-left text-sm text-gray-500">
																			{!isExpiredEvent && '(Chưa khả dụng)'}
																		</p>
																	)}
																	<div className="mb-1 flex items-center gap-2">
																		<p
																			className={`text-left text-base font-semibold ${x.disable
																				? 'text-gray-500'
																				: 'text-gray-900 group-hover:text-blue-500'
																				}  `}
																		>
																			{x.label}{' '}
																		</p>
																	</div>
																	<p className="mb-2 text-left text-sm text-gray-500">
																		{x.des}
																	</p>
																</div>
																<div className="h-20 w-20 shrink-0 self-center rounded-lg border border-blue-500/25 bg-gray-200/25 shadow-inner group-hover:bg-blue-300/25">
																	<span className="mx-auto box-border flex h-full w-full items-center justify-center overflow-hidden border-none text-blue-500 opacity-100">
																		{x.icon}
																	</span>
																</div> */}
                                                            {x?.notify && newGreeting.total > 0 && (
                                                                <>
                                                                    <div
                                                                        data-tip
                                                                        data-for={`icon-notify-greeting`}
                                                                        className={`absolute flex items-center justify-center border-2 border-white ${
                                                                            newGreeting.total > 99
                                                                                ? 'size-8'
                                                                                : 'size-7'
                                                                        } 
																		bg-red-500 z-[999] text-white  text-xs font-bold rounded-full -top-2.5 -right-2.5`}
                                                                    >
                                                                        {newGreeting.total > 99
                                                                            ? '99+'
                                                                            : newGreeting.total}
                                                                    </div>
                                                                    <ReactTooltip
                                                                        id={`icon-notify-greeting`}
                                                                        place="top"
                                                                        type="dark"
                                                                        delayShow={100}
                                                                        className="w-max"
                                                                    >
                                                                        Bạn có {newGreeting.total}{' '}
                                                                        lời chúc mới chưa duyệt
                                                                    </ReactTooltip>
                                                                </>
                                                            )}
                                                        </button>
                                                        <ReactTooltip
                                                            id={x?.keyQues}
                                                            place="bottom"
                                                            type="dark"
                                                            delayShow={100}
                                                        >
                                                            {x.des}
                                                        </ReactTooltip>
                                                    </div>
                                                )
                                        )}
                                </div>
                            </div>
                            <div className="rounded-lg px-3 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
                                <div className="pb-1 pt-2 text-lg font-bold text-slate-800">
                                    Quản lý và cấu hình
                                </div>
                                <div className="mx-auto w-full grid-cols-4 flex-col gap-3 min-[0px]:flex min-[768px]:grid">
                                    {menuList(templateAlias)
                                        .filter((x) => x.management)
                                        .sort((prev, cur) => prev.index - cur.index)
                                        .map(
                                            (x, index) =>
                                                !x.hidden && (
                                                    <div
                                                        key={index}
                                                        className={
                                                            'h-20 w-full ' +
                                                            (x.mobileHidden
                                                                ? 'hidden md:block'
                                                                : '')
                                                        }
                                                    >
                                                        <button
                                                            data-tip
                                                            data-for={x?.keyQues}
                                                            onClick={() => {
                                                                const path = x.path
                                                                    .replace(':urlShare', eventId)
                                                                    .replace(
                                                                        ':eventID',
                                                                        formData?._id
                                                                    );
                                                                navigate(path);
                                                            }}
                                                            disabled={x.disable}
                                                            className={`${
                                                                x.disable
                                                                    ? 'cursor-default grayscale'
                                                                    : 'hover:scale-105 hover:border-blue-500 hover:shadow-md hover:ring-1'
                                                            } group relative ${
                                                                x.mobileHidden
                                                                    ? 'hidden md:block'
                                                                    : ''
                                                            } aspect-square h-20 w-full items-start gap-4 rounded-md border border-gray-300 ring-0 ring-blue-500 transition duration-500 `}
                                                        >
                                                            <div className="flex h-full text-center">
                                                                <div className="mx-auto aspect-square h-full self-center rounded-l-lg border-blue-500/25 bg-blue-200/25 shadow-inner group-hover:bg-blue-300/25">
                                                                    <span className="mx-auto box-border flex size-full items-center justify-center overflow-hidden border-none text-blue-500 opacity-100">
                                                                        {x.icon}
                                                                    </span>
                                                                </div>
                                                                <div className="my-auto mb-1 flex h-full w-3/4 items-center gap-2">
                                                                    <p
                                                                        className={`mx-auto text-center text-sm font-semibold ${
                                                                            x.disable
                                                                                ? 'text-gray-500'
                                                                                : 'text-gray-900 group-hover:text-blue-500'
                                                                        }  `}
                                                                    >
                                                                        {x.label}{' '}
                                                                    </p>
                                                                </div>
                                                                {/* {x?.keyQues && (
																	<button
																		onClick={(ev) => {
																			ev.stopPropagation();
																			setKeyQues(x?.keyQues);
																		}}
																		data-bs-toggle={'modal'}
																		data-bs-target={`#${keyQues}`}
																		className="text-black hover:text-blue-500"
																	>
																		<FaInfoCircle />
																	</button>
																)} */}
                                                            </div>
                                                            {/* <p className="mt-2 ml-2 text-left text-[12px] text-gray-500">
																{x.des}
															</p> */}
                                                            {/* <div className="flex-1">
																	{x.disable && (
																		<p className="text-left text-sm text-gray-500">
																			{!isExpiredEvent && '(Chưa khả dụng)'}
																		</p>
																	)}
																	<div className="mb-1 flex items-center gap-2">
																		<p
																			className={`text-left text-base font-semibold ${x.disable
																				? 'text-gray-500'
																				: 'text-gray-900 group-hover:text-blue-500'
																				}  `}
																		>
																			{x.label}{' '}
																		</p>
																	</div>
																	<p className="mb-2 text-left text-sm text-gray-500">
																		{x.des}
																	</p>
																</div>
																<div className="h-20 w-20 shrink-0 self-center rounded-lg border border-blue-500/25 bg-gray-200/25 shadow-inner group-hover:bg-blue-300/25">
																	<span className="mx-auto box-border flex h-full w-full items-center justify-center overflow-hidden border-none text-blue-500 opacity-100">
																		{x.icon}
																	</span>
																</div> */}
                                                            {x?.notify && newGreeting.total > 0 && (
                                                                <>
                                                                    <div
                                                                        data-tip
                                                                        data-for={`icon-notify-greeting`}
                                                                        className={`absolute flex items-center justify-center border-2 border-white ${
                                                                            newGreeting.total > 99
                                                                                ? 'size-8'
                                                                                : 'size-7'
                                                                        } 
																		bg-red-500 z-[999] text-white  text-xs font-bold rounded-full -top-2.5 -right-2.5`}
                                                                    >
                                                                        {newGreeting.total > 99
                                                                            ? '99+'
                                                                            : newGreeting.total}
                                                                    </div>
                                                                    <ReactTooltip
                                                                        id={`icon-notify-greeting`}
                                                                        place="top"
                                                                        type="dark"
                                                                        delayShow={100}
                                                                        className="w-max"
                                                                    >
                                                                        Bạn có {newGreeting.total}{' '}
                                                                        lời chúc mới chưa duyệt
                                                                    </ReactTooltip>
                                                                </>
                                                            )}
                                                        </button>
                                                        <ReactTooltip
                                                            id={x?.keyQues}
                                                            place="bottom"
                                                            type="dark"
                                                            delayShow={100}
                                                        >
                                                            {x.des}
                                                        </ReactTooltip>
                                                    </div>
                                                )
                                        )}
                                </div>
                                {/* </div> */}
                                {/* <div className='rounded-lg p-3 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]'> */}
                                {menuList(templateAlias).filter((x) => x.infor).length > 0 && (
                                    <div className="mt-2 py-2 text-lg font-bold text-slate-800">
                                        Thông tin{' '}
                                        {templateAlias.includes('wedding') ? 'đám cưới' : 'sự kiện'}
                                    </div>
                                )}
                                <div className="mx-auto mb-2 w-full grid-cols-2 gap-3 min-[0px]:grid min-[768px]:flex ">
                                    {menuList(templateAlias)
                                        .filter((x) => x.infor)
                                        .sort((prev, cur) => prev.index - cur.index)
                                        .map(
                                            (x, index) =>
                                                !x.hidden && (
                                                    <div
                                                        key={index}
                                                        className="aspect-square h-28 "
                                                    >
                                                        <button
                                                            data-tip
                                                            data-for={x?.keyQues}
                                                            onClick={() => {
                                                                const path = x.path
                                                                    .replace(':urlShare', eventId)
                                                                    .replace(
                                                                        ':eventID',
                                                                        formData?._id
                                                                    );
                                                                navigate(path);
                                                            }}
                                                            disabled={x.disable}
                                                            className={`${
                                                                x.disable
                                                                    ? 'cursor-default grayscale'
                                                                    : 'hover:scale-105 hover:border-blue-500 hover:shadow-md hover:ring-1'
                                                            } group relative ${
                                                                x.mobileHidden
                                                                    ? 'hidden md:block'
                                                                    : ''
                                                            } aspect-square h-28 items-start gap-4 rounded-md border border-gray-300 px-1 py-2 ring-0 ring-blue-500 transition duration-500 `}
                                                        >
                                                            <div className="my-auto flex flex-col justify-start text-center">
                                                                <div className="mx-auto size-12 shrink-0 self-center rounded-lg border-blue-500/25 bg-blue-200/25 shadow-inner group-hover:bg-blue-300/25">
                                                                    <span className="mx-auto box-border flex size-full items-center justify-center overflow-hidden border-none text-blue-500 opacity-100">
                                                                        {x.icon}
                                                                    </span>
                                                                </div>
                                                                <div className="mt-1 flex items-center gap-2 text-center">
                                                                    <p
                                                                        className={`mx-auto text-center text-[12px] leading-tight font-semibold ${
                                                                            x.disable
                                                                                ? 'text-gray-500'
                                                                                : 'text-gray-900 group-hover:text-blue-500'
                                                                        }  `}
                                                                    >
                                                                        {x.label}{' '}
                                                                    </p>
                                                                </div>
                                                                {/* {x?.keyQues && (
																	<button
																		onClick={(ev) => {
																			ev.stopPropagation();
																			setKeyQues(x?.keyQues);
																		}}
																		data-bs-toggle={'modal'}
																		data-bs-target={`#${keyQues}`}
																		className="text-black hover:text-blue-500"
																	>
																		<FaInfoCircle />
																	</button>
																)} */}
                                                            </div>
                                                            {/* <p className="mt-2 ml-2 text-left text-[12px] text-gray-500">
																{x.des}
															</p> */}
                                                            {/* <div className="flex-1">
																	{x.disable && (
																		<p className="text-left text-sm text-gray-500">
																			{!isExpiredEvent && '(Chưa khả dụng)'}
																		</p>
																	)}
																	<div className="mb-1 flex items-center gap-2">
																		<p
																			className={`text-left text-base font-semibold ${x.disable
																				? 'text-gray-500'
																				: 'text-gray-900 group-hover:text-blue-500'
																				}  `}
																		>
																			{x.label}{' '}
																		</p>
																	</div>
																	<p className="mb-2 text-left text-sm text-gray-500">
																		{x.des}
																	</p>
																</div>
																<div className="h-20 w-20 shrink-0 self-center rounded-lg border border-blue-500/25 bg-gray-200/25 shadow-inner group-hover:bg-blue-300/25">
																	<span className="mx-auto box-border flex h-full w-full items-center justify-center overflow-hidden border-none text-blue-500 opacity-100">
																		{x.icon}
																	</span>
																</div> */}
                                                            {x?.notify && newGreeting.total > 0 && (
                                                                <>
                                                                    <div
                                                                        data-tip
                                                                        data-for={`icon-notify-greeting`}
                                                                        className={`absolute flex items-center justify-center border-2 border-white ${
                                                                            newGreeting.total > 99
                                                                                ? 'size-8'
                                                                                : 'size-7'
                                                                        } 
																		bg-red-500 z-[999] text-white  text-xs font-bold rounded-full -top-2.5 -right-2.5`}
                                                                    >
                                                                        {newGreeting.total > 99
                                                                            ? '99+'
                                                                            : newGreeting.total}
                                                                    </div>
                                                                    <ReactTooltip
                                                                        id={`icon-notify-greeting`}
                                                                        place="top"
                                                                        type="dark"
                                                                        delayShow={100}
                                                                        className="w-max"
                                                                    >
                                                                        Bạn có {newGreeting.total}{' '}
                                                                        lời chúc mới chưa duyệt
                                                                    </ReactTooltip>
                                                                </>
                                                            )}
                                                        </button>
                                                        <ReactTooltip
                                                            id={x?.keyQues}
                                                            place="bottom"
                                                            type="dark"
                                                            delayShow={100}
                                                        >
                                                            {x.des}
                                                        </ReactTooltip>
                                                    </div>
                                                )
                                        )}
                                </div>
                            </div>
                            <div className="-translate-y-2">
                                <ButtonUpgradeVip loggedIn={auth?.loggedIn} />
                            </div>
                            {/* <div className="mx-auto w-full grid-cols-3 flex-col gap-4 min-[0px]:flex md:grid min-[768px]:grid">
                                {menuList(templateAlias).map((x, index) => {
                                    x.disable =
                                        x.disable ||
                                        (x.path === '/:eventID/config' && isExpiredEvent);
                                    return (
                                        !x.hidden && (
                                            <button
                                                key={index}
                                                onClick={() => {
                                                    const path = x.path
                                                        .replace(':urlShare', eventId)
                                                        .replace(':eventID', formData?._id);
                                                    navigate(path);
                                                }}
                                                disabled={x.disable}
                                                className={`${
                                                    x.disable
                                                        ? 'cursor-default grayscale'
                                                        : 'hover:scale-105 hover:border-blue-500 hover:shadow-md hover:ring-1'
                                                } h-30 group relative lg:flex ${
                                                    x.mobileHidden ? 'hidden' : ''
                                                } items-center gap-4 rounded-md border border-gray-300 p-4 ring-0 ring-blue-500 transition duration-500 `}
                                            >
                                                <div className="flex-1">
                                                    {x.disable && (
                                                        <p className="text-left text-sm text-gray-500">
                                                            {!isExpiredEvent && '(Chưa khả dụng)'}
                                                        </p>
                                                    )}
                                                    <div className="mb-1 items-center gap-2 flex ">
                                                        <p
                                                            className={`text-left text-base font-semibold ${
                                                                x.disable
                                                                    ? 'text-gray-500'
                                                                    : 'text-gray-900 group-hover:text-blue-500'
                                                            }  `}
                                                        >
                                                            {x.label}{' '}
                                                        </p>
                                                        {x?.keyQues && (
                                                            <button
                                                                onClick={(ev) => {
                                                                    ev.stopPropagation();
                                                                    setKeyQues(x?.keyQues);
                                                                }}
                                                                data-bs-toggle={'modal'}
                                                                data-bs-target={'#questionModal'}
                                                                className="text-blue-500"
                                                            >
                                                                <FaInfoCircle />
                                                            </button>
                                                        )}
                                                    </div>
                                                    <p className="mb-2 text-left text-sm text-gray-500">
                                                        {x.des}
                                                    </p>
                                                </div>
                                                <div className="h-20 w-20 shrink-0 self-center rounded-lg border border-blue-500/25 bg-gray-200/25 shadow-inner group-hover:bg-blue-300/25">
                                                    <span className="mx-auto box-border flex h-full w-full items-center justify-center overflow-hidden border-none text-blue-500 opacity-100">
                                                        {x.icon}
                                                    </span>
                                                </div>
                                                {x?.notify && newGreeting.total > 0 && (
                                                    <>
                                                        <div
                                                            data-tip
                                                            data-for={`icon-notify-greeting`}
                                                            className={`absolute flex items-center justify-center border-2 border-white ${
                                                                newGreeting.total > 99
                                                                    ? 'h-8 w-8'
                                                                    : 'h-7 w-7'
                                                            } 
                                                         bg-red-500 z-[999] text-white  text-xs font-bold rounded-full -top-2.5 -right-2.5`}
                                                        >
                                                            {newGreeting.total > 99
                                                                ? '99+'
                                                                : newGreeting.total}
                                                        </div>
                                                        <ReactTooltip
                                                            id={`icon-notify-greeting`}
                                                            place="top"
                                                            type="dark"
                                                            delayShow={100}
                                                            className="w-max"
                                                        >
                                                            Bạn có {newGreeting.total} lời chúc mới
                                                            chưa duyệt
                                                        </ReactTooltip>
                                                    </>
                                                )}
                                            </button>
                                        )
                                    );
                                })}
                            </div> */}
                        </div>
                    </div>
                )}
            </div>
            <ModalUpgradePackage />
            <ModalManagement />
            <QuestionModal keyQues={keyQues} />
            <PublicModal isPublic={!formData?.is_public} handlePublic={handlePublic} />
            <InputValidatePublic eventId={eventId} isOpen={isOpenModal} />
        </div>
    );
}
