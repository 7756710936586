import suggestTypes from './suggest.type';

const initialState =  { dataForm: {} };

function suggestReducer(state = initialState, action) {
    switch (action.type) {
        case suggestTypes.SET_DATA_FORM:
            return {
				...state,
				dataForm: {
					...state.dataForm,
					...action.payload,
				}
            };
		case suggestTypes.CLEAR_DATA_FORM:
			return {
				...state,
				dataForm: {}
			};
        default:
            return state;
    }
}

export default suggestReducer;