/* eslint-disable */
import { useEffect, useState } from 'react';

import {
    FaUserPlus,
    FaRegSave,
    FaArrowLeft,
    FaUserEdit,
    FaBars,
    FaCaretDown,
    FaEnvelopeOpenText,
    FaLink,
    FaEnvelope,
    FaImages,
    FaFileImage,
    FaUserCheck,
    FaHourglass,
    FaUserTimes,
    FaImage,
    FaQrcode,
    FaTimes,
    FaSave,
} from 'react-icons/fa';

import moment from 'moment/moment';
import InputBasic from '../../../../../components/InputBasic/InputBasic';
import ChangeStatusModal from './ChangeStatusModal';

const statusConvert = (status = '') => {
    switch (status) {
        case 'UNINVITED':
            return { name: 'Chưa gửi lời mời', bgColor: 'bg-yellow-100', icon: <FaEnvelope /> };
        case 'INVITED':
            return { name: 'Đã gửi lời mời', bgColor: 'bg-blue-100', icon: <FaEnvelopeOpenText /> };
        case 'ACCEPT':
            return { name: 'Đồng ý tham dự', bgColor: 'bg-green-100', icon: <FaUserCheck /> };
        case 'DECLINE':
            return { name: 'Từ chối tham dự', bgColor: 'bg-red-100', icon: <FaUserTimes /> };
        case 'SENDING':
            return { name: 'Đang gửi lời mời', bgColor: 'bg-sky-100', icon: <FaHourglass /> };
        case 'VERIFYING':
            return 'Đang chờ xác nhận';
        case 'USER_DENY':
            return 'Từ chối yêu cầu tham dự';
        case 'USER_ACCEPT':
            return 'Đồng ý yêu cầu tham dự';
        case 'USER_CONFIRM':
            return 'Chủ sự kiện xác nhận';
        case 'GUEST':
            return 'Khách mời xác nhận';
        default:
            return status;
    }
};
export default function GroupForm({
    formik,
    setMode,
    mode,
    onUpdateGroup,
    handleRadioButtons,
    onReadGroup,
    onCopyInvite,
    onCopyLink,
    onCopyImage,
    onDownloadTemplate,
    eventTemplate,
    makeUrlShare,
}) {
    const onClearForm = () => {
        formik.resetForm();
        setMode('group-default');
    };

    const [check, setCheck] = useState(mode == 'group-add' ? false : true);

    const handleChangeStatus = (status) => {
        formik.setValues({ ...formik.values, status: status });
    };
    const [isTypeSalutation, setIsTypeSalutation] = useState(false);
    useEffect(() => {
        if (!isTypeSalutation && mode === 'group-add') {
            formik.setValues({ ...formik.values, salutation: 'Trân trọng kính mời' });
        }
    }, [mode]);
    return (
        <>
            <div className="relative flex flex-col w-full min-w-0 mb-6 break-words bg-white rounded-lg shadow-xl ">
                <div className="flex justify-between w-full mb-4 px-4 py-5 border-b-[1px] border-slate-200">
                    <div className="flex items-center gap-[8px]">
                        <button
                            type="button"
                            data-mdb-ripple="true"
                            data-mdb-ripple-color="light"
                            className="inline-block px-5 py-2 text-xs font-medium leading-tight text-gray-700 uppercase transition duration-150 ease-in-out bg-gray-200 rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg"
                            onClick={() => {
                                if (mode == 'group-read') {
                                    onClearForm();
                                }
                            }}
                            data-bs-toggle={mode != 'group-read' && 'modal'}
                            data-bs-target={'#confirmBackModal'}
                        >
                            <FaArrowLeft />
                        </button>

                        <p className="text-[16px] font-bold text-black">Thông tin nhóm khách</p>
                    </div>
                    {!eventTemplate?.isExpired && (
                        <div className="flex items-center">
                            {mode == 'group-read' && formik.values._id && (
                                <button
                                    type="button"
                                    onClick={() => {
                                        setMode('group-update');
                                        onUpdateGroup(formik.values._id);
                                    }}
                                    className="flex items-center px-5 py-2 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-600 rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                                >
                                    <FaUserEdit className="mr-2" />
                                    Sửa
                                </button>
                            )}
                        </div>
                    )}
                </div>
                <form key="formAddGuest" onSubmit={formik.handleSubmit}>
                    <fieldset className="w-full h-auto px-4 pb-4 mr-3 rounded border-b-[1px] border-gray-300">
                        <input name="_id" hidden value={formik.values._id} />
                        <div className="flex flex-wrap items-center w-full justify-evenly lg:justify-between">
                            <div className="w-full col-span-2 px-2 form-group ">
                                <InputBasic
                                    disable={mode == 'group-read'}
                                    id="fullname"
                                    name="fullname"
                                    label="Tên nhóm khách"
                                    placeholder={mode != 'group-read' && 'VD: Công ty TNHH ABC'}
                                    key="ipnFullname"
                                    type="text"
                                    size="default"
                                    require
                                    onKeyPress={() => {
                                        setCheck(true);
                                    }}
                                    onBlur={() => {
                                        setCheck(true);
                                    }}
                                    onChange={formik.handleChange}
                                    value={formik.values.fullname}
                                    invalid={check && !!formik.errors.fullname}
                                />

                                <div className="mb-5">
                                    <span className="text-xs text-red-500">
                                        {(check && formik.errors.fullname) || ''}
                                    </span>
                                </div>
                            </div>
                            <div className="w-full col-span-3 px-2 form-group">
                                <InputBasic
                                    disable={mode == 'group-read'}
                                    id="salutation"
                                    name="salutation"
                                    label="Lời mời"
                                    key="ipnsalutation"
                                    placeholder={
                                        mode != 'group-read' && 'VD: "Trân trọng kính mời"'
                                    }
                                    type="text"
                                    size="small"
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        setIsTypeSalutation(true);
                                    }}
                                    value={formik.values.salutation}
                                />
                                <div className="mb-5">
                                    <span className="text-xs text-red-500">
                                        {formik.errors.salutation || ''}
                                    </span>
                                </div>
                            </div>

                            <div className="w-full col-span-2 px-2 form-group">
                                <InputBasic
                                    disable={mode == 'group-read'}
                                    id="phone_number"
                                    name="phone_number"
                                    label="Số điện thoại"
                                    placeholder={mode != 'group-read' && 'VD: "0983511444"'}
                                    key="ipnPhoneNumber"
                                    type="text"
                                    size="small"
                                    onChange={(e) =>
                                        formik.setValues({
                                            ...formik.values,
                                            phone_number: e.target.value.replace(/[^0-9+ ]/g, ''),
                                        })
                                    }
                                    value={formik.values.phone_number}
                                    invalid={!!formik.errors.phone_number}
                                />
                                <div className="mb-5">
                                    <span className="text-xs text-red-500">
                                        {formik.errors.phone_number || ''}
                                    </span>
                                </div>
                            </div>
                            <div className="w-full col-span-2 px-2 form-group">
                                <InputBasic
                                    disable={mode == 'group-read'}
                                    id="email"
                                    name="email"
                                    label="Email"
                                    key="ipnEmail"
                                    placeholder={mode != 'group-read' && 'VD: "congtya@gmail.com"'}
                                    type="text"
                                    size="small"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    invalid={!!formik.errors.email}
                                />
                                <div className="mb-5">
                                    <span className="text-xs text-red-500">
                                        {formik.errors.email || ''}
                                    </span>
                                </div>
                            </div>
                            <div className="flex w-full col-span-3 px-2 my-3 form-group">
                                <p className="mr-3 text-sm font-bold text-gray-700 ">VIP</p>
                                <div className="flex">
                                    <div className="mr-3 form-check">
                                        <input
                                            className="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-full appearance-none cursor-pointer form-check-input checked:border-blue-600 checked:bg-blue-600 focus:outline-none"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                            value={true}
                                            disabled={mode == 'group-read'}
                                            defaultChecked={formik.values.is_important === true}
                                            onChange={(e) => handleRadioButtons(e)}
                                        />
                                        <label
                                            className="inline-block text-gray-800 form-check-label"
                                            htmlFor="flexRadioDefault1"
                                        >
                                            Có
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border border-gray-300 rounded-full appearance-none cursor-pointer form-check-input checked:border-blue-600 checked:bg-blue-600 focus:outline-none"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault2"
                                            disabled={mode == 'group-read'}
                                            value={false}
                                            defaultChecked={formik.values.is_important === false}
                                            onChange={(e) => handleRadioButtons(e)}
                                        />
                                        <label
                                            className="inline-block text-gray-800 form-check-label"
                                            htmlFor="flexRadioDefault2"
                                        >
                                            Không
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group col-span-3 mt-3 flex w-full justify-center px-2 md:w-[90%] lg:w-full">
                                <div className="w-full">
                                    <label className="inline-block mb-2 text-sm font-bold text-gray-700 form-label ">
                                        Ghi chú
                                    </label>
                                    <textarea
                                        className="block w-full px-2 py-1 mx-auto text-sm font-normal text-gray-700 transition bg-white border border-gray-300 border-solid rounded m-0focus:text-gray-700 form-control bg-clip-padding first-letter:ease-in-out focus:border-blue-600 focus:bg-white focus:outline-none"
                                        rows="3"
                                        placeholder={
                                            mode != 'group-read' && 'VD: "Công ty đối tác"'
                                        }
                                        name="note"
                                        disabled={mode == 'group-read'}
                                        onChange={formik.handleChange}
                                        value={formik.values.note}
                                    />
                                    <div className="">
                                        <span className="text-xs text-red-500">
                                            {formik.errors.note || ''}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* {mode === 'group-read' && (
                            <div className="form-group col-span-3 flex w-full flex-wrap px-2 md:w-[90%] lg:w-[100%]">
                                <p className="my-3 mr-3 w-[50%] text-sm font-bold text-gray-700">
                                    Tạo lúc:
                                    <span className="ml-3 font-normal text-gray-500">
                                        {moment(formik.values.infoCreatedAt).format('HH:mm - L')}
                                    </span>
                                </p>
                                <p className="my-3 mr-3 w-[50%] text-sm font-bold text-gray-700">
                                    Cập nhật lần cuối:
                                    <span className="ml-3 font-normal text-gray-500">
                                        {moment(formik.values.infoUpdateAt).format('HH:mm - L')}
                                    </span>
                                </p>
                            </div>
                        )} */}
                        </div>

                        <div className="flex flex-row justify-start gap-2 mt-5"></div>
                    </fieldset>
                    {mode != 'group-add' && (
                        <fieldset className="w-full h-auto px-4 pb-4 mt-5 rounded">
                            <legend className="px-1 mb-4 text-[14px] font-bold text-black">
                                Trạng thái phản hồi
                            </legend>
                            <div
                                className={`flex w-full items-center ${
                                    statusConvert(formik.values.status).bgColor
                                } justify-between  p-[1rem] shadow-md transition-all`}
                            >
                                <div className="flex items-center gap-[12px] ">
                                    <p className="max-w-[50rem] text-[14px] text-black font-semibold flex items-center gap-2">
                                        {statusConvert(formik.values.status).icon}
                                        {statusConvert(formik.values.status).name}
                                    </p>
                                </div>
                                {mode === 'group-update' && (
                                    <button
                                        type="button"
                                        className="text-[14px] font-bold text-blue-700 transition-all hover:text-blue-400"
                                        data-bs-toggle="modal"
                                        data-bs-target="#selectStatusModal"
                                    >
                                        Thay đổi
                                    </button>
                                )}
                            </div>
                            <input name="_id" hidden value={formik.values._id} />

                            {mode == 'group-read' &&
                                formik.values.status != 'INVITED' &&
                                formik.values.status != 'UNINVITED' && (
                                    <div className="form-group col-span-3 flex w-full justify-center md:w-[90%] lg:w-full mt-3">
                                        <div className="w-full">
                                            <label className="inline-block mb-2 text-sm font-bold text-black form-label">
                                                Lời nhắn
                                            </label>
                                            <textarea
                                                className="block w-full py-1 mx-auto text-sm font-normal text-gray-700 transition bg-white border border-gray-300 border-solid rounded m-0focus:text-gray-700 form-control bg-clip-padding first-letter:ease-in-out focus:border-blue-600 focus:bg-white focus:outline-none"
                                                rows="3"
                                                name="note"
                                                disabled={mode == 'group-read'}
                                                onChange={formik.handleChange}
                                                value={formik.values.message}
                                            />
                                            <div className="">
                                                <span className="text-xs text-red-500">
                                                    {formik.errors.note || ''}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </fieldset>
                    )}
                    {mode !== 'group-add' && (
                        <div className="flex w-full flex-col items-start gap-5 border-y-[1px] border-gray-300 md:px-6 px-3 py-5">
                            <p className="text-[14px] font-semibold text-black">Lời mời</p>
                            <div className="flex w-full items-center justify-between bg-gray-100 p-[1rem] shadow-md transition-all">
                                <div className="flex items-center gap-[12px] ">
                                    <p className="max-w-[14rem] md:max-w-[40rem] text-[14px]">
                                        {(formik.values.salutation &&
                                            formik.values.salutation + ' ') +
                                            (formik.values.title && formik.values.title + ' ') +
                                            (formik.values.fullname &&
                                                formik.values.fullname + ' ') +
                                            `Tới tham dự sự kiện${
                                                eventTemplate.form_data.nameEvent &&
                                                `${': ' + eventTemplate.form_data.nameEvent}`
                                            } tại đường link sau: ${makeUrlShare(
                                                eventTemplate.url_slug,
                                                formik.values.slug
                                            )}`}
                                    </p>
                                </div>
                                <button
                                    className="text-[14px] font-bold text-blue-700 transition-all hover:text-blue-400"
                                    onClick={() => {
                                        onCopyInvite(formik.values._id);
                                    }}
                                >
                                    Sao chép
                                </button>
                            </div>
                        </div>
                    )}
                    {mode !== 'group-add' && (
                        <div className="flex w-full flex-col items-start gap-5 border-y-[1px] border-gray-300 md:px-6 px-3 py-5">
                            <p className="text-[14px] font-semibold text-black">Đường dẫn</p>
                            <div className="flex w-full items-center justify-between bg-gray-100 p-[1rem] shadow-md transition-all">
                                <div className="flex items-center gap-[12px] ">
                                    <p className="max-w-[50rem] text-[14px]">
                                        {makeUrlShare(eventTemplate.url_slug, formik.values.slug)}
                                    </p>
                                </div>
                                <button
                                    className="text-[14px] font-bold text-blue-700 transition-all hover:text-blue-400"
                                    onClick={() => {
                                        onCopyLink(formik.values._id);
                                    }}
                                >
                                    Sao chép
                                </button>
                            </div>
                        </div>
                    )}
                    {mode !== 'group-add' && (
                        <div className="flex w-full flex-col items-start gap-5 border-y-[1px] border-gray-300 md:px-6 px-3 py-5">
                            <div className="flex w-full items-center justify-between bg-gray-100 p-[1rem] shadow-md transition-all">
                                <div className="flex items-center gap-[12px] ">
                                    <p className="max-w-[50rem] text-[14px] text-black font-semibold flex items-center gap-[8px]">
                                        <FaImage /> Ảnh thiệp
                                    </p>
                                </div>
                                <button
                                    type="button"
                                    className="text-[14px] font-bold text-blue-700 transition-all hover:text-blue-400"
                                    data-bs-toggle="modal"
                                    data-bs-target="#templateModal"
                                >
                                    Xem ảnh thiệp
                                </button>
                            </div>
                            <div className="flex w-full items-center justify-between bg-gray-100 p-[1rem] shadow-md transition-all">
                                <div className="flex items-center gap-[12px] ">
                                    <p className="max-w-[50rem] text-[14px] text-black font-semibold flex items-center gap-[8px]">
                                        <FaQrcode /> Mã Qr
                                    </p>
                                </div>
                                <button
                                    type="button"
                                    className="text-[14px] font-bold text-blue-700 transition-all hover:text-blue-400"
                                    data-bs-toggle="modal"
                                    data-bs-target="#qrModal"
                                >
                                    Xem chi tiết
                                </button>
                            </div>
                        </div>
                    )}
                </form>
                <div
                    className={
                        (mode !== 'group-read' ? 'bottom-0 opacity-100' : '-bottom-80 opacity-0') +
                        ' fixed inset-x-0 z-[40] bg-gray-50 transition-all duration-500 md:px-6 lg:z-[40]'
                    }
                >
                    <div className="flex items-center justify-between gap-3 px-6 py-4 border-t border-gray-300 md:px-0">
                        <p>Chưa lưu !</p>
                        <div className="flex gap-3">
                            <button
                                type="button"
                                className={
                                    'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-100 px-6 py-2 text-center font-semibold text-blue-500 transition-all duration-150 ease-linear hover:bg-blue-200 '
                                }
                                data-bs-toggle="modal"
                                data-bs-target="#confirmBackModal"
                            >
                                <FaTimes />
                                Hủy bỏ
                            </button>
                            <button
                                data-bs-toggle={
                                    check && JSON.stringify(formik.errors) == '{}' && 'modal'
                                }
                                onClick={() => {
                                    setCheck(true);
                                    formik.handleSubmit();
                                }}
                                data-bs-target={'#confirmGroup'}
                                className={
                                    'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-500 px-6 py-2 text-center font-semibold text-white transition-all duration-150 ease-linear hover:bg-blue-600 '
                                }
                            >
                                <FaSave />
                                Hoàn tất
                            </button>
                        </div>
                    </div>
                </div>
            </div>{' '}
            <ChangeStatusModal status={formik.values.status} setStatus={handleChangeStatus} />
        </>
    );
}
