/* eslint-disable */
import { Link } from 'react-router-dom';
import PAGE from '../../../../config/path.route';
import React from 'react';
import { FaPhoneAlt, FaGlobe, FaHome, FaAngleDoubleRight, FaFacebook } from 'react-icons/fa';
import { MdOutlineMailOutline, MdEmail } from 'react-icons/md';
import { PRODUCT_LIST } from '../../../../views/dashboard/components/_OurProducts';
import envConstants from '../../../../config/key';

const FooterDefault = () => {
    return (
        <footer className="w-full bg-blue-100">
            <div className=" max-w-screen-xl mx-auto text-sm leading-6">
                <div className="flex flex-col w-full md:flex-row justify-between px-4 pt-10  pb-10  gap-y-5 gap-x-2 text-slate-500 ">
                    <div className="flex flex-col w-full md:w-[40%] text-black">
                        <img
                            src="/logo_chungvui.png"
                            className="object-cover w-[6rem] h-auto "
                            alt="logo"
                        />
                        <b className=" mt-3 mb-2 text-black uppercase text-[14px]">
                            ChungVui - Enjoy the Moment
                        </b>
                        <span>
                            Nền tảng tạo thiệp đẹp, tiện lợi và trải nghiệm miễn phí dành cho bạn, một sự lựa
                            chọn tốt nhất hiện nay cho việc quản lý các sự kiện của bạn.
                        </span>
                        <a
                            className='w-fit'
                            href={`tel:(+84) 979.33.88.11`}>
                            <p className="flex mb-2 mt-[20px] text-[16px]">
                                <FaPhoneAlt className="mt-1 mr-1.5" /> (+84) 979.33.88.11
                            </p>
                        </a>
                        <a
                            className='w-fit'
                            href="mailto:info@daviso.vn">

                            <p className="flex  mb-2 text-[16px]">
                                <MdOutlineMailOutline className="text-xl mt-0.5 mr-1" />{' '}
                                info@daviso.vn
                            </p>
                        </a>
                        <a href={envConstants.FACEBOOK_CHUNGVUI_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                            className='w-fit mt-1'
                        >
                            <FaFacebook className="h-6 w-6 text-main-500" />
                        </a>
                    </div>
                    <div className="flex w-full mt-4 md:mt-0 md:w-[50%]">
                        <div className="flex flex-col w-[55%]">
                            <h1 className="mb-4 text-[20px] font-bold text-gray-800">
                                Sản phẩm khác
                            </h1>
                            {PRODUCT_LIST.map((product, index) => (
                                <div key={index} className="flex w-auto text-black flex-row py-1.5">
                                    <img
                                        alt="anh-san-pham"
                                        src={product.image}
                                        className="w-[30px] rounded-md h-auto aspect-[1/1]"
                                    />
                                    <a
                                        href={product.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className=" hover:text-sky-500 ml-2 mt-1"
                                    >
                                        {product.name}
                                    </a>
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-col w-[45%] text-black">
                            <h1 className="mb-5 text-[20px] font-bold text-gray-800">
                                Về chung vui
                            </h1>
                            <div className='flex '>
                                <div className='flex hover:text-sky-500'>
                                    <FaAngleDoubleRight className="mt-1  mr-1" />
                                    <Link className="flex mb-3  hover:text-sky-500 mr-1" to={PAGE.policy}>
                                        Chính sách
                                    </Link>
                                </div>/
                                <Link className="flex mb-3  hover:text-sky-500 ml-1" to={PAGE.terms}>
                                    Điều khoản
                                </Link>
                            </div>
                            <Link className="flex mb-3  hover:text-sky-500 " to={PAGE.help}>
                                <FaAngleDoubleRight className="mt-1  mr-1" /> Trung tâm trợ giúp
                            </Link>
                            <a
                                className="flex mb-3  hover:text-sky-500 cursor-pointer"
                                href={envConstants.BLOG_URL}
                                target="_blank"
                            >
                                <FaAngleDoubleRight className="mt-1  mr-1" /> Bài viết mới nhất
                            </a>
                            <Link className="flex mb-3  hover:text-sky-500 " to={PAGE.feedback}>
                                <FaAngleDoubleRight className="mt-1  mr-1" /> Đánh giá sản phẩm
                            </Link>
                            <Link className="flex mb-3  hover:text-sky-500 " to={PAGE.aboutus}>
                                <FaAngleDoubleRight className="mt-1  mr-1" /> Về chúng tôi
                            </Link>
                            <Link className="flex mb-3  hover:text-sky-500 " to={PAGE.pricing}>
                                <FaAngleDoubleRight className="mt-1  mr-1" /> Bảng giá
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="flex sm:flex-row flex-col justify-center items-center py-4 border-t border-gray-400">
                    <img
                        src="/congty.jpg"
                        className="object-cover w-[4rem] h-auto mr-2"
                        alt="logo"
                    />
                    <div className="mt-1 sm:mt-0">
                        <span className="text-gray-800 mt-1 sm:mt-0 text-[14px]">
                            Copyright © {new Date().getFullYear()}. MỘT SẢN PHẨM CỦA
                        </span>
                        <a
                            href="https://daviso.vn/"
                            className=" hover:text-main-600 cursor-pointer ml-1"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <b className="text-black">DAVISO</b>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default FooterDefault;
