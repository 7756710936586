import { FaImage, FaImages } from 'react-icons/fa';
import { MySubTemplate } from '../../../../../resource/_index/sub.template';

export default function TemplateModal({ alias, props, onDownloadTemplate, onCopyImage }) {
    return (
        <div
            className="fade modal fixed left-0 top-0 hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none "
            id="templateModal"
            tabIndex="-1"
            aria-modal="true"
            role="dialog"
            aria-labelledby="staticConfigEvnet"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered modal-lg pointer-events-none relative w-auto max-w-[680px]">
                <div className=" modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
                    <div className="modal-header flex shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4">
                        <h5
                            className="text-base font-bold leading-normal text-black"
                            id="staticConfigEvnet"
                        >
                            Mẫu thiệp
                        </h5>
                        <button
                            type="button"
                            className="btn-close box-content h-4 w-4 rounded-none border-none p-1 text-black opacity-50 hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    <div className="modal-body relative mt-1 justify-center md:px-10 px-2 md:py-5 py-1">
                        <MySubTemplate alias={alias} props={props} />
                    </div>
                    <div className="modal-footer flex shrink-0 flex-wrap items-center justify-end rounded-b-md border-t border-gray-200 p-4">
                        <button
                            type="button"
                            className="hover: focus: active: flex items-center gap-2 rounded bg-blue-700 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            onClick={onDownloadTemplate}
                        >
                            <FaImage />
                            Tải ảnh
                        </button>
                        <button
                            type="button"
                            onClick={onCopyImage}
                            className="ml-1 flex items-center gap-2 rounded bg-violet-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                        >
                            <FaImages /> Sao chép ảnh
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
