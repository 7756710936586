/* eslint-disable */
import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaGlobeAsia, FaImage, FaLock } from 'react-icons/fa';
import EventGreetingService from '../PublishGreeting.service';
import ToastNotify from '../../../../../utils/Toast';
import { GREETING_CONTENT_CHUNGVUI, OWNER } from '../asests/constants/key';
import '../asests/css/offcanvas.css';
import { validateSizeOfFile } from '../../../../../utils/common';

const schema = yup.object().shape({
    guestName: yup
        .string()
        .trim()
        .required('Vui lòng nhập tên người chúc!')
        .max(50, 'Tên không vượt được quá 50 ký tự!'),
    greeting: yup
        .string()
        .trim()
        .required('Vui lòng nhập lời chúc!')
        .max(500, 'Lời chúc không được vượt quá 500 ký tự!'),
    reply: yup
        .string()
        .trim()
        .max(200, 'Câu trả lời không được vượt quá 200 ký tự!'),
});

const dataDefault = {
    id: '',
    guestName: '',
    greeting: '',
    reply: '',
    isPublic: true,
    image: '',
    type: OWNER,
};

export default function ModelAddGreeting({ eventId, reloadData, rowDataEdit }) {
    const {
        register,
        handleSubmit,
        formState: { isDirty, isValid, errors },
        clearErrors,
        setValue,
    } = useForm({ resolver: yupResolver(schema), mode: 'onSubmit', reValidateMode: 'onChange' });

    const [imagePreview, setImagePreview] = useState('');
    const [dataSave, setDataSave] = useState(dataDefault);
    const toastLoadingId = useRef(null);

    const onChangeInput = (e) => {
        setDataSave({
            ...dataSave,
            // [e.target.name]: (e.target.name === 'isPublic' ? e.target.value == 1 : e.target.value)
            [e.target.name]: e.target.name === 'isPublic' ? e.target.checked : e.target.value,
        });
    };

    const isValidateForm = () => {
        let isValid = true;
        if (!dataSave.guestName || !dataSave.greeting) {
            isValid = false;
        } else {
            if (dataSave.guestName.length > 50) {
                isValid = false;
            }
            if (dataSave.greeting.length > 500) {
                isValid = false;
            }
            if (dataSave.reply.length > 200) {
                isValid = false;
            }
        }
        return isValid;
    };

    const changeFile = async (e) => {
        const val = e.target.files[0];
        if (val && validateSizeOfFile(val, 5, 'Dung lượng ảnh không được vượt quá 5Mb')) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                const image = reader.result;
                setImagePreview(image);
                setDataSave({
                    ...dataSave,
                    image: image,
                });
            });
            reader.readAsDataURL(val);
        }
    };

    const btnRemoveImage = () => {
        setImagePreview('');
        setDataSave({
            ...dataSave,
            image: '',
        });
    };

    const submitForm = async () => {
        toastLoadingId.current = ToastNotify.Loading('Đang lưu lời chúc...');
        const bodyFormData = new FormData();
        if (rowDataEdit?.index || rowDataEdit?.index === 0) {
            await appendFormdata(bodyFormData, dataSave);
            if (dataSave.hasOwnProperty('image')) {
                bodyFormData.append(
                    'image',
                    dataSave.image ? await EventGreetingService.createFile(dataSave.image) : ''
                );
            }
            EventGreetingService.update(dataSave.id, bodyFormData)
                .then((data) => {
                    ToastNotify.Update(toastLoadingId.current, 'Chỉnh sửa thành công');
                })
                .catch((err) => {
                    console.log('Chỉnh sửa bị lỗi = ', err);
                    ToastNotify.Update(
                        toastLoadingId.current,
                        err?.message || 'Đã có lỗi xảy ra khi chỉnh sửa',
                        'error'
                    );
                })
                .finally(() => {
                    reloadData();
                    clearForm();
                });
        } else {
            await appendFormdata(bodyFormData, dataSave);
            if (dataSave.image) {
                bodyFormData.append('image', await EventGreetingService.createFile(dataSave.image));
            }
            bodyFormData.append('eventId', eventId);
            EventGreetingService.create(bodyFormData)
                .then((data) => {
                    ToastNotify.Update(toastLoadingId.current, 'Thêm mới thành công');
                })
                .catch((err) => {
                    console.log('Thêm mới bị lỗi = ', err);
                    ToastNotify.Update(
                        toastLoadingId.current,
                        err?.message || 'Đã có lỗi xảy ra khi thêm mới',
                        'error'
                    );
                })
                .finally(() => {
                    reloadData();
                    clearForm();
                });
        }
    };

    const appendFormdata = async (formData, data = dataSave) => {
        formData.append('guestName', data.guestName);
        formData.append('greeting', data.greeting);
        formData.append('reply', data.reply);
        formData.append('isPublic', data.isPublic);
    };

    const clearForm = () => {
        setDataSave(dataDefault);
        setImagePreview('');
        clearErrors();
    };

    useEffect(() => {
        setDataSave({
            id: rowDataEdit._id,
            guestName: rowDataEdit.guestName,
            greeting: rowDataEdit.greeting,
            reply: rowDataEdit?.reply || '',
            isPublic: rowDataEdit.isPublic,
            type: rowDataEdit.type,
        });
        if (rowDataEdit.image) {
            setImagePreview(process.env.REACT_APP_SERVER_URL + rowDataEdit.image);
        }
        setValue('guestName', rowDataEdit.guestName);
        setValue('greeting', rowDataEdit.greeting);
        setValue('reply', rowDataEdit?.reply || '');
    }, [rowDataEdit]);

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="modalAddGreeting"
            data-bs-backdrop="static"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between px-4 py-3 border-b border-gray-300 modal-header shrink-0 rounded-t-md">
                        <h5 className="text-[16px] font-bold leading-normal text-gray-800">
                            {rowDataEdit?.index || rowDataEdit?.index === 0
                                ? 'Chỉnh sửa lời chúc'
                                : 'Thêm mới lời chúc'}
                        </h5>
                        <button
                            type="button"
                            title="Đóng"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={clearForm}
                        ></button>
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(submitForm)(e).catch((err) => {
                                console.log('handleSubmit err = ', err);
                            });
                        }}
                    >
                        <div className="flex flex-col items-center justify-between w-full border-b border-gray-200 modal-content shrink-0 rounded-t-md p-[20px] gap-[10px]">
                            <div className="w-full">
                                <div className="block w-full">
                                    <span className="text-sm font-normal">
                                        Tên <span className="text-red-500">(*)</span>
                                    </span>
                                    <div className="flex w-full">
                                        <div className="w-full">
                                            <input
                                                placeholder={`VD: Bạn A yêu dấu`}
                                                type="text"
                                                name="guestName"
                                                value={dataSave?.guestName}
                                                onInput={onChangeInput}
                                                disabled={dataSave?.type !== OWNER}
                                                {...register('guestName')}
                                                className={`mt-0.5 block w-full rounded-[0.375rem] border border-gray-300 ${dataSave?.type !== OWNER ? 'bg-gray-300' : 'bg-white'}  p-3 text-sm text-gray-700 transition-all duration-150 ease-linear placeholder:text-gray-300 focus:outline-none focus:ring-1`}
                                            />
                                            <span className="text-xs font-medium text-red-500">
                                                {errors.guestName && errors.guestName?.message}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="block w-full mt-2">
                                    <span className="text-sm font-normal">
                                        Lời chúc <span className="text-red-500">(*)</span>
                                    </span>
                                    <div className="flex w-full">
                                        <div className="w-full">
                                            <textarea
                                                placeholder={`VD: ${GREETING_CONTENT_CHUNGVUI}`}
                                                rows={4}
                                                className={`mt-0.5 block w-full rounded-[0.375rem] border border-gray-300 ${dataSave?.type !== OWNER
                                                        ? 'bg-gray-300'
                                                        : 'bg-white'
                                                    } p-3 text-sm text-gray-700 transition-all duration-150 ease-linear placeholder:text-gray-300 focus:outline-none focus:ring-1`}
                                                name="greeting"
                                                value={dataSave?.greeting}
                                                onInput={onChangeInput}
                                                disabled={dataSave?.type !== OWNER}
                                                {...register('greeting')}
                                            />
                                            <span className="text-xs font-medium text-red-500">
                                                {errors.greeting && errors.greeting?.message}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="block w-full mt-2">
                                    <span className="text-sm font-normal">Trả lời</span>
                                    <div className="flex w-full">
                                        <div className="w-full">
                                            <textarea
                                                placeholder='VD: Cảm ơn bạn rất nhiều!'
                                                rows={2}
                                                className="mt-0.5 block w-full rounded-[0.375rem] border border-gray-300 bg-white p-3 text-sm text-gray-700 transition-all duration-150 ease-linear placeholder:text-gray-300 focus:outline-none focus:ring-1"
                                                name="reply"
                                                value={dataSave?.reply}
                                                onInput={onChangeInput}
                                                {...register('reply')}
                                            />
                                            <span className="text-xs font-medium text-red-500">
                                                {errors.reply && errors.reply?.message}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="block w-full mt-2">
                                    <span className="text-sm font-normal">Trạng thái</span>
                                    <div
                                        className={`flex w-full justify-between ${dataSave.isPublic ? 'bg-blue-50' : 'bg-gray-50'
                                            }  items-center py-[1rem] shadow-sm transition-all`}
                                    >
                                        <div
                                            className={`flex items-center gap-[12px] max-w-[250px] md:max-w-[50rem] `}
                                        >
                                            <span
                                                className={`rounded-full ${dataSave.isPublic
                                                        ? 'bg-gray-200'
                                                        : 'bg-gray-200'
                                                    }  p-2.5`}
                                            >
                                                {dataSave.isPublic ? (
                                                    <FaGlobeAsia className="text-lg text-gray-600" />
                                                ) : (
                                                    <FaLock className="text-lg text-gray-600" />
                                                )}
                                            </span>
                                            <div className="">
                                                <p className="text-[16px] text-gray-600 font-semibold">
                                                    {dataSave.isPublic
                                                        ? 'Công khai'
                                                        : 'Chỉ mình tôi'}
                                                </p>
                                                <p className="text-xs font-semibold text-gray-400">
                                                    {dataSave.isPublic
                                                        ? 'Tất cả mọi người có thể xem lời chúc này'
                                                        : 'Chỉ bạn mới xem được lời chúc này'}
                                                </p>
                                            </div>
                                        </div>
                                        <label className="relative cursor-pointer">
                                            <input
                                                type="checkbox"
                                                className="sr-only peer"
                                                name="isPublic"
                                                value={dataSave.isPublic}
                                                checked={dataSave.isPublic}
                                                onChange={onChangeInput}
                                            />
                                            <div className="flex items-center h-3 bg-gray-300 rounded-full peer w-11 after:absolute after:left-0 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all peer-checked:bg-blue-600 peer-checked:after:-left-1 peer-checked:after:translate-x-full"></div>
                                        </label>
                                    </div>
                                    {/* <div className="flex w-full">
                                        <div className="w-full">
                                            <select
                                                name="isPublic"
                                                onChange={onChangeInput}
                                                className="mt-1 rounded-[0.375rem] peer form-select w-full border border-gray-300 bg-white p-3 text-sm appearance-none font-normal  text-gray-700 transition  ease-in-out focus:outline-none"
                                            >
                                                <option key='1' value={1} selected={dataSave?.isPublic}>
                                                    Công khai
                                                </option>
                                                <option key='2' value={2} selected={!dataSave?.isPublic}>
                                                    Chỉ mình tôi
                                                </option>
                                            </select>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="block w-full mt-2">
                                    <span className="text-sm font-normal">Ảnh</span>
                                    {!imagePreview ? (
                                        <div className="flex w-full">
                                            <div className="w-full">
                                                <label
                                                    className={`mt-1 mx-auto h-[180px] cursor-pointer flex flex-col gap-[20px] appearance-none justify-center items-center rounded-lg border-2 border-dashed border-gray-300 ${dataSave?.type !== OWNER
                                                            ? 'bg-gray-300'
                                                            : 'bg-white'
                                                        } transition hover:border-gray-400 focus:outline-none`}
                                                >
                                                    <FaImage className="text-[60px] text-gray-500" />
                                                    <span className="text-sky-500 text-[16px] font-semibold">
                                                        Thêm ảnh{' '}
                                                        <span className="text-gray-400 text-[16px] font-medium">
                                                            (Giới hạn 5Mb)
                                                        </span>
                                                    </span>
                                                    {dataSave?.type === OWNER && (
                                                        <input
                                                            className="hidden"
                                                            onChange={(e) => changeFile(e)}
                                                            type="file"
                                                            accept="image/*"
                                                        />
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="group relative h-max shadow-sm rounded-lg border-2 border-dashed border-gray-300 hover:border-gray-400">
                                            <img
                                                src={imagePreview}
                                                className="w-full rounded-lg"
                                                alt="Ảnh"
                                            />
                                            {dataSave?.type === OWNER && (
                                                <div
                                                    onClick={() => btnRemoveImage()}
                                                    title="Xóa"
                                                    className="absolute right-3 top-1 w-[33px] cursor-pointer rounded-full border bg-slate-100 p-2 text-black shadow-lg hover:bg-gray-400"
                                                >
                                                    <svg
                                                        width="15px"
                                                        height="15px"
                                                        viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fill="none"
                                                            stroke="#000"
                                                            strokeWidth="2"
                                                            d="M3,3 L21,21 M3,21 L21,3"
                                                        />
                                                    </svg>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap items-center justify-end px-4 py-3 modal-footer shrink-0 rounded-b-md">
                            <button
                                type="button"
                                className="hover:bg-gray-50 transition-all focus:bg-gray-50 active:bg-gray-50  inline-block rounded bg-white border-[1px] border-gray-300 px-5 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 duration-150 ease-in-out"
                                data-bs-dismiss="modal"
                                onClick={clearForm}
                            >
                                Thoát
                            </button>
                            <button
                                type="submit"
                                onClick={handleSubmit}
                                data-bs-dismiss={isValidateForm() ? 'modal' : ''}
                                className={`bg-blue-600 text-white hover:bg-blue-700 focus:bg-blue-700 ml-1 inline-block rounded  px-6 py-2.5 text-xs font-medium uppercase leading-tight  shadow-md transition duration-150 ease-in-out  hover:shadow-lg   focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg`}
                            >
                                Lưu
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
