/* eslint-disable */

import { useEffect, useState } from "react";
import userService from "../User.service";
import { useSelector } from "react-redux";
import { formatDatetime } from "../../../utils/Times";
import { STATUS_TEXT } from "./TransactionHistory";

export default function ModalConfirmPayment({ funToggle, handleConfirm, isOpen, isLoading }) {
    const userData = useSelector(state => state.auth.user)

    const [hasOrderPenddingBefore, setHasOrderPenddingBefore] = useState(false)
    const [orderPenddingBefore, setOrderPenddingBefore] = useState({})

    useEffect(() => {
        if (isOpen) {
            // API check da co hoa don truoc do pending chua
            userService.getAdminListPendingOrder(userData?._id)
                .then(({ data }) => {
                    if (data?.data?.length > 0) {
                        setHasOrderPenddingBefore(true)
                        setOrderPenddingBefore(data?.data);
                    }
                })
                .catch(err => console.log(err))
        }
    }, [isOpen])

    return (
        <div
            className={`fixed top-0 left-0 w-screen h-screen overflow-x-hidden outline-none fade modal z-[1056] ${isOpen ? 'show' : ''
                } backdrop-brightness-50`}
            style={{
                margin: 0,
                visibility: isOpen ? 'visible' : 'hidden',
                transition: 'opacity 0.15s linear, visibility 0.15s linear',
            }}
            tabIndex="-1"
            aria-modal="true"
            role="dialog"
        >
            <div className="modal-dialog modal-dialog-centered pointer-events-none relative w-auto">
                <div className="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
                    <div className="modal-header flex shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4">
                        <h5
                            className="flex items-center text-xl font-bold leading-normal text-gray-800"
                        >
                            Xác nhận
                        </h5>
                        <button
                            type="button"
                            disabled={isLoading}
                            className="btn-close box-content h-4 w-4 rounded-none border-none p-1 text-black opacity-50 hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            onClick={funToggle}
                        ></button>
                    </div>
                    <div className="modal-header flex shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4">
                        {
                            hasOrderPenddingBefore
                                ? <div className="flex flex-col py-2 w-full">
                                    <h5 className="text-lg font-medium leading-normal text-red-800" >
                                        Bạn đang có đơn hàng chưa hoàn thành.
                                    </h5>
                                    <div className="w-full overflow-x-auto">
                                        <table className="w-full min-w-[350px] mt-1 mb-4 table-auto border border-gray-300  shadow-lg">
                                            <thead>
                                                <tr>
                                                    <th className="border-b border-gray-300 py-3 px-2.5 text-center">
                                                        <p className="block antialiased font-sans text-xs font-bold uppercase text-gray-600">Mã đơn hàng</p>
                                                    </th>
                                                    {/* <th className="border-b border-gray-300 py-3 px-2.5 text-center">
                                                    <p className="block antialiased font-sans text-xs font-bold uppercase text-gray-600">Thành tiền</p>
                                                </th> */}
                                                    <th className="border-b border-gray-300 py-3 px-2.5 text-center">
                                                        <p className="block antialiased font-sans text-xs font-bold uppercase text-gray-600">Trạng thái</p>
                                                    </th>
                                                    <th className="border-b border-gray-300 py-3 px-2.5 text-center">
                                                        <p className="block antialiased font-sans text-xs font-bold uppercase text-gray-600">Ngày tạo</p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    orderPenddingBefore?.map(item => {
                                                        return (
                                                            <tr>
                                                                <td className="py-3 px-5 border-b border-blue-gray-50">
                                                                    <div className="flex justify-center items-center gap-4">
                                                                        <div>
                                                                            <p className="block antialiased font-sans text-sm leading-normal font-medium">
                                                                                {item.order_alias_string + item.order_alias_number.toString().padStart(5, '0')}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                {/* <td className="py-3 px-5 border-b border-blue-gray-50">
                                                                <p className="block text-center antialiased font-sans text-sm font-semibold">
                                                                    {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(item.total)}
                                                                </p>
                                                            </td> */}
                                                                <td className="py-3 px-5 border-b border-blue-gray-50">
                                                                    <div className={`relative grid mx-auto items-center font-sans uppercase whitespace-nowrap select-none text-white rounded-xl py-0.5 px-2.5 text-[11px] font-medium w-fit 
                                                                    bg-${STATUS_TEXT[item.status].color}  `} >
                                                                        <span className="">
                                                                            {STATUS_TEXT[item.status].text}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td className="py-3 px-5 border-b border-blue-gray-50">
                                                                    <p className="block antialiased text-center font-sans text-xs font-semibold text-blue-gray-600">
                                                                        {formatDatetime(item.createdAt).split('ngày')[0]}
                                                                    </p>
                                                                    <p className="block antialiased text-center font-sans text-xs font-semibold text-blue-gray-600">
                                                                        {formatDatetime(item.createdAt).split('ngày')[1]}
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <h5 className="text-lg font-medium leading-normal text-gray-800" >
                                        Bạn có muốn <span className="text-red-800">huỷ đơn hàng cũ</span>  và tiếp tục <span className="text-green-700">nâng cấp gói dịch vụ mới</span>  không?
                                    </h5>
                                </div>
                                : <h5 className="text-lg font-medium leading-normal text-gray-800" >
                                    Bạn có chắc chắn muốn nâng cấp gói dịch vụ không?
                                </h5>
                        }

                    </div>
                    <div className="flex items-center justify-end rounded-b border-t border-solid border-blueGray-200 p-5">
                        <button
                            type="button"
                            disabled={isLoading}
                            onClick={funToggle}
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                        >
                            Thoát
                        </button>
                        <button
                            type="button"
                            disabled={isLoading}
                            onClick={handleConfirm}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            Xác nhận
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
