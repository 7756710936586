/* eslint-disable */
const envConstants = {
    API_URL: process.env.REACT_APP_API_URL || 'http://localhost:8888/api/v1',
    SERVER_URL: process.env.REACT_APP_SERVER_URL || 'http://localhost:8888',
    CLIENT_URL: process.env.REACT_APP_CLIENT_URL || 'http://localhost:3000/',
    BLOG_URL: process.env.BLOG_URL || 'https://blog.chungvui.net/',
    POPULAR_URL: 'https://blog.chungvui.net/tag/chungvui/',
    HELP_URL: 'https://blog.chungvui.net/category/huong-dan-su-dung/',
    FACEBOOK_CHUNGVUI_URL: 'https://www.facebook.com/chungvui.daiviet/',
    SITE_KEY_CAPTCHA: process.env.REACT_APP_SITE_KEY_CAPTCHA || '',
    ADMIN_API_URL: process.env.REACT_APP_ADMIN_API_URL || 'http://fuji.akb.vn:8889/api/v1',
    // ADMIN_API_URL: 'http://localhost:8889/api/v1',
};
export default envConstants;
