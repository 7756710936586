/* eslint-disable */

import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import EventsService from '../../../../events/Events.service';
import ToastNotify from '../../../../../utils/Toast';
import { screenShot } from '../../publish.summary/PublishSummary.page';
import PublishSummaryService from '../../publish.summary/PublishSummary.service';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const regexStr = /^[^\\(\\)~`#$%^&*=|\?\/<>\\*]+$/g;

const dataDefaultWedding = {
    timeCeremony: '',
    dateCeremony: '',
    placeCeremony: '',
    addressCeremony: '',
    nameGroom: '',
    nameBride: '',
};
const dataDefaultEvent = {
    titleEvent: '',
    nameEvent: '',
    invitation: '',
};

const InputValidatePublicLoading = () => {
    return (
        <>
            <div className="flex items-center justify-between p-4 border-b border-gray-300 modal-header shrink-0 rounded-t-md">
                <h5 className="text-[17px] font-bold leading-normal text-gray-800">
                    Thông tin cần nhập
                </h5>
                <button
                    type="button"
                    title="Đóng"
                    className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                ></button>
            </div>
            <div className="relative m-4">
                <div className="mt-4 self-stretch flex-col justify-start items-start gap-4 flex h-full">
                    <div className="relative self-stretch h-4 w-2/3 p-1 rounded-md bg-trueGray-300 animate-pulse">
                        {' '}
                    </div>
                    <div className="relative self-stretch h-10  p-1 rounded-md bg-trueGray-300 animate-pulse">
                        {' '}
                    </div>
                    <div className="relative self-stretch h-10  p-1 rounded-md bg-trueGray-300 animate-pulse">
                        {' '}
                    </div>
                    <div className="relative self-stretch h-10  p-1 rounded-md bg-trueGray-300 animate-pulse">
                        {' '}
                    </div>
                    <div className="mt-4 relative self-stretch h-4 w-2/3 p-1 rounded-md bg-trueGray-300 animate-pulse">
                        {' '}
                    </div>
                    <div className="relative self-stretch h-10  p-1 rounded-md bg-trueGray-300 animate-pulse">
                        {' '}
                    </div>
                    <div className="relative self-stretch h-10  p-1 rounded-md bg-trueGray-300 animate-pulse">
                        {' '}
                    </div>
                    <div className="relative self-stretch h-10  p-1 rounded-md bg-trueGray-300 animate-pulse">
                        {' '}
                    </div>
                </div>
            </div>
        </>
    );
};

const schemaWedding = yup.object().shape({
    timeCeremony: yup.string().required('Vui lòng chọn thời gian!'),
    dateCeremony: yup
        .string()
        .nullable(true)
        .required('Vui lòng chọn ngày!')
        .transform((curr, orig) => (orig === '' ? null : curr)),
    placeCeremony: yup
        .string()
        .trim()
        .required('Vui lòng nhập địa điểm!')
        .matches(regexStr, 'Vui lòng nhập địa điểm hợp lệ!')
        .max(200, 'Vui lòng nhập địa điểm không vượt quá 200 ký tự!'),
    addressCeremony: yup
        .string()
        .trim()
        .required('Vui lòng nhập địa chỉ cụ thể!')
        .matches(regexStr, 'Vui lòng nhập địa chỉ hợp lệ!')
        .max(200, 'Vui lòng nhập địa chỉ cụ thể không vượt quá 200 ký tự!'),
    nameBride: yup.string().trim().required('Vui lòng nhập tên cô dâu!'),

    nameGroom: yup.string().trim().required('Vui lòng nhập tên chú rể!'),
});
const InputWedding = ({ eventId, formData, reload }) => {
    const [dataSave, setDataSave] = useState(dataDefaultWedding);
    const btnCloseRef = useRef();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        setValue,
    } = useForm({
        resolver: yupResolver(schemaWedding),
        mode: 'onSubmit',
        reValidateMode: 'onChange',
    });

    const clearForm = () => {
        setDataSave(dataDefaultWedding);
        clearErrors();
    };

    const onChangeInput = (e) => {
        setDataSave({
            ...dataSave,
            [e.target.name]: e.target.value,
        });
    };

    const submitForm = async () => {
        PublishSummaryService.saveContent(eventId, dataSave)
            .then(({ data }) => {
                ToastNotify.Success('Lưu thành công');
                const event = data.data;
                if (event?.is_public) {
                    screenShot(event._id, event.url_slug);
                }
            })
            .catch((err) => ToastNotify.Error(err?.message || 'Đã có lỗi xảy ra.'))
            .finally(() => {
                if (reload) {
                    setTimeout(() => {
                        navigate(0);
                    }, 1000);
                } else {
                    btnCloseRef.current.click();
                }
            });
    };

    useEffect(() => {
        if (formData) {
            setDataSave({
                timeCeremony: formData?.timeCeremony,
                dateCeremony: formData?.dateCeremony,
                addressCeremony: formData?.addressCeremony,
                placeCeremony: formData?.placeCeremony,
                nameGroom: formData?.nameGroom,
                nameBride: formData?.nameBride,
            });
            setValue('timeCeremony', formData.timeCeremony);
            setValue('dateCeremony', formData.dateCeremony);
            setValue('addressCeremony', formData.addressCeremony);
            setValue('placeCeremony', formData.placeCeremony);
            setValue('nameGroom', formData.nameGroom);
            setValue('nameBride', formData.nameBride);
        }
    }, [formData]);

    const handleDateChange = (date) => {
        register('dateCeremony');
        clearErrors('dateCeremony');
        setValue('dateCeremony', date ? moment(date).format('YYYY-MM-DD') : '');
        setDataSave({
            ...dataSave,
            ['dateCeremony']: date ? moment(date).format('YYYY-MM-DD') : '',
        });
    };

    return (
        <>
            <div className="flex items-center justify-between p-4 border-b border-gray-300 modal-header shrink-0 rounded-t-md">
                <h5 className="text-[17px] font-bold leading-normal text-gray-800">
                    Thông tin cần nhập
                </h5>
                <button
                    type="button"
                    title="Đóng"
                    className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={clearForm}
                    ref={btnCloseRef}
                ></button>
            </div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(submitForm)(e).catch((err) => {
                        console.log('handleSubmit err = ', err);
                    });
                }}
            >
                <div
                    className="flex flex-col i
                tems-center justify-between w-full border-b border-gray-200 modal-content shrink-0 rounded-t-md p-[20px] gap-[10px]"
                >
                    <div className="w-full">
                        <p className="text-[12px] mb-5 text-gray-500 italic leading-[20px]">
                            Chúng tôi cần thêm một số thông tin để hoàn tất thiệp mời của bạn. Vui
                            lòng giúp chúng tôi nhé!
                        </p>

                        <div className="flex items-center justify-between ">
                            <p className="mb-0 text-[16px] font-bold text-gray-700 pb-2 ">
                                Thông tin chính
                            </p>
                        </div>
                        <div className="w-full ">
                            <label className="block w-full">
                                <span className="text-[14px] text-gray-700 font-normal">
                                    Thời gian <span className="text-red-500">(*)</span>
                                </span>
                                <div className="flex w-full">
                                    <div className="mr-2 w-1/2">
                                        <input
                                            className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                            type="time"
                                            name="timeCeremony"
                                            value={dataSave.timeCeremony}
                                            onKeyDown={(event) => event.preventDefault()}
                                            onInput={onChangeInput}
                                            {...register('timeCeremony')}
                                        />
                                        <span className="text-xs font-medium text-red-500">
                                            {errors.timeCeremony && errors.timeCeremony?.message}
                                        </span>
                                    </div>

                                    <div className="w-1/2">
                                        {/* <input
                                            type="date"
                                            min={new Date().toISOString().slice(0, 10)}
                                            name="dateCeremony"
                                            value={dataSave?.dateCeremony}
                                            onKeyDown={(event) => event.preventDefault()}
                                            onInput={onChangeInput}
                                            className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                            {...register('dateCeremony')}
                                        /> */}
                                        <DatePicker
                                            name="dateCeremony"
                                            selected={dataSave?.dateCeremony}
                                            minDate={new Date()}
                                            onChange={(date) => handleDateChange(date)}
                                            locale="vi"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText={'dd/mm/yyyy'}
                                            className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                            showYearDropdown
                                            yearDropdownItemNumber={10}
                                            scrollableYearDropdown
                                        />
                                        <span className="text-xs font-medium text-red-500">
                                            {errors.dateCeremony && errors.dateCeremony?.message}
                                        </span>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="mt-3 w-full">
                            <div className="block w-full">
                                <span className="mb-5 text-sm font-normal text-gray-700">
                                    Địa điểm <span className="text-red-500">(*)</span>
                                </span>
                                <div className="flex w-full">
                                    <div className="w-full ">
                                        <input
                                            type="text"
                                            name="placeCeremony"
                                            value={dataSave?.placeCeremony}
                                            onInput={onChangeInput}
                                            placeholder={`VD: "Tư gia nhà trai"`}
                                            className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                            {...register('placeCeremony')}
                                        />
                                    </div>
                                </div>
                                <span className="text-xs font-medium text-red-500">
                                    {errors.placeCeremony && errors.placeCeremony?.message}
                                </span>
                            </div>
                        </div>
                        <div className="mt-3 w-full">
                            <div className="block w-full">
                                <span className="mb-5 text-sm font-normal text-gray-700">
                                    Địa chỉ <span className="text-red-500">(*)</span>
                                </span>
                                <div className="flex w-full">
                                    <div className="w-full">
                                        <input
                                            type="text"
                                            name="addressCeremony"
                                            value={dataSave?.addressCeremony}
                                            onInput={onChangeInput}
                                            placeholder={`VD: "Nhân Chính, Thanh Xuân, Hà Nội"`}
                                            className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                            {...register('addressCeremony')}
                                        />
                                    </div>
                                </div>
                                <span className="text-xs font-medium text-red-500">
                                    {errors.addressCeremony && errors.addressCeremony?.message}
                                </span>
                            </div>
                        </div>
                        <div className="mt-5 flex items-center justify-between ">
                            <p className="mb-0 text-[16px] font-bold text-gray-700 pb-2 ">
                                Thông tin cô dâu chú rể
                            </p>
                        </div>
                        <div className=" w-full">
                            <div className="block w-full">
                                <span className="mb-5 text-sm font-normal text-gray-700">
                                    Tên cô dâu <span className="text-red-500">(*)</span>
                                </span>
                                <div className="flex w-full">
                                    <div className="w-full ">
                                        <input
                                            type="text"
                                            name="nameBride"
                                            value={dataSave?.nameBride}
                                            onInput={onChangeInput}
                                            placeholder={`VD: "Phạm Thị B"`}
                                            className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                            {...register('nameBride')}
                                        />
                                    </div>
                                </div>
                                <span className="text-xs font-medium text-red-500">
                                    {errors.nameBride && errors.nameBride?.message}
                                </span>
                            </div>
                        </div>
                        <div className="mt-3 w-full">
                            <div className="block w-full">
                                <span className="mb-5 text-sm font-normal text-gray-700">
                                    Tên chú rể <span className="text-red-500">(*)</span>
                                </span>
                                <div className="flex w-full">
                                    <div className="w-full ">
                                        <input
                                            type="text"
                                            name="nameGroom"
                                            value={dataSave?.nameGroom}
                                            onInput={onChangeInput}
                                            placeholder={`VD: "Nguyễn Văn A"`}
                                            className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                            {...register('nameGroom')}
                                        />
                                    </div>
                                </div>
                                <span className="text-xs font-medium text-red-500">
                                    {errors.nameGroom && errors.nameGroom?.message}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap items-center justify-end p-4 modal-footer shrink-0 rounded-b-md">
                    <button
                        type="button"
                        className="hover:bg-gray-50 transition-all focus:bg-gray-50 active:bg-gray-50  inline-block rounded bg-white border-[1px] border-gray-300 px-5 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 duration-150 ease-in-out"
                        data-bs-dismiss="modal"
                        onClick={clearForm}
                    >
                        Thoát
                    </button>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        // data-bs-dismiss={isValidateForm() ? 'modal' : ''}
                        className={`bg-blue-600 text-white hover:bg-blue-700 focus:bg-blue-700 ml-1 inline-block rounded  px-6 py-2.5 text-xs font-medium uppercase leading-tight  shadow-md transition duration-150 ease-in-out  hover:shadow-lg   focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg`}
                    >
                        Lưu
                    </button>
                </div>
            </form>
        </>
    );
};

const schemaEvent = yup.object().shape({
    titleEvent: yup
        .string()
        .required('Vui lòng nhập tiêu để!')
        .max(200, 'Vui lòng nhập tiêu đề không được quá 200 ký tự!'),
    nameEvent: yup
        .string()
        .required('Vui lòng nhập tên sự kiện!')
        .max(200, 'Vui lòng nhập tên sự kiện không được quá 200 ký tự!'),
    invitation: yup
        .string()
        .required('Vui lòng nhập lời mời!')
        .max(200, 'Vui lòng nhập lời mời không được quá 200 ký tự!'),
    dateHold: yup.string().required('Vui lòng chọn ngày!'),
    timeHold: yup.string().required('Vui lòng chọn thời gian!'),
});
const InputEvent = ({ eventId, formData, reload }) => {
    const [dataSave, setDataSave] = useState(dataDefaultEvent);
    const navigate = useNavigate();
    const btnCloseRef = useRef();

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        setValue,
    } = useForm({
        resolver: yupResolver(schemaEvent),
        mode: 'onSubmit',
        reValidateMode: 'onChange',
    });

    const clearForm = () => {
        setDataSave(dataDefaultEvent);
        clearErrors();
    };

    const onChangeInput = (e) => {
        setDataSave({
            ...dataSave,
            [e.target.name]: e.target.value,
        });
    };

    const submitForm = async () => {
        PublishSummaryService.saveContent(eventId, dataSave)
            .then(({ data }) => {
                ToastNotify.Success('Lưu thành công');
                const event = data.data;
                if (event?.is_public) {
                    screenShot(event._id, event.url_slug);
                }
            })
            .catch(() => ToastNotify.Error('Đã có lỗi xảy ra.'))
            .finally(() => {
                if (reload) {
                    setTimeout(() => {
                        navigate(0);
                    }, 1000);
                } else {
                    btnCloseRef.current.click();
                }
            });
    };

    useEffect(() => {
        if (formData) {
            setDataSave({
                titleEvent: formData?.titleEvent,
                nameEvent: formData?.nameEvent,
                invitation: formData?.invitation,
            });
            setValue('titleEvent', formData.titleEvent);
            setValue('nameEvent', formData.nameEvent);
            setValue('invitation', formData.invitation);
        }
    }, [formData]);

    const handleDateChange = (date) => {
        register('dateHold');
        clearErrors('dateHold');
        setValue('dateHold', date ? moment(date).format('YYYY-MM-DD') : '');
        setDataSave({
            ...dataSave,
            ['dateHold']: date ? moment(date).format('YYYY-MM-DD') : '',
        });
    };

    return (
        <>
            <div className="flex items-center justify-between p-4 border-b border-gray-300 modal-header shrink-0 rounded-t-md">
                <h5 className="text-[17px] font-bold leading-normal text-gray-800">
                    Thông tin cần nhập
                </h5>
                <button
                    type="button"
                    title="Đóng"
                    className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={clearForm}
                    ref={btnCloseRef}
                ></button>
            </div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(submitForm)(e).catch((err) => {
                        console.log('handleSubmit err = ', err);
                    });
                }}
            >
                <div className="flex flex-col items-center justify-between w-full border-b border-gray-200 modal-content shrink-0 rounded-t-md p-[20px] gap-[10px]">
                    <div className="w-full">
                        <p className="text-[12px] mb-5 text-gray-500 italic leading-[20px]">
                            Chúng tôi cần thêm một số thông tin để hoàn tất thiệp mời của bạn. Vui
                            lòng giúp chúng tôi nhé!
                        </p>
                        <div className="flex items-center justify-between ">
                            <p className="mb-0 text-[16px] font-bold text-gray-700 pb-2 ">
                                Thông tin sự kiện
                            </p>
                        </div>
                        <div className="mt-3 w-full">
                            <div className="block w-full">
                                <span className="mb-5 text-sm font-normal text-gray-700">
                                    Tiêu đề <span className="text-red-500">(*)</span>
                                </span>
                                <div className="flex w-full">
                                    <div className="w-full ">
                                        <input
                                            type="text"
                                            name="titleEvent"
                                            value={dataSave?.titleEvent}
                                            onInput={onChangeInput}
                                            placeholder={`VD: "Khai trương nhà hàng"`}
                                            className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                            {...register('titleEvent')}
                                        />
                                    </div>
                                </div>
                                <span className="text-xs font-medium text-red-500">
                                    {errors.titleEvent && errors.titleEvent?.message}
                                </span>
                            </div>
                        </div>
                        <div className="mt-3 w-full">
                            <div className="block w-full">
                                <span className="mb-5 text-sm font-normal text-gray-700">
                                    Tên sự kiện <span className="text-red-500">(*)</span>
                                </span>
                                <div className="flex w-full">
                                    <div className="w-full">
                                        <input
                                            type="text"
                                            name="nameEvent"
                                            value={dataSave?.nameEvent}
                                            onInput={onChangeInput}
                                            placeholder={`VD: "Khai trương nhà hàng A"`}
                                            className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                            {...register('nameEvent')}
                                        />
                                    </div>
                                </div>
                                <span className="text-xs font-medium text-red-500">
                                    {errors.nameEvent && errors.nameEvent?.message}
                                </span>
                            </div>
                        </div>
                        <div className="mt-3 w-full">
                            <div className="block w-full">
                                <span className="mb-5 text-sm font-normal text-gray-700">
                                    Lời mời <span className="text-red-500">(*)</span>
                                </span>
                                <div className="flex w-full">
                                    <div className="w-full">
                                        <input
                                            type="text"
                                            name="invitation"
                                            value={dataSave?.invitation}
                                            onInput={onChangeInput}
                                            placeholder={'Tham gia cùng chúng tôi'}
                                            className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                            {...register('invitation')}
                                        />
                                    </div>
                                </div>
                                <span className="text-xs font-medium text-red-500">
                                    {errors.invitation && errors.invitation?.message}
                                </span>
                            </div>
                        </div>
                        <div className="w-full mt-3">
                            <label className="block w-full">
                                <span className="text-[14px] text-gray-700 font-normal">
                                    Thời gian <span className="text-red-500">(*)</span>
                                </span>
                                <div className="flex w-full">
                                    <div className="mr-2 w-1/2">
                                        <input
                                            className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                            type="time"
                                            name="timeHold"
                                            value={dataSave.timeHold}
                                            onKeyDown={(event) => event.preventDefault()}
                                            onInput={onChangeInput}
                                            {...register('timeHold')}
                                        />
                                        <span className="text-xs font-medium text-red-500">
                                            {errors.timeHold && errors.timeHold?.message}
                                        </span>
                                    </div>

                                    <div className="w-1/2">
                                        <DatePicker
                                            name="dateHold"
                                            selected={dataSave?.dateHold}
                                            minDate={new Date()}
                                            onChange={(date) => handleDateChange(date)}
                                            locale="vi"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText={'dd/mm/yyyy'}
                                            className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                            showYearDropdown
                                            yearDropdownItemNumber={10}
                                            scrollableYearDropdown
                                        />
                                        <span className="text-xs font-medium text-red-500">
                                            {errors.dateHold && errors.dateHold?.message}
                                        </span>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap items-center justify-end p-4 modal-footer shrink-0 rounded-b-md">
                    <button
                        type="button"
                        className="hover:bg-gray-50 transition-all focus:bg-gray-50 active:bg-gray-50  inline-block rounded bg-white border-[1px] border-gray-300 px-5 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 duration-150 ease-in-out"
                        data-bs-dismiss="modal"
                        onClick={clearForm}
                    >
                        Thoát
                    </button>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className={`bg-blue-600 text-white hover:bg-blue-700 focus:bg-blue-700 ml-1 inline-block rounded  px-6 py-2.5 text-xs font-medium uppercase leading-tight  shadow-md transition duration-150 ease-in-out  hover:shadow-lg   focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg`}
                    >
                        Lưu
                    </button>
                </div>
            </form>
        </>
    );
};

export default function InputValidatePublic({ eventId, isOpen, reload }) {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [isWedding, setIsWedding] = useState(true);

    useEffect(() => {
        if (eventId) {
            setLoading(true);
            EventsService.getOneEventById(eventId)
                .then(({ data }) => {
                    const alias = data.template.alias;
                    const formData = data.form_data;
                    if (alias.includes('wedding')) {
                        setIsWedding(true);
                        setData({
                            timeCeremony: formData?.timeCeremony,
                            dateCeremony: formData?.dateCeremony,
                            addressCeremony: formData?.addressCeremony,
                            placeCeremony: formData?.placeCeremony,
                            nameGroom: formData?.nameGroom,
                            nameBride: formData?.nameBride,
                        });
                    } else {
                        setIsWedding(false);
                        setData({
                            titleEvent: formData?.titleEvent,
                            nameEvent: formData?.nameEvent,
                            invitation: formData?.invitation,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [eventId, isOpen]);

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="modalInputValidatePublic"
            data-bs-backdrop="static"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    {loading ? (
                        <InputValidatePublicLoading />
                    ) : isWedding ? (
                        <InputWedding eventId={eventId} formData={data} reload={reload} />
                    ) : (
                        <InputEvent eventId={eventId} formData={data} reload={reload} />
                    )}
                </div>
            </div>
        </div>
    );
}
