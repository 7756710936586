/* eslint-disable */
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useState } from 'react';
import { FaPhoneAlt, FaUser } from 'react-icons/fa';
import IMAGES_EVENT4 from './assets/images';
import { getDateOfMonth, getMonthOfYear, getYear } from '../../../utils/Times';
import envConstants from '../../../config/key';
import LANGUAGE from './assets/config/language';
let objectLanguage = LANGUAGE.Vietnamese;
const switchLanguage = (languages) => {
    switch (languages) {
        case 'Vietnamese':
            return LANGUAGE.Vietnamese;
        case 'Japanese':
            return LANGUAGE.Japanese;
        default:
            return false;
    }
};

export default function SubEvent4({
    data,
    guestTitle,
    guestFullname,
    salutation,
    refFrame,
    urlShare,
    language = 'Vietnamese',
}) {
    objectLanguage = switchLanguage(language);
    const dataCopy = {
        groupName: data.groupName,
        invitation: data.invitation,
        titleEvent: data.titleEvent,
        nameEvent: data.nameEvent,
        date: data.dateHold,
        time: data.timeHold,
        placeEvent: data.placeEvent,
        addressEvent: data.addressEvent,
        logo: data.logo && data.logo[0],
    };
    const [pathImgLogo, setPathImgLogo] = useState('');
    useEffect(() => {
        if (
            !/^blob:/.test(dataCopy.logo) &&
            !/^data:image/.test(dataCopy.logo) &&
            !/^\/static\/media/.test(dataCopy.logo) &&
            dataCopy.logo !== undefined &&
            dataCopy.logo !== '' &&
            dataCopy.logo !== null &&
            dataCopy.logo !== false
        ) {
            setPathImgLogo(envConstants.SERVER_URL + dataCopy.logo);
        } else if (dataCopy.logo) {
            setPathImgLogo(dataCopy.logo);
        } else {
            setPathImgLogo('');
        }
    }, [dataCopy.logo]);
    return (
        <>
            <div tabIndex="0" ref={refFrame} className="relative p-1">
                <div
                    className="relative flex h-full  w-full flex-col  overflow-x-hidden bg-white bg-contain bg-center bg-no-repeat"
                    style={{
                        backgroundImage: `url(${IMAGES_EVENT4.BG_TRONG_DONG_4})`,
                    }}
                >
                    <div className="item-center flex h-full w-full flex-col justify-center bg-gray-50/30  px-2 py-3">
                        <div className=" flex w-full justify-center">
                            <p className="text-center text-[calc(0.5rem_+_1vh)] font-bold uppercase  text-sky-600 md:text-[calc(0.5rem_+_1.5vh)]">
                                {dataCopy.groupName
                                    ? dataCopy.groupName
                                    : objectLanguage.GROUP_NAME}
                            </p>
                        </div>
                        <div className="-mt-1 flex  h-[calc(6rem_+_6vh)] w-full justify-center">
                            {pathImgLogo && (
                                <img className=" h-[calc(6rem_+_6vh)]  " src={pathImgLogo} />
                            )}
                        </div>
                        <div className={'-mt-[calc(0.5rem_+_0.5vh)] flex w-full justify-center '}>
                            <span className="border-b border-sky-600 pb-[calc(1rem_+_1vh)] font-nautigal text-[calc(2.5rem_+_3vh)] text-sky-600 md:pb-[calc(1rem_+_1.2vh)] md:text-[calc(2.5rem_+_3vh)] ">
                                {dataCopy.titleEvent ? dataCopy.titleEvent : 'Anniversary'}
                            </span>
                        </div>
                        <div className="mt-[calc(0.5rem_+_2vh)] flex w-full  flex-col justify-center">
                            {language === 'Vietnamese' && (
                                <p className="text-center font-Merriweather text-[calc(0.5rem_+_1vh)] font-black uppercase text-black md:text-[calc(0.3rem_+_2vh)]">
                                    {salutation || objectLanguage?.SALUTATION}
                                </p>
                            )}
                            {language === 'Japanese' && (
                                <p className=" mt-[calc(0.4rem_+_1vh)] text-center text-[calc(1rem_+_1vh)] font-bold uppercase text-sky-600 md:text-[calc(1rem_+_2vh)]">
                                    {dataCopy.nameEvent
                                        ? dataCopy.nameEvent
                                        : objectLanguage?.NAME_EVENT}
                                </p>
                            )}
                            <p className="mt-[calc(0.5rem_+_0.5vh)] text-center font-fairplay text-[calc(0.5rem_+_1vh)] font-bold text-red-500 md:mt-[calc(1rem_+_1vh)] md:text-[calc(0.3rem_+_2vh)]">
                                {language === 'Vietnamese' && guestTitle && guestTitle}{' '}
                                {guestFullname && guestFullname}{' '}
                                {language === 'Japanese' && guestTitle && guestTitle}
                                {!guestTitle &&
                                    !guestFullname &&
                                    '....................................'}
                            </p>
                            {language === 'Vietnamese' && (
                                <p className="mt-[calc(0.5rem_+_1vh)] text-center  font-Merriweather text-[calc(0.5rem_+_1vh)] font-bold text-gray-800 md:mt-[calc(0.5rem_+_2vh)]">
                                    {dataCopy.invitation
                                        ? dataCopy.invitation
                                        : objectLanguage?.INVITATION}
                                </p>
                            )}
                            {language === 'Vietnamese' && (
                                <p className=" mt-[calc(0.4rem_+_1vh)] text-center text-[calc(1rem_+_1vh)] font-bold uppercase text-sky-600 md:text-[calc(1rem_+_2vh)]">
                                    {dataCopy.nameEvent
                                        ? dataCopy.nameEvent
                                        : objectLanguage?.NAME_EVENT}
                                </p>
                            )}
                            {language === 'Japanese' && (
                                <p className="mt-5 text-center font-Merriweather text-[calc(0.5rem_+_1vh)] font-black uppercase text-black md:text-[calc(0.3rem_+_2vh)]">
                                    {salutation || objectLanguage?.SALUTATION}
                                </p>
                            )}
                        </div>
                        {/* Time */}
                        <div className="relative mt-[calc(2rem_+_2vh)] h-[calc(2rem_+_3vh)] w-full  bg-gradient-to-b  from-yellow-500/60 via-yellow-500/70  to-yellow-500/60 md:mt-[calc(3rem_+_3vh)] md:h-[calc(3rem_+_4vh)] ">
                            <div className="absolute inset-0 m-auto -mt-[calc(1.5rem_+_2vh)] h-[calc(5rem_+_6vh)] w-[calc(5rem_+_6vh)] rounded-full bg-amber-200 p-[calc(0.2rem_+_0.25vh)] md:-mt-[calc(2rem_+_3vh)] md:h-[calc(6rem_+_10vh)]  md:w-[calc(6rem_+_10vh)]">
                                <div className="h-full w-full rounded-full border-4 border-yellow-600/50 bg-orange-100 p-[calc(0.2rem_+_0.25vh)] ">
                                    <div className=" item-center flex h-full w-full flex-col justify-center rounded-full border-[calc(0.25rem_+_0.25vh)] border-dotted border-yellow-500/50 bg-orange-100  md:border-[calc(0.25rem_+_0.5vh)]">
                                        <p className="mt-1 text-center text-[calc(0.5rem_+_0.75vh)] uppercase  text-gray-800 md:text-[calc(0.75rem_+_1vh)] ">
                                            <b>
                                                {/* Tháng 12 */}
                                                {/* 12月 */}
                                                {objectLanguage.MONTH === 'Tháng' &&
                                                    objectLanguage.MONTH + ' '}
                                                {dataCopy.date
                                                    ? getMonthOfYear(dataCopy.date)
                                                    : getMonthOfYear(new Date())}{' '}
                                                {objectLanguage?.MONTH === '月' &&
                                                    objectLanguage?.MONTH}
                                                {/* 月 */}
                                            </b>
                                        </p>
                                        <p className="my-3 animate-pulse text-center text-[calc(3rem_+_1.5vh)] text-red-600 md:my-6  md:text-[calc(5rem_+_1vh)] ">
                                            <b>
                                                {dataCopy.date
                                                    ? getDateOfMonth(dataCopy.date)
                                                    : getDateOfMonth(new Date())}
                                            </b>
                                        </p>
                                        <p className="text-center text-[calc(0.5rem_+_0.5vh)] tracking-[8px] text-gray-800 md:text-[calc(0.7rem_+_1vh)] md:tracking-[10px]">
                                            <b>
                                                {dataCopy.date
                                                    ? getYear(dataCopy.date)
                                                    : getYear(new Date())}
                                            </b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* address */}
                        <div className="mx-auto mt-[calc(1rem_+_2.5vh)] flex w-full px-1 md:mt-[calc(1.5rem_+_2.5vh)] md:px-2 ">
                            <div className="mx-auto flex h-fit w-fit flex-col items-center justify-center rounded-lg bg-gray-300/60 px-[calc(0.25rem_+_0.25vh)]  py-[calc(0.25rem_+_1vh)]  lg:px-[calc(0.5rem_+_1vh)]">
                                {/* <p className="text-sky-600 text-[calc(1rem_+_1vh)] md:text-[calc(0.75rem_+_2vh)] font-bold">
                                    {dataCopy.time ? dataCopy.time : '18:00'}
                                </p> */}
                                <p className="text-[calc(0.7rem_+_1vh)]  text-sky-600 md:text-[calc(1rem_+_1vh)]  ">
                                    {language === 'Vietnamese' && (
                                        <span className="text-[calc(0.6rem_+_0.5vh)] md:text-[calc(0.5rem_+_1vh)]">
                                            {objectLanguage.SALUTATION_TIME}{' '}
                                        </span>
                                    )}
                                    <b className=" ">{dataCopy.time ? dataCopy.time : '18:00'}</b>
                                    {language === 'Japanese' && objectLanguage.SALUTATION_TIME}{' '}
                                </p>
                                <p className="mt-[calc(0.25rem_+_0.5vh)] text-center text-[calc(0.5rem_+_0.8vh)] font-bold uppercase text-gray-700 md:mt-[calc(0.25rem_+_1vh)] md:text-[calc(0.75rem_+_1vh)]">
                                    {dataCopy.placeEvent
                                        ? dataCopy.placeEvent
                                        : objectLanguage.PLACE_EVENT}
                                </p>
                                <p className="text-center text-[calc(0.5rem_+_0.75vh)] font-medium text-gray-800 md:text-[calc(0.5rem_+_1vh)] ">
                                    {dataCopy.addressEvent
                                        ? dataCopy.addressEvent
                                        : objectLanguage.ADDRESS_EVENT}
                                    <a
                                        href={
                                            'https://www.google.com/maps/search/?api=1&query=' +
                                            dataCopy.addressEvent
                                        }
                                        rel="noreferrer"
                                        target="_blank"
                                        className={
                                            'text-[calc(0.5rem_+_0.75vh] ml-2 mr-1 border-sky-600 text-sky-600 hover:border-b '
                                        }
                                        title={objectLanguage.TITLE_MAP}
                                    >
                                        <i className="fas fa-map-marker-alt"></i>
                                    </a>
                                </p>
                            </div>
                        </div>
                        {/* Lien he */}
                        <p
                            className={
                                'font-nautigal font-semibold text-red-500 text-lg   text-center mt-5 ' +
                                (language === 'Japanese' ? ' md:text-2xl ' : ' md:text-4xl ')
                            }
                        >
                            {data.greeting ? data.greeting : objectLanguage.GREETING}
                        </p>
                        <p className="mt-4 text-center text-[16px] font-bold uppercase text-sky-600 md:text-lg">
                            {objectLanguage.CONTACT}
                        </p>
                        <div className="mx-auto flex">
                            <p className="mr-3 flex items-center text-sm font-bold text-black md:text-[16px]">
                                <FaUser className="mr-1.5 text-sky-600" />
                                {data.nameSupporter ? data.nameSupporter : 'Ms. Thu Sang'}
                            </p>
                            <a
                                className="flex cursor-pointer items-center text-sm font-bold text-black underline md:text-[16px]"
                                href={`tel:${data.phoneSupporter}`}
                            >
                                <FaPhoneAlt className={'mr-1.5 animate-autoRing text-sky-600'} />
                                {data.phoneSupporter ? data.phoneSupporter : '0906885804'}
                            </a>
                        </div>
                    </div>
                </div>
                {/** QR code */}
                {urlShare && (
                    <div className="absolute right-2 top-11 w-[15%] rounded-md bg-gray-700 p-1.5">
                        <div className="rounded-md bg-white p-3">
                            <QRCodeSVG
                                value={urlShare}
                                bgColor={'#ffffff'}
                                fgColor={'#3498db'}
                                className="h-1/2 w-full"
                            />
                        </div>

                        <div className="mt-2 flex">
                            <div className="w-full text-center text-white">
                                <i className="fas fa-camera mr-1 text-[16px]"></i>{' '}
                                <span className="text-[12px]">{objectLanguage.VIEW_DETAIL}</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
