import { useEffect, useRef, useState } from 'react';
import { formatDatetime } from '../../../../../utils/Times';
import { GUEST } from '../asests/constants/key';
import { BsArrowReturnRight } from 'react-icons/bs';
import { FaGlobeAsia, FaLock } from 'react-icons/fa';
import EventGreetingService from '../PublishGreeting.service';
import ToastNotify from '../../../../../utils/Toast';

/* eslint-disable */
const dataDefault = {
    id: '',
    guestName: '',
    greeting: '',
    reply: '',
    isPublic: true,
    image: '',
    type: GUEST
}

export default function ModelApproval({ reloadData, data }) {

    const [dataSave, setDataSave] = useState(dataDefault);
    const [validReply, setValidReply] = useState('');
    const focusReply = useRef(null);
    const toastLoadingId = useRef(null);

    const handleChecked = (e) => {
        const { checked, name } = e.target;
        setDataSave({
            ...dataSave,
            [name]: checked
        })
    };

    const validateReply = (val = dataSave.reply) => {
        let isValid = true;
        if (val.length > 200) {
            setValidReply('Câu trả lời không được vượt quá 200 ký tự!');
            isValid = false;
        } else {
            setValidReply('');
        }
        if (!isValid) {
            if (focusReply) {
                focusReply.current.focus();
            }
        }
        return isValid;
    };

    const alreadySubmit = (val = dataSave.reply) => {
        let isValid = true;
        if (val.length > 200) {
            isValid = false;
        }
        return isValid;
    }

    const submitForm = async (e) => {
        if (validateReply()) {
            toastLoadingId.current = ToastNotify.Loading('Đang lưu lời chúc...');
            e.preventDefault();
            const bodyFormData = new FormData();
            bodyFormData.append('reply', dataSave.reply.trim());
            bodyFormData.append('isPublic', dataSave.isPublic);
            EventGreetingService.update(dataSave.id, bodyFormData)
                .then((data) => {
                    ToastNotify.Update(toastLoadingId.current, 'Lưu thành công');
                })
                .catch((err) => {
                    ToastNotify.Update(toastLoadingId.current, err?.message || 'Đã có lỗi xảy ra', 'error');
                })
                .finally(() => {
                    reloadData();
                    clearForm();
                });
        }
    };

    const clearForm = () => {
        setValidReply('');
    }

    useEffect(() => {
        if (data) {
            setDataSave({
                id: data._id,
                guestName: data.guestName,
                greeting: data.greeting,
                reply: data?.reply || '',
                isPublic: data.isPublic,
                image: data.image,
                type: data.type
            });
        }
    }, [data]);

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="modalApprovalGreeting"
            data-bs-backdrop="static"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-300 modal-header shrink-0 rounded-t-md">
                        <h5 className="text-[16px] font-bold leading-normal text-gray-800">
                            Trả lời & Chia sẻ lời chúc
                        </h5>
                        <button
                            type="button"
                            title="Đóng"
                            onClick={clearForm}
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="flex flex-col items-center justify-between w-full border-b border-gray-200 modal-content shrink-0 rounded-t-md p-[20px] gap-[10px] overflow-y-auto overflow-x-hidden h-[500px] md:h-fit md:min-h-[500px] md:max-h-[700px]">
                        <div className='w-full '>
                            <div className='relative'>
                                <div className="self-stretch flex-col justify-start items-start gap-1 flex h-full">
                                    <div
                                        className="self-stretch text-gray-900 text-lg font-semibold leading-tight mt-auto ">
                                        {dataSave.guestName}
                                    </div>
                                    <div className="relative self-stretch min-h-16 bg-gray-200 p-1 rounded-md">
                                        <span
                                            className="text-gray-800 text-sm leading-snug break-words "
                                        >
                                            {dataSave.greeting}
                                        </span>
                                        <p className='h-10'></p>
                                        <span className="absolute bottom-1 right-1 text-gray-700 text-xs leading-none italic" >
                                            Tạo lúc {formatDatetime(dataSave.createdAt)}
                                        </span>
                                    </div>
                                    {
                                        dataSave?.image &&
                                        <div className=' mt-0.5 h-40'>
                                            <img className='rounded-lg h-full'
                                                src={process.env.REACT_APP_SERVER_URL + dataSave.image} />
                                        </div>
                                    }
                                    <p className="text-[16px] text-black font-bold mt-1">
                                        Trả lời
                                    </p>
                                    <div className="self-stretch  flex">
                                        <span>
                                            <BsArrowReturnRight className='font-black  text-black text-3xl mr-1' />
                                        </span>
                                        <div className="block w-full">
                                            <div className="flex w-full">
                                                <div className="w-full">
                                                    <textarea
                                                        placeholder='VD: Cảm ơn bạn rất nhiều!'
                                                        rows={3}
                                                        className="mt-1 block w-full rounded-[0.375rem] border border-gray-300 bg-white p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-400 focus:outline-none focus:ring-1"
                                                        name="reply"
                                                        ref={focusReply}
                                                        value={dataSave?.reply}
                                                        onInput={(e) => {
                                                            setDataSave({
                                                                ...dataSave,
                                                                reply: e.target.value
                                                            })
                                                            validateReply(e.target.value);
                                                        }}
                                                    />
                                                    <span className="text-xs font-medium text-red-500">
                                                        {validReply && validReply}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h5 className='text-gray-900 mt-8'>Trạng thái</h5>
                            <div
                                className={`flex w-full justify-between ${dataSave.isPublic ? 'bg-blue-50' : 'bg-gray-50'}  items-center py-[1rem] shadow-sm transition-all`}
                            >
                                <div
                                    className={`flex items-center gap-[12px] max-w-[250px] md:max-w-[50rem] `}
                                >
                                    <span className={`rounded-full ${dataSave.isPublic ? 'bg-gray-200' : 'bg-gray-200'}  p-2.5`}>
                                        {
                                            dataSave.isPublic
                                                ?
                                                <FaGlobeAsia className='text-lg text-gray-900' />
                                                :
                                                <FaLock className='text-lg text-gray-900' />
                                        }
                                    </span>
                                    <div className=''>
                                        <p className="text-[16px] text-gray-900 font-semibold">
                                            {dataSave.isPublic ? 'Công khai' : "Chỉ mình tôi"}
                                        </p>
                                        <p className="text-xs font-medium pr-6">
                                            {dataSave.isPublic
                                                ? 'Tất cả mọi người có thể xem lời chúc này trên website thiệp của bạn.'
                                                : "Lời chúc sẽ không được hiển thị trên website thiệp của bạn. Chỉ bạn mới xem được lời chúc này."}
                                        </p>
                                    </div>
                                </div>
                                <label className="relative cursor-pointer">
                                    <input
                                        type="checkbox"
                                        className="sr-only peer"
                                        name='isPublic'
                                        value={dataSave.isPublic}
                                        checked={dataSave.isPublic}
                                        onChange={handleChecked}
                                    />
                                    <div className="flex items-center h-3 bg-gray-300 rounded-full peer w-11 after:absolute after:left-0 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all peer-checked:bg-blue-600 peer-checked:after:-left-1 peer-checked:after:translate-x-full"></div>
                                </label>
                            </div>

                        </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            onClick={clearForm}
                            className="hover:bg-gray-50 transition-all focus:bg-gray-50 active:bg-gray-50  inline-block rounded bg-white border-[1px] border-gray-300 px-5 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                        >
                            Đóng
                        </button>
                        <button
                            type="submit"
                            onClick={submitForm}
                            data-bs-dismiss={alreadySubmit() ? 'modal' : ''}
                            className={`bg-blue-600 text-white hover:bg-blue-700 focus:bg-blue-700 ml-1 inline-block rounded  px-6 py-2.5 text-xs font-medium uppercase leading-tight  shadow-md transition duration-150 ease-in-out  hover:shadow-lg   focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg`}
                        >
                            Lưu
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}