/* eslint-disable */
export const ACCESS_TOKEN_KEY = 'token'
export const REFRESH_TOKEN_KEY = 'rtoken'
export const HTTP_STATUS_CODE = {
	SUCCESS: 200,
	ERROR: 400,
	PAYMENT_REQUIRED: 402,
}
export const USER_ROLE = {
	GUEST: 'guest',
	NORMAL: 'normal',
	VIP: 'vip',
}
export const ROLE_ORDER = {
	guest: 0,
	normal: 1,
	vip: 2,
}
export const LIMIT_RESOUCE = {
	EVENT: 'event',
	EVENT_GUEST: 'event_guest',
	FILE_STORAGE: 'file_storage'
}
export const TEMPLATE_TYPE = {
	WEDDING: 'wedding',
	EVENT: 'event'
}
export const PACKAGE_ICON_TYPE = {
	VIP: 'vip',
	POPULAR: 'popular'
}