
const NAME_BANK = {
    Momo: 'Momo',
    TPBank: 'TPBank',
    VPBank: 'VPBank',
    ACB: 'ACB',
    AGRIBANK: 'Agribank',
    VIETCOMBANK: 'Vietcombank',    
};

export default NAME_BANK; 