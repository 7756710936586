/* eslint-disable */
import { useEffect, useState, useRef, MouseEvent } from 'react';

import {
    FaArrowsAltH,
    FaCaretDown,
    FaChartBar,
    FaEnvelope,
    FaEnvelopeOpenText,
    FaHourglass,
    FaInfo,
    FaSortAmountUpAlt,
    FaUser,
    FaUserCheck,
    FaUserClock,
    FaUserTie,
    FaUserTimes,
} from 'react-icons/fa';

import guestManagerService from '../guestManager.service';
import ToastNotify from '../../../../../utils/Toast';

const reverseStatus = (status) => {
    switch (status) {
        case 'Chưa gửi lời mời':
            return ' UNINVITED';
        case 'Đang gửi lời mời':
            return 'SENDING';
        case 'Đã gửi lời mời':
            return 'INVITED';
        case 'Đồng ý tham dự':
            return 'ACCEPT';
        case 'Từ chối tham dự':
            return 'DECLINE';
        case 'Đang chờ xác nhận':
            return 'VERIFYING';
        case 'Chấp nhận yêu cầu tham dự':
            return 'USER_ACCEPT';
        case 'Từ chối yêu cầu tham dự':
            return 'USER_DENY';
        default:
            return;
    }
};

const data = {
    datasets: [
        {
            data: [10, 20, 30],
            backgroundColor: ['red', 'blue', 'yellow'],
        },
    ],
    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: ['Red', 'Yellow', 'Blue'],
};

export default function GroupChartModal({
    groups,
    statisticalData,
    resetAndAddGroupQuery,
    event_id,
}) {
    const [mode, setMode] = useState('status');
    const [groupData, setGroupData] = useState(statisticalData[0]);
    const [compareData, setCompareData] = useState();
    const [compareMode, setCompareMode] = useState('status');

    useEffect(() => {
        setGroupData(statisticalData[0]);
    }, [statisticalData[0]]);

    const percentGuest = (sl, type) => {
        const totalTypeGuest = groupData?.groupVip + groupData?.groupNormal;
        const totalVerifiedGuest =
            groupData?.groupUninvited +
            groupData?.groupInvited +
            groupData?.groupSending +
            groupData?.groupAccept +
            groupData?.groupDecline;

        if (type == 'status') {
            if (totalVerifiedGuest == 0) {
                return 0;
            } else {
                return ((sl / totalVerifiedGuest) * 100).toFixed(1);
            }
        }
        if (type == 'type') {
            if (totalTypeGuest == 0) {
                return 0;
            } else {
                return ((sl / totalTypeGuest) * 100).toFixed(1);
            }
        }
    };

    const checkMode = (mode) => {
        if (mode == 'status') {
            return {
                text: 'Trạng thái phản hồi',
                icon: <FaEnvelope className="mr-1" />,
            };
        }
        if (mode == 'type') {
            return {
                text: 'Loại nhóm',
                icon: <FaUser className="mr-1" />,
            };
        }
        if (mode == 'verify_status') {
            return {
                text: 'Trạng thái xác nhận',
                icon: <FaUserClock className="mr-1" />,
            };
        }
    };

    const options = [
        {
            alias: 'status',
            name: 'Trạng thái phản hồi',
            icon: <FaEnvelope className="mr-1" />,
            action: () => {
                setMode('status');
            },
        },
        {
            alias: 'type',
            name: 'Loại nhóm',
            icon: <FaUser className="mr-1" />,
            action: () => {
                setMode('type');
            },
        },
        {
            alias: 'verify_status',
            name: 'Trạng thái xác nhận',
            icon: <FaUserClock className="mr-1" />,
            action: () => {
                setMode('type');
            },
        },
    ];

    const compareOptions = [
        {
            alias: 'status',
            name: 'Trạng thái phản hồi',
            icon: <FaEnvelope className="mr-1" />,
            action: () => {
                setCompareMode('status');
            },
        },
        {
            alias: 'type',
            name: 'Loại nhóm',
            icon: <FaUser className="mr-1" />,
            action: () => {
                setCompareMode('type');
            },
        },
        {
            alias: 'verify_status',
            name: 'Trạng thái xác nhận',
            icon: <FaUserClock className="mr-1" />,
            action: () => {
                setCompareMode('verify_status');
            },
        },
    ];

    const handleGetCompareData = async () => {
        await guestManagerService
            .getCompareData(event_id)
            .then((data) => {
                setCompareData(data[0]);
                setMode('compare');
            })
            .catch(() => {
                ToastNotify.Error('Có lỗi ngoài ý muốn xảy ra vui lòng thử lại sau!');
            });
    };

    const type = [
        {
            name: 'Khách thường',
            icon: <FaUser className="mr-1 text-gray-700" />,
            quantity: groupData?.groupNormal,
            percent: percentGuest(groupData?.groupNormal, 'type'),
            action: () => {
                resetAndAddGroupQuery({ vip: 'false' });
            },
            color: ['#cbd5e1', '#64748b'],
        },
        {
            name: 'Khách VIP',
            icon: <FaUserTie className="mr-1 text-violet-500" />,
            quantity: groupData?.groupVip,
            percent: percentGuest(groupData?.groupVip, 'type'),
            action: () => {
                resetAndAddGroupQuery({ vip: 'true' });
            },
            color: ['#ddd6fe', '#8b5cf6'],
        },
    ];

    const status = [
        {
            name: 'Chưa gửi lời mời',
            icon: <FaEnvelope className="mr-1 text-amber-500" />,
            quantity: groupData?.groupUninvited,
            percent: percentGuest(groupData?.groupUninvited, 'status'),
            action: () => {
                resetAndAddGroupQuery({ status: 'UNINVITED' });
            },
            color: ['#fde68a', '#f59e0b'],
        },
        {
            name: 'Đang gửi lời mời',
            icon: <FaHourglass className="mr-1 text-sky-500" />,
            quantity: groupData?.groupSending,
            percent: percentGuest(groupData?.groupSending, 'status'),
            action: () => {
                resetAndAddGroupQuery({ status: 'SENDING' });
            },
            color: ['#bae6fd', '#0ea5e9'],
        },
        {
            name: 'Đã gửi lời mời',
            icon: <FaEnvelopeOpenText className="mr-1 text-blue-500" />,
            quantity: groupData?.groupInvited,
            percent: percentGuest(groupData?.groupInvited, 'status'),
            action: () => {
                resetAndAddGroupQuery({ status: 'INVITED' });
            },
            color: ['#bfdbfe', '#3b82f6'],
        },
        {
            name: 'Đồng ý tham dự',
            icon: <FaUserCheck className="mr-1 text-green-500" />,
            quantity: groupData?.groupAccept,
            percent: percentGuest(groupData?.groupAccept, 'status'),
            action: () => {
                resetAndAddGroupQuery({ status: 'ACCEPT' });
            },
            color: ['#bbf7d0', '#22c55e'],
        },
        {
            name: 'Từ chối tham dự',
            icon: <FaUserTimes className="mr-1 text-red-500" />,
            quantity: groupData?.groupDecline,
            percent: percentGuest(groupData?.groupDecline, 'status'),
            action: () => {
                resetAndAddGroupQuery({ status: 'DECLINE' });
            },
            color: ['#fecaca', '#ef4444'],
        },
    ];
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="groupChartModal"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered modal-lg">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-xl font-bold leading-normal text-gray-800"
                            id="exampleModalScrollableLabel"
                        >
                            <FaChartBar className="mr-1" /> Thống kê ( Nhóm khách mời )
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:opacity-200 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="relative p-4 modal-body ">
                        {/* <div className="flex items-center justify-between">
                            {mode == 'compare' && (
                                <div className="flex items-center mt-5">
                                    <p className="text-sm font-bold ">So sánh theo:</p>
                                    <div>
                                        <button
                                            className=" dropdown dropdown-toggle relative ml-1 flex w-full items-center  whitespace-nowrap rounded bg-blue-600 px-2 py-1.5 text-center text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:text-white active:shadow-lg "
                                            type="button"
                                            id="dropdownMenuButton3"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {checkMode(compareMode).icon}
                                            {checkMode(compareMode).text}
                                            <FaCaretDown className="ml-1" />
                                        </button>
                                        <ul
                                            className="dropdown-menu absolute z-[99] float-left m-0 mt-1 hidden min-w-max list-none rounded border-none bg-white bg-clip-padding text-center text-base shadow-lg "
                                            aria-labelledby="dropdownMenuButton3"
                                        >
                                            {compareOptions.map(
                                                (option) =>
                                                    option.alias != compareMode && (
                                                        <li onClick={option.action}>
                                                            <span className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-transparent cursor-pointer dropdown-item whitespace-nowrap hover:bg-gray-300">
                                                                {option.icon}
                                                                {option.name}
                                                            </span>
                                                        </li>
                                                    )
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            )}
                            {mode == 'compare' ? (
                                <button
                                    onClick={() => {
                                        setMode('status');
                                    }}
                                    className="dropdown dropdown-toggle relative ml-1 mt-5 flex items-center  whitespace-nowrap rounded bg-blue-600 px-2 py-1.5 text-center text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:text-white active:shadow-lg"
                                >
                                    <FaChartBar className="mr-1" /> Thống kê
                                </button>
                            ) : (
                                <button
                                    onClick={handleGetCompareData}
                                    className="dropdown dropdown-toggle relative ml-1 mt-5 flex items-center  whitespace-nowrap rounded bg-blue-600 px-2 py-1.5 text-center text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:text-white active:shadow-lg"
                                >
                                    <FaSortAmountUpAlt className="mr-1" /> So sánh
                                </button>
                            )}
                            
                        </div> */}
                        <div className="flex flex-col items-start gap-[20px] mb-5">
                            <p className="text-base font-bold text-black">Loại khách</p>
                            <div className="flex gap-[20px] flex-wrap">
                                {type.map((statusItem) => (
                                    <div className="w-[370px] flex flex-col h-[140px] shadow-xl p-4 bg-white rounded-md border border-gray-200 gap-[4px]">
                                        <div className="flex items-start justify-between w-full">
                                            <div className="flex items-center gap-4">
                                                <div
                                                    className="p-4 text-[24px] rounded-md "
                                                    style={{ backgroundColor: statusItem.color[0] }}
                                                >
                                                    {statusItem.icon}
                                                </div>
                                                <div className="flex flex-col gap-1">
                                                    <p className="text-base font-semibold text-black">
                                                        {statusItem.name}
                                                    </p>
                                                    <p className="text-2xl font-bold text-black">
                                                        {statusItem.quantity}
                                                    </p>
                                                </div>
                                            </div>
                                            <button
                                                className="text-sm font-bold text-blue-700"
                                                onClick={statusItem.action}
                                                data-bs-dismiss="modal"
                                            >
                                                Chi tiết
                                            </button>
                                        </div>
                                        <div className="w-full flex flex-col gap-[8px] items-end">
                                            <div
                                                className="px-2 py-1 text-sm rounded-full"
                                                style={{
                                                    backgroundColor: statusItem.color[0],
                                                    color: statusItem.color[1],
                                                }}
                                            >
                                                {statusItem.percent}%
                                            </div>
                                            <div
                                                className="flex w-full h-2 overflow-hidden text-xs rounded"
                                                style={{ backgroundColor: statusItem.color[0] }}
                                            >
                                                <div
                                                    style={{
                                                        width: `${statusItem.percent}%`,
                                                        backgroundColor: statusItem.color[1],
                                                    }}
                                                    className="flex flex-col justify-center shadow-none whitespace-nowrap"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col items-start gap-[20px]">
                            <p className="text-base font-bold text-black">Trạng thái</p>
                            <div className="flex gap-[20px] flex-wrap">
                                {status.map((statusItem) => (
                                    <div className="w-[370px] flex flex-col h-[140px] shadow-xl p-4 bg-white rounded-md border border-gray-200 gap-[4px]">
                                        <div className="flex items-start justify-between w-full">
                                            <div className="flex items-center gap-4">
                                                <div
                                                    className="p-4 text-[24px] rounded-md "
                                                    style={{ backgroundColor: statusItem.color[0] }}
                                                >
                                                    {statusItem.icon}
                                                </div>
                                                <div className="flex flex-col gap-1">
                                                    <p className="text-base font-semibold text-black">
                                                        {statusItem.name}
                                                    </p>
                                                    <p className="text-2xl font-bold text-black">
                                                        {statusItem.quantity}
                                                    </p>
                                                </div>
                                            </div>
                                            <button
                                                className="text-sm font-bold text-blue-700"
                                                onClick={statusItem.action}
                                                data-bs-dismiss="modal"
                                            >
                                                Chi tiết
                                            </button>
                                        </div>
                                        <div className="w-full flex flex-col gap-[8px] items-end">
                                            <div
                                                className="px-2 py-1 text-sm rounded-full"
                                                style={{
                                                    backgroundColor: statusItem.color[0],
                                                    color: statusItem.color[1],
                                                }}
                                            >
                                                {statusItem.percent}%
                                            </div>
                                            <div
                                                className="flex w-full h-2 overflow-hidden text-xs rounded"
                                                style={{ backgroundColor: statusItem.color[0] }}
                                            >
                                                <div
                                                    style={{
                                                        width: `${statusItem.percent}%`,
                                                        backgroundColor: statusItem.color[1],
                                                    }}
                                                    className="flex flex-col justify-center shadow-none whitespace-nowrap"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const CompareTable = ({
    groups,
    compareData,
    percentGuest,
    resetAndAddGroupQuery,
    compareMode,
}) => {
    const tableView = (mode, group = {}) => {
        if (mode == 'status') {
            return {
                header: [
                    'Tên nhóm',
                    'Tất cả',
                    'Chưa gửi lời mời',
                    'Đang gửi lời mời',
                    'Đã gửi lời mời',
                    'Đồng ý tham dự',
                    'Từ chối tham dự',
                ],
                body: [
                    group.fullname,
                    groupItemData(group).total,
                    groupItemData(group).uninvited,
                    groupItemData(group).sending,
                    groupItemData(group).invited,
                    groupItemData(group).accept,
                    groupItemData(group).decline,
                ],
            };
        }
        if (mode == 'type') {
            return {
                header: ['Tên nhóm', 'Tất cả', 'Khách thường', 'Khách VIP'],
                body: [
                    group.fullname,
                    groupItemData(group).total,
                    groupItemData(group).normal,
                    groupItemData(group).vip,
                ],
            };
        }
        if (mode == 'verify_status') {
            return {
                header: [
                    'Tên nhóm',
                    'Đang chờ xác nhận',
                    'Chấp nhận yêu cầu tham dự',
                    'Từ chối yêu cầu tham dự',
                ],
                body: [
                    group.fullname,
                    groupItemData(group).verifying,
                    groupItemData(group).user_accept,
                    groupItemData(group).user_deny,
                ],
            };
        }
    };
    const groupItemData = (group) => {
        const newData = {
            total:
                compareData.total.find((compareDataItem) => compareDataItem?._id[0] == group._id)
                    ?.count || 0,
            uninvited:
                compareData.uninvited.find(
                    (compareDataItem) => compareDataItem?._id[0] == group._id
                )?.count || 0,
            sending:
                compareData.sending.find((compareDataItem) => compareDataItem?._id[0] == group._id)
                    ?.count || 0,
            invited:
                compareData.invited.find((compareDataItem) => compareDataItem?._id[0] == group._id)
                    ?.count || 0,
            accept:
                compareData.accept.find((compareDataItem) => compareDataItem?._id[0] == group._id)
                    ?.count || 0,
            decline:
                compareData.decline.find((compareDataItem) => compareDataItem?._id[0] == group._id)
                    ?.count || 0,
            vip:
                compareData.vip.find((compareDataItem) => compareDataItem?._id[0] == group._id)
                    ?.count || 0,
            normal:
                compareData.normal.find((compareDataItem) => compareDataItem?._id[0] == group._id)
                    ?.count || 0,
            verifying:
                compareData.verifying.find(
                    (compareDataItem) => compareDataItem?._id[0] == group._id
                )?.count || 0,
            user_accept:
                compareData.user_accept.find(
                    (compareDataItem) => compareDataItem?._id[0] == group._id
                )?.count || 0,
            user_deny:
                compareData.user_deny.find(
                    (compareDataItem) => compareDataItem?._id[0] == group._id
                )?.count || 0,
        };
        return newData;
    };

    return (
        <div className="my-5 overflow-auto bg-white rounded shadow-xl">
            <table className="w-full table-auto min-w-max">
                <thead>
                    <tr className="text-xs leading-normal text-gray-600 uppercase bg-gray-200">
                        {tableView(compareMode).header.map((tableHeader) => (
                            <th className="px-6 py-3 text-center">{tableHeader}</th>
                        ))}
                    </tr>
                </thead>
                <tbody className="text-sm font-light text-gray-600">
                    {groups.map((group) => (
                        <tr className="text-sm leading-normal text-black bg-white">
                            {tableView(compareMode, group).body.map((tableBody) => (
                                <th className="px-6 py-3 text-center">{tableBody}</th>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
