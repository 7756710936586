/* eslint-disable */
import { useEffect, useState } from 'react';
import { getDateOfMonth, getDateOfWeek, getMonthOfYear, getYear } from '../../../../utils/Times';

import envConstants from '../../../../config/key';
import { useParams } from 'react-router-dom';

import { FaEnvelopeOpenText } from 'react-icons/fa';
import IMAGE_EVENT13 from '../assets/images/index';
import '../assets/styles/style.css';
import { statusConvert } from '../../../_index/helper';

const TiltlePage = ({
    data = {},
    guest,
    guestFullname = '',
    salutation = '',
    guestTitle = '',
    eventConfig,
}) => {
    const dataCopy = {
        invitation: data.invitation,
        titleEvent: data.titleEvent,
        nameEvent: data.nameEvent,
        logo: data.logo && data.logo[0],
        placeHold: data.placeHold,
        addressHold: data.addressHold,
        date: data.dateHold,
        time: data.timeHold,
        nameSupporter: data.nameSupporter,
        phoneSupporter: data.phoneSupporter,
        dress_1: data?.dress_1 ? data?.dress_1 : '',
        dress_2: data?.dress_2 ? data?.dress_2 : '',
        linkGgMap: data?.linkGgMap ?? '',
    };
    const { url_share, url_guest } = useParams();

    const [pathImgLogo, setPathImgLogo] = useState('');

    useEffect(() => {
        if (
            !/^blob:/.test(dataCopy?.logo) &&
            !/^data:image/.test(dataCopy?.logo) &&
            !/^\/static\/media/.test(dataCopy?.logo) &&
            dataCopy?.logo !== undefined &&
            dataCopy?.logo !== '' &&
            dataCopy?.logo !== null &&
            dataCopy?.logo !== false
        ) {
            setPathImgLogo(envConstants.SERVER_URL + dataCopy?.logo);
        } else if (dataCopy?.logo) {
            setPathImgLogo(dataCopy?.logo);
        } else {
            setPathImgLogo('');
        }
    }, [dataCopy?.logo]);

    return (
        <>
            <div className="relative mx-auto w-full ">
                <div className="mx-auto mt-[calc(0.5rem_+_3vh)] flex flex-col items-center w-full bg-left bg-contain bg-no-repeat">
                    {/* <div className='lg:bg-transparent min-h-screen w-full lg:w-3/4 flex flex-col items-center justify-center'> */}
                    <div className="w-full">
                        {pathImgLogo && (
                            <img
                                className="mx-auto w-[70px] lg:w-[90px]"
                                src={pathImgLogo}
                                loading="lazy"
                                alt="logo"
                            />
                        )}
                    </div>
                    {/* <div className={'px-5'}>
                        <p className="text-[calc(0.5rem_+_4vh)] font-greatVibes text-center text-white">

                        </p>
                    </div> */}
                    <div>
                        <p className="text-[calc(0.5rem_+_4vh)] text-center leading-[40px] font-greatVibes text-white">
                            {dataCopy?.titleEvent}
                        </p>
                    </div>
                    <div className={'mt-8 text-center tracking-[1px] text-yellow-200 font-bold '}>
                        <p
                            className={
                                'text-center text-[calc(0.5rem_+_1.5vh)] font-fairplay text-yellow-200  '
                            }
                        >
                            {dataCopy?.invitation}
                        </p>
                    </div>
                    <div className="flex justify-center mt-3 mb-[calc(0.5rem_+_3vh)]">
                        <p
                            className={
                                'text-center text-[calc(0.5rem_+_2.5vh)] leading-[40px] text-yellow-200 font-prata'
                            }
                        >
                            {dataCopy?.nameEvent}
                        </p>
                    </div>
                    <div className="w-full text-center">
                        <p className="text-lg lg:text-xl font-extrabold text-white text-center uppercase mb-[calc(0.5rem_+_0.25vh)]">
                            Thời gian
                        </p>
                        <p className=" text-lg lg:text-xl text-white text-center">
                            {dataCopy?.time && dataCopy?.time}{' '}
                            {dataCopy?.date &&
                                getDateOfWeek(dataCopy?.date) +
                                    ', ngày ' +
                                    getDateOfMonth(dataCopy?.date) +
                                    ' tháng ' +
                                    getMonthOfYear(dataCopy?.date) +
                                    ' năm ' +
                                    getYear(dataCopy?.date)}
                        </p>
                    </div>

                    <div className="w-full text-center mt-4">
                        {(dataCopy?.placeHold || dataCopy?.addressHold) && (
                            <p className="text-lg lg:text-xl text-center tracking-[1px] text-white font-extrabold uppercase mb-[calc(0.5rem_+_0.25vh)]">
                                Địa điểm
                            </p>
                        )}
                        <p className="mt-0.5 w-full text-xl text-white text-center">
                            {dataCopy?.placeHold}
                        </p>
                        <p className="w-full text-xl text-white text-center">
                            {dataCopy?.addressHold}{' '}
                            {(dataCopy?.addressHold || dataCopy?.linkGgMap) && (
                                <a
                                    href={`${
                                        dataCopy?.linkGgMap
                                            ? dataCopy?.linkGgMap
                                            : `${
                                                  'https://www.google.com/maps/search/?api=1&query=' +
                                                  dataCopy?.addressHold
                                              }`
                                    }`}
                                    rel="noreferrer"
                                    target="_blank"
                                    className={
                                        'ml-1 border-yellow-200 text-sm lg:text-[17px] text-yellow-200  hover:border-b '
                                    }
                                    title="Xem trên bản đồ"
                                >
                                    <i className="fas fa-map-marker-alt"></i>
                                </a>
                            )}
                        </p>
                    </div>

                    <div className="flex items-center mt-3 justify-center">
                        {url_guest && url_share && eventConfig?.auto_response && (
                            <a
                                href={'/' + url_share + '/' + url_guest + '/xac-nhan'}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <button className=" flex items-center rounded bg-red-500 px-4 py-1.5 text-[calc(0.5rem_+_0.7vh)] font-semibold text-yellow-100 transition hover:bg-red-700 ">
                                    {/* <FaEnvelopeOpenText className="mr-2" /> Xác nhận tham dự */}
                                    {statusConvert(guest?.status)}
                                </button>
                            </a>
                        )}
                        {!url_guest && url_share && eventConfig?.auto_join && (
                            <button
                                className=" flex items-center rounded bg-red-500 px-4 py-1.5 text-[calc(0.5rem_+_0.7vh)] font-semibold text-yellow-100 transition-all hover:bg-red-700 "
                                data-bs-toggle="modal"
                                data-bs-target="#formModal"
                            >
                                <FaEnvelopeOpenText className="mr-2" /> Đăng kí tham dự
                            </button>
                        )}
                    </div>

                    <div className={'mt-2 text-center mb-3'}>
                        <span className="text-2xl font-greatVibes font-medium  text-white">
                            Rất hân hạnh được đón tiếp quý khách!
                        </span>
                    </div>

                    {dataCopy?.dress_1 || dataCopy?.dress_2 ? (
                        <div>
                            <p className="text-xs lg:text-sm text-center tracking-[1px] text-white font-extrabold mb-1">
                                Trang phục:
                            </p>
                            {dataCopy?.dress_1 ? (
                                <p className="w-full text-xs lg:text-sm text-white text-center italic">
                                    {dataCopy?.dress_1}
                                </p>
                            ) : null}
                            {dataCopy?.dress_2 ? (
                                <p className="w-full text-xs lg:text-sm text-white text-center italic">
                                    {dataCopy?.dress_2}
                                </p>
                            ) : null}
                        </div>
                    ) : null}

                    {(dataCopy?.phoneSupporter || dataCopy?.nameSupporter) && (
                        <p className="mt-14 lg:mt-[calc(0.5rem_+_0.5vh)] text-center  italic text-white text-xs lg:text-sm">
                            Liên hệ hỗ trợ:{' '}
                            <a
                                className="cursor-pointer  font-bold text-white underline   hover:text-yellow-200  "
                                href={`tel:${dataCopy?.phoneSupporter}`}
                            >
                                {dataCopy?.phoneSupporter}
                            </a>{' '}
                            ({dataCopy?.nameSupporter})
                        </p>
                    )}
                </div>
            </div>
        </>
    );
};

export default TiltlePage;
