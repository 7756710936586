/* eslint-disable */
// Viết hoa chữ cái đầu va bo dau cach
export const Capitalize = (name) => {
    if (typeof name !== 'string') return '';

    name = name.toLocaleLowerCase();
    var arrName = name.split(' ');
    var resultName = '';
    for (var i = 0; i < arrName.length; i++) {
        if (arrName[i] !== '') {
            resultName += (arrName[i].charAt(0).toUpperCase() + arrName[i].slice(1));
            resultName += ' ';
        }
    }
    return resultName.trim();
};

// loai bo dau Tieng Viet va dau cach o giua
export const removeAccents = (str) => str.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/đ/g, 'd').replace(/Đ/g, 'D')
        .replaceAll(' ', '');

export const removeAllSpace = (textInput) => {
	if (typeof textInput !== 'string' || !textInput) return '';
	const arrText = textInput.split(' ');
	let result = '';
	for (let i = 0; i < arrText.length; i++) {
		if (arrText[i] !== '') {
			result += arrText[i];
		}
	}
	return result.trim();
};

