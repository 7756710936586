import MAIL_CLOCK from './mail-clock.png';
import TIME_CHANGE from './time-change.png';


const IMAGES = {
    MAIL_CLOCK,
    TIME_CHANGE
};

export default IMAGES;
