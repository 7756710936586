
import axios from './axios';

const EventGreetings = {
    getAll: (eventId, query = {}) =>
        axios({
            url: `/event-greeting/get/${eventId}`,
            method: 'GET',
            params: query,
        }),

    analysis: (eventId) =>
        axios({
            url: `/event-greeting/analysis/${eventId}`,
            method: 'GET',
        }),

    getNew: (eventId) =>
        axios({
            url: `/event-greeting/get-new/${eventId}`,
            method: 'GET',
        }),

    getAllByGuest: (slugEvent) =>
        axios({
            url: `/event-greeting/get-by-guest/${slugEvent}`,
            method: 'GET',
        }),

    getOneByGuest: (slugEvent, slugGuest) =>
        axios({
            url: `/event-greeting/find-by-guest/${slugEvent}/${slugGuest}`,
            method: 'GET',
        }),

    getOne: (id) =>
        axios({
            url: `/event-greeting/${id}`,
            method: 'GET',
        }),

    create: (payload) =>
        axios({
            url: '/event-greeting/',
            method: 'POST',
            data: payload,
            headers: { 'Content-Type': 'multipart/form-data' },
        }),

    createByGuest: (payload) =>
        axios({
            url: '/event-greeting/create-by-guest',
            method: 'POST',
            data: payload,
            headers: { 'Content-Type': 'multipart/form-data' },
        }),

    update: (id, payload) =>
        axios({
            url: `/event-greeting/${id}`,
            method: 'PATCH',
            data: payload,
            headers: { 'Content-Type': 'multipart/form-data' },
        }),

    delete: async (id) =>
        axios({
            url: `/event-greeting/${id}`,
            method: 'DELETE',
        })
    ,
};

export default EventGreetings;

