/* eslint-disable */
const INPUT_ALIAS = {
    wedding: 'thiep-cuoi',
    wedding_1: 'wedding-1',
    wedding_2: 'wedding-2',
    wedding_3: 'wedding-3',
    wedding_4: 'wedding-4',
    wedding_5: 'wedding-5',
    wedding_6: 'wedding-6',
    wedding_7: 'wedding-7',
    wedding_8: 'wedding-8',
    wedding_9: 'wedding-9',
    wedding_10: 'wedding-10',
    wedding_12: 'wedding-12',
    wedding_13: 'wedding-13',
    wedding_14: 'wedding-14',
    wedding_15: 'wedding-15',

    event: 'thiep-su-kien',
    event_1: 'event-1',
    event_2: 'event-2',
    event_3: 'event-3',
    event_4: 'event-4',
    event_5: 'event-5',
    event_6: 'event-6',
    event_7: 'event-7',
    event_8: 'event-8',
    event_9: 'event-9',
    event_11: 'event-11',
    event_12: 'event-12',
    event_13: 'event-13',
};

export default INPUT_ALIAS;
