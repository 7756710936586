/* eslint-disable */
const Regex = {
    // email: new RegExp(/^[a-z0-9](\.?[a-z0-9_-]){3,100}@[a-z0-9]{2,}([a-z0-9.]{2,4}){1,2}$/),
    email: new RegExp(/^[A-Za-z0-9](\.?[a-zA-Z0-9_-]){1,100}@[a-z0-9-]{2,}([a-z0-9.]{2,4}){1,2}$/),
    phone_number: new RegExp(/([\+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9 ]{8})\b/),
    str: new RegExp(
        /^[a-zA-Z_ÀÁÂÃÈÉÊẾÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêếìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]*$/g
    ),
    url: new RegExp(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.])([/\w .-]*)*\/?$/),
    linkGgMap: new RegExp(
        'https?://(?:www\\.)?[a-zA-Z0-9\\-\\.]+\\.[a-zA-Z]{2,}(?:/[\\w\\-\\.]*)*(?:\\?.*)?(?:#.*)?'
    ),
    keyYoutube: new RegExp(/^[a-zA-Z0-9_-]{11}$/),
    password: new RegExp(/^.{6,}$/),
};

export default Regex;
