import { useNavigate } from 'react-router-dom';
import img from '../images/icon.png';
import PAGE from '../../../config/path.route';
import { MdDashboardCustomize } from 'react-icons/md';
export default function ContactUs() {
    const navigate = useNavigate();
    return (
        <div className="mx-auto  flex h-[auto] w-full flex-col items-center justify-between gap-[40px] rounded-[40px] bg-blue-500 p-[20px] lg:max-w-[1180px] lg:flex-row lg:rounded-full lg:px-[40px]">
            <div className="flex flex-col items-center gap-4 lg:flex-row">
                <img src={img} alt="icon" />
                <p className="flex max-w-[680px] flex-col justify-start gap-2 text-center text-2xl font-bold text-white lg:text-left">
                    <span className="text-[20px]">Bạn đã sẵn sàng để bắt đầu?</span>
                </p>
            </div>
            <button
                className="rounded-full bg-white px-10 py-4 text-xl font-bold text-blue-500 transition hover:scale-105 flex items-center gap-2"
                onClick={() => {
                    navigate(PAGE.suggest);
                }}
            >
                <MdDashboardCustomize />
                Tạo thiệp ngay
            </button>
        </div>
    );
}
