/* eslint-disable */
export default function GuestManagerLoading() {
    return (
        <>
            <div className="mx-4 md:mx-20 lg:mx-32  justify-start items-start flex flex-col relative">
                <div className="flex gap-4">
                    <div className="self-stretch text-gray-500 rounded-md h-10 w-44 bg-trueGray-300 animate-pulse"></div>
                    <div className="self-stretch text-gray-500 rounded-md  h-10 w-44 bg-trueGray-300 animate-pulse"></div>
                </div>
                <div className="mt-6 self-stretch text-gray-500 rounded-md h-14 w-[25rem] bg-trueGray-300 animate-pulse"></div>
                <div className="mt-6 flex flex-col md:flex-row gap-6">
                    <div className=" self-stretch text-gray-500 rounded-md  h-32 w-[23rem] bg-trueGray-300 animate-pulse"></div>
                    <div className=" self-stretch text-gray-500 rounded-md  h-32 w-[23rem] bg-trueGray-300 animate-pulse"></div>
                    <div className=" self-stretch text-gray-500 rounded-md  h-32 w-[23rem] bg-trueGray-300 animate-pulse"></div>
                    <div className=" self-stretch text-gray-500 rounded-md  h-32 w-[23rem] bg-trueGray-300 animate-pulse"></div>
                </div>
                <div className="mt-8 self-stretch text-gray-500 rounded-md h-10 w-[29rem] bg-trueGray-300 animate-pulse"></div>
                <div className="mt-8 self-stretch text-gray-500 rounded-md h-44 w-full bg-trueGray-300 animate-pulse"></div>
            </div>
        </>
    );
}
