import { FaInfoCircle } from 'react-icons/fa';

export default function ModalConfirmPaidOrderNew({ isOpen, funToggle, handleConfirm }) {
    return (
        <div
            className={`fade modal fixed left-0 top-0 z-[1056]  h-full w-full overflow-y-auto overflow-x-hidden outline-none ${
                isOpen ? 'show' : ''
            } backdrop-brightness-50`}
            tabIndex="-1"
            style={{
                margin: 0,
                visibility: isOpen ? 'visible' : 'hidden',
                transition: 'opacity 0.15s linear, visibility 0.5s linear',
            }}
            aria-modal="true"
            role="dialog"
        >
            <div className="modal-dialog modal-dialog-centered pointer-events-none relative w-auto">
                <div className="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
                    <div className="modal-header flex shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4">
                        <h5 className="text-xl font-bold leading-normal text-gray-800">
                            Xác nhận đã thanh toán
                        </h5>
                        <button
                            type="button"
                            className="btn-close box-content h-4 w-4 rounded-none border-none p-1 text-black opacity-50 hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            onClick={funToggle}
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-header flex flex-col shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4">
                        {/* <svg viewBox="0 0 24 24" className="text-green-600 w-20 h-20 mx-auto mb-3">
                            <path fill="currentColor"
                                d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
                            </path>
                            
                        </svg> */}
                        <FaInfoCircle className="text-[80px] text-blue-500 mb-3" />
                        <h5 className="text-left text-lg font-medium leading-normal text-gray-800">
                            Bạn có chắc rằng mình đã thanh toán đơn hàng này chưa?
                        </h5>
                    </div>
                    <div className="modal-footer flex shrink-0 flex-wrap items-center justify-end rounded-b-md border-t border-gray-200 p-4">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            onClick={funToggle}
                        >
                            Thoát
                        </button>
                        <button
                            type="button"
                            onClick={handleConfirm}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            Xác nhận
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
