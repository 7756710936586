import ICON_MAIL from './icon_mail2.png';
import ICON_MAIL_CLOSE from './mail_close.jpg';
import ICON_MAIL_OPEN from './mail_open.jpg';


const IMAGES = {
    ICON_MAIL,
    ICON_MAIL_CLOSE,
    ICON_MAIL_OPEN
};

export default IMAGES;
