import { useEffect, useState } from 'react';

// import IMAGES_WEDDING from '../../resource/wedding/images/_index';
// import PublishBankService from './PublishBank.service';
// import Templates from '../events/components/Templates';
import { useParams, useNavigate } from 'react-router-dom';
import themeCommon from '../../PublishMainMenu.config';
import PAGE from '../../../../config/path.route';
// import { setContentMeta } from '../../utils/MetaTag';
// import { Helmet } from 'react-helmet'
import { Helmet } from 'react-helmet-async';
import EventsService from '../../../events/Events.service';

const parseJsonData = (formData) => {
    // parse json
    const dataCopy = formData;
    let arrParse = [];
    let arrCopy = [];
    let keyName = '';
    // infoParty
    if (dataCopy?.infoParty) {
        arrCopy = dataCopy.infoParty;
        keyName = 'infoParty';
    } else {
        //  programes
        arrCopy = dataCopy.programes;
        keyName = 'programes';
    }
    if (Array.isArray(arrCopy)) {
        for (var idx = 0; idx < arrCopy.length; idx++) {
            if (typeof arrCopy[idx] === 'string') {
                arrParse.push(JSON.parse(arrCopy[idx]));
            } else {
                arrParse.push(arrCopy[idx]);
            }
        }
    } else {
        if (arrCopy) {
            arrParse.push(JSON.parse(arrCopy));
        }
    }
    return { ...dataCopy, [keyName]: arrParse };
};

export default function PublishBank() {
    const navigate = useNavigate();
    const { eventId, url_guest : urlGuest } = useParams();
    const [formData, setFormData] = useState({});
    const [templateAlias, setTemplateAlias] = useState('');
    // const [guest, setGuest] = useState(undefined);
    // const [eventConfig, setEventConfig] = useState();
    const [valueMeta, setValueMeta] = useState({
        title: '',
        thumbnail: '',
    });

    useEffect(() => {
        if (eventId) {
            // const payload = { slug: eventId };
            // if (urlGuest) {
            //     payload.guest = urlGuest;
            // }
            // PublishBankService.getPublic(payload)
            EventsService.getOneEventById(eventId)
                .then(({ data }) => {
                //     const { templateAlias, formData, guest, title, thumbnail, data, eventConfig 
                // } =
                //         res;
                    const title = data.form_data.titleMeta;
                    const thumbnail = data.thumbnail_url;
                    const templateAlias = data.template.alias;
                    const formData = data.form_data;
                    // setContentMeta({ title, image: thumbnail })
                    setValueMeta({
                        title,
                        thumbnail,
                    });
                    setTemplateAlias(templateAlias);
                    // setGuest(guest);

                    setFormData(parseJsonData({ ...data, ...formData }));
                    // setEventConfig(eventConfig);
                })
                .catch((error) => {
                    const AuthorizeError = 404;
                    if (error.status === AuthorizeError) {
                        navigate(PAGE.dashboard);
                    }
                    const ServerError = 500;
                    if (error.status === ServerError) {
                        navigate(PAGE.dashboard);
                    }
                });
        }
    }, [eventId, urlGuest, navigate, templateAlias]);

    return (
        <div>
            <Helmet>
                <title>{valueMeta.title + ' | ' + 'Tài khoản'}</title>
                <meta property="og:image" content={valueMeta.thumbnail} />
            </Helmet>
            <div className={'h-[100dvh] w-[100vw] '+themeCommon[templateAlias]?.background?.color }>
                <div className={
                    themeCommon[templateAlias]?.header.font 
                    + ' text-6xl text-center mx-auto '
                    + themeCommon[templateAlias]?.header.color
                }>{formData?.nameTemplate}</div>
                <div className={
                    themeCommon[templateAlias]?.header.font 
                    + ' text-center mx-auto '
                    + themeCommon[templateAlias]?.header.color
                }>{formData?.template?.name}</div>
            </div>
        </div>
    );
}
