
import EventTemplate from '../../api/event.template.api';
import { eventAnalyze } from '../EventDetail/services/eventService';

const PublishMainMenuService = {
    shareEventTemplate: async (id, data) => {
        const result = await EventTemplate.export(id, data);
        return result;
    },
    getPublic: async (payload) => {
        const { data: response } = await EventTemplate.getPublicEvent(payload);
        const { data } = response;

        // means has Guest Info
        if (
            Object.prototype.hasOwnProperty.call(data, 'event') 
            && Object.prototype.hasOwnProperty.call(data, 'guest')) {
            const { event, guest, eventConfig } = data;

            return {
                guest,
                eventConfig,
                ev: event,
                formData: event.form_data,
                templateAlias: event.template.alias,
                title: event.title,
                thumbnail: event.thumbnail_url,
            };
        }

        const { event, eventConfig } = data;
        return {
            eventConfig,
            data: event,
            formData: event.form_data,
            templateAlias: event.template.alias,
            title: event.title,
            thumbnail: event.thumbnail_url,
        };
    },

    getStatic: async (eventId) => {
        const result = await eventAnalyze(
            eventId, { include: ['stats', 'template_info', 'guests'] });
            return JSON.stringify(result.data.stats);
    }
};

export default PublishMainMenuService;
