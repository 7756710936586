/* eslint-disable */
import axios from '../../../api/axios';

export const addNewGuests = async (payload) => {
    const response = await axios({
        url: '/event-guest',
        method: 'POST',
        data: payload,
    });
    return response.data;
};

export const updateGuest = async (id, payload) => {
    const response = await axios({
        url: '/event-guest/' + id,
        method: 'PATCH',
        data: payload,
    });
    return response.data;
};

export const deleteGuest = async (id) => {
    const response = await axios({
        url: '/event-guest/' + id,
        method: 'DELETE',
    });
    return response.data;
};

export const getGuests = async (query) => {
    const response = await axios({
        url: '/event-guest',
        method: 'GET',
        params: query,
    });
    return response.data;
};

export const importExcelGuest = async (formData) => {
    const response = await axios({
        url: '/event-guest/import',
        method: 'POST',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
};

export const importExcelByGuest = async (formData) => {
    const response = await axios({
        url: '/event-guest/import-by-guest',
        method: 'POST',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
};

export const importExcelGroup = async (formData) => {
    console.log(formData);
    const response = await axios({
        url: '/group-guest/import',
        method: 'POST',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
};

export const downloadExcel = async (query) => {
    const payload = {
        ...(query.status != '' && { status: query.status }),
        ...(query.fullname != '' && { fullname: query.fullname }),
        ...(query.vip != '' && { vip: query.vip }),
        ...(query.group != '' && { group: query.group }),
    };
    const response = await axios({
        url: '/event-guest/' + query.event_id + '/download',
        method: 'POST',
        responseType: 'blob',
        data: payload,
    });
    const headerLine = response.headers['content-disposition'];
    const startFileNameIndex = headerLine.indexOf('"') + 1;
    const endFileNameIndex = headerLine.lastIndexOf('"');
    const filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
    return { fileData: response.data, filename };
};
