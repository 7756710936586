/* eslint-disable */
import { getGuests } from '../views/user.events/services/modalService';
import axios from './axios';

const Events = {
    getAll: (query = {}) => {
        return axios({
            url: '/event',
            method: 'GET',
            params: query,
        });
    },

    getOne: (id) => {
        return axios({
            url: `/event/${ id }`,
            method: 'GET',
        });
    },

    init: (nameTemplate, templateId, nameEvent = null, nameBride = null, nameGroom = null) => {
        return axios({
            url: '/event/',
            method: 'POST',
            data: {
                nameTemplate,
                templateId,
                nameEvent: nameEvent ? nameEvent : undefined,
                nameBride: nameBride ? nameBride : undefined,
                nameGroom: nameGroom ? nameGroom : undefined,
            },
        });
    },

    create: (payload) => {
        return axios({
            url: '/event/',
            method: 'POST',
            data: payload,
        });
    },

    verifyGuest: (payload) => {
        return axios.post('/event-guest/verify', payload);
    },
    getGuests: (query) => {
        return axios.get('/event-guest', { params: query });
    },
    getAllByGuest: (query) => {
        return axios.get('/event-guest/get-by-guest', { params: query });
    },
    getGuestsBySlug: (payload = {}) => {
        return axios.post('/event-guest/public-guest', payload);
    },
    feedback: (id, payload) => {
        return axios.patch(`/event-guest/confirm-guest/${ id }`, payload);
    },
    groupFeedback: (id, payload) => {
        return axios.patch(`/group-guest/confirm-group/${ id }`, payload);
    },
    sendInvition: (payload) => {
        return axios.post(`/event-guest/sendemail-invite`, payload);
    },
    changeTimer: (payload) => {
        return axios.post(`/event-guest/change-timer`, payload);
    },
    update: (id, payload) => {
        return axios.patch(`/event/${ id }`, payload);
    },
    deleteManyGuest: (payload) => {
        return axios.post(`/event-guest/delete-many`, payload);
    },
    deleteManyByGuest: (payload) => {
        return axios.post(`/event-guest/delete-many-by-guest`, payload);
    },
    findGuest: (params) => {
        return axios.get(`/event-guest/${ params }`);
    },
    findGuest: (params) => {
        return axios.get(`/event-guest/${ params }`);
    },
    statistic: (query) => {
        return axios.get(`/event-guest/statistic`, { params: query });
    },
    getGroups: (query) => {
        return axios.get('/group-guest', { params: query });
    },
    getAllGroups: (query) => {
        console.log(query);
        return axios.get('/group-guest/get-all', { params: query });
    },
    findGroup: (id) => {
        return axios.get(`/group-guest/${ id }`);
    },
    createGroup: (payload) => {
        return axios.post('/group-guest', payload);
    },
    updateGroup: (id, payload) => {
        return axios.patch(`/group-guest/${ id }`, payload);
    },
    deleteGroup: (id) => {
        return axios.delete(`/group-guest/${ id }`);
    },
    deleteManyGroup: (payload) => {
        return axios.post(`/group-guest/delete-many`, payload);
    },
    sendInvitionToGroup: (payload) => {
        return axios.post(`/group-guest/sendemail-invite`, payload);
    },
    groupStatistic: (params) => {
        return axios.get(`/group-guest/statistic/${ params }`);
    },
    register: (payload) => {
        return axios.post(`/event-guest/register`, payload);
    },
    removeManyGuestFromGroup: (payload) => {
        return axios.post(`/event-guest/remove-many`, payload);
    },
    getCompareData: (params) => {
        return axios.get(`/group-guest/compare/${ params }`);
    },

    getTemplateEmail: (id, alias) => {
        return axios.get(`/event/event-email/${ id }/${ alias }`);
    },
    saveNewContent: (params, payload) => {
        return axios.post(`/event/event-email/${ params }`, payload);
    },
    deleteContent: (id, type) => {
        return axios.delete(`/event/event-email/${ id }/${ type }`);
    },
    testEmail: (payload) => {
        return axios.post(`/event/test-email`, payload);
    },
    uploadFileEmail: (payload) => {
        return axios({
            url: `/event/event-email-uploadfile`,
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: payload,
        });
    },
    removeFileEmail: (payload) => {
        return axios.post(`/event/event-email-removefile`, payload);
    },
    deleteByGuestId: () => {
        return axios.delete('/event/guest/by-id');
    },
    checkCanCreateEvent: () => {
        return axios.get('/event/check/can-create');
    },
    count: (eventId) => {
        return axios.get(`/event-guest/count/${ eventId }`);
    },
    exportQR: (eventId, page, limit, guestSelect) => {
        return axios.post('/event-guest/export-qr', { eventId, page, limit, guestSelect });
    },
    getTableCodition: () => {
        return axios.post('/event/get-codition');
    },
    getEvTable: (id) => {
        return axios.get(`/event/${ id }/get-table-event`);
    },
    getEvAcceptGuest: (id) => {
        return axios.get(`/event/${ id }/get-accept-guest`);
    },
    createTable: (payload) => {
        return axios.post('/event/table-event', payload);
    },
    addGuestToTable: (payload) => {
        return axios.post('/event/table-guest-event', payload);
    },
    sortTable: (payload) => {
        return axios.post('/event/sort-table', payload);
    },
    deleteGuest: (payload) => {
        return axios.post('/event/remove-guest-event', payload);
    },
    deleteTable: (payload) => {
        return axios.post('/event/delete-table', payload);
    },
    swapGuest: (payload) => {
        return axios.post('/event/swap-guest', payload);
    },
};

export default Events;
