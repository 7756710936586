/* eslint-disable */
// import 'aos/dist/aos.css'
import '../Event3.css';

import IMAGES from '../../../../assets/images/_index';
import IMAGES_EVENT3 from '../assets/_index';
import envConstants from '../../../../config/key';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import { statusConvert } from '../../../_index/helper';

export default function Slider({
    data,
    guest,
    guestFullname,
    guestTitle,
    guestSalutation,
    animateActive,
    isMobile,
    isSubEvent = false,
    eventConfig
}) {
    const pathLogo = data.logo && data.logo[0];
    const [pathImgLogo, setPathImgLogo] = useState('');
    const { url_share, url_guest } = useParams();
    useEffect(() => {
        if (
            !/^blob:/.test(pathLogo) &&
            !/^data:image/.test(pathLogo) &&
            !/^\/static\/media/.test(pathLogo) &&
            pathLogo
        ) {
            setPathImgLogo(envConstants.SERVER_URL + pathLogo);
        } else if (pathLogo) {
            setPathImgLogo(pathLogo);
        } else {
            setPathImgLogo('');
        }
    }, [pathLogo]);

    return (
        <div className="parent relative my-auto h-screen w-[100%] overflow-hidden ">
            {/* <img className="h-full w-full object-cover" src={IMAGES.BLANK} alt="_blank" /> */}
            {
                !isSubEvent &&
                <div className="absolute left-[6%] top-0 z-[9] hidden w-[10%] lg:block">
                    <img
                        className={animateActive && `animate-autoRotate  hidden lg:block`}
                        src={IMAGES_EVENT3.SUN_IMG}
                        loading="lazy"
                        alt="sun"
                    />
                </div>
            }
            <div
                className={`absolute left-0 top-0 flex h-full w-full items-center justify-end bg-[url('./resource/events/event-3/assets/imgs/slider-bg.jpg')]  bg-cover ${isSubEvent ? 'bg-center' : 'bg-left'} `}
            >
                <div
                    className={'flex h-full w-full items-center justify-center lg:justify-end '}
                >
                    <div
                        className={`z-[99] flex  h-[100%] w-full ${isSubEvent ? ' justify-start pt-20 ' : 'lg:w-[50%] justify-center'}  flex-col items-center   lg:bg-transparent`}
                    >
                        {pathImgLogo && (
                            <img
                                className={`h-[calc(8rem_+_2vh)]`}
                                src={pathImgLogo}
                            />
                        )}
                        <h2
                            className={'mt-6 text-center font-Merriweather text-xl uppercase tracking-[2px] text-gray-800'}
                        >
                            {guestSalutation ? guestSalutation : data.titleEvent}
                        </h2>

                        <h2
                            className={`mt-5 font-greatVibes text-gray-800 text-3xl text-center `}
                        >
                            {guestTitle ? guestTitle.trim() : '...................'}{' '}
                        </h2>
                        <h2
                            className={`mt-1 font-Merriweather text-gray-800  text-2xl text-center `}
                        >
                            {guestFullname ? guestFullname.trim() : '.............................'}
                        </h2>
                        <h1
                            className={'text-normal-stroke  text-center mt-14 font-Merriweather uppercase text-[16px] lg:text-lg font-thin tracking-[2px] text-white '}
                        >
                            {data.invitation && data.invitation}
                        </h1>
                        <h2
                            className={'mt-4 text-center font-pacifico text-[34px] lg:text-4xl font-thin tracking-[4px] text-blue-700'}
                        >
                            {data.nameEvent && data.nameEvent}
                        </h2>
                        {url_guest && url_share && eventConfig?.auto_response && (
                            <a
                                href={'/' + url_share + '/' + url_guest + '/xac-nhan'}
                                target="_blank" rel="noreferrer"
                            >
                                <button className="mt-3 flex items-center rounded bg-amber-200 px-4 py-1.5 text-[calc(0.5rem_+_0.7vh)] font-semibold text-red-800 md:mt-4">
                                    {/* <FaEnvelopeOpenText className="mr-2" /> Xác nhận tham dự */}
                                    {statusConvert(guest?.status)}
                                </button>
                            </a>
                        )}
                        {!url_guest && url_share && eventConfig?.auto_join && (
                            <button
                                className="mt-3 flex items-center rounded bg-amber-200 px-4 py-1.5 text-[calc(0.5rem_+_0.7vh)] font-semibold text-red-800 transition-all md:mt-4"
                                data-bs-toggle="modal"
                                data-bs-target="#formModal"
                            >
                                <FaEnvelopeOpenText className="mr-2" /> Đăng kí tham dự
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
