/* eslint-disable */
import axios from './axios'

const EventConfigAPI = {
    findEventConfig: (eventID) => {
        return axios({
            url: `/event/config/${eventID}`,
            method: 'GET',
        })
    },
    postEventConfig: (eventID, data) => {
        return axios({
            url: `/event/config/${eventID}`,
            method: 'POST',
            data: data
        })
    },
}

export default EventConfigAPI