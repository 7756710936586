/* eslint-disable */
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';

export default function SortTableModal() {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="sortTableModal"
            tabIndex="-1"
            aria-modal="true"
            role="dialog"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered modal-xl ">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-[16px] font-bold leading-normal text-gray-800"
                            id="staticBackdropLabel"
                        >
                            Sắp xếp bàn tiệc
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="w-full flex justify-center items-center">
                        <div className="w-full h-[600px] p-4 bg-white flex flex-col">
                            <div className="flex gap-2"></div>
                            <div className="flex">
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover:bg-gray-50 transition-all focus:bg-gray-50 active:bg-gray-50  inline-block rounded bg-white border-[1px] border-gray-300 px-5 py-2.5 text-[14px] leading-tight text-gray-800 duration-150 ease-in-out font-medium"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                        <button
                            onClick={onDownloadQr}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-[14px] font-medium leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            Tải ảnh Qr
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
