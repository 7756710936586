/* eslint-disable */
import { useEffect, useState } from 'react';
import { getDateOfMonth, getDateOfWeek, getMonthOfYear, getYear } from '../../../../utils/Times';

import envConstants from '../../../../config/key';
import { useParams } from 'react-router-dom';

import { FaEnvelopeOpenText } from 'react-icons/fa';
import IMAGE_EVENT12 from '../assets/images';
import '../assets/styles/style.css';
import Slider from 'react-slick';
import { statusConvert } from '../../../_index/helper';
import Sponsor from '../components/Sponsor';

const TiltlePage = ({
    data = {},
    guest,
    guestFullname = '',
    salutation = '',
    guestTitle = '',
    eventConfig,
    show,
}) => {
    const dataCopy = {
        invitation: data.invitation,
        titleEvent: data.titleEvent,
        nameEvent: data.nameEvent,
        logo: data.logo && data.logo[0],
        placeHold: data.placeHold,
        addressHold: data.addressHold,
        date: data.dateHold,
        time: data.timeHold,
        nameSupporter: data.nameSupporter,
        phoneSupporter: data.phoneSupporter,
        linkGgMap: data?.linkGgMap ?? '',
        dress_1: data?.dress_1 ? data?.dress_1 : '',
        dress_2: data?.dress_2 ? data?.dress_2 : '',
    };
    const { url_share, url_guest } = useParams();
    const [pathImgLogo, setPathImgLogo] = useState('');

    useEffect(() => {
        if (
            !/^blob:/.test(dataCopy?.logo) &&
            !/^data:image/.test(dataCopy?.logo) &&
            !/^\/static\/media/.test(dataCopy?.logo) &&
            dataCopy?.logo !== undefined &&
            dataCopy?.logo !== '' &&
            dataCopy?.logo !== null &&
            dataCopy?.logo !== false
        ) {
            setPathImgLogo(envConstants.SERVER_URL + dataCopy?.logo);
        } else if (dataCopy?.logo) {
            setPathImgLogo(dataCopy?.logo);
        } else {
            setPathImgLogo('');
        }
    }, [dataCopy?.logo]);

    return (
        <>
            <div className="relative mx-auto  min-h-screen max-w-screen-xl w-full ">
                <div
                    className="relative mx-auto flex items-center  min-h-screen w-full bg-left bg-contain bg-no-repeat bg-black "
                    style={{ backgroundImage: `url(${IMAGE_EVENT12.BG})` }}
                >
                    <div className="hidden lg:block w-1/4"></div>
                    <div className="bg-black/60 lg:bg-transparent min-h-screen w-full lg:w-3/4 flex flex-col items-center justify-center">
                        <div className="w-full">
                            {pathImgLogo && (
                                <img
                                    className="mx-auto w-[140px] lg:w-[180px]"
                                    src={pathImgLogo}
                                    loading="lazy"
                                    alt="logo"
                                />
                            )}
                        </div>
                        <div className={'mt-0.5 text-center '}>
                            <span className="font-ephesis-regular text-5xl lg:text-6xl font-thin text-white">
                                {dataCopy?.titleEvent}
                            </span>
                        </div>
                        <div className="mx-auto mt-4 ">
                            <div className=" mx-auto  text-center   text-white">
                                <p className=" text-sm lg:text-[16px] tracking-[1px] font-bold uppercase leading-tight">
                                    {salutation || '..............................'}
                                </p>
                                <p className="mt-2 text-lg lg:text-2xl  tracking-[1px] text-yellow-200 font-semibold text-center font-Merriweather  ">
                                    {guestTitle || guestFullname
                                        ? (guestTitle || guestTitle) +
                                          ' ' +
                                          (guestFullname || guestFullname)
                                        : '................................'}
                                </p>
                            </div>
                        </div>
                        <div
                            className={
                                'mt-6 text-center tracking-[1px] text-white font-bold uppercase  '
                            }
                        >
                            <span className="text-sm lg:text-[16px] ">{dataCopy?.invitation}</span>
                        </div>
                        <div className="flex justify-center mt-3">
                            <p
                                className={
                                    'text-center  font-splash-regular text-4xl lg:text-7xl   text-yellow-200  '
                                }
                            >
                                {dataCopy?.nameEvent}
                            </p>
                        </div>
                        <div className="w-full text-center mt-6">
                            <p className=" text-3xl lg:text-4xl font-tourney-custom font-medium tracking-[4px] text-white text-center">
                                {dataCopy?.time && dataCopy?.time}
                            </p>
                            <p className=" text-lg lg:text-xl font-tourney-custom font-medium text-white text-center">
                                {dataCopy?.date &&
                                    getDateOfWeek(dataCopy?.date) +
                                        ', ngày ' +
                                        getDateOfMonth(dataCopy?.date) +
                                        ' tháng ' +
                                        getMonthOfYear(dataCopy?.date) +
                                        ' năm ' +
                                        getYear(dataCopy?.date)}
                            </p>
                        </div>

                        <div className="w-full text-center mt-4">
                            {(dataCopy?.placeHold || dataCopy?.addressHold) && (
                                <p className="text-xs lg:text-sm text-center tracking-[1px] text-yellow-100 font-extrabold ">
                                    Địa điểm:
                                </p>
                            )}
                            <p className="mt-0.5 w-full text-xl lg:text-2xl font-tourney-custom font-medium  text-white text-center">
                                {dataCopy?.placeHold}
                            </p>
                            <p className="w-full text-sm lg:text-lg font-tourney-custom font-medium  text-white  italic text-center">
                                {dataCopy?.addressHold}{' '}
                                {(dataCopy?.addressHold || dataCopy?.linkGgMap) && (
                                    <a
                                        href={`${
                                            dataCopy?.linkGgMap
                                                ? dataCopy?.linkGgMap
                                                : `${
                                                      'https://www.google.com/maps/search/?api=1&query=' +
                                                      dataCopy?.addressHold
                                                  }`
                                        }`}
                                        rel="noreferrer"
                                        target="_blank"
                                        className={
                                            'ml-1 border-yellow-200 text-sm lg:text-[17px] text-yellow-200  hover:border-b '
                                        }
                                        title="Xem trên bản đồ"
                                    >
                                        <i className="fas fa-map-marker-alt"></i>
                                    </a>
                                )}
                            </p>
                        </div>

                        <div className="flex items-center mt-3 justify-center">
                            {url_guest && url_share && eventConfig?.auto_response && (
                                <a
                                    href={'/' + url_share + '/' + url_guest + '/xac-nhan'}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <button className=" flex items-center rounded bg-red-500 px-4 py-1.5 text-[calc(0.5rem_+_0.7vh)] font-semibold text-yellow-100 transition hover:bg-red-700 ">
                                        {/* <FaEnvelopeOpenText className="mr-2" /> Xác nhận tham dự */}
                                        {statusConvert(guest?.status)}
                                    </button>
                                </a>
                            )}
                            {!url_guest && url_share && eventConfig?.auto_join && (
                                <button
                                    className=" flex items-center rounded bg-red-500 px-4 py-1.5 text-[calc(0.5rem_+_0.7vh)] font-semibold text-yellow-100 transition-all hover:bg-red-700 "
                                    data-bs-toggle="modal"
                                    data-bs-target="#formModal"
                                >
                                    <FaEnvelopeOpenText className="mr-2" /> Đăng kí tham dự
                                </button>
                            )}
                        </div>
                        {dataCopy?.dress_1 || dataCopy?.dress_2 ? (
                            <div className="w-full text-center mt-4">
                                <p className="w-full text-xs lg:text-sm text-center tracking-[1px] text-yellow-100 font-extrabold ">
                                    Trang phục
                                </p>
                                {dataCopy?.dress_1 ? (
                                    <p className="w-full tracking-[1px] text-lg font-Comfortaa text-center font-medium mt-2  text-white">
                                        {dataCopy?.dress_1}
                                    </p>
                                ) : null}
                                {dataCopy?.dress_2 ? (
                                    <p className="w-full tracking-[1px] text-lg font-Comfortaa text-center font-medium mt-2  text-white">
                                        {dataCopy?.dress_2}
                                    </p>
                                ) : null}
                            </div>
                        ) : null}
                        {(dataCopy?.phoneSupporter || dataCopy?.nameSupporter) && (
                            // <p className="mt-5 text-center  italic text-white text-xs lg:text-sm">
                            //     Liên hệ hỗ trợ:{' '}
                            //     <a
                            //         className="cursor-pointer  font-bold text-white underline   hover:text-yellow-200  "
                            //         href={`tel:${dataCopy?.phoneSupporter}`}
                            //     >
                            //         {dataCopy?.phoneSupporter}
                            //     </a>{' '}
                            //     ({dataCopy?.nameSupporter})
                            // </p>
                            <div className="mx-auto mt-5 text-center text-[15px] md:text-[16px]">
                                <p className="w-full text-xs lg:text-sm text-center tracking-[1px] text-yellow-100 font-extrabold ">
                                    Liên hệ hỗ trợ
                                </p>
                                <div className="flex">
                                    <p className={'text-right mt-2 font-medium w-1/2 text-white'}>
                                        <i className="mr-1 text-lg fas fa-user-alt text-[#ab7628] md:text-xl "></i>{' '}
                                        {data?.nameSupporter && data?.nameSupporter}
                                    </p>

                                    <a href={`tel:${data?.phoneSupporter}`} className="w-1/2 ">
                                        <p
                                            className={
                                                'text-left flex font-medium mt-2  text-white '
                                            }
                                        >
                                            <i className="fas fa-phone-alt ml-5 mr-1.5 text-lg text-[#ab7628] md:text-xl"></i>
                                            {data?.phoneSupporter && data?.phoneSupporter}
                                        </p>
                                    </a>
                                </div>
                            </div>
                        )}

                        {show && data?.sponsors && data?.sponsors?.length > 0 ? (
                            <Sponsor data={data} />
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TiltlePage;
