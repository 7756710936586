/* eslint-disable */
import EventTemplate from '../../../../api/event.template.api';
import envConstants from '../../../../config/key';

const PublishInfoPartyService = {
    shareEventTemplate: async (id, data) => {
        const result = await EventTemplate.export(id, data);
        return result;
    },
    getPublic: async (payload) => {
        const { data: response } = await EventTemplate.getPublicEvent(payload);
        const { data } = response;

        // means has Guest Info
        if (data.hasOwnProperty('event') && data.hasOwnProperty('guest')) {
            const { guest, eventConfig, event } = data;

            return {
                guest,
                eventConfig,
                ev: event,
                formData: event?.form_data,
                templateAlias: event?.template.alias,
                title: event?.title,
                thumbnail: event?.thumbnail_url,
            };
        }

        const { event, eventConfig } = data;
        return {
            eventConfig,
            data: event,
            formData: event?.form_data,
            templateAlias: event?.template.alias,
            title: event?.title,
            thumbnail: event?.thumbnail_url,
        };
    },
    createFile: async (url, key) => {
        if (
            url === '' ||
            url === envConstants.SERVER_URL ||
            url.startsWith('data:text/html;base64')
        ) {
            return '';
        }
        // if(url.startsWith(envConstants.SERVER_URL)){
        //     return url.replace(envConstants.SERVER_URL, '')
        // }
        let response = await fetch(url);
        let data = await response.blob();
        return new File([data], key + '_create_image.jpg', { type: 'image/jpeg' });
    },
    saveFiles: async (id, data, files, elementId) => {
        const bodyFormData = new FormData();
        bodyFormData.append('key', data);
        // bodyFormData.append('elementId', 0);
        // bodyFormData.append('value', files);
        bodyFormData.append('value', files);
        bodyFormData.append('keyChild', 'image');
        bodyFormData.append('elementId', elementId);
        const res = await EventTemplate.updateFile(id, bodyFormData);
        return res.data;
    },
    submitForm: async (id, payload) => {
        const { data } = await EventTemplate.updateContent(id, payload);
        return data;
    },
    deleteEvent: async (id, payload) => {
        const { data } = await EventTemplate.updateContent(id, payload);
        return data;
    },
};

export default PublishInfoPartyService;
