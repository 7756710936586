/* eslint-disable */

import { useEffect, useState } from 'react';
import userService from '../User.service';
import ModalConfirmPaidOrderNew from './ModalConfirmPaidOrderNew';
import ToastNotify from '../../../utils/Toast';
import { useSelector } from 'react-redux';
import { FaCopy } from 'react-icons/fa';
import ModalStatusConfirm from './ModalStatusConfirm';
import ModalStatusConfirmError from './ModalStatusConfirmError';

const getOrderAlias = (detailOrder) => {
    if (!detailOrder) {
        return '';
    }
    return (
        detailOrder?.order_alias_string +
        detailOrder?.order_alias_number?.toString()?.padStart(5, '0')
    );
};

export default function ModalPaymentInfo({ isOpen, funToggle, orderInfo, setIsReloadTable }) {
    const userData = useSelector((state) => state.auth.user);
    const [detailOrder, setDetailOrder] = useState();
    const [totalPayment, setTotalPayment] = useState();
    const [banks, setBanks] = useState([]);

    const [isOpenModalConfirmPayment, setIsOpenModalConfirmPayment] = useState(false);
    const funToggleModalConfirmPayment = () => {
        setIsOpenModalConfirmPayment(!isOpenModalConfirmPayment);
    };

    useEffect(() => {
        if (orderInfo) {
            userService
                .getAdminListBank()
                .then(async ({ data }) => {
                    let listBank = [];
                    data.map((item) => {
                        const imageQR = userService.getAdminImageQRBank(
                            getOrderAlias(orderInfo),
                            item.bank_key
                        );
                        listBank.push({
                            ...item,
                            imageQR,
                        });
                    });
                    setBanks(listBank);
                })
                .catch((err) => console.log(err));

            setDetailOrder(orderInfo);

            let total = 0;
            orderInfo.detail.map((item) => (total += item.price * item.quantity));
            setTotalPayment(total);
        }
    }, [orderInfo]);

    const [statusConfirmError, setStatusConfirmError] = useState({ open: false, detail: {} });
    const [statusConfirm, setStatusConfirm] = useState({ open: false, success: true, detail: {} });
    const handleConfirmPaidOrder = () => {
        userService
            .postAdminConfirmPaidOder(userData._id, orderInfo._id)
            .then(({ data }) => {
                funToggleModalConfirmPayment();
                funToggle();
                setIsReloadTable();
                // ToastNotify.Success('Xác nhận đã thanh toán thành công');
                setStatusConfirm({ open: true, success: true, detail: data });
            })
            .catch(({ response }) => {
                funToggleModalConfirmPayment();
                funToggle();
                setIsReloadTable();
                // ToastNotify.Error(response?.data?.message);
                // setStatusConfirm({ open: true, success: false, detail: {} });
                setStatusConfirmError({ open: true, detail: response?.data })
            })
            .finally();
    };
    const closeStatusConfirm = () => {
        setStatusConfirm({ success: true, open: false, detail: {} });
    };
    const closeStatusConfirmError = () => {
        setStatusConfirmError({ open: false, detail: {} });
    };
    function copySTK(text) {
        // Tạo một element input ẩn
        var input = document.createElement('textarea');
        input.style.position = 'fixed';
        input.style.opacity = 0;
        input.value = text;
        document.body.appendChild(input);

        // Chọn và sao chép nội dung vào clipboard
        input.select();
        document.execCommand('copy');

        // Xoá element input
        document.body.removeChild(input);
        ToastNotify.Success('Đã sao chép tài khoản!');
    }
    return (
        <>
            <ModalStatusConfirmError
                isOpen={statusConfirmError.open}
                close={closeStatusConfirmError}
                detail={statusConfirmError.detail}
            />
            <ModalStatusConfirm
                isOpen={statusConfirm.open}
                success={statusConfirm.success}
                close={closeStatusConfirm}
                detail={statusConfirm.detail}
            />
            <ModalConfirmPaidOrderNew
                isOpen={isOpenModalConfirmPayment}
                funToggle={funToggleModalConfirmPayment}
                handleConfirm={handleConfirmPaidOrder}
            />
            <div
                className={`fade modal fixed left-0 top-0  h-full w-full overflow-y-auto overflow-x-hidden outline-none ${isOpen ? 'show' : ''
                    } backdrop-brightness-50`}
                tabIndex="-1"
                style={{
                    margin: 0,
                    visibility: isOpen ? 'visible' : 'hidden',
                    transition: 'opacity 0.15s linear, visibility 0.5s linear',
                }}
                aria-modal="true"
                role="dialog"
            >
                <div className="modal-dialog modal-dialog-centered pointer-events-none relative modal-xl lg:modal-lg">
                    <div className="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
                        <div className="flex items-center justify-between bg-gray-200 p-4 border-b border-gray-300 modal-header shrink-0 rounded-t-md">
                            <h5 className="text-[16px] font-bold leading-normal text-gray-800">
                                Thông tin thanh toán đơn hàng {getOrderAlias(detailOrder)}
                            </h5>
                            <button
                                type="button"
                                title="Đóng"
                                className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                onClick={funToggle}
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="flex flex-col items-center justify-between w-full border-b border-gray-200 modal-content shrink-0 rounded-t-md px-5 py-3 overflow-y-auto overflow-x-hidden h-[500px] md:h-[700px]">
                            <div className="w-full">
                                <p className="text-center">
                                    <svg
                                        viewBox="0 0 24 24"
                                        className="text-green-600 w-10 h-10 mx-auto mb-2"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                                        ></path>
                                    </svg>
                                </p>
                                <p className="mt-2 text-xl text-center text-green-500 font-semibold">
                                    Bạn đã tạo đơn hàng thành công!
                                </p>
                                <p className=" text-sm text-center text-black">
                                    Vui lòng kiểm tra email để xem thông tin chi tiết đơn hàng của
                                    bạn.
                                </p>
                                <div className="mt-3 w-full">
                                    <p className="text-lg mb-0.5 font-semibold text-black">
                                        Thông tin đơn hàng
                                    </p>
                                    <div className="w-full overflow-x-auto">
                                        <table className="w-full min-w-[350px] table-auto border border-gray-400  shadow-lg">
                                            <thead>
                                                <tr>
                                                    <th className="border-b border-r border-gray-400 py-2 px-2.5 text-center">
                                                        <p className="block antialiased font-sans text-sm font-bold text-gray-700">
                                                            STT
                                                        </p>
                                                    </th>
                                                    <th className="border-b border-r border-gray-400 py-2 px-2.5 text-center">
                                                        <p className="block antialiased font-sans text-sm font-bold text-gray-700">
                                                            Gói dịch vụ
                                                        </p>
                                                    </th>
                                                    <th className="border-b border-r border-gray-400 py-2 px-2.5 text-center">
                                                        <p className="block antialiased font-sans text-sm font-bold text-gray-700">
                                                            Số lượng
                                                        </p>
                                                    </th>
                                                    <th className="border-b border-gray-400 py-2 px-2.5 text-center">
                                                        <p className="block antialiased font-sans text-sm font-bold text-gray-700">
                                                            Thành tiền
                                                        </p>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {detailOrder?.detail?.map((item, index) => {
                                                    return (
                                                        <tr>
                                                            <td className="py-2 border-b border-r border-gray-400">
                                                                <p className="block antialiased text-center font-sans text-sm font-semibold text-gray-600">
                                                                    {index + 1}
                                                                </p>
                                                            </td>
                                                            <td className="py-2 pl-1 border-b border-r border-gray-400">
                                                                <div className="flex justify-start items-center gap-4">
                                                                    <p className="block antialiased font-sans text-sm leading-normal font-semibold text-gray-600">
                                                                        {item?.package_name}
                                                                    </p>
                                                                </div>
                                                            </td>
                                                            <td className="py-2  border-b border-r border-gray-400">
                                                                <p className="antialiased text-center font-sans text-sm font-semibold text-gray-600 flex justify-center items-center gap-2">
                                                                    {item?.quantity}
                                                                </p>
                                                            </td>
                                                            <td className="py-2  pr-1 border-b border-gray-400 ">
                                                                <p className="block antialiased text-end font-sans text-sm font-semibold text-gray-600">
                                                                    {new Intl.NumberFormat(
                                                                        'vi-VN'
                                                                    ).format(
                                                                        item?.price * item?.quantity
                                                                    )}{' '}
                                                                    VNĐ
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                <tr>
                                                    <td
                                                        colSpan={2}
                                                        className="py-2 pl-3 border-b border-gray-400"
                                                    >
                                                        <div className="flex justify-start items-center">
                                                            <p className="block antialiased font-sans text-[16px] font-semibold text-black leading-normal ">
                                                                Tổng tiền:
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td
                                                        colSpan={2}
                                                        className="py-2  pr-1 border-b border-gray-400 "
                                                    >
                                                        <p className="block antialiased text-end font-sans text-lg font-semibold text-blue-600">
                                                            {new Intl.NumberFormat('vi-VN').format(
                                                                totalPayment
                                                            )}{' '}
                                                            VNĐ
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="mt-3 w-full">
                                    <p className="text-lg font-semibold text-black">
                                        Thông tin thanh toán
                                    </p>
                                    <p className="text-sm mb-0.5  text-black">
                                        Bạn vui lòng thanh toán qua một trong các tài khoản dưới
                                        đây:
                                    </p>
                                    <div className="flex flex-wrap justify-center w-full gap-12">
                                        {banks?.map((item) => (
                                            <div className="bg-white  shadow-lg rounded-lg overflow-hidden max-w-[200px] mx-2 my-4">
                                                <div className="w-[160px] h-[160px] relative bg-white  overflow-hidden rounded-lg p-4 mx-auto">
                                                    <img
                                                        src={item?.imageQR}
                                                        alt="Placeholder Image"
                                                        className=" w-full h-full object-cover rounded-lg"
                                                    />
                                                    <div className="absolute top-0 left-0 w-8 h-1 bg-blue-400  rounded-tl-2xl"></div>
                                                    <div className="absolute top-0 left-0 h-8 w-1 bg-blue-400  rounded-tl-2xl"></div>
                                                    <div className="absolute top-0 right-0 w-8 h-1 bg-blue-400  rounded-tr-2xl"></div>
                                                    <div className="absolute top-0 right-0 h-8 w-1 bg-blue-400  rounded-tr-2xl"></div>
                                                    <div className="absolute bottom-0 left-0 w-8 h-1 bg-blue-400  rounded-bl-2xl"></div>
                                                    <div className="absolute bottom-0 left-0 h-8 w-1 bg-blue-400  rounded-bl-2xl"></div>
                                                    <div className="absolute bottom-0 right-0 w-8 h-1 bg-blue-400  rounded-br-2xl"></div>
                                                    <div className="absolute bottom-0 right-0 h-8 w-1 bg-blue-400  rounded-br-2xl"></div>
                                                </div>
                                                <div className="py-3 text-center">
                                                    <h3 className="text-lg text-blue-900 font-semibold">
                                                        {' '}
                                                        {item?.account_name}
                                                    </h3>
                                                    <p
                                                        className="text-lg text-black flex items-center gap-2 hover:text-blue-500 cursor-pointer text-center justify-center"
                                                        onClick={() => {
                                                            copySTK(item?.account_number);
                                                        }}
                                                    >
                                                        <FaCopy />
                                                        {item?.account_number}
                                                    </p>
                                                    <p className="text-sm text-zinc-600 ">
                                                        {item?.account_address}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap items-center justify-end p-4 modal-footer shrink-0 rounded-b-md">
                            <button
                                type="button"
                                className="hover:bg-gray-100 transition-all focus:bg-gray-50 active:bg-gray-50  inline-block rounded bg-white border-[1px] border-gray-300 px-5 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 duration-150 ease-in-out"
                                onClick={funToggle}
                            >
                                Đóng
                            </button>
                            <button
                                type="button"
                                // data-bs-dismiss="modal"
                                // data-bs-target="#modalConfirmPaidOrder"
                                onClick={funToggleModalConfirmPayment}
                                className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                            >
                                Xác nhận đã thanh toán
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
