import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import userService from '../user/User.service';
import userConstants from '../../config/redux/user.redux';
import storageConstant from '../../config/constant/storage.constant';
import Cookies from 'js-cookie';

export default function VerifyUser() {
	const { token } = useParams();
	// eslint-disable-next-line no-unused-vars
	let [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const errorToast = () => (
		<div className='flex flex-col justify-center'>
			<div className="mb-4">
				Yêu cầu xác thực đã hết hạn. Hãy nhấn nút dưới đây để gửi lại email xác thực.
			</div>
			<button
				onClick={resendVerifyEmail}
				className="rounded-lg bg-gradient-to-r from-[#51ADF5] to-[#2196F3] p-2 text-white hover:from-[#2196F3] hover:to-[#51ADF5]  hover:shadow-lg "
				>
					<div className="flex flex-row items-center justify-center">
						<div className="font-bold capitalize text-white">Gửi lại email xác thực</div>
					</div>
            </button>
		</div>
	);
	useEffect(() => {
		userService.verifyToken(token, searchParams.get('user_name'))
		.then((data) => {
			const { user, accessToken, refreshToken } = data;
			if (user) {
				localStorage.setItem(storageConstant.USER, JSON.stringify(user));

				let date = new Date();
				// eslint-disable-next-line no-magic-numbers
				date.setTime(date.getTime() + 3600 * 1000);
	
				Cookies.set(storageConstant.ACCESS_TOKEN, accessToken, { expires: date });
				Cookies.set(storageConstant.REFRESH_TOKEN, refreshToken, { expires: 1 }); // 1 day
	
				const loginSuccess = (user) => ({
					type: userConstants.LOGIN_SUCCESS,
					payload: user,
				});
				dispatch(loginSuccess(user));
				toast.success('Xác thực tài khoản thành công.');
			}
			navigate('/');
		})
		.catch(async (error) => {
			if (error.message === 'Yêu cầu xác thực đã hết hạn.') {
				toast.info(errorToast, {
					icon: false,
					position: 'top-center',
					closeOnClick: false,
					autoClose: false,
				});
			}
			else {
				toast.error(error?.message || 'Yêu cầu xác thực không tồn tại.');
			}
			navigate('/');
		});
	}, []);
	const resendVerifyEmail = () => {
		userService.resendVerifyEmail(token).
			then(() => {
				toast.info('Gửi email xác thực tài khoản thành công.');
			})
			.catch((error) => {
				console.error(error);
				let message = 'Gửi email xác thực tài khoản thất bại.';
				if (error.message) {
					message += ' ' + error.message;
				}
				toast.error(message);
			});
	};
	return (
		<></>
	);
}