/* eslint-disable */

import { FaCrown } from 'react-icons/fa';
import img from './images/demo.gif';
import envConstants from '../../../../../config/key';
import { useSelector } from 'react-redux';
import { getHighestRole } from '../../../../../utils/common';
import { USER_ROLE } from '../../../../../config/constant/common.constant';
import IMAGES from '../../../../../assets/images/_index';

export default function Premium({templateAlias}) {
    const auth = useSelector((state) => state.auth);
    const data = useSelector((state) => state.auth.user);

    return (
        <div className="w-[200px] h-[80vh] flex flex-col bg-blue-500 px-4 pt-5 pb-10 rounded-md gap-[40px] justify-center items-center">
            <img
                src={templateAlias.includes('wedding') ? IMAGES.LOGO_WEDDING : IMAGES.LOGO_EVENT}
                className='w-40 mx-auto'
            />
            <p className="font-light text-white text-center">
                {getHighestRole(data?.roles) === USER_ROLE.NORMAL &&
                    'Đăng ký gói thành viên để có thể sử dụng các tính năng giới hạn'}
                {getHighestRole(data?.roles) === USER_ROLE.VIP &&
                    'Bạn đã đăng ký gói thành viên và giờ hãy cùng chúng tôi trải nghiệm những dịch vụ mới nhất'}
                {!auth?.loggedIn &&
                    'Đăng ký gói thành viên để có thể sử dụng các tính năng giới hạn'}
            </p>
            <a href={envConstants.CLIENT_URL + 'bang-gia'} className="w-fit">
                <button className="px-4 py-2 bg-yellow-500 rounded-full text-white font-semibold transition-all hover:scale-105 flex gap-2 items-center">
                    <FaCrown />
                    Xem chi tiết
                </button>
            </a>
            <img src={img} className="w-full" />
        </div>
    );
}
