
/* eslint-disable */

import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { FaArrowUp } from 'react-icons/fa';


const ScrollToTop = () => {

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 60) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener('scroll', toggleVisibility);

        // Xóa event listener khi component unmounts
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [window.pageYOffset]);

    return (
        <AnimatePresence initial={false}>
            {isVisible && (
                <motion.button
                    onClick={() => scrollToTop()}
                    initial="hide"
                    animate="show"
                    exit="hide"
                    variants={{
                        show: { opacity: 1, transform: 'translateY(0%)' },
                        hide: { opacity: 0, transform: 'translateY(500%)' },
                    }}
                    transition={{ duration: 0.3 }}
                    className="fixed p-4 text-white bg-main-500 rounded-full bottom-12 lg:right-10 right-5 z-[999999] border-[2px] border-white"
                >
                    <FaArrowUp className="text-xl" />
                </motion.button>
            )}
        </AnimatePresence>
    )
}

export default ScrollToTop;