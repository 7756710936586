/* eslint-disable */
import { getDateOfMonth, getDateOfWeek, getMonthOfYear, getYear } from '../../../../utils/Times';
import { useContext, useEffect, useState } from 'react';

import { FocusContext } from '../../../../context/Focus.context';
import { HighlightContext } from '../../../../context/Highlight.context';
import envConstants from '../../../../config/key';
import IMAGES_EVENT7 from '../assets/images';
import moment from 'moment';
import Snowfall from 'react-snowfall';
import { useParams } from 'react-router-dom';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { statusConvert } from '../../../_index/helper';

const TiltlePage = ({
    data = {},
    guest,
    guestFullname = '',
    salutation = '',
    guestTitle = '',
    isSubEvent = false,
    eventConfig,
}) => {
    const { url_share, url_guest } = useParams();
    const dateTimeData = moment(data?.dateHold + ' ' + data?.timeHold, 'YYYY-MM-DD HH:mm');

    const dataCopy = {
        invitation: data.invitation,
        titleEvent: data.titleEvent,
        nameEvent: data.nameEvent,
        logo: data.logo && data.logo[0],
        placeEvent: data.placeHold,
        addressEvent: data.addressHold,
        date: data.dateHold,
        time: data.timeHold,
        datetime: dateTimeData,
        linkGgMap: data?.linkGgMap ?? '',
        sponsors: data?.sponsors ?? [],
        dress_1: data?.dress_1 ? data?.dress_1 : '',
        dress_2: data?.dress_2 ? data?.dress_2 : '',
    };

    const settings = {
        infinite: false,
        speed: 4000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const focusContext = useContext(FocusContext);
    const [highlightContext, setHighlightContext] = useContext(HighlightContext);

    const [pathImgLogo, setPathImgLogo] = useState('');

    useEffect(() => {
        if (
            !/^blob:/.test(dataCopy.logo) &&
            !/^data:image/.test(dataCopy.logo) &&
            !/^\/static\/media/.test(dataCopy.logo) &&
            dataCopy.logo !== undefined &&
            dataCopy.logo !== '' &&
            dataCopy.logo !== null &&
            dataCopy.logo !== false
        ) {
            setPathImgLogo(envConstants.SERVER_URL + dataCopy.logo);
        } else if (dataCopy.logo) {
            setPathImgLogo(dataCopy.logo);
        } else {
            setPathImgLogo('');
        }
    }, [dataCopy.logo]);

    useEffect(() => {
        if (!isSubEvent) {
            document
                .getElementById('audioID')
                .play()
                .catch((error) => {
                    document.addEventListener(
                        'click',
                        () => {
                            document.getElementById('audioID').play();
                        },
                        { once: true }
                    );
                });
        }
    }, []);

    const snowflake1 = document.createElement('img');
    snowflake1.src = IMAGES_EVENT7.SNOW;

    return (
        <>
            {/* <iframe src={IMAGES_EVENT7.SILENCE} type="audio/mp3" allow="autoplay" id="audio" style="display:none"></iframe> */}
            <div
                className="mx-auto min-h-[100vh] w-full bg-[length:auto_170%] bg-left bg-no-repeat text-white  md:bg-[length:auto_130%] lg:h-[100vh] lg:bg-center xl:overflow-hidden"
                // className="w-full h-fit min-h-[100vh] mx-auto text-white bg-no-repeat bg-cover font-Comfortaa"
                style={{ backgroundImage: `url(${IMAGES_EVENT7.BG_8})` }}
            >
                <div
                    className={`${isSubEvent ? '' : ''}
                        flex min-h-[100vh] w-full items-center justify-center py-10 backdrop-blur-sm md:py-20 xl:backdrop-blur-none`}
                >
                    {!isSubEvent && (
                        <audio src={IMAGES_EVENT7.AUDIO} id="audioID" loop autoPlay></audio>
                    )}
                    {/* {!isSubEvent && (
                        <Snowfall
                            images={[snowflake1]}
                            radius={[3.0, 15.0]}
                            speed={[1.0, 5.0]}
                        />
                    )} */}
                    <div
                        className="mx-auto w-[95%] md:w-3/4"
                        tabIndex="0"
                        ref={focusContext.formGeneral}
                    >
                        {isSubEvent ? (
                            <div className="-mt-1 flex h-[calc(5rem_+_2vh)] w-auto justify-center">
                                {pathImgLogo && (
                                    <img
                                        className=" h-[calc(5rem_+_2vh)] rounded-lg"
                                        src={pathImgLogo}
                                        loading="lazy"
                                        alt="logo"
                                    />
                                )}
                            </div>
                        ) : (
                            <div className="-mt-1 flex h-[calc(5rem_+_2vh)] w-auto justify-center">
                                {pathImgLogo && (
                                    <img
                                        className=" h-[calc(5rem_+_2vh)] rounded-lg"
                                        src={pathImgLogo}
                                    />
                                )}
                            </div>
                        )}
                        <div className={'mt-2 text-center '}>
                            <span className="text-xl tracking-[2px] font-extrabold  text-white">
                                {dataCopy?.titleEvent}
                            </span>
                        </div>
                        <div className="mx-auto my-5 w-[90%] md:my-10 md:w-2/3">
                            <div className="mx-auto  mt-4 w-full">
                                <p className="font-Comfortaa uppercase text-center text-[calc(0.5rem_+_1vh)] font-extrabold md:text-[calc(0.3rem_+_2vh)]">
                                    {salutation}
                                </p>
                            </div>
                            <div className="font-Comfortaa mx-auto mb-2 mt-2 text-center text-[16px] text-amber-200 md:text-lg">
                                {/* <p className="uppercase break-words">
                                    {guestTitle}
                                </p> */}
                                <p className=" break-words text-[calc(0.7rem_+_1vh)] font-extrabold leading-10 md:text-[calc(0.3rem_+_3vh)]">
                                    {guestTitle} {guestFullname}
                                </p>
                            </div>
                        </div>

                        {/* loi moi */}
                        <div
                            className={
                                'text-center mt-3 ' +
                                (highlightContext === 'invitation' && ' animate-bounce ')
                            }
                        >
                            <span className="font-nautigal  text-[calc(30px_+_1vh)] font-extrabold">
                                {dataCopy.invitation && dataCopy.invitation}
                            </span>
                        </div>
                        <div className="flex justify-center ">
                            <div
                                className={
                                    (!isSubEvent &&
                                        'text-center  text-[calc(30px_+_1vh)] lg:text-[calc(40px_+_3vh)] font-extrabold leading-[60px]  my-5 font-nautigal text-amber-200 py-4') +
                                    (isSubEvent &&
                                        ' text-center  text-[calc(30px_+_1vh)] lg:text-[calc(40px_+_3vh)] font-extrabold leading-[50px] my-5 font-nautigal text-amber-200 py-4 ') +
                                    (highlightContext === 'nameEvent' && ' animate-bounce')
                                }
                            >
                                {dataCopy.nameEvent ? dataCopy.nameEvent : 'Christmas Party'}
                            </div>
                        </div>
                        {dataCopy?.time && dataCopy?.date && (
                            <div className="flex justify-center md:mt-2">
                                <div className="mb-3 flex flex-col items-center border-b border-amber-200 pb-3 pr-4 text-center">
                                    <span
                                        className={
                                            'text-[calc(0.3rem_+_1vh)] md:text-[calc(0.5rem_+_1vh)] leading-[28px]  tracking-[2px] font-extrabold ' +
                                            (highlightContext === 'dateHold' && ' animate-bounce ')
                                        }
                                    >
                                        {/* {dateTimeData
                                        ? dateTimeData.locale('en').format('dddd, MMMM Do, YYYY')
                                        : dataCopy.dateHold
                                            ? dataCopy.dateHold
                                            : moment(new Date()).format('dddd, MMMM Do, YYYY')} */}
                                        {dataCopy?.date &&
                                            getDateOfWeek(dataCopy?.date) +
                                                ', ngày ' +
                                                getDateOfMonth(dataCopy?.date) +
                                                ' tháng ' +
                                                getMonthOfYear(dataCopy?.date) +
                                                ' năm ' +
                                                getYear(dataCopy?.date)}
                                    </span>
                                    <span
                                        className={
                                            ' leading-[28px] text-[calc(0.4rem_+_1vh)] md:text-[calc(0.6rem_+_1vh)] tracking-[2px] font-extrabold ' +
                                            (highlightContext === 'timeHold' && ' animate-bounce ')
                                        }
                                    >
                                        {/* {dateTimeData
                                        ? dateTimeData.locale('en').format('hh:mm A')
                                        : dataCopy.time
                                        ? dataCopy.time
                                        : moment(new Date()).format('hh:mm A')} */}
                                        {dataCopy?.time && dataCopy?.time}
                                    </span>
                                </div>
                            </div>
                        )}

                        <div className="flex justify-center md:mt-2">
                            <div className="flex flex-col items-center text-center">
                                {dataCopy?.placeEvent && (
                                    <span
                                        className={
                                            ' leading-[28px] text-[calc(0.3rem_+_2vh)] md:text-[calc(0.5rem_+_2vh)] tracking-[2px] font-extrabold  ' +
                                            (highlightContext === 'placeEvent' &&
                                                ' animate-bounce ')
                                        }
                                    >
                                        {' '}
                                        {dataCopy?.placeEvent}
                                    </span>
                                )}
                                <span
                                    className={
                                        ' leading-[28px] text-[calc(0.3rem_+_1vh)] md:text-[calc(0.5rem_+_1vh)] tracking-[2px] font-extrabold  ' +
                                        (highlightContext === 'addressEvent' && ' animate-bounce ')
                                    }
                                >
                                    {dataCopy.addressEvent && dataCopy.addressEvent}{' '}
                                    {(dataCopy.addressEvent || dataCopy?.linkGgMap) && (
                                        <a
                                            href={`${
                                                dataCopy?.linkGgMap
                                                    ? dataCopy?.linkGgMap
                                                    : `${
                                                          'https://www.google.com/maps/search/?api=1&query=' +
                                                          dataCopy?.addressEvent
                                                      }`
                                            }`}
                                            rel="noreferrer"
                                            target="_blank"
                                            className={
                                                'ml-2 mr-1 border-amber-200 text-[17px] text-amber-200 hover:border-b '
                                            }
                                            title="View on map"
                                        >
                                            <i className="fas fa-map-marker-alt"></i>
                                        </a>
                                    )}
                                </span>
                                {url_guest && url_share && eventConfig?.auto_response && (
                                    <a
                                        href={'/' + url_share + '/' + url_guest + '/xac-nhan'}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <button className="mt-2 flex items-center rounded bg-amber-200 px-4 py-1.5 text-[calc(0.5rem_+_0.7vh)] font-semibold text-red-800 md:mt-4">
                                            {/* <FaEnvelopeOpenText className="mr-2" /> Xác nhận tham dự */}
                                            {statusConvert(guest?.status)}
                                        </button>
                                    </a>
                                )}
                                {!url_guest && url_share && eventConfig?.auto_join && (
                                    <button
                                        className="mt-2 flex items-center rounded bg-amber-200 px-4 py-1.5 text-[calc(0.5rem_+_0.7vh)] font-semibold text-red-800 transition-all md:mt-4"
                                        data-bs-toggle="modal"
                                        data-bs-target="#formModal"
                                    >
                                        <FaEnvelopeOpenText className="mr-2" /> Đăng kí tham dự
                                    </button>
                                )}
                            </div>
                        </div>
                        {dataCopy?.dress_1 || dataCopy?.dress_2 ? (
                            <div className="w-full text-center mt-10">
                                <p className="text-sm tracking-[2px] font-extrabold  text-amber-200">
                                    Trang phục
                                </p>
                                {dataCopy?.dress_1 ? (
                                    <p className="w-full text-sm font-Comfortaa text-center font-medium mt-2  text-white">
                                        {dataCopy?.dress_1}
                                    </p>
                                ) : null}
                                {dataCopy?.dress_2 ? (
                                    <p className="w-full text-sm font-Comfortaa text-center font-medium mt-2  text-white">
                                        {dataCopy?.dress_2}
                                    </p>
                                ) : null}
                            </div>
                        ) : null}
                        <div className="mx-auto  mt-5 text-center">
                            {(data?.phoneSupporter || data?.nameSupporter) && (
                                <>
                                    <p className="text-sm tracking-[2px] font-extrabold  text-amber-200">
                                        Liên hệ hỗ trợ
                                    </p>
                                    <div className="flex">
                                        <p
                                            className={
                                                'text-right tracking-[2px] text-sm font-extrabold mt-2 w-1/2 text-white'
                                            }
                                        >
                                            <i className="mr-1 text-sm fas fa-user-alt text-amber-200 "></i>{' '}
                                            {data?.nameSupporter && data?.nameSupporter}
                                        </p>

                                        <a href={`tel:${data?.phoneSupporter}`} className="w-1/2 ">
                                            <p
                                                className={
                                                    'text-left text-sm flex tracking-[2px] font-extrabold mt-2.5  text-white hover:text-amber-200 '
                                                }
                                            >
                                                <i className="fas fa-phone-alt ml-5 mr-1.5 text-sm text-amber-200"></i>
                                                {data?.phoneSupporter && data?.phoneSupporter}
                                            </p>
                                        </a>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TiltlePage;
