/* eslint-disable */
import { FaExclamationTriangle, FaTrashAlt, FaArrowLeft } from 'react-icons/fa';

export default function ModalDelete({ funDelete, data }) {
    const btnDelete = () => {
        funDelete(data._id);
    };

    return (
        <div
            className="fade modal fixed left-0 top-0 hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
            id="confirmDeleteGreetingModal"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="modal-dialog modal-dialog-centered pointer-events-none relative w-auto">
                <div className="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
                    <div className="modal-header flex shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4">
                        <h5
                            className="flex items-center text-xl font-bold leading-normal text-gray-800"
                            id="exampleModalScrollableLabel"
                        >
                            <FaExclamationTriangle className="mr-2 text-amber-500" />
                            Xác nhận xóa lời chúc
                        </h5>
                        <button
                            type="button"
                            className="btn-close box-content h-4 w-4 rounded-none border-none p-1 text-black opacity-50 hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-header flex shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4">
                        <h5
                            className="text-lg font-medium leading-normal text-gray-800"
                            id="exampleModalScrollableLabel"
                        >
                            Bạn có chắc xóa lời chúc của {data?.guestName} không?
                        </h5>
                    </div>
                    <div className="modal-footer flex shrink-0 flex-wrap items-center justify-end rounded-b-md border-t border-gray-200 p-4">
                        <button
                            type="button"
                            className="hover: focus: active:  flex items-center gap-2 rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            <FaArrowLeft />
                            Trở lại
                        </button>
                        <button
                            type="button"
                            onClick={btnDelete}
                            data-bs-dismiss="modal"
                            className="ml-1 flex items-center gap-2 rounded bg-red-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg"
                        >
                            <FaTrashAlt />
                            Xóa
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
