/* eslint-disable */
import { useCallback, useEffect, useRef, useState } from 'react';

// import IMAGES_WEDDING from '../../resource/wedding/images/_index';

// import Templates from '../events/components/Templates';
import { useParams, useNavigate } from 'react-router-dom';

import PAGE from '../../../../config/path.route';

import EventsService from '../../../events/Events.service';

import ShortcutMenu from '../../components/ShortcutMenu';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import InputBasic from '../../../../components/InputBasic/InputBasic';
import { FaInfoCircle, FaTimes, FaSave } from 'react-icons/fa';
import PublishInfoPartyService from '../publish.partyinfo/PublishInfoParty.service';
import ToastNotify from '../../../../utils/Toast';
import HelpModal from './components/HelpModal';
import Loading from './components/Loading';
import Regex from '../../../../utils/Regex';
import Products from '../publish.news/products/Products';
import TimelineShortcutMenu from '../../components/TimelineShortcutMenu';
import Blank from './components/Blank';
import Invalid from './components/Invalid';
import QuestionTitle from '../publish.question/QuestionTitle';
import Header from '../publish.sub/components/Header';
import { useSelector } from 'react-redux';

export default function PublishVideo() {
    const navigate = useNavigate();
    const { eventId } = useParams();
    const [loading, setLoading] = useState(false);
    const [event, setEvent] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [check, setCheck] = useState(false);
    const { loggedIn } = useSelector((state) => state.auth);
    const formik = useFormik({
        initialValues: {
            videoKey: '',
        },
        validationSchema: Yup.object({
            videoKey: Yup.string()
                .required('Vui lòng nhập video key')
                .matches(Regex.keyYoutube, {
                    message:
                        'Video key chứa 11 ký tự bao gồm các chữ cái (hoa và thường), chữ số, dấu gạch dưới ("_"), và dấu gạch ngang ("-")',
                }),
        }),
    });
    useEffect(() => {
        setLoading(true);
        if (eventId) {
            EventsService.getOneEventById(eventId)
                .then(({ data }) => {
                    setEvent(data);
                    formik.setValues({
                        videoKey: data?.form_data?.videoKey ? data?.form_data?.videoKey : '',
                    });
                })
                .catch((error) => {
                    console.log(error);
                    const AuthorizeError = 404;
                    if (error.status === AuthorizeError) {
                        navigate(PAGE.dashboard);
                    }
                    const ServerError = 500;
                    if (error.status === ServerError) {
                        navigate(PAGE.dashboard);
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 500);
                });
        }
    }, []);

    const handleSubmit = () => {
        setCheck(true);
        if (!formik.errors.videoKey) {
            PublishInfoPartyService.submitForm(event._id, createPayload()).then(({ }) => {
                // setFormData(parseJsonData(data?.form_data));
                setIsEdit(false);
                ToastNotify.Success('Lưu thành công!');
            });
        }
    };

    const createPayload = () => {
        const payload = [{ key: 'videoKey', value: formik.values.videoKey }];
        return payload;
    };

    useEffect(() => {
        if (!loggedIn) {
            const beforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = true;
            };
            window.addEventListener('beforeunload', beforeUnload);
            return () => {
                window.removeEventListener('beforeunload', beforeUnload);
            };
        }
    }, []);

    return (
        <>
            <HelpModal />
            <div className="flex flex-col items-center gap-[1.5rem] pb-[2rem] relative">
                {!event?.isExpired && (
                    <div
                        className={
                            (isEdit ? 'bottom-0 opacity-100' : '-bottom-80 opacity-0') +
                            ' fixed inset-x-0 z-[40] bg-gray-50 transition-all duration-500 md:px-6 lg:z-[40]'
                        }
                    >
                        <div className="flex items-center justify-between gap-3 border-t border-gray-300 px-6 py-4 md:px-0">
                            <p>Chưa lưu !</p>
                            <div className="flex gap-3">
                                <button
                                    type="button"
                                    className={
                                        'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-100 px-6 py-2 text-center font-semibold text-blue-500 transition-all duration-150 ease-linear hover:bg-blue-200 '
                                    }
                                    onClick={() => {
                                        setIsEdit(false);
                                    }}
                                >
                                    <FaTimes />
                                    Hủy bỏ
                                </button>
                                <button
                                    // type="submit"
                                    onClick={handleSubmit}
                                    className={
                                        'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-500 px-6 py-2 text-center font-semibold text-white transition-all duration-150 ease-linear hover:bg-blue-600 '
                                    }
                                >
                                    <FaSave />
                                    Hoàn tất
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <Header event={event} isExpired={event?.isExpired} />
                <div className=" w-full flex justify-evenly mt-20">
                    <div className="lg:flex hidden relative w-[250px]">
                        <TimelineShortcutMenu screen={'video'} />
                    </div>

                    {loading ? (
                        <Loading />
                    ) : (
                        <div className="w-[100vw] rounded-lg border-[1px] border-slate-200 bg-white-box shadow-lg lg:w-[48rem] 2xl:w-[60rem] flex flex-col">
                            <div className="w-full p-2 px-3 border-b border-gray-300 flex flex-col">
                                <p className="text-black font-bold text-lg mb-1">Video tiệc cưới</p>
                                <QuestionTitle keyQues={'video'} />
                            </div>
                            <div className="w-full p-4">
                                <div className='flex w-full justify-end pr-2'>
                                    <button
                                        className="px-4 py-1 rounded-md bg-blue-700 text-white hover:bg-blue-800 flex items-center gap-2 text-sm"
                                        data-bs-toggle={'modal'}
                                        data-bs-target={'#helpModal'}
                                    >
                                        <FaInfoCircle /> Hướng dẫn lấy video key trên Youtube
                                    </button>
                                </div>
                                <div className="w-full col-span-3 px-2 form-group mt-4">
                                    <InputBasic
                                        id="videoKey"
                                        name="videoKey"
                                        // label="Video Key"
                                        placeholder={'VD: "9NLKcz1lrQr"'}
                                        type="text"
                                        size="medium"
                                        onChange={(e) => {
                                            setIsEdit(true);
                                            formik.handleChange(e);
                                        }}
                                        value={formik.values.videoKey}
                                        disable={event?.isExpired}
                                    />
                                    <div className="mb-5">
                                        <span className="text-xs text-red-500">
                                            {check && (formik.errors.videoKey || '')}
                                        </span>
                                    </div>
                                </div>
                                {formik?.values?.videoKey?.trim() !== '' ? (
                                    !formik.errors.videoKey ? (
                                        <div className="w-full p-4">
                                            <iframe
                                                className="w-full h-[500px]"
                                                src={`https://www.youtube.com/embed/${formik.values.videoKey}`}
                                                title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                referrerpolicy="strict-origin-when-cross-origin"
                                            ></iframe>
                                        </div>
                                    ) : (
                                        <Blank />
                                    )
                                ) : (
                                    <Blank />
                                )}
                            </div>
                            {/* <ShortcutMenu screen={'video'} /> */}
                        </div>
                    )}
                    <div className="lg:flex hidden relative w-[250px]">
                        <Products />
                    </div>
                </div>
            </div>
        </>
    );
}
