export default function SlideSkeleton() {
    return (
        <div className="m-2 max-h-[80dvh] flex-auto overflow-y-auto px-6 py-2">
            <ul className="grid grid-cols-1 gap-x-4 gap-y-6 overflow-y-auto md:grid-cols-3">
                {Array.from({length: 6}).map((index)=><li key={index} className="relative ">
                    <div
                        className="group relative block w-full animate-pulse gap-[28px] overflow-hidden rounded-lg bg-white p-6"
                        style={{
                            '--tw-aspect-h': 7,
                            '--tw-aspect-w': 10,
                            position: 'relative',
                            paddingBottom:
                                'calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%)',
                        }}
                    >
                        <div
                            className="absolute inset-0 mx-auto h-[10rem] w-[16rem] rounded-lg bg-slate-200 object-cover"
                            alt="Cô dâu"
                        />
                    </div>
                </li>)}
                
            </ul>
            
        </div>
    );
}
