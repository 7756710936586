import 'tw-elements';

import { applyMiddleware, compose, createStore } from 'redux'; // createStore

import { BrowserRouter } from 'react-router-dom';
import { FocusProvider } from './context/Focus.context';
import { HighlightProvider } from './context/Highlight.context';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom/client';
import RouteApp from './routes/route';
import { ToastContainer } from 'react-toastify';
import reducers from './redux/reducers/_index.reducer'; // import reducer thay đổi store
import thunkMiddleware from 'redux-thunk';
import { HelmetProvider } from 'react-helmet-async';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import reportWebVitals from './reportWebVitals';
// import TagManager from 'react-gtm-module';
// import ReactGA from 'react-ga4';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// Tạo store
export const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleware)));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <Provider store={store}>
		<GoogleOAuthProvider
			clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
		>
			<HelmetProvider>
				<FocusProvider>
					<HighlightProvider>
						<BrowserRouter>
							<ToastContainer
								position="top-right"
								autoClose={2000}
								draggable
								pauseOnHover={true}
								hideProgressBar={true}
								newestOnTop={false}
								pauseOnFocusLoss={true}
								closeOnClick
								limit={3}
							/>
							<RouteApp />
						</BrowserRouter>
					</HighlightProvider>
				</FocusProvider>
			</HelmetProvider>
		</GoogleOAuthProvider>
    </Provider>
    // </React.StrictMode>
);

/* eslint-disable */
// ReactGA.initialize(process.env.REACT_APP_GA_ID, { debug: true, gaOptions: { cookieDomain:'none' }});
// TagManager.initialize({
// 	gtmId: process.env.REACT_APP_GTAG_MANAGER_ID
// });
// reportWebVitals((metric) => {
// 	console.log(metric);
// 	TagManager.dataLayer({
// 		dataLayer: {
// 			event: 'web_vitals',
// 			cwvMetric: metric.name,
// 			cwvValue: metric.delta,
// 			cwvId: metric.id,
// 		}
// 	});
// });
