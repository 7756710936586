import EventGreetings from '../../../../api/event.greeting.api';

const EventGreetingService = {

    getAll: async (eventId, query) => {
        const { data } = await EventGreetings.getAll(eventId, query);
        return data.data;
    },

    analysis: async (eventId) => {
        const { data } = await EventGreetings.analysis(eventId);
        return data;
    },

    getNew: async (eventId) => {
        const { data } = await EventGreetings.getNew(eventId);
        return data;
    },

    getAllByGuest: async (slugEvent) => {
        const { data } = await EventGreetings.getAllByGuest(slugEvent);
        return data.data;
    },

    getOneByGuest: async (slugEvent, slugGuest) => {
        const { data } = await EventGreetings.getOneByGuest(slugEvent, slugGuest);
        return data.data;
    },

    getOne: async (id) => {
        const { data } = await EventGreetings.getOne(id);
        return data.data;
    },

    create: async (payload) => {
        const { data } = await EventGreetings.create(payload);
        return data.data;
    },

    createByGuest: async (payload) => {
        const { data } = await EventGreetings.createByGuest(payload);
        return data.data;
    },

    update: async (id, payload) => {
        const { data } = await EventGreetings.update(id, payload);
        return data.data;
    },

    delete: async (id) => {
        const result = await EventGreetings.delete(id);
        return result;
    },

    createFile: async (url) => {
        let response = await fetch(url);
        let data = await response.blob();
        return new File([data], 'create_image.jpg', { type: 'image/jpeg' });
    },

};

export default EventGreetingService;
