/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

export default function ModalInfo({ data, setSelectParty, submitForm, clearForm }) {
    const [errValid, setErrValid] = useState({ name: '' });
    const [checkValid, setCheckValid] = useState(false);
    const handleChange = (e) => {
        if (e.target.name === 'name' && e.target.value.trim() !== '') {
            setErrValid({ name: '' });
        }
        setSelectParty({ ...data, ...{ [e.target.name]: e.target.value } });
    };

    const handleSubmit = () => {
        setCheckValid(true);

        if (data?.name && data?.name?.trim() !== '') {
            setErrValid({ name: '' });
            submitForm();
            setCheckValid(false);
        } else {
            console.log('err');
            setErrValid({ name: 'Vui lòng nhập tiêu đề!' });
        }
    };

    const handleClear = () => {
        setCheckValid(false);
        setErrValid({ name: '' });
        clearForm();
    };

    const handleDateChange = (date) => {
        handleChange({
            target: { name: 'date', value: date ? moment(date).format('YYYY-MM-DD') : '' },
        });
    }

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="modalContent"
            data-bs-backdrop="static"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-300 modal-header shrink-0 rounded-t-md">
                        <h5 className="text-[16px] font-bold leading-normal text-gray-800">
                            {data?.name ? 'Chỉnh sửa câu chuyện' : 'Thêm mới câu chuyện'}
                        </h5>
                        <button
                            type="button"
                            title="Đóng"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClear}
                        ></button>
                    </div>
                    <div className="flex flex-col items-center justify-between w-full border-b border-gray-200 modal-content shrink-0 rounded-t-md p-[20px] gap-[10px]">
                        <div className="flex flex-col w-full gap-[10px]">
                            <p className="font-bold text-[14px] text-black">
                                Tiêu đề <span className="text-red-500">(*)</span>
                            </p>
                            <input
                                name="name"
                                value={data?.name || ''}
                                onChange={handleChange}
                                type="text"
                                placeholder={`VD: "Phút giây cầu hôn"`}
                                className={` form-control m-0 block w-[100%] rounded border border-solid ${
                                    checkValid && errValid?.name !== ''
                                        ? 'border-red-500'
                                        : 'border-gray-300'
                                } bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                            />
                            <p className="text-sm text-red-500">{errValid?.name}</p>
                        </div>
                        <div className="flex flex-col w-full gap-[10px]">
                            <p className="font-bold text-[14px] text-black">Thời gian</p>
                            <div className="w-full flex gap-[8px] items-center">
                                <input
                                    name="time"
                                    value={data?.time || ''}
                                    onChange={handleChange}
                                    type="time"
                                    className={` form-control m-0 block w-[50%] rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                                />
                                {/* <input
                                    name="date"
                                    value={data?.date || ''}
                                    onChange={handleChange}
                                    type="date"
                                    className={` form-control m-0 block w-[50%] rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                                /> */}
                                <DatePicker
                                    name="date"
                                    selected={data?.date || ''}
                                    onChange={(date) => handleDateChange(date)}
                                    locale="vi"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText={'dd/mm/yyyy'}
                                    className={`form-control m-0 block w-[100%] rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                                    showYearDropdown
                                    yearDropdownItemNumber={100}
                                    scrollableYearDropdown
                                />
                            </div>
                        </div>

                        <div className="flex flex-col w-full gap-[10px]">
                            <p className="font-bold text-[14px] text-black">Địa điểm</p>
                            <input
                                name="place"
                                value={data?.place || ''}
                                onChange={handleChange}
                                type="text"
                                placeholder={`VD: "Nhân Chính, Thanh Xuân, Hà Nội"`}
                                className={` form-control m-0 block w-[100%] rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                            />
                        </div>
                        <div className="flex flex-col w-full gap-[10px]">
                            <p className="font-bold text-[14px] text-black">Nội dung</p>
                            <textarea
                                rows={5}
                                name="content"
                                value={data?.content || ''}
                                onChange={handleChange}
                                type="text"
                                placeholder="Thêm nội dung"
                                className={` form-control m-0 block w-[100%] rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-md font-normal text-gray-700 transition ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none `}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover:bg-gray-50 transition-all focus:bg-gray-50 active:bg-gray-50  inline-block rounded bg-white border-[1px] border-gray-300 px-5 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                            onClick={handleClear}
                        >
                            Thoát
                        </button>
                        <button
                            type="button"
                            onClick={handleSubmit}
                            data-bs-dismiss={data?.name && data?.name?.trim() !== '' ? 'modal' : ''}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg  focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                        >
                            Lưu
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
