/* eslint-disable */
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { removeAllSpace } from '../../../utils/Capitalize';
import LoadingButtonIcon from '../../../components/LoadingButton/LoadingButtonIcon';
import storageConstant from '../../../config/constant/storage.constant';

const FormSignUp = ({ formikSignUp, changeMode, setMode, isOpen, isLoading }) => {
    const focusInput = useRef(null);
    const [showPassword, setShowPassword] = useState(false);
    const toogleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const [emailInputStarted, setEmailInputStarted] = useState(false);
    const [fullnameInputStarted, setFullnameInputStarted] = useState(false);
    const [phoneInputStarted, setPhoneInputStarted] = useState(false);
    const [usernameInputStarted, setUsernameInputStarted] = useState(false);
    const [passwordInputStarted, setPasswordInputStarted] = useState(false);
    const [passwordConfirmInputStarted, setPasswordConfirmInputStarted] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                focusInput.current.focus();
            }, 100);
            const guestUser = JSON.parse(localStorage.getItem(storageConstant.GUEST_USER));
            if (guestUser?.fullname) {
                formikSignUp.setFieldValue('fullname', guestUser.fullname, false);
            }
            if (guestUser?.email) {
                formikSignUp.setFieldValue('email', guestUser.email, false);
            }
            if (guestUser?.phone) {
                formikSignUp.setFieldValue('phone', guestUser.phone, false);
            }
        }
    }, [isOpen]);
    return (
        <div
            className="mx-auto w-full md:mx-0  max-w-[700px] lg:min-w-[500px] form-register  max-h-[75vh] sm:max-h-[80vh] lg:max-h-[85vh] xl:max-h-[95vh] overflow-y-auto"
            id="modal-signup"
        >
            <div className="flex flex-col w-full p-5 bg-white max-md:rounded-b-xl md:rounded-r-xl md:p-10">
                <h2 className="text-2xl font-bold text-left text-gray-800 sm:mb-2">Đăng ký tài khoản</h2>
                <form onSubmit={formikSignUp.handleSubmit} className="w-full">
                    <div id="fullname-input" className="flex flex-col w-full my-4">
                        <label htmlFor="fullname" className="mb-1 text-left text-gray-700 ">
                            Họ và tên
                        </label>
                        <input
                            ref={focusInput}
                            type="text"
                            id="register-fullname"
                            name="fullname"
                            placeholder="Nhập Họ và tên"
                            className={`border-1 appearance-none rounded-lg px-4 py-3 text-black placeholder:text-gray-400 focus:shadow-lg focus:outline-none focus:ring-1 ${(formikSignUp.errors.fullname && fullnameInputStarted)
                                ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                                : 'border-gray-300 focus:border-blue-600 focus:ring-blue-600'
                                }`}
                            value={formikSignUp.values.fullname}
                            onChange={(e) => {
                                if (!fullnameInputStarted) {
                                    setFullnameInputStarted(true)
                                }
                                formikSignUp.setFieldValue('fullname', e.target.value)
                            }}
                            helperText={formikSignUp.errors.fullname}
                        />
                        {
                            fullnameInputStarted && (
                                <span className="mt-0.5 text-sm text-left text-red-500">
                                    {formikSignUp.errors.fullname}
                                </span>
                            )
                        }
                    </div>
                    <div id="email-input" className="flex flex-col w-full my-4">
                        <label htmlFor="email" className="mb-1 text-left text-gray-700 ">
                            Email <span className="text-red-500">(*)</span>
                        </label>
                        <input
                            // ref={focusInput}
                            type="text"
                            id="register-email"
                            name="email"
                            placeholder="Nhập email"
                            className={`border-1 appearance-none rounded-lg px-4 py-3 text-black placeholder:text-gray-400 focus:shadow-lg focus:outline-none focus:ring-1 ${(formikSignUp.errors.email && emailInputStarted)
                                ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                                : 'border-gray-300 focus:border-blue-600 focus:ring-blue-600'
                                }`}
                            value={formikSignUp.values.email}
                            onChange={formikSignUp.handleChange}
                            // onChange={(e) => {
                            //     if (!emailInputStarted) {
                            //         setEmailInputStarted(true)
                            //     }
                            //     formikSignUp.setFieldValue('email', removeAllSpace(e.target.value))
                            // }}
                            helperText={formikSignUp.errors.email}
                        />
                        {
                            emailInputStarted && (
                                <span className="mt-0.5 text-sm text-left text-red-500">
                                    {formikSignUp.errors.email}
                                </span>
                            )
                        }
                    </div>
                    <div id="username-input" className="flex flex-col w-full my-4">
                        <label htmlFor="username" className="mb-1 text-left text-gray-700 ">
                            Tên đăng nhập <span className="text-red-500">(*)</span>
                        </label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            placeholder="Nhập tên đăng nhập"
                            className={`border-1 appearance-none rounded-lg px-4 py-3 text-black placeholder:text-gray-400 focus:shadow-lg focus:outline-none focus:ring-1 ${(formikSignUp.errors.username && usernameInputStarted)
                                ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                                : 'border-gray-300 focus:border-blue-600 focus:ring-blue-600'
                                }`}
                            value={formikSignUp.values.username}
                            onChange={formikSignUp.handleChange}
                            // onChange={(e) => {
                            //     if (!usernameInputStarted) {
                            //         setUsernameInputStarted(true);
                            //     }
                            //     formikSignUp.setFieldValue('username', removeAllSpace(e.target.value))
                            // }}
                            helperText={formikSignUp.errors.username}
                        />
                        {
                            usernameInputStarted && (
                                <span className="mt-0.5 text-sm text-left text-red-500">
                                    {formikSignUp.errors.username}
                                </span>
                            )
                        }
                    </div>
                    <div className="flex flex-col w-full my-4">
                        <label htmlFor="password" className="mb-1 text-left text-gray-700">
                            Mật khẩu <span className="text-red-500">(*)</span>
                        </label>
                        <div className="flex">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="register-password"
                                name="password"
                                placeholder="Nhập mật khẩu"
                                className={`border-1 w-full appearance-none rounded-lg px-4 py-3 text-black placeholder:text-gray-400 focus:shadow-lg focus:outline-none focus:ring-1 ${(formikSignUp.errors.password && passwordInputStarted)
                                    ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                                    : 'border-gray-300 focus:border-blue-600 focus:ring-blue-600'
                                    }`}
                                value={formikSignUp.values.password}
                                onChange={formikSignUp.handleChange}
                                // onChange={(e) => {
                                //     if (!passwordInputStarted) {
                                //         setPasswordInputStarted(true);
                                //     }
                                //     formikSignUp.setFieldValue('password', removeAllSpace(e.target.value))
                                // }}
                            />
                            <i
                                className={
                                    'text-black mt-2.5 -ml-9 text-lg cursor-pointer fas fa-eye' +
                                    (showPassword ? '' : '-slash')
                                }
                                onClick={toogleShowPassword}
                            ></i>
                        </div>
                        {
                            passwordInputStarted && (
                                <span className="mt-0.5 text-sm text-left text-red-500">
                                    {formikSignUp.errors.password}
                                </span>
                            )
                        }
                    </div>
                    <div className="flex flex-col w-full my-4">
                        <label htmlFor="confirmPassword" className="mb-1 text-left text-gray-700">
                            Xác nhận lại mật khẩu <span className="text-red-500">(*)</span>
                        </label>
                        <div className="flex">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="confirmPassword"
                                name="confirmPassword"
                                placeholder="Xác nhận lại mật khẩu"
                                className={`border-1 w-full appearance-none rounded-lg px-4 py-3 text-black placeholder:text-gray-400 focus:shadow-lg focus:outline-none focus:ring-1 ${(formikSignUp.errors.confirmPassword && passwordConfirmInputStarted)
                                    ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                                    : 'border-gray-300 focus:border-blue-600 focus:ring-blue-600'
                                    }`}
                                value={formikSignUp.values.confirmPassword}
                                onChange={formikSignUp.handleChange}
                                // onChange={(e) => {
                                //     if (!passwordConfirmInputStarted) {
                                //         setPasswordConfirmInputStarted(true);
                                //     }
                                //     formikSignUp.setFieldValue(
                                //         'confirmPassword',
                                //         removeAllSpace(e.target.value)
                                //     )
                                // }}
                            />
                            <i
                                className={
                                    'text-black mt-2.5 -ml-9 text-lg cursor-pointer fas fa-eye' +
                                    (showPassword ? '' : '-slash')
                                }
                                onClick={toogleShowPassword}
                            ></i>
                        </div>
                        {
                            passwordConfirmInputStarted && (
                                <span className="mt-0.5 text-sm text-left text-red-500">
                                    {formikSignUp.errors.confirmPassword}
                                </span>
                            )
                        }
                    </div>
                    <div id="phone-input" className="flex flex-col w-full my-4">
                        <label htmlFor="phone" className="mb-1 text-left text-gray-700 ">
                            Số điện thoại
                        </label>
                        <input
                            type="text"
                            id="register-phone"
                            name="phone"
                            placeholder="Nhập số điện thoại"
                            className={`border-1 appearance-none rounded-lg px-4 py-3 text-black placeholder:text-gray-400 focus:shadow-lg focus:outline-none focus:ring-1 ${(formikSignUp.errors.phone && phoneInputStarted)
                                ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                                : 'border-gray-300 focus:border-blue-600 focus:ring-blue-600'
                                }`}
                            value={formikSignUp.values.phone}
                            onChange={formikSignUp.handleChange}
                            // onChange={(e) => {
                            //     if (!phoneInputStarted) {
                            //         setPhoneInputStarted(true)
                            //     }
                            //     formikSignUp.setFieldValue('phone', e.target.value)
                            // }}
                            helperText={formikSignUp.errors.phone}
                        />
                        {
                            phoneInputStarted && (
                                <span className="mt-0.5 text-sm text-left text-red-500">
                                    {formikSignUp.errors.phone}
                                </span>
                            )
                        }
                    </div>
                    <div className="flex flex-col w-full mt-5 mb-1 sm:mb-5">
                        <button
                            type="submit"
                            disabled={isLoading}
                            onClick={() => {
                                setFullnameInputStarted(true)
                                setPhoneInputStarted(true)
                                setEmailInputStarted(true)
                                setUsernameInputStarted(true)
                                setPasswordInputStarted(true)
                                setPasswordConfirmInputStarted(true)
                            }}
                            className="w-full rounded-lg bg-gradient-to-r from-[#51ADF5]  to-[#2196F3] py-4 text-white hover:from-[#2196F3] hover:to-[#51ADF5]  hover:shadow-lg "
                        >
                            <div className="flex flex-row items-center justify-center">
                                {isLoading && <LoadingButtonIcon />}
                                <div className="font-bold text-white ">Tạo tài khoản</div>
                            </div>
                        </button>
                        <div className="flex mt-1 flex-start justify-evenly sm:mt-5">
                            <div>
                                <span className="text-gray-600">Bạn đã có tài khoản. </span>
                                <span  //<Link
                                    // to="#"
                                    className="w-full font-medium text-right text-main-500 cursor-pointer"
                                    onClick={() => {
                                        setMode('login');
                                        changeMode();
                                    }}
                                >
                                    Đăng nhập
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FormSignUp;
