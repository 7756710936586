/* eslint-disable */
import '../Event3.css';

import IMAGES_EVENT3 from '../assets/_index';
import envConstants from '../../../../config/key';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import { getDateOfMonth, getDateOfWeek, getMonthOfYear, getYear } from '../../../../utils/Times';

export default function SubEvent({
    data,
    guestFullname,
    guestTitle,
    guestSalutation,
    isSubEvent = false,
    eventConfig
}) {
    const pathLogo = data.logo && data.logo[0];
    const [pathImgLogo, setPathImgLogo] = useState('');
    useEffect(() => {
        if (
            !/^blob:/.test(pathLogo) &&
            !/^data:image/.test(pathLogo) &&
            !/^\/static\/media/.test(pathLogo) &&
            pathLogo
        ) {
            setPathImgLogo(envConstants.SERVER_URL + pathLogo);
        } else if (pathLogo) {
            setPathImgLogo(pathLogo);
        } else {
            setPathImgLogo('');
        }
    }, [pathLogo]);

    return (
        <div className="parent relative my-auto h-screen w-[100%] overflow-hidden ">
            <div
                className={`absolute left-0 top-0 flex h-full w-full items-center justify-end bg-[url('./resource/events/event-3/assets/imgs/slider-bg.jpg')]  bg-cover bg-center `}
            >
                <div
                    className={'flex h-full w-full items-center justify-center lg:justify-end bg-white/60'}
                >
                    <div
                        className={`z-[99] flex  h-[100%] w-full  justify-start pt-16  flex-col items-center   lg:bg-transparent`}
                    >
                        {pathImgLogo && (
                            <img
                                className={`h-[calc(8rem_+_2vh)]`}
                                src={pathImgLogo}
                            />
                        )}
                        <h2
                            className={'mt-6 text-center font-Merriweather text-xl uppercase tracking-[2px] text-gray-800'}
                        >
                            {guestSalutation ? guestSalutation : data.titleEvent}
                        </h2>

                        <h2
                            className={`mt-5 font-greatVibes text-gray-800 text-2xl text-center `}
                        >
                            {guestTitle ? guestTitle.trim() : '...................'}{' '}
                        </h2>
                        <h2
                            className={`mt-1 font-Merriweather text-gray-800  text-2xl text-center `}
                        >
                            {guestFullname ? guestFullname.trim() : '.............................'}
                        </h2>
                        <h1
                            className={'text-normal-stroke  text-center mt-10 font-Merriweather uppercase text-[16px] lg:text-lg font-thin tracking-[2px] text-white '}
                        >
                            {data.invitation && data.invitation}
                        </h1>
                        <h2
                            className={'text-black-stroke mt-4 text-center font-pacifico text-[34px] lg:text-4xl font-thin tracking-[4px] text-blue-500'}
                        >
                            {data.nameEvent && data.nameEvent}
                        </h2>
                        <div className='w-full text-center mt-8'>
                            {(data?.timeHold || data?.dateHold) &&
                                <p className='w-full font-Merriweather font-semibold uppercase text-gray-700 text-lg md:text-xl text-center'>
                                    Thời gian
                                </p>
                            }
                            <p className='text-black-stroke w-full text-3xl md:text-4xl font-pacifico text-blue-200 font-thin italic text-center'>
                                {data?.timeHold && data?.timeHold + ''}
                            </p>
                            <p className=' w-full text-lg md:text-xl font-Merriweather text-gray-700 font-semibold italic text-center'>
                                {data?.dateHold &&
                                    getDateOfWeek(data?.dateHold) +
                                    ', ngày ' +
                                    getDateOfMonth(data?.dateHold) +
                                    ' tháng ' +
                                    getMonthOfYear(data?.dateHold) +
                                    ' năm ' +
                                    getYear(data?.dateHold)
                                }
                            </p>
                        </div>
                        <div className='w-full text-center  mt-8'>
                            {(data?.placeHold || data?.addressHold) &&
                                <p className='w-full font-Merriweather font-semibold uppercase text-gray-700 text-lg md:text-xl text-center'>
                                    Địa điểm
                                </p>
                            }
                            <p className='text-black-stroke mt-2 w-full text-2xl md:text-3xl  text-blue-200 font-pacifico font-medium italic text-center'>
                                {data?.placeHold}
                            </p>
                            <p className=' w-full text-lg md:text-xl font-Merriweather text-gray-700 font-semibold italic text-center'>
                                {data?.addressHold}{' '}
                                {data?.addressHold && (
                                    <a
                                        href={
                                            'https://www.google.com/maps/search/?api=1&query=' +
                                            data?.addressHold
                                        }
                                        rel="noreferrer"
                                        target="_blank"
                                        className={
                                            'ml-1 border-blue-600 text-[17px] text-blue-600 hover:border-b '
                                        }
                                        title="Xem trên bản đồ"
                                    >
                                        <i className="fas fa-map-marker-alt"></i>
                                    </a>
                                )}
                            </p>
                        </div>
                        <div className="mx-auto w-auto max-w-[90%] border border-gray-600 rounded-md bg-white px-6 py-2 mb-4 mt-10 text-center text-[15px] md:mt-16 md:text-[16px] ">
                            {(data?.phoneSupporter || data?.nameSupporter) && (
                                <>
                                    <p className="text-[16px] font-semibold text-gray-800 md:text-lg">
                                        LIÊN HỆ HỖ TRỢ
                                    </p>
                                    <div className="flex flex-wrap items-center justify-center w-full">
                                        <p
                                            className={
                                                'text-right font-medium mt-2  text-gray-800'
                                            }
                                        >
                                            <i className="mr-1 text-lg fas fa-user-alt text-blue-700 md:text-xl "></i>{' '}
                                            {data?.nameSupporter && data?.nameSupporter}
                                        </p>

                                        <a href={`tel:${data?.phoneSupporter}`} className=" ">
                                            <p
                                                className={
                                                    'text-left flex font-medium mt-2.5  text-gray-800 hover:text-gray-900 '
                                                }
                                            >
                                                <i className="fas fa-phone-alt ml-5 mr-1.5 text-lg text-blue-700 md:text-xl"></i>
                                                {data?.phoneSupporter && data?.phoneSupporter}
                                            </p>
                                        </a>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
