import BG from './BG_2023_1.webp';
import LONG_DEN_1 from './longden1.png';
import LONG_DEN_2 from './longden2.png';
import LONG_DEN_3 from './longden3.png';
import LONG_DEN_DONG from './den-long-dong.gif';
import MEO from './meo.png';
import HOA_TIET from './HoaTiet_2023.png';
import PHAO_HOA_VANG from './phao-hoa-vang.gif';
import PHAO_HOA_XANH from './phao-hoa-xanh.gif';
import CANH_HOA_1 from './canh-hoa-1.png';
import CANH_HOA_2 from './canh-hoa-2.png';
import CANH_HOA_3 from './canh-hoa-3.png';
import CANH_HOA_4 from './canh-hoa-4.png';
import CANH_HOA_5 from './canh-hoa-5.png';
import BG_CONTENT_2 from './Back.webp';
import HOA_VAN_1 from './hoa-van-1.png';
import HOA_VAN_3 from './hoa-van-3.png';
import HOA_VAN_2 from './hoa-van-2.png';
import HOA_VAN_4 from './hoa-van-4.png';
import NHAC from './music.mp3';

const IMAGES_EVENT8 = {
    BG,
    LONG_DEN_1,
    LONG_DEN_2,
    HOA_TIET,
    MEO,
    PHAO_HOA_VANG,
    PHAO_HOA_XANH,
    CANH_HOA_1,
    CANH_HOA_2,
    CANH_HOA_3,
    CANH_HOA_4,
    CANH_HOA_5,
    LONG_DEN_DONG,
    NHAC,
    BG_CONTENT_2,
    LONG_DEN_3,
    HOA_VAN_1,
    HOA_VAN_2,
    HOA_VAN_3,
    HOA_VAN_4,
};

export default IMAGES_EVENT8;
