import ToastNotify from './Toast';
import { ROLE_ORDER, USER_ROLE } from '../config/constant/common.constant';

/**
 * 
 * @param {*} file : e.target.files[0]
 * @param {*} maxSize : MB
 * @param {*} messageError 
 */
export const validateSizeOfFile = (file, maxSize, messageError) => {
  const miliMB = 1024;
  const fileSize = file?.size / miliMB / miliMB; // in MB
  console.log('fileSize = ', fileSize);
//   console.log('maxSize = ', maxSize);
  if (fileSize > maxSize) {
    if (messageError) {
      // ToastNotify.Error(messageError ?? `Dung lượng file không được lớn hơn ${maxSize}Mb`);
      ToastNotify.Error(messageError);
    }
    return false;
  }
  return true;
};

export function getHighestRole(roles) {
	if (roles && !roles.length) {
		return USER_ROLE.NORMAL;
	}
	let highestRole = USER_ROLE.GUEST;
	if (roles?.length) {
		for (const role of roles) {
			if (ROLE_ORDER[role] > ROLE_ORDER[highestRole]) {
				highestRole = role;
			}
		}
	}
	return highestRole;
}

export async function sleep (delay) {
	return new Promise((resolve) => setTimeout(resolve, delay));
} 