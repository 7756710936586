/* eslint-disable */

import { FaPen } from 'react-icons/fa';
import img from './imgs/sub.webp';

const Blank = () => {
    return (
        <div className=" flex flex-col justify-center items-center w-full ">
            <img src={img} alt="not found" className="w-[250px] opacity-70" />

            <p className=" text-center text-blue-900 font-bold py-6 max-w-[600px]">
                Chúng tôi cần thêm một số thông tin để hoàn tất thiệp mời của bạn. Vui lòng giúp
                chúng tôi nhé!{' '}
            </p>
            <button
                data-bs-toggle="modal"
                data-bs-target="#modalInputValidatePublic"
                className="px-10 py-2 bg-blue-500 hover:bg-blue-700 transition-all rounded-md text-white font-semibold flex items-center gap-2 text-sm"
            >
                <FaPen />
                Hoàn thiện thông tin
            </button>
        </div>
    );
};

export default Blank;
