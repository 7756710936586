/* eslint-disable */

import { Link, useNavigate, useParams } from 'react-router-dom';
import PAGE from '../../../../../config/path.route';
import { FaChartPie, FaExternalLinkAlt } from 'react-icons/fa';
import { checkTypeTemplate, limitString } from '../../publish.greeting/PublishGreeting.page';
import { useSelector } from 'react-redux';

export default function Header({ event, isExpired }) {
    const navigate = useNavigate();
    const { eventId } = useParams();
    const { loggedIn } = useSelector((state) => state.auth);
    // const limitString = (str) => {
    //     if (!str) {
    //         return '';
    //     }
    //     if (str.length > 35) {
    //         return str.substring(0, 35) + '...';
    //     }
    //     return str;
    // };
    return (
        <div className="fixed top-0 z-[999] w-full bg-white md:bg-gray-50 md:px-4 lg:px-6">
            <div className="relative flex w-full flex-wrap items-center justify-between gap-x-3 p-4 md:px-0 lg:flex-nowrap lg:gap-4 lg:py-5">
                <div className="flex md:flex-row flex-col md:gap-2">
                    <button
                        className="flex items-center"
                        type="button"
                        onClick={() => {
                            navigate(`/${eventId}/mainmenu`);
                        }}
                        title="Trở về"
                    >
                        <i className=" fas fa-chevron-left"></i>
                        <p className="order-1 ml-3 font-bold text-black">
                            {/* Quản lý lời chúc */}
                            {limitString(event?.name)}
                        </p>
                    </button>
                    {isExpired && (
                        <div className="h-5 rounded-[4px]  flex justify-center items-center bg-red-500 px-3 w-fit">
                            <span className="text-[9px] text-white uppercase">
                                {checkTypeTemplate(event?.template?.alias)}
                            </span>
                        </div>
                    )}
                    {!loggedIn && (
                        <div className="h-5 rounded-[4px]  flex justify-center items-center bg-red-500 px-3 w-fit">
                            <span className="text-[9px] text-white uppercase">
                                Bạn chưa đăng nhập
                            </span>
                        </div>
                    )}
                </div>
                <div className="flex justify-center gap-2 overflow-hidden ">
                    {loggedIn && (
                        <Link
                            // target="_blank"
                            className="inline-flex select-none gap-2 rounded-md bg-white border border-blue-500 px-3 py-1 text-center font-medium text-blue-500 transition-all duration-150 ease-linear hover:bg-blue-100 xl:px-4 text-sm"
                            to={`..${PAGE.profile}`}
                            state={{ isResourcePackage: true }}
                        >
                            Quản lý gói dịch vụ
                            <FaChartPie className="my-auto" />
                        </Link>
                    )}
                    <button className="inline-flex w-max select-none rounded-md bg-blue-500 px-3 py-1 text-center font-medium text-white transition-all duration-150 ease-linear hover:bg-blue-600 xl:px-4 text-sm">
                        <Link target="_blank" className="flex gap-2" to={'../' + event?.url_slug}>
                            Xem thiệp <FaExternalLinkAlt className="my-auto" />
                        </Link>
                    </button>
                </div>
            </div>
            <div className="w-full border-b border-gray-300"></div>
        </div>
    );
}
