/* eslint-disable */
import { getDateOfMonth, getDateOfWeek, getMonthOfYear, getYear } from '../../../../utils/Times';
import { useContext, useEffect, useState } from 'react';

import { FocusContext } from '../../../../context/Focus.context';
import { HighlightContext } from '../../../../context/Highlight.context';
import envConstants from '../../../../config/key';
import IMAGES_EVENT6 from '../assets/images';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import { statusConvert } from '../../../_index/helper';
import Sponsor from './Sponsor';

const TiltlePage = ({
    data = {},
    guest,
    guestFullname = '',
    salutation = '',
    guestTitle = '',
    eventConfig,
    show,
}) => {
    const dataCopy = {
        invitation: data.invitation,
        titleEvent: data.titleEvent,
        nameEvent: data.nameEvent,
        logo: data.logo && data.logo[0],
        placeHold: data.placeHold,
        addressHold: data.addressHold,
        date: data.dateHold,
        time: data.timeHold,
        linkGgMap: data?.linkGgMap ?? '',
        dress_1: data?.dress_1 ? data?.dress_1 : '',
        dress_2: data?.dress_2 ? data?.dress_2 : '',
    };

    const { url_share, url_guest } = useParams();
    console.log(url_share, url_guest, eventConfig?.auto_response);

    const focusContext = useContext(FocusContext);
    const [highlightContext, setHighlightContext] = useContext(HighlightContext);

    const [pathImgLogo, setPathImgLogo] = useState('');

    useEffect(() => {
        if (
            !/^blob:/.test(dataCopy?.logo) &&
            !/^data:image/.test(dataCopy?.logo) &&
            !/^\/static\/media/.test(dataCopy?.logo) &&
            dataCopy?.logo !== undefined &&
            dataCopy?.logo !== '' &&
            dataCopy?.logo !== null &&
            dataCopy?.logo !== false
        ) {
            setPathImgLogo(envConstants.SERVER_URL + dataCopy?.logo);
        } else if (dataCopy?.logo) {
            setPathImgLogo(dataCopy?.logo);
        } else {
            setPathImgLogo('');
        }
    }, [dataCopy?.logo]);

    return (
        <>
            <div
                className="font-Comfortaa mx-auto  h-fit min-h-[85vh] max-w-[1444px]  bg-cover bg-no-repeat text-white"
                style={{ backgroundImage: `url(${IMAGES_EVENT6.BG_8})` }}
            >
                <div className="w-full h-full pt-5 pb-10 md:pb-20 md:pt-10">
                    <div
                        className="mx-auto w-[95%] md:w-3/4 "
                        tabIndex="0"
                        ref={focusContext.formGeneral}
                    >
                        {pathImgLogo && (
                            <div className="px-10 mx-auto bg-white w-fit rounded-xl md:px-16">
                                <img
                                    className="mx-auto w-[100px] md:w-[200px]"
                                    src={pathImgLogo}
                                    loading="lazy"
                                    alt="logo"
                                />
                            </div>
                        )}

                        <div
                            className={
                                'mt-5 md:mt-10 text-center ' +
                                (highlightContext === 'titleEvent' && ' animate-bounce ')
                            }
                        >
                            <span className="text-3xl font-bold md:text-4xl">
                                {dataCopy?.titleEvent}
                            </span>
                        </div>

                        <div className="mx-auto my-5 w-[90%] border-y border-gray-300 md:my-10 md:w-2/3">
                            <div className="w-full mx-auto mt-5 mb-3">
                                <p className="text-xl font-extrabold text-center uppercase font-Comfortaa">
                                    {salutation}
                                </p>
                            </div>
                            <div className="font-Comfortaa mx-auto mb-5 mt-3 text-center text-[16px]  text-amber-200 md:text-lg">
                                <p className="uppercase break-words">
                                    {guestTitle ||
                                        '................................................'}
                                </p>
                                <p className="text-2xl font-extrabold uppercase break-words md:text-3xl">
                                    {guestFullname || '...............................'}
                                </p>
                            </div>
                        </div>

                        {/* loi moi */}
                        <div
                            className={
                                'text-center font-Comfortaa mt-3 ' +
                                (highlightContext === 'invitation' && ' animate-bounce ')
                            }
                        >
                            <span className="text-xl md:text-2xl ">{dataCopy?.invitation}</span>
                        </div>
                        <div className="flex justify-center md:-mt-2">
                            <div
                                className={
                                    'text-center uppercase   text-3xl md:text-4xl font-extrabold text-amber-200 py-4 ' +
                                    (highlightContext === 'nameEvent' && ' animate-bounce ')
                                }
                            >
                                {dataCopy?.nameEvent}
                            </div>
                        </div>
                        <div className="flex items-center gap-[20px] justify-center">
                            {url_guest && url_share && eventConfig?.auto_response && (
                                <a
                                    href={'/' + url_share + '/' + url_guest + '/xac-nhan'}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <button className="mt-2 flex items-center rounded bg-red-500 px-4 py-1.5 text-[calc(0.5rem_+_0.7vh)] font-semibold text-white transition hover:bg-red-700 md:mt-10">
                                        {/* <FaEnvelopeOpenText className="mr-2" /> Xác nhận tham dự */}
                                        {statusConvert(guest?.status)}
                                    </button>
                                </a>
                            )}

                            {!url_guest && url_share && eventConfig?.auto_join && (
                                <button
                                    className="mt-2 flex items-center rounded bg-red-500 px-4 py-1.5 text-[calc(0.5rem_+_0.7vh)] font-semibold text-white transition-all hover:bg-red-700 md:mt-10"
                                    data-bs-toggle="modal"
                                    data-bs-target="#formModal"
                                >
                                    <FaEnvelopeOpenText className="mr-2" /> Đăng kí tham dự
                                </button>
                            )}
                        </div>

                        <div className="mx-auto mt-3 w-full md:mt-[5vh] md:w-3/4">
                            <div className="grid grid-flow-col grid-rows-5 gap-4">
                                <div className="flex items-end justify-end col-span-2 row-span-2 pr-4 text-xl font-bold border-r border-gray-30 md:text-2xl">
                                    THỜI GIAN
                                </div>
                                <div className="flex flex-col items-end col-span-2 row-span-3 pr-4 border-r border-gray-30">
                                    <span
                                        className={
                                            'text-3xl md:text-5xl tracking-[3px] -mr-[3px] font-extrabold ' +
                                            (highlightContext === 'timeHold' && ' animate-bounce ')
                                        }
                                    >
                                        {' '}
                                        {dataCopy?.time}
                                    </span>
                                    <span
                                        className={
                                            'text-lg ' +
                                            (highlightContext === 'dateHold' && ' animate-bounce ')
                                        }
                                    >
                                        <span className="text-[17px]">
                                            {dataCopy?.date &&
                                                getDateOfWeek(dataCopy?.date) + ' -  '}
                                        </span>
                                        {dataCopy?.date &&
                                            getDateOfMonth(dataCopy?.date) +
                                                '/' +
                                                getMonthOfYear(dataCopy?.date) +
                                                '/' +
                                                getYear(dataCopy?.date)}
                                    </span>
                                </div>
                                <div className="flex items-end col-span-3 row-span-2 text-xl font-bold md:text-2xl">
                                    ĐỊA ĐIỂM
                                </div>
                                <div className="flex flex-col col-span-3 row-span-3">
                                    <span
                                        className={
                                            'text-[18px] md:text-[22px] font-bold ' +
                                            (highlightContext === 'placeHold' && ' animate-bounce ')
                                        }
                                    >
                                        {' '}
                                        {dataCopy?.placeHold}
                                    </span>
                                    <span
                                        className={
                                            'text-[15px] mt-2 md:text-[17px] ' +
                                            (highlightContext === 'addressHold' &&
                                                ' animate-bounce ')
                                        }
                                    >
                                        {dataCopy?.addressHold}{' '}
                                        {(dataCopy?.addressHold || dataCopy?.linkGgMap) && (
                                            <a
                                                href={`${
                                                    dataCopy?.linkGgMap
                                                        ? dataCopy?.linkGgMap
                                                        : `${
                                                              'https://www.google.com/maps/search/?api=1&query=' +
                                                              dataCopy?.addressHold
                                                          }`
                                                }`}
                                                rel="noreferrer"
                                                target="_blank"
                                                className={
                                                    'ml-2 mr-1 border-white text-[17px] text-white hover:border-b '
                                                }
                                                title="Xem trên bản đồ"
                                            >
                                                <i className="fas fa-map-marker-alt"></i>
                                            </a>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {dataCopy?.dress_1 || dataCopy?.dress_2 ? (
                            <div className="w-full text-center mt-10">
                                <p className="text-lg font-extrabold text-white md:text-xl">
                                    TRANG PHỤC
                                </p>
                                {dataCopy?.dress_1 ? (
                                    <p className="w-full text-lg font-Comfortaa text-center font-medium mt-2  text-white">
                                        {dataCopy?.dress_1}
                                    </p>
                                ) : null}
                                {dataCopy?.dress_2 ? (
                                    <p className="w-full text-lg font-Comfortaa text-center font-medium mt-2  text-white">
                                        {dataCopy?.dress_2}
                                    </p>
                                ) : null}
                            </div>
                        ) : null}
                        <div className="mx-auto my-5 text-center text-[15px] md:text-[16px]">
                            {(data?.phoneSupporter || data?.nameSupporter) && (
                                <>
                                    <p className="text-lg font-extrabold text-white md:text-xl">
                                        LIÊN HỆ HỖ TRỢ
                                    </p>
                                    <div className="flex">
                                        <p
                                            className={
                                                'text-right font-bold mt-4 w-1/2 ' +
                                                (highlightContext === 'nameSupporter' &&
                                                    ' animate-bounce ')
                                            }
                                        >
                                            <i className="mr-1 text-lg fas fa-user-alt text-amber-200 md:text-xl "></i>{' '}
                                            {data?.nameSupporter && data?.nameSupporter}
                                        </p>

                                        <a href={`tel:${data?.phoneSupporter}`} className="w-1/2">
                                            <p
                                                className={
                                                    'text-left font-bold mt-4  text-white hover:text-slate-400 ' +
                                                    (highlightContext === 'phoneSupporter' &&
                                                        ' animate-bounce ')
                                                }
                                            >
                                                <i className="fas fa-phone-alt ml-5 mr-1.5 text-lg text-amber-200 md:text-xl"></i>
                                                {data?.phoneSupporter && data?.phoneSupporter}
                                            </p>
                                        </a>
                                    </div>
                                </>
                            )}
                        </div>
                        {show && data?.sponsors && data?.sponsors?.length > 0 ? (
                            <Sponsor data={data} />
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TiltlePage;
