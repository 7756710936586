import authService from '../../services/auth.service';
import userConstants from '../../config/redux/user.redux';
import { USER_ROLE } from '../../config/constant/common.constant';
import storageConstant from '../../config/constant/storage.constant';

const authActions = {
    login: (email, password, tokenCaptcha) => {
        const loginRequest = () => ({
            type: userConstants.LOGIN_REQUEST,
            payload: {},
        });
        const loginSuccess = (user) => ({
            type: userConstants.LOGIN_SUCCESS,
            payload: user,
        });
        const loginFailure = (error) => ({
            type: userConstants.LOGIN_FAILURE,
            payload: error,
        });
        return async (dispatch) => {
			const guestUser = localStorage.getItem(storageConstant.GUEST_USER);
			if (guestUser && JSON.parse(guestUser)?.user_name !== email) {
				dispatch(loginRequest());
			}
            return authService
                .handleLoginIn(email, password, tokenCaptcha)
                .then((user) => {
					if (!user?.roles?.includes(USER_ROLE.GUEST)) {
						dispatch(loginSuccess(user));
					}
                    return Promise.resolve();
                })
                .catch((error) => {
                    dispatch(loginFailure(error.errorMessage));
                    return Promise.reject(error);
                });
        };
    },
	authProviderLogin: (authProvider, loginData) => {
        const loginRequest = () => ({
            type: userConstants.AUTH_LOGIN_REQUEST,
            payload: {},
        });
        const loginSuccess = (user) => ({
            type: userConstants.LOGIN_SUCCESS,
            payload: user,
        });
        const loginFailure = (error) => ({
            type: userConstants.LOGIN_FAILURE,
            payload: error,
        });
        return async (dispatch) => {
            dispatch(loginRequest());
            return authService
                .handleAuthProviderLogin(authProvider, loginData)
                .then((user) => {
                    dispatch(loginSuccess(user));
                    return Promise.resolve();
                })
                .catch((error) => {
                    dispatch(loginFailure(error.errorMessage));
                    return Promise.reject(error);
                });
        };
	},
    changePassword: (oldPassword, newPassword) => async () => authService
                .handleChangePassword(oldPassword, newPassword)
                .then(() => Promise.resolve())
                .catch((error) => Promise.reject(error)),
	setPassword: (password) => async () => authService
				.handleSetPassword(password)
				.then(() => Promise.resolve())
				.catch((error) => Promise.reject(error)),
	sendVerifyEmail: () => async () => authService
				.handleVerifyEmail()
				.then(() => {
					console.log('then');
					return Promise.resolve();
				})
				.catch((error) => {
					console.log('catch');
					return Promise.reject(error);
				}),
};

export default authActions;
