/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
/* eslint-disable tailwindcss/classnames-order */
import { useState } from 'react';
import { FaEnvelope, FaEnvelopeOpenText, FaUserCheck, FaUserTimes } from 'react-icons/fa';

const statusList = [
    {
        name: 'Chưa gửi lời mời',
        bgColor: 'bg-yellow-50',
        icon: <FaEnvelope />,
        color: '#ca8a04',
        status: 'UNINVITED',
    },
    {
        name: 'Đã gửi lời mời',
        bgColor: 'bg-blue-50',
        icon: <FaEnvelopeOpenText />,
        color: '#1d4ed8',
        status: 'INVITED',
    },
    {
        name: 'Đồng ý tham dự',
        bgColor: 'bg-green-50',
        icon: <FaUserCheck />,
        color: '#16a34a',
        status: 'ACCEPT',
    },
    {
        name: 'Từ chối tham dự',
        bgColor: 'bg-red-50',
        icon: <FaUserTimes />,
        color: '#dc2626',
        status: 'DECLINE',
    },
];

export default function ChangeStatusModal({ status, setStatus }) {
    const [newStatus, setNewStatus] = useState(status);

    const checkStatus = (status) => {
        const findStatus = statusList.find((item) => item.status === status);
        return findStatus.status === newStatus;
    };

    const handleSave = () => {
        setStatus(newStatus);
    };

    return (
        <div
            className="fade modal fixed left-0 top-0 hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
            id="selectStatusModal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="modal-dialog modal-dialog-centered pointer-events-none relative w-auto">
                <div className="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
                    <div className="modal-header flex shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4">
                        <h5
                            className="text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Thay đổi trạng thái
                        </h5>

                        <button
                            type="button"
                            className="focus:opacity-200 btn-close box-content h-4 w-4 rounded-none border-none p-1 text-black opacity-50 hover:text-black hover:no-underline hover:opacity-75 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    <div className="flex max-h-[40vh] w-full flex-col gap-[12px] p-2 ">
                        {statusList.map((item, index) => (
                            <button
                                key={index}
                                className={`py-3 px-4 border-[1px] rounded-md  flex items-center gap-2 ${
                                    checkStatus(item.status) ? item.bgColor : 'bg-white'
                                }`}
                                onClick={() => {
                                    setNewStatus(item.status);
                                }}
                                style={{
                                    borderColor: checkStatus(item.status) ? item.color : '#d1d5db',
                                    color: checkStatus(item.status) ? item.color : '#4b5563',
                                }}
                            >
                                {item.icon} {item.name}
                            </button>
                        ))}
                    </div>

                    <div className="modal-footer flex shrink-0 flex-wrap items-center justify-end rounded-b-md border-t border-gray-200 p-4">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss={'modal'}
                            onClick={() => {
                                setNewStatus(status);
                            }}
                        >
                            Trở lại
                        </button>

                        <button
                            type="button"
                            data-bs-dismiss={'modal'}
                            onClick={handleSave}
                            className={
                                'ml-1 inline-block rounded bg-blue-700 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg'
                            }
                        >
                            Lưu
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
