
import BG from './bg2.jpg';
import BG_CONTENT_3 from './bg-content-3.jpg';
import BG_CONTENT_BOTTOM from './bg-bottom.png';
import BG_CONTENT_BOTTOM_3 from './bg-bottom-3.png';
import BG_THANKYOU from './bg-thankyou.png';
import NGOI_SAO_ROI from './ngoi-sao-roi.png';

const IMAGE_EVENT13 = {
    BG,
    BG_CONTENT_3,
    BG_CONTENT_BOTTOM,
    BG_CONTENT_BOTTOM_3,
    BG_THANKYOU,
    NGOI_SAO_ROI
};

export default IMAGE_EVENT13;