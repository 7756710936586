/* eslint-disable */

import React, { useEffect, useRef, useState } from 'react';
import ToastNotify from '../../../../../utils/Toast';

import { FaCaretDown } from 'react-icons/fa';

export default function SelectGuestModal({ listGuest, handleAddGuestToTable }) {
    const [listSelected, setListSelected] = useState([]);
    const closeRef = useRef(null);
    const handleAddGuest = (guest) => {
        if (checkGuestInList(guest._id)) {
            const newList = listSelected.filter((item) => item._id !== guest._id);
            setListSelected(newList);
            console.log('newList 1', newList);
        } else {
            const newList = [...listSelected, guest];
            setListSelected(newList);
            console.log('newList 2', newList);
        }
    };

    const [search, setSearch] = useState('');
    const [searchMode, setSearchMode] = useState('all');

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    const searchResult = () => {
        if (search.trim() === '') {
            return listGuest.filter((guest) =>
                searchMode === 'all' ? true : searchMode === 'no_table' ? !guest.table : guest.table
            );
        }
        return listGuest.filter((guest) => {
            const name = guest?.title + guest?.fullname;
            return (
                name.toLowerCase().includes(search.toLowerCase()) &&
                (searchMode === 'all' ||
                    (searchMode === 'no_table' && !guest.table) ||
                    (searchMode === 'have_table' && guest.table))
            );
        });
    };

    const handleSubmit = () => {
        if (listSelected.length === 0) {
            ToastNotify.Error('Vui lòng chọn khách mời để thêm');
            return;
        }
        handleAddGuestToTable(listSelected.map(({ _id }) => _id));
        closeRef.current.click();
    };

    const handleClear = () => {
        setListSelected([]);
    };

    const checkGuestInList = (id) => {
        // console.log('id', id);
        return listSelected.map(({ _id }) => _id).includes(id);
    };

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="modalSelectGuest"
            data-bs-backdrop="static"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-300 modal-header shrink-0 rounded-t-md">
                        <h5 className="text-[16px] font-bold leading-normal text-gray-800">
                            Thêm khách mời
                        </h5>
                        <button
                            type="button"
                            title="Đóng"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref={closeRef}
                            onClick={handleClear}
                        ></button>
                    </div>
                    <div className="flex flex-col items-center justify-between w-full border-b border-gray-200 modal-content shrink-0 rounded-t-md p-[20px] gap-[10px]">
                        <div className="relative mb-[10px] flex flex-wrap items-stretch w-full transition-all rounded-lg ease-soft ">
                            <span className="ease-soft leading-5.6 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-r-none border-r-0 border-transparent  px-2.5 py-2 text-center text-sm font-normal text-slate-500 transition-all">
                                <i className="fas fa-search" aria-hidden="true"></i>
                            </span>
                            <input
                                type="text"
                                value={search}
                                onChange={handleSearch}
                                className="focus:shadow-soft-primary-outline ease-soft w-1/100 leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border-solid bg-white bg-clip-padding py-2 pl-9 pr-3 text-sm text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-600 focus:outline-none focus:transition-shadow"
                                placeholder="Tìm kiếm theo tên khách"
                            />

                            <div className="absolute group top-1/2 right-[4px]">
                                <button
                                    id="dropdownSelectModeButton"
                                    aria-expanded="false"
                                    data-bs-toggle="dropdown"
                                    className=" -translate-y-1/2  flex items-center gap-2 px-2 py-1 text-sm text-white bg-blue-500 rounded-md"
                                >
                                    {searchMode === 'all' && 'Tất cả'}
                                    {searchMode === 'no_table' && 'Khách chưa có bàn'}
                                    {searchMode === 'have_table' && 'Khách đã có bàn'}
                                    <FaCaretDown />
                                </button>
                                <ul
                                    className="dropdown-menu absolute z-[999] float-left m-0 mt-1 hidden min-w-max list-none rounded bg-white bg-clip-padding py-2  text-center text-base shadow-lg"
                                    aria-labelledby="dropdownSelectModeButton"
                                >
                                    <li>
                                        <button
                                            disabled={searchMode === 'all'}
                                            onClick={() => setSearchMode('all')}
                                            className={`${
                                                searchMode === 'all'
                                                    ? 'bg-gray-300'
                                                    : 'hover:bg-gray-100 cursor-pointer'
                                            }  flex items-center gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700  dropdown-item whitespace-nowrap `}
                                        >
                                            Tất cả
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            disabled={searchMode === 'no_table'}
                                            onClick={() => setSearchMode('no_table')}
                                            className={`${
                                                searchMode === 'no_table'
                                                    ? 'bg-gray-300'
                                                    : 'hover:bg-gray-100 cursor-pointer'
                                            }  flex items-center gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700  dropdown-item whitespace-nowrap `}
                                        >
                                            Khách chưa có bàn
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            disabled={searchMode === 'have_table'}
                                            onClick={() => setSearchMode('have_table')}
                                            className={`${
                                                searchMode === 'have_table'
                                                    ? 'bg-gray-300'
                                                    : 'hover:bg-gray-100 cursor-pointer'
                                            } flex items-center gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700  dropdown-item whitespace-nowrap `}
                                        >
                                            Khách đã có bàn
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 w-full max-h-[800px] overflow-auto items-center">
                            {searchResult()?.length === 0 ? (
                                <p className="text-sm text-black font-bold">
                                    Không tìm thấy kết quả nào
                                </p>
                            ) : (
                                searchResult()?.map((guest, index) => (
                                    <div
                                        className={`w-full px-4 py-2 rounded  flex justify-between cursor-pointer ${
                                            checkGuestInList(guest._id)
                                                ? 'border-blue-500 text-blue-500 border-[2px]'
                                                : 'text-black border-[1px]'
                                        }`}
                                        onClick={() => {
                                            handleAddGuest(guest);
                                        }}
                                    >
                                        <p className="text-sm font-bold ">
                                            {guest?.title} {guest?.fullname}
                                        </p>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover:bg-gray-50 transition-all focus:bg-gray-50 active:bg-gray-50  inline-block rounded bg-white border-[1px] border-gray-300 px-5 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 duration-150 ease-in-out"
                            data-bs-dismiss="modal"
                            onClick={handleClear}
                        >
                            Thoát
                        </button>
                        <button
                            type="button"
                            onClick={handleSubmit}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg  focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                        >
                            Lưu
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
