/* eslint-disable */
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const InputBasic = ({
    label,
    name,
    id,
    type = 'text',
    size = 'default',
    placeholder,
    value,
    onChange,
    onBlur,
    invalid,
    onKeyPress,
    require,
    disable = false,
    onKeyDown,
}) => {
    const [classSize, setClassSize] = useState('px-3 py-1.5 text-base w-full');
    useEffect(() => {
        switch (size) {
            case 'small':
                setClassSize('px-2 py-1 text-sm w-full');
                break;
            case 'default':
                setClassSize('px-3 py-2 text-sm w-full');
                break;
            case 'large':
                setClassSize('px-4 py-2 text-xl w-full');
            case 'semiexlarge':
                setClassSize('px-4 py-2 text-xl w-[400px]');
                break;
            case 'exlarge':
                setClassSize('px-4 py-2 text-xl w-[800px]');
                break;
            default:
                break;
        }
    }, [size]);
    return (
        <div className="mb-2">
            {label && (
                <label
                    htmlFor={id}
                    className="inline-block mb-2 text-sm font-bold text-gray-700 form-label"
                >
                    {label} {require && <span className="text-red-500 ">(*)</span>}
                </label>
            )}
            <input
                name={name}
                type={type}
                disabled={disable}
                className={` form-control block  ${classSize}  ${
                    disable ? 'bg-gray-50' : 'bg-white'
                } font-normal text-gray-700  bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none focus:border-blue-600 ${
                    invalid && 'border-red-500'
                } `}
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onKeyPress={onKeyPress}
            />
        </div>
    );
};

export default InputBasic;
