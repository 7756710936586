/* eslint-disable */
import {
    FaCog,
    FaRegImages,
    FaRegClone,
    FaLink,
    FaFileDownload,
    FaEnvelope,
    FaRegClock,
    FaCaretDown,
    FaEdit,
    FaTrash,
    FaInfo,
    FaUserTie,
    FaAngleDown,
    FaAngleUp,
    FaAngleDoubleLeft,
    FaAngleDoubleRight,
    FaAngleLeft,
    FaAngleRight,
    FaHourglass,
    FaEnvelopeOpenText,
    FaUserCheck,
    FaUserTimes,
    FaChartPie,
    FaArrowLeft,
    FaExternalLinkAlt,
    FaPlus,
    FaUsers,
    FaUser,
    FaEnvelopeOpen,
    FaRedo,
    FaCheckCircle,
    FaTimesCircle,
    FaUserClock,
    FaUserMinus,
    FaTimes,
    FaTable,
    FaRegFrown,
    FaUserPlus,
    FaCheck,
    FaRegCheckSquare,
    FaChartBar,
    FaFileExcel,
    FaUsersCog,
    FaUserEdit,
    FaPen,
    setModalOpen,
    FaEllipsisH,
    FaCheckSquare,
    FaFilter,
    FaRedoAlt,
    FaSlidersH,
    FaSdCard,
    FaDownload,
    FaFileExport,
    FaFileImport,
    FaQrcode,
    FaFile,
    FaUndoAlt,
    FaClock,
    FaToggleOff,
    FaToggleOn,
} from 'react-icons/fa';
import { MdTableBar } from 'react-icons/md';

import { useState, useEffect, useRef } from 'react';

import ToastNotify from '../../../../../utils/Toast';
import { formatDatetime } from '../../../../../utils/Times';
import IMAGES from '../assets/images';
import image from '../../../../../assets/images/_index';
import { RiMailSettingsFill } from 'react-icons/ri';
import Blank from './Blank';

// components

const statusConvert = (status = '', timer = '') => {
    switch (status) {
        case 'UNINVITED':
            return 'Chưa gửi lời mời';
        case 'SENDING':
            if (timer) {
                return 'Đang chờ gửi theo lịch';
            }
            return 'Đang gửi lời mời';
        case 'INVITED':
            return 'Đã gửi lời mời';
        case 'ACCEPT':
            return 'Đồng ý tham dự';
        case 'DECLINE':
            return 'Từ chối tham dự';
        case 'VERIFYING':
            return 'Đang chờ xác nhận';
        case 'USER_ACCEPT':
            return 'Chấp nhận yêu cầu tham dự';
        case 'USER_DENY':
            return 'Từ chối yêu cầu tham dự';
        case 'true':
            return 'Khách VIP';
        case 'false':
            return 'Khách thường';
        case 'USER':
            return 'Thêm bởi chủ sự kiện';
        case 'GROUP':
            return 'Thêm bởi nhóm khách';
        case 'GUEST':
            return 'Khách tự đăng ký';
        case 'GUEST_INVITE':
            return 'Được thêm bởi khách';
        default:
            return;
    }
};
const defaultQuery = {
    search: '',
    status: '',
    pageNow: 1,
    vip: '',
    method: '',
    limit: 10,
};
export default function GuestTable({
    guests,
    eventTemplate,
    groups,
    onCopyInvite,
    onCopyLink,
    onDownloadTemplate,
    onCopyImage,
    guestSelect,
    guestQuery,
    setGuestQuery,
    setGuestSelect,
    actionMode,
    setActionMode,
    setSelectOne,
    onUpdateGuest,
    onReadGuest,
    getGuests,
    setModalOpen,
    handleAddGuest,
    viewMode,
    selectGroups,
    removeManyGuestFromGroup,
    onOpenChooseFile,
    onDownloadExcel,
    onDownloadExcelTemplate,
    refFile,
    onChangeFileSelect,
    statisticalData,
    setViewMode,
    resetAndAddGuestQuery,
    setMode,
    handleGetVerifyGuest,
    setIsChangeTimer,
    setTrigger,
    trigger,
}) {
    const [expandRow, setExpandRow] = useState([]);
    const rowRef = useRef([]);

    const checkedAllSelected = (guests) =>
        guests.every((guest) => guestSelect.map(({ _id }) => _id).includes(guest._id));

    const selectGuest = (e) => {
        e.stopPropagation();
        const { value, checked } = e.target;
        let guestSelected = [...guestSelect];
        if (checked) {
            setActionMode('many');
            guestSelected.push(guests.find((guest) => guest._id == value));
            setGuestSelect(guestSelected);
        } else {
            setActionMode('many');
            guestSelected = guestSelected.filter(
                (guestSelectedItem) => guestSelectedItem._id != value
            );
            setGuestSelect(guestSelected);
        }
    };

    const selectAll = (guests) => {
        let selected = [...guestSelect];
        guests.map((guest) => {
            if (!guestSelect.map(({ _id }) => _id).includes(guest._id)) {
                selected.push(guest);
            }
        });
        setGuestSelect(selected);
    };

    const removeSelectAll = () => {
        setGuestSelect(
            guestSelect.filter((select) => !guests.map(({ _id }) => _id).includes(select._id))
        );
    };

    const handleExpandAll = () => {
        let newExpandRow = [...expandRow];

        guests.map((guest) => {
            if (!expandRow.includes(guest._id)) {
                newExpandRow.push(guest._id);
            }
        });

        setExpandRow(newExpandRow);
    };
    const handleCloseExpandAll = () => {
        setExpandRow(expandRow.filter((item) => !guests.map(({ _id }) => _id).includes(item)));
    };

    const [firstCall, setFirstCall] = useState(false);
    useEffect(() => {
        if (firstCall) {
            const timeOutId = setTimeout(() => getGuests(eventTemplate.id), 500);
            return () => clearTimeout(timeOutId);
        } else {
            setFirstCall(true);
        }
    }, [guestQuery.search]);

    function countFilter() {
        const guestFilter = {
            status: guestQuery.status,
            vip: guestQuery.vip,
            method: guestQuery.method,
        };
        let count = 0;

        for (const key in guestFilter) {
            if (guestFilter.hasOwnProperty(key) && guestFilter[key] !== '') {
                count++;
            }
        }

        return count;
    }

    const handleRemoveFilter = (key) => {
        setGuestQuery({ ...guestQuery, [key]: '' });
    };

    const handleChangeLimit = (e) => {
        setGuestQuery({ ...guestQuery, limit: e.target.value, pageNow: 1 });
    };

    const handleClearSearch = () => {
        setGuestQuery({ ...guestQuery, search: '', status: '', vip: '', method: '' });
    };

    const checkVerifyStatus = (guest) => {
        if (guest.createdMethod === 'GUEST') {
            return 'VERIFYING';
        }
        if (guest.createdMethod === 'GUEST_INVITE') {
            if (guest.guest_invite.status.find((status) => status.active).status === 'ACCEPT') {
                return 'VERIFYING';
            }
            if (guest.guest_invite.status.find((status) => status.active).status === 'DECLINE') {
                return 'CANCEL';
            }
            return 'PROCESS';
        }
        if (guest.createdMethod === 'GROUP') {
            if (guest.group[0].status.find((status) => status.active).status === 'ACCEPT') {
                return 'VERIFYING';
            }
            if (guest.group[0].status.find((status) => status.active).status === 'DECLINE') {
                return 'CANCEL';
            }
            return 'PROCESS';
        }
    };

    const handleReload = () => {
        setTrigger(!trigger);
        ToastNotify.Success('Đã làm mới danh sách!');
    };

    return (
        <>
            <div className="relative mb-6 flex min-h-[80vh] w-full min-w-0 flex-col break-words ">
                <div className="w-full mb-0 border-0 rounded-t">
                    <div className="flex flex-col w-full">
                        <div className="flex flex-col mt-[10px] gap-[10px]">
                            <div className="flex justify-between max-md:flex-col">
                                <div className="flex items-center gap-2 justify-between">
                                    <div className="relative flex flex-wrap items-stretch md:w-[400px] w-[250px] transition-all rounded-lg ease-soft ">
                                        <span className="ease-soft leading-5.6 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-r-none border-r-0 border-transparent  px-2.5 py-2 text-center text-sm font-normal text-slate-500 transition-all">
                                            <i className="fas fa-search" aria-hidden="true"></i>
                                        </span>
                                        <input
                                            type="text"
                                            value={guestQuery.search}
                                            onChange={(e) =>
                                                setGuestQuery({
                                                    ...guestQuery,
                                                    search: e.target.value,
                                                })
                                            }
                                            className="focus:shadow-soft-primary-outline ease-soft w-1/100 leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border-solid bg-white bg-clip-padding py-2 pl-9 pr-3 text-sm text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-600 focus:outline-none focus:transition-shadow"
                                            placeholder="Tìm kiếm theo tên khách"
                                        />
                                        {/*  */}

                                        <div className="flex gap-1 items-center">
                                            <button
                                                onClick={handleReload}
                                                className="absolute group top-1/2 -translate-y-1/2 right-[36px] flex items-center gap-2 px-2 py-2 text-sm text-white bg-blue-500 rounded-md"
                                            >
                                                <FaUndoAlt />
                                                <span className="absolute -top-3 left-[50%] hidden -translate-y-full translate-x-[-50%] rounded-lg bg-gray-700  px-2 py-1 text-center text-sm font-thin text-white after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-gray-700 after:content-[''] group-hover:flex w-[76px]">
                                                    Làm mới
                                                </span>
                                            </button>

                                            {!guestQuery.verify_status && (
                                                <button
                                                    className="absolute group top-1/2 -translate-y-1/2 right-[4px] flex items-center gap-2 px-2 py-2 text-sm text-white bg-blue-500 rounded-md"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#filterModal"
                                                >
                                                    <FaSlidersH />
                                                    <span className="absolute -top-3 left-[50%] hidden -translate-y-full translate-x-[-50%] rounded-lg bg-gray-700  px-2 py-1 text-center text-sm font-thin text-white after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-gray-700 after:content-[''] group-hover:flex">
                                                        Lọc
                                                    </span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex bg-gray-100 gap-1 rounded-md">
                                    <button
                                        className={`py-1 px-4 text-xs text-black font-bold rounded-md ${
                                            viewMode === 'guest' &&
                                            !guestQuery.verify_status &&
                                            'bg-blue-500 text-white'
                                        }`}
                                        onClick={() => {
                                            const newGuestQuery = {
                                                search: '',
                                                status: '',
                                                pageNow: 1,
                                                vip: '',
                                                method: '',
                                                limit: 10,
                                                ...(guestQuery.guest_invite && {
                                                    guest_invite: guestQuery.guest_invite,
                                                }),
                                                ...(guestQuery.group && {
                                                    group: guestQuery.group,
                                                }),
                                            };
                                            setGuestQuery(newGuestQuery);
                                        }}
                                    >
                                        Khách mời
                                    </button>
                                    {!guestQuery.guest_invite && !guestQuery.group && (
                                        <button
                                            className={`py-1 px-4 text-xs text-black font-bold rounded-md`}
                                            onClick={() => {
                                                setViewMode('group');
                                                setMode('group-default');
                                            }}
                                        >
                                            Nhóm khách
                                        </button>
                                    )}
                                    <button
                                        onClick={() => {
                                            setGuestQuery({
                                                search: '',
                                                status: '',
                                                pageNow: 1,
                                                vip: '',
                                                method: '',
                                                limit: 10,
                                                verify_status: 'VERIFYING',
                                                ...(guestQuery.guest_invite && {
                                                    guest_invite: guestQuery.guest_invite,
                                                }),
                                                ...(guestQuery.group && {
                                                    group: guestQuery.group,
                                                }),
                                            });
                                        }}
                                        className={`py-1 px-4 text-xs text-black font-bold rounded-md flex gap-1 items-center  ${
                                            viewMode === 'guest' &&
                                            guestQuery.verify_status &&
                                            'bg-blue-500 text-white'
                                        }`}
                                    >
                                        Chờ duyệt{' '}
                                        <div className="flex justify-center items-center w-[24px] h-[24px] rounded-full bg-red-500">
                                            <span className="text-xs text-white">
                                                {statisticalData[0]?.guestVerifying}
                                            </span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div className="flex items-center gap-[12px]">
                                {guestQuery.status !== '' && (
                                    <div className="py-1 px-4 bg-gray-400 text-xs text-white rounded-full flex items-center gap-[8px]">
                                        {statusConvert(guestQuery.status)}
                                        <button
                                            onClick={() => {
                                                handleRemoveFilter('status');
                                            }}
                                        >
                                            <FaTimes />
                                        </button>
                                    </div>
                                )}
                                {guestQuery.vip !== '' && (
                                    <div className="py-1 px-4 bg-gray-400 text-xs text-white rounded-full flex items-center gap-[8px]">
                                        {statusConvert(guestQuery.vip)}
                                        <button
                                            onClick={() => {
                                                handleRemoveFilter('vip');
                                            }}
                                        >
                                            <FaTimes />
                                        </button>
                                    </div>
                                )}
                                {guestQuery.method !== '' && (
                                    <div className="py-1 px-4 bg-gray-400 text-xs text-white rounded-full flex items-center gap-[8px]">
                                        {statusConvert(guestQuery.method)}
                                        <button
                                            onClick={() => {
                                                handleRemoveFilter('method');
                                            }}
                                        >
                                            <FaTimes />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        {!guestQuery.verify_status ? (
                            <div className="w-full flex md:flex-row flex-col justify-start gap-[10px] my-[10px]">
                                <button
                                    className={`px-[10px] py-[6px] rounded-md text-xs text-white  font-normal flex items-center gap-2 ${
                                        eventTemplate?.isExpired ? 'bg-gray-400' : 'bg-blue-500'
                                    }`}
                                    onClick={handleAddGuest}
                                    disabled={eventTemplate?.isExpired}
                                >
                                    <FaUserPlus /> Thêm khách mời
                                </button>
                                <div className="flex items-center gap-[8px]">
                                    <button
                                        id="dropdownMenuButtonExcel"
                                        aria-expanded="false"
                                        data-bs-toggle="dropdown"
                                        className={`  bg-blue-500 cursor-pointer flex items-center gap-2 px-[10px] py-[6px] rounded-md text-xs text-white  font-normal`}
                                    >
                                        <FaFileExcel />
                                        File Excel <FaCaretDown className="ml-1 mt-0.5" />
                                    </button>
                                    <ul
                                        className="dropdown-menu absolute z-[999] float-left m-0 mt-1 hidden min-w-max list-none rounded bg-white bg-clip-padding py-2  text-center text-base shadow-lg"
                                        aria-labelledby="dropdownMenuButtonExcel"
                                    >
                                        <li>
                                            <button
                                                onClick={() => {
                                                    onOpenChooseFile();
                                                }}
                                                disabled={eventTemplate?.isExpired}
                                                style={{
                                                    backgroundColor:
                                                        eventTemplate?.isExpired &&
                                                        'rgb(243 244 246 / var(--tw-bg-opacity))',
                                                }}
                                                className={`flex items-center ${
                                                    eventTemplate?.isExpired
                                                        ? 'bg-gray-100'
                                                        : 'cursor-pointer hover:bg-blue-100'
                                                } gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700 bg-white  dropdown-item whitespace-nowrap `}
                                            >
                                                <FaFileImport /> Import
                                            </button>
                                        </li>
                                        <li>
                                            <span
                                                onClick={onDownloadExcel}
                                                className={`flex items-center cursor-pointer hover:bg-blue-100
                                             gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700 bg-white  dropdown-item whitespace-nowrap `}
                                            >
                                                <FaFileExport /> Export
                                            </span>
                                        </li>
                                        <li>
                                            <span
                                                onClick={onDownloadExcelTemplate}
                                                className={`flex items-center cursor-pointer hover:bg-blue-100
                                             gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700 bg-white  dropdown-item whitespace-nowrap `}
                                            >
                                                <FaDownload /> Tải mẫu
                                            </span>
                                        </li>
                                    </ul>
                                    <input
                                        ref={refFile}
                                        onChange={onChangeFileSelect}
                                        name="file"
                                        type="file"
                                        className="hidden"
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    />
                                </div>
                                {eventTemplate?.is_public && (
                                    <div className="flex items-center gap-[8px]">
                                        <button
                                            id="dropdownMenuButtonSendMail"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            className={` 
                                            bg-blue-500 cursor-pointer
                                                   
                                             flex items-center gap-2 px-[10px] py-[6px] rounded-md text-xs text-white  font-normal`}
                                        >
                                            <FaEnvelope />
                                            Gửi lời mời qua email{' '}
                                            <FaCaretDown className="ml-1 mt-0.5" />
                                        </button>
                                        <ul
                                            className="dropdown-menu absolute z-[999] float-left m-0 mt-1 hidden min-w-max list-none rounded bg-white bg-clip-padding py-2  text-center text-base shadow-lg"
                                            aria-labelledby="dropdownMenuButtonSendMail"
                                        >
                                            <li>
                                                <button
                                                    data-bs-toggle={
                                                        guestSelect?.length > 0 ? 'modal' : ''
                                                    }
                                                    data-bs-target={
                                                        eventTemplate?.email_host != '' &&
                                                        eventTemplate?.email_host
                                                            ? '#confirmSendEmail'
                                                            : '#confirmAddEmailModal'
                                                    }
                                                    disabled={
                                                        eventTemplate?.isExpired ||
                                                        guestSelect?.length === 0
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            (eventTemplate?.isExpired ||
                                                                guestSelect?.length === 0) &&
                                                            'rgb(243 244 246 / var(--tw-bg-opacity))',
                                                    }}
                                                    className={`flex items-center ${
                                                        eventTemplate?.isExpired ||
                                                        guestSelect?.length === 0
                                                            ? 'bg-gray-100'
                                                            : 'cursor-pointer hover:bg-blue-100'
                                                    } gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700 bg-white  dropdown-item whitespace-nowrap `}
                                                >
                                                    <FaEnvelope className="mr-1.5" />
                                                    Gửi ngay
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    data-bs-toggle={
                                                        guestSelect?.length > 0 ? 'modal' : ''
                                                    }
                                                    disabled={
                                                        eventTemplate?.isExpired ||
                                                        guestSelect?.length === 0
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            (eventTemplate?.isExpired ||
                                                                guestSelect?.length === 0) &&
                                                            'rgb(243 244 246 / var(--tw-bg-opacity))',
                                                    }}
                                                    data-bs-target="#setTimerSendMailManyGuests"
                                                    className={`flex items-center ${
                                                        eventTemplate?.isExpired ||
                                                        guestSelect?.length === 0
                                                            ? 'bg-gray-100'
                                                            : 'cursor-pointer hover:bg-blue-100'
                                                    } gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700 bg-white  dropdown-item whitespace-nowrap `}
                                                >
                                                    <FaClock className="mr-1.5" />
                                                    Gửi hẹn giờ
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                <div className="flex items-center gap-[8px]">
                                    <button
                                        id="dropdownMenuButtonSendMail"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        className={` 
                                            bg-blue-500 cursor-pointer
                                                   
                                             flex items-center gap-2 px-[10px] py-[6px] rounded-md text-xs text-white  font-normal`}
                                    >
                                        <RiMailSettingsFill />
                                        Cài đặt sự kiện <FaCaretDown className="ml-1 mt-0.5" />
                                    </button>
                                    <ul
                                        className="dropdown-menu absolute z-[999] float-left m-0 mt-1 hidden min-w-max list-none rounded bg-white bg-clip-padding py-2  text-center text-base shadow-lg"
                                        aria-labelledby="dropdownMenuButtonSendMail"
                                    >
                                        <li>
                                            <button
                                                // disabled={eventTemplate?.isExpired}
                                                data-bs-toggle="modal"
                                                data-bs-target="#settingEmailModal"
                                                className={`flex items-center cursor-pointer hover:bg-blue-100
                                                 gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700 bg-white  dropdown-item whitespace-nowrap `}
                                            >
                                                <FaCog />
                                                Cài đặt email
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                // disabled={eventTemplate?.isExpired}
                                                data-bs-toggle="modal"
                                                data-bs-target="#contentEmailModal"
                                                className={`flex items-center 
                                                  
                                                        cursor-pointer hover:bg-blue-100
                                                 gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700 bg-white  dropdown-item whitespace-nowrap `}
                                            >
                                                <FaEnvelopeOpenText />
                                                Nội dung email
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                // disabled={eventTemplate?.isExpired}
                                                data-bs-toggle="modal"
                                                data-bs-target="#remindModal"
                                                className={`flex items-center cursor-pointer hover:bg-blue-100
                                             gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700 bg-white  dropdown-item whitespace-nowrap `}
                                            >
                                                <FaToggleOn />
                                                Cài đặt
                                            </button>
                                        </li>
                                    </ul>
                                </div>

                                <div className="flex items-center gap-[8px]">
                                    <button
                                        id="dropdownMenuButtonSendMail"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        className={` 
                                            bg-blue-500 cursor-pointer
                                                   
                                             flex items-center gap-2 px-[10px] py-[6px] rounded-md text-xs text-white  font-normal`}
                                    >
                                        <FaQrcode />
                                        Mã QR
                                        <FaCaretDown className="ml-1 mt-0.5" />
                                    </button>
                                    <ul
                                        className="dropdown-menu absolute z-[999] float-left m-0 mt-1 hidden min-w-max list-none rounded bg-white bg-clip-padding py-2  text-center text-base shadow-lg"
                                        aria-labelledby="dropdownMenuButtonSendMail"
                                    >
                                        <li>
                                            <button
                                                data-bs-toggle="modal"
                                                data-bs-target="#exportQRModal"
                                                className={`flex items-center  gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700 bg-white  dropdown-item whitespace-nowrap cursor-pointer hover:bg-blue-100`}
                                            >
                                                <FaFile />
                                                Xuất QR toàn bộ khách mời
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                data-bs-toggle={
                                                    guestSelect?.length !== 0 && 'modal'
                                                }
                                                data-bs-target="#exportQRModalSelect"
                                                className={`flex items-center  gap-2 w-full pl-4 pr-20 py-2 text-xs font-normal text-gray-700 dropdown-item whitespace-nowrap ${
                                                    guestSelect?.length === 0
                                                        ? 'bg-gray-100'
                                                        : 'cursor-pointer hover:bg-blue-100'
                                                }`}
                                            >
                                                <FaFile />
                                                Xuất QR khách mời đã chọn
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <div className="w-full flex md:flex-row flex-col justify-start gap-[10px] my-[10px]">
                                <button
                                    className={`px-[10px] py-[6px] rounded-md text-xs text-white  font-normal flex items-center gap-2 ${
                                        eventTemplate?.isExpired || guestSelect?.length === 0
                                            ? 'bg-gray-400'
                                            : 'bg-blue-500'
                                    }`}
                                    data-bs-toggle="modal"
                                    data-bs-target="#confirmAcceptRequest"
                                    onClick={() => {
                                        if (actionMode !== 'all') {
                                            setActionMode('many');
                                        }
                                    }}
                                    disabled={eventTemplate?.isExpired || guestSelect?.length === 0}
                                >
                                    <FaCheck /> Chấp nhận yêu cầu tham dự
                                </button>
                                <button
                                    className={`px-[10px] py-[6px] rounded-md text-xs text-white  font-normal flex items-center gap-2 ${
                                        eventTemplate?.isExpired || guestSelect?.length === 0
                                            ? 'bg-gray-400'
                                            : 'bg-red-500'
                                    }`}
                                    data-bs-toggle="modal"
                                    data-bs-target="#confirmDenyRequest"
                                    onClick={() => {
                                        if (actionMode !== 'all') {
                                            setActionMode('many');
                                        }
                                    }}
                                    disabled={eventTemplate?.isExpired || guestSelect?.length === 0}
                                >
                                    <FaTimes /> Từ chối yêu cầu tham dự
                                </button>
                            </div>
                        )}
                        {actionMode != 'all' && guestSelect.length != 0 && (
                            <div className="flex flex-col items-center justify-center w-full my-2 xl:flex-row">
                                <p className="text-center text-[16px] font-bold leading-[25px] text-black">
                                    Bạn đã chọn {guestSelect?.length}/{guestQuery.totalDocs} khách
                                    mời!
                                </p>
                                <p
                                    className="ml-1 cursor-pointer text-[16px] font-bold leading-[25px] text-blue-500"
                                    onClick={() => {
                                        setActionMode('all');
                                    }}
                                >
                                    Chọn tất cả {guestQuery.totalDocs} khách mời
                                </p>
                                <p className="ml-1 cursor-pointer text-[16px] font-bold leading-[25px] text-black">
                                    hoặc
                                </p>
                                <p
                                    className="ml-1 cursor-pointer text-[16px] font-bold leading-[25px] text-red-500"
                                    onClick={() => {
                                        setGuestSelect([]);
                                    }}
                                >
                                    Bỏ chọn.
                                </p>
                            </div>
                        )}
                        {actionMode == 'all' && (
                            <div className="flex flex-col items-center justify-center w-full my-2 xl:flex-row">
                                <p className="text-[16px] font-bold leading-[25px] text-black">
                                    Bạn đã chọn tất cả {guestQuery.totalDocs} khách mời !
                                </p>
                                <p
                                    className="cursor-pointer text-[16px] font-bold leading-[25px] text-red-500 ml-1"
                                    onClick={() => {
                                        setActionMode('many');
                                    }}
                                >
                                    Bỏ chọn.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="block w-full overflow-auto xl:h-[440px] 2xl:h-[600px]">
                    {/* Projects table */}

                    {guests.length != 0 && (
                        <table className="min-w-full border-separate border-spacing-0">
                            <thead className="sticky top-[0px] z-[99] ">
                                <tr className="relative">
                                    {guests.length != 0 && (
                                        <th
                                            title={`Đã chọn ${guestSelect.length} trong số ${guestQuery.totalDocs} khách mời.`}
                                            className="w-[50px] sticky left-[0px]  text-xs text-black  align-middle bg-gray-100 border-t-[1px] border-l-[1px] border-gray-300 border-solid  whitespace-nowrap"
                                        >
                                            <label
                                                for="all"
                                                className="flex items-center px-2 py-3 cursor-pointer justify-center"
                                            >
                                                {guestQuery.verify_status != 'USER_ACCEPT' && (
                                                    <input
                                                        title="Chọn tất cả khách mời"
                                                        type="checkbox"
                                                        id="all"
                                                        className={'cursor-pointer rounded'}
                                                        checked={checkedAllSelected(guests)}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                selectAll(guests);
                                                                setActionMode('many');
                                                            } else {
                                                                removeSelectAll(guests);
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </label>
                                        </th>
                                    )}
                                    <th className="px-2 py-3 sticky left-[41px]  text-xs  text-center font-thin text-black  align-middle bg-gray-100 border-t-[1px] border-l-[1px] border-gray-300 border-solid  whitespace-nowrap">
                                        STT
                                    </th>
                                    <th className="px-2 py-3 text-xs sticky left-[80px] max-w-[100px] text-center font-thin text-black  align-middle bg-gray-100 border-t-[1px] border-l-[1px] border-gray-300 border-solid  whitespace-nowrap">
                                        Danh xưng
                                    </th>
                                    <th className="px-2 py-3 text-xs sticky left-[156px]  text-center font-thin text-black  align-middle bg-gray-100 border-t-[1px] border-l-[1px] border-r-[1px] border-gray-300 border-solid  whitespace-nowrap">
                                        Tên khách
                                    </th>

                                    <th className="px-2 py-3 text-xs  text-center font-thin text-black  align-middle bg-gray-100 border-t-[1px] border-gray-300 border-solid  whitespace-nowrap">
                                        Email
                                    </th>
                                    <th className=" min-w-[100px] border-solid bg-gray-100 border-t-[1px] border-l-[1px] border-gray-300 px-2 py-3 text-center font-thin align-middle text-xs   text-black ">
                                        SĐT
                                    </th>
                                    <th className="px-2 py-3 text-xs  text-center font-thin text-black max-w-[100px]  align-middle bg-gray-100 border-t-[1px] border-l-[1px] border-gray-300 border-solid  whitespace-nowrap">
                                        Trạng thái
                                    </th>
                                    <th className="px-2 py-3 text-xs  text-center font-thin text-black max-w-[150px]  align-middle bg-gray-100 border-t-[1px] border-l-[1px] border-gray-300 border-solid  whitespace-nowrap">
                                        Nhóm
                                    </th>
                                    <th className="px-2 py-3 text-xs  text-center font-thin text-black max-w-[240px]  align-middle bg-gray-100 border-t-[1px] border-l-[1px] border-gray-300 border-solid  whitespace-nowrap">
                                        Hình thức đăng ký
                                    </th>
                                    <th className="px-2 py-3 text-xs  text-center font-thin text-black  align-middle bg-gray-100 border-t-[1px] border-l-[1px] border-r-[1px] border-gray-300 border-solid  whitespace-nowrap"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {guests.map((guest, i) => (
                                    <>
                                        {!guestQuery.group &&
                                            (guests[i - 1] || guest?.group[0]) &&
                                            guest?.group[0]?._id !=
                                                guests[i - 1]?.group[0]?._id && (
                                                <tr
                                                    key={i + 'c'}
                                                    className={`border-collapse  ${
                                                        expandRow.includes(guest._id)
                                                            ? ''
                                                            : 'border-b-[1px]'
                                                    }`}
                                                >
                                                    <th
                                                        colSpan={7}
                                                        className={
                                                            'sticky border-t-[1px] left-0 border-l-[1px] border-gray-300 items-center whitespace-nowrap border-solid bg-gray-100 p-4 pl-6 text-center font-thin align-middle text-xs text-black'
                                                        }
                                                    >
                                                        <div className="flex items-center justify-start text-[16px] ">
                                                            <FaUsers className="mr-2 text-[16px]" />{' '}
                                                            <p>{guest.group[0]?.fullname}</p>
                                                        </div>
                                                    </th>
                                                    <th
                                                        colSpan={3}
                                                        className={
                                                            'border-t-[1px] relative border-r-[1px] border-gray-300 items-center whitespace-nowrap border-solid bg-gray-100 p-4 pl-6 text-center font-thin align-middle text-xs text-black'
                                                        }
                                                    ></th>
                                                </tr>
                                            )}
                                        {!guestQuery.group &&
                                            guests[i].createdMethod == 'GUEST' &&
                                            guests[i].createdMethod !=
                                                guests[i - 1]?.createdMethod &&
                                            !guest?.group[0] &&
                                            guests[i].createdMethod == 'GUEST' && (
                                                <tr
                                                    key={i + 'a'}
                                                    className={`border-collapse  ${
                                                        expandRow.includes(guest._id)
                                                            ? ''
                                                            : 'border-b-[1px]'
                                                    }`}
                                                >
                                                    <th
                                                        colSpan={7}
                                                        className={
                                                            'sticky border-t-[1px] left-0 border-l-[1px] border-gray-300 items-center whitespace-nowrap border-solid bg-gray-100 p-4 pl-6 text-center font-thin align-middle text-xs text-black'
                                                        }
                                                    >
                                                        <div className="flex items-center justify-start text-[16px] ">
                                                            <FaUserEdit className="mr-2 text-[16px]" />{' '}
                                                            <p>Khách tự đăng ký</p>
                                                        </div>
                                                    </th>
                                                    <th
                                                        colSpan={3}
                                                        className={
                                                            'border-t-[1px] relative border-r-[1px] border-gray-300 items-center whitespace-nowrap border-solid bg-gray-100 p-4 pl-6 text-center font-thin align-middle text-xs text-black'
                                                        }
                                                    ></th>
                                                </tr>
                                            )}
                                        {!guestQuery.group &&
                                            !guests[i - 1] &&
                                            !guest?.group[0] &&
                                            guests[i].createdMethod == 'USER' && (
                                                <tr key={i + 'b'} className={`border-collapse `}>
                                                    <th
                                                        colSpan={7}
                                                        className={
                                                            'sticky border-t-[1px] left-0 border-l-[1px] border-gray-300 items-center whitespace-nowrap border-solid bg-gray-100 p-4 pl-6 text-center font-thin align-middle text-xs text-black'
                                                        }
                                                    >
                                                        <div className="flex items-center justify-start text-[16px] ">
                                                            <FaUserPlus className="mr-2 text-[16px]" />{' '}
                                                            <p>Chủ sự kiện thêm</p>
                                                        </div>
                                                    </th>
                                                    <th
                                                        colSpan={3}
                                                        className={
                                                            'border-t-[1px] relative border-r-[1px] border-gray-300 items-center whitespace-nowrap border-solid bg-gray-100 p-4 pl-6 text-center font-thin align-middle text-xs text-black'
                                                        }
                                                    ></th>
                                                </tr>
                                            )}

                                        <tr
                                            key={i}
                                            className={`row cursor-pointer hover:bg-gray-100 group `}
                                        >
                                            <th
                                                onClick={selectGuest}
                                                for={guest._id}
                                                className={
                                                    'border-t-[1px] border-b-[0.5px] sticky bg-white left-[0px] group-hover:bg-gray-100 border-l-[1px]  items-center whitespace-nowrap border-y-0 border-solid text-center font-thin align-middle text-xs text-black'
                                                }
                                            >
                                                <label
                                                    for={guest._id}
                                                    className="flex items-center justify-center w-[40px] min-h-[40px] cursor-pointer mx-auto"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        id={guest._id}
                                                        className={'cursor-pointer rounded '}
                                                        value={guest._id}
                                                        checked={
                                                            !!guestSelect.find(
                                                                (guestItem) =>
                                                                    guestItem._id == guest._id
                                                            )
                                                        }
                                                        onChange={selectGuest}
                                                    />
                                                </label>
                                            </th>
                                            <th
                                                onClick={() => {
                                                    onReadGuest(guest._id);
                                                }}
                                                className="border-t-[1px] border-b-[0.5px] sticky bg-white left-[41px] group-hover:bg-gray-100 border-l-[1px] p-4 px-2 text-xs text-center font-thin text-black align-middle border-solid  whitespace-nowrap border-y-0"
                                            >
                                                {i +
                                                    1 +
                                                    (guestQuery.pageNow - 1) * guestQuery.limit}
                                            </th>
                                            <th
                                                onClick={() => {
                                                    onReadGuest(guest._id);
                                                }}
                                                className="border-t-[1px] border-b-[0.5px] sticky bg-white left-[80px] border-l-[1px] group-hover:bg-gray-100 p-4 px-2 max-w-[100px] text-xs text-center font-thin text-black align-middle border-solid border-y-0"
                                            >
                                                {guest.title}
                                            </th>
                                            <th
                                                onClick={() => {
                                                    onReadGuest(guest._id);
                                                }}
                                                className="border-t-[1px] border-b-[0.5px] sticky left-[156px] border-x-[1px] min-w-[110px] group-hover:bg-gray-100 bg-white whitespace-nowrap break-words border-y-0 border-solid p-4 px-2 text-center font-thin align-middle text-xs text-black "
                                            >
                                                {guest.fullname} {guest.subname}
                                            </th>

                                            <th
                                                onClick={() => {
                                                    onReadGuest(guest._id);
                                                }}
                                                className="border-t-[1px] border-b-[0.5px]  min-w-[100px] whitespace-nowrap break-words border-y-0 border-solid p-4 px-2 text-center font-thin align-middle text-xs text-black "
                                            >
                                                {guest.email}
                                            </th>
                                            <th
                                                className="border-t-[1px] border-b-[0.5px] border-l-[1px] min-w-[100px] whitespace-nowrap break-words border-y-0 border-solid p-4 px-2 text-center font-thin align-middle text-xs text-black "
                                                onClick={() => {
                                                    onReadGuest(guest._id);
                                                }}
                                            >
                                                {guest.phone_number}
                                            </th>
                                            <th
                                                onClick={() => {
                                                    onReadGuest(guest._id);
                                                }}
                                                className="border-t-[1px] border-b-[0.5px] border-l-[1px] relative p-4 px-2 min-w-[40px] text-xs text-center font-thin text-black align-middle border-solid border-y-0"
                                            >
                                                {!guestQuery.verify_status && (
                                                    <>
                                                        <div className="relative group/status">
                                                            {guest.status?.find(
                                                                ({ active }) => active === true
                                                            )?.status === 'UNINVITED' && (
                                                                <FaEnvelope
                                                                    data-mdb-ripple="true"
                                                                    data-mdb-ripple-color="light"
                                                                    className="mx-auto text-xl text-amber-500"
                                                                />
                                                            )}
                                                            {guest.status?.find(
                                                                ({ active }) => active === true
                                                            )?.status === 'SENDING' && (
                                                                <>
                                                                    <FaHourglass
                                                                        data-mdb-ripple="true"
                                                                        data-mdb-ripple-color="light"
                                                                        className={`mx-auto text-xl animate-loaderAnim
                                                                            ${
                                                                                guest.status?.find(
                                                                                    ({ active }) =>
                                                                                        active ===
                                                                                        true
                                                                                )?.timer
                                                                                    ? 'text-amber-400 '
                                                                                    : ' text-sky-500'
                                                                            }
                                                                            `}
                                                                    />
                                                                    {guest.status?.find(
                                                                        ({ active }) =>
                                                                            active === true
                                                                    )?.timer && (
                                                                        <>
                                                                            {/* <span className='text-sm font-normal'> Hẹn giờ </span> */}
                                                                            <span className="text-xs font-thin mt-0.5">
                                                                                <br />(
                                                                                {formatDatetime(
                                                                                    guest.status?.find(
                                                                                        ({
                                                                                            active,
                                                                                        }) =>
                                                                                            active ===
                                                                                            true
                                                                                    )?.timer
                                                                                )}
                                                                                )
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                            {guest.status?.find(
                                                                ({ active }) => active === true
                                                            )?.status === 'INVITED' && (
                                                                <FaEnvelopeOpenText
                                                                    data-mdb-ripple="true"
                                                                    data-mdb-ripple-color="light"
                                                                    className="mx-auto text-xl text-blue-500 "
                                                                />
                                                            )}
                                                            {guest.status?.find(
                                                                ({ active }) => active === true
                                                            )?.status === 'ACCEPT' && (
                                                                <FaUserCheck
                                                                    data-mdb-ripple="true"
                                                                    data-mdb-ripple-color="light"
                                                                    className="mx-auto text-xl text-green-500 "
                                                                />
                                                            )}
                                                            {guest.status?.find(
                                                                ({ active }) => active === true
                                                            )?.status === 'DECLINE' && (
                                                                <FaUserTimes
                                                                    data-mdb-ripple="true"
                                                                    data-mdb-ripple-color="light"
                                                                    className="mx-auto text-xl text-red-500 "
                                                                />
                                                            )}
                                                            {guest.status?.find(
                                                                ({ active }) => active === true
                                                            )?.status === 'VERIFYING' && (
                                                                <FaUserClock
                                                                    data-mdb-ripple="true"
                                                                    data-mdb-ripple-color="light"
                                                                    className="mx-auto text-xl text-violet-500 "
                                                                />
                                                            )}
                                                            {guest.status?.find(
                                                                ({ active }) => active === true
                                                            )?.status === 'REJECT' && (
                                                                <FaUserMinus
                                                                    data-mdb-ripple="true"
                                                                    data-mdb-ripple-color="light"
                                                                    className="mx-auto text-xl text-red-500 "
                                                                />
                                                            )}

                                                            <div className="absolute -top-3 left-[50%] z-[999] hidden -translate-y-full w-[120px] translate-x-[-50%] rounded-md bg-gray-700  px-2 py-1 text-center font-thin text-sm text-white after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-gray-700 after:content-[''] group-hover/status:flex justify-center items-center">
                                                                {statusConvert(
                                                                    guest.status?.find(
                                                                        ({ active }) =>
                                                                            active === true
                                                                    )?.status,
                                                                    guest.status?.find(
                                                                        ({ active }) =>
                                                                            active === true
                                                                    )?.timer
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {guestQuery.verify_status && (
                                                    <div className="relative group">
                                                        {guest.verify_status?.find(
                                                            ({ active }) => active === true
                                                        )?.status === 'VERIFYING' && (
                                                            <FaUserClock
                                                                data-mdb-ripple="true"
                                                                data-mdb-ripple-color="light"
                                                                className="mx-auto text-xl text-violet-500"
                                                            />
                                                        )}
                                                        {guest.verify_status?.find(
                                                            ({ active }) => active === true
                                                        )?.status === 'USER_ACCEPT' && (
                                                            <FaCheck
                                                                data-mdb-ripple="true"
                                                                data-mdb-ripple-color="light"
                                                                className="mx-auto text-xl text-green-500"
                                                            />
                                                        )}
                                                        {guest.verify_status?.find(
                                                            ({ active }) => active === true
                                                        )?.status === 'USER_DENY' && (
                                                            <FaTimes
                                                                data-mdb-ripple="true"
                                                                data-mdb-ripple-color="light"
                                                                className="mx-auto text-xl text-red-500"
                                                            />
                                                        )}

                                                        <span className="absolute justify-center items-center -top-3 z-[999] left-[50%] hidden -translate-y-full translate-x-[-50%]  w-[120px] rounded-md bg-gray-700  px-2 py-1 text-center font-thin text-sm text-white after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-gray-700 after:content-[''] group-hover:flex">
                                                            {statusConvert(
                                                                guest.verify_status?.find(
                                                                    ({ active }) => active === true
                                                                )?.status
                                                            )}
                                                        </span>
                                                    </div>
                                                )}
                                            </th>
                                            <th
                                                onClick={() => {
                                                    onReadGuest(guest._id);
                                                }}
                                                className="border-t-[1px] border-b-[0.5px] border-l-[1px] relative p-4 px-2 max-w-[150px] text-xs text-center font-thin whitespace-nowrap text-black align-middle border-solid  border-y-0"
                                            >
                                                {' '}
                                                {guest.group[0]?.fullname || ' '}
                                            </th>
                                            <th
                                                onClick={() => {
                                                    onReadGuest(guest._id);
                                                }}
                                                className="border-t-[1px] border-b-[0.5px] border-x-[1px] relative p-4 px-2  text-xs text-center font-thin text-black align-middle border-solid border-y-0 max-w-[240px]"
                                            >
                                                {guest.createdMethod === 'GUEST' && (
                                                    <div className="px-2 py-1 bg-yellow-500 text-white rounded-full text-xs w-auto max-w-[200px] whitespace-nowrap">
                                                        Tự đăng ký
                                                    </div>
                                                )}
                                                {guest.createdMethod === 'GUEST_INVITE' && (
                                                    <div className="px-2 py-1 bg-violet-500 text-white rounded-full text-xs w-auto max-w-[200px] whitespace-nowrap">
                                                        Tạo bởi{' '}
                                                        {guest?.guest_invite?.fullname
                                                            ? guest?.guest_invite?.fullname
                                                            : 'khách khác'}
                                                    </div>
                                                )}
                                                {(guest.createdMethod === 'USER' ||
                                                    !guest.createdMethod) && (
                                                    <div className="px-2 py-1 bg-blue-500 text-white rounded-full text-xs w-auto max-w-[200px] whitespace-nowrap">
                                                        Tạo bởi chủ sự kiện
                                                    </div>
                                                )}
                                                {guest.createdMethod === 'GROUP' && (
                                                    <div className="px-2 py-1 bg-green-500 text-white rounded-full text-xs w-auto max-w-[200px] whitespace-nowrap">
                                                        Tạo bởi nhóm
                                                    </div>
                                                )}
                                            </th>
                                            <th className="border-t-[1px] border-b-[0.5px] border-x-[1px] right-[0px] p-4 px-2 text-xs text-center font-thin text-black align-middle border-solid whitespace-nowrap z-[0]">
                                                <div className="mx-auto flex justify-center">
                                                    {!guestQuery.verify_status && (
                                                        <>
                                                            <button
                                                                className=" dropdown dropdown-toggle relative flex items-center whitespace-nowrap rounded bg-blue-600 px-3 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-lg transition duration-150 ease-in-out hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:text-white active:shadow-lg "
                                                                type="button"
                                                                id="dropdownMenuButton3"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                // onClick={(e) => {
                                                                //     e.stopPropagation()
                                                                // }}
                                                            >
                                                                <svg
                                                                    aria-hidden="true"
                                                                    focusable="false"
                                                                    data-prefix="fas"
                                                                    data-icon="caret-down"
                                                                    className="w-2"
                                                                    role="img"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 320 512"
                                                                >
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                                                                    ></path>
                                                                </svg>
                                                            </button>
                                                            <ul
                                                                className="dropdown-menu absolute z-[9999999] float-left m-0 mt-1 hidden min-w-max list-none rounded border-none bg-white bg-clip-padding py-2 text-center font-thin text-base shadow-lg"
                                                                aria-labelledby="dropdownMenuButton3"
                                                            >
                                                                {eventTemplate?.is_public && (
                                                                    <>
                                                                        <li>
                                                                            <span
                                                                                className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-white cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                                                itemID={guest._id}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    onCopyInvite(
                                                                                        guest._id
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <FaRegClone className="mr-1" />
                                                                                Sao chép lời mời
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            <span
                                                                                className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-white cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                                                itemID={guest._id}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    onCopyLink(
                                                                                        guest._id
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <FaLink className="mr-1" />
                                                                                Sao chép đường dẫn
                                                                                thiệp
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            <span
                                                                                className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-white cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                                                itemID={guest._id}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    onCopyLink(
                                                                                        guest._id,
                                                                                        true
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <FaExternalLinkAlt className="mr-1" />
                                                                                Sao chép đường dẫn
                                                                                phản hồi
                                                                            </span>
                                                                        </li>
                                                                        {!eventTemplate?.isExpired && (
                                                                            <>
                                                                                <li>
                                                                                    <span
                                                                                        className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-white cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                                                        itemID={
                                                                                            guest._id
                                                                                        }
                                                                                        data-bs-toggle={
                                                                                            guest.email !=
                                                                                                '' &&
                                                                                            'modal'
                                                                                        }
                                                                                        data-bs-target={
                                                                                            eventTemplate?.email_host !=
                                                                                                '' &&
                                                                                            eventTemplate?.email_host
                                                                                                ? '#confirmSendOnlyGuest'
                                                                                                : '#confirmAddEmail4Only'
                                                                                        }
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            if (
                                                                                                guest.email !=
                                                                                                    '' ||
                                                                                                guest.email
                                                                                            ) {
                                                                                                setSelectOne(
                                                                                                    guest._id
                                                                                                );
                                                                                            } else {
                                                                                                ToastNotify.Error(
                                                                                                    `${guest.title} ${guest.fullname} chưa có email!`
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <FaEnvelope className="mr-1" />
                                                                                        Gửi lời mời
                                                                                        qua email
                                                                                    </span>
                                                                                </li>
                                                                                {guest.status?.find(
                                                                                    ({ active }) =>
                                                                                        active ===
                                                                                        true
                                                                                )?.timer ? (
                                                                                    <li>
                                                                                        <span
                                                                                            className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-white cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                                                            itemID={
                                                                                                guest._id
                                                                                            }
                                                                                            data-bs-toggle={
                                                                                                guest.email !=
                                                                                                    '' &&
                                                                                                'modal'
                                                                                            }
                                                                                            data-bs-target="#setTimerSendMailModal"
                                                                                            onClick={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    guest.email !=
                                                                                                        '' ||
                                                                                                    guest.email
                                                                                                ) {
                                                                                                    setSelectOne(
                                                                                                        guest._id
                                                                                                    );
                                                                                                    setIsChangeTimer(
                                                                                                        {
                                                                                                            value: true,
                                                                                                        }
                                                                                                    );
                                                                                                } else {
                                                                                                    ToastNotify.Error(
                                                                                                        `${guest.title} ${guest.fullname} chưa có email!`
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            {/* <FaRegClock className="mr-1" /> */}
                                                                                            <img
                                                                                                src={
                                                                                                    IMAGES.TIME_CHANGE
                                                                                                }
                                                                                                className="w-[14px] mr-1"
                                                                                            />
                                                                                            Thay đổi
                                                                                            hẹn giờ
                                                                                            gửi mail
                                                                                        </span>
                                                                                    </li>
                                                                                ) : (
                                                                                    <li>
                                                                                        <span
                                                                                            className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-white cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                                                            itemID={
                                                                                                guest._id
                                                                                            }
                                                                                            data-bs-toggle={
                                                                                                guest.email !=
                                                                                                    '' &&
                                                                                                'modal'
                                                                                            }
                                                                                            data-bs-target="#setTimerSendMailModal"
                                                                                            onClick={(
                                                                                                e
                                                                                            ) => {
                                                                                                if (
                                                                                                    guest.email !=
                                                                                                        '' ||
                                                                                                    guest.email
                                                                                                ) {
                                                                                                    setSelectOne(
                                                                                                        guest._id
                                                                                                    );
                                                                                                } else {
                                                                                                    ToastNotify.Error(
                                                                                                        `${guest.title} ${guest.fullname} chưa có email!`
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            {/* <FaEnvelope className="mr-1" /> */}
                                                                                            <img
                                                                                                src={
                                                                                                    IMAGES.MAIL_CLOCK
                                                                                                }
                                                                                                className="w-[17px] mr-1"
                                                                                            />
                                                                                            Hẹn giờ
                                                                                            gửi
                                                                                            email
                                                                                        </span>
                                                                                    </li>
                                                                                )}
                                                                            </>
                                                                        )}

                                                                        <li>
                                                                            <span
                                                                                className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-white cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                                                itemID={guest._id}
                                                                                data-bs-toggle={
                                                                                    'modal'
                                                                                }
                                                                                data-bs-target={
                                                                                    '#groupListModal'
                                                                                }
                                                                                onClick={(e) => {
                                                                                    setModalOpen({
                                                                                        groupListModal: true,
                                                                                    });
                                                                                    setSelectOne(
                                                                                        guest._id
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <FaUsers className="mr-1" />
                                                                                Nhóm
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            <span
                                                                                className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-white cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                                                itemID={guest._id}
                                                                                onClick={() => {
                                                                                    resetAndAddGuestQuery(
                                                                                        {
                                                                                            guest_invite:
                                                                                                guest._id,
                                                                                            invited_by:
                                                                                                guest.fullname,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <FaTable className="mr-1" />
                                                                                Danh sách khách được
                                                                                mời
                                                                            </span>
                                                                        </li>
                                                                    </>
                                                                )}
                                                                <li>
                                                                    <span
                                                                        className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-white cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                                        itemID={guest._id}
                                                                        onClick={(e) => {
                                                                            onReadGuest(guest._id);
                                                                        }}
                                                                    >
                                                                        <FaInfo className="mr-1" />
                                                                        Chi tiết
                                                                    </span>
                                                                </li>
                                                                {!eventTemplate?.isExpired && (
                                                                    <>
                                                                        <li>
                                                                            <span
                                                                                className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-white cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                                                itemID={guest._id}
                                                                                onClick={(e) => {
                                                                                    onUpdateGuest(
                                                                                        guest._id
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <FaEdit className="mr-1" />
                                                                                Sửa
                                                                            </span>
                                                                        </li>

                                                                        {/* {guestQuery.group && (
                                                                            <li>
                                                                                <span
                                                                                    className="flex items-center w-full px-4 py-2 text-sm font-normal text-red-600 bg-white cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                                                    itemID={guest._id}
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#confirmRemoveFromGroup"
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation()
                                                                                        setSelectOne(
                                                                                            guest._id
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <FaTimes className="mr-1" />
                                                                                    Xóa khách mời khỏi nhóm
                                                                                </span>
                                                                            </li>
                                                                        )} */}
                                                                        <li>
                                                                            <span
                                                                                className="flex items-center w-full px-4 py-2 text-sm font-normal text-red-600 bg-white cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                                                itemID={guest._id}
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#confirmDeleteOne"
                                                                                onClick={(e) => {
                                                                                    setSelectOne(
                                                                                        guest._id
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <FaTrash className="mr-1" />
                                                                                Xóa
                                                                            </span>
                                                                        </li>
                                                                    </>
                                                                )}
                                                            </ul>
                                                        </>
                                                    )}
                                                    {guestQuery.verify_status && (
                                                        <>
                                                            <button
                                                                className=" dropdown dropdown-toggle relative flex items-center whitespace-nowrap rounded bg-blue-600 px-3 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-lg transition duration-150 ease-in-out hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:text-white active:shadow-lg "
                                                                type="button"
                                                                id="dropdownMenuButton4"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                <svg
                                                                    aria-hidden="true"
                                                                    focusable="false"
                                                                    data-prefix="fas"
                                                                    data-icon="caret-down"
                                                                    className="w-2"
                                                                    role="img"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 320 512"
                                                                >
                                                                    <path
                                                                        fill="currentColor"
                                                                        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                                                                    ></path>
                                                                </svg>
                                                            </button>
                                                            <ul
                                                                className="dropdown-menu absolute z-[999] float-left m-0 mt-1 hidden min-w-max list-none rounded border-none bg-white bg-clip-padding py-2 text-left text-base shadow-lg"
                                                                aria-labelledby="dropdownMenuButton4"
                                                            >
                                                                <li>
                                                                    <span
                                                                        className="flex items-center w-full px-4 py-2 text-sm font-normal text-gray-700 bg-white cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                                        itemID={guest._id}
                                                                        onClick={(e) => {
                                                                            onReadGuest(guest._id);
                                                                        }}
                                                                    >
                                                                        <FaInfo className="mr-1" />
                                                                        Chi tiết
                                                                    </span>
                                                                </li>
                                                                {guestQuery.verify_status ==
                                                                    'VERIFYING' && (
                                                                    <>
                                                                        <li>
                                                                            <span
                                                                                className="flex items-center w-full px-4 py-2 text-sm font-normal text-green-600 bg-white cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                                                itemID={guest._id}
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#confirmAcceptRequest"
                                                                                onClick={(e) => {
                                                                                    setActionMode(
                                                                                        ''
                                                                                    );
                                                                                    setSelectOne(
                                                                                        guest
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <FaCheck className="mr-1" />
                                                                                Chấp nhận yêu cầu
                                                                                tham dự
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            <span
                                                                                className="flex items-center w-full px-4 py-2 text-sm font-normal text-red-600 bg-white cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                                                                itemID={guest._id}
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#confirmDenyRequest"
                                                                                onClick={(e) => {
                                                                                    setActionMode(
                                                                                        ''
                                                                                    );
                                                                                    setSelectOne(
                                                                                        guest
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <FaTimes className="mr-1" />
                                                                                Từ chối yêu cầu tham
                                                                                dự
                                                                            </span>
                                                                        </li>
                                                                    </>
                                                                )}
                                                            </ul>
                                                        </>
                                                    )}
                                                </div>
                                            </th>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </table>
                    )}
                    {guests.length == 0 &&
                        (guestQuery.search?.trim() === '' && countFilter() === 0 ? (
                            <div className="flex w-full flex-col items-center justify-center mt-10">
                                <img
                                    src={image.GUEST_MANAGER}
                                    alt="not found"
                                    className="mx-auto w-1/3 opacity-70"
                                />
                                <h4 className="mt-5 text-center text-xl text-blue-900 ">
                                    {guestQuery.verify_status
                                        ? 'Không có khách nào đang chờ xác nhận'
                                        : 'Khách mời rất quan trọng cho sự kiện của bạn'}
                                </h4>
                                <p className="mt-2 text-center text-sm text-black">
                                    {guestQuery.verify_status
                                        ? ''
                                        : 'Vui lòng nhập thông tin khách mời tại đây để bạn có thể gửi lời mời trân trọng nhất đến từng người nhận.'}
                                </p>
                            </div>
                        ) : (
                            <Blank handleClear={handleClearSearch} />
                        ))}
                </div>
                {guests.length != 0 && (
                    <div className="flex items-center justify-start w-full py-2 px-4 bg-white border-[1px] border-t-[1px] gap-[4px]">
                        {guestQuery.pageNow != 1 && (
                            <button
                                onClick={() => {
                                    setGuestQuery({ ...guestQuery, pageNow: 1 });
                                }}
                                className="p-1 rounded-full border-[1px] border-blue-500 text-blue-700"
                            >
                                <FaAngleDoubleLeft
                                    title="Chuyển về trang đầu"
                                    className="cursor-pointer text-[14px] font-medium"
                                />
                            </button>
                        )}
                        {guestQuery.hasPrevPage && (
                            <button
                                onClick={() => {
                                    setGuestQuery({
                                        ...guestQuery,
                                        pageNow: (guestQuery.pageNow -= 1),
                                    });
                                }}
                                className="p-1 rounded-full border-[1px] border-blue-500 text-blue-700"
                            >
                                <FaAngleLeft
                                    title="Chuyển về trang trước"
                                    className="cursor-pointer text-[14px]"
                                />
                            </button>
                        )}
                        <p className="text-[14px] text-black">
                            Trang: {guestQuery.pageNow}/{guestQuery.totalPages}
                        </p>
                        {guestQuery.hasNextPage && (
                            <button
                                onClick={() => {
                                    setGuestQuery({
                                        ...guestQuery,
                                        pageNow: (guestQuery.pageNow += 1),
                                    });
                                }}
                                className="p-1 rounded-full border-[1px] border-blue-500 text-blue-700"
                            >
                                <FaAngleRight
                                    title="Chuyển sang trang tiếp"
                                    className="cursor-pointer text-[14px]"
                                />
                            </button>
                        )}
                        {guestQuery.pageNow != guestQuery.totalPages && (
                            <button
                                onClick={() => {
                                    setGuestQuery({
                                        ...guestQuery,
                                        pageNow: guestQuery.totalPages,
                                    });
                                }}
                                className="p-1 rounded-full border-[1px] border-blue-500 text-blue-700"
                            >
                                <FaAngleDoubleRight
                                    title="Chuyển đến trang cuối"
                                    className="cursor-pointer text-[14px]"
                                />
                            </button>
                        )}

                        <div className="flex items-center gap-[4px] ml-[12px]">
                            <p className="text-sm text-black">Hiển thị</p>
                            <div class="relative">
                                <select
                                    onChange={handleChangeLimit}
                                    class="w-full cursor-default rounded-md bg-white py-[2px] pl-2 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                    tabindex="-1"
                                    role="listbox"
                                    aria-labelledby="listbox-label"
                                    aria-activedescendant="listbox-option-3"
                                    value={guestQuery?.limit}
                                >
                                    <option
                                        selected
                                        class="text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9 text-sm"
                                        id="listbox-option-0"
                                        role="option"
                                        value={10}
                                    >
                                        <span class="font-normal ml-3 block truncate">10</span>
                                    </option>
                                    <option
                                        class="text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9 text-sm"
                                        id="listbox-option-0"
                                        role="option"
                                        value={20}
                                    >
                                        <span class="font-normal ml-3 block truncate">20</span>
                                    </option>
                                    <option
                                        class="text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9 text-sm"
                                        id="listbox-option-0"
                                        role="option"
                                        value={50}
                                    >
                                        <span class="font-normal ml-3 block truncate">50</span>
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
