import SubEvent4 from '../event-4/SubEvent4.page';

export default function SubEvent5({
    data,
    guestTitle,
    guestFullname,
    salutation,
    refFrame,
    urlShare,
    language = 'Japanese',
}) {
    return (
        <SubEvent4
            data={data}
            guestTitle={guestTitle}
            guestFullname={guestFullname}
            salutation={salutation}
            refFrame={refFrame}
            urlShare={urlShare}
            language={language}
        />
    );
}
