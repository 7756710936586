import PAGE from '../config/path.route';
import { Suspense } from 'react';
import TemplateView from '../views/template.view/TemplateView.page';

const MappingRoute = [
    {
        path: PAGE.templateView,
        element: (
            <Suspense fallback={<></>}>
                <TemplateView />
            </Suspense>
        ),
        layout: 'no layout',
    },
];

export default MappingRoute;
