/* eslint-disable */
import * as Yup from 'yup';

import React, { useState } from 'react';

import { FaTimes } from 'react-icons/fa';
import FormSignIn from './components/FormSignIn';
import FormSignUp from './components/FormSignUp';
import SloganLanding from './components/SloganLanding';
import ToastNotify from '../../utils/Toast';
import authActions from '../../redux/actions/auth.action';
import authService from '../../services/auth.service';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import FormForgotPassword from './components/FormForgotPassword';
import userService from '../user/User.service';
import FormConfirm from './components/FormConfirm';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import PAGE from '../../config/path.route';
import envConstants from '../../config/key';
import Regex from '../../utils/Regex';

const loadScriptByURL = (id, url, callback) => {
    const isScriptExist = document.getElementById(id);
    if (!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function () {
            if (callback) callback();
        };
        document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
};

const emailRegex = /^[a-z0-9][a-z0-9\._-]{1,90}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/;
// const usernameRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,20}$/;
const usernameRegex = /^[a-zA-Z][a-zA-Z0-9]{5,19}$/;
const phoneRegex = /([\+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/;

// LOGIN
const INIT_FORM_LOGIN = {
    email: '',
    password: '',
};
const SCHEMA_LOGIN = Yup.object({
    // email: Yup.string().trim().required('Email không được để trống!').matches(emailRegex, 'Email không hợp lệ!'),
    email: Yup.string()
        .trim()
        .required('Email / Tên đăng nhập không được để trống!')
        .test(
            'email-or-username',
            <div className="text-red-500 text-[13px]">
                {' '}
                <a>
                    Email: sử dụng các chữ cái (a-z), số (0-9), dấu chấm (.), và dấu a còng (@). Tên
                    đăng nhập: bắt đầu bằng chữ cái và gồm 6-20 ký tự
                </a>{' '}
            </div>,
            (value) => emailRegex.test(value) || usernameRegex.test(value)
        ),
    password: Yup.string().trim().required('Mật khẩu không được để trống!'),
});

// SIGNUP
const INIT_FORM_SIGNUP = {
    username: '',
    fullname: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
};
const SCHEMA_SIGNUP = Yup.object({
    username: Yup.string()
        .required('Tên đăng nhập không được để trống!')
        .matches(usernameRegex, 'Tên đăng nhập: bắt đầu bằng chữ cái và gồm 6-20 ký tự')
        .max(20, 'Tên đăng nhập không dài quá 20 ký tự!'),
    fullname: Yup.string()
        // .required('Họ và tên không được để trống!')
        .max(30, 'Họ và tên không dài quá 30 ký tự!'),
    phone: Yup.string()
        .matches(phoneRegex, 'Số điện thoại không đúng!')
        .max(12, 'Số điện thoại không vượt quá 12 chữ số!'),
    email: Yup.string()
        .trim()
        .required('Email không được để trống!')
        .matches(
            emailRegex,
            'Email: sử dụng các chữ cái (a-z), số (0-9), dấu chấm (.), và dấu a còng (@).'
        ),
    password: Yup.string()
        .trim()
        .required('Mật khẩu không được để trống!')
        // .matches(
        //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,20}$/,
        //     'Mật khẩu gồm 6-20 ký tự, có chứa chữ thường, chữ in hoa, và ký tự số'
        // ),
        .matches(
            Regex.password,
            'Mật khẩu gồm ít nhất 6 ký tự'
        ),
    confirmPassword: Yup.string()
        .trim()
        .required('Mật khẩu xác nhận không được để trống!')
        .oneOf([Yup.ref('password'), null], 'Nhập lại mật khẩu không chính xác!'),
});

const INIT_FORM_FORGOTPASSWORD = {
    email: '',
};
const SCHEMA_FORGOTPASSWORD = Yup.object({
    email: Yup.string()
        .trim()
        .required('Email không được để trống!')
        .matches(emailRegex, 'Email không hợp lệ!'),
});

function ModalLogin({ isOpen, funToggle, activeLoginTab, defaultMode, screenWidth }) {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const [isTabLogin, setIsTabLogin] = useState(true)
    const [mode, setMode] = useState(defaultMode || 'login');
    const [user, setUser] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const formEl = document.querySelector(`.form-container-${defaultMode} .form-${mode}`);
        const resizeObserver = new ResizeObserver((el) => {
            if (screenWidth > 768) {
                const height = el?.[0]?.target?.offsetHeight;
                const sloganLandingEl = document.querySelector(
                    `.form-container-${defaultMode} .slogan-landing`
                );
                if (sloganLandingEl && height) {
                    sloganLandingEl.style.height = `${height}px`;
                }
            } else {
                const width = el?.[0]?.target?.offsetWidth;
                const sloganLandingEl = document.querySelector(
                    `.form-container-${defaultMode} .slogan-landing`
                );
                if (sloganLandingEl && width) {
                    sloganLandingEl.style.height = 'unset';
                    sloganLandingEl.style.width = `${width}px`;
                }
            }
        });
        resizeObserver.observe(formEl);
        return () => resizeObserver.disconnect();
    }, [mode, screenWidth]);

    useEffect(() => {
        if (!isOpen) {
            setMode(defaultMode);
            formikLogin.isValid = false;
            formikLogin.setTouched({});
            formikLogin.resetForm();
            formikLogin.resetForm({
                values: INIT_FORM_LOGIN,
                errors: {},
                touched: {
                    email: true,
                    password: true,
                },
                isValidating: false,
                isSubmitting: false,
            });
            removeRecaptcha();
        } else {
            if (!auth.loggedIn) {
                // load the script by passing the URL
                loadScriptByURL(
                    'recaptcha-key',
                    `https://www.google.com/recaptcha/api.js?render=${envConstants.SITE_KEY_CAPTCHA}`,
                    () => {
                        // console.log('Script recaptcha loaded!');
                    }
                );
            }
        }
    }, [isOpen]);

    const formikLogin = useFormik({
        initialValues: INIT_FORM_LOGIN,
        validationSchema: SCHEMA_LOGIN,
        onSubmit: () => handleLogin(),
        validateOnBlur: false,
    });

    const formikSignUp = useFormik({
        validateOnBlur: false,
        initialValues: INIT_FORM_SIGNUP,
        validationSchema: SCHEMA_SIGNUP,
        onSubmit: () => handleSignup(),
    });
    const formikForgotPassword = useFormik({
        validateOnBlur: false,
        initialValues: INIT_FORM_FORGOTPASSWORD,
        validationSchema: SCHEMA_FORGOTPASSWORD,
        onSubmit: () => handleCheckEmail(),
    });

    const handleLogin = () => {
        setIsLoading(true);
        window.grecaptcha.ready(function () {
            window.grecaptcha
                .execute(envConstants.SITE_KEY_CAPTCHA, { action: 'submit' })
                .then(function (token) {
                    // Add your logic to submit to your backend server here.
                    dispatch(
                        authActions.login(
                            formikLogin.values.email,
                            formikLogin.values.password,
                            token
                        )
                    )
                        .then(async () => {
                            // window.location.reload();
                            toggleForm();
                            // removeRecaptcha();
                            notifyLogin();
                            if (
                                !window.location.pathname?.includes('mainmenu') &&
                                !window.location.pathname?.includes('goi-y') &&
                                !window.location.pathname?.includes('config')
                            ) {
                                navigate(PAGE.userEvents);
                            }
                        })
                        .catch((error) => {
                            switch (error.status) {
                                case 404:
                                    formikLogin.setFieldError(
                                        'email',
                                        'Email / Tên đăng nhập không tồn tại!'
                                    );
                                    break;
                                case 401:
                                    formikLogin.setFieldError(
                                        'password',
                                        error.message || 'Sai mật khẩu!'
                                    );
                                    break;
                                case 403:
                                    formikLogin.setFieldError('email', error.message);
                                    break;
                                case 400:
                                    formikLogin.setFieldError('password', error.message);
                                    ToastNotify.Error(error.message);
                                    break;
                                default:
                                    break;
                            }
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                });
            // .finally(() => {
            //     setIsLoading(false);
            // });
        });
    };

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: async (response) => {
            try {
                window.grecaptcha.ready(function () {
                    window.grecaptcha
                        .execute(envConstants.SITE_KEY_CAPTCHA, { action: 'submit' })
                        .then(function (token) {
                            dispatch(
                                authActions.authProviderLogin('google', {
                                    accessToken: response.access_token,
                                    tokenCaptcha: token,
                                })
                            )
                                .then(async () => {
                                    toggleForm();
                                    notifyLogin();
                                    // removeRecaptcha();
                                    if (
                                        !window.location.pathname?.includes('mainmenu') &&
                                        !window.location.pathname?.includes('goi-y') &&
                                        !window.location.pathname?.includes('config')
                                    ) {
                                        navigate(PAGE.userEvents);
                                    }
                                })
                                .catch((error) => {
                                    ToastNotify.Error(error?.message || 'Lỗi đăng nhập Google');
                                });
                        });
                });
            } catch (err) {
                console.log('google login error: ', err);
            }
        },
        onError: (error) => console.log('Google Login Failed:', error),
    });

    const handleFacebookLogin = async (response) => {
        await dispatch(
            authActions.authProviderLogin('facebook', {
                accessToken: response.accessToken,
            })
        );
        // window.location.reload();
        toggleForm();
        notifyLogin();
        if (
            !window.location.pathname?.includes('mainmenu') &&
            !window.location.pathname?.includes('goi-y') &&
            !window.location.pathname?.includes('config')
        ) {
            navigate(PAGE.userEvents);
        }
    };

    const handleSignup = () => {
        setIsLoading(true);
        authService
            .handleSignUp(
                formikSignUp.values.email,
                formikSignUp.values.username,
                formikSignUp.values.password,
                formikSignUp.values.fullname,
                formikSignUp.values.phone
            )
            .then((user) => {
                // TH user đã đăng nhập bằng gmail và đã xác thực
                if (user?.status === 'verified') {
                    notifySiginVerified();
                    changeMode();
                    formikLogin.setFieldValue(
                        'email',
                        JSON.parse(localStorage.getItem('user')).email,
                        false
                    );
                    setMode('login');
                } else {
                    notifySigin();
                    toggleForm();
                }
            })
            .catch((error) => {
                switch (error.statusCode) {
                    case 400:
                        formikSignUp.setFieldError(
                            error?.data?.field || 'email',
                            error?.errorMessage || 'Email đã tồn tại!'
                        );
                        break;
                    default:
                        break;
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSendEmailForgotPassword = (email) => {
        setIsLoading(true);
        userService
            .forgotPassword(email)
            .then(() => {
                ToastNotify.Success(
                    'Hệ thống đã gửi email cho bạn. Vui lòng kiểm tra email để đổi mật khẩu!'
                );
            })
            .catch((err) => {
                ToastNotify.Error(err.message);
                formikForgotPassword.setFieldError('email', err.message);
            })
            .finally(() => {
                setIsLoading(false);
                toggleForm();
            });
    };
    const handleCheckEmail = () => {
        setIsLoading(true);
        userService
            .checkEmail(formikForgotPassword.values.email)
            .then((data) => {
                setUser(data);
                setMode('confirm');
            })
            .catch((err) => {
                ToastNotify.Error(err.message);
                formikForgotPassword.setFieldError('email', err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const toggleForm = () => {
        formikLogin.resetForm({
            values: INIT_FORM_LOGIN,
            errors: {},
            touched: {},
            isValidating: false,
            isSubmitting: false,
        });
        setMode('register');
        funToggle();
    };

    const changeMode = () => {
        formikLogin.resetForm();
        formikSignUp.resetForm();
        formikForgotPassword.resetForm();
    };

    const notifyLogin = () => {
        ToastNotify.Success('Đăng nhập thành công.');
        setMode('register');
    };

    const notifySigin = () => {
        ToastNotify.Success(
            'Hệ thống đã gửi email xác thực tài khoản cho bạn. Vui lòng kiểm tra và làm theo hướng dẫn!'
        );
    };

    const notifySiginVerified = () => {
        ToastNotify.Success('Đăng ký thành công. Vui lòng đăng nhập.');
    };

    const removeRecaptcha = () => {
        const script = document.getElementById('recaptcha-key');
        if (script) {
            script.remove();
        }
        const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
        if (recaptchaElems.length) {
            recaptchaElems[0].remove();
        }
    };

    // useEffect(() => {
    //     if (!auth.loggedIn) {
    //         // load the script by passing the URL
    //         loadScriptByURL(
    //             'recaptcha-key',
    //             `https://www.google.com/recaptcha/api.js?render=${envConstants.SITE_KEY_CAPTCHA}`,
    //             () => {
    //                 // console.log('Script recaptcha loaded!');
    //             }
    //         );
    //     }
    // }, []);

    return (
        <>
            <div
                id="modal-login"
                className={`fixed top-0 left-0 w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal ${isOpen ? 'show' : ''
                    } backdrop-brightness-50`}
                style={{
                    margin: 0,
                    visibility: isOpen ? 'visible' : 'hidden',
                    transition: 'opacity 0.15s linear, visibility 0.15s linear',
                }}
            >
                <div className="relative">
                    <div
                        className="block w-full overflow-hidden border-0 "
                    // style={{ backgroundColor: '#f3f4f6' }}
                    >
                        <div className="flex items-center justify-center h-screen px-6 mx-auto md:px-20 lg:px-40 xl:px-48">
                            <div
                                className={`flex flex-col items-center text-center md:flex-row md:items-center md:text-left relative form-container-${defaultMode} rounded-lg overflow-hidden`}
                            >
                                <button
                                    tabIndex={1}
                                    disabled={isLoading}
                                    className="absolute right-3 top-3 focus:bg-gray-300 focus:shadow-lg focus:outline-none "
                                    type="button"
                                    onClick={toggleForm}
                                >
                                    <FaTimes className="text-3xl text-gray-400" />
                                </button>
                                <SloganLanding />

                                <div className="flex items-center justify-center w-full h-full bg-white ">
                                    {mode == 'login' && (
                                        <FormSignIn
                                            setMode={setMode}
                                            formikLogin={formikLogin}
                                            changeMode={changeMode}
                                            googleLogin={handleGoogleLogin}
                                            facebookLogin={handleFacebookLogin}
                                            isOpen={isOpen}
                                            isLoading={isLoading}
                                        />
                                    )}
                                    {mode == 'register' && (
                                        <FormSignUp
                                            setMode={setMode}
                                            formikSignUp={formikSignUp}
                                            changeMode={changeMode}
                                            isOpen={isOpen}
                                            isLoading={isLoading}
                                        />
                                    )}
                                    {mode == 'forgot-password' && (
                                        <FormForgotPassword
                                            setMode={setMode}
                                            formikForgotPassword={formikForgotPassword}
                                            changeMode={changeMode}
                                            isLoading={isLoading}
                                        />
                                    )}
                                    {mode == 'confirm' && (
                                        <FormConfirm
                                            handleSendEmailVerify={handleSendEmailForgotPassword}
                                            setMode={setMode}
                                            changeMode={changeMode}
                                            user={user}
                                            isLoading={isLoading}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ModalLogin;
