/* eslint-disable */
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';

import ReactQuill from 'react-quill';

import 'quill-mention';
import 'react-quill/dist/quill.snow.css'; // Add css for snow theme
import { FaSave, FaUndoAlt, FaInfoCircle, FaEnvelope, FaArrowRight } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

import { MdOutlineAttachEmail } from 'react-icons/md';
import { IoClose } from 'react-icons/io5';
import {
    CONTENT_KEYS,
    CONTENT_TEXTS,
    CONTENT_TEXT_DEFAULTS,
    TYPE_EMAILS,
} from '../../../publish.mainmenu.detail/publish.guest/assets/KeyConstants';

import { useFormik } from 'formik';

import * as Yup from 'yup';

import './styles.css';
import guestManagerService from '../../../publish.mainmenu.detail/publish.guest/guestManager.service';
import ToastNotify from '../../../../../utils/Toast';
import Regex from '../../../../../utils/Regex';
import InputBasic from '../../../../../components/InputBasic/InputBasic';
import EventsService from '../../../../events/Events.service';

const mentionKeys = [
    { id: CONTENT_KEYS.FULLNAME, value: CONTENT_TEXTS.FULLNAME },
    { id: CONTENT_KEYS.TITLE, value: CONTENT_TEXTS.TITLE },
    { id: CONTENT_KEYS.POSITION, value: CONTENT_TEXTS.POSITION },
    { id: CONTENT_KEYS.NAME_COMPANY, value: CONTENT_TEXTS.NAME_COMPANY },
    { id: CONTENT_KEYS.NAME_EVENT, value: CONTENT_TEXTS.NAME_EVENT },
    { id: CONTENT_KEYS.TIME_EVENT, value: CONTENT_TEXTS.TIME_EVENT },
    { id: CONTENT_KEYS.DATE_EVENT, value: CONTENT_TEXTS.DATE_EVENT },
    { id: CONTENT_KEYS.PLACE_EVENT, value: CONTENT_TEXTS.PLACE_EVENT },
    { id: CONTENT_KEYS.ADDRESS_EVENT, value: CONTENT_TEXTS.ADDRESS_EVENT },
    { id: CONTENT_KEYS.NAME_SUPPORTER, value: CONTENT_TEXTS.NAME_SUPPORTER },
    { id: CONTENT_KEYS.PHONE_SUPPORTER, value: CONTENT_TEXTS.PHONE_SUPPORTER },
    { id: CONTENT_KEYS.NAME_SENDER, value: CONTENT_TEXTS.NAME_SENDER },

    { id: CONTENT_KEYS.LINK_EVENT_GUEST, value: CONTENT_TEXTS.LINK_EVENT_GUEST },
    { id: CONTENT_KEYS.LINK_CONFIRM_GUEST, value: CONTENT_TEXTS.LINK_CONFIRM_GUEST },
    { id: CONTENT_KEYS.LINK_GGMAP, value: CONTENT_TEXTS.LINK_GGMAP },
];

const extensionFileBlockeds = [
    'ade',
    'adp',
    'apk',
    'appx',
    'appxbundle',
    'bat',
    'cab',
    'chm',
    'cmd',
    'com',
    'cpl',
    'diagcab',
    'diagcfg',
    'diagpack',
    'dll',
    'dmg',
    'ex',
    'ex_',
    'exe',
    'hta',
    'img',
    'ins',
    'iso',
    'isp',
    'jar',
    'jnlp',
    'js',
    'jse',
    'lib',
    'lnk',
    'mde',
    'msc',
    'msi',
    'msix',
    'msixbundle',
    'msp',
    'mst',
    'nsh',
    'pif',
    'ps1',
    'scr',
    'sct',
    'shb',
    'sys',
    'vb',
    'vbe',
    'vbs',
    'vhd',
    'vxd',
    'wsc',
    'wsf',
    'wsh',
    'xll',
    'terminal',
];

const createTagMention = (index, key, text) =>
    `<span class="mention" data-index="${index}" data-denotation-char="@" data-id="${key}" data-value="${text}"><span contenteditable="false"><span class="ql-mention-denotation-char">@</span>${text}</span></span>`;

export default function ComposeContentEmail({ eventTemplate }) {
    const form_data = eventTemplate?.form_data;

    const [listFiles, setListFile] = useState([]);
    const [content, setContent] = useState('');
    const [previewContent, setPreviewContent] = useState('');
    const [oldContent, setOldContent] = useState('');
    const [subject, setSubject] = useState('');
    const [subjectDefault, setSubjectDefault] = useState('');

    const [typeEmail, setTypeEmail] = useState(TYPE_EMAILS[0].alias);

    const mentionValueViews = [
        { id: CONTENT_KEYS.FULLNAME, value: CONTENT_TEXT_DEFAULTS.FULLNAME },
        { id: CONTENT_KEYS.TITLE, value: CONTENT_TEXT_DEFAULTS.TITLE },
        { id: CONTENT_KEYS.POSITION, value: CONTENT_TEXT_DEFAULTS.POSITION },
        { id: CONTENT_KEYS.NAME_COMPANY, value: CONTENT_TEXT_DEFAULTS.NAME_COMPANY },

        // Hiển thị data từ db
        {
            id: CONTENT_KEYS.NAME_EVENT,
            value: eventTemplate?.form_data?.nameEvent || CONTENT_TEXT_DEFAULTS.NAME_EVENT,
        },
        {
            id: CONTENT_KEYS.TIME_EVENT,
            value: eventTemplate?.form_data?.timeHold || CONTENT_TEXT_DEFAULTS.TIME_EVENT,
        },
        {
            id: CONTENT_KEYS.DATE_EVENT,
            value: eventTemplate?.form_data?.dateHold
                ? convertDate(eventTemplate?.form_data?.dateHold)
                : CONTENT_TEXT_DEFAULTS.DATE_EVENT,
        },
        {
            id: CONTENT_KEYS.PLACE_EVENT,
            value: eventTemplate?.form_data?.placeEvent || CONTENT_TEXT_DEFAULTS.PLACE_EVENT,
        },
        {
            id: CONTENT_KEYS.ADDRESS_EVENT,
            value: eventTemplate?.form_data?.addressEvent || CONTENT_TEXT_DEFAULTS.ADDRESS_EVENT,
        },
        {
            id: CONTENT_KEYS.NAME_SUPPORTER,
            value: eventTemplate?.form_data?.nameSupporter || CONTENT_TEXT_DEFAULTS.NAME_SUPPORTER,
        },
        {
            id: CONTENT_KEYS.PHONE_SUPPORTER,
            value:
                eventTemplate?.form_data?.phoneSupporter || CONTENT_TEXT_DEFAULTS.PHONE_SUPPORTER,
        },
        {
            id: CONTENT_KEYS.NAME_SENDER,
            value: eventTemplate?.form_data?.groupName || CONTENT_TEXT_DEFAULTS.NAME_SENDER,
        },

        { id: CONTENT_KEYS.LINK_EVENT_GUEST, value: CONTENT_TEXT_DEFAULTS.LINK_EVENT_GUEST },
        { id: CONTENT_KEYS.LINK_CONFIRM_GUEST, value: CONTENT_TEXT_DEFAULTS.LINK_CONFIRM_GUEST },
        { id: CONTENT_KEYS.LINK_GGMAP, value: CONTENT_TEXT_DEFAULTS.LINK_GGMAP },
    ];

    const handleChangeFile = (e) => {
        console.log('pick');
        const file = e.target.files[0];
        if (file) {
            const fileSize = file?.size / 1024 / 1024; // in MB

            if (fileSize > 20) {
                ToastNotify.Error('Dung lượng tệp không được lớn hơn 20MB');
                return;
            }

            // block file upload
            if (extensionFileBlockeds.includes(file.name.split('.').pop())) {
                ToastNotify.Error('Tệp này bị chặn vì lý do bảo mật!');
                return;
            }

            // get size file
            let _size = file?.size / 1;
            const fSExt = ['Bytes', 'KB', 'MB'];
            let i = 0;
            while (_size > 900) {
                _size /= 1024;
                i++;
            }
            const exactSize = Math.round(_size * 100) / 100 + ' ' + fSExt[i];

            // console.log({ file })
            setConfirmState({
                open: true,
                content: `<b class="text-lg">Bạn có muốn tải tệp này lên không?</b>
                         <ul><li>&nbsp;Tên tệp: <b>${file?.name}</b></li><li>&nbsp;Dung lượng: <b>${exactSize}</b></li></ul>`,
                type: 'html',
                aciton: () => {
                    ToastNotify.Promise(
                        handleUploadFile(e.target.files),
                        'Đang tải tệp lên...',
                        'Tải tệp đính kèm lên thành công!'
                    );
                },
            });
        }
    };

    const modules = useMemo(
        () => ({
            toolbar: {
                container: '#toolbarQuill',
                handlers: {
                    // attachFile: handleChangeFile
                },
            },
            mention: {
                allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                mentionDenotationChars: ['@'],
                source: function (searchTerm, renderList, mentionChar) {
                    const valueCopy = mentionKeys;
                    if (searchTerm?.length === 0) {
                        renderList(valueCopy, searchTerm);
                    } else {
                        const matches = [];
                        for (let i = 0; i < valueCopy?.length; i++)
                            if (~valueCopy[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
                                matches.push(valueCopy[i]);
                        renderList(matches, searchTerm);
                    }
                },
            },
        }),
        []
    );

    const quillInputRef = useRef(null);
    const quillOutputRef = useRef(null);
    const attachRef = useRef(null);
    const [heightDivAttach, setHeightDivAttach] = useState(0);

    const handleUploadFile = async (files) => {
        try {
            let formData = new FormData();
            for (var i = 0; i < files?.length; i++) {
                formData.append(`file_${i}`, files[i]);
            }
            formData.append('eventId', eventTemplate?.id);
            formData.append('type', typeEmail);

            const dataRes = await EventsService.uploadFileEmail(formData);
            const eventEmailRes = dataRes?.event_emails.find((item) => item.type === typeEmail);
            if (eventEmailRes) {
                setListFile(eventEmailRes.attachments);
            }
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const handleRemoveFile = (file) => {
        setConfirmState({
            open: true,
            content: `<b>Bạn có muốn xóa tệp </b> ${file.filename} <b> không?</b>`,
            type: 'html',
            aciton: () => {
                const payload = { eventId: eventTemplate?.id, fileId: file.id, type: typeEmail };
                EventsService.removeFileEmail(payload)
                    .then((dataRes) => {
                        setListFile(getAttachments(dataRes, typeEmail));
                        ToastNotify.Success('Xóa tệp thành công!');
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
        });
    };

    const getAttachments = (eventObj, typeEmail) => {
        if (!eventObj) return [];

        const eventEmail = eventObj?.event_emails.find((item) => item.type === typeEmail);
        if (!eventEmail) return [];

        return eventEmail.attachments;
    };

    const handleTypeEmailChange = (event) => {
        const value = event.target.value;
        if (checkDiff()) {
            setConfirmState({
                open: true,
                content: 'Bạn có chắc chắn muốn đổi loại email?',
                type: '',
                aciton: () => {
                    setTypeEmail(value);
                },
            });
        } else {
            setTypeEmail(value);
        }
    };

    const replaceKeyToDataView = (content) => {
        let contentCopy = content;
        const mentionTag = 'data-denotation-char="@"';
        const mentionTagSpace = 'data-denotation-char=""';
        mentionKeys.forEach((item, index) => {
            contentCopy = contentCopy.replaceAll(
                `data-value="${item.value}"`,
                `data-value="${mentionValueViews[index].value}"`
            );
        });
        contentCopy = contentCopy.replaceAll(mentionTag, mentionTagSpace);
        return contentCopy;
    };

    const replaceKeyToDataSave = (content) => {
        let contentCopy = content;
        const keyFindMention = '[data-denotation-char="@"]';

        let arrTagMentions = Array.from(
            new DOMParser().parseFromString(content, 'text/html').querySelectorAll(keyFindMention)
        )?.map((element) => element.outerHTML);

        mentionKeys.forEach((key) => {
            arrTagMentions.forEach((tag, index, object) => {
                if (tag.includes(key.id)) {
                    contentCopy = contentCopy.replaceAll(tag, key.id);
                    object.splice(index, 1); //remove element of array
                }
            });
        });
        return contentCopy;
    };

    const convertContentFromDB = (content) => {
        let contentCopy = content;

        // Neu content co mentio @
        if (mentionKeys.some((key) => content?.includes(key.id))) {
            mentionKeys.forEach((key, index) => {
                contentCopy = contentCopy.replaceAll(
                    key.id,
                    createTagMention(index, key.id, key.value)
                );
            });
        }
        return contentCopy;
    };

    const handleEditorChange = (content) => {
        // let contentView = content
        // const keyCheckMention = 'data-denotation-char="@"'

        // // Neu co mention @
        // if (content.includes(keyCheckMention)) {
        //     contentView = replaceKeyToDataView(contentView)
        // }
        // // const delta = quillOutputRef.current.editor.clipboard.convert(contentView);
        // // quillOutputRef.current.editor.setContents(delta, 'silent');
        // setPreviewContent(contentView)
        // console.log("handleEditorChange")
        setContent(content);
    };

    const handleSaveNewContent = () => {
        const contentSave = replaceKeyToDataSave(content);
        return guestManagerService
            .saveNewContentEmail(eventTemplate?._id, typeEmail, contentSave, subject)
            .then(() => {
                setOldContent(content);
                ToastNotify.Success('Cập nhập nội dung email thành công!');
            });
    };

    const handleDeleteContent = () =>
        guestManagerService
            .deleteContentEmail(eventTemplate?._id, typeEmail)
            .then(() => {
                ToastNotify.Promise(
                    getTemplateEmail(),
                    'Đang tải...',
                    'Đã khôi phục nội dung email về mặc định!'
                );
            })
            .catch(() => {
                ToastNotify.Error('Nội dung email đang là mẫu mặc định');
                setContent(oldContent);
            });

    function convertDate(dateString) {
        const [year, month, day] = dateString.split('-');
        const formattedDate = `Ngày ${parseInt(day)} Tháng ${parseInt(month)} Năm ${parseInt(
            year
        )}`;
        return formattedDate;
    }

    const [isOpenEmailModal, setIsOpenEmailModal] = useState(false);

    const openModal = () => {
        setIsOpenEmailModal(true);
    };

    const closeModal = () => {
        setIsOpenEmailModal(false);
    };

    const [emailTo, setEmailTo] = useState('');

    const handleTestEmail = (email_to) => {
        setEmailTo(email_to);
        return guestManagerService.testEmail(
            eventTemplate?._id,
            typeEmail,
            replaceKeyToDataSave(content),
            email_to,
            subject
        );
    };

    const trimFilename = (text, maxLength) => {
        if (!text) return '';
        if (text?.length <= maxLength) return text;

        // lấy đuôi file
        const extFile = text.split('.').pop();
        return `${text.substring(0, maxLength - 5)}... .${extFile}`;
    };

    const getTemplateEmail = () =>
        guestManagerService
            .getEmailTemplate(eventTemplate?._id, typeEmail)
            .then((data) => {
                const dataConverted = convertContentFromDB(data.content);
                setOldContent(dataConverted);
                setContent(dataConverted);
                setListFile(data.attachments);
                setSubject(data.subject || data.subjectDefault);
                setSubjectDefault(data.subjectDefault);
                // const delta = quillRef.current.editor.clipboard.convert(new DOMParser().parseFromString(dataConverted, 'text/html').body);
                // const delta = quillInputRef.current.editor.clipboard.convert(dataConverted.replaceAll("<p>", `<p style="white-space: pre-wrap;">`));
                const delta = quillInputRef.current.editor.clipboard.convert(dataConverted);
                quillInputRef.current.editor.setContents(delta, 'silent');
            })
            .catch((err) => {
                console.log(err);
            });
    useEffect(() => {
        getTemplateEmail();
    }, [typeEmail]);

    useEffect(() => {
        console.log('change');
        let contentView = content;
        const keyCheckMention = 'data-denotation-char="@"';

        // Neu co mention @
        if (content?.includes(keyCheckMention)) {
            contentView = replaceKeyToDataView(contentView);
        }
        setPreviewContent(contentView);
        setHeightDivAttach(attachRef?.current?.clientHeight);
    }, [content]);

    const [confirmState, setConfirmState] = useState({
        open: false,
        type: '',
        content: '',
        aciton: () => {},
    });

    const checkDiff = () =>
        // console.log(content)
        // console.log(oldContent)
        // if (content !== oldContent) {
        //     return true
        // }

        // const deltaContent = new Delta().insert(content)
        // const deltaContentOld = new Delta().insert(oldContent)
        // console.log({ deltaContent })
        // console.log({ deltaContentOld })
        // console.log( deltaContent.diff(deltaContentOld) )
        // return deltaContent.ops !== deltaContentOld.ops
        // return deltaContent.diff(deltaContentOld).ops?.length !== 0
        true;

    useEffect(() => {
        // if (!attachRef?.current?.clientHeight) {
        //     return;
        // }
        setHeightDivAttach(attachRef?.current?.clientHeight);
    }, [listFiles]);

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal "
            id="contentEmailModal"
            tabIndex="-1"
            aria-modal="true"
            role="dialog"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered modal-xl ">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <div className="flex items-center">
                            <h5
                                className="text-[16px] font-medium leading-normal text-black"
                                id="staticBackdropLabel"
                            >
                                Sửa nội dung email
                            </h5>{' '}
                            <div className="relative group">
                                <button
                                    data-tip
                                    data-for="helper-templateMail"
                                    className="group relative ml-3 mr-1 flex items-center rounded-full border-[1px] border-blue-500 bg-white text-xs font-medium leading-tight text-white shadow-md transition duration-150 ease-in-out "
                                >
                                    <FaInfoCircle className="text-xl text-blue-500" />
                                </button>
                                <ReactTooltip
                                    id="helper-templateMail"
                                    place="bottom"
                                    effect="solid"
                                >
                                    <p>* Các loại email:</p>
                                    <p>- Lời mời: dùng khi mời khách đến tham gia sự kiện</p>
                                    <p>- Chấp nhận tham dự: dùng khi khách đồng ý tham dự</p>
                                    <p>- Từ chối tham dự: dùng khi khách từ chối tham dự</p>
                                    <p>
                                        - Chờ xác nhận: dùng khi khách hoặc nhóm khách đăng ký tham
                                        dự
                                    </p>
                                    <p>
                                        - Chấp nhận yêu cầu tham dự: dùng khi chấp nhận yêu cầu tham
                                        dự của khách
                                    </p>
                                    <p>
                                        - Từ chối yêu cầu tham dự: dùng khi từ chối yêu cầu tham dự
                                        của khách
                                    </p>
                                    {/* <p>- Nhắc nhở: dùng khi nhắc nhở khách phản hồi</p> */}
                                </ReactTooltip>
                            </div>
                        </div>
                        <button
                            type="button"
                            onClick={() => {
                                if (checkDiff()) {
                                    setConfirmState({
                                        open: true,
                                        content: 'Bạn có chắc chắn muốn thoát?',
                                        type: 'confirm_exit',
                                        aciton: () => {
                                            setContent(oldContent);
                                        },
                                    });
                                }
                            }}
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss={checkDiff() ? '' : 'modal'}
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="relative flex flex-col px-4 modal-body">
                        <div className="flex w-full my-2 space-x-2">
                            <div className="relative w-[1024px] rounded-md border-[1px] border-gray-400">
                                <div className="flex items-center justify-between py-2">
                                    <div className="flex items-center">
                                        <p className="ml-1 text-sm font-bold">Loại email:</p>
                                        <div className="relative inline-flex ml-1">
                                            <select
                                                value={typeEmail}
                                                onChange={handleTypeEmailChange}
                                                className="py-1 pl-3 pr-8 text-sm font-medium leading-5 bg-white border border-gray-500 rounded-md appearance-none focus:shadow-outline-blue focus:border-blue-300 focus:outline-none focus:ring-4 focus:ring-blue-200"
                                            >
                                                {TYPE_EMAILS?.map((item) => (
                                                    <option value={item.alias} key={item.alias}>
                                                        {item.title}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    {!eventTemplate?.isExpired && (
                                        <button
                                            onClick={handleSaveNewContent}
                                            className="flex items-center px-5 py-2 mr-1 text-xs font-medium leading-tight text-white transition duration-150 ease-in-out bg-blue-600 rounded-md shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                                        >
                                            <FaSave className="mr-2" />
                                            Lưu nội dung email
                                        </button>
                                    )}
                                </div>
                                <div className="flex items-center justify-between border-y-[1px] border-gray-400 h-[50px]">
                                    {!eventTemplate?.isExpired && (
                                        <div className="flex items-center">
                                            <button
                                                onClick={openModal}
                                                className="flex items-center px-5 py-2 ml-1 text-xs font-medium leading-tight text-white transition duration-150 ease-in-out bg-blue-600 rounded-md shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                                            >
                                                <FaEnvelope className="mr-2" />
                                                Gửi mẫu
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setConfirmState({
                                                        open: true,
                                                        content:
                                                            'Bạn có chắc chắn muốn khôi phục nội dung email về mặc định không?',
                                                        type: '',
                                                        aciton: () => {
                                                            handleDeleteContent();
                                                        },
                                                    });
                                                }}
                                                className="flex items-center px-5 py-2 ml-1 text-xs font-medium leading-tight text-white transition duration-150 ease-in-out bg-red-600 rounded-md shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg"
                                            >
                                                <FaUndoAlt className="mr-2" />
                                                Khôi phục
                                            </button>
                                        </div>
                                    )}
                                    <div className="relative group">
                                        {!eventTemplate?.isExpired && (
                                            <button
                                                data-tip
                                                data-for="helper"
                                                className="group relative ml-3 mr-1 flex items-center rounded-full border-[1px] border-blue-500 bg-white text-xs font-medium leading-tight text-white shadow-md transition duration-150 ease-in-out "
                                            >
                                                <FaInfoCircle className="text-xl text-blue-500" />
                                            </button>
                                        )}
                                        <ReactTooltip id="helper" place="bottom" effect="solid">
                                            <p>- Nhập "@" để chọn từ khóa</p>
                                            <p>
                                                - Bạn có thể soạn nội dung của email ở khung bên
                                                trái
                                            </p>
                                            <p>
                                                - Nội dung mẫu của email sẽ được hiển thị ở khung
                                                bên phải
                                            </p>
                                            <p>
                                                {' '}
                                                - Bạn được phép đính kèm tối đa 5 tệp, mỗi tệp có
                                                dung lượng tối đa 20MB{' '}
                                            </p>
                                            <p>
                                                {' '}
                                                - Định dạng tệp được phép đính kèm tuân theo bảo mật
                                                của Gmail{' '}
                                            </p>
                                        </ReactTooltip>
                                    </div>
                                </div>
                                <div className="h-[500px] w-full">
                                    <div className={'items-center border-b-[1px] border-gray-400'}>
                                        <input
                                            className=" m-0 block w-full border-none bg-white bg-clip-padding px-2 py-1 text-[15px] font-semibold text-black outline-none focus:border-none focus:outline-none  "
                                            placeholder="Tiêu đề"
                                            value={subject}
                                            title={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                        />
                                    </div>
                                    <ReactQuill
                                        theme="snow"
                                        ref={quillInputRef}
                                        onChange={handleEditorChange}
                                        modules={modules}
                                        style={{
                                            height: `calc(465px - ${heightDivAttach}px)`,
                                            // height: "475px",
                                            color: 'black',
                                            border: 'none',
                                            paddingBottom: '40px',
                                        }}
                                        readOnly={eventTemplate?.isExpired}
                                    />
                                </div>
                                <div
                                    className="absolute bottom-8 w-full border-t-[1px] border-gray-200 bg-gray-100"
                                    ref={attachRef}
                                >
                                    {listFiles &&
                                        listFiles?.map((file) => (
                                            <div className="w-full p-1">
                                                <div className="flex justify-between px-3 hover:rounded-lg hover:bg-gray-200">
                                                    <span className="py-0.5 text-sm font-bold text-sky-700 ">
                                                        {trimFilename(file.filename, 55)}
                                                    </span>
                                                    <button
                                                        onClick={() => handleRemoveFile(file)}
                                                        className="p-1 text-sm font-bold text-black cursor-pointer hover:rounded-full hover:bg-gray-100"
                                                        title="Xóa tệp đính kèm"
                                                    >
                                                        <IoClose className="" />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                                <div
                                    className={`absolute bottom-0 w-full ${
                                        eventTemplate?.isExpired && 'pointer-events-none opacity-50'
                                    }`}
                                >
                                    <CustomToolbar
                                        attachDisable={listFiles?.length >= 5}
                                        changeFile={handleChangeFile}
                                    />
                                </div>
                            </div>
                            <div className="flex w-[15px] items-center justify-center">
                                <FaArrowRight />
                            </div>
                            <div className="w-[1024px] rounded-md border-[1px] border-gray-400 ">
                                <div className="flex items-center py-2 bg-gray-10 0 rounded-t-md">
                                    <p className="ml-1 text-sm font-bold">Đến: </p>
                                    <div className="flex items-center p-2 ml-1 text-xs font-medium leading-tight text-white transition duration-150 ease-in-out bg-gray-500 rounded-full shadow-md active:shadow-lg">
                                        {'NguyenVanA@gmail.com'}
                                    </div>
                                </div>
                                <div
                                    className={`flex flex-wrap items-center ${
                                        eventTemplate?.email_cc?.length != 0
                                            ? 'py-2'
                                            : 'py-[13.5px]'
                                    } bg-gray-10 0 gap-1 border-y-[1px] border-gray-400`}
                                >
                                    <p className="ml-1 text-sm font-bold">CC: </p>
                                    {eventTemplate?.email_cc?.map((item) => (
                                        <div className="flex items-center p-2 ml-1 text-xs font-medium leading-tight text-white transition duration-150 ease-in-out bg-gray-500 rounded-full shadow-md active:shadow-lg">
                                            {item}
                                        </div>
                                    ))}
                                </div>
                                <div
                                    className={
                                        'bg-gray-10 0 items-center border-b-[1px] border-gray-400 '
                                    }
                                >
                                    <input
                                        value={subject || subjectDefault}
                                        className=" m-0 block w-full border-none bg-clip-padding px-2 py-1  text-[15px] font-semibold text-black outline-none focus:border-none focus:outline-none  "
                                        readOnly
                                        disabled
                                        title={subject || subjectDefault}
                                    />
                                </div>
                                <div className="bg-gray-10 0 h-[500px] w-full overflow-auto rounded-b-md">
                                    <ReactQuill
                                        theme="snow"
                                        value={previewContent}
                                        // ref={quillOutputRef}
                                        modules={{ toolbar: false }}
                                        style={{
                                            color: 'black',
                                        }}
                                        readOnly={true}
                                    />
                                    <FakeSignature signature={eventTemplate?.signature} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EmailModal
                isOpen={isOpenEmailModal}
                closeModal={closeModal}
                emailTo={emailTo}
                handleTestEmail={handleTestEmail}
            />
            <ConfirmAction state={confirmState} setState={setConfirmState} />
        </div>
    );
}

const CustomToolbar = ({ attachDisable, changeFile }) => (
    <div id="toolbarQuill">
        <button className="ql-bold" title="In đậm" />
        <button className="ql-italic" title="In nghiêng" />
        <button className="ql-underline" title="Gạch dưới" />
        <button className="ql-strike" title="Gạch ngang" />

        <button className="ql-list" value="ordered" title="Đánh số thứ tự" />
        <button className="ql-list" value="bullet" title="Danh sách" />
        <button className="ql-indent" value="-1" title="Căn phải" />
        <button className="ql-indent" value="+1" title="Căn trái" />

        <button className="ql-link" title="Chèn liên kết" />

        {/* Button attach file */}
        <button
            className=""
            disabled={attachDisable}
            onClick={(e) => document.getElementById('inputFileUpload').click()}
        >
            <MdOutlineAttachEmail
                className={`font-bold ${
                    attachDisable ? 'text-gray-400' : 'text-black hover:text-blue-700'
                }  -mb-0.5 text-lg `}
                title={attachDisable ? 'Chỉ được đính kèm tối đa 5 tệp' : 'Đính kèm tệp'}
            />
            <input
                id="inputFileUpload"
                className="hidden"
                onChange={changeFile}
                onClick={(event) => {
                    event.target.value = null;
                }}
                type="file"
            />
        </button>
    </div>
);

function FakeSignature({ signature }) {
    const html = signature || '';
    const blocksFromHtml = htmlToDraft(html);
    const content = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks);
    const value = EditorState.createWithContent(content);

    const [editorState, setEditorState] = useState(value);
    function convertRichTextToString(richText) {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = richText;
        return tempElement.innerText;
    }

    return (
        <>
            {signature && convertRichTextToString(signature) != '' && (
                <Editor
                    wrapperStyle={{
                        padding: '5px',
                        borderTop: '1px solid #d1d5db',
                    }}
                    toolbarHidden
                    editorState={editorState}
                    readOnly
                />
            )}
        </>
    );
}

const EmailModal = ({ isOpen, closeModal, handleTestEmail }) => {
    const formik = useFormik({
        initialValues: {
            email_to: '',
        },
        validationSchema: Yup.object({
            email_to: Yup.string()
                .required('Email nhận không được để trống')
                .matches(Regex.email, 'Email không hợp lệ!')
                .max(100, 'Email không vượt quá 100 kí tự!'),
        }),
        onSubmit: () => {
            ToastNotify.Promise(
                handleTestEmail(formik.values.email_to),
                'Đang gửi...',
                'Đã gửi email mẫu!'
            );
        },
    });
    const handleCloseModal = () => {
        closeModal();
    };
    return (
        <div>
            {isOpen && (
                <div
                    className="fixed inset-0 z-[9999] flex items-center justify-center"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                >
                    <div className="w-[424px] rounded-lg bg-white p-4">
                        <div className="flex items-center justify-between w-full">
                            <h5 className="text-xl font-medium leading-normal text-gray-800">
                                Nhập email nhận
                            </h5>
                            <button
                                onClick={handleCloseModal}
                                type="button"
                                className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            ></button>
                        </div>

                        <div className="my-3">
                            <InputBasic
                                id="email_to"
                                name="email_to"
                                placeholder={'Nhập email nhận'}
                                value={formik.values.email_to}
                                onChange={formik.handleChange}
                                type="text"
                                size="small"
                            />
                            <span className="text-xs text-red-500">
                                {formik.errors.email_to || ''}
                            </span>
                        </div>

                        <div className="flex justify-end w-full">
                            <button
                                type="button"
                                onClick={handleCloseModal}
                                className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            >
                                Trở lại
                            </button>
                            <button
                                type="button"
                                onClick={formik.handleSubmit}
                                className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                            >
                                Gửi
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const ConfirmAction = ({ state, setState }) => {
    const initState = { open: false, type: '', action: () => {}, content: '' };
    const handleContinue = async () => {
        await state.aciton();
        setState(initState);
    };
    const handleExit = () => {
        setState(initState);
    };
    return (
        <div>
            {state.open && (
                <div
                    className="fixed inset-0 z-[9999] flex items-center justify-center"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                >
                    <div className="min-w-[424px] max-w-[500px] rounded-lg bg-white p-4">
                        <div className="flex items-center justify-between w-full">
                            <div className="flex items-center">
                                <FaInfoCircle className="mr-2 text-blue-500" />
                                <h5 className="text-xl font-medium leading-normal text-gray-800">
                                    Xác nhận
                                </h5>
                            </div>
                            <button
                                onClick={handleExit}
                                type="button"
                                className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            ></button>
                        </div>

                        <div className="my-3">
                            {state.type === 'html' ? (
                                <p
                                    className="break-words"
                                    dangerouslySetInnerHTML={{ __html: state.content }}
                                ></p>
                            ) : (
                                <p className="break-words">{state.content}</p>
                            )}
                        </div>

                        <div className="flex justify-end w-full">
                            <button
                                type="button"
                                onClick={handleExit}
                                className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            >
                                Trở lại
                            </button>
                            <button
                                type="button"
                                onClick={handleContinue}
                                data-bs-dismiss={state.type === 'confirm_exit' ? 'modal' : ''}
                                className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                            >
                                Xác nhận
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
