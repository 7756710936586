import '@fortawesome/fontawesome-free/css/all.min.css';
import '../index.css';
import '../slick.css';
import '../assets/styles/style.css';
import '../assets/styles/gg-fonts.css';

import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import LayoutDefault from '../components/Layout/LayoutDefault/LayoutDefault';
import Loading from '../components/Loading';
import MainRoute from './Main.route';
import MappingRoute from './Mapping.route';
import ProtectedRoute from './Protected.route';
import RequireAuthUserRoute from './RequireAuthUser.route';
import LogOutModal from '../views/user/components/LogOutModal';
import PublishRoute from '../views/publish.mainmenu/PublishMainMenu.router';
import { useSelector } from 'react-redux';
import ToastNotify from '../utils/Toast';
import PAGE from '../config/path.route';
import AvatarPreview from '../components/Popover/components/AvatarPreview';

const finalRoutes = [...MainRoute, ...MappingRoute, ...PublishRoute];

export default function RouteApp() {
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    useEffect(() => {
        // console.log(auth);
        if (auth.isTokenExpired) {
            // ToastNotify.Error('Phiên đăng nhập đã hết hạn vui lòng đăng nhập lại!', 'top-left');
            ToastNotify.Info('Vui lòng đăng nhập để sử dụng chức năng của ChungVui', 'top-left');
            navigate(PAGE.dashboard);
        }
    }, [auth.isTokenExpired]);

    return (
        <>
            <LogOutModal />
            <AvatarPreview />
            <Routes>
                {finalRoutes.map((route, idx) => {
                    const Layout = route.layout ?? LayoutDefault;
                    const Element = route.element;
                    const isProtect = route.isProtect;
                    const isRequireAuthUser = route?.isRequireAuthUser;
                    return (
                        <Route
                            key={idx}
                            path={route.path}
                            element={
                                <Layout>
                                    <Suspense fallback={<Loading />}>
                                        {isProtect ? (
                                            <ProtectedRoute>
                                                {isRequireAuthUser ? (
                                                    <RequireAuthUserRoute>
                                                        <Element />
                                                    </RequireAuthUserRoute>
                                                ) : (
                                                    <Element />
                                                )}
                                            </ProtectedRoute>
                                        ) : (
                                            <Element />
                                        )}
                                    </Suspense>
                                </Layout>
                            }
                        />
                    );
                })}
            </Routes>
        </>
    );
}
