/* eslint-disable max-len */
import {
    FaChartLine,
    // FaCreditCard,
    FaGratipay,
    FaHouseUser,
    FaMapSigns,
    FaMars,
    // FaTable,
    FaVenus,
    // FaInfoCircle,
    // FaImages,
    FaCog,
    // FaBook,
    FaMailBulk,
    // FaImage,
    FaYoutube,
    FaUsers,
    FaAddressCard,
    FaInfoCircle,
    // FaEnvelopeOpenText,
    // FaRegImage,
} from 'react-icons/fa';
import { BsPostcardHeartFill } from 'react-icons/bs';
// import { TbSlideshow } from 'react-icons/tb';
import { BiSolidPhotoAlbum } from 'react-icons/bi';
// import {ReactComponent as PostCard} from '../../assets/images/postcard.svg';
import { RiSlideshow3Fill } from 'react-icons/ri';
import INPUT_ALIAS from '../../config/templates/input.templates';
// import { MdTableBar } from 'react-icons/md';
const themeCommon = {
    [INPUT_ALIAS.wedding_1]: {
        header: {
            color: 'text-green-700',
            font: 'font-Merriweather',
        },
        component: {
            color: 'text-green-900',
            font: 'font-greatVibes',
            icon: 'fa fa-heart text-2xl text-red-500',
        },
        button: {
            background: 'bg-green-700 text-white hover:bg-white hover:text-green-700',
            color: 'text-green-700 hover:bg-green-700 hover:text-white',
            font: 'font-Merriweather',
            border: 'border-green-700',
        },
        background: {
            color: 'bg-bg-temp1 bg-cover bg-fixed',
        },
    },
    [INPUT_ALIAS.wedding_2]: {
        header: {
            color: 'text-slate-700',
            font: '',
        },
        component: {
            background: 'bg-slate-700 text-white hover:bg-white hover:text-slate-700',
            color: 'text-slate-700',
            font: 'font-greatVibes',
            icon: 'fa fa-heart text-2xl text-red-500',
        },
        button: {
            color: 'text-slate-800 hover:bg-slate-800 hover:text-white',
            font: 'font-medium',
            border: 'border-black',
        },
        background: {
            color: 'bg-bg-temp2 bg-cover bg-fixed',
        },
    },
    [INPUT_ALIAS.wedding_3]: {
        header: {
            color: 'text-green-700',
            font: 'font-Merriweather',
        },
        component: {
            color: 'text-green-900',
            font: 'font-greatVibes',
            icon: 'fa fa-heart text-2xl text-red-500',
        },
        button: {
            color: 'text-green-700',
            font: 'font-Merriweather',
            border: 'border-green-700',
        },
        background: {
            color: 'bg-white',
        },
    },
    [INPUT_ALIAS.wedding_4]: {
        component: {
            icon: 'fa fa-heart text-2xl text-red-500',
        },
    },
    [INPUT_ALIAS.wedding_5]: {
        component: {
            icon: 'fa fa-heart text-2xl text-red-500',
        },
    },
    [INPUT_ALIAS.wedding_6]: {
        component: {
            icon: 'fa fa-heart text-2xl text-red-500',
        },
    },
    [INPUT_ALIAS.wedding_7]: {
        component: {
            icon: 'fa fa-heart text-2xl text-red-500',
        },
    },
    [INPUT_ALIAS.wedding_8]: {
        component: {
            icon: 'fa fa-heart text-2xl text-red-500',
        },
    },
    [INPUT_ALIAS.wedding_9]: {
        component: {
            icon: 'fa fa-heart text-2xl text-red-500',
        },
    },
    [INPUT_ALIAS.wedding_10]: {
        component: {
            icon: 'fa fa-heart text-2xl text-red-500',
        },
    },
    [INPUT_ALIAS.wedding_12]: {
        component: {
            icon: 'fa fa-heart text-2xl text-red-500',
        },
    },
    [INPUT_ALIAS.event_1]: {
        header: {
            color: 'text-amber-600 font-medium',
            font: '',
        },
        component: {
            color: 'text-white',
            font: '',
            icon: '',
        },
        button: {
            color: 'text-black hover:bg-white hover:text-amber-500',
            font: 'font-medium',
            border: 'border-black',
        },
        background: {
            color: 'bg-gradient-to-br from-gray-600 via-[#45b7d6] to-gray-900',
        },
    },
    [INPUT_ALIAS.event_2]: {
        header: {
            color: 'text-amber-600 font-medium',
            font: '',
        },
        component: {
            color: 'text-white',
            font: '',
            icon: '',
        },
        button: {
            color: 'text-black hover:bg-white hover:text-amber-600',
            font: 'font-medium',
            border: 'border-black hover:border-amber-500',
        },
        background: {
            color: 'bg-gradient-to-br from-gray-600 via-[#45b7d6] to-gray-900',
        },
    },
    [INPUT_ALIAS.event_3]: {
        header: {
            color: 'text-green-700',
            font: 'font-Merriweather',
            icon: '',
        },
        component: {
            color: 'text-green-900',
            font: 'font-greatVibes',
        },
        button: {
            color: 'text-green-700',
            font: 'font-Merriweather',
            icon: '',
            border: 'border-green-700',
        },
        background: {
            color: 'bg-white',
        },
    },
    [INPUT_ALIAS.event_4]: {
        header: {
            color: 'text-green-700',
            font: 'font-Merriweather',
        },
        component: {
            color: 'text-green-900',
            font: 'font-greatVibes',
            icon: '',
        },
        button: {
            color: 'text-green-700',
            font: 'font-Merriweather',
            border: 'border-green-700',
        },
        background: {
            color: 'bg-white',
        },
    },
    [INPUT_ALIAS.event_5]: {
        header: {
            color: 'text-green-700',
            font: 'font-Merriweather',
        },
        component: {
            color: 'text-green-900',
            font: 'font-greatVibes',
            icon: '',
        },
        button: {
            color: 'text-green-700',
            font: 'font-Merriweather',
            border: 'border-green-700',
        },
        background: {
            color: 'bg-white',
        },
    },
    [INPUT_ALIAS.event_6]: {
        header: {
            color: 'text-green-700',
            font: 'font-Merriweather',
        },
        component: {
            color: 'text-green-900',
            font: 'font-greatVibes',
            icon: '',
        },
        button: {
            color: 'text-green-700',
            font: 'font-Merriweather',
            border: 'border-green-700',
        },
        background: {
            color: 'bg-white',
        },
    },
    [INPUT_ALIAS.event_7]: {
        header: {
            color: 'text-green-700',
            font: 'font-Merriweather',
        },
        component: {
            color: 'text-green-900',
            font: 'font-greatVibes',
            icon: '',
        },
        button: {
            color: 'text-green-700',
            font: 'font-Merriweather',
            border: 'border-green-700',
        },
        background: {
            color: 'bg-white',
        },
    },
    [INPUT_ALIAS.event_8]: {
        header: {
            color: 'text-green-700',
            font: 'font-Merriweather',
        },
        component: {
            color: 'text-green-900',
            font: 'font-greatVibes',
            icon: '',
        },
        button: {
            color: 'text-green-700',
            font: 'font-Merriweather',
            border: 'border-green-700',
        },
        background: {
            color: 'bg-white',
        },
    },
    [INPUT_ALIAS.event_9]: {
        header: {
            color: 'text-green-700',
            font: 'font-Merriweather',
        },
        component: {
            color: 'text-green-900',
            font: 'font-greatVibes',
            icon: '',
        },
        button: {
            color: 'text-green-700',
            font: 'font-Merriweather',
            border: 'border-green-700',
        },
        background: {
            color: 'bg-white',
        },
    },
    [INPUT_ALIAS.event_11]: {
        header: {
            color: 'text-green-700',
            font: 'font-Merriweather',
        },
        component: {
            color: 'text-green-900',
            font: 'font-greatVibes',
            icon: '',
        },
        button: {
            color: 'text-green-700',
            font: 'font-Merriweather',
            border: 'border-green-700',
        },
        background: {
            color: 'bg-white',
        },
    },
    [INPUT_ALIAS.event_12]: {
        header: {
            color: 'text-green-700',
            font: 'font-Merriweather',
        },
        component: {
            color: 'text-green-900',
            font: 'font-greatVibes',
            icon: '',
        },
        button: {
            color: 'text-green-700',
            font: 'font-Merriweather',
            border: 'border-green-700',
        },
        background: {
            color: 'bg-white',
        },
    },
    [INPUT_ALIAS.event_13]: {
        header: {
            color: 'text-green-700',
            font: 'font-Merriweather',
        },
        component: {
            color: 'text-green-900',
            font: 'font-greatVibes',
            icon: '',
        },
        button: {
            color: 'text-green-700',
            font: 'font-Merriweather',
            border: 'border-green-700',
        },
        background: {
            color: 'bg-white',
        },
    },
};

export default themeCommon;
export const menuList = (alias) => {
    let listData = [];
    switch (alias) {
        case INPUT_ALIAS.wedding_1:
        case INPUT_ALIAS.wedding_2:
        case INPUT_ALIAS.wedding_3:
        case INPUT_ALIAS.wedding_6:
        case INPUT_ALIAS.wedding_8:
        case INPUT_ALIAS.wedding_4:
        case INPUT_ALIAS.wedding_5:
        case INPUT_ALIAS.wedding_7:
        case INPUT_ALIAS.wedding_9:
        case INPUT_ALIAS.wedding_12:
        case INPUT_ALIAS.wedding_13:
        case INPUT_ALIAS.wedding_14:
        case INPUT_ALIAS.wedding_15:
        case INPUT_ALIAS.wedding_10:
            listData = listData.concat([
                {
                    path: '/:urlShare/summary',
                    icon: <FaHouseUser className="size-8" />,
                    label: 'Thông tin chung',
                    des: 'Thời gian, địa điểm, địa chỉ, người hỗ trợ.',
                    keyQues: 'main_info_wed',
                    top: true,
                    management: false,
                    infor: true,
                    index: 1,
                },
                {
                    path: '/:urlShare/groom',
                    icon: <FaMars className="size-8" />,
                    label: 'Chú rể',
                    des: 'Thông tin chú rể, người đại diện, ngân hàng.',
                    top: false,
                    management: false,
                    infor: true,
                    index: 2,
                    keyQues: 'groom',
                },
                {
                    path: '/:urlShare/bride',
                    icon: <FaVenus className="size-7" />,
                    label: 'Cô dâu',
                    des: 'Thông tin cô dâu, người đại diện, ngân hàng.',
                    top: false,
                    management: false,
                    infor: true,
                    index: 3,
                    keyQues: 'bride',
                },
                {
                    path: '/:urlShare/partyinfo',
                    icon: <FaMapSigns className="size-8" />,
                    label: 'Danh sách chương trình',
                    des: 'Tên, thời gian, địa điểm của các hoạt động trong lễ cưới.',
                    top: true,
                    management: false,
                    infor: true,
                    index: 4,
                    keyQues: 'events',
                },
                {
                    path: '/:urlShare/album',
                    icon: <BiSolidPhotoAlbum className="size-8" />,
                    label: 'Bộ sưu tập ảnh',
                    des: 'Những hình ảnh đẹp của đám cưới được chia sẻ trên thiệp.',
                    top: false,
                    management: false,
                    infor: true,
                    index: 5,
                    keyQues: 'album',
                },
                {
                    path: '/:urlShare/slide',
                    icon: <RiSlideshow3Fill className="size-8" />,
                    label: 'Ảnh trình chiếu',
                    des: 'Những hình ảnh tiêu biểu được lựa chọn để hiển thị nổi bật trên thiệp.',
                    top: false,
                    management: false,
                    infor: true,
                    index: 6,
                    keyQues: 'slide',
                },
                {
                    path: '/:eventID/video',
                    icon: <FaYoutube className="size-8" />,
                    label: 'Video tiệc cưới',
                    des: 'Sử dụng video từ Youtube để chia sẻ những khoảnh khắc quan trọng.',
                    top: false,
                    management: false,
                    infor: true,
                    index: 7,
                    keyQues: 'video',
                    hidden:
                        alias === INPUT_ALIAS.wedding_3 ||
                        alias === INPUT_ALIAS.wedding_2 ||
                        alias === INPUT_ALIAS.wedding_5 ||
                        alias === INPUT_ALIAS.wedding_6 ||
                        alias === INPUT_ALIAS.wedding_8 ||
                        alias === INPUT_ALIAS.wedding_7 ||
                        alias === INPUT_ALIAS.wedding_9 ||
                        alias === INPUT_ALIAS.wedding_12 ||
                        alias === INPUT_ALIAS.wedding_13 ||
                        alias === INPUT_ALIAS.wedding_14 ||
                        alias === INPUT_ALIAS.wedding_15 ||
                        alias === INPUT_ALIAS.wedding_10,
                },
                {
                    path: '/:urlShare/story',
                    icon: <FaGratipay className="size-8" />,
                    label: 'Câu chuyện',
                    des: 'Ghi lại câu chuyện tình yêu của cô dâu và chú rể.',
                    hidden: alias === INPUT_ALIAS.wedding_4,
                    top: false,
                    management: false,
                    infor: true,
                    index: 8,
                    keyQues: 'story',
                },
                {
                    path: '/:eventID/greetings',
                    icon: <FaMailBulk className="size-8" />,
                    label: 'Quản lý lời chúc',
                    des: 'Quản lý lời chúc từ bạn bè người thân dành tặng cho bạn',
                    notify: true,
                    top: false,
                    management: false,
                    infor: true,
                    index: 9,
                    keyQues: 'greeting',
                },

                // {
                //     path: '/:urlShare/bank',
                //     icon: <FaCreditCard className="w-8 h-8" />,
                //     label: 'Tài khoản',
                // },
            ]);
            // if (alias === INPUT_ALIAS.wedding_3) {
            //     listData = listData.concat([
            //         {
            //             path: '/:eventID/greetings',
            //             icon: <FaMailBulk className="w-8 h-8" />,
            //             label: 'Quản lý lời chúc',
            //             des: 'Quản lý lời chúc từ bạn bè người thân dành tặng cho bạn',
            //         },
            //     ]);
            // }

            break;
        case INPUT_ALIAS.event_1:
        case INPUT_ALIAS.event_2:
        case INPUT_ALIAS.event_3:
        case INPUT_ALIAS.event_4:
        case INPUT_ALIAS.event_5:
        case INPUT_ALIAS.event_6:
        case INPUT_ALIAS.event_7:
        case INPUT_ALIAS.event_8:
        case INPUT_ALIAS.event_9:
        case INPUT_ALIAS.event_11:
        case INPUT_ALIAS.event_13:
        case INPUT_ALIAS.event_12:
            listData = listData.concat([
                {
                    path: '/:urlShare/summary',
                    icon: <FaHouseUser className="size-8" />,
                    label: 'Thông tin chung',
                    top: true,
                    management: false,
                    infor: true,
                    des: 'Tên sự kiện, Logo.',
                    index: 1,
                    keyQues: 'main_info_ev',
                },

                {
                    path: '/:urlShare/content',
                    icon: <FaMapSigns className="size-8" />,
                    label: 'Nội dung chương trình',
                    top: true,
                    management: false,
                    infor: true,
                    des: 'Tên, thời lượng, địa điểm diễn ra chương trình.',
                    index: 2,
                    keyQues: 'events',
                },

                {
                    path: '/:eventID/dress',
                    icon: <FaAddressCard className="size-8" />,
                    label: 'Trang phục',
                    des: 'Sử dụng trang phục khi tham dự sự kiện',
                    top: false,
                    management: false,
                    infor: true,
                    index: 4,
                    hidden: alias === INPUT_ALIAS.event_4 || alias === INPUT_ALIAS.event_5,
                },
                {
                    path: '/:eventID/sponsor',
                    icon: <FaAddressCard className="size-8" />,
                    label: 'Nhà đồng hành',
                    des: 'Danh sách nhà đồng hành cùng sự kiện',
                    top: false,
                    management: false,
                    infor: true,
                    index: 5,
                    hidden:
                        alias === INPUT_ALIAS.event_6 ||
                        alias === INPUT_ALIAS.event_3 ||
                        alias === INPUT_ALIAS.event_7 ||
                        alias === INPUT_ALIAS.event_13,
                },
                {
                    path: '/:urlShare/subinfo',
                    icon: <FaInfoCircle className="size-8" />,
                    label: 'Thông tin khác',
                    top: false,
                    management: false,
                    infor: true,
                    des: 'Thời gian, địa điểm, người hỗ trợ.',
                    index: 3,
                    keyQues: 'sub_info_ev',
                },
            ]);
            break;
        default:
            break;
    }
    listData = listData.concat([
        {
            path: '/:eventID/sub-event',
            icon: <BsPostcardHeartFill className="size-8" />,
            label: 'Thiệp mời dạng ảnh',
            des: 'Tải xuống, chia sẻ hình ảnh, QRCode website của thiệp mời.',
            mobileHidden: true,
            top: false,
            management: true,
            infor: false,
            index: 13,
            keyQues: 'sub',
        },
        {
            path: '/:eventID/guest-manage',
            icon: <FaUsers className="size-8" />,
            label: 'Quản lý khách mời',
            des: 'Tạo, quản lý và theo dõi thông tin danh sách khách mời.',
            top: true,
            management: true,
            infor: false,
            index: 10,
            keyQues: 'guest',
        },
        {
            path: '/:eventID/config',
            icon: <FaCog className="size-8" />,
            label: 'Cấu hình thiệp',
            des: 'Thay đổi trạng thái, cài đặt, xóa thiệp mời.',
            top: true,
            management: true,
            infor: false,
            index: 11,
            keyQues: 'config',
        },
        {
            path: '/su-kien/:eventID',
            icon: <FaChartLine className="size-8" />,
            label: 'Thống kê truy cập',
            des: 'Thống kê lượt truy cập website thiệp theo thời gian.',
            top: false,
            management: true,
            infor: false,
            index: 12,
            keyQues: 'static',
        },
        // {
        //     path: '/:eventID/table-sort',
        //     icon: <MdTableBar className="size-8" />,
        //     label: 'Sắp xếp bàn tiệc',
        //     des: 'Bố trí và sắp xếp bàn tiệc cho sự kiện.',
        //     top: false,
        //     management: false,
        //     infor: true,
        //     index: 13,
        //     keyQues: 'table',
        // },
        // {
        //     path: '/:eventID/greetings',
        //     icon: <FaMailBulk className="w-8 h-8" />,
        //     label: 'Quản lý lời chúc',
        //     des: 'Quản lý lời chúc từ bạn bè người thân dành tặng cho bạn',
        // },
    ]);

    return listData;
};
