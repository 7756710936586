/* eslint-disable */

import { FaEnvelopeOpenText, FaTimesCircle } from 'react-icons/fa';
import PAGE from '../../../config/path.route';
import envConstants from '../../../config/key';

function calculateTotalPrice(arr) {
    let totalPrice = 0;
    arr?.forEach((item) => {
        totalPrice += item?.price * item?.quantity;
    });
    return totalPrice;
}

export default function ModalStatusConfirm({ isOpen, close, success, detail }) {
    return (
        <div
            className={`fade modal fixed left-0 top-0 z-[1056]  h-full w-full overflow-y-auto overflow-x-hidden outline-none ${
                isOpen ? 'show' : ''
            } backdrop-brightness-50`}
            tabIndex="-1"
            style={{
                margin: 0,
                visibility: isOpen ? 'visible' : 'hidden',
                transition: 'opacity 0.15s linear, visibility 0.5s linear',
            }}
            aria-modal="true"
            role="dialog"
        >
            <div className="modal-dialog modal-dialog-centered pointer-events-none relative w-auto">
                <div className="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
                    <div className="modal-header flex flex-col shrink-0 items-start justify-between rounded-t-md  p-4 px-10">
                        {success ? (
                            <FaEnvelopeOpenText className="text-violet-500 text-[80px] mx-auto mb-3" />
                        ) : (
                            <FaTimesCircle className="text-red-600 text-[80px] mx-auto mb-3" />
                        )}
                        <h5 className="text-xl leading-normal text-gray-800 font-bold text-center mt-5 w-full">
                            {success
                                ? 'Xác nhận đơn hàng đã được thanh toán'
                                : 'Đã có lỗi xảy ra vui lòng thử lại sau.'}
                        </h5>
                        <p className="text-left mt-5 text-gray-600 text-sm">
                            {success
                                ? 'Cảm ơn bạn đã xác nhận đơn hàng đã được thanh toán. Chúng tôi sẽ kiểm tra và hoàn thành đơn hàng của bạn trong thời gian sớm nhất'
                                : 'Chúng tôi xin lỗi vì sự cố ngoài ý muốn đã xảy ra, bạn vui lòng thử lại sau ít phút'}
                        </p>
                        <p className="text-black text-left mt-4 font-bold">
                            Mã đơn hàng:{' '}
                            <span className="text-blue-500">
                                {detail?.order_alias_string +
                                    detail?.order_alias_number?.toString()?.padStart(5, '0')}
                            </span>
                        </p>
                        <p className="text-black text-left mt-1 font-bold">
                            Số tiền:{' '}
                            <span className="text-blue-500">
                                {new Intl.NumberFormat('vi-VN').format(
                                    calculateTotalPrice(detail?.detail)
                                )}{' '}
                                VNĐ
                            </span>
                        </p>
                        {success ? (
                            <p className="text-gray-600 text-left mt-4 text-[12px]">
                                Nếu đơn hàng chưa kịp xử lý hoặc bạn muốn ưu tiên xử lý đơn hàng,
                                vui lòng{' '}
                                <a
                                    href={envConstants.CLIENT_URL + PAGE.aboutus.replace('/', '')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="cursor-pointer text-blue-500 underline font-bold text-center text-[14px] mt-5"
                                >
                                    liên hệ
                                </a>{' '}
                                với chúng tôi để được hỗ trợ sớm nhất.
                            </p>
                        ) : (
                            <p className="text-gray-600 text-left mt-4 text-[12px]">
                                Báo cáo sự cố{' '}
                                <a
                                    href={envConstants.CLIENT_URL + PAGE.aboutus.replace('/', '')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="cursor-pointer text-blue-500 underline font-bold text-center text-[14px] mt-5"
                                >
                                    tại đây
                                </a>
                            </p>
                        )}
                    </div>
                    <div className="modal-footer flex shrink-0 flex-wrap items-center justify-center rounded-b-md p-4 pt-0">
                        <button
                            type="button"
                            onClick={close}
                            className="ml-1 inline-block rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            Xác nhận
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
