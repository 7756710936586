/* eslint-disable */
import BLANK from './blank.jpg';
import CO_DAU_4 from './codau.png';
import CHU_RE_4 from './chure.png';
import FEATURE_1 from './bg_service_1.png';
import FEATURE_2 from './bg_service_2.png';
import FEATURE_3 from './bg_service_3.png';
import FEATURE_4 from './bg_service_4.png';
import PLAN_FLY from './plan.webp';
import BG from './bg.jpg';
import IPHONE_IMAGE from './iphone_image.png';
import TIMETABLE from './timetable.png';
import ALBUM from './album.jpg';
import SLIDER from './slider.jpg';
import STORY from './story.jpg';
import VIDEO_LANDING_WEBM from './video-landingpage.webm';
import NOT_FOUND_EVENT from './not-found-event.png';
import TITLE_SHORTCUT_TIMELINE from './title_shortcut_timeline.png';
import PAGE_NOT_FOUND from './404.jpg';
import GREETING_NOT_FOUND from './greeting_not_found.png';
import LOGO_WEDDING from './logo_wedding_chungvui.png';
import LOGO_EVENT from './logo_event_chungvui.png';
import GUEST_MANAGER from './guest_manager.jpg';
import CHART_EMPTY_DATA from './chart_empty_data.png';
import CO_DAU_3 from './co_dau_3.png';
import CHU_RE_3 from './chu_re_3.png';
import SPONSOR from './sponsor.png';

const IMAGES = {
    CHU_RE_3,
    CO_DAU_3,
    CHU_RE_4,
    CO_DAU_4,
    FEATURE_1,
    FEATURE_2,
    FEATURE_3,
    FEATURE_4,
    BLANK,
    PLAN_FLY,
    BG,
    IPHONE_IMAGE,
    VIDEO_LANDING_WEBM,
    NOT_FOUND_EVENT,
    TIMETABLE,
    ALBUM,
    SLIDER,
    TITLE_SHORTCUT_TIMELINE,
    PAGE_NOT_FOUND,
    GREETING_NOT_FOUND,
	STORY,
    LOGO_WEDDING,
    LOGO_EVENT,
    GUEST_MANAGER,
    CHART_EMPTY_DATA,
    SPONSOR,
};

export default IMAGES;
