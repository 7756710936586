/* eslint-disable */
/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// import IMAGES_WEDDING from '../../resource/wedding/images/_index';
import PublishGroomService from './PublishGroom.service';
import BaseModal from '../../../../components/BaseModal';
// import Templates from '../events/components/Templates';
import IMAGES from '../../../../assets/images/_index';
import { useParams, useNavigate, Link } from 'react-router-dom';
// import themeCommon from '../../PublishMainMenu.config';
// import { setContentMeta } from '../../utils/MetaTag';
import { Helmet } from 'react-helmet-async';
import { Cropper } from 'react-mobile-cropper';
import ToastNotify from '../../../../utils/Toast';
import ModalLeavingPage from '../../../events/components/ModalLeavingPage';

// import { CropperCanvas } from 'react-advanced-cropper';
import 'react-mobile-cropper/dist/style.css';
// import Banks from '../../../../resource/wedding/_input/components/Banks';

import PAGE from '../../../../config/path.route';
import {
    // FaBirthdayCake,
    // FaInfoCircle,
    // FaUserCircle,
    // FaMinusCircle,
    // FaPlusCircle,
    FaTiktok,
    FaFacebook,
    FaImage,
    FaExternalLinkAlt,
    FaChartPie,
    FaTimes,
    FaSave
    // FaCheck,
    // FaTimes,
} from 'react-icons/fa';
import EventsService from '../../../events/Events.service';
import NAME_BANK from '../../../../resource/wedding/constant/key.bank';
import { validateSizeOfFile } from '../../../../utils/common';
import LoadingButtonIcon from '../../../../components/LoadingButton/LoadingButtonIcon';
import envConstants from '../../../../config/key';
import ImageQRUploader, { TYPE } from '../publish.bride/components/ImageQRUploader';
import AvatarUploader, { TYPE_NAME } from '../publish.bride/components/AvatarUploader';
import ShortcutMenu from '../../components/ShortcutMenu';
import Products from '../publish.news/products/Products';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import vi from 'date-fns/locale/vi';
import 'react-datepicker/dist/react-datepicker.css';
import TimelineShortcutMenu from '../../components/TimelineShortcutMenu';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import QuestionTitle from '../publish.question/QuestionTitle';
import { checkTypeTemplate, limitString } from '../publish.greeting/PublishGreeting.page';
import { useSelector } from 'react-redux';

const parseJsonData = (formData) => {
    // parse json
    const dataCopy = formData;
    let arrParse = [];
    let arrCopy = [];
    let keyName = '';
    // infoParty
    if (dataCopy?.infoParty) {
        arrCopy = dataCopy.infoParty;
        keyName = 'infoParty';
    } else {
        //  programes
        arrCopy = dataCopy.programes;
        keyName = 'programes';
    }
    if (Array.isArray(arrCopy)) {
        for (var idx = 0; idx < arrCopy.length; idx++) {
            if (typeof arrCopy[idx] === 'string') {
                arrParse.push(JSON.parse(arrCopy[idx]));
            } else {
                arrParse.push(arrCopy[idx]);
            }
        }
    } else {
        if (arrCopy) {
            arrParse.push(JSON.parse(arrCopy));
        }
    }
    return { ...dataCopy, [keyName]: arrParse };
};

const CropperModal = ({
    defaultsrc,
    eventId,
    eventUrlSlug,
    load,
    setLoad,
    setOpen,
    name,
    tempImage,
}) => {
    const cropperRef = useRef(null);
    const [image, setImage] = useState(defaultsrc ? defaultsrc : tempImage);
    const [isOpenModal, setOpenModal] = useState(false);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const toastLoadingId = useRef(null);

    const submitHandle = async () => {
        setIsLoadingBtn(true);
        const file = await PublishGroomService.createFile(
            cropperRef.current.getCanvas()?.toDataURL('image/jpeg', 1), // convert sang jpg để load nhanh hơn, nhưng khi lưu thì để nguyên ảnh gốc
            'img_' + name
        );
        setIsLoadingBtn(false);
        if (
            validateSizeOfFile(
                file,
                10,
                'Dung lượng ảnh sau khi chỉnh sửa không được vượt quá 10Mb'
            )
        ) {
            setOpenModal(true);
        }
    };
    const changeFile = (event) => {
        const val = event.target.files[0];
        event.target.value = '';
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setImage(image);
            // setCroppedImageGroom(image)
        });
        reader.readAsDataURL(val);
    };
    const submitImageForm = async () => {
        toastLoadingId.current = ToastNotify.Loading('Đang lưu ảnh...');
        const file = await PublishGroomService.createFile(
            cropperRef.current.getCanvas()?.toDataURL(),
            'img_' + name
        );
        let index = null;
        let keyChild = null;
        if (name === 'bank') {
            index = '1';
            keyChild = 'imageQR';
        }
        PublishGroomService.saveFiles(eventId, name, file, index, keyChild)
            .then(() => ToastNotify.Update(toastLoadingId.current, 'Lưu ảnh thành công!'))
            .catch((error) =>
                ToastNotify.Update(
                    toastLoadingId.current,
                    error?.message || 'Đã có lỗi xảy ra khi lưu ảnh!',
                    'error'
                )
            )
            .finally(() => {
                setLoad(!load);
                setOpenModal(false);
                setOpen(false);
                toastLoadingId.current = null;
            });
        // if (eventUrlSlug) {
        //     screenShot(eventId, eventUrlSlug);
        // }
    };
    return (
        <div className="mx-1.5 appearance-none justify-center rounded-md border-2 border-dashed border-gray-300 bg-white px-2 pt-2 transition  hover:border-gray-400 focus:outline-none">
            <Cropper
                stencilProps={{
                    aspectRatio: 1,
                }}
                ref={cropperRef}
                style={{ height: '500px', width: '500px' }}
                className="cropper h-[500px]"
                src={image}
            />
            <div className="m-2 flex justify-center gap-2">
                <label
                    className="flex gap-[6px] items-center rounded-lg bg-blue-100 px-[16px] py-[8px] text-[16px] font-semibold text-blue-700 hover:bg-blue-200 cursor-pointer"
                // onClick={() => btnRemoveImagePreview('avatarGroom')}
                >
                    <input
                        className="hidden"
                        onChange={(event) => changeFile(event)}
                        type="file"
                        accept="image/*"
                    />
                    Thay đổi
                </label>
                <button
                    className={`${isLoadingBtn ? 'bg-blue-500' : 'bg-blue-600 hover:bg-blue-700 '
                        } flex gap-[6px] items-center rounded-lg  px-[16px] py-[8px] text-[16px] font-semibold text-white `}
                    onClick={() => submitHandle('avatarGroom')}
                    disabled={isLoadingBtn}
                >
                    {isLoadingBtn && <LoadingButtonIcon />}
                    Lưu
                </button>
                <ModalLeavingPage
                    saveChanged={submitImageForm}
                    isOpen={isOpenModal}
                    confirmNavigation={() => {
                        setOpenModal(false);
                    }}
                    funToggle={() => {
                        setOpenModal(false);
                    }}
                />
            </div>
        </div>
    );
};
const maxlength = 20;
const max2length = 300;
const regexStr = /^[^\\(\\)~`#$%^&*=|\?\/<>\\*]+$/g;
const regexStrNum = /^[^\\(\\)~`#$%^&*=0-9|\?\/<>\\*]+$/g;
const regexPhoneNumber = /([\+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/g;
const schema = yup.object().shape({
    nameGroom: yup
        .string()
        .trim()
        .required('Vui lòng nhập tên!'),
    // .max(maxlength, 'Tên không vượt quá 20 ký tự!'),
    levelFatherGroom: yup
        .string()
        .trim()
        .matches(regexStrNum, {
            excludeEmptyString: true,
            message: 'Vui lòng nhập danh sưng hợp lệ!',
        })
        .max(20, 'Không được vượt quá 20 ký tự!')
        .ensure()
        .when('fatherGroom', {
            is: (val) => { return val && val?.length > 0 },
            then: yup.string().required('Vui lòng nhập danh sưng!')
        }),
    fatherGroom: yup
        .string()
        .trim()
        .matches(regexStrNum, {
            excludeEmptyString: true,
            message: 'Vui lòng nhập tên hợp lệ!',
        })
        .max(50, 'Tên không vượt quá 50 ký tự!')
        .when('levelFatherGroom', {
            is: (val) => { return val && val?.length > 0 },
            then: yup.string().required('Vui lòng nhập tên!'),
            otherwise: yup.string().optional(),
        }),
    levelMotherGroom: yup
        .string()
        .trim()
        .matches(regexStrNum, {
            excludeEmptyString: true,
            message: 'Vui lòng nhập danh sưng hợp lệ!',
        })
        .max(20, 'Không được vượt quá 20 ký tự!')
        .when('motherGroom', {
            is: (val) => { return val && val?.length > 0 },
            then: yup.string().required('Vui lòng nhập danh sưng!')
        }),
    motherGroom: yup
        .string()
        .trim()
        .matches(regexStrNum, {
            excludeEmptyString: true,
            message: 'Vui lòng nhập tên hợp lệ!',
        })
        .max(50, 'Tên không vượt quá 50 ký tự!')
        .when('levelMotherGroom', {
            is: (val) => { return val && val?.length > 0 },
            then: yup.string().required('Vui lòng nhập tên!')
        }),
    addressGroom: yup
        .string()
        .trim()
        .matches(regexStr, {
            excludeEmptyString: true,
            message: 'Vui lòng nhập địa chỉ hợp lệ!',
        })
        .max(200, 'Địa chỉ không vượt quá 200 ký tự!'),
    summaryGroom: yup
        .string()
        .trim()
        // .required('Chưa nhập lời giới thiệu')
        .max(max2length, 'Giới thiệu không vượt quá 300 ký tự !'),
    linkFbGroom: yup.string().trim().url('Đường link không đúng!'),
    linkZaloGroom: yup.string().trim().url('Đường link không đúng!'),
    linkTiktokGroom: yup.string().trim().url('Đường link không đúng!'),
    phoneNumberGroom: yup
        .string()
        .trim()
        .matches(regexPhoneNumber, {
            excludeEmptyString: true,
            message: 'Số điện thoại phải chứa các chữ số (0-9) và không vượt quá 12 chữ số!',
        })
        .max(12, 'Số điện thoại không vượt quá 12 chữ số!'),
}, [['fatherGroom', 'levelFatherGroom'], ['motherGroom', 'levelMotherGroom']]);
export default function PublishGroom() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        // watch
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
        reValidateMode: 'onChange',
    });
    const listBank = Object.entries(NAME_BANK);
    const navigate = useNavigate();
    const { eventId, url_guest: urlGuest } = useParams();
    const [formData, setFormData] = useState({});
    const [templateAlias, setTemplateAlias] = useState('');
    const [normalObj, setNormalObj] = useState({});
    const [imageToCropGroom, setImageToCropGroom] = useState(null);
    const [imageToCropBank, setImageToCropBank] = useState(null);
    const [tmpimageToCropBank, setTmpImageToCropBank] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [deleteImgBank, setDeleteImgBank] = useState(false);
    const [openBankModal, setOpenBankModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [load, setLoad] = useState(false);
    const [isOpenLeavinModal, setOpenLeavinModal] = useState(false);
    const [isExpiredEvent, setIsExpiredEvent] = useState(false);
    const { loggedIn } = useSelector((state) => state.auth);

    const submitHandle = async () => {
        setOpenLeavinModal(true);
    };
    // const emptyString = 0;
    // const [tempImg, setTempImg] = useState(null);
    // const [editdadGroom, setEditdadGroom] = useState(false);
    // const [editmomGroom, setEditmomGroom] = useState(false);
    // const [editaddress, setEditAddress] = useState(false);

    const [valueMeta, setValueMeta] = useState({
        title: '',
        thumbnail: '',
    });
    const changeFile = (event) => {
        const val = event.target.files[0];
        event.target.value = '';
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setImageToCropGroom(image);
            // setCroppedImageGroom(image)
            setFormData({ ...formData, avatarGroom: [image] });
        });
        reader.readAsDataURL(val);
    };

    const changeBankFile = (event) => {
        const val = event.target.files[0];
        event.target.value = '';
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setTmpImageToCropBank(image);
            setOpenBankModal(true);
        });
        reader.readAsDataURL(val);
    };

    const submitForm = async () => {
        let saveData = normalObj;
        if (deleteImgBank) {
            saveData = { ...saveData, imageQRGroom: [] };
        }
        PublishGroomService.saveContent(formData._id, saveData)
            .then(() => {
                ToastNotify.Success('Lưu thành công');
                if (formData?.is_public) {
                    screenShot(eventId, formData.url_slug);
                }
            })
            .catch((err) => ToastNotify.Error(err?.message || 'Đã có lỗi xảy ra.'))
            .finally(() => {
                setLoad(true);
                setIsEdit(false);
                setDeleteImgBank(false);
            });
    };

    const onChangeInput = (event) => {
        // if (formData[event.target.name] !== event.target.value) {
        setIsEdit(true);
        // } else {
        //     setIsEdit(false);
        // }
        if (event.target.name === 'levelFatherGroom' && event.target.value.length === 0 && errors.fatherGroom?.type === 'required') {
            clearErrors('fatherGroom')
        }
        if (event.target.name === 'fatherGroom' && event.target.value.length === 0 && errors.levelFatherGroom?.type === 'required') {
            clearErrors('levelFatherGroom')
        }
        if (event.target.name === 'levelMotherGroom' && event.target.value.length === 0 && errors.motherGroom?.type === 'required') {
            clearErrors('motherGroom')
        }
        if (event.target.name === 'motherGroom' && event.target.value.length === 0 && errors.levelMotherGroom?.type === 'required') {
            clearErrors('levelMotherGroom')
        }
        setNormalObj({ ...normalObj, [event.target.name]: event.target.value });
    };
    useEffect(() => {
        if (eventId) {
            // const payload = { slug: eventId };
            // if (urlGuest) {
            //     payload.guest = urlGuest;
            // }
            // PublishGroomService.getPublic(payload)
            EventsService.getOneEventById(eventId)
                .then(({ data }) => {
                    //     const { templateAlias, formData, guest, title, thumbnail, data, eventConfig
                    // } =
                    //         res;
                    setIsEdit(false);
                    const title = data.form_data.titleMeta || data.name;
                    const thumbnail = data.thumbnail_url;
                    const templateAlias = data.template.alias;
                    const formData = data.form_data;
                    const url_slug = data.url_slug;
                    // const jsonData = parseJsonData({ ...data, ...formData });
                    // setContentMeta({ title, image: thumbnail })
                    setValueMeta({
                        title,
                        thumbnail,
                        url_slug,
                    });
                    setIsExpiredEvent(data?.isExpired);
                    setTemplateAlias(templateAlias);
                    // setGuest(guest);
                    const jsonData = parseJsonData({ ...data, ...formData });
                    const bankObj = jsonData.bank.find((item) => {
                        return item.id === TYPE.Groom.id;
                    });
                    setNormalObj({
                        nameGroom: jsonData.nameGroom,
                        birthdayGroom: jsonData.birthdayGroom,
                        summaryGroom: jsonData.summaryGroom,
                        phoneNumberGroom: jsonData?.phoneNumberGroom,
                        levelFatherGroom: jsonData.levelFatherGroom,
                        fatherGroom: jsonData.fatherGroom,
                        levelMotherGroom: jsonData.levelMotherGroom,
                        motherGroom: jsonData.motherGroom,
                        addressGroom: jsonData.addressGroom,
                        linkTiktokGroom: jsonData.linkTiktokGroom,
                        linkZaloGroom: jsonData.linkZaloGroom,
                        linkFbGroom: jsonData.linkFbGroom,
                        // nameAccountGroom: jsonData?.nameAccountGroom,
                        // nameBankGroom: jsonData?.nameBankGroom,
                        // numberAccountGroom: jsonData?.numberAccountGroom,
                        nameAccountGroom: bankObj?.nameAccount || '',
                        nameBankGroom: bankObj?.nameBank || '',
                        numberAccountGroom: bankObj?.numberAccount || '',
                    });
                    setValue('nameGroom', jsonData.nameGroom);
                    setValue('linkTiktokGroom', jsonData.nameEvent);
                    setValue('summaryGroom', jsonData.summaryGroom);
                    setValue('phoneNumberGroom', jsonData?.phoneNumberGroom);
                    setValue('linkZaloGroom', jsonData.linkZaloGroom);
                    setValue('linkFbGroom', jsonData.linkFbGroom);
                    setFormData(parseJsonData({ ...data, ...formData }));
                    const emptyString = 0;
                    if (parseJsonData({ ...data, ...formData }).avatarGroom.length > emptyString) {
                        setImageToCropGroom(
                            process.env.REACT_APP_SERVER_URL +
                            parseJsonData({ ...data, ...formData }).avatarGroom
                        );
                    } else {
                        setImageToCropGroom(null);
                    }
                    if (
                        // parseJsonData({ ...data, ...formData }).imageBankGroom.length > emptyString
                        parseJsonData({ ...data, ...formData })?.bank[0]?.imageQR?.length >
                        emptyString
                    ) {
                        setImageToCropBank(
                            process.env.REACT_APP_SERVER_URL +
                            // parseJsonData({ ...data, ...formData }).imageBankGroom
                            parseJsonData({ ...data, ...formData }).bank[0].imageQR
                        );
                    } else {
                        setImageToCropBank(null);
                    }
                    // setEventConfig(eventConfig);
                })
                .catch((error) => {
                    const AuthorizeError = 404;
                    if (error.status === AuthorizeError) {
                        navigate(PAGE.dashboard);
                    }
                    const ServerError = 500;
                    if (error.status === ServerError) {
                        navigate(PAGE.dashboard);
                    }
                });
        }
    }, [eventId, urlGuest, navigate, templateAlias, setValue, load]);

    const handleDateChange = (date) => {
        setIsEdit(true);
        setNormalObj({ ...normalObj, ['birthdayGroom']: date ? date.toISOString().slice(0, 10) : '' });
    }

    const handleDeleteQR = () => {
        setIsEdit(true);
        setNormalObj({ ...normalObj, imageQRGroom: [] });
        setDeleteImgBank(true);
    }

    useEffect(() => {
        if (!loggedIn) {
            const beforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = true;
            };
            window.addEventListener('beforeunload', beforeUnload);
            return () => {
                window.removeEventListener('beforeunload', beforeUnload);
            };
        }
    }, []);

    return (
        <div>
            <Helmet>
                <title>{'Chú rể' + ' | ' + valueMeta.title}</title>
                <meta property="og:image" content={valueMeta.thumbnail} />
            </Helmet>
            <div className={'h-[100dvh] w-[100vw]  bg-gray-50 text-black'}>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        handleSubmit(submitHandle)(event).catch((err) => {
                            console.log('handleSubmit err = ', err);
                        });
                    }}
                    noValidate={true}
                >
                    <div className="sticky top-0 z-[40] bg-white md:bg-gray-50 md:px-4 lg:px-6">
                        <div className="relative flex w-full flex-wrap items-center justify-between gap-x-3 p-4 md:px-0 lg:flex-nowrap lg:gap-4 lg:py-5">
                            <div className="flex md:flex-row flex-col md:gap-2">
                                <button
                                    className="flex items-center"
                                    type="button"
                                    onClick={() => {
                                        navigate(`/${eventId}/mainmenu`);
                                    }}
                                    title="Trở về"
                                >
                                    <i className=" fas fa-chevron-left"></i>
                                    <p className="order-1 ml-3 font-bold text-black">
                                        {limitString(formData?.name)}
                                    </p>
                                </button>
                                {
                                    isExpiredEvent &&
                                    <div className='h-5 rounded-[4px]  flex justify-center items-center bg-red-500 px-3 w-fit'>
                                        <span className='text-[9px] text-white uppercase'>
                                            {checkTypeTemplate(templateAlias)}
                                        </span>
                                    </div>
                                }
                                {
                                    !loggedIn &&
                                    <div className='h-5 rounded-[4px]  flex justify-center items-center bg-red-500 px-3 w-fit'>
                                        <span className='text-[9px] text-white uppercase'>
                                            Bạn chưa đăng nhập
                                        </span>
                                    </div>
                                }
                            </div>
                            <div className="flex justify-center gap-2 overflow-hidden ">
                                {
                                    loggedIn &&
                                    <Link
                                        // target="_blank"
                                        className="inline-flex select-none gap-2 rounded-md bg-white border border-blue-500 px-3 py-1 text-center font-medium text-blue-500 transition-all duration-150 ease-linear hover:bg-blue-100 xl:px-4 text-sm"
                                        to={`..${PAGE.profile}`}
                                        state={{ isResourcePackage: true }}
                                    >
                                        Quản lý gói dịch vụ
                                        <FaChartPie className="my-auto" />
                                    </Link>
                                }
                                <button className="inline-flex w-max select-none rounded-md bg-blue-500 px-3 py-1 text-center font-medium text-white transition-all duration-150 ease-linear hover:bg-blue-600 xl:px-4 text-sm">
                                    <Link
                                        target="_blank"
                                        className="flex gap-2"
                                        to={'../' + valueMeta?.url_slug}
                                    >
                                        Xem thiệp{' '}
                                        <FaExternalLinkAlt className="my-auto" />
                                    </Link>
                                </button>
                            </div>
                        </div>
                        <div className="w-full border-b border-gray-300"></div>
                    </div>

                    <div className="h-full overflow-x-hidden px-[1rem] py-[1rem] ">
                        <div className="w-full flex justify-evenly">
                            <div className="lg:flex hidden relative w-[250px]">
                                <TimelineShortcutMenu screen={'groom'} isEdit={isEdit} />
                            </div>
                            <div className="border border-gray-200 rounded-md flex w-full lg:w-[48rem] 2xl:w-[60rem] flex-col gap-1 p-2 px-3">
                                <p className='font-bold text-black text-lg '>
                                    Chú rể
                                </p>
                                <QuestionTitle keyQues={'groom'} />
                                <div className="flex w-full  flex-col gap-5 lg:flex-row">
                                    <div className="relative inline-flex h-full w-full shrink-0 flex-col items-start justify-start rounded-lg bg-white shadow md:h-fit md:max-h-fit lg:w-80">
                                        {/* <div className="relative flex flex-col items-center justify-start gap-2 self-stretch rounded-t-lg bg-gray-300 ">
                                        <div className="group relative mx-auto h-60 w-full rounded-t-lg">
                                            <img
                                                src={imageToCropGroom ?? IMAGES.CHU_RE_4}
                                                className="mx-auto h-60 w-full rounded-t-lg object-cover"
                                                alt="Chú rể"
                                            />
                                            <div
                                                className="absolute -top-0 flex h-60 w-full flex-col items-center justify-center bg-black/30 opacity-0 backdrop-blur-sm duration-500 ease-out group-hover:opacity-100"
                                                onClick={() => {
                                                    setOpenModal(true);
                                                }}
                                            >
                                                <span className="fas fa-edit cursor-pointer text-3xl text-slate-100"></span>
                                            </div>
                                        </div>
                                        <div className="absolute bottom-0 flex h-20 w-full bg-gradient-to-b from-transparent via-slate-100 to-white">
                                            <p className="absolute bottom-0 my-auto mb-4 ml-2 flex justify-between gap-5 text-xl font-semibold text-black"></p>
                                        </div>
                                    </div> */}
                                        <AvatarUploader
                                            eventId={eventId}
                                            type={TYPE_NAME.Groom}
                                            isExpired={isExpiredEvent}
                                        />
                                        <div className="ld:mb-0 grid w-full grid-flow-row auto-rows-max grid-cols-6 gap-2 rounded-b-lg">
                                            <div className="col-span-4 w-fit grow pl-4 text-sm font-medium text-gray-900">
                                                Tên chú rể <span className="text-red-500">(*)</span>
                                            </div>
                                            <label className="col-span-6 block px-2">
                                                <div className="flex w-full">
                                                    <div className="w-full">
                                                        <input
                                                            placeholder={`VD: "Nguyễn Văn A"`}
                                                            className="block w-full rounded-[0.375rem] border border-gray-300 bg-white disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                                            name="nameGroom"
                                                            type="text"
                                                            disabled={isExpiredEvent}
                                                            value={normalObj?.nameGroom}
                                                            onInput={onChangeInput}
                                                            {...register('nameGroom')}
                                                        />
                                                        <span className="text-xs font-medium text-red-500">
                                                            {errors.nameGroom &&
                                                                errors.nameGroom?.message}
                                                        </span>
                                                    </div>
                                                </div>
                                            </label>
                                            <hr className="col-span-6 mx-4 my-0"></hr>
                                            <div className="col-span-2 w-fit grow pl-4 text-sm font-medium text-gray-900">
                                                Ngày sinh
                                            </div>
                                            <label className="col-span-6 block px-2">
                                                <div className="flex w-full">
                                                    <div className="w-full">
                                                        {/* <input
                                                            className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white disabled:bg-gray-100  p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                                            type="date"
                                                            disabled={isExpiredEvent}
                                                            name="birthdayGroom"
                                                            value={normalObj?.birthdayGroom}
                                                            onInput={onChangeInput}
                                                        /> */}
                                                        <DatePicker
                                                            name="birthdayGroom"
                                                            selected={normalObj?.birthdayGroom}
                                                            onChange={(date) => handleDateChange(date)}
                                                            locale="vi"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText={'dd/mm/yyyy'}
                                                            disabled={isExpiredEvent}
                                                            className="block w-full rounded-[0.375rem] border border-gray-300 bg-white disabled:bg-gray-100  p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                                            showYearDropdown
                                                            yearDropdownItemNumber={100}
                                                            scrollableYearDropdown
                                                        />
                                                    </div>
                                                </div>
                                            </label>
                                            <hr className="col-span-6 mx-4 my-0"></hr>
                                            <div className="col-span-3 w-fit grow pl-4 text-sm font-medium text-gray-900">
                                                Số điện thoại
                                            </div>
                                            <label className="col-span-6 block px-2">
                                                <div className="flex w-full">
                                                    <div className="w-full">
                                                        <input
                                                            className="block w-full rounded-[0.375rem] border border-gray-300 bg-white disabled:bg-gray-100  p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                                            type="text"
                                                            name="phoneNumberGroom"
                                                            disabled={isExpiredEvent}
                                                            value={normalObj?.phoneNumberGroom}
                                                            onInput={onChangeInput}
                                                            {...register('phoneNumberGroom')}
                                                            placeholder={`VD: "0124567809"`}
                                                        />
                                                        <span className="text-xs font-medium text-red-500">
                                                            {errors.phoneNumberGroom &&
                                                                errors.phoneNumberGroom?.message}
                                                        </span>
                                                    </div>
                                                </div>
                                            </label>
                                            <hr className="col-span-6 mx-4 my-0"></hr>
                                            <div className="col-span-3 w-fit grow pl-4 text-sm font-medium text-gray-900">
                                                Lời giới thiệu
                                            </div>
                                            <label className="col-span-6 block px-2">
                                                <div className="flex w-full">
                                                    <div className="w-full">
                                                        <textarea
                                                            placeholder='VD: "Với người đàn ông, không có hạnh phúc nào lớn hơn việc đứng trước cửa nhà vào cuối ngày và biết rằng ở phía bên kia, có ai đó đang đợi tiếng bước chân của mình."'
                                                            rows={5}
                                                            className="block w-full rounded-[0.375rem] border border-gray-300 bg-white disabled:bg-gray-100  p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                                            name="summaryGroom"
                                                            disabled={isExpiredEvent}
                                                            value={normalObj?.summaryGroom}
                                                            onInput={onChangeInput}
                                                            {...register('summaryGroom')}
                                                        />
                                                        <span className="text-xs font-medium text-red-500">
                                                            {errors.summaryGroom &&
                                                                errors.summaryGroom?.message}
                                                        </span>
                                                    </div>
                                                </div>
                                            </label>
                                            <hr className="col-span-6 mx-4 my-0"></hr>
                                            <div className="col-span-2 w-fit grow pl-4 text-sm font-medium text-gray-900">
                                                Địa chỉ
                                            </div>
                                            <label className="col-span-6 block px-2">
                                                <div className="flex w-full">
                                                    <div className="w-full">
                                                        <textarea
                                                            placeholder={`VD: "Nhân Chính, Thanh Xuân, Hà Nội"`}
                                                            className="block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                                            type="text"
                                                            rows={3}
                                                            name="addressGroom"
                                                            disabled={isExpiredEvent}
                                                            value={normalObj?.addressGroom}
                                                            onInput={onChangeInput}
                                                            {...register('addressGroom')}
                                                        />
                                                        <span className="text-xs font-medium text-red-500">
                                                            {errors.addressGroom &&
                                                                errors.addressGroom?.message}
                                                        </span>
                                                    </div>
                                                </div>
                                            </label>
                                            <div className="col-span-6 pb-5"></div>
                                        </div>
                                    </div>
                                    <div className="flex w-full flex-col gap-5">
                                        <div className="mb-5 rounded-lg bg-white shadow">
                                            <div className="flex items-center justify-between border-b border-gray-200 p-4 md:p-6">
                                                <p className="mb-0 text-[16px] font-bold text-gray-900">
                                                    Thông tin Người đại diện
                                                </p>
                                            </div>
                                            <div className="flex flex-col gap-4 p-4">
                                                <div className="w-full ">
                                                    <div className="col-span-2 w-fit grow text-sm font-medium text-gray-900 pl-2">
                                                        Người đại diện 01
                                                    </div>
                                                    <label className="block w-full">
                                                        <div className="flex w-full">
                                                            <div className="w-1/4">

                                                                <input
                                                                    placeholder='VD: "Bố"'
                                                                    className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                                                    type="text"
                                                                    name="levelFatherGroom"
                                                                    disabled={isExpiredEvent}
                                                                    value={normalObj?.levelFatherGroom}
                                                                    onInput={onChangeInput}
                                                                    {...register('levelFatherGroom')}
                                                                />
                                                                <span className="text-xs font-medium text-red-500">
                                                                    {errors.levelFatherGroom &&
                                                                        errors.levelFatherGroom
                                                                            ?.message}
                                                                </span>
                                                            </div>
                                                            <span className="mx-3 mt-5 font-bold">
                                                                :
                                                            </span>
                                                            <div className="w-3/4">
                                                                <input
                                                                    placeholder={`VD: "Nguyễn Văn D"`}
                                                                    type="text"
                                                                    name="fatherGroom"
                                                                    disabled={isExpiredEvent}
                                                                    value={normalObj?.fatherGroom}
                                                                    onInput={onChangeInput}
                                                                    {...register('fatherGroom')}
                                                                    className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                                                />
                                                                <span className="text-xs font-medium text-red-500">
                                                                    {errors.fatherGroom &&
                                                                        errors.fatherGroom?.message}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="w-full ">
                                                    <div className="col-span-2 w-fit grow text-sm font-medium text-gray-900 pl-2">
                                                        Người đại diện 02
                                                    </div>
                                                    <label className="block w-full">
                                                        <div className="flex w-full">
                                                            <div className="w-1/4">
                                                                <input
                                                                    placeholder='VD: "Mẹ"'
                                                                    className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white disabled:bg-gray-100  p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                                                    type="text"
                                                                    name="levelMotherGroom"
                                                                    disabled={isExpiredEvent}
                                                                    value={normalObj?.levelMotherGroom}
                                                                    onInput={onChangeInput}
                                                                    {...register('levelMotherGroom')}
                                                                />
                                                                <span className="text-xs font-medium text-red-500">
                                                                    {errors.levelMotherGroom &&
                                                                        errors.levelMotherGroom
                                                                            ?.message}
                                                                </span>
                                                            </div>
                                                            <span className="mx-3 mt-5 font-bold">
                                                                :
                                                            </span>
                                                            <div className="w-3/4">
                                                                <input
                                                                    placeholder={`VD: "Bùi Thị E"`}
                                                                    type="text"
                                                                    name="motherGroom"
                                                                    disabled={isExpiredEvent}
                                                                    value={normalObj?.motherGroom}
                                                                    onInput={onChangeInput}
                                                                    {...register('motherGroom')}
                                                                    className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white disabled:bg-gray-100  p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                                                />
                                                                <span className="text-xs font-medium text-red-500">
                                                                    {errors.motherGroom &&
                                                                        errors.motherGroom?.message}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-5 rounded-lg bg-white shadow">
                                            <div className="flex items-center justify-between border-b border-gray-200 p-4 md:p-6">
                                                <p className="mb-0 text-[16px] font-bold text-gray-900">
                                                    Thông tin Mạng xã hội
                                                </p>
                                            </div>
                                            <div className="flex flex-col gap-4 p-4">
                                                <div className="w-full ">
                                                    <label className="block w-full">
                                                        <div className="relative mt-1 w-full flex-1 md:w-auto">
                                                            <span className="absolute top-0 z-10 mx-auto py-3 pl-2.5">
                                                                <FaTiktok className="h-5 w-5" />
                                                            </span>
                                                        </div>
                                                        <div className="w-full flex-1 md:w-auto">
                                                            <div className="relative flex rounded-md">
                                                                <input
                                                                    placeholder='VD: "https://www.tiktok.com/"'
                                                                    className="block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100  !py-3 !px-9 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                                                    type="text"
                                                                    name="linkTiktokGroom"
                                                                    disabled={isExpiredEvent}
                                                                    value={normalObj?.linkTiktokGroom}
                                                                    onInput={onChangeInput}
                                                                    {...register('linkTiktokGroom')}
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            (normalObj?.linkTiktokGroom && !errors.linkTiktokGroom) &&
                                                            <div className="relative w-full flex-1 md:w-auto">
                                                                <a
                                                                    data-tip
                                                                    data-for='tiktok'
                                                                    className="absolute right-3 top-1/2 transform -translate-y-full z-10 mx-auto py-3 pl-2.5"
                                                                    href={normalObj?.linkTiktokGroom}
                                                                    target='_blank'>
                                                                    <FaExternalLinkAlt className="h-5 w-5 text-blue-400 cursor-pointer hover:text-blue-500" />
                                                                </a>
                                                                <ReactTooltip
                                                                    id='tiktok'
                                                                    place="top"
                                                                    type="dark"
                                                                    delayShow={100}
                                                                    className="w-max"
                                                                >
                                                                    Xem trước
                                                                </ReactTooltip>
                                                            </div>
                                                        }
                                                    </label>
                                                    <span className="text-xs font-medium text-red-500">
                                                        {errors.linkTiktokGroom &&
                                                            errors.linkTiktokGroom?.message}
                                                    </span>
                                                </div>
                                                <div className="w-full ">
                                                    <label className="block w-full">
                                                        <div className="relative w-full flex-1 md:w-auto">
                                                            <span className="absolute top-0 z-10 py-3 pl-2.5">
                                                                <FaFacebook className="h-5 w-5 text-blue-600" />
                                                            </span>
                                                        </div>
                                                        <div className="w-full flex-1 md:w-auto">
                                                            <div className="relative flex rounded-md">
                                                                <input
                                                                    placeholder='VD: "https://www.facebook.com/"'
                                                                    className="block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 !py-3 !pl-9 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                                                    type="text"
                                                                    name="linkFbGroom"
                                                                    disabled={isExpiredEvent}
                                                                    value={normalObj?.linkFbGroom}
                                                                    onInput={onChangeInput}
                                                                    {...register('linkFbGroom')}
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            (normalObj?.linkFbGroom && !errors.linkFbGroom) &&
                                                            <div className="relative w-full flex-1 md:w-auto">
                                                                <a
                                                                    data-tip
                                                                    data-for='tiktok'
                                                                    className="absolute right-3 top-1/2 transform -translate-y-full z-10 mx-auto py-3 pl-2.5"
                                                                    href={normalObj?.linkFbGroom}
                                                                    target='_blank'>
                                                                    <FaExternalLinkAlt className="h-5 w-5 text-blue-400 cursor-pointer hover:text-blue-500" />
                                                                </a>
                                                                <ReactTooltip
                                                                    id='tiktok'
                                                                    place="top"
                                                                    type="dark"
                                                                    delayShow={100}
                                                                    className="w-max"
                                                                >
                                                                    Xem trước
                                                                </ReactTooltip>
                                                            </div>
                                                        }
                                                    </label>
                                                    <span className="text-xs font-medium text-red-500">
                                                        {errors.linkFbGroom &&
                                                            errors.linkFbGroom?.message}
                                                    </span>
                                                </div>
                                                <div className="w-full ">
                                                    <label className="block w-full">
                                                        <div className="relative w-full flex-1 md:w-auto">
                                                            <span className="absolute top-0 z-10 py-3 pl-2.5">
                                                                <svg
                                                                    className="h-6 w-6 fill-current text-blue-700 "
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 448 512"
                                                                >
                                                                    <path d="M82.6 380.9c-1.8-.8-3.1-1.7-1-3.5 1.3-1 2.7-1.9 4.1-2.8 13.1-8.5 25.4-17.8 33.5-31.5 6.8-11.4 5.7-18.1-2.8-26.5C69 269.2 48.2 212.5 58.6 145.5 64.5 107.7 81.8 75 107 46.6c15.2-17.2 33.3-31.1 53.1-42.7 1.2-.7 2.9-.9 3.1-2.7-.4-1-1.1-.7-1.7-.7-33.7 0-67.4-.7-101 .2C28.3 1.7.5 26.6.6 62.3c.2 104.3 0 208.6 0 313 0 32.4 24.7 59.5 57 60.7 27.3 1.1 54.6.2 82 .1 2 .1 4 .2 6 .2H290c36 0 72 .2 108 0 33.4 0 60.5-27 60.5-60.3v-.6-58.5c0-1.4.5-2.9-.4-4.4-1.8.1-2.5 1.6-3.5 2.6-19.4 19.5-42.3 35.2-67.4 46.3-61.5 27.1-124.1 29-187.6 7.2-5.5-2-11.5-2.2-17.2-.8-8.4 2.1-16.7 4.6-25 7.1-24.4 7.6-49.3 11-74.8 6zm72.5-168.5c1.7-2.2 2.6-3.5 3.6-4.8 13.1-16.6 26.2-33.2 39.3-49.9 3.8-4.8 7.6-9.7 10-15.5 2.8-6.6-.2-12.8-7-15.2-3-.9-6.2-1.3-9.4-1.1-17.8-.1-35.7-.1-53.5 0-2.5 0-5 .3-7.4.9-5.6 1.4-9 7.1-7.6 12.8 1 3.8 4 6.8 7.8 7.7 2.4.6 4.9.9 7.4.8 10.8.1 21.7 0 32.5.1 1.2 0 2.7-.8 3.6 1-.9 1.2-1.8 2.4-2.7 3.5-15.5 19.6-30.9 39.3-46.4 58.9-3.8 4.9-5.8 10.3-3 16.3s8.5 7.1 14.3 7.5c4.6.3 9.3.1 14 .1 16.2 0 32.3.1 48.5-.1 8.6-.1 13.2-5.3 12.3-13.3-.7-6.3-5-9.6-13-9.7-14.1-.1-28.2 0-43.3 0zm116-52.6c-12.5-10.9-26.3-11.6-39.8-3.6-16.4 9.6-22.4 25.3-20.4 43.5 1.9 17 9.3 30.9 27.1 36.6 11.1 3.6 21.4 2.3 30.5-5.1 2.4-1.9 3.1-1.5 4.8.6 3.3 4.2 9 5.8 14 3.9 5-1.5 8.3-6.1 8.3-11.3.1-20 .2-40 0-60-.1-8-7.6-13.1-15.4-11.5-4.3.9-6.7 3.8-9.1 6.9zm69.3 37.1c-.4 25 20.3 43.9 46.3 41.3 23.9-2.4 39.4-20.3 38.6-45.6-.8-25-19.4-42.1-44.9-41.3-23.9.7-40.8 19.9-40 45.6zm-8.8-19.9c0-15.7.1-31.3 0-47 0-8-5.1-13-12.7-12.9-7.4.1-12.3 5.1-12.4 12.8-.1 4.7 0 9.3 0 14v79.5c0 6.2 3.8 11.6 8.8 12.9 6.9 1.9 14-2.2 15.8-9.1.3-1.2.5-2.4.4-3.7.2-15.5.1-31 .1-46.5z"></path>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <div className="w-full flex-1 md:w-auto">
                                                            <div className="relative flex rounded-md">
                                                                <input
                                                                    placeholder='VD: "https://zalo.me/"'
                                                                    className="block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 !py-3 !pl-9 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                                                    type="text"
                                                                    name="linkZaloGroom"
                                                                    disabled={isExpiredEvent}
                                                                    value={normalObj?.linkZaloGroom}
                                                                    onInput={onChangeInput}
                                                                    {...register('linkZaloGroom')}
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            (normalObj?.linkZaloGroom && !errors.linkZaloGroom) &&
                                                            <div className="relative w-full flex-1 md:w-auto">
                                                                <a
                                                                    data-tip
                                                                    data-for='tiktok'
                                                                    className="absolute right-3 top-1/2 transform -translate-y-full z-10 mx-auto py-3 pl-2.5"
                                                                    href={normalObj?.linkZaloGroom}
                                                                    target='_blank'>
                                                                    <FaExternalLinkAlt className="h-5 w-5 text-blue-400 cursor-pointer hover:text-blue-500" />
                                                                </a>
                                                                <ReactTooltip
                                                                    id='tiktok'
                                                                    place="top"
                                                                    type="dark"
                                                                    delayShow={100}
                                                                    className="w-max"
                                                                >
                                                                    Xem trước
                                                                </ReactTooltip>
                                                            </div>
                                                        }
                                                    </label>
                                                    <span className="text-xs font-medium text-red-500">
                                                        {errors.linkZaloGroom &&
                                                            errors.linkZaloGroom?.message}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex w-full flex-col gap-5">
                                            <div className="mb-5 rounded-lg bg-white shadow">
                                                <div className="flex items-center justify-between border-b border-gray-200 p-4 md:p-6">
                                                    <p className="mb-0 text-[16px] font-bold text-gray-900">
                                                        Thông tin Ngân hàng
                                                    </p>
                                                </div>
                                                <div className="flex w-full gap-4 p-4 flex-col">
                                                    <div className="-mb-2 px-2">
                                                        <QuestionTitle keyQues={'bank'} />
                                                    </div>
                                                    <div className="flex flex-col gap-4 p-4 ">
                                                        <div className="block w-full">
                                                            <span className="mb-5 text-sm font-normal">
                                                                Ngân hàng
                                                            </span>
                                                            <input
                                                                placeholder={`VD: "Momo"`}
                                                                type="text"
                                                                name="nameBankGroom"
                                                                disabled={isExpiredEvent}
                                                                value={
                                                                    normalObj?.nameBankGroom
                                                                }
                                                                onInput={onChangeInput}
                                                                className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                                            />
                                                            {/* <div className="flex w-full">
                                                                <select
                                                                    name="nameBankGroom"
                                                                    onChange={onChangeInput}
                                                                    disabled={isExpiredEvent}
                                                                    // onChange={(e) => changeInput(e, index, 'nameBank_GROUP')}

                                                                    className="mt-2 rounded-[0.375rem] peer form-select w-full border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm appearance-none font-normal  text-gray-700 transition  ease-in-out focus:outline-none"
                                                                >
                                                                    {listBank.map((item2, index2) => {
                                                                        if (
                                                                            item2[0] ===
                                                                            normalObj.nameBankGroom
                                                                        ) {
                                                                            return (
                                                                                <option
                                                                                    key={index2}
                                                                                    selected
                                                                                    value={item2[0]}
                                                                                >
                                                                                    {item2[1]}
                                                                                </option>
                                                                            );
                                                                        } else {
                                                                            return (
                                                                                <option
                                                                                    key={index2}
                                                                                    value={item2[0]}
                                                                                >
                                                                                    {item2[1]}
                                                                                </option>
                                                                            );
                                                                        }
                                                                    })}
                                                                </select>
                                                            </div> */}
                                                            {/* <span className="text-xs font-medium text-red-500">
                                                                {errors.addressCeremony &&
                                                                    errors.addressCeremony?.message}
                                                            </span> */}
                                                        </div>
                                                        <div className="block w-full">
                                                            <span className="mb-5 text-sm font-normal">
                                                                Người thụ hưởng
                                                            </span>
                                                            <div className="flex w-full">
                                                                <div className="w-full">
                                                                    <input
                                                                        placeholder={`VD: "Nguyễn Văn A"`}
                                                                        type="text"
                                                                        name="nameAccountGroom"
                                                                        disabled={isExpiredEvent}
                                                                        value={
                                                                            normalObj?.nameAccountGroom
                                                                        }
                                                                        onInput={onChangeInput}
                                                                        className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="block w-full">
                                                            <span className="mb-5 text-sm font-normal">
                                                                Số tài khoản
                                                            </span>
                                                            <div className="flex w-full">
                                                                <div className="w-full">
                                                                    <input
                                                                        placeholder={`VD: "001123456789"`}
                                                                        type="text"
                                                                        name="numberAccountGroom"
                                                                        disabled={isExpiredEvent}
                                                                        value={
                                                                            normalObj?.numberAccountGroom
                                                                        }
                                                                        onInput={onChangeInput}
                                                                        className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white  disabled:bg-gray-100 p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="block h-52 w-52 pl-4 md:h-max md:w-1/3">
                                                        <span className="mb-4 text-sm font-normal pb-1">
                                                            <p className="mx-2">Mã QR</p>
                                                        </span>
                                                        <div className="">
                                                            <ImageQRUploader
                                                                eventId={eventId}
                                                                type={TYPE.Groom}
                                                                isExpired={isExpiredEvent}
                                                                onDelete={handleDeleteQR}
                                                                setDelete={setDeleteImgBank}
                                                                isDelete={deleteImgBank}
                                                                load={load}
                                                                setLoad={setLoad}
                                                            />
                                                            {/* {!imageToCropBank && (
                                                            <label className="mx-auto px-4 md:h-[300px] w-[300px] cursor-pointer flex flex-col gap-[20px] appearance-none justify-center items-center rounded-lg border-2 border-dashed border-gray-300 bg-white transition hover:border-gray-400 focus:outline-none">
                                                                <FaImage className="text-[60px] text-gray-500" />
                                                                <span className="text-violet-500 text-[16px] font-semibold">
                                                                    Thêm ảnh{' '}
                                                                    <span className="text-gray-400 text-[16px] font-medium">
                                                                        (Giới hạn 10Mb)
                                                                    </span>
                                                                </span>
                                                                <input
                                                                    className="hidden"
                                                                    onChange={(event) =>
                                                                        changeBankFile(event)
                                                                    }
                                                                    type="file"
                                                                    accept="image/*"
                                                                />
                                                            </label>
                                                        )}
                                                        {imageToCropBank && (
                                                            <div className="group relative mx-auto h-full w-full rounded-lg">
                                                                <img
                                                                    src={
                                                                        imageToCropBank
                                                                    }
                                                                    className="mx-auto h-full w-full rounded-lg object-cover"
                                                                    alt="Chú rể"
                                                                />
                                                                <div
                                                                    className="absolute -top-0 flex h-full w-full flex-col items-center justify-center bg-black/30 opacity-0 backdrop-blur-sm duration-500 ease-out group-hover:opacity-100"
                                                                    onClick={() => {
                                                                        setOpenBankModal(true);
                                                                    }}
                                                                >
                                                                    <span className="fas fa-edit cursor-pointer text-3xl text-slate-100"></span>
                                                                </div>
                                                                <div
                                                                    onClick={() => {
                                                                        setDeleteImgBank(true);
                                                                        setImageToCropBank(null);
                                                                        setIsEdit(true);
                                                                    }
                                                                    }
                                                                    className="absolute right-3 top-1 w-[33px] cursor-pointer rounded-full border bg-slate-100 p-2 opacity-0 text-black shadow-lg hover:bg-gray-400 group-hover:opacity-100"
                                                                >
                                                                    <svg
                                                                        width="15px"
                                                                        height="15px"
                                                                        viewBox="0 0 24 24"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fill="none"
                                                                            stroke="#000"
                                                                            strokeWidth="2"
                                                                            d="M3,3 L21,21 M3,21 L21,3"
                                                                        />
                                                                    </svg>
                                                                </div>

                                                            </div>
                                                        )} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:flex hidden relative w-[250px]">
                                <Products />
                            </div>
                        </div>
                    </div>

                    {!isExpiredEvent && (
                        <div
                            className={
                                (isEdit ? 'bottom-0 opacity-100' : '-bottom-80 opacity-0') +
                                ' fixed inset-x-0 z-[30] bg-gray-50 transition-all duration-500 md:px-6 lg:z-[40]'
                            }
                        >
                            <div className="flex items-center justify-between gap-3 border-t border-gray-300 px-6 py-4 md:px-0">
                                <p>Chưa lưu !</p>
                                <div className="flex gap-3">
                                    <button
                                        type="button"
                                        className={
                                            'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-100 px-6 py-2 text-center font-semibold text-blue-500 transition-all duration-150 ease-linear hover:bg-blue-200 '
                                        }
                                        onClick={() => {
                                            setIsEdit(false);
                                            setLoad(!load);
                                            clearErrors();
                                        }}
                                    >
                                        <FaTimes />
                                        Hủy bỏ
                                    </button>
                                    <button
                                        type="submit"
                                        className={
                                            'mx-auto items-center gap-2 inline-flex select-none rounded-md bg-blue-500 px-6 py-2 text-center font-semibold text-white transition-all duration-150 ease-linear hover:bg-blue-600 '
                                        }
                                    >
                                        <FaSave />
                                        Hoàn tất
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <ModalLeavingPage
                        saveChanged={submitForm}
                        isOpen={isOpenLeavinModal}
                        confirmNavigation={() => {
                            setOpenLeavinModal(false);
                        }}
                        funToggle={() => {
                            setOpenLeavinModal(false);
                        }}
                    />
                </form>
                <BaseModal
                    isOpen={openModal}
                    onClose={() => {
                        setOpenModal(false);
                    }}
                    modalTitle=""
                    width="max-w-[700px]"
                >
                    {!imageToCropGroom && (
                        <>
                            <label className="mt-1.5 flex h-[500px] w-full cursor-pointer appearance-none justify-center rounded-md border-2 border-dashed border-gray-300 bg-white px-4 transition hover:border-gray-400 focus:outline-none">
                                <span className="flex items-center space-x-2 py-10">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 text-gray-600"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                        />
                                    </svg>
                                    <span className="text-sm font-bold text-gray-700">
                                        Tải ảnh lên (Giới hạn 10Mb)
                                    </span>
                                </span>
                                <input
                                    className="hidden"
                                    onChange={(event) => changeFile(event)}
                                    type="file"
                                    accept="image/*"
                                />
                            </label>
                        </>
                    )}
                    {imageToCropGroom && (
                        <CropperModal
                            setOpen={setOpenModal}
                            defaultsrc={imageToCropGroom}
                            eventId={formData?._id}
                            eventUrlSlug={formData?.url_slug}
                            templateAlias={templateAlias}
                            key={formData?._id}
                            load={load}
                            setLoad={setLoad}
                            setOpenLeavinModal={setOpenLeavinModal}
                            name={'avatarGroom'}
                        />
                    )}
                </BaseModal>
                <BaseModal
                    isOpen={openBankModal}
                    onClose={() => {
                        setOpenBankModal(false);
                    }}
                    modalTitle=""
                    width="max-w-[700px]"
                >
                    {imageToCropBank && (
                        <CropperModal
                            setOpen={setOpenBankModal}
                            defaultsrc={imageToCropBank}
                            eventId={formData?._id}
                            templateAlias={templateAlias}
                            key={formData?._id}
                            load={load}
                            setLoad={setLoad}
                            tempImage={tmpimageToCropBank}
                            setOpenLeavinModal={setOpenLeavinModal}
                            // name={'imageBankGroom'}
                            name={'bank'}
                        />
                    )}
                    {!imageToCropBank && tmpimageToCropBank && (
                        <CropperModal
                            setOpen={setOpenBankModal}
                            defaultsrc={imageToCropBank}
                            eventId={formData?._id}
                            templateAlias={templateAlias}
                            key={formData?._id}
                            load={load}
                            setLoad={setLoad}
                            tempImage={tmpimageToCropBank}
                            setOpenLeavinModal={setOpenLeavinModal}
                            // name={'imageBankGroom'}
                            name={'bank'}
                        />
                    )}
                </BaseModal>
                {/* <ShortcutMenu screen={'groom'} /> */}
            </div>
        </div>
    );
}

const screenShot = (eventId, eventUrlSlug) => {
    // Screen short
    const linkPage = envConstants.CLIENT_URL + eventUrlSlug;
    const linkThumbnail = envConstants.CLIENT_URL + 'thumbnail/' + eventUrlSlug;
    const location = 'events/' + eventId;
    const promises = [
        EventsService.getScreenshot(linkPage, location),
        EventsService.getScreenshot(linkThumbnail, location),
    ];
    Promise.all(promises)
        .then((responses) => {
            const arrPathData = responses.map(({ data }) => data.path);
            const [image, thumbnail] = arrPathData;
            const payload = {
                image: image,
                thumbnail: thumbnail,
            };
            return payload;
        })
        .then((formData) => EventsService.updateEventTemplate(eventId, formData))
        .catch((error) => console.log(error));
};
