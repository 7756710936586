/* eslint-disable */
// import { Cropper } from 'react-mobile-cropper';
import Slider from 'react-slick';
import './custom_slick.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEffect, useState } from 'react';
import BaseModal from '../../../../../components/BaseModal';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="slick-arrow absolute top-1/2 -translate-x-1/2 !z-[1000] !right-0 bg-slate-100 rounded-full cursor-pointer hover:bg-slate-300"
        style={{ ...style, display: "block", width: '50px', height: '50px' }}
        onClick={onClick}
      >
        <FaAngleRight className='text-slate-700 m-auto text-xl h-full'/>
      </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="slick-arrow absolute top-1/2 -translate-x-1/2 !z-[1000] !left-[50px] bg-slate-100 rounded-full cursor-pointer hover:bg-slate-300"
        style={{ ...style, display: "block", width: '50px', height: '50px' }}
        onClick={onClick}
      >
        <FaAngleLeft className='text-slate-700 m-auto text-xl h-full'/>
      </div>
    );
  }
  

export default function ImageView({ index, albumList, open, handleClose }) {
    const renderImg = (src) => process.env.REACT_APP_SERVER_URL + src;
    const [initIndex, setInitIndex] = useState(index);
    useEffect(() => {
        if (open) {
            setInitIndex(index);
        }
    }, [index, open]);
    // const settings = {
    //     dots: true,
    //     lazyLoad: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     initialSlide: initIndex,
    //   };

    return (
        <>
            {
                open && (
                    <BaseModal
                        isOpen={open}
                        onClose={handleClose}
                        modalTitle={'Xem chi tiết ảnh'}
                        width="w-[90%]"
                    >
                        <div className="slider-container h-[80vh]">
                            <Slider
                                {...{
                                    dots: true,
                                    lazyLoad: true,
                                    infinite: true,
                                    speed: 500,
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    nextArrow: <SampleNextArrow />,
                                    prevArrow: <SamplePrevArrow />
                                }}
                                ref={(slider) => {
                                    console.log(index);
                                    slider?.slickGoTo(Number(index) ?? 0);
                                }}
                            >
                                {albumList?.map((item) => (
                                    <div className="h-[80vh] bg-black !flex items-center">
                                        <img
                                            className="max-h-[80vh] w-full object-scale-down"
                                            src={item.image}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </BaseModal>
                )
                // <div
                //     key={initIndex}
                //     className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
                //     id="modalImageViewAlbum"
                //     aria-modal={open.toString()}
                //     role="dialog"
                // >
                //     <div className="relative pointer-events-none modal-dialog modal-dialog-centered modal-xl">
                //         <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                //             <div className="flex items-center justify-between p-4 border-b border-gray-300 modal-header shrink-0 rounded-t-md">
                //                 <h5 className="text-[16px] font-bold leading-normal text-gray-800">
                //                     Xem chi tiết ảnh
                //                 </h5>
                //                 <button
                //                     type="button"
                //                     title="Đóng"
                //                     className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                //                     data-bs-dismiss="modal"
                //                     aria-label="Close"
                //                     onClick={handleClose}
                //                 ></button>
                //             </div>
                //             <div className="slider-container px-5 h-[80vh]">
                //                 <Slider {...{
                //                     dots: true,
                //                     lazyLoad: true,
                //                     infinite: true,
                //                     speed: 500,
                //                     slidesToShow: 1,
                //                     slidesToScroll: 1,
                //                 }}
                //                 ref={ slider => {
                //                     console.log(index)
                //                     slider?.slickGoTo(Number(index) ?? 0)
                //                 }}
                //                 >
                //                     {
                //                         albumList?.map(item=><div className="h-[80vh] bg-black !flex items-center">
                //                             <img className="max-h-[80vh] w-full object-scale-down" src={item.image} />
                //                         </div>)
                //                     }
                //                 </Slider>
                //             </div>
                //             {/* <div className="flex flex-col items-center justify-between  border-b border-gray-200 modal-content shrink-0 rounded-t-md p-[20px] gap-[10px] overflow-y-auto overflow-x-hidden h-[500px] md:h-[700px]">
                //                 <div className='w-full flex justify-center items-center h-[450px] md:h-[650px]'>
                //                     <img src={image} alt="Ảnh album"
                //                         className="max-h-full max-w-full object-contain"
                //                     />
                //                     <Cropper
                //                         src={image}
                //                     />
                //                 </div>
                //             </div> */}

                //             <div className="flex flex-wrap items-center justify-end p-4 modal-footer shrink-0 rounded-b-md">
                //                 <button
                //                     type="button"
                //                     className="transition-all focus:bg-gray-50 active:bg-gray-50  inline-block rounded bg-white hover:bg-gray-200 border-[1px] border-gray-300 px-5 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 duration-150 ease-in-out"
                //                     data-bs-dismiss="modal"
                //                 >
                //                     Đóng
                //                 </button>
                //             </div>
                //         </div>
                //     </div>
                // </div>
            }
        </>
    );
}
