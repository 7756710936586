import BG_2 from './bg-2.jpg';
import BG_8 from './bg-8.jpg';
import BG_CONTENT from './bg-content.jpg';
import BG_CONTENT_2 from './bg-content-2.png';
import BG_CONTENT_3 from './bg-content-3.png';
import BELL_ICON from './bell-icon.webp';
import BELL_ICON_4 from './bell-icon-4.png';
import BELL_ICON_5 from './bell-icon-5.png';
import BG_THANKYOU from './bg-thankyou.png';
import SNOW from './snow.png';
import AUDIO from './christmas.mp3';
import SILENCE from './silence.mp3';

const IMAGES_EVENT7 = {
    BG_2,
    BG_8,
    BG_CONTENT,
    BG_CONTENT_2,
    BG_CONTENT_3,
    BELL_ICON,
    BELL_ICON_4,
    BELL_ICON_5,
    BG_THANKYOU,
    SNOW,
    AUDIO,
    SILENCE,
};

export default IMAGES_EVENT7;
