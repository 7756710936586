/* eslint-disable */
import React from 'react'
import { FaCrown } from 'react-icons/fa';
import { USER_ROLE } from '../../config/constant/common.constant';

const Avatar = ({ username, avatarUrl, userRole }) => {
    let avatar ;
    if (!avatarUrl) {
        avatar = (
            <div
                className={`inline-flex overflow-hidden relative justify-center items-center w-8 h-8 bg-blue-500 rounded-full `}
            >
                <span className="font-medium text-xs text-white ">
                    {username ? username.split('').slice(0, 2).join('').toUpperCase() : 'User'}
                </span>
            </div>
        )
    } else {
        avatar = <img className="w-8 h-8 rounded-full" src={avatarUrl} alt="Avatar User" />
    }

    return <>
    <div className="relative inline-block w-9 h-9">
        <div className={`w-9 h-9 rounded-full ${userRole === USER_ROLE.VIP && 'border-2 border-[#ffd700] ring-1 ring-yellow-500'}`}>
            {avatar}
        </div>
        {
            userRole === USER_ROLE.VIP &&
            <div className="absolute top-0 right-1/2 left-1/2 w-full h-full items-center gap-1 -translate-x-1/2 -translate-y-1/2 flex rounded-full text-yellow-500 ">
                <FaCrown className='mx-auto'/>
            </div>
        }
    </div>
    </>
}

export default Avatar
