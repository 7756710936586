/* eslint-disable */
import EventTemplate from '../../../../api/event.template.api';
import envConstants from '../../../../config/key';

const PublishBrideService = {
    shareEventTemplate: async (id, data) => {
        const result = await EventTemplate.export(id, data);
        return result;
    },
    getPublic: async (payload) => {
        const { data: response } = await EventTemplate.getPublicEvent(payload);
        const { data } = response;

        // means has Guest Info
        if (data.hasOwnProperty('event') && data.hasOwnProperty('guest')) {
            const { event, guest, eventConfig } = data;

            return {
                guest,
                eventConfig,
                ev: event,
                formData: event.form_data,
                templateAlias: event.template.alias,
                title: event.title,
                thumbnail: event.thumbnail_url,
            };
        }

        const { event, eventConfig } = data;
        return {
            eventConfig,
            data: event,
            formData: event.form_data,
            templateAlias: event.template.alias,
            title: event.title,
            thumbnail: event.thumbnail_url,
        };
    },

    saveContent : async (id,data) =>{
        const objtoArr = Object.entries(data).map(x=>{
            if(x[0].includes("nameAccountBride") || x[0].includes("nameBankBride") || x[0].includes("numberAccountBride") || x[0].includes('imageQRBride')){
                return {
                    key: 'bank',
                    value: x[1],
                    keyChild: x[0].replace('Bride', ''),
                    elementId: '2'
                }
            }
            return {
                // key: `bride`,
                key: x[0],
                value: x[1]
            }
        });
        //.filter(x=>x.value != "" && x.value);
        const result = await EventTemplate.updateContent(id,objtoArr);
        return result;
    },
    createFile:  async (url, key) => {
        if (
            url === '' ||
            url === envConstants.SERVER_URL ||
            url.startsWith('data:text/html;base64')
        ) {
            return '';
        }
        // if(url.startsWith(envConstants.SERVER_URL)){
        //     return url.replace(envConstants.SERVER_URL, '')
        // }
        let response = await fetch(url);
        let data = await response.blob();
        return new File([data], key + '_create_image.jpg', { type: 'image/jpeg' });
    },
    saveFiles : async (id, data, files, index, keyChild) =>{
        
        const bodyFormData = new FormData();
        bodyFormData.append('key', data);
        if(index && keyChild){
            bodyFormData.append('elementId', index);
            bodyFormData.append('keyChild', keyChild);
        }
        bodyFormData.append('value',files);  
        const result = await EventTemplate.updateFile(id,bodyFormData);
        return result;
        // console.log(result);

    }
};

export default PublishBrideService;
