/* eslint-disable */
import INPUT_ALIAS from '../../config/templates/input.templates';
import { lazy } from 'react';

const Wedding1 = lazy(() => import('../../resource/wedding/wedding-1/Wedding1.page'));
const Wedding2 = lazy(() => import('../../resource/wedding/wedding-2/Wedding2.page'));
const Wedding3 = lazy(() => import('../wedding/wedding-3/Wedding3.page'));
const Wedding4 = lazy(() => import('../../resource/wedding/wedding-4/Wedding4.page'));
const Wedding6 = lazy(() => import('../wedding/wedding-6/Wedding6.page'));
const Wedding5 = lazy(() => import('../../resource/wedding/wedding-5/Wedding5.page'));
const Wedding7 = lazy(() => import('../../resource/wedding/wedding-7/Wedding7.page'));
const Wedding8 = lazy(() => import('../../resource/wedding/wedding-8/Wedding8.page'));
const Wedding9 = lazy(() => import('../../resource/wedding/wedding-9/Wedding9.page'));
const Wedding10 = lazy(() => import('../../resource/wedding/wedding-10/Wedding10.page'));
const Wedding12 = lazy(() => import('../../resource/wedding/wedding-12/Wedding12.page'));
const Wedding13 = lazy(() => import('../../resource/wedding/wedding-13/Wedding13.page'));
const Wedding14 = lazy(() => import('../../resource/wedding/wedding-14/Wedding14.page'));
const Wedding15 = lazy(() => import('../../resource/wedding/wedding-15/Wedding15.page'));

const Event1 = lazy(() => import('../../resource/events/event-1/Event1.page'));
const Event2 = lazy(() => import('../../resource/events/event-2/Event2.page'));
const Event3 = lazy(() => import('../../resource/events/event-3/Event3.page'));
const Event4 = lazy(() => import('../../resource/events/event-4/Event4.page'));
const Event5 = lazy(() => import('../../resource/events/event-5/Event5.page'));
const Event6 = lazy(() => import('../../resource/events/event-6/Event6.page'));
const Event7 = lazy(() => import('../../resource/events/event-7/Event7.page'));
const Event8 = lazy(() => import('../../resource/events/event-8/Event8.page'));
// const Event9 = lazy(() => import('../../resource/events/event-9/Event9.page'))
const Event9 = lazy(() => import('../../resource/events/event-10/Event10.page'));
const Event11 = lazy(() => import('../../resource/events/event-11/Event11.page'));
const Event12 = lazy(() => import('../../resource/events/event-12/Event12.page'));
const Event13 = lazy(() => import('../../resource/events/event-13/Event13.page'));

const routerTemplates = [
    // loai thiep cuoi
    { t: INPUT_ALIAS.wedding, k: INPUT_ALIAS.wedding_1, v: Wedding1 },
    { t: INPUT_ALIAS.wedding, k: INPUT_ALIAS.wedding_2, v: Wedding2 },
    { t: INPUT_ALIAS.wedding, k: INPUT_ALIAS.wedding_3, v: Wedding3 },
    { t: INPUT_ALIAS.wedding, k: INPUT_ALIAS.wedding_4, v: Wedding4 },
    { t: INPUT_ALIAS.wedding, k: INPUT_ALIAS.wedding_6, v: Wedding6 },
    { t: INPUT_ALIAS.wedding, k: INPUT_ALIAS.wedding_5, v: Wedding5 },
    { t: INPUT_ALIAS.wedding, k: INPUT_ALIAS.wedding_7, v: Wedding7 },
    { t: INPUT_ALIAS.wedding, k: INPUT_ALIAS.wedding_8, v: Wedding8 },
    { t: INPUT_ALIAS.wedding, k: INPUT_ALIAS.wedding_9, v: Wedding9 },
    { t: INPUT_ALIAS.wedding, k: INPUT_ALIAS.wedding_10, v: Wedding10 },
    { t: INPUT_ALIAS.wedding, k: INPUT_ALIAS.wedding_12, v: Wedding12 },
    { t: INPUT_ALIAS.wedding, k: INPUT_ALIAS.wedding_13, v: Wedding13 },
    { t: INPUT_ALIAS.wedding, k: INPUT_ALIAS.wedding_14, v: Wedding14 },
    { t: INPUT_ALIAS.wedding, k: INPUT_ALIAS.wedding_15, v: Wedding15 },

    // loai thiep su kien
    { t: INPUT_ALIAS.event, k: INPUT_ALIAS.event_1, v: Event1 },
    { t: INPUT_ALIAS.event, k: INPUT_ALIAS.event_2, v: Event2 },
    { t: INPUT_ALIAS.event, k: INPUT_ALIAS.event_3, v: Event3 },
    { t: INPUT_ALIAS.event, k: INPUT_ALIAS.event_4, v: Event4 },
    { t: INPUT_ALIAS.event, k: INPUT_ALIAS.event_5, v: Event5 },
    { t: INPUT_ALIAS.event, k: INPUT_ALIAS.event_6, v: Event6 },
    { t: INPUT_ALIAS.event, k: INPUT_ALIAS.event_7, v: Event7 },
    { t: INPUT_ALIAS.event, k: INPUT_ALIAS.event_8, v: Event8 },
    { t: INPUT_ALIAS.event, k: INPUT_ALIAS.event_9, v: Event9 },
    { t: INPUT_ALIAS.event, k: INPUT_ALIAS.event_11, v: Event11 },
    { t: INPUT_ALIAS.event, k: INPUT_ALIAS.event_12, v: Event12 },
    { t: INPUT_ALIAS.event, k: INPUT_ALIAS.event_13, v: Event13 },
];

export default routerTemplates;
