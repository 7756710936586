/* eslint-disable */
import EventsService from "../../events/Events.service";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import storageConstant from "../../../config/constant/storage.constant";
import authActions from "../../../redux/actions/auth.action";
import { HTTP_STATUS_CODE } from "../../../config/constant/common.constant";
import ToastNotify from "../../../utils/Toast";
import { Link } from "react-router-dom";
import PAGE from "../../../config/path.route";
import envConstants from "../../../config/key";
import modalAction from "../../../redux/modal/modal.action";

function GuestEventNotify({ loggedIn }) {
	const [showNotify, setShowNotify] = useState(false);
	const [createdEventId, setCreatedEventId] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		// setTimeout(() => checkHasGuestEvent(), 1000); // timeout để chờ recaptcha load xong thì login tài khoản khách
		checkHasGuestEvent();
	}, [loggedIn])

	const checkHasGuestEvent = async () => {
		if (!loggedIn) {
			// window.grecaptcha.ready(function () {
			// 	window.grecaptcha.execute(envConstants.SITE_KEY_CAPTCHA, { action: 'submit' })
			// 		.then(async function (token) {
			try {
				const guestUser = localStorage.getItem(storageConstant.GUEST_USER);
				if (guestUser) {
					await dispatch(
						// authActions.login(JSON.parse(guestUser).user_name, 'Chungvui123', token)
						authActions.login(JSON.parse(guestUser).user_name, 'Chungvui123', '')
					)
					await EventsService.checkCanCreateEvent();
				}
			}
			catch (error) {
				if (error?.status === HTTP_STATUS_CODE.PAYMENT_REQUIRED && error?.data?.createdEventId) {
					setShowNotify(true);
					setCreatedEventId(error.data.createdEventId)
				} else {
					if (error.message !== 'NOT_FOUND_REFRESH_TOKEN') {
						// ToastNotify.Error(error.message || 'Đã có lỗi xảy ra. Vui lòng thử lại.');
					}
					setShowNotify(false);
				}
			}

			// 		})
			// })
		} else {
			setShowNotify(false);
		}
	}
	return (
		<>
			{
				showNotify && (
					<div className="fixed md:flex-row flex-col bottom-0 border-t border-white   bg-blue-300 flex justify-center items-center  py-2.5 px-0.5  text-sm  w-[100vw] overflow-hidden">
						<p className="text-black font-light">
							Bạn đã tạo thiệp trước đó.
						</p>
						<p className="md:ml-1 text-black font-light">
							Bạn có thể{' '}
							<a
								href={`/${createdEventId}/mainmenu`}
								target="_blank"
								className="font-semibold text-blue-600 hover:text-blue-700 underline cursor-pointer">
								xem lại
							</a>{' '}
							hoặc{' '}
							<span
								onClick={() => dispatch(modalAction.openModalLogin())}
								className="font-semibold text-blue-600 hover:text-blue-700 underline cursor-pointer">
								đăng nhập
							</span>{' '}
							để lưu trữ thiệp này.
						</p>
					</div >
				)
			}
		</>)
}
export default GuestEventNotify;