/* eslint-disable */

import axios from 'axios';
import cheerio, { load } from 'cheerio';
import { useEffect, useState } from 'react';
import envConstants from '../../../config/key';
import { useNavigate } from 'react-router-dom';
import PAGE from '../../../config/path.route';
import { FaSearch } from 'react-icons/fa';
export default function Blog() {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const result = await axios.get(envConstants.HELP_URL);
            const $ = cheerio.load(result.data);
            const dataList = [];
            $('.type-post').each((index, element) => {
                const title = $(element).find('.entry-title a').text();
                const desc = $(element).find('.post-content-inner p').text();
                const time = $(element).find('.published').text();
                const href = $(element).find('.entry-featured-image-url').attr('href');
                const src = $(element).find('.entry-featured-image-url img').attr('src');
                const item = { title, desc, time, href, src };
                dataList.push(item);
            });
            // console.log(dataList);
            setBlogs(dataList);
            setLoading(false);
        };

        fetchData();
    }, []);

    return (
        <div className="w-full max-w-[1420px] flex flex-col items-center gap-[40px] my-[100px]">
            {!loading ? (
                blogs.length > 0 && (
                    <>
                        <p className="text-4xl text-blue-500 font-bold">Các bài viết hướng dẫn</p>
                        <div className="w-full flex flex-wrap items-center justify-center h-[auto] gap-[40px] py-[20px] lg:px-[40px] px-[20px]">
                            {blogs?.map(
                                (item, idx) =>
                                    idx < 6 && (
                                        <a
                                            href={item.href}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <div className="flex flex-col w-[280px] h-auto rounded-[20px] overflow-hidden shadow-xl">
                                                <img
                                                    src={item.src}
                                                    className="w-full h-[200px] object-cover object-center"
                                                />
                                                <div className="w-full p-5 flex flex-col gap-[8px]">
                                                    <p className="text-blue-500 font-bold text-lg">
                                                        {item.title.trim()}
                                                    </p>
                                                    <p className="text-gray-700 text-[14px]">
                                                        {item.time}
                                                    </p>
                                                    <p className="text-gray-700 text-[14px]">
                                                        {item.desc}
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    )
                            )}
                        </div>
                        <a href={envConstants.HELP_URL}>
                            <button className="px-8 py-2 bg-blue-700 text-white hover:scale-105 rounded-md mt-[-20px] flex items-center gap-1">
                                <FaSearch /> Xem thêm
                            </button>
                        </a>
                    </>
                )
            ) : (
                <Loading />
            )}
        </div>
    );
}

const Loading = () => {
    return (
        <div className="w-full flex flex-wrap items-center justify-center h-[auto] gap-[40px] py-[20px] lg:px-[40px] px-[20px] mb-10">
            <div className="flex flex-col w-[280px] h-[380px] rounded-[20px] overflow-hidden shadow-xl bg-gray-300 animate-pulse"></div>
            <div className="flex flex-col w-[280px] h-[380px] rounded-[20px] overflow-hidden shadow-xl bg-gray-300 animate-pulse"></div>
            <div className="flex flex-col w-[280px] h-[380px] rounded-[20px] overflow-hidden shadow-xl bg-gray-300 animate-pulse"></div>
        </div>
    );
};
