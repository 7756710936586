/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { getDateOfMonth, getDateOfWeek, getMonthOfYear, getYear } from '../../../utils/Times';

import { Capitalize } from '../../../utils/Capitalize';

import { QRCodeSVG } from 'qrcode.react';
import { convertNgayDuongAm } from '../../../utils/LichAm';
import bg from './images/sub-bg.jpg';
import envConstants from '../../../config/key';

export default function SubTemplate12({ data, guestName, salutation, refFrame, urlShare, qrRef }) {
    const [pathImgGroom, setPathImgGroom] = useState('');
    const [pathImgBride, setPathImgBride] = useState('');
    useEffect(() => {
        if (data?.avatarGroom?.length > 0) {
            setPathImgGroom(envConstants.SERVER_URL + data?.avatarGroom[0]);
        }
    }, []);

    useEffect(() => {
        if (data?.avatarBride?.length > 0) {
            setPathImgBride(envConstants.SERVER_URL + data?.avatarBride[0]);
        }
    }, []);
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 768); // Thay 768 bằng giá trị cụ thể của width mà bạn muốn xem là mobile
        }

        window.addEventListener('resize', handleResize);

        // Kiểm tra một lần khi component được render
        handleResize();

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {
                <div
                    className=" relative bg-white h-auto py-48"
                    tabIndex="0"
                    ref={refFrame}
                    style={{
                        background: `url('${bg}')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    {urlShare && (
                        <div className="absolute top-3 right-3 z-2 lg:w-[15%] w-[80px] rounded-md bg-gray-700 p-1.5">
                            <div className="rounded-md bg-white p-3" ref={qrRef}>
                                <QRCodeSVG
                                    value={urlShare}
                                    bgColor="#ffffff"
                                    fgColor="#927861"
                                    className="h-1/2 w-full"
                                    renderAs="canvas"
                                />
                            </div>
                            <div className="mt-2  flex">
                                <div className="w-full text-center text-[#927861]">
                                    <i className="fas fa-camera mr-1 text-lg"></i>{' '}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="">
                        <div className="relative h-auto ">
                            {/** Chieu cao cua div theo chieuef roong ma hinh */}
                            <div className="mx-auto w-full">
                                <p className="text-center font-greatVibes text-4xl text-[#927861]">
                                    {salutation}
                                </p>
                            </div>
                            <div className="mx-auto mt-1 w-full">
                                <p className="text-center font-Merriweather text-sm uppercase text-[#927861]">
                                    {guestName ||
                                        '....................................................'}
                                </p>
                            </div>
                            <div className="mx-auto mt-1 w-full">
                                <p className="text-center font-Merriweather text-sm uppercase text-[#927861]">
                                    Tới dự lễ thành hôn của chúng tôi
                                </p>
                            </div>
                            {/** avatar */}
                            <div className="mx-auto mt-10 flex w-full">
                                <div className="mx-ato w-[40%]">
                                    <p className="text-center font-italianno text-5xl text-[#927861]">
                                        <b className="font-italianno">{data?.nameGroom}</b>
                                    </p>
                                </div>
                                <div className="mx-ato w-[20%]">
                                    <p className="text-center font-italianno text-6xl text-[#927861]">
                                        <b className="font-italianno">&</b>
                                    </p>
                                </div>
                                <div className="mx-ato w-[40%]">
                                    <p className="text-center font-italianno text-5xl text-[#927861]">
                                        <b className="font-italianno">{data?.nameBride}</b>
                                    </p>
                                </div>
                            </div>
                            <div className="mx-auto -mt-3 flex w-full">
                                <div className="mx-ato w-[40%]">
                                    {pathImgGroom !== '' && (
                                        <div className="mx-auto mt-3 w-1/3 rounded-full border border-gray-300 shadow-xl">
                                            {
                                                <img
                                                    className="rounded-full"
                                                    alt="..."
                                                    src={pathImgGroom}
                                                />
                                            }
                                        </div>
                                    )}
                                </div>
                                <div className="mx-ato w-[20%]"> </div>
                                <div className="mx-ato w-[40%]">
                                    {pathImgBride !== '' && (
                                        <div className="mx-auto mt-3 w-1/3 rounded-full border border-gray-300 shadow-xl">
                                            {
                                                <img
                                                    className="rounded-full"
                                                    alt="..."
                                                    src={pathImgBride}
                                                />
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/** info */}
                            <div className="mx-auto mt-10 w-full">
                                {data.placeCeremony && (
                                    <p className="text-center font-Merriweather text-sm uppercase text-[#927861]">
                                        Hôn lễ được tổ chức tại {data.placeCeremony}
                                    </p>
                                )}
                                {data.addressCeremony && (
                                    <p className=" mt-1 text-center text-xs font-bold text-[#927861]">
                                        ({Capitalize(data.addressCeremony)})
                                    </p>
                                )}
                            </div>
                            {/** Thoi gian */}
                            <div className="mx-auto w-5/6 px-0">
                                <p className=" m-2 text-center text-xl text-[#927861]">
                                    <b>{data.timeCeremony}</b>
                                </p>
                                <div className="mx-auto -mt-2 flex w-4/6">
                                    <div className="w-1/3  ">
                                        <span className="float-right my-5 block w-full border-y-2 border-[#966737]">
                                            <p className=" text-center text-xs font-bold uppercase text-[#927861] m-2">
                                                {getDateOfWeek(data?.dateCeremony)}
                                            </p>
                                        </span>
                                    </div>
                                    <div className="w-1/3  ">
                                        {data.dateCeremony && (
                                            <p className="text-center text-6xl font-bold text-[#966737]">
                                                {getDateOfMonth(data?.dateCeremony)}
                                            </p>
                                        )}
                                    </div>
                                    <div className="w-1/3">
                                        <span className="float-left my-5 block w-full border-y-2 border-[#966737]">
                                            <p className="text-center text-xs font-bold uppercase text-[#927861] m-2">
                                                {data.dateCeremony &&
                                                    ' THÁNG ' + getMonthOfYear(data.dateCeremony)}
                                            </p>
                                        </span>
                                    </div>
                                </div>
                                <p className=" mt-0 text-center text-sm font-bold uppercase text-[#927861]">
                                    {data.dateCeremony && ' NĂM ' + getYear(data.dateCeremony)}
                                </p>
                                {data.dateCeremony && (
                                    <p className=" mt-0 text-center text-xs text-[#927861]">
                                        (Nhằm ngày{' '}
                                        {data.dateCeremony
                                            ? ' ' + convertNgayDuongAm(data.dateCeremony).day + ' '
                                            : ' 30 '}
                                        tháng{' '}
                                        {data.dateCeremony
                                            ? ' ' +
                                              convertNgayDuongAm(data.dateCeremony).month +
                                              ' '
                                            : ' 12 '}
                                        năm{' '}
                                        {data.dateCeremony
                                            ? ' ' + convertNgayDuongAm(data.dateCeremony).year
                                            : ' Nhâm Dần'}
                                        )
                                    </p>
                                )}
                            </div>
                            {/**  */}
                            <div className="mx-auto mb-6 mt-7 w-full">
                                <p className="text-center font-greatVibes text-3xl text-[#927861]">
                                    Rất hân hạnh được đón tiếp!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
