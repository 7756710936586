/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState, useRef } from 'react';
import BaseModal from '../../../../components/BaseModal';
import PublishSponsorService from './PublishSponsor.service';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Cropper } from 'react-mobile-cropper';
import ToastNotify from '../../../../utils/Toast';
import EventsService from '../../../events/Events.service';
import PAGE from '../../../../config/path.route';
import ModalLeavingPage from '../../../events/components/ModalLeavingPage';
import { useSelector } from 'react-redux';
import {
    FaBook,
    FaChartPie,
    FaExternalLinkAlt,
    FaPen,
    FaPlus,
    FaSave,
    FaTimes,
} from 'react-icons/fa';
import { checkTypeTemplate, limitString } from '../publish.greeting/PublishGreeting.page';
import TimelineShortcutMenu from '../../components/TimelineShortcutMenu';
import QuestionTitle from '../publish.question/QuestionTitle';
import { validateSizeOfFile } from '../../../../utils/common';
import LoadingButtonIcon from '../../../../components/LoadingButton/LoadingButtonIcon';
import Products from '../publish.news/products/Products';
import IMAGES from '../../../../assets/images/_index';

const parseJsonData = (formData) => {
    // parse json
    const dataCopy = formData;
    let arrParse = [];
    let arrCopy = [];
    let keyName = '';
    // infoParty
    if (dataCopy?.infoParty) {
        arrCopy = dataCopy.infoParty;
        keyName = 'infoParty';
    } else {
        //  programes
        arrCopy = dataCopy.programes;
        keyName = 'programes';
    }
    if (Array.isArray(arrCopy)) {
        for (var idx = 0; idx < arrCopy.length; idx++) {
            if (typeof arrCopy[idx] === 'string') {
                arrParse.push(JSON.parse(arrCopy[idx]));
            } else {
                arrParse.push(arrCopy[idx]);
            }
        }
    } else {
        if (arrCopy) {
            arrParse.push(JSON.parse(arrCopy));
        }
    }
    return { ...dataCopy, [keyName]: arrParse };
};

const CropperModal = ({
    index,
    templateAlias,
    setOpenModal,
    setNormalObj,
    normalObj,
    setIsEdit,
    tempImg,
}) => {
    const cropperRef = useRef(null);
    const [image, setImage] = useState(normalObj?.sponsors[index]?.image ?? tempImg);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);

    const changeFile = (event) => {
        const val = event.target.files[0];
        event.target.value = '';
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setImage(image);
        });
        reader.readAsDataURL(val);
    };

    const submitImageForm = async () => {
        setIsLoadingBtn(true);
        const img = cropperRef.current.getCanvas()?.toDataURL();
        const file = [];

        if (
            validateSizeOfFile(
                file,
                10,
                'Dung lượng ảnh sau khi chỉnh sửa không được vượt quá 10Mb'
            )
        ) {
            const change = Math.max(
                ...(normalObj?.sponsors ?? []).map((x) => (isNaN(Number(x.id)) ? -1 : Number(x.id)))
            );
            const max = normalObj?.sponsors?.length > 0 ? change : 1;
            setNormalObj({
                ...normalObj,
                ['sponsors']:
                    index !== null
                        ? (normalObj?.sponsors ?? []).map((x, idx) =>
                              idx === index ? { image: img, id: x.id, name: x?.name ?? '' } : x
                          )
                        : (normalObj?.sponsors ?? []).concat([
                              { image: img, id: (max + 1).toString(), name: '' },
                          ]),
            });
            setIsEdit(true);
            setOpenModal(false);
        }
        setIsLoadingBtn(false);
    };

    return (
        <div className="mx-1.5 appearance-none justify-center rounded-md bg-white px-2 pt-2 transition  hover:border-gray-400 focus:outline-none">
            <Cropper
                stencilProps={{
                    // eslint-disable-next-line no-magic-numbers
                    aspectRatio: 16 / 16,
                }}
                ref={cropperRef}
                style={{ height: '500px', width: '500px' }}
                className="cropper h-[500px]"
                src={image}
            />
            <div className="m-2 flex justify-center gap-2">
                <label
                    className={`flex cursor-pointer items-center gap-[6px] rounded-lg bg-blue-100 px-[16px] py-[8px] text-[16px] font-semibold text-blue-700 hover:bg-blue-200 ${
                        isLoadingBtn
                            ? 'cursor-auto bg-slate-100 text-slate-700 hover:bg-blue-100'
                            : ''
                    }`}
                    // onClick={() => btnRemoveImagePreview('avatarGroom')}
                >
                    <input
                        className="hidden"
                        disabled={isLoadingBtn}
                        onChange={(event) => changeFile(event)}
                        type="file"
                        accept="image/*"
                    />
                    <FaPen /> Thay đổi
                </label>
                <button
                    className={`${
                        isLoadingBtn ? 'bg-blue-500' : 'bg-blue-600 hover:bg-blue-700 '
                    } flex items-center gap-[6px] rounded-lg  px-[16px] py-[8px] text-[16px] font-semibold text-white disabled:bg-slate-700 `}
                    onClick={() => submitImageForm('sponsors')}
                    disabled={isLoadingBtn}
                >
                    {isLoadingBtn && <LoadingButtonIcon />}
                    {!isLoadingBtn && <FaSave />}
                    Lưu
                </button>
            </div>
        </div>
    );
};

export default function PublisSponsor() {
    const navigate = useNavigate();
    const { eventId, url_guest: urlGuest } = useParams();
    const [formData, setFormData] = useState({});
    const [templateAlias, setTemplateAlias] = useState('');
    const [normalObj, setNormalObj] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [load, setLoad] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [tempImg, setTempImg] = useState(null);
    const [listDel, setListDel] = useState([]);
    const toastLoadingId = useRef(null);
    const [isExpiredEvent, setIsExpiredEvent] = useState(false);
    const { loggedIn } = useSelector((state) => state.auth);
    const [valueMeta, setValueMeta] = useState({
        title: '',
        thumbnail: '',
    });
    const [imgIdx, setimgIdx] = useState(null);
    const confirmForm = (event) => {
        event.preventDefault();
    };
    const [isOpenLeavinModal, setOpenLeavinModal] = useState(false);
    const [flag, setFlag] = useState(false);
    const [event, setEvent] = useState();
    const [errors, setErrors] = useState({});

    const validURL = (str) => {
        const pattern = new RegExp(
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
        );
        return !!pattern.test(str);
    };

    const checkValidate = () => {
        let check = false;
        const newErrors = { ...errors };
        const dataList = JSON.parse(JSON.stringify(normalObj?.sponsors));
        dataList.map((element, index) => {
            if (element.web && !validURL(element.web)) {
                newErrors[index] = {
                    ...newErrors[index],
                    name: 'Vui lòng nhập đường dẫn trang chủ đầy đủ, bao gồm cả "http://" hoặc "https://"',
                };
                check = true;
            }
        });
        setErrors(newErrors);
        return check;
    };

    const submitForm = async () => {
        try {
            setErrors([]);
            toastLoadingId.current = ToastNotify.Loading('Đang lưu ảnh...');
            const imgList = JSON.parse(JSON.stringify(normalObj?.sponsors));
            const listDels = [...new Set(listDel)];
            if (listDels.length > 0) {
                for (let ind = 0; ind < listDels.length; ind++) {
                    await PublishSponsorService.saveContent(formData._id, {
                        sponsors: listDels[ind],
                    });
                }
            }
            // eslint-disable-next-line no-magic-numbers
            if (imgList.length > 0) {
                const max =
                    Math.max(...imgList.map((x) => (isNaN(Number(x.id)) ? -1 : Number(x.id)))) ?? 0;
                let step = 1;
                for (let ind = 0; ind < imgList.length; ind++) {
                    const file = await PublishSponsorService.createFile(
                        imgList[ind].image,
                        'sponsor'
                    );
                    if (file !== '') {
                        await PublishSponsorService.saveFileData(
                            normalObj._id,
                            'sponsors',
                            file,
                            imgList[ind].id ?? (max + step).toString(),
                            'image',
                            imgList[ind]?.name ?? '',
                            imgList[ind]?.web ?? ''
                        );
                        step++;
                    } else {
                        await PublishSponsorService.saveFileData(
                            normalObj._id,
                            'sponsors',
                            [],
                            imgList[ind].id ?? (max + step).toString(),
                            'image',
                            imgList[ind]?.name ?? '',
                            imgList[ind]?.web ?? ''
                        );
                        step++;
                    }
                }
            }
            setIsEdit(false);
            setLoadData(!loadData);
            setFlag(false);
            ToastNotify.Update(toastLoadingId.current, 'Lưu ảnh thành công!');
            toastLoadingId.current = null;
            setErrors([]);
        } catch (error) {
            setIsEdit(false);
            setLoadData(!loadData);
            ToastNotify.Update(
                toastLoadingId.current,
                error?.message || 'Đã có lỗi xảy ra. Vui lòng thử lại',
                'error'
            );
            toastLoadingId.current = null;
            setErrors([]);
        }
    };

    const changeFile = (event) => {
        const val = event.target.files[0];
        event.target.value = '';
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const image = reader.result;
            setTempImg(image);
            setimgIdx(null);
            setOpenModal(true);
        });
        reader.readAsDataURL(val);
    };

    const btnRemoveImage = (id) => {
        setListDel(listDel.concat(id));
        setNormalObj({
            ...normalObj,
            sponsors: normalObj.sponsors.filter((x) => id !== x.id),
        });
        setIsEdit(true);
    };

    useEffect(() => {
        if (eventId) {
            setLoad(true);
            EventsService.getOneEventById(eventId)
                .then(({ data }) => {
                    setEvent(data);
                    const title = data.form_data.titleMeta || data.name;
                    const thumbnail = data.thumbnail_url;
                    const templateAlias = data.template.alias;
                    const formData = data.form_data;
                    const jsonData = parseJsonData({ ...data, ...formData });
                    setIsExpiredEvent(data?.isExpired);
                    // const jsonData = parseJsonData({ ...data, ...formData });
                    // setContentMeta({ title, image: thumbnail })
                    setValueMeta({
                        title,
                        thumbnail,
                    });
                    setTemplateAlias(templateAlias);
                    // setGuest(guest);

                    setNormalObj({
                        _id: jsonData?._id,
                        titleEvent: jsonData?.titleEvent ?? null,
                        nameEvent: jsonData?.nameEvent,
                        invitation: jsonData?.invitation,
                        logo: jsonData?.logo ? jsonData?.logo[0] : null,
                        dateHold: jsonData?.dateHold,
                        timeHold: jsonData?.timeHold,
                        addressHold: jsonData?.addressHold,
                        groupName: jsonData?.groupName,
                        groupNameBrief: jsonData?.groupNameBrief,
                        placeHold: jsonData?.placeHold,
                        nameSupporter: jsonData?.nameSupporter,
                        phoneSupporter: jsonData?.phoneSupporter,
                        nameTemplate: jsonData?.name,
                        linkGgMap: jsonData?.linkGgMap ?? '',
                        sponsors:
                            formData?.sponsors && formData?.sponsors?.length > 0
                                ? formData?.sponsors.map((x) => ({
                                      image: process.env.REACT_APP_SERVER_URL + x.image,
                                      id: x.id,
                                      name: x?.name ?? '',
                                      web: x?.web ?? '',
                                  }))
                                : [],
                    });
                    setListDel([]);

                    setFormData(jsonData);
                    // setEventConfig(eventConfig);
                })
                .catch((error) => {
                    const AuthorizeError = 404;
                    if (error.status === AuthorizeError) {
                        navigate(PAGE.dashboard);
                    }
                    const ServerError = 500;
                    if (error.status === ServerError) {
                        navigate(PAGE.dashboard);
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoad(false);
                    }, 500);
                });
        }
    }, [loadData, eventId, urlGuest, navigate, templateAlias]);

    useEffect(() => {
        if (
            Number(normalObj?.sponsors?.length) !== Number(formData?.sponsors?.length) &&
            formData?.sponsors &&
            normalObj?.sponsors
        ) {
            setIsEdit(true);
        } else if (Number(normalObj?.sponsors?.length) === Number(formData?.sponsors?.length)) {
            for (let index = 0; index < formData?.sponsors?.length; index++) {
                if (
                    normalObj?.sponsors[index]?.id !== formData?.sponsors[index]?.id ||
                    normalObj?.sponsors[index]?.image !==
                        process.env.REACT_APP_SERVER_URL + formData?.sponsors[index]?.image[0]
                ) {
                    setIsEdit(true);
                    return;
                }
            }
            setIsEdit(false);
        }
        if (flag) {
            setIsEdit(true);
        }
    }, [formData, normalObj, flag]);

    const onChangeInput = (item, event) => {
        const dataSponsors = normalObj?.sponsors;
        dataSponsors.map((element, index) => {
            if (element.id === item.id) {
                element.name = event.target.value;
            }
        });
        setFlag(true);
        setIsEdit(true);
        setNormalObj({
            ...normalObj,
            ['sponsors']: dataSponsors,
        });
    };

    const onChangeWeb = (item, event, index) => {
        const dataSponsors = normalObj?.sponsors;
        const newErrors = { ...errors };
        dataSponsors.map((element, idx) => {
            if (element.id === item.id) {
                element.web = event.target.value;
            }
        });
        newErrors[index] = {
            ...newErrors[index],
            name: null,
        };
        setErrors(newErrors);
        setFlag(true);
        setIsEdit(true);
        setNormalObj({
            ...normalObj,
            ['sponsors']: dataSponsors,
        });
    };

    const onAction = () => {
        if (checkValidate()) {
            return;
        }
        setOpenLeavinModal(true);
    };

    return (
        <div>
            <Helmet>
                <title>{'Logo nhà đồng hành' + ' | ' + valueMeta.title}</title>
                <meta property="og:image" content={valueMeta.thumbnail} />
            </Helmet>
            <div className={'h-[100dvh] w-[100vw] bg-gray-50 text-black'}>
                <div className="sticky top-0 z-[40] bg-white md:bg-gray-50 md:px-4 lg:px-6">
                    <div className="relative flex w-full flex-wrap items-center justify-between gap-x-3 p-4 md:px-0 lg:flex-nowrap lg:gap-4 lg:py-5">
                        <div className="flex flex-col md:flex-row md:gap-2">
                            <button
                                className="flex items-center"
                                type="button"
                                onClick={() => {
                                    navigate(`/${eventId}/mainmenu`);
                                }}
                                title="Trở về"
                            >
                                <i className=" fas fa-chevron-left"></i>
                                <p className="order-1 ml-3 font-bold text-black">
                                    {limitString(formData?.name)}
                                </p>
                            </button>
                            {isExpiredEvent && (
                                <div className="flex h-5  w-fit items-center justify-center rounded-[4px] bg-red-500 px-3">
                                    <span className="text-[9px] uppercase text-white">
                                        {checkTypeTemplate(templateAlias)}
                                    </span>
                                </div>
                            )}
                            {!loggedIn && (
                                <div className="flex h-5  w-fit items-center justify-center rounded-[4px] bg-red-500 px-3">
                                    <span className="text-[9px] uppercase text-white">
                                        Bạn chưa đăng nhập
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="flex justify-center gap-2 overflow-hidden ">
                            {loggedIn && (
                                <Link
                                    // target="_blank"
                                    className="inline-flex select-none gap-2 rounded-md border border-blue-500 bg-white px-3 py-1 text-center text-sm font-medium text-blue-500 transition-all duration-150 ease-linear hover:bg-blue-100 xl:px-4"
                                    to={`..${PAGE.profile}`}
                                    state={{ isResourcePackage: true }}
                                >
                                    Quản lý gói dịch vụ
                                    <FaChartPie className="my-auto" />
                                </Link>
                            )}
                            <button className="inline-flex w-max select-none rounded-md bg-blue-500 px-3 py-1 text-center text-sm font-medium text-white transition-all duration-150 ease-linear hover:bg-blue-600 xl:px-4">
                                <Link
                                    target="_blank"
                                    className="flex gap-2"
                                    to={'../' + event?.url_slug}
                                >
                                    Xem thiệp <FaExternalLinkAlt className="my-auto" />
                                </Link>
                            </button>
                        </div>
                    </div>
                    <div className="w-full border-b border-gray-300"></div>
                </div>
                <form className="p-4" onSubmit={confirmForm}>
                    <div className="flex w-full justify-evenly">
                        <div className="relative hidden w-[250px] lg:flex">
                            <TimelineShortcutMenu screen={'sponsor'} />
                        </div>
                        <div className=" relative min-h-[500px] w-full  rounded-lg pb-3 md:col-span-2 lg:w-[48rem] 2xl:w-[60rem]">
                            <div className="mx-auto mb-5 max-h-[90dvh] w-full scroll-mt-[100px] rounded-lg bg-white pb-3 shadow md:col-span-2 ">
                                <div className="flex flex-col border-b border-gray-200 p-2  px-3">
                                    <p className="sticky mb-0 text-lg font-bold text-gray-900">
                                        Logo nhà đồng hành
                                    </p>
                                </div>
                                {/* {load && <SlideSkeleton />} */}
                                {!load && (
                                    <div className="m-2 max-h-[80dvh] flex-auto overflow-y-auto px-6 py-2">
                                        {!isExpiredEvent && (
                                            <div className=" flex w-full justify-end gap-4">
                                                <div>
                                                    <label className="hidden cursor-pointer gap-[6px] rounded-lg bg-blue-600 px-[12px] py-[8px] text-sm font-semibold text-white hover:bg-blue-700 md:flex ">
                                                        <FaPlus className="mr-0.5 mt-0.5" />
                                                        Thêm logo
                                                        <input
                                                            type="file"
                                                            className="hidden"
                                                            onChange={(event) =>
                                                                changeFile(event, 'sponsors')
                                                            }
                                                            multiple="multiple"
                                                            accept="image/*"
                                                            disabled={isExpiredEvent}
                                                        />
                                                    </label>
                                                    <label className="block cursor-pointer gap-[6px] rounded-lg bg-blue-600 px-[12px] py-[8px] text-sm font-semibold text-white hover:bg-blue-700 md:hidden">
                                                        <FaPlus />
                                                        <input
                                                            type="file"
                                                            className="hidden"
                                                            disabled={isExpiredEvent}
                                                            onChange={(event) =>
                                                                changeFile(event, 'sponsors')
                                                            }
                                                            accept="image/*"
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                        <ul className="mt-5 grid grid-cols-1 gap-x-4 gap-y-6 overflow-y-auto md:grid-cols-3">
                                            {normalObj?.sponsors?.length > 0 && !isExpiredEvent && (
                                                <label
                                                    multiple
                                                    className="block w-full cursor-pointer justify-center rounded-lg border-2 border-dashed border-gray-300 bg-white transition hover:border-gray-400 focus:outline-none"
                                                >
                                                    <span className="my-auto flex h-full flex-row justify-center text-center md:flex-col md:justify-start 2xl:flex-row 2xl:justify-center">
                                                        <svg
                                                            className="my-auto fill-gray-400 md:mx-auto md:mb-0 2xl:mx-0 2xl:my-auto"
                                                            width="32px"
                                                            height="32px"
                                                            viewBox="0 0 24 24"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <rect
                                                                x="0"
                                                                fill="none"
                                                                width="24"
                                                                height="24"
                                                            />
                                                            <g>
                                                                <path d="M23 4v2h-3v3h-2V6h-3V4h3V1h2v3h3zm-8.5 7c.828 0 1.5-.672 1.5-1.5S15.328 8 14.5 8 13 8.672 13 9.5s.672 1.5 1.5 1.5zm3.5 3.234l-.513-.57c-.794-.885-2.18-.885-2.976 0l-.655.73L9 9l-3 3.333V6h7V4H6c-1.105 0-2 .895-2 2v12c0 1.105.895 2 2 2h12c1.105 0 2-.895 2-2v-7h-2v3.234z" />
                                                            </g>
                                                        </svg>
                                                        <span className="my-auto text-gray-400 md:mt-0 2xl:my-auto">
                                                            Thêm logo (Giới hạn 10Mb)
                                                        </span>
                                                        <input
                                                            type="file"
                                                            className="hidden"
                                                            disabled={isExpiredEvent}
                                                            onChange={(event) =>
                                                                changeFile(event, 'sponsor')
                                                            }
                                                            accept="image/*"
                                                        />
                                                    </span>
                                                </label>
                                            )}
                                            {normalObj?.sponsors?.length > 0 &&
                                                normalObj?.sponsors
                                                    ?.sort(
                                                        (start, end) =>
                                                            Number(start.id) - Number(end.id)
                                                    )
                                                    ?.map((item, index) => (
                                                        <li key={index} className="relative h-max">
                                                            <div
                                                                className="group relative block w-full overflow-hidden rounded-lg bg-gray-100"
                                                                style={{
                                                                    '--tw-aspect-h': 16,
                                                                    '--tw-aspect-w': 16,
                                                                    position: 'relative',
                                                                    paddingBottom:
                                                                        'calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%)',
                                                                }}
                                                            >
                                                                <img
                                                                    src={item.image}
                                                                    className="absolute inset-0 mx-auto size-full rounded-lg object-cover"
                                                                    alt="sponsor"
                                                                />

                                                                <button
                                                                    type="button"
                                                                    key={index}
                                                                    disabled={isExpiredEvent}
                                                                    className="absolute -top-0 flex size-full flex-col items-center justify-center rounded-lg bg-black/30 opacity-0 backdrop-blur-sm duration-500 ease-out group-hover:opacity-100"
                                                                    onClick={() => {
                                                                        setimgIdx(index);
                                                                        setOpenModal(true);
                                                                    }}
                                                                >
                                                                    <span className="fas fa-edit cursor-pointer text-3xl text-slate-100"></span>
                                                                </button>
                                                                {!isExpiredEvent && (
                                                                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                                                    <div
                                                                        onClick={() =>
                                                                            btnRemoveImage(item.id)
                                                                        }
                                                                        className="absolute right-3 top-1 w-[33px] cursor-pointer rounded-full border bg-slate-100 p-2 text-black shadow-lg hover:bg-gray-400"
                                                                    >
                                                                        <svg
                                                                            width="15px"
                                                                            height="15px"
                                                                            viewBox="0 0 24 24"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                fill="none"
                                                                                stroke="#000"
                                                                                strokeWidth="2"
                                                                                d="M3,3 L21,21 M3,21 L21,3"
                                                                            />
                                                                        </svg>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="mb-2 mt-5 w-full">
                                                                <span className="mb-3 text-sm font-normal">
                                                                    Tên công ty
                                                                </span>
                                                                <input
                                                                    type="text"
                                                                    name="name_company"
                                                                    value={item?.name ?? ''}
                                                                    onInput={(event) =>
                                                                        onChangeInput(item, event)
                                                                    }
                                                                    placeholder={
                                                                        'VD: "Công ty TNHH ABC"'
                                                                    }
                                                                    disabled={isExpiredEvent}
                                                                    // {...register('nameTemplate')}
                                                                    className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1 disabled:bg-gray-100"
                                                                />
                                                            </div>
                                                            <div className="w-full">
                                                                <span className="mb-3 text-sm font-normal">
                                                                    Đường dẫn trang chủ
                                                                </span>
                                                                <input
                                                                    type="text"
                                                                    name="web"
                                                                    value={item?.web ?? ''}
                                                                    onInput={(event) =>
                                                                        onChangeWeb(
                                                                            item,
                                                                            event,
                                                                            index
                                                                        )
                                                                    }
                                                                    placeholder={
                                                                        'VD: "https://example.com"'
                                                                    }
                                                                    disabled={isExpiredEvent}
                                                                    // {...register('nameTemplate')}
                                                                    className="mt-2 block w-full rounded-[0.375rem] border border-gray-300 bg-white p-3 text-sm text-blueGray-600 transition-all duration-150 ease-linear placeholder:text-blueGray-300 focus:outline-none focus:ring-1 disabled:bg-gray-100"
                                                                />
                                                                {errors[index]?.name ? (
                                                                    <span className="text-xs font-medium text-red-500">
                                                                        {errors[index]?.name}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </li>
                                                    ))}
                                        </ul>
                                        <BaseModal
                                            isOpen={openModal}
                                            onClose={() => {
                                                setOpenModal(false);
                                            }}
                                            modalTitle="Chỉnh sửa ảnh trình chiếu"
                                            width="max-w-[700px]"
                                        >
                                            <CropperModal
                                                index={imgIdx}
                                                normalObj={normalObj}
                                                setTempImg={setTempImg}
                                                setIsEdit={setIsEdit}
                                                setNormalObj={setNormalObj}
                                                setOpenModal={setOpenModal}
                                                templateAlias={templateAlias}
                                                key={imgIdx}
                                                tempImg={tempImg}
                                            />
                                        </BaseModal>
                                    </div>
                                )}
                                {!load &&
                                    !(normalObj?.sponsors && normalObj?.sponsors?.length > 0) && (
                                        <div className="flex w-full flex-col items-center justify-center">
                                            <img
                                                src={IMAGES.SPONSOR}
                                                alt="not found"
                                                className="mx-auto w-1/3 opacity-70"
                                            />
                                            <h4 className="mt-3 text-center text-xl text-blue-900 ">
                                                Sự kiện của bạn sẽ không thể trọn vẹn và hoàn hảo
                                                khi không có sự đồng hành của các đối tác.
                                            </h4>
                                            <p className="mt-2 text-center text-sm text-black">
                                                Hãy thêm logo của họ vào thiệp mời để tăng phần ý
                                                nghĩa cho sự kiện!
                                                {!isExpiredEvent && (
                                                    <p>
                                                        <label className="inline-block cursor-pointer font-bold text-blue-500 underline">
                                                            Thêm logo ngay
                                                            <input
                                                                type="file"
                                                                className="hidden"
                                                                onChange={(event) =>
                                                                    changeFile(event, 'sponsor')
                                                                }
                                                                multiple="multiple"
                                                                accept="image/*"
                                                                disabled={isExpiredEvent}
                                                            />
                                                        </label>
                                                    </p>
                                                )}
                                            </p>
                                        </div>
                                    )}

                                {!isExpiredEvent && (
                                    <div
                                        className={
                                            (isEdit
                                                ? 'bottom-0 opacity-100'
                                                : '-bottom-80 opacity-0') +
                                            ' fixed inset-x-0 z-[40] bg-gray-50 transition-all duration-500 md:px-6 lg:z-[40]'
                                        }
                                    >
                                        <div className="flex items-center justify-between gap-3 border-t border-gray-300 px-6 py-4 md:px-0">
                                            <p>Chưa lưu !</p>
                                            <div className="flex gap-3">
                                                <button
                                                    type="button"
                                                    className={
                                                        'mx-auto inline-flex select-none items-center gap-2 rounded-md bg-blue-100 px-6 py-2 text-center font-semibold text-blue-500 transition-all duration-150 ease-linear hover:bg-blue-200 '
                                                    }
                                                    onClick={() => {
                                                        setErrors([]);
                                                        setFlag(false);
                                                        setIsEdit(false);
                                                        setLoadData(!loadData);
                                                    }}
                                                >
                                                    <FaTimes />
                                                    Hủy bỏ
                                                </button>
                                                <button
                                                    type="submit"
                                                    onClick={() => {
                                                        onAction();
                                                    }}
                                                    className={
                                                        'mx-auto inline-flex select-none items-center gap-2 rounded-md bg-blue-500 px-6 py-2 text-center font-semibold text-white transition-all duration-150 ease-linear hover:bg-blue-600 '
                                                    }
                                                >
                                                    <FaSave />
                                                    Hoàn tất
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <ModalLeavingPage
                                    saveChanged={submitForm}
                                    isOpen={isOpenLeavinModal}
                                    confirmNavigation={() => {
                                        setOpenLeavinModal(false);
                                    }}
                                    key={formData._id}
                                    funToggle={() => {
                                        setOpenLeavinModal(false);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="relative hidden w-[250px] lg:flex">
                            <Products />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
