/* eslint-disable */

import { useEffect, useState } from 'react';
import { questionList } from './Question';

export default function QuestionModal({ keyQues }) {
    const [questionSelect, setQuestionSelect] = useState();
    useEffect(() => {
        setQuestionSelect(keyQues ? questionList.find((item) => item.key === keyQues) : {});
    }, [keyQues]);
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id={keyQues}
            tabIndex="-1"
            aria-modal="true"
            role="dialog"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered modal-lg">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-[16px] font-bold leading-normal text-gray-800"
                            id="staticBackdropLabel"
                        >
                            Có thể bạn đã biết
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="w-full flex justify-center items-center flex-col gap-4 p-4 px-8">
                        <p className="text-black font-bold text-xl">{questionSelect?.ques}</p>
                        {questionSelect?.ans}
                        <img src={questionSelect?.img} className="rounded-md"></img>
                    </div>
                </div>
            </div>
        </div>
    );
}
