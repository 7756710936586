import SLIDER_BG from './imgs/slider-bg.jpg';
import BG_THANKYOU from './imgs/bg-thankyou.jpg';
import BG_THANKYOU2 from './imgs/bg-thankyou.png';
import TRAVEL_BG2 from './imgs/travel-bg-2.jpg';
import TRAVEL_BG3 from './imgs/travel-bg-3.jpg';
import SUN_IMG from './imgs/sun.png';
import LOGO_IMG from './imgs/logo.png';
import GIF_1 from './imgs/travel-gif1.gif';
import BG_MP3 from './mp3/bg_mp3.mp3';
const IMAGES_EVENT3 = {
    SLIDER_BG,
    TRAVEL_BG2,
    TRAVEL_BG3,
    SUN_IMG,
    LOGO_IMG,
    GIF_1,
    BG_MP3,
    BG_THANKYOU,
    BG_THANKYOU2,
};
export default IMAGES_EVENT3;
