/* eslint-disable */
import GuestTable from './components/GuestTable';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useReducer, useRef, useCallback } from 'react';
import guestManagerService from './guestManager.service';

import GuestForm from './components/GuestForm';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { toBlob, toPng } from 'html-to-image';

import {
    addNewGuests,
    downloadExcel,
    importExcelGroup,
    importExcelGuest,
    updateGuest,
} from '../../../user.events/services/modalService';

import { useSelector } from 'react-redux';

import TrimSpace from '../../../../utils/TrimSpace';
import './index.css';
import GroupList from './components/GroupList';
import Regex from '../../../../utils/Regex';
import GroupForm from './components/GroupForm';

import GroupListModal from './components/GroupListModal';

import GroupMenu from './components/GroupMenu';

import {
    ConfirmDeleteOneGroup,
    ConfirmAddEmailModal,
    ConfirmImportModal,
    ConfirmGuest,
    ConfirmGroup,
    ConfirmAddEmail4Only,
    ConfirmBackModal,
    ConfirmDeleteModal,
    ConfirmDeleteOne,
    ConfirmRemoveGuestModal,
    ConfirmSendOnlyGuest,
    ConfirmSendEmail,
    ConfirmReplaceByExcel,
    ConfirmDeleteAll,
    ConfirmSendOnlyGroup,
    ConfirmAddEmail4OnlyGroup,
} from './components/ConfirmModal';

import { FilterModal } from './components/FilterModal';
import GuestChartModal from './components/GuestChartModal';
import GroupChartModal from './components/GroupChartModal';

import ToastNotify from '../../../../utils/Toast';
import envConstants from '../../../../config/key';
import PAGE from '../../../../config/path.route';

import TemplateModal from './components/TemplateModal';
import SettingEmailModal from './components/SettingEmailModal';
import VerifyTable, {
    ConfirmAcceptRequest,
    ConfirmDenyRequest,
} from './components/VerifyingTableModal';
import SetTimerSendMailModal from './components/SetTimerSendMailModal';
import SetTimerSendMailManyGuests from './components/SetTimerSendMailManyGuests';
import GuestManagerLoading from './components/GuestManagerLoading';
import { ExportQRModal } from './components/ExportQRModal';
import ShortcutMenu from '../../components/ShortcutMenu';
import QrModal from './components/QrModal';
import Products from '../publish.news/products/Products';
import TimelineShortcutMenu from '../../components/TimelineShortcutMenu';
import QuestionTitle from '../publish.question/QuestionTitle';
import ComposeContentEmail from '../pushish.config/component/ComposeContentEmail';
import Header from '../publish.sub/components/Header';
import { ExportQRModalSelect } from './components/ExportQRModalSelect';
import { RemindModal } from './components/RemindModal';
import UpgradeModal from './components/UpgradeModal';
import { FaArrowLeft, FaChartPie } from 'react-icons/fa';
import SortTableModal from './components/SortTableModal';

const INIT_GUEST = [];

const INIT_FORM_STATE = {
    _id: '',
    fullname: '',
    title: '',
    salutation: '',
    note: '',
    slug: '',
    company_name: '',
    position: '',
    email: '',
    phone_number: '',
    is_important: false,
    gender: 'MALE',
    group: [],
};

const INIT_GROUP_FORM = {
    _id: '',
    fullname: '',
    salutation: '',
    note: '',
    slug: '',
    email: '',
    phone_number: '',
    is_important: false,
};

function guestReducer(state, action) {
    const { type, payload } = action;
    switch (type) {
        case 'get':
            return payload;
        case 'reset':
            return payload;
        case 'update':
            return state.map((item) => {
                if (item._id === payload._id) {
                    return payload;
                }
                return item;
            });
        case 'delete': {
            return state.filter(({ _id }) => _id !== payload);
        }
        case 'deleteMany': {
            return state.filter(({ _id }) => _id !== !payload?.includes(_id));
        }
        default:
            break;
    }
}

const defaultQuery = {
    search: '',
    status: '',
    pageNow: 1,
    vip: '',
    method: '',
    limit: 10,
};

export default function GuestManager() {
    const { eventId } = useParams();
    const navigate = useNavigate();
    const [mode, setMode] = useState('default');
    const [eventTemplate, setEventTemplate] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState();
    const [guestSelect, setGuestSelect] = useState([]);
    const btnOpenModal = useRef();
    const [modalOpen, setModalOpen] = useState({});
    const [messageError, setMessageError] = useState('');
    const [guests, dispatch] = useReducer(guestReducer, INIT_GUEST);

    const [groups, setGroups] = useState([]);

    const [viewMode, setViewMode] = useState('guest');

    const [guestQuery, setGuestQuery] = useState(defaultQuery);

    const resetAndAddGuestQuery = (newQuery) => {
        setGuestQuery({
            ...guestQuery,
            search: '',
            status: '',
            pageNow: 1,
            vip: '',
            method: '',
            limit: 10,
            ...(newQuery ? newQuery : guestQuery),
        });
        setViewMode('guest');
        setMode('default');
    };

    const resetAndAddGroupQuery = (newQuery) => {
        setGroupQuery({
            search: '',
            status: '',
            vip: '',
            pageNow: 1,

            ...(newQuery && newQuery),
        });
        setViewMode('group');
        setMode('group-default');
    };

    const [groupQuery, setGroupQuery] = useState({ search: '', status: '', pageNow: 1, vip: '' });

    const [sort, setSort] = useState({});
    const [actionMode, setActionMode] = useState('');
    const [importMode, setImportMode] = useState('');

    const [selectOne, setSelectOne] = useState('');
    const [selectGroups, setSelectGroups] = useState([]);

    const [statisticalData, setStatisticalData] = useState([]);
    const [groupStatisticalData, setGroupStatisticalData] = useState([]);

    const [totalGuest, setTotalGuest] = useState(null);
    const [trigger, setTrigger] = useState(false);
    const [isChangeTimer, setIsChangeTimer] = useState({ value: false });
    const setIsChangeTimer2 = (val) => {
        setIsChangeTimer({ ...val });
    };
    const { loggedIn } = useSelector((state) => state.auth);

    useEffect(() => {
        getData();
    }, [trigger]);

    const getData = async () => {
        setLoading(true);
        const tempEv = await guestManagerService.getEventTemplateById(eventId);
        setEventTemplate(tempEv);
        await getGuests(tempEv.id);
        await getGroups(tempEv.id);
        setLoading(false);
    };

    useEffect(() => {
        setGuestSelect([]);
        setActionMode('');
    }, [
        guestQuery.group,
        guestQuery.search,
        guestQuery.status,
        guestQuery.vip,
        guestQuery.verify_status,
        guestQuery.method,
        guestQuery.guest_invite,
    ]);

    useEffect(() => {
        setSelectGroups([]);
        setActionMode('');
    }, [groupQuery.search, groupQuery.status, groupQuery.vip]);

    useEffect(() => {
        if (eventTemplate) {
            getGuests(eventTemplate._id);
        }
    }, [
        guestQuery.vip,
        guestQuery.status,
        guestQuery.verify_status,
        guestQuery.pageNow,

        guestQuery.group,
        guestQuery.method,
        guestQuery.guest_invite,
        guestQuery.limit,
    ]);

    useEffect(() => {
        if (eventTemplate) {
            getGroups(eventTemplate.id);
        }
    }, [groupQuery.vip, groupQuery.pageNow, groupQuery.status]);

    const getGuests = async (event_id) => {
        if (event_id) {
            await guestManagerService
                .getAllGuests({ ...guestQuery, event_id: event_id })
                .then((data) => {
                    setGuestQuery({
                        ...guestQuery,
                        pageNow: data.page,
                        hasPrevPage: data.hasPrevPage,
                        hasNextPage: data.hasNextPage,
                        totalPages: data.totalPages,
                        totalDocs: data.totalDocs,
                    });

                    dispatch({ type: 'get', payload: data.docs });
                    return Promise.resolve();
                });
            const newStatisticalData = await guestManagerService.statistic({
                event_id: event_id,
                group: guestQuery.group,
                guest_invite: guestQuery.guest_invite,
            });
            guestManagerService.countGuest(event_id).then((data) => {
                setTotalGuest(data.totalGuest);
            });
            setStatisticalData(newStatisticalData);

            return Promise.resolve();
        }
    };
    const getGroups = async (event_id) => {
        if (event_id) {
            await guestManagerService
                .getAllGroups({ ...groupQuery, event_id: event_id })
                .then((data) => {
                    setGroupQuery({
                        ...groupQuery,
                        hasPrevPage: data.hasPrevPage,
                        hasNextPage: data.hasNextPage,
                        totalPages: data.totalPages,
                        totalDocs: data.totalDocs,
                    });
                    setGroups(data.docs);
                });

            const newStatisticalData = await guestManagerService.groupStatistic(event_id);
            setGroupStatisticalData(newStatisticalData);
            return Promise.resolve();
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, [guests, groups, mode, viewMode]);
    const deleteManyGuest = () => {
        if (actionMode === 'many') {
            const newSelect = guestSelect.filter((select) =>
                guests.map(({ _id }) => _id).includes(select._id)
            );
            guestManagerService
                .deleteManyGuest({
                    event_id: eventTemplate.id,
                    ids: newSelect.map(({ _id }) => _id),
                    verify_status: guestQuery.verify_status,
                })
                .then(() => {
                    ToastNotify.Success(`Đã xóa ${newSelect.length} khách mời!`);
                    setGuestSelect(
                        guestSelect.filter(
                            (guest) => !guests.map(({ _id }) => _id).includes(guest._id)
                        )
                    );

                    if (
                        newSelect.length == guests.length &&
                        guestQuery.pageNow == guestQuery.totalPages &&
                        guestQuery.pageNow != 1
                    ) {
                        setGuestQuery({ ...guestQuery, pageNow: (guestQuery.pageNow -= 1) });
                    } else {
                        getGuests(eventTemplate.id);
                    }
                });
        } else {
            guestManagerService
                .deleteManyGuest({
                    event_id: eventTemplate.id,
                    ...(guestQuery.status != '' && { status: guestQuery.status }),
                    ...(guestQuery.fullname != '' && { fullname: guestQuery.fullname }),
                    ...(guestQuery.vip != '' && { vip: guestQuery.vip }),
                    ...(guestQuery.group != '' && { group: guestQuery.group }),
                    ...(guestQuery.verify_status != '' && {
                        verify_status: guestQuery.verify_status,
                    }),
                })
                .then(() => {
                    ToastNotify.Success('Đã xóa tất cả khách mời!');
                    setGuestSelect([]);
                    setActionMode('');
                    if (guestQuery.pageNow != 1) {
                        setGuestQuery({ ...guestQuery, pageNow: 1 });
                    } else {
                        setGuestQuery({ ...guestQuery, pageNow: 1 });
                        getGuests(eventTemplate.id);
                    }
                });
        }
    };

    const deleteAllGuest = () => {
        guestManagerService
            .deleteManyGuest({ event_id: eventTemplate.id, all_status: true })
            .then(() => {
                ToastNotify.Success('Đã xóa tất cả khách mời!');
                setGuestSelect([]);
                setActionMode('');
                if (guestQuery.pageNow != 1) {
                    setGuestQuery({ ...guestQuery, pageNow: 1 });
                } else {
                    setGuestQuery({ ...guestQuery, pageNow: 1 });
                    getGuests(eventTemplate.id);
                    getGroups(eventTemplate.id);
                }
            });
    };

    const removeManyGuestFromGroup = () => {
        guestManagerService
            .removeManyGuestFromGroup({
                ...(actionMode == 'many' &&
                    guestSelect && { guests: guestSelect.map(({ _id }) => _id) }),
                group: guestQuery.group,
            })
            .then(() => {
                ToastNotify.Success('Đã xóa khách mời khỏi nhóm!');
                setGuestSelect([]);
                setActionMode('');
                getGuests(eventTemplate.id);
            });
    };

    const deleteManyGroup = () => {
        if (actionMode === 'many') {
            const newSelectGroup = selectGroups.filter((select) =>
                groups.map(({ _id }) => _id).includes(select._id)
            );
            guestManagerService
                .deleteManyGroup(
                    eventTemplate.id,
                    newSelectGroup.map(({ _id }) => _id)
                )
                .then(() => {
                    ToastNotify.Success(`Đã xóa ${newSelectGroup.length} nhóm khách mời!`);
                    setSelectGroups(
                        selectGroups.filter(
                            (group) => !groups.map(({ _id }) => _id).includes(group._id)
                        )
                    );

                    if (
                        newSelectGroup.length == groups.length &&
                        groupQuery.pageNow == groupQuery.totalPages &&
                        groupQuery.pageNow != 1
                    ) {
                        setGroupQuery({ ...groupQuery, pageNow: (groupQuery.pageNow -= 1) });
                    } else {
                        getGroups(eventTemplate.id);
                    }
                });
        } else {
            guestManagerService.deleteManyGroup(eventTemplate.id).then(() => {
                ToastNotify.Success('Đã xóa tất cả nhóm khách mời!');
                setSelectGroups([]);
                setActionMode('');
                if (groupQuery.pageNow == 1) {
                    getGroups(eventTemplate.id);
                } else {
                    setGroupQuery({ ...groupQuery, pageNow: 1 });
                }
            });
        }
    };

    const deleteOnlyGuest = () => {
        guestManagerService
            .deleteManyGuest({ event_id: eventTemplate.id, ids: [selectOne] })
            .then(() => {
                ToastNotify.Success(
                    `Đã xóa ${
                        guests.find((guest) => guest._id === selectOne)?.title +
                        ' ' +
                        guests.find((guest) => guest._id === selectOne)?.fullname
                    }!`
                );
                setGuestSelect(guestSelect.filter((guest) => guest._id != selectOne));
                setSelectOne('');
                if (
                    guests.length == 1 &&
                    guestQuery.pageNow == guestQuery.totalPages &&
                    guestQuery.pageNow != 1
                ) {
                    setGuestQuery({ ...guestQuery, pageNow: (guestQuery.pageNow -= 1) });
                } else {
                    getGuests(eventTemplate.id);
                }
            });
    };

    const deleteOneGroup = () => {
        guestManagerService.deleteGroup(selectOne).then(() => {
            ToastNotify.Success(
                `Đã xóa nhóm ${groups.find((group) => group._id === selectOne)?.fullname}!`
            );
            setSelectGroups(selectGroups.filter((group) => group._id != selectOne));
            if (viewMode == 'guest') {
                if (guestQuery.group == selectOne) {
                    setGuestQuery({
                        ...guestQuery,
                        group: '',
                        search: '',
                        status: '',
                        pageNow: 1,
                        vip: '',
                        method: '',
                    });
                } else {
                    getGuests(eventTemplate.id);
                }
            }
            setSelectOne('');
            if (
                groups.length == 1 &&
                groupQuery.pageNow == groupQuery.totalPages &&
                groupQuery.pageNow != 1
            ) {
                setGroupQuery({ ...groupQuery, pageNow: (groupQuery.pageNow -= 1) });
            } else {
                getGroups(eventTemplate.id);
            }
        });
    };

    const handleSendInvition = (newMode = '') => {
        setLoadingBtn(true);
        if (viewMode == 'guest') {
            if (newMode === 'not_send_before') {
                const newSelect = guestSelect.filter(
                    (guest) =>
                        guest.status?.find(({ active }) => active === true).status === 'UNINVITED'
                );

                if (newSelect.length == 0) {
                    ToastNotify.Error('Không có khách nào chưa được gửi!');
                } else {
                    guestManagerService
                        .sendInvition(
                            eventTemplate._id,
                            newSelect.map(({ _id }) => _id)
                        )
                        .then(() => {
                            ToastNotify.Success(
                                `Hệ thống đang gửi lời mời tới ${newSelect.length} khách!`
                            );
                            getGuests(eventTemplate.id);
                        })
                        .catch((err) => {
                            ToastNotify.Error(err.message);
                        })
                        .finally(() => {
                            setLoadingBtn(false);
                        });
                }
            } else {
                if (actionMode == 'many') {
                    // const checkEmail = guestSelect.filter((guest)=>guest.email =='')
                    guestManagerService
                        .sendInvition(
                            eventTemplate._id,
                            guestSelect.map(({ _id }) => _id)
                        )
                        .then(() => {
                            ToastNotify.Success(
                                `Hệ thống đang gửi lời mời tới ${guestSelect.length} khách!`
                            );
                            getGuests(eventTemplate.id);
                        })
                        .catch((err) => {
                            ToastNotify.Error(err.message);
                        })
                        .finally(() => {
                            setLoadingBtn(false);
                        });
                }
            }
        } else {
            if (newMode === 'not_send_before') {
                const newSelect = selectGroups.filter(
                    (group) =>
                        group.status?.find(({ active }) => active === true).status === 'UNINVITED'
                );

                if (newSelect.length == 0) {
                    ToastNotify.Error('Không có khách nào chưa được gửi!');
                } else {
                    guestManagerService
                        .sendInvitionToGroup(
                            eventTemplate._id,
                            newSelect.map(({ _id }) => _id)
                        )
                        .then(() => {
                            ToastNotify.Success(
                                `Hệ thống đang gửi lời mời tới ${newSelect.length} nhóm khách!`
                            );
                            getGroups(eventTemplate.id);
                        })
                        .catch((err) => {
                            ToastNotify.Error(err.message);
                        })
                        .finally(() => {
                            setLoadingBtn(false);
                        });
                }
            } else {
                if (actionMode == 'many') {
                    // const checkEmail = selectGroups.filter((guest)=>guest.email =='')
                    guestManagerService
                        .sendInvitionToGroup(
                            eventTemplate._id,
                            selectGroups.map(({ _id }) => _id)
                        )
                        .then(() => {
                            ToastNotify.Success(
                                `Hệ thống đang gửi lời mời tới ${selectGroups.length} nhóm khách!`
                            );
                            getGroups(eventTemplate.id);
                        })
                        .catch((err) => {
                            ToastNotify.Error(err.message);
                        })
                        .finally(() => {
                            setLoadingBtn(false);
                        });
                }
            }
        }
        if (guestQuery.status !== '') {
            setGuestSelect([]);
        }
    };

    const handleSendToOne = () => {
        setLoadingBtn(true);
        if (viewMode == 'guest') {
            guestManagerService
                .sendInvition(eventTemplate._id, [selectOne])
                .then(() => {
                    ToastNotify.Success(
                        `Hệ thống đang gửi lời mời tới ${
                            guests.find((guest) => guest._id === selectOne)?.title +
                            ' ' +
                            guests.find((guest) => guest._id === selectOne)?.fullname
                        }!`
                    );
                    getGuests(eventTemplate.id);
                })
                .catch((err) => {
                    ToastNotify.Error(err.message);
                })
                .finally(() => {
                    setLoadingBtn(false);
                });
        } else {
            guestManagerService
                .sendInvitionToGroup(eventTemplate._id, [selectOne])
                .then(() => {
                    ToastNotify.Success(
                        `Hệ thống đang gửi lời mời tới ${
                            groups.find((group) => group._id === selectOne)?.fullname
                        }!`
                    );
                    getGroups(eventTemplate.id);
                })
                .catch((err) => {
                    ToastNotify.Error(err.message);
                })
                .finally(() => {
                    setLoadingBtn(false);
                });
        }
    };

    const handleSendToOneGroup = () => {
        guestManagerService
            .sendInvitionToGroup(eventTemplate._id, [selectOne])
            .then(() => {
                ToastNotify.Success(
                    `Hệ thống đang gửi lời mời tới ${
                        groups.find((group) => group._id === selectOne)?.fullname
                    }!`
                );
                getGroups(eventTemplate.id);
            })
            .catch((err) => {
                ToastNotify.Error(err.message);
            })
            .finally(() => {
                setLoadingBtn(false);
            });
    };

    const handleSendToAll = (options) => {
        setLoadingBtn(true);
        if (viewMode == 'guest') {
            guestManagerService
                .sendInvitionToAll(eventTemplate._id, options)
                .then(() => {
                    ToastNotify.Success('Hệ thống đang gửi lời mời !');
                    getGuests(eventTemplate.id);
                })
                .catch((err) => {
                    ToastNotify.Error(err.message);
                })
                .finally(() => {
                    setLoadingBtn(false);
                });
        } else {
            guestManagerService
                .sendInvitionToAllToGroup(eventTemplate._id, options)
                .then(() => {
                    ToastNotify.Success('Hệ thống đang gửi lời mời !');
                    getGuests(eventTemplate.id);
                    getGroups(eventTemplate.id);
                })
                .catch((err) => {
                    ToastNotify.Error(err.message);
                })
                .finally(() => {
                    setLoadingBtn(false);
                });
        }
    };

    const handleVerifyOne = (guest, verify_status) => {
        guestManagerService
            .VerifyGuest({
                ids: [guest._id],
                verify_status: verify_status,
                event_id: eventTemplate.id,
            })
            .then(() => {
                if (verify_status == 'USER_ACCEPT') {
                    ToastNotify.Success(
                        `Đã đồng ý yêu cầu tham dự của ${guest.title + ' ' + guest.fullname}`
                    );
                } else {
                    ToastNotify.Success(
                        `Đã từ chối yêu cầu tham dự của ${guest.title + ' ' + guest.fullname}`
                    );
                }
                getGuests(eventTemplate.id);
            });
    };

    const handleVerifyMany = (verify_status) => {
        if (actionMode == 'many') {
            guestManagerService
                .VerifyGuest({
                    ids: guestSelect.map(({ _id }) => _id),
                    verify_status: verify_status,
                    event_id: eventTemplate.id,
                })
                .then(() => {
                    if (verify_status == 'USER_ACCEPT') {
                        ToastNotify.Success(
                            `Đã đồng ý yêu cầu tham dự của ${guestSelect.length} khách mời`
                        );
                    } else {
                        ToastNotify.Success(
                            `Đã từ chối yêu cầu tham dự của ${guestSelect.length} khách mời`
                        );
                    }
                    setGuestSelect([]);
                    getGuests(eventTemplate.id);
                });
        } else {
            guestManagerService
                .VerifyGuest({
                    verify_status: verify_status,
                    event_id: eventTemplate.id,
                })
                .then(() => {
                    if (verify_status == 'USER_ACCEPT') {
                        ToastNotify.Success('Đã đồng ý yêu cầu tham dự của tất cả khách mời');
                    } else {
                        ToastNotify.Success('Đã từ chối yêu cầu tham dự của tất cả khách mời');
                    }
                    setGuestSelect([]);
                    setActionMode('');
                    getGuests(eventTemplate.id);
                });
        }
    };

    const refFile = useRef(null);
    const refBtnImportText = useRef(null);
    const refBtnDownloadText = useRef(null);

    const makeUrlShare = useCallback((urlSlug, guestKey = undefined) => {
        let rawUrl =
            envConstants.CLIENT_URL.slice(0, envConstants.CLIENT_URL.lastIndexOf('/')) +
            PAGE.publish;
        if (urlSlug) {
            rawUrl = rawUrl.replace(':url_share', urlSlug);
            if (guestKey) {
                return rawUrl + '/' + guestKey;
            }
            return rawUrl;
        }
        return '';
    }, []);

    const formik = useFormik({
        initialValues: INIT_FORM_STATE,
        validationSchema: Yup.object({
            fullname: Yup.string()
                .trim()
                .required('Vui lòng nhập tên khách mời.')
                .max(100, 'Vui lòng nhập tên khách mời không vượt quá 100 ký tự!'),
            subname: Yup.string()
                .trim()
                .max(100, 'Vui lòng nhập tên người cùng tham dự không vượt quá 100 ký tự!'),
            title: Yup.string().trim().max(20, 'Vui lòng nhập danh xưng không vượt quá 20 ký tự!'),
            salutation: Yup.string()
                .trim()
                .max(100, 'Vui lòng nhập lời mời không vượt quá 100 ký tự!'),
            position: Yup.string()
                .trim()
                .max(100, 'Vui lòng nhập vị trí không vượt quá 100 ký tự!'),
            company_name: Yup.string()
                .trim()
                .max(300, 'Vui lòng nhập tên công ty không vượt quá 300 ký tự!'),
            note: Yup.string().trim().max(500, 'Vui lòng nhập ghi chú không vượt quá 500 ký tự!'),
            email: Yup.string()
                .matches(
                    Regex.email,
                    'Vui lòng nhập email hợp lệ bao gồm chữ cái (a-z), số (0-9), dấu chấm (.), và dấu a còng (@).'
                )
                .max(100, 'Vui lòng nhập email không vượt quá 100 ký tự!'),
            phone_number: Yup.string()
                .matches(Regex.phone_number, 'Vui lòng nhập số điện thoại không lệ!')
                .max(15, 'Vui lòng nhập số điện thoại không vượt quá 15 kí tự!'),
        }),
        validateOnBlur: false,
    });

    const handleSubmitGuest = () => {
        const { values, resetForm } = formik;
        console.log('values', values);
        Object.keys(values).forEach((k) => {
            typeof values[k] === 'string'
                ? (values[k] = TrimSpace(values[k]))
                : (values[k] = values[k]);
        });

        onSubmitForm({ ...values }).then(() => {
            if (mode == 'guest-add') {
                resetForm();
                setMode('default');
            } else {
                onReadGuest(values._id);
                setMode('guest-read');
            }
        });
    };

    const groupFormik = useFormik({
        initialValues: INIT_GROUP_FORM,
        validationSchema: Yup.object({
            fullname: Yup.string()
                .trim()
                .required('Vui lòng nhập tên nhóm.')
                .max(100, 'Vui lòng nhập tên nhóm không vượt quá 100 ký tự!'),
            salutation: Yup.string()
                .trim()
                .max(100, 'Vui lòng nhập lời mời không vượt quá 100 ký tự!'),
            note: Yup.string().trim().max(500, 'Vui lòng nhập ghi chú không vượt quá 500 ký tự!'),
            email: Yup.string()
                .matches(
                    Regex.email,
                    'Vui lòng nhập email hợp lệ bao gồm chữ cái (a-z), số (0-9), dấu chấm (.), và dấu a còng (@).'
                )
                .max(100, 'Vui lòng nhập email không vượt quá 100 ký tự!'),
            phone_number: Yup.string()
                .matches(Regex.phone_number, 'Vui lòng nhập số điện thoại không hợp lệ!')
                .max(15, 'Vui lòng nhập số điện thoại không vượt quá 15 kí tự!'),
        }),
    });

    const handleSubmitGroup = () => {
        const { values, resetForm } = groupFormik;
        Object.keys(values).forEach((k) => {
            typeof values[k] === 'string'
                ? (values[k] = TrimSpace(values[k]))
                : (values[k] = values[k]);
        });
        onSubmitGroup({ ...values }).then(async () => {
            if (mode == 'group-add') {
                await getGroups(eventTemplate.id);
                setMode('group-default');
                resetForm();
            } else {
                await getGroups(eventTemplate.id);
                onReadGroup(values._id);
            }
        });
    };

    const handleAddGuest = () => {
        formik.resetForm();
        setMode('guest-add');
    };
    const onUpdateGuest = async (guestId) => {
        const guest = await guestManagerService.getGuestById(guestId);
        const newForm = {
            _id: guestId,
            fullname: guest.fullname || '',
            subname: guest.subname || '',
            title: guest.title || '',
            salutation: guest.salutation || '',
            note: guest.note || '',
            slug: guest.slug || '',
            company_name: guest.company_name || '',
            email: guest.email || '',
            phone_number: guest.phone_number || '',
            is_important: guest.is_important || false,
            gender: guest.gender || 'MALE',
            position: guest.position || '',
            group: guest.group,
            status: guest.status?.find(({ active }) => active === true)?.status,
            // verify_status: guest.verify_status?.find(({ active }) => active === true)?.status,
        };
        formik.setValues(newForm);

        setMode('guest-update');
    };

    const onUpdateGroup = async (groupId) => {
        const guest = await guestManagerService.findGroup(groupId);
        const newGroupForm = {
            _id: groupId,
            fullname: guest.fullname || '',
            title: guest.title || '',
            salutation: guest.salutation || '',
            note: guest.note || '',
            slug: guest.slug || '',
            company_name: guest.company_name || '',
            email: guest.email || '',
            phone_number: guest.phone_number || '',
            is_important: guest.is_important || false,
            position: guest.position || '',
            status: guest.status?.find(({ active }) => active === true)?.status,
        };
        groupFormik.setValues(newGroupForm);

        setViewMode('group');
        setGuestQuery({ ...guestQuery, group: '' });
        setMode('group-update');
    };

    const onReadGuest = async (guestId) => {
        const guest = await guestManagerService.getGuestById(guestId);
        // console.log(guest);
        formik.setValues({
            _id: guestId,
            fullname: guest.fullname || '',
            subname: guest.subname || '',
            title: guest.title || '',
            salutation: guest.salutation || '',
            note: guest.note || '',
            slug: guest.slug || '',
            company_name: guest.company_name || '',
            email: guest.email || '',
            phone_number: guest.phone_number || '',
            is_important: guest.is_important || false,
            gender: guest.gender || 'MALE',
            position: guest.position || '',
            status: guest.status?.find(({ active }) => active === true)?.status,
            updatedAt: !guestQuery.verify_status
                ? guest.status?.find(({ active }) => active === true)?.updatedAt
                : guest.verify_status?.find(({ active }) => active === true)?.createdAt,
            updatedBy:
                guest.status?.find(({ active }) => active === true)?.updatedBy?.name ||
                'Khách mời cập nhập',
            method: guest.status?.find(({ active }) => active === true)?.method,
            message: guest.status?.find(({ active }) => active === true)?.message,
            infoCreateAt: guest.createdAt,
            infoCreateBy: guest.createdBy?.user_name
                ? guest.createdBy?.user_name
                : guest.group.length != 0
                ? `Nhóm ${guest.group[0].fullname}`
                : 'Khách mời đăng ký',
            infoUpdateAt: guest.updatedInfoAt,
            infoUpdateBy:
                guest.updatedBy?.user_name && guest.updatedBy ? guest.updatedBy?.user_name : '',
            verify_status: guest.verify_status?.find(({ active }) => active === true)?.status,
            group: guest.group,
            createdMethod: guest.createdMethod,
            guest_invite: guest.guest_invite,
        });

        setMode('guest-read');
    };

    const onReadGroup = async (groupId) => {
        console.log(groupId);
        const group = await guestManagerService.findGroup(groupId);

        groupFormik.setValues({
            _id: groupId,
            fullname: group.fullname || '',
            title: group.title || '',
            salutation: group.salutation || '',
            note: group.note || '',
            slug: group.slug || '',
            company_name: group.company_name || '',
            email: group.email || '',
            phone_number: group.phone_number || '',
            is_important: group.is_important || false,
            position: group.position || '',
            status: group.status?.find(({ active }) => active === true)?.status,
            updatedAt: group.status?.find(({ active }) => active === true)?.updatedAt,
            updatedBy: group.status?.find(({ active }) => active === true)?.updatedBy.name,
            method: group.status?.find(({ active }) => active === true)?.method,
            message: group.status?.find(({ active }) => active === true)?.message,
            infoUpdateAt: group.updatedInfoAt,
            infoCreatedAt: group.createdAt,
        });
        setViewMode('group');
        setGuestQuery({ ...guestQuery, group: '' });
        setMode('group-read');
    };

    const handleCreateGroup = () => {
        groupFormik.resetForm();
        setMode('group-add');
    };

    const handleRadioButtons = (e) => {
        if (mode == 'guest-add' || mode == 'guest-update') {
            if (e.target.name === 'vip') {
                formik.values.is_important = e.target.value.toLowerCase() === 'true';
            } else {
                formik.values.gender = e.target.value;
            }
        }
        if (mode == 'group-add' || mode == 'group-update') {
            groupFormik.values.is_important = e.target.value.toLowerCase() === 'true';
        }
    };
    const onSubmitForm = async (values) => {
        let check = false;
        const eventId = eventTemplate.id;

        if (eventTemplate.status === 'draft') {
            ToastNotify.Warning('Thiệp bạn đang sử dụng vẫn đang ở trạng thái bản nháp.');
            return;
        }
        let promise = undefined;
        let msgSuccess = '';
        if (values._id) {
            const { _id, status, ...payload } = values;
            let newPayload = { ...payload };
            if (formik.values.group && formik.values.group?.length != 0) {
                newPayload = { ...payload, group: formik.values.group[0]._id };
            }
            promise = updateGuest(values._id, {
                ...(status != 'SENDING' && { status: status }),
                ...newPayload,
            });
            await promise
                .then((value) => {
                    check = true;
                    console.log(value);
                })
                .catch((error) => {
                    console.log(error);
                    check = false;
                });
            msgSuccess = 'Sửa thông tin khách mời thành công';
        } else {
            const { ...payload } = values;
            if (guestQuery.group) {
                const newPayload = { ...payload, group: [guestQuery.group] };
                promise = addNewGuests({
                    event_id: eventId,
                    guests: [newPayload],
                });
                await promise
                    .then((value) => {
                        check = true;
                        console.log(value);
                    })
                    .catch((error) => {
                        console.log(error);
                        check = false;
                    });
                msgSuccess = 'Thêm khách mời thành công';
            } else {
                let newPayload = { ...payload };
                if (formik.values.group && formik.values.group?.length != 0) {
                    newPayload = { ...payload, group: formik.values.group[0]._id };
                }
                promise = addNewGuests({
                    event_id: eventId,
                    guests: [newPayload],
                });

                await promise
                    .then((value) => {
                        check = true;
                        console.log(value);
                    })
                    .catch((error) => {
                        console.log('error ----', error);
                        setMessageError(error.message);
                        btnOpenModal.current.click();
                        console.log(error);
                        check = false;
                    });

                msgSuccess = 'Thêm khách mời thành công';
            }
        }

        if (check) {
            await ToastNotify.Promise(promise, 'Đang lưu...', msgSuccess);
            getGuests(eventTemplate.id);
        }
    };
    const onSubmitGroup = async (values) => {
        const eventId = eventTemplate.id;

        if (eventTemplate.status === 'draft') {
            ToastNotify.Warning('Thiệp bạn đang sử dụng vẫn đang ở trạng thái bản nháp!');
            return;
        }
        let promise = undefined;
        let msgSuccess = '';
        if (values._id) {
            const { _id, status, ...payload } = values;
            promise = guestManagerService.updateGroup(values._id, {
                ...(status != 'SENDING' && { status: status }),
                ...payload,
            });
            msgSuccess = 'Sửa thông tin nhóm khách mời thành công!';
        } else {
            const { _id, ...payload } = values;
            promise = guestManagerService.createGroup({
                event_id: eventId,
                ...payload,
            });
            msgSuccess = 'Đã tạo nhóm khách mời!';
        }
        await ToastNotify.Promise(promise, 'Đang lưu...', msgSuccess);
        return Promise.resolve();
    };

    const onAddToGroup = async (ids) => {
        const guest = await guestManagerService.getGuestById(selectOne);
        const newGroup = guest.group.map(({ _id }) => _id).concat(ids);
        await updateGuest(guest._id, {
            ...guest,
            status: guest.status?.find(({ active }) => active === true)?.status,
            group: newGroup,
        }).then(() => {
            getGuests(eventTemplate.id);
            ToastNotify.Success('Đã thêm khách mời vào nhóm!');
        });
    };

    const onRemoveFromGroup = async () => {
        const guest = guests.find((guest) => guest._id == selectOne);
        const newGroup = guest.group.filter(({ _id }) => _id != guestQuery.group);
        console.log(newGroup);
        await updateGuest(guest._id, {
            ...guest,
            status: guest.status?.find(({ active }) => active === true)?.status,
            group: newGroup,
        }).then(() => {
            getGuests(eventTemplate.id);
            ToastNotify.Success('Đã xóa khách mời khỏi nhóm!');
        });
    };

    const onOpenChooseFile = () => {
        if (refFile) {
            refFile.current.value = null;
            refFile.current.click();
        }
    };

    const onDownloadExcel = () => {
        downloadExcel({
            event_id: eventTemplate._id,
            status: guestQuery.status,
            fullname: guestQuery.fullname,
            vip: guestQuery.vip,
            group: guestQuery.group,
        }).then(({ fileData, filename }) => {
            const href = URL.createObjectURL(fileData);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(link);

            // setTimeout(() => {
            //     refBtnDownloadText.current.innerText = 'Tải lại danh sách khách mời';
            // }, 300);
        });
    };

    const onDownloadExcelTemplate = () => {
        window.location.href =
            envConstants.SERVER_URL + '/public/shared/mau-upload-danh-sach-khach-moi.xlsx';
    };
    const onCopyInvite = (id) => {
        if (viewMode == 'guest') {
            const urlSlug = eventTemplate?.url_slug;
            const guest = guests.find(({ _id }) => _id === id);
            const urlShare = makeUrlShare(urlSlug, guest?.slug);
            if (!urlShare) {
                return;
            }
            let text;
            if (guest) {
                const preHeader = [guest.salutation, guest.title, guest.fullname]
                    .filter((e) => e)
                    .join(' ');
                text = `${preHeader}\nTới tham dự sự kiện: ${eventTemplate.form_data.nameEvent} tại đường link sau: ${urlShare}`;
            } else {
                text = `Trân trọng kính mời quý khách\nTới tham dự sự kiện: ${eventTemplate.form_data.nameEvent} tại đường link sau: ${urlShare}`;
            }
            if (navigator.clipboard && window.isSecureContext) {
                navigator.clipboard.writeText(text).then(() => {
                    ToastNotify.Info('Đã sao chép lời mời');
                });
            } else {
                let textArea = document.createElement('textarea');
                textArea.value = text;
                textArea.style.position = 'fixed';
                textArea.style.left = '-999999px';
                textArea.style.top = '-999999px';
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                new Promise((res, rej) => {
                    document.execCommand('copy') ? res() : rej();
                    textArea.remove();
                }).then(() => {
                    ToastNotify.Info('Đã sao chép lời mời');
                });
            }
        } else {
            const urlSlug = eventTemplate?.url_slug;
            const group = groups.find(({ _id }) => _id === id);
            const urlShare = makeUrlShare(urlSlug, group?.slug);
            if (!urlShare) {
                return;
            }
            let text;
            if (group) {
                const preHeader = [group.salutation || 'Trân trọng kính mời', group.fullname]
                    .filter((e) => e)
                    .join(' ');
                text = `${preHeader}\nTới tham dự sự kiện: ${eventTemplate.form_data.nameEvent} tại đường link sau: ${urlShare}`;
            } else {
                text = `Trân trọng kính mời quý khách\nTới tham dự sự kiện: ${eventTemplate.form_data.nameEvent} tại đường link sau: ${urlShare}`;
            }
            if (navigator.clipboard && window.isSecureContext) {
                navigator.clipboard.writeText(text).then(() => {
                    ToastNotify.Info('Đã sao chép lời mời');
                });
            } else {
                let textArea = document.createElement('textarea');
                textArea.value = text;
                textArea.style.position = 'fixed';
                textArea.style.left = '-999999px';
                textArea.style.top = '-999999px';
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                new Promise((res, rej) => {
                    document.execCommand('copy') ? res() : rej();
                    textArea.remove();
                }).then(() => {
                    ToastNotify.Info('Đã sao chép lời mời');
                });
            }
        }
    };

    const onCopyLink = (id, isLinkConfirm = false) => {
        const urlSlug = eventTemplate?.url_slug;
        if (viewMode == 'guest') {
            const guest = guests.find(({ _id }) => _id === id);
            let urlShare = makeUrlShare(urlSlug, guest?.slug);
            if (isLinkConfirm) {
                urlShare += '/xac-nhan';
            }
            if (!urlShare) {
                return;
            }
            if (navigator.clipboard && window.isSecureContext) {
                navigator.clipboard.writeText(urlShare).then(() => {
                    ToastNotify.Info(
                        isLinkConfirm
                            ? 'Đã sao chép dường dẫn xác nhận tham dự'
                            : 'Đã sao chép đường dẫn'
                    );
                });
            } else {
                let textArea = document.createElement('textarea');
                textArea.value = urlShare;
                textArea.style.position = 'fixed';
                textArea.style.left = '-999999px';
                textArea.style.top = '-999999px';
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                new Promise((res, rej) => {
                    document.execCommand('copy') ? res() : rej();
                    textArea.remove();
                }).then(() => {
                    ToastNotify.Info(
                        isLinkConfirm
                            ? 'Đã sao chép dường dẫn xác nhận tham dự'
                            : 'Đã sao chép đường dẫn'
                    );
                });
            }
        } else {
            const group = groups.find(({ _id }) => _id === id);
            let urlShare = makeUrlShare(urlSlug, group?.slug);
            if (isLinkConfirm) {
                urlShare += '/xac-nhan';
            }
            if (!urlShare) {
                return;
            }
            if (navigator.clipboard && window.isSecureContext) {
                navigator.clipboard.writeText(urlShare).then(() => {
                    ToastNotify.Info(
                        isLinkConfirm
                            ? 'Đã sao chép dường dẫn xác nhận tham dự'
                            : 'Đã sao chép đường dẫn'
                    );
                });
            } else {
                let textArea = document.createElement('textarea');
                textArea.value = urlShare;
                textArea.style.position = 'fixed';
                textArea.style.left = '-999999px';
                textArea.style.top = '-999999px';
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                new Promise((res, rej) => {
                    document.execCommand('copy') ? res() : rej();
                    textArea.remove();
                }).then(() => {
                    ToastNotify.Info(
                        isLinkConfirm
                            ? 'Đã sao chép dường dẫn xác nhận tham dự'
                            : 'Đã sao chép đường dẫn'
                    );
                });
            }
        }
    };

    const refFrame = useRef(null);
    const onDownloadTemplate = (e) => {
        if (refFrame.current === null || !eventTemplate.is_public) {
            ToastNotify.Error('Thiệp chưa được công khai');
            return;
        }

        const oldHTML = e.currentTarget.innerHTML;
        async function htmlToPng() {
            return toPng(refFrame.current, { cacheBust: false })
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.download =
                        (viewMode === 'guest'
                            ? (formik?.values?.title + ' ' + formik?.values?.fullname)
                                  .trim()
                                  .replace(/\s+/g, '_')
                            : groupFormik?.values?.fullname
                        )
                            .trim()
                            .replace(/\s+/g, '_') + '.png';
                    link.href = dataUrl;
                    link.click();
                })
                .finally(() => {
                    e.target.innerHTML = oldHTML;
                });
        }
        setTimeout(() => {
            ToastNotify.Promise(htmlToPng(), 'Loading...', 'Tải ảnh thành công.');
        }, 300);
    };

    const onCopyImage = (e) => {
        if (refFrame.current === null || !eventTemplate.is_public) {
            ToastNotify.Error('Thiệp chưa được công khai');
            return;
        }
        const guestId = e.currentTarget.getAttribute('itemID');
        const oldHTML = e.currentTarget.innerHTML;

        async function convertFrameToImage() {
            return toBlob(refFrame.current, { cacheBust: false }).then(async (blob) => {
                if (navigator.clipboard && window.isSecureContext) {
                    navigator.clipboard
                        .write([
                            // eslint-disable-next-line no-undef
                            new ClipboardItem({
                                [blob.type]: blob,
                            }),
                        ])
                        .catch((error) => console.log(error))
                        .finally(() => {
                            e.target.innerHTML = oldHTML;
                        });
                } else {
                    throw Error('Trình duyệt không hỗ trợ sao chép ảnh!');
                }
            });
        }

        setTimeout(() => {
            ToastNotify.Promise(convertFrameToImage(), 'Loading...', 'Sao chép ảnh thành công');
        }, 300);
    };

    const qrRef = useRef(null);
    const onDownloadQr = (e) => {
        if (qrRef.current === null || !eventTemplate.is_public) {
            ToastNotify.Error('Thiệp chưa được công khai');
            return;
        }

        const oldHTML = e.currentTarget.innerHTML;
        async function htmlToPng() {
            return toPng(qrRef.current, { cacheBust: false })
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.download =
                        (viewMode === 'guest'
                            ? (formik?.values?.title + ' ' + formik?.values?.fullname)
                                  .trim()
                                  .replace(/\s+/g, '_')
                            : groupFormik?.values?.fullname
                        )
                            .trim()
                            .replace(/\s+/g, '_') + '_qr.png';
                    link.href = dataUrl;
                    link.click();
                })
                .finally(() => {
                    e.target.innerHTML = oldHTML;
                });
        }
        setTimeout(() => {
            ToastNotify.Promise(htmlToPng(), 'Loading...', 'Tải ảnh thành công.');
        }, 300);
    };
    const onChangeFileSelect = () => {
        const files = refFile.current.files;
        // User cancle choose file or close dialog
        if (files.length === 0) {
            // refBtnImportText.current.innerText = 'Tạo bằng Excel'
            return;
        }
        // Get first File
        const [file] = files;
        const fileName = file.name;
        // refBtnImportText.current.innerText = fileName;

        fetchUploadFile(file);
    };
    const fetchUploadFile = async (file) => {
        const formData = new FormData();
        if (importMode === 'replace') {
            if (guestQuery.group) {
                await guestManagerService.deleteManyGuest({
                    event_id: eventTemplate.id,

                    group: guestQuery.group,
                });
            } else {
                await guestManagerService.deleteManyGuest({ event_id: eventTemplate.id });
            }
        }
        formData.append('file', file);
        formData.append('event_id', eventTemplate.id);
        if (guestQuery.group && viewMode == 'guest') {
            formData.append('group', guestQuery.group);
        }
        if (viewMode == 'guest') {
            return ToastNotify.Promise(
                importExcelGuest(formData),
                'Hệ thống đang lưu thông tin khách mời',
                'Đã cập nhật danh sách khách mời thành công'
            )
                .then(() => {
                    getGuests(eventTemplate.id);
                    return;
                })
                .catch((err) => {
                    // ToastNotify.Error(err.message);
                });
        } else {
            ToastNotify.Promise(
                importExcelGroup(formData),
                'Hệ thống đang lưu thông tin nhóm khách mời',
                'Đã cập nhật danh sách nhóm khách mời thành công'
            ).then(() => {
                getGroups(eventTemplate.id);
                return;
            });
            // .catch((err) => {
            //     ToastNotify.Error(err.message);
            // });
        }
    };

    //Verify guest

    const [verifyGuest, setVerifyGuest] = useState();

    const handleGetVerifyGuest = () => {
        guestManagerService
            .getAllGuests({
                event_id: eventTemplate._id,
                pageNow: 1,
                verify_status: 'VERIFYING',
                limit: 10000,
            })
            .then((data) => {
                setVerifyGuest(data.docs);
            });
    };
    // console.log(guestSelect);

    useEffect(() => {
        if (!loggedIn) {
            const beforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = true;
            };
            window.addEventListener('beforeunload', beforeUnload);
            return () => {
                window.removeEventListener('beforeunload', beforeUnload);
            };
        }
    }, []);

    const onGoToUpdate = () => {
        setMessageError('');
        navigate(PAGE.profile);
    };

    return (
        <>
            <div className="relative flex h-auto w-[100vw] flex-col justify-center gap-[20px] lg:pt-[80px] 2xl:pt-[100px]">
                <Header event={eventTemplate} isExpired={eventTemplate?.isExpired} />
                {loading ? (
                    <GuestManagerLoading />
                ) : (
                    <div className="flex h-auto w-[100%] bg-gray-50 ">
                        <div className=" w-full flex justify-center gap-[10px]">
                            <div className="lg:flex hidden relative w-[250px]">
                                <TimelineShortcutMenu screen={'guest-manage'} />
                            </div>
                            <div className="w-full lg:w-[48rem] 2xl:w-[72rem] border-[1px] border-slate-200 bg-white-box shadow-lg rounded-lg p-2">
                                <div className="flex w-full justify-between items-center">
                                    <div className="flex items-center">
                                        {(guestQuery.group || guestQuery.guest_invite) && (
                                            <button
                                                type="button"
                                                data-mdb-ripple="true"
                                                data-mdb-ripple-color="light"
                                                title="Trở lại"
                                                className="inline-block px-3 py-2 mr-2 text-xs font-medium leading-tight text-white uppercase transition duration-150 ease-in-out bg-blue-700 rounded hover:bg-blue-700 focus:bg-blue-800"
                                                onClick={() => {
                                                    setViewMode('group');

                                                    setMode('group-default');
                                                    setGuestQuery(defaultQuery);
                                                }}
                                            >
                                                <FaArrowLeft />
                                            </button>
                                        )}
                                        <p className="text-lg font-bold text-black ">
                                            Quản lý khách mời
                                            <span>
                                                {guestQuery.group &&
                                                    ` (Nhóm ${
                                                        groups.find(
                                                            (group) => group._id == guestQuery.group
                                                        )?.fullname
                                                    })`}
                                                {guestQuery.guest_invite &&
                                                    ` (Được mời bởi ${guestQuery.invited_by})`}
                                            </span>
                                        </p>
                                    </div>
                                    {!guestQuery.verify_status && (
                                        <button
                                            id="dropdownMenuButtonSendMail"
                                            aria-expanded="false"
                                            className={` bg-blue-500 cursor-pointer flex items-center gap-2 px-[10px] py-[6px] rounded-md text-xs text-white  font-normal`}
                                            data-bs-toggle="modal"
                                            data-bs-target={
                                                viewMode == 'guest'
                                                    ? '#chartModal'
                                                    : '#groupChartModal'
                                            }
                                        >
                                            <FaChartPie />
                                            Thống kê
                                        </button>
                                    )}
                                </div>

                                {/* <QuestionTitle keyQues={'guest'} /> */}
                                <div className="tab-content" id="tabs-tabContent">
                                    {viewMode == 'guest' && (
                                        <div>
                                            {mode == 'default' && (
                                                <GuestTable
                                                    guests={guests}
                                                    eventTemplate={eventTemplate}
                                                    formik={formik}
                                                    setMode={setMode}
                                                    dispatch={dispatch}
                                                    onCopyInvite={onCopyInvite}
                                                    refFrame={refFrame}
                                                    onDownloadTemplate={onDownloadTemplate}
                                                    onCopyLink={onCopyLink}
                                                    onCopyImage={onCopyImage}
                                                    guestSelect={guestSelect}
                                                    getGuests={getGuests}
                                                    guestQuery={guestQuery}
                                                    setGuestQuery={setGuestQuery}
                                                    setGuestSelect={setGuestSelect}
                                                    handleSendInvition={handleSendInvition}
                                                    loadingBtn={loadingBtn}
                                                    actionMode={actionMode}
                                                    setActionMode={setActionMode}
                                                    selectOne={selectOne}
                                                    setSelectOne={setSelectOne}
                                                    onUpdateGuest={onUpdateGuest}
                                                    onReadGuest={onReadGuest}
                                                    sort={sort}
                                                    setSort={setSort}
                                                    groups={groups}
                                                    setModalOpen={setModalOpen}
                                                    handleAddGuest={handleAddGuest}
                                                    viewMode={viewMode}
                                                    setViewMode={setViewMode}
                                                    selectGroups={selectGroups}
                                                    removeManyGuestFromGroup={
                                                        removeManyGuestFromGroup
                                                    }
                                                    onOpenChooseFile={onOpenChooseFile}
                                                    refBtnDownloadText={refBtnDownloadText}
                                                    onDownloadExcel={onDownloadExcel}
                                                    onDownloadExcelTemplate={
                                                        onDownloadExcelTemplate
                                                    }
                                                    refFile={refFile}
                                                    onChangeFileSelect={onChangeFileSelect}
                                                    statisticalData={statisticalData}
                                                    setImportMode={setImportMode}
                                                    resetAndAddGuestQuery={resetAndAddGuestQuery}
                                                    handleGetVerifyGuest={handleGetVerifyGuest}
                                                    setIsChangeTimer={setIsChangeTimer2}
                                                    setTrigger={setTrigger}
                                                    trigger={trigger}
                                                />
                                            )}
                                            {(mode == 'guest-add' ||
                                                mode == 'guest-update' ||
                                                mode == 'guest-read') && (
                                                <div className="flex w-[100%] justify-center">
                                                    <div
                                                        className={`w-full lg:w-[48rem] 2xl:w-[80rem] ${
                                                            mode !== 'guest-read' && 'pb-[80px]'
                                                        }`}
                                                    >
                                                        <GuestForm
                                                            formik={formik}
                                                            handleRadioButtons={handleRadioButtons}
                                                            setMode={setMode}
                                                            mode={mode}
                                                            onUpdateGuest={onUpdateGuest}
                                                            onReadGuest={onReadGuest}
                                                            groups={groups}
                                                            guestQuery={guestQuery}
                                                            onDownloadTemplate={onDownloadTemplate}
                                                            onCopyInvite={onCopyInvite}
                                                            onCopyLink={onCopyLink}
                                                            onCopyImage={onCopyImage}
                                                            setModalOpen={setModalOpen}
                                                            eventTemplate={eventTemplate}
                                                            makeUrlShare={makeUrlShare}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {viewMode == 'group' && (
                                        <div>
                                            <div
                                                className="active show tab-pane"
                                                id="tabs-guest"
                                                role="tabpanel"
                                                aria-labelledby="tabs-guest-tab"
                                            >
                                                {mode == 'group-default' && (
                                                    <GroupList
                                                        groups={groups}
                                                        query={groupQuery}
                                                        setQuery={setGroupQuery}
                                                        eventTemplate={eventTemplate}
                                                        onCopyInvite={onCopyInvite}
                                                        onCopyLink={onCopyLink}
                                                        onCopyImage={onCopyImage}
                                                        onDownloadTemplate={onDownloadTemplate}
                                                        setSelectOne={setSelectOne}
                                                        selectGroups={selectGroups}
                                                        setSelectGroups={setSelectGroups}
                                                        actionMode={actionMode}
                                                        setActionMode={setActionMode}
                                                        onUpdateGroup={onUpdateGroup}
                                                        onReadGroup={onReadGroup}
                                                        getGroups={getGroups}
                                                        guestQuery={guestQuery}
                                                        setGuestQuery={setGuestQuery}
                                                        setViewMode={setViewMode}
                                                        setMode={setMode}
                                                        handleCreateGroup={handleCreateGroup}
                                                        resetAndAddGuestQuery={
                                                            resetAndAddGuestQuery
                                                        }
                                                        onOpenChooseFile={onOpenChooseFile}
                                                        onDownloadExcel={onDownloadExcel}
                                                        onDownloadExcelTemplate={
                                                            onDownloadExcelTemplate
                                                        }
                                                        statisticalData={statisticalData}
                                                        refFile={refFile}
                                                        setTrigger={setTrigger}
                                                        trigger={trigger}
                                                    />
                                                )}
                                                {(mode == 'group-add' ||
                                                    mode == 'group-update' ||
                                                    mode == 'group-read') && (
                                                    <div className="flex justify-center w-full">
                                                        <div className="w-full lg:w-[48rem] 2xl:w-[80rem]">
                                                            {mode != 'config' && (
                                                                <GroupForm
                                                                    onDownloadTemplate={
                                                                        onDownloadTemplate
                                                                    }
                                                                    onCopyInvite={onCopyInvite}
                                                                    onCopyLink={onCopyLink}
                                                                    onCopyImage={onCopyImage}
                                                                    formik={groupFormik}
                                                                    handleRadioButtons={
                                                                        handleRadioButtons
                                                                    }
                                                                    setMode={setMode}
                                                                    mode={mode}
                                                                    onUpdateGroup={onUpdateGroup}
                                                                    onReadGroup={onReadGroup}
                                                                    groups={groups}
                                                                    eventTemplate={eventTemplate}
                                                                    makeUrlShare={makeUrlShare}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="lg:flex hidden relative w-[250px]">
                                <Products />
                            </div>
                        </div>
                        <ComposeContentEmail eventTemplate={eventTemplate} />
                        <SettingEmailModal
                            eventTemplate={eventTemplate}
                            setTrigger={setTrigger}
                            trigger={trigger}
                        />
                        <RemindModal eventTemplate={eventTemplate} />
                    </div>
                )}
                {/* <ShortcutMenu screen={'guest-manage'} /> */}
            </div>

            <ConfirmDeleteModal
                deleteManyGuest={deleteManyGuest}
                guestSelect={guestSelect}
                guests={guests}
                actionMode={actionMode}
                setActionMode={setActionMode}
                viewMode={viewMode}
                deleteManyGroup={deleteManyGroup}
                groups={groups}
                selectGroups={selectGroups}
            />
            <ConfirmSendEmail
                guestSelect={guestSelect}
                selectGroups={selectGroups}
                guests={guests}
                groups={groups}
                actionMode={actionMode}
                setActionMode={setActionMode}
                selectOne={selectOne}
                setSelectOne={setSelectOne}
                handleSendInvition={handleSendInvition}
                handleSendToAll={handleSendToAll}
                viewMode={viewMode}
            />
            <ConfirmImportModal
                onOpenChooseFile={onOpenChooseFile}
                refFile={refFile}
                setImportMode={setImportMode}
                refBtnImportText={refBtnImportText}
                onChangeFileSelect={onChangeFileSelect}
            />
            <ConfirmGuest formik={formik} mode={mode} handleSubmitGuest={handleSubmitGuest} />
            <ConfirmBackModal
                viewMode={viewMode}
                setMode={setMode}
                formik={viewMode === 'guest' ? formik : groupFormik}
                mode={mode}
                onReadGuest={onReadGuest}
                onReadGroup={onReadGroup}
            />
            <GuestChartModal
                resetAndAddGuestQuery={resetAndAddGuestQuery}
                guestQuery={guestQuery}
                groups={groups}
                setGroupQuery={setGroupQuery}
                statisticalData={statisticalData}
            />
            <GroupChartModal
                resetAndAddGroupQuery={resetAndAddGroupQuery}
                groups={groups}
                statisticalData={groupStatisticalData}
                event_id={eventTemplate.id}
            />
            <ConfirmAddEmailModal />
            <ConfirmAddEmail4Only />
            <ConfirmSendOnlyGuest
                handleSendToOne={handleSendToOne}
                selectOne={selectOne}
                guests={guests}
                groups={groups}
                viewMode={viewMode}
            />
            <SetTimerSendMailModal
                guests={guests}
                guestId={selectOne}
                eventId={eventTemplate._id}
                refeshTable={getGuests}
                isChangeTimer={isChangeTimer}
                setIsChangeTimer={setIsChangeTimer2}
            />
            <SetTimerSendMailManyGuests
                guestSelect={guestSelect}
                actionMode={actionMode}
                guests={guests}
                eventId={eventTemplate._id}
                refeshTable={getGuests}
            />
            <ConfirmDeleteOne
                deleteOnlyGuest={deleteOnlyGuest}
                deleteOneGroup={deleteOneGroup}
                selectOne={selectOne}
                guests={guests}
                viewMode={viewMode}
            />
            <ConfirmGroup formik={groupFormik} mode={mode} handleSubmitGroup={handleSubmitGroup} />
            <GroupListModal
                open={modalOpen.groupListModal}
                setOpen={setModalOpen}
                selectOne={selectOne}
                eventTemplate={eventTemplate}
                guests={guests}
                onAddToGroup={onAddToGroup}
                getGuests={getGuests}
                groups={groups}
                formik={formik}
                mode={mode}
            />
            <ConfirmRemoveGuestModal
                onRemoveFromGroup={onRemoveFromGroup}
                guest={guests.find((guest) => guest._id === selectOne)}
                group={groups.find((group) => group._id === guestQuery.group)}
            />
            <ConfirmDeleteOneGroup
                group={groups.find((group) => group._id == selectOne)}
                deleteOneGroup={deleteOneGroup}
            />
            <ConfirmReplaceByExcel
                refFile={refFile}
                onChangeFileSelect={onChangeFileSelect}
                onOpenChooseFile={onOpenChooseFile}
            />
            <ConfirmDeleteAll deleteAllGuest={deleteAllGuest} />

            <FilterModal
                query={viewMode == 'guest' ? guestQuery : groupQuery}
                setQuery={viewMode == 'guest' ? setGuestQuery : setGroupQuery}
                viewMode={viewMode}
            />
            <ExportQRModal totalGuest={totalGuest} eventId={eventId} />
            <ExportQRModalSelect
                totalGuest={totalGuest}
                eventId={eventId}
                guestSelect={guestSelect}
            />
            <ConfirmSendOnlyGroup
                handleSendToOne={handleSendToOneGroup}
                selectOne={selectOne}
                groups={groups}
            />
            <ConfirmAddEmail4OnlyGroup />
            <TemplateModal
                alias={eventTemplate.template?.alias}
                props={{
                    data: eventTemplate.form_data,
                    urlShare: makeUrlShare(
                        eventTemplate?.url_slug,
                        viewMode === 'guest' ? formik.values.slug : groupFormik.values.slug
                    ),
                    guestName:
                        (viewMode === 'guest' ? formik.values.title : groupFormik.values.title) +
                        ' ' +
                        (viewMode === 'guest'
                            ? formik.values.fullname
                            : groupFormik.values.fullname),
                    guestTitle:
                        viewMode === 'guest' ? formik.values.title : groupFormik.values.title,
                    guestFullname:
                        viewMode === 'guest' ? formik.values.fullname : groupFormik.values.fullname,
                    salutation:
                        viewMode === 'guest'
                            ? formik.values.salutation
                            : groupFormik.values.salutation,
                    refFrame: refFrame,
                }}
                onCopyImage={onCopyImage}
                onDownloadTemplate={onDownloadTemplate}
            />

            <ComposeContentEmail eventTemplate={eventTemplate} />
            {/* <VerifyTable
                guests={verifyGuest}
                handleGetVerifyGuest={handleGetVerifyGuest}
                eventTemplate={eventTemplate}
                setTrigger={setTrigger}
                trigger={trigger}
            /> */}
            <QrModal
                url={makeUrlShare(
                    eventTemplate?.url_slug,
                    viewMode === 'guest' ? formik.values.slug : groupFormik.values.slug
                )}
                qrRef={qrRef}
                onDownloadQr={onDownloadQr}
            />

            <button
                type="button"
                ref={btnOpenModal}
                data-bs-toggle="modal"
                data-bs-target="#upgradepublicModal"
                className="hidden"
            ></button>
            <UpgradeModal message={messageError} handlePublic={() => onGoToUpdate()} />
            <ConfirmAcceptRequest
                actionMode={actionMode}
                selectOne={selectOne}
                handleVerifyOne={handleVerifyOne}
                guestSelect={guestSelect}
                handleVerifyMany={handleVerifyMany}
            />
            <ConfirmDenyRequest
                actionMode={actionMode}
                selectOne={selectOne}
                handleVerifyOne={handleVerifyOne}
                guestSelect={guestSelect}
                handleVerifyMany={handleVerifyMany}
            />
            <SortTableModal />
        </>
    );
}
