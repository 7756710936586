/* eslint-disable */
import { useEffect, useState } from 'react';
import IMAGES from '../asests/images/_index';
import ReactTooltip from 'react-tooltip';
import {
    FaHistory,
    FaEdit,
    FaTrash,
    FaUser,
    FaUsers,
    FaUserSecret
} from 'react-icons/fa';
import { BsArrowReturnRight, BsIncognito } from "react-icons/bs";
import { FaRegCalendarCheck } from "react-icons/fa";

import { formatDatetime } from '../../../../../utils/Times';
import { GUEST, OWNER, PASSING } from '../asests/constants/key';


export default function ItemGreeting({ data, index, showModalAdd, sendDataGreeting, isExpired, setImagePreview }) {

    const [dataInfo, setDataInfo] = useState({});
    const isHighlight = (!data.isPublic && [GUEST, PASSING].includes(data.type));

    useEffect(() => {
        if (data) {
            setDataInfo({
                id: data._id,
                guestName: data?.guestName,
                greeting: data?.greeting,
                image: data?.image
                    ? (process.env.REACT_APP_SERVER_URL + data.image)
                    : (isHighlight ? IMAGES.ICON_MAIL_CLOSE : IMAGES.ICON_MAIL_OPEN),
                isPublic: data.isPublic,
                createdAt: formatDatetime(data.createdAt),
                createdBy: {
                    text: data?.createdByName ? data.createdByName : (data.type === OWNER ? 'Tôi' : 'Khách ẩn danh'),
                    icon: data?.createdByName
                        ? <FaUsers className='text-blue-900 text-lg' />
                        : (data.type === OWNER
                            ? <FaUser className='text-blue-900' />
                            : <FaUserSecret className='text-black' />),
                },
                reply: data?.reply
            });
        }
    }, [data]);

    return (
        <>
            <div className={`${isHighlight && 'border-2 border-blue-400 font-extrabold '} px-5 pt-5 pb-3 bg-white rounded-lg shadow justify-start items-start flex relative w-full`}>
                <div className="w-[35%] relative group mr-4  items-center">
                    <img src={dataInfo.image}
                        alt='image'
                        data-bs-toggle="modal"
                        data-bs-target={data?.image && "#modalImagePreviewGreeting"}
                        // loading="lazy"
                        className={`w-full max-h-48 ${data?.image && 'cursor-pointer '}`}
                        onClick={() => setImagePreview(dataInfo.image)}
                    />
                </div>
                <div className='w-[65%] relative'>
                    <div className='absolute -top-2 -right-1'>
                        {dataInfo.isPublic ?
                            <i className="fas fa-globe-americas text-[16px] text-sky-600" title='Công khai'></i>
                            :
                            <i className="fas fa-lock text-[15px] text-gray-700" title='Chỉ mình tôi'></i>
                        }
                    </div>
                    <div className="self-stretch flex-col justify-start items-start gap-1 flex h-full">
                        <div className="self-stretch text-gray-500 text-xs  leading-none pb-1">Tạo lúc {dataInfo.createdAt}</div>
                        <div
                            data-tip
                            data-for={`helper-guestName-${data._id}`}
                            className="self-stretch text-gray-900 text-lg font-semibold leading-tight mt-auto line-clamp-1">
                            {dataInfo.guestName}
                        </div>
                        {
                            dataInfo.guestName?.length > 20 &&
                            <ReactTooltip
                                id={`helper-guestName-${data._id}`}
                                place="top"
                                type="dark"
                                className='font-normal'
                                delayShow={100}
                            >
                                {dataInfo.guestName}
                            </ReactTooltip>
                        }
                        <div className="self-stretch mt-0.5 h-16 bg-gray-200 p-1 -m-1 rounded-md">
                            <span data-tip
                                data-for={`helper-greeting-${data._id}`}
                                className="text-gray-600 text-sm leading-snug break-words line-clamp-3"
                            >
                                {dataInfo.greeting}
                            </span>
                            {
                                dataInfo.greeting?.length > 90 &&
                                <ReactTooltip
                                    id={`helper-greeting-${data._id}`}
                                    place="top"
                                    type="dark"
                                    className='md:max-w-[50vw] max-w-full font-normal'
                                    delayShow={100}
                                >
                                    {dataInfo.greeting}
                                </ReactTooltip>
                            }
                        </div>
                        {
                            dataInfo?.reply
                                ? <>
                                    <div className="self-stretch mt-1 flex">
                                        <span>
                                            <BsArrowReturnRight className='font-black mr-1 text-lg' />
                                        </span>
                                        <span
                                            data-tip
                                            data-for={`helper-reply-${data._id}`}
                                            className="bg-sky-100 px-2 py-1 rounded-md text-gray-700 text-sm leading-snug break-words line-clamp-1" >
                                            {dataInfo.reply}
                                        </span>
                                    </div>
                                    {
                                        dataInfo.reply?.length > 25 &&
                                        <ReactTooltip
                                            id={`helper-reply-${data._id}`}
                                            place="bottom"
                                            type="dark"
                                            className='md:max-w-[50vw] max-w-full font-normal'
                                            delayShow={100}
                                        >
                                            {dataInfo.reply}
                                        </ReactTooltip>
                                    }
                                </>
                                : <div className='h-8'></div>
                        }

                        <div className="mt-1">
                            {/* <span className="text-gray-500 text-sm line-clamp-1" title="">
                                Tạo bởi <b>{dataInfo.createdBy}</b>
                            </span> */}
                        </div>
                    </div>
                    <div className="relative -left-1 flex justify-between items-center">
                        <div className="relative inline-block text-left !w-full" data-headlessui-state="">
                            {
                                (!isExpired || data.type === GUEST) &&
                                <button className="link w-full flex items-center pt-2 text-sky-500 z-5"
                                    id="dropdownMenuButtonMore"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24" height="24" className="w-4 h-4 mr-2" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 5v.01M12 12v.01M12 19v.01M12 6c-.5523 0-1-.44772-1-1s.4477-1 1-1 1 .44772 1 1-.4477 1-1 1Zm0 7c-.5523 0-1-.4477-1-1s.4477-1 1-1 1 .4477 1 1-.4477 1-1 1Zm0 7c-.5523 0-1-.4477-1-1s.4477-1 1-1 1 .4477 1 1-.4477 1-1 1Z">
                                        </path>
                                    </svg>
                                    <span className="text-sm">Xem thêm</span>
                                </button>
                            }
                            <ul
                                className="dropdown-menu absolute z-[999] float-left m-0 mt-1 hidden min-w-max list-none rounded border bg-gray-100 bg-clip-padding py-2  text-center text-base shadow-lg"
                                aria-labelledby="dropdownMenuButtonMore"
                            >
                                {
                                    data.type === OWNER
                                        ?
                                        <li>
                                            <span
                                                onClick={() =>
                                                    !isExpired && showModalAdd(
                                                        'edit',
                                                        data,
                                                        index
                                                    )
                                                }
                                                data-bs-toggle="modal"
                                                data-bs-target={!isExpired && "#modalAddGreeting"}
                                                className="flex items-center w-full pl-4 pr-20 py-2 text-sm font-normal text-gray-700 bg-transparent cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                            >
                                                <FaEdit className="mr-1" />
                                                Chỉnh sửa
                                            </span>
                                        </li>
                                        :
                                        <li>
                                            <span
                                                onClick={() => !isExpired && sendDataGreeting(data)}
                                                data-bs-toggle="modal"
                                                data-bs-target={!isExpired && "#modalApprovalGreeting"}
                                                className={`flex items-center w-full pl-4 pr-20 py-2 text-sm font-normal text-gray-700 bg-transparent cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100 ${isExpired && 'opacity-20'}`}
                                            >
                                                <FaRegCalendarCheck className="mr-1" />
                                                Trả lời & Chia sẻ
                                            </span>
                                        </li>
                                }

                                {
                                    data.type === GUEST &&
                                    <li>
                                        <span
                                            onClick={() => sendDataGreeting(data)}
                                            data-bs-toggle="modal"
                                            data-bs-target="#modalHistoryGreeting"
                                            className="flex items-center w-full pl-4 pr-20 py-2 text-sm font-normal text-gray-700 bg-transparent cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100"
                                        >
                                            <FaHistory className="mr-1" />
                                            Xem lịch sử
                                        </span>
                                    </li>
                                }

                                <li>
                                    <span
                                        onClick={() => !isExpired && sendDataGreeting(data)}
                                        className={`flex items-center w-full pl-4 pr-20 py-2 text-sm font-normal text-red-600 bg-transparent cursor-pointer dropdown-item whitespace-nowrap hover:bg-blue-100 ${isExpired && 'opacity-20'}`}
                                        data-mdb-ripple="true"
                                        data-mdb-ripple-color="light"
                                        data-bs-toggle="modal"
                                        data-bs-target={!isExpired && "#confirmDeleteGreetingModal"}
                                    >
                                        <FaTrash className="mr-1" />
                                        Xóa
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className='relative inline-block -mr-2 -mb-1'>
                            <div
                                // id="dropdownTitleCreatedBy"
                                // data-bs-toggle="dropdown"
                                data-tip
                                data-for={`helper-createdBy-${data._id}`}
                                className=' cursor-pointer '
                            >
                                {dataInfo?.createdBy?.icon}
                            </div>
                            <ReactTooltip
                                id={`helper-createdBy-${data._id}`}
                                place="top"
                                type="dark"
                                delayShow={100}
                                className='font-normal'
                            >
                                Tạo bởi {dataInfo?.createdBy?.text}
                            </ReactTooltip>
                            {/* <ul
                                className="dropdown-menu absolute z-[999] float-left m-0 mt-1 hidden min-w-max list-none rounded border bg-gray-100 bg-clip-padding py-2  text-center text-base shadow-sm"
                                aria-labelledby="dropdownTitleCreatedBy"
                            >
                                {dataInfo.createdBy.text}
                            </ul> */}
                        </div>

                    </div>
                </div>
                {
                    // isHighlight &&
                    // <div className='absolute animate-autozoom h-4 w-4 bg-blue-500 rounded-full -top-2 -right-2'>
                    // </div>
                }
            </div>
        </>
    );
}
