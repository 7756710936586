/* eslint-disable */
import { useEffect, useState } from 'react';
import {
    FaEnvelope,
    FaEnvelopeOpen,
    FaEnvelopeOpenText,
    FaHourglass,
    FaInfoCircle,
    FaPen,
    FaUser,
    FaUserCheck,
    FaUserEdit,
    FaUserFriends,
    FaUserPlus,
    FaUsers,
    FaUserTie,
    FaUserTimes,
} from 'react-icons/fa';

const defaultFilter = {
    status: '',
    vip: '',
    method: '',
};

export function FilterModal({ query, viewMode, setQuery }) {
    const [newQuery, setNewQuery] = useState(query);

    useEffect(() => {
        setNewQuery(query);
    }, [query]);

    const changeStatus = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setNewQuery({
                ...newQuery,
                status: value,
            });
        } else {
            setNewQuery({
                ...newQuery,
                status: '',
            });
        }
    };
    const changeType = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setNewQuery({
                ...newQuery,
                vip: value,
            });
        } else {
            setNewQuery({
                ...newQuery,
                vip: '',
            });
        }
    };
    const changeMethod = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setNewQuery({
                ...newQuery,
                method: value,
            });
        } else {
            setNewQuery({
                ...newQuery,
                method: '',
            });
        }
    };
    const handleApply = () => {
        setQuery(newQuery);
    };
    const handleClearFilter = () => {
        setNewQuery({ ...newQuery, status: '', vip: '', method: '' });
    };

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="filterModal"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative pointer-events-none modal-dialog modal-dialog-centered md:w-[700px]">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-[16px] font-bold leading-normal text-gray-800"
                            id="exampleModalScrollableLabel"
                        >
                            Lọc
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body flex max-h-[80vh] shrink-0 flex-col overflow-auto rounded-t-md border-b border-gray-200 md:py-[2rem] py-2 md:px-[3rem] px-3">
                        <div className="flex flex-col">
                            <label className="flex items-center text-[16px] font-bold text-gray-600 mb-[10px]">
                                Trạng thái phản hồi
                            </label>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        checked={newQuery.status == 'UNINVITED'}
                                        value={'UNINVITED'}
                                        id="UNINVITED"
                                        onChange={(e) => {
                                            changeStatus(e);
                                        }}
                                        className="mr-2 rounded"
                                        name="status"
                                    />
                                    <label
                                        for="UNINVITED"
                                        className="flex items-center text-black text-[14px] cursor-pointer"
                                    >
                                        <FaEnvelope className="mr-2 text-amber-500" />
                                        Chưa gửi lời mời
                                    </label>
                                </div>
                                <div className="flex items-center">
                                    <input
                                        checked={newQuery.status == 'SENDING'}
                                        value={'SENDING'}
                                        onChange={(e) => {
                                            changeStatus(e);
                                        }}
                                        type="checkbox"
                                        id="SENDING"
                                        className="mr-2 rounded"
                                        name="status"
                                    />
                                    <label
                                        for="SENDING"
                                        className="flex items-center text-black text-[14px] cursor-pointer"
                                    >
                                        <FaHourglass className="mr-2 text-sky-500" />
                                        Đang gửi lời mời
                                    </label>
                                </div>
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="INVITED"
                                        className="mr-2 rounded"
                                        checked={newQuery.status == 'INVITED'}
                                        value={'INVITED'}
                                        onChange={(e) => {
                                            changeStatus(e);
                                        }}
                                        name="status"
                                    />
                                    <label
                                        for="INVITED"
                                        className="flex items-center text-black text-[14px] cursor-pointer"
                                    >
                                        <FaEnvelopeOpenText className="mr-2 text-blue-500" />
                                        Đã gửi lời mời
                                    </label>
                                </div>
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="ACCEPT"
                                        className="mr-2 rounded"
                                        checked={newQuery.status == 'ACCEPT'}
                                        value={'ACCEPT'}
                                        onChange={(e) => {
                                            changeStatus(e);
                                        }}
                                        name="status"
                                    />
                                    <label
                                        for="ACCEPT"
                                        className="flex items-center text-black text-[14px] cursor-pointer"
                                    >
                                        <FaUserCheck className="mr-2 text-green-500" />
                                        Đồng ý tham dự
                                    </label>
                                </div>
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="DECLINE"
                                        className="mr-2 rounded"
                                        checked={newQuery.status == 'DECLINE'}
                                        value={'DECLINE'}
                                        onChange={(e) => {
                                            changeStatus(e);
                                        }}
                                        name="status"
                                    />
                                    <label
                                        for="DECLINE"
                                        className="flex items-center text-black text-[14px] cursor-pointer"
                                    >
                                        <FaUserTimes className="mr-2 text-red-500" />
                                        Từ chối tham dự
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col mt-[28px]">
                            <label className="flex items-center text-[16px] font-bold text-gray-600 mb-[10px]">
                                Loại
                            </label>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="true"
                                        className="mr-2 rounded"
                                        checked={newQuery.vip == 'true'}
                                        value={'true'}
                                        onChange={(e) => {
                                            changeType(e);
                                        }}
                                        name="vip"
                                    />
                                    <label
                                        for="true"
                                        className="flex items-center text-sm text-black cursor-pointer"
                                    >
                                        <FaUserTie className="mr-2 text-violet-500" /> Khách VIP
                                    </label>
                                </div>
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="false"
                                        className="mr-2 rounded"
                                        checked={newQuery.vip == 'false'}
                                        value={'false'}
                                        onChange={(e) => {
                                            changeType(e);
                                        }}
                                        name="vip"
                                    />
                                    <label
                                        for="false"
                                        className="flex items-center text-sm text-black cursor-pointer"
                                    >
                                        <FaUser className="mr-2" /> khách thường
                                    </label>
                                </div>
                            </div>
                        </div>

                        {viewMode == 'guest' && (
                            <div className="flex flex-col mt-[28px]">
                                <label className="flex items-center text-[16px] font-bold text-gray-600 mb-[10px]">
                                    Hình thức
                                </label>
                                <div className="grid grid-cols-2 gap-4 mb-[10px]">
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            id="USER"
                                            className="mr-2 rounded"
                                            checked={newQuery.method == 'USER'}
                                            value={'USER'}
                                            onChange={(e) => {
                                                changeMethod(e);
                                            }}
                                        />
                                        <label
                                            for="USER"
                                            className="flex items-center text-sm text-black cursor-pointer"
                                        >
                                            <FaUserEdit className="mr-2" /> Thêm bởi chủ sự kiện
                                        </label>
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            id="GROUP"
                                            className="mr-2 rounded"
                                            checked={newQuery.method == 'GROUP'}
                                            value={'GROUP'}
                                            onChange={(e) => {
                                                changeMethod(e);
                                            }}
                                        />
                                        <label
                                            for="GROUP"
                                            className="flex items-center text-sm text-black cursor-pointer"
                                        >
                                            <FaUsers className="mr-2" /> Thêm bởi nhóm khách
                                        </label>
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            id="GUEST"
                                            className="mr-2 rounded"
                                            checked={newQuery.method == 'GUEST'}
                                            value={'GUEST'}
                                            onChange={(e) => {
                                                changeMethod(e);
                                            }}
                                        />
                                        <label
                                            for="GUEST"
                                            className="flex items-center text-sm text-black cursor-pointer"
                                        >
                                            <FaPen className="mr-2" /> Khách tự đăng ký
                                        </label>
                                    </div>
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            id="GUEST_INVITE"
                                            className="mr-2 rounded"
                                            checked={newQuery.method == 'GUEST_INVITE'}
                                            value={'GUEST_INVITE'}
                                            onChange={(e) => {
                                                changeMethod(e);
                                            }}
                                        />
                                        <label
                                            for="GUEST_INVITE"
                                            className="flex items-center text-sm text-black cursor-pointer"
                                        >
                                            <FaUserFriends className="mr-2" /> Được thêm bởi khách
                                            khác
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                        <button
                            type="button"
                            className="inline-block px-6 py-2.5  text-gray-800 border-[1px] border-gray-500 font-medium text-xs leading-tight uppercase rounded shadow-md hover: hover:shadow-lg focus: focus:shadow-lg focus:outline-none focus:ring-0 active: active:shadow-lg transition duration-150 ease-in-out"
                            onClick={handleClearFilter}
                        >
                            Hủy lọc
                        </button>
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={handleApply}
                            className="inline-block px-6 py-2.5 bg-blue-700 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-800 hover:shadow-lg focus:bg-blue-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                        >
                            Áp dụng
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
