export const CONTENT_KEYS = {
    FULLNAME: '%%fullname%%',
    TITLE: '%%title%%',
    POSITION: '%%position%%',
    NAME_COMPANY: '%%nameCompany%%',
    NAME_EVENT: '%%nameEvent%%',
    TIME_EVENT: '%%timeEvent%%',
    DATE_EVENT: '%%dateEvent%%',
    PLACE_EVENT: '%%placeEvent%%',
    ADDRESS_EVENT: '%%addressEvent%%',
    NAME_SUPPORTER: '%%nameSupporter%%',
    PHONE_SUPPORTER: '%%phoneSupporter%%',
    NAME_SENDER: '%%nameSender%%',
    LINK_EVENT_GUEST: '%%linkEventGuest%%',
    LINK_CONFIRM_GUEST: '%%linkConfirmGuest%%',
    LINK_GGMAP: '%%linkGGMap%%',
};

export const CONTENT_TEXTS = {
    FULLNAME: 'Tên khách mời',
    TITLE: 'Danh xưng',
    POSITION: 'Vị trí',
    NAME_COMPANY: 'Tên công ty',
    NAME_EVENT: 'Tên sự kiện',
    TIME_EVENT: 'Giờ sự kiện',
    DATE_EVENT: 'Ngày sự kiện',
    PLACE_EVENT: 'Địa điểm',
    ADDRESS_EVENT: 'Địa chỉ',
    NAME_SUPPORTER: 'Tên người hỗ trợ',
    PHONE_SUPPORTER: 'Số điện thoại người hỗ trợ',
    NAME_SENDER: 'Người gửi',
    LINK_EVENT_GUEST: 'Đường_dẫn_thiệp_mời',
    LINK_CONFIRM_GUEST: 'Đường_dẫn_xác_nhận_tham_dự',
    LINK_GGMAP: 'Đường_dẫn_GoogleMap',
};

export const CONTENT_TEXT_DEFAULTS = {
    FULLNAME: 'Nguyễn Văn A',
    TITLE: 'Anh',
    POSITION: 'Giám đốc',
    NAME_COMPANY: 'Công ty ABC',
    NAME_EVENT: 'Tên sự kiện',
    TIME_EVENT: 'Giờ sự kiện',
    DATE_EVENT: 'Ngày sự kiện',
    PLACE_EVENT: 'Địa điểm',
    ADDRESS_EVENT: 'Địa chỉ',
    NAME_SUPPORTER: 'Tên người hỗ trợ',
    PHONE_SUPPORTER: 'Số điện thoại người hỗ trợ',
    NAME_SENDER: 'Người gửi',
    LINK_EVENT_GUEST: 'Xem thiệp mời',
    LINK_CONFIRM_GUEST: 'Xác nhận tham dự',
    LINK_GGMAP: 'Xem địa chỉ',
};

export const TYPE_EMAILS = [
    { title: 'Lời mời', alias: 'EMAIL_INVITE' },
    { title: 'Chấp nhận tham dự', alias: 'EMAIL_CONFIRM_ACCEPT' },
    { title: 'Từ chối tham dự', alias: 'EMAIL_CONFIRM_DECLINE' },
    { title: 'Chờ xác nhận', alias: 'EMAIL_VERIFYING' },
    { title: 'Chấp nhận yêu cầu tham dự', alias: 'EMAIL_ACCEPT_REQUEST' },
    { title: 'Từ chối yêu cầu tham dự', alias: 'EMAIL_DENY_REQUEST' },
];

export const APPLY_FOR = {
    ONLY_USER: 'ONLY_USER',
    ALL_USER_WITH_TIMER_TOGETHER: 'ALL_USER_WITH_TIMER_TOGETHER',
};