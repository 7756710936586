/* eslint-disable */
import { Link } from 'react-router-dom'
import React from 'react'

const DialogMenu = ({ visiable, onClose }) => {
    return (
        <>
            {visiable && (
                <div
                    className="fixed z-50 inset-0 overflow-y-auto lg:hidden"
                    role="dialog"
                    aria-modal="true"
                >
                    <div
                        className="fixed inset-0 bg-black/20 backdrop-blur-sm "
                        aria-hidden="true"
                    ></div>
                    <div className="relative bg-white w-80 max-w-[calc(100%-3rem)] p-6  h-full">
                        <button
                            type="button"
                            className="absolute z-10 top-5 right-5 w-8 h-8 flex items-center justify-center text-slate-500 hover:text-slate-600"
                            onClick={onClose}
                        >
                            <span className="sr-only">Close navigation</span>
                            <svg viewBox="0 0 10 10" className="w-2.5 h-2.5 overflow-visible">
                                <path
                                    d="M0 0L10 10M10 0L0 10"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                ></path>
                            </svg>
                        </button>
                        <nav className="lg:text-sm lg:leading-6 relative">
                            <ul>
                                <li className="lg:mt-8">
                                    <h5 className="mb-8 lg:mb-3 font-semibold text-slate-900 ">
                                        Getting Started
                                    </h5>
                                    <ul className="space-y-6 lg:space-y-2 border-l border-slate-100 ">
                                        {[1, 2, 3, 4, 5, 6].map(() => (
                                            <li>
                                                <Link
                                                    to="/"
                                                    className="block border-l pl-4 -ml-px border-transparent hover:border-slate-400  text-slate-700 hover:text-slate-900 s"
                                                >
                                                    Install
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            )}
        </>
    )
}

export default DialogMenu
