import { FaArrowLeft, FaCheck } from 'react-icons/fa';
import DeleteImg from '../../../assets/images/DeleteEventImg.webp';

export default function ModalDelete({ funDelete, data }) {
    const btnDelete = () => {
        funDelete(data._id);
    };

    return (
        <div
            className="fade modal fixed left-0 top-0 hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none "
            id="confirmDeleteModal"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="modal-dialog modal-dialog-centered pointer-events-none relative w-auto z-[999]">
                <div className="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
                    <div className="modal-header flex flex-col shrink-0 items-center justify-between rounded-t-md  p-4 px-6">
                        <img src={DeleteImg} className="w-[200px]" alt="xoa-icon" />

                        <h5
                            className={`text-xl leading-normal text-red-500
                             font-bold text-center mt-5 w-full`}
                        >
                            Bạn có chắc chắn muốn xóa thiệp này không?
                        </h5>
                        <p className="text-center mt-5 text-black text-sm px-2">
                            Khi xóa thiệp, tất cả dữ liệu liên quan như thông tin khách mời và hình
                            ảnh cũng sẽ bị xóa, hành động này không thể hoàn tác
                        </p>
                    </div>
                    <div className="modal-footer flex shrink-0 flex-wrap items-center justify-center rounded-b-md p-4 pt-0 mt-5">
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            className="ml-1 flex items-center gap-2 rounded bg-gray-200 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-300 active:shadow-lg"
                        >
                            <FaArrowLeft /> Trở lại
                        </button>
                        <button
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={btnDelete}
                            className={`ml-1 flex items-center gap-2 rounded 
                                
                                     bg-red-500 hover:bg-red-700 hover:shadow-lg focus:bg-red-700
                              px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out  focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg`}
                        >
                            <FaCheck /> Xác nhận
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
