/* eslint-disable */
import MainInfoWedImg from './imgs/main_info_wed.png';
import EventImgs from './imgs/events.png';
import SupportImg from './imgs/supporter.png';
import AlbumImg from './imgs/album.png';
import SliderImg from './imgs/slider.png';
import GroomImg from './imgs/groom.png';
import BrideImg from './imgs/bride.png';
import BankImg from './imgs/bank.png';
import GreetingImg from './imgs/greeting.png';
import VideoImg from './imgs/video.png';

import MainInfoEvImg from './imgs/main_info_ev.png';
import ContentImg from './imgs/contents.png';
import SupportEv from './imgs/supporter_ev.png';
import StoryImg from './imgs/story.png';

export const questionList = [
    {
        key: 'main_info_ev',
        ques: 'Thông tin sự kiện là gì?',
        ans: (
            <div className="flex flex-col gap-2">
                <p className="text-gray-700 text-sm">
                    Thông tin chung về sự kiện bao gồm tên sự kiện, thời gian, địa điểm, chương
                    trình, và mục tiêu nhằm giúp khách mời hiểu rõ hơn về nội dung và ý nghĩa của sự
                    kiện.
                </p>
                <p className="text-gray-700 text-sm">
                    Thông tin chung về sự kiện bao gồm tên sự kiện, thời gian, địa điểm, chương
                    trình, và mục tiêu nhằm giúp khách mời hiểu rõ hơn về nội dung và ý nghĩa của sự
                    kiện.
                </p>
            </div>
        ),
        img: MainInfoEvImg,
    },
    {
        key: 'main_info_wed',
        ques: 'Thông tin chính là gì?',

        img: MainInfoWedImg,
        ans: (
            <div className="flex flex-col gap-2">
                <p className="text-gray-700 text-sm">
                    Thông tin chính về sự kiện bao gồm tên sự kiện, thời gian, địa điểm, chương
                    trình, và mục tiêu nhằm giúp khách mời hiểu rõ hơn về nội dung và ý nghĩa của sự
                    kiện.
                </p>
                <p className="text-gray-700 text-sm">
                    Thông tin chính thường được đặt ở đầu trang để có thể giúp khách mời có thể dễ
                    dàng tìm kiếm và nắm bắt.
                </p>
            </div>
        ),
    },
    {
        key: 'supporter',
        ques: 'Thông tin người hỗ trợ là gì?',
        ans: (
            <div className="flex flex-col gap-2">
                <p className="text-gray-700 text-sm">
                    Những thông tin cơ bản của người hỗ trợ để khách mời có thể liên hệ khi cần hỗ
                    trợ hoặc có thắc mắc.
                </p>
                <p className="text-gray-700 text-sm">
                    Thông tin người hỗ trợ được đặt ở phần "Liên hệ hỗ trợ"
                </p>
            </div>
        ),
        img: SupportImg,
    },
    {
        key: 'supporter_ev',
        ques: 'Thông tin người hỗ trợ là gì?',
        ans: (
            <div className="flex flex-col gap-2">
                <p className="text-gray-700 text-sm">
                    Những thông tin cơ bản của người hỗ trợ để khách mời có thể liên hệ khi cần hỗ
                    trợ hoặc có thắc mắc.
                </p>
                <p className="text-gray-700 text-sm">
                    Thông tin người hỗ trợ được đặt ở phần "Liên hệ hỗ trợ"
                </p>
            </div>
        ),
        img: SupportEv,
    },
    {
        key: 'events',
        ques: 'Lịch trình sự kiện là gì?',
        img: EventImgs,
        ans: (
            <div className="flex flex-col gap-2">
                <p className="text-gray-700 text-sm">
                    Lịch trình sự kiện chi tiết cung cấp các thông tin về thời gian, địa điểm diễn
                    ra sự kiện. Thông tin này giúp khách mời biết trước các hoạt động sẽ diễn ra và
                    chuẩn bị tham gia đầy đủ các phần quan trọng của buổi lễ.
                </p>
                <p className="text-gray-700 text-sm">
                    Lịch trình sự kiện được đặt ở phần "Sự kiện cưới"
                </p>
            </div>
        ),
    },
    {
        key: 'contents',
        ques: 'Nội dung chương trình là gì?',
        img: ContentImg,
        ans: (
            <div className="flex flex-col gap-2">
                <p className="text-gray-700 text-sm">
                    Nội dung chương trình chi tiết cung cấp các thông tin về thời gian, địa điểm
                    diễn ra sự kiện. Thông tin này giúp khách mời biết trước các hoạt động sẽ diễn
                    ra và chuẩn bị tham gia đầy đủ các phần quan trọng của buổi lễ.
                </p>
                {/* <p className="text-gray-700 text-sm">
                    Nội dung chương trình được đặt ở phần "Nội dung chương trình"
                </p> */}
            </div>
        ),
    },
    {
        key: 'album',
        ques: 'Bộ sưu tập ảnh là gì?',
        ans: (
            <div className="flex flex-col gap-2">
                <p className="text-gray-700 text-sm">
                    Bộ sưu tập ảnh cưới là nơi lưu giữ những khoảnh khắc đẹp và ý nghĩa nhất trong ngày
                    trọng đại của cặp đôi. Mỗi trang bộ sưu tập ảnh là một câu chuyện, một kỷ niệm mà cặp đôi
                    sẽ trân trọng suốt đời.
                </p>
            </div>
        ),
        img: AlbumImg,
    },
    {
        key: 'slider',
        ques: 'Ảnh trình chiếu là gì?',
        ans: (
            <div className="flex flex-col gap-2">
                <p className="text-gray-700 text-sm">Những bức ảnh tạo nên màu sắc cho sự kiện</p>
                <p className="text-gray-700 text-sm">
                    Ảnh trình chiếu thường được đặt ở cùng cới những thông tin chung để làm nổi bật
                    phần đó hoặc dùng để làm ảnh nền.
                </p>
            </div>
        ),
        img: SliderImg,
    },
    {
        key: 'groom',
        ques: 'Thông tin chú rể là gì?',
        ans: (
            <p className="text-gray-700 text-sm">
                Bao gồm những thông tin chi tiết về chú rể như họ tên, ngày sinh, số điện thoại,
                mạng xã hội...
            </p>
        ),
        img: GroomImg,
    },
    {
        key: 'bride',
        ques: 'Thông tin cô dâu là gì?',
        ans: (
            <p className="text-gray-700 text-sm">
                Bao gồm những thông tin chi tiết về cô dâu như họ tên, ngày sinh, số điện thoại,
                mạng xã hội...
            </p>
        ),
        img: BrideImg,
    },
    {
        key: 'bank',
        ques: 'Thông tin ngân hàng là gì?',
        ans: (
            <p className="text-gray-700 text-sm">
                Bao gồm những thông tin về tài khoản ngân hàng của cô dâu và chú rể giúp cho khách
                mời có thể gửi lời chúc một cách nhanh chóng!
            </p>
        ),
        img: BankImg,
    },
    {
        key: 'greeting',
        ques: 'Quản lý lời chúc là gì?',
        ans: (
            <p className="text-gray-700 text-sm">
                Cho phép bạn bè và người thân có thể gửi đến Cô Dâu & Chú rể những lời chúc ngọt
                ngào, tốt đẹp nhất cho ngày trọng đại ngay trên website đám cưới. Theo đó, bạn cũng
                có thể phản hồi lại những lời chúc mà mọi người đã gửi đến mình.
            </p>
        ),
        img: GreetingImg,
    },
    {
        key: 'video',
        ques: 'Video tiệc cưới là gì?',
        ans: (
            <p className="text-gray-700 text-sm">
                Sử dụng video từ youtube để chia sẻ khoảnh khắc hạnh phúc của bạn đến mọi người
            </p>
        ),
        img: VideoImg,
    },
    {
        key: 'sub',
        ques: 'Ảnh thiệp mời là gì?',
        ans: (
            <p className="text-gray-700 text-sm">
                Gồm những thông tin quan trọng để bạn dễ dàng chia sẻ cho mõi người
            </p>
        ),
    },
    {
        key: 'guest',
        ques: 'Quản lý khách mời là gì?',
        ans: (
            <p className="text-gray-700 text-sm">
                Tổ chức và theo dõi các thông tin liên quan đến khách mời
            </p>
        ),
    },
    {
        key: 'config',
        ques: 'Cấu hình sự kiện là gì?',
        ans: (
            <p className="text-gray-700 text-sm">
                Giúp bạn tùy chỉnh các chức năng của thiệp theo ý muốn
            </p>
        ),
    },
    {
        key: 'static',
        ques: 'Thống kê truy cập là gì?',
        ans: <p className="text-gray-700 text-sm">Theo dõi và đánh giá các thông số tương tác</p>,
    },
    {
        key: 'story',
        ques: 'Câu chuyện là gì',
        ans: (
            <div className="flex flex-col gap-2">
                <p className="text-gray-700 text-sm">
                    Chia sẻ những khoảnh khắc hạnh phúc của cặp đôi cho mọi người
                </p>
            </div>
        ),
        img: StoryImg,
    },
];
