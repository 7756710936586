
/* eslint-disable */
export default function ImagePreview({ imageUrl }) {
    return (
        <div
            className="fixed top-0 left-0  hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="modalImagePreviewGreeting"
            aria-modal="true"
            role="dialog"
        >
            <div className="modal-dialog modal-dialog-centered pointer-events-none relative w-auto modal-lg">
                <div className="modal-content pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
                    <div className="modal-header flex shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4">
                        <h5
                            className="text-xl font-bold leading-normal text-gray-800"
                            id="exampleModalScrollableLabel"
                        >
                            Xem ảnh
                        </h5>
                        <button
                            type="button"
                            className="btn-close box-content h-4 w-4 rounded-none border-none p-1 text-black opacity-50 hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-header flex shrink-0 items-center justify-between rounded-t-md border-b border-gray-200 p-4">
                        <div className='w-full flex justify-center items-center h-[450px] md:h-[550px]'>
                            <img src={imageUrl} alt="Ảnh"
                                className="max-h-full max-w-full"
                            />
                        </div>
                    </div>
                    <div className="modal-footer flex shrink-0 flex-wrap items-center justify-end rounded-b-md border-t border-gray-200 p-4">
                        <button
                            type="button"
                            className="border border-gray-300 inline-block rounded bg-gray-100 hover:bg-gray-200 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            Đóng
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}