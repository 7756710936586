/* eslint-disable */
import { getDateOfMonth, getDateOfWeek, getMonthOfYear, getYear } from '../../../../utils/Times';
import { useContext, useEffect, useState } from 'react';

import { FocusContext } from '../../../../context/Focus.context';
import { HighlightContext } from '../../../../context/Highlight.context';
import IMAGES_EVENT from '../../assets/_index';
import envConstants from '../../../../config/key';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import { statusConvert } from '../../../_index/helper';
import Sponsor from './Sponsor';

// const TiltlePage = ({ data = {}, guestName = "" }) => {
const TiltlePage = ({
    data = {},
    guest,
    guestFullname = '',
    salutation = '',
    guestTitle = '',
    formDataDefault,
    eventConfig,
    show,
}) => {
    const { url_share, url_guest } = useParams();
    const dataCopy = {
        invitation: data.invitation,
        titleEvent: data.titleEvent,
        nameEvent: data.nameEvent,
        logo: data.logo && data.logo[0],
        placeHold: data.placeHold,
        addressHold: data.addressHold,
        date: data.dateHold,
        time: data.timeHold,
        linkGgMap: data?.linkGgMap ?? '',
        dress_1: data?.dress_1 ? data?.dress_1 : '',
        dress_2: data?.dress_2 ? data?.dress_2 : '',
    };

    const focusContext = useContext(FocusContext);
    const [highlightContext, setHighlightContext] = useContext(HighlightContext);

    const [pathImgLogo, setPathImgLogo] = useState('');

    useEffect(() => {
        if (
            !/^blob:/.test(dataCopy.logo) &&
            !/^data:image/.test(dataCopy.logo) &&
            !/^\/static\/media/.test(dataCopy.logo) &&
            dataCopy.logo !== undefined &&
            dataCopy.logo !== '' &&
            dataCopy.logo !== null &&
            dataCopy.logo !== false
        ) {
            setPathImgLogo(envConstants.SERVER_URL + dataCopy.logo);
        } else if (dataCopy.logo) {
            setPathImgLogo(dataCopy.logo);
        } else {
            // setPathImgLogo(IMAGES_EVENT.LOGO_SUNSHINE_GARDEN)
            setPathImgLogo('');
        }
    }, [dataCopy.logo]);

    return (
        <>
            <div className="font-Comfortaa mx-auto h-fit min-h-[85vh] max-w-[1444px] bg-gradient-to-br from-gray-600 via-[#45b7d6] to-gray-900 py-10 text-white">
                <div className="mx-auto" tabIndex="0" ref={focusContext.formGeneral}>
                    {pathImgLogo && (
                        <div className="mx-auto ">
                            <img
                                className="mx-auto w-[190px]"
                                src={pathImgLogo}
                                loading="lazy"
                                alt="logo"
                            />
                        </div>
                    )}

                    <div
                        className={
                            'mt-10 text-center ' +
                            (highlightContext === 'titleEvent' && ' animate-bounce ')
                        }
                    >
                        <span className="text-4xl font-bold">{dataCopy?.titleEvent}</span>
                    </div>

                    <div className="w-2/3 mx-auto my-10 border-gray-300 border-y">
                        <div className="w-full mx-auto mt-5 mb-3">
                            <p className="text-lg font-extrabold text-center uppercase font-Comfortaa">
                                {salutation || 'Trân trọng kính mời'}
                            </p>
                        </div>
                        <div className="mx-auto mt-3 mb-5 text-lg text-center font-Comfortaa text-amber-200">
                            <p className="uppercase break-words">
                                {guestTitle ||
                                    '....................................................'}
                            </p>
                            <p className="text-3xl font-extrabold uppercase break-words">
                                {guestFullname || '............................'}
                            </p>
                        </div>
                    </div>

                    {/* loi moi */}
                    <div
                        className={
                            'text-center font-Comfortaa mt-3 ' +
                            (highlightContext === 'invitation' && ' animate-bounce ')
                        }
                    >
                        <span className="text-2xl ">{dataCopy?.invitation}</span>
                    </div>
                    <div className="flex justify-center -mt-2 ">
                        <div
                            className={
                                'text-center uppercase text-4xl font-extrabold text-amber-200 py-4 ' +
                                (highlightContext === 'nameEvent' && ' animate-bounce ')
                            }
                        >
                            {dataCopy?.nameEvent}
                        </div>
                    </div>
                    <div className="flex items-center gap-[20px] justify-center">
                        {url_guest && url_share && eventConfig?.auto_response && (
                            <a
                                href={'/' + url_share + '/' + url_guest + '/xac-nhan'}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <button className="mt-2 flex items-center rounded bg-amber-200 px-4 py-1.5 text-[calc(0.5rem_+_0.7vh)] font-semibold text-black transition hover:bg-amber-300 md:mt-10">
                                    {/* <FaEnvelopeOpenText className="mr-2" /> Xác nhận tham dự */}
                                    {statusConvert(guest?.status)}
                                </button>
                            </a>
                        )}

                        {!url_guest && url_share && eventConfig?.auto_join && (
                            <button
                                className="mt-2 flex items-center rounded bg-amber-200 px-4 py-1.5 text-[calc(0.5rem_+_0.7vh)] font-semibold text-black transition-all hover:bg-amber-300 md:mt-10"
                                data-bs-toggle="modal"
                                data-bs-target="#formModal"
                            >
                                <FaEnvelopeOpenText className="mr-2" /> Đăng kí tham dự
                            </button>
                        )}
                    </div>

                    <div className="mx-auto mt-[5vh] w-full md:w-3/4">
                        <div className="grid grid-flow-col grid-rows-5 gap-4">
                            <div className="flex items-end justify-end col-span-2 row-span-2 pr-4 text-2xl font-bold border-r border-gray-30">
                                THỜI GIAN
                            </div>
                            <div className="flex flex-col items-end col-span-2 row-span-3 pr-4 border-r border-gray-30">
                                <span
                                    className={
                                        'text-4xl font-extrabold ' +
                                        (highlightContext === 'timeHold' && ' animate-bounce ')
                                    }
                                >
                                    {' '}
                                    {dataCopy?.time}
                                </span>
                                <span
                                    className={
                                        'text-lg ' +
                                        (highlightContext === 'dateHold' && ' animate-bounce ')
                                    }
                                >
                                    {dataCopy.date &&
                                        getDateOfMonth(dataCopy.date) +
                                            '/' +
                                            getMonthOfYear(dataCopy.date) +
                                            '/' +
                                            getYear(dataCopy.date)}
                                </span>
                            </div>
                            <div className="flex items-end col-span-3 row-span-2 text-2xl font-bold">
                                ĐỊA ĐIỂM
                            </div>
                            <div className="flex flex-col col-span-3 row-span-3">
                                <span
                                    className={
                                        'text-lg ' +
                                        (highlightContext === 'placeHold' && ' animate-bounce ')
                                    }
                                >
                                    {' '}
                                    {dataCopy?.placeHold}
                                </span>
                                <span
                                    className={
                                        'text-lg ' +
                                        (highlightContext === 'addressHold' && ' animate-bounce ')
                                    }
                                >
                                    {dataCopy?.addressHold}
                                    {(dataCopy?.addressHold || dataCopy?.linkGgMap) && (
                                        <a
                                            href={`${
                                                dataCopy?.linkGgMap
                                                    ? dataCopy?.linkGgMap
                                                    : `${
                                                          'https://www.google.com/maps/search/?api=1&query=' +
                                                          dataCopy?.addressHold
                                                      }`
                                            }`}
                                            rel="noreferrer"
                                            target="_blank"
                                            className={
                                                'ml-2 mr-1 border-white text-[17px] text-white hover:border-b '
                                            }
                                            title="Xem trên bản đồ"
                                        >
                                            <i className="fas fa-map-marker-alt"></i>
                                        </a>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>

                    {dataCopy?.dress_1 || dataCopy?.dress_2 ? (
                        <div className="w-full text-center mt-10">
                            <p className="text-xl font-extrabold text-white">TRANG PHỤC</p>
                            {dataCopy?.dress_1 ? (
                                <p className="w-full text-lg font-Comfortaa text-center font-medium mt-2  text-white">
                                    {dataCopy?.dress_1}
                                </p>
                            ) : null}
                            {dataCopy?.dress_2 ? (
                                <p className="w-full text-lg font-Comfortaa text-center font-medium mt-2  text-white">
                                    {dataCopy?.dress_2}
                                </p>
                            ) : null}
                        </div>
                    ) : null}

                    {(data?.phoneSupporter || data?.nameSupporter) && (
                        <div className="z-50 mx-auto mt-5 text-center text-[16px]">
                            <p className="text-xl font-extrabold text-white">LIÊN HỆ HỖ TRỢ</p>
                            <div className="flex justify-center">
                                <p
                                    className={
                                        'text-right font-bold mt-4  ' +
                                        (highlightContext === 'nameSupporter' && ' animate-bounce ')
                                    }
                                >
                                    <i className="mr-1 text-xl fas fa-user-alt text-amber-200 "></i>{' '}
                                    {data?.nameSupporter && data?.nameSupporter}
                                </p>

                                <a href={`tel:${data?.phoneSupporter}`} className="">
                                    <p
                                        className={
                                            'text-left font-bold mt-4  text-white hover:text-slate-400 ' +
                                            (highlightContext === 'phoneSupporter' &&
                                                ' animate-bounce ')
                                        }
                                    >
                                        <i className="fas fa-phone-alt ml-5 mr-1.5 text-xl text-amber-200"></i>
                                        {data?.phoneSupporter && data?.phoneSupporter}
                                    </p>
                                </a>
                            </div>
                        </div>
                    )}
                    {show && data?.sponsors && data?.sponsors?.length > 0 ? (
                        <Sponsor data={data} />
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default TiltlePage;
