/* eslint-disable */
import { useEffect, useState } from 'react';
import guestManagerService from '../guestManager.service';
import ToastNotify from '../../../../../utils/Toast';

export function ExportQRModalSelect({ totalGuest, eventId, guestSelect }) {
    const [exportSegments, setExportSegments] = useState([]);
    const guestPerSegement = 200;
    const [exporting, setExporting] = useState(false);

    useEffect(() => {
        const exportSegementCount = Math.ceil(totalGuest / guestPerSegement);
        let exportSegementsData = [];
        for (let i = 0; i < exportSegementCount; i += 1) {
            const segment = {
                start: guestPerSegement * i + 1,
                end:
                    guestPerSegement * (i + 1) > totalGuest
                        ? totalGuest
                        : guestPerSegement * (i + 1),
            };
            exportSegementsData.push(segment);
        }
        setExportSegments(exportSegementsData);
    }, [totalGuest]);

    const handleExportQR = async (start) => {
        if (exporting) {
            return;
        }
        setExporting(true);
        const id = ToastNotify.Loading('Đang xuất file...');
        try {
            const data = await guestManagerService.exportGuestQR(
                eventId,
                Math.ceil(start / guestPerSegement),
                guestPerSegement,
                guestSelect.map((item) => item._id)
            );
            console.log('file data: ', data);
            const link = process.env.REACT_APP_SERVER_URL + data.path;
            ToastNotify.Success(
                'Xuất file thành công. File của bạn đang được tải xuống. Vui lòng không đóng trang khi chưa tải xong.'
            );
            downloadFileFormUrl(link);
        } catch (err) {
            ToastNotify.Error('Xuất file thất bại. Vui lòng thử lại.');
            console.log('error export QR: ', err);
        }
        ToastNotify.DismissLoading(id);
        setExporting(false);
    };

    const downloadFileFormUrl = (url) => {
        const a = document.createElement('a');
        a.href = url;
        a.download = url.split('/').pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="exportQRModalSelect"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative pointer-events-none modal-dialog modal-dialog-centered md:w-[700px]">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-[16px] font-bold leading-normal text-gray-800"
                            id="exampleModalScrollableLabel"
                        >
                            Xuất File QR thiệp mời
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-base font-medium leading-normal text-center text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Bạn muốn xuất mã QR {guestSelect?.length} khách mời đã chọn?
                        </h5>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-dismiss="modal"
                        >
                            Trở lại
                        </button>
                        {exportSegments.length > 1 ? (
                            exportSegments.map((el) => (
                                <button
                                    type="button"
                                    onClick={() => handleExportQR(el.start)}
                                    className="w-[320px] mb-4 inline-block px-6 py-2.5 bg-blue-700 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-800 hover:shadow-lg focus:bg-blue-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                                >
                                    {`Xuất file từ khách mời thứ ${el.start} đến ${el.end}`}
                                </button>
                            ))
                        ) : (
                            <button
                                type="button"
                                onClick={() => handleExportQR(1)}
                                className="w-fit inline-block px-6 py-2.5 bg-blue-700 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-800 hover:shadow-lg focus:bg-blue-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                            >
                                Xuất file
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
