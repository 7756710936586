/* eslint-disable */

import { useEffect, useState } from 'react';
import ToastNotify from '../../../../../utils/Toast';

const listCondition = [
    { k: 'vip', v: 'none' },
    { k: 'gender', v: 'none' },
    { k: 'group', v: 'false' },
    { k: 'guest_join', v: 'false' },
    { k: 'guest_quality', v: '6' },
    { k: 'guest_excess', v: 'new_table' },
];

const defaultCondition = {
    vip: 'none',
    guest_quality: 6,
    guest_excess: 'new_table',
    group: 'false',
    guest_join: 'false',
    gender: 'none',
};

export default function CoditionTableModal({ handleSortTable, evTableCondition }) {
    const [oldCondition, setOldCondition] = useState({});
    const [evCondition, setEvCondition] = useState(defaultCondition);

    useEffect(() => {
        if (evTableCondition?.length > 0) {
            const condition = {};
            evTableCondition.forEach((item) => {
                condition[item.k] = item.v;
            });
            // console.log('condition', condition);
            setEvCondition(condition);
            setOldCondition(condition);
        }
    }, [evTableCondition]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log('name', name, 'value', value);
        setEvCondition((prev) => ({ ...prev, [name]: value }));
    };
    const handleConvertCondition = () => {
        const result = Object.entries(evCondition).map(([key, value]) => ({
            k: key,
            v: value.toString(),
        }));
        return result;
    };
    const handleAutoSortTable = () => {
        if (evCondition.guest_quality < 1) {
            ToastNotify.Error('Vui lòng nhập số khách trên 1 bàn lớn hơn 0');
            return;
        }
        handleSortTable(handleConvertCondition());
    };

    const handleClear = () => {
        setEvCondition(oldCondition);
    };
    return (
        <>
            <ConfirmAutoSortTable handleAutoSortTable={handleAutoSortTable} />
            <div
                className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
                id="coditionTableModal"
                tabIndex="-1"
                aria-modal="true"
                role="dialog"
            >
                <div className="relative pointer-events-none modal-dialog modal-dialog-centered md:w-[700px]">
                    <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                        <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                            <h5
                                className="flex items-center text-[16px] font-bold leading-normal text-gray-800"
                                id="exampleModalScrollableLabel"
                            >
                                Tự động sắp xếp bàn tiệc
                            </h5>
                            <button
                                type="button"
                                className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClear}
                            ></button>
                        </div>
                        <div className="modal-body flex max-h-[80vh] shrink-0 flex-col overflow-auto rounded-t-md border-b border-gray-200 py-[1rem] justify-center gap-2 ">
                            <div className="flex gap-2 items-start w-full px-[1rem] flex-col">
                                <p className="text-sm font-bold text-black">
                                    Số khách trên 1 bàn <span className="text-red-500">(*)</span>
                                </p>
                                <input
                                    className="w-full rounded py-2 text-sm border-none outline-none ring-0 bg-blue-100 text-black"
                                    name="guest_quality"
                                    value={evCondition.guest_quality}
                                    onChange={handleChange}
                                ></input>
                            </div>
                            <div className="w-full h-[1px] bg-gray-300 my-2"></div>
                            <div className="flex gap-2 items-start w-full px-[1rem] flex-col ">
                                <p className="text-sm font-bold text-black">Chọn độ ưu tiên</p>
                                <select
                                    name="vip"
                                    className="select text-sm rounded py-2 w-full border-none outline-none ring-0 bg-blue-100 text-black"
                                    onChange={handleChange}
                                >
                                    <option
                                        value={'none'}
                                        className="bg-white"
                                        selected={evCondition.vip === 'none'}
                                    >
                                        Mặc định
                                    </option>
                                    <option
                                        className="bg-white"
                                        value={'high'}
                                        selected={evCondition.vip === 'high'}
                                    >
                                        Từ cao đến thấp
                                    </option>
                                    <option
                                        className="bg-white"
                                        value={'low'}
                                        selected={evCondition.vip === 'low'}
                                    >
                                        Từ thấp lên cao
                                    </option>
                                </select>
                            </div>
                            <div className="w-full h-[1px] bg-gray-300 my-2"></div>
                            <div className="flex gap-2 items-start w-full px-[1rem] flex-col ">
                                <p className="text-sm font-bold text-black">
                                    Sắp xếp chỗ ngồi theo giới tính
                                </p>
                                <select
                                    name="gender"
                                    onChange={handleChange}
                                    className="select text-sm rounded py-2 w-full border-none outline-none ring-0 bg-blue-100 text-black"
                                >
                                    <option
                                        value={'none'}
                                        className="bg-white"
                                        selected={evCondition.gender === 'none'}
                                    >
                                        Mặc định
                                    </option>
                                    <option
                                        className="bg-white"
                                        value={'group'}
                                        selected={evCondition.gender === 'group'}
                                    >
                                        Nam nữ xếp riêng
                                    </option>
                                    <option
                                        className="bg-white"
                                        value={'sole'}
                                        selected={evCondition.gender === 'sole'}
                                    >
                                        Nam nữ cạnh nhau
                                    </option>
                                </select>
                            </div>
                            <div className="w-full h-[1px] bg-gray-300 my-2"></div>

                            <div className="flex gap-2 items-start w-full px-[1rem] flex-col ">
                                <p className="text-sm font-bold text-black">Nhóm khách mời</p>
                                <select
                                    name="group"
                                    onChange={handleChange}
                                    className="select text-sm rounded py-2 w-full border-none outline-none ring-0 bg-blue-100 text-black"
                                >
                                    <option
                                        className="bg-white"
                                        value={'false'}
                                        selected={evCondition.group === 'false'}
                                    >
                                        Mặc định
                                    </option>
                                    <option
                                        className="bg-white"
                                        value={'true'}
                                        selected={evCondition.group === 'true'}
                                    >
                                        Khách cùng nhóm xếp cạnh nhau
                                    </option>
                                </select>
                            </div>
                            <div className="w-full h-[1px] bg-gray-300 my-2"></div>
                            <div className="flex gap-2 items-start w-full px-[1rem] flex-col ">
                                <p className="text-sm font-bold text-black">Khách đi cùng</p>
                                <select
                                    name="guest_join"
                                    onChange={handleChange}
                                    className="select text-sm rounded py-2 w-full border-none outline-none ring-0 bg-blue-100 text-black"
                                >
                                    <option
                                        className="bg-white"
                                        value={'false'}
                                        selected={evCondition.guest_join === 'false'}
                                    >
                                        Mặc định
                                    </option>
                                    <option
                                        className="bg-white"
                                        value={'true'}
                                        selected={evCondition.guest_join === 'true'}
                                    >
                                        Nhóm khách đi cùng nhau
                                    </option>
                                </select>
                            </div>
                            <div className="w-full h-[1px] bg-gray-300 my-2"></div>
                            <div className="flex gap-2 items-start w-full px-[1rem] flex-col ">
                                <p className="text-sm font-bold text-black">Khách dư khi xếp bàn</p>
                                <select
                                    name="guest_excess"
                                    onChange={handleChange}
                                    className="select text-sm rounded py-2 w-full border-none outline-none ring-0 bg-blue-100 text-black"
                                >
                                    <option
                                        className="bg-white"
                                        value={'new_table'}
                                        selected={evCondition.guest_excess === 'new_table'}
                                    >
                                        Xếp ra bàn khác
                                    </option>
                                    <option
                                        className="bg-white"
                                        value={'old_table'}
                                        selected={evCondition.guest_excess === 'old_table'}
                                    >
                                        Xếp vào bàn đã có
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                            <button
                                type="button"
                                className="inline-block px-6 py-2.5  text-gray-800 border-[1px] border-gray-500 font-medium text-xs leading-tight uppercase rounded shadow-md hover: hover:shadow-lg focus: focus:shadow-lg focus:outline-none focus:ring-0 active: active:shadow-lg transition duration-150 ease-in-out"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClear}
                            >
                                Trở lại
                            </button>
                            <button
                                type="button"
                                data-bs-toggle={'modal'}
                                data-bs-target={'#confirmAutoSortTable'}
                                className="inline-block px-6 py-2.5 bg-blue-700 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-800 hover:shadow-lg focus:bg-blue-800 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                            >
                                Sắp xếp
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const ConfirmAutoSortTable = ({ handleAutoSortTable }) => {
    return (
        <div
            className="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none fade modal"
            id="confirmAutoSortTable"
            tabIndex="-1"
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
        >
            <div className="relative w-auto pointer-events-none modal-dialog modal-dialog-centered">
                <div className="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="flex items-center text-base font-bold leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Xác nhận tự động sắp xếp bàn tiệc
                        </h5>
                        <button
                            type="button"
                            className="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close hover:text-black hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            data-bs-toggle={'modal'}
                            data-bs-target={'#coditionTableModal'}
                        ></button>
                    </div>
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 modal-header shrink-0 rounded-t-md">
                        <h5
                            className="text-base font-medium leading-normal text-black"
                            id="exampleModalScrollableLabel"
                        >
                            Thao tác này sẽ thay đổi toàn bộ dữ liệu bàn tiệc và không thể hoàn tác,
                            bạn có chắc chắn muốn tiếp tục?
                        </h5>
                    </div>
                    <div className="flex flex-wrap items-center justify-end p-4 border-t border-gray-200 modal-footer shrink-0 rounded-b-md">
                        <button
                            type="button"
                            className="hover: focus: active:  inline-block rounded bg-gray-300 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                            data-bs-toggle={'modal'}
                            data-bs-target={'#coditionTableModal'}
                        >
                            Trở lại
                        </button>
                        <button
                            type="button"
                            onClick={handleAutoSortTable}
                            data-bs-dismiss="modal"
                            className="ml-1 flex items-center rounded bg-blue-500 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                        >
                            Tiếp tục
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
