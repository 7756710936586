/* eslint-disable */

import { useEffect, useState } from 'react';
import img1 from './imgs/sp-01.png';
import img2 from './imgs/sp-02.png';
import img3 from './imgs/sp-03.png';
import img4 from './imgs/sp-04.png';
import Slider from 'react-slick';
import './style.css';

const products = [
    { img: img1, url: 'https://giapha.akb.com.vn/' },
    { img: img2, url: 'https://nhansodaiviet.com/' },
    { img: img3, url: 'https://chiabuon.vn/' },
    { img: img4, url: 'https://giaphaso.com/' },
];

const settings = {
    dots: true,
    customPaging: function () {
        return (
            <span className="block w-4 h-4 mx-2 transition-all rounded-full cursor-pointer"></span>
        );
    },
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    cssEase: 'linear',
};

export default function Products() {
    const imgs = [img1, img2, img3, img4];
    
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div
            className={`absolute lg:flex hidden left-1/2 -translate-x-1/2 min-h-[85vh] bg-white  shadow-md rounded-md pt-4 px-2`}
            style={{ top: `${scrollPosition}px` }}
        >
            <Slider
                {...settings}
                arrows={false}
                className="flex relative w-[200px] h-[520px] bg-top z-10 lg:px-0 px-[40px] overflow-hidden gap-[20px] hover:scale-105  transition-all"
            >
                {products.map((item, index) => (
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                        <img
                            alt="albumv2"
                            key={index}
                            src={item.img}
                            className="w-full object-cover rounded-md cursor-pointer   "
                        />
                    </a>
                ))}
            </Slider>
        </div>
    );
}
