/* eslint-disable */
import { FaTimes, FaSave } from 'react-icons/fa';
export default function ModalLeavingPage({
    isOpen,
    funToggle,
    cancelNavigation,
    confirmNavigation,
    saveChanged,
}) {
    // const toggle = () => {
    //     funToggle()
    // }

    // Lưu nháp sau đó thoát
    const confirmSave = () => {
        saveChanged();
        confirmNavigation();
    };

    return (
        <>
            {isOpen ? (
                <>
                    <div className="fixed inset-0 z-[44] m-auto flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
                        <div className="relative mx-auto my-6 w-auto ">
                            {/*content*/}
                            <div className="relative flex w-fit max-w-[600px] flex-col rounded-lg  border-0 bg-white shadow-lg  outline-none focus:outline-none md:min-w-[550px]">
                                {/*header*/}

                                <div className="flex items-start justify-between rounded-t-lg border-b border-solid border-gray-300 p-4">
                                    <h3 className="text-[16px] font-bold text-blue-500">
                                        Xác nhận
                                    </h3>
                                    <button
                                        title="Đóng"
                                        className="float-right ml-auto border-0 bg-transparent  p-1 text-xl font-semibold leading-none outline-none focus:outline-none"
                                        onClick={funToggle}
                                    >
                                        <i className="fas fa-times text-gray-600"></i>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto p-4 ">
                                    {/* <h6 className="text-blueGray-700 text-lg ">
                                        Dữ liệu nhập sẽ bị mất. 
                                    </h6> */}
                                    <h6 className="my-2 text-[16px] text-blueGray-700">
                                        Bạn có chắc muốn lưu dữ liệu?
                                    </h6>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end rounded-b border-t border-solid border-blueGray-200 p-5">
                                    <button
                                        className="hover:bg-gray-200 transition-all focus:bg-gray-200 active:bg-gray-200  flex items-center gap-2 rounded bg-gray-200 border-[1px] border-gray-300 px-5 py-2.5 text-xs font-medium uppercase leading-tight text-gray-800 duration-150 ease-in-out"
                                        type="button"
                                        onClick={confirmNavigation}
                                    >
                                        <FaTimes />
                                        Không
                                    </button>
                                    <button
                                        className="ml-1 flex items-center gap-2 rounded bg-blue-600 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
                                        type="button"
                                        onClick={confirmSave}
                                    >
                                        <FaSave />
                                        Lưu
                                    </button>
                                    {/* <button
                                        className="text-gray-400 hover:bg-gray-200 background-transparent font-bold uppercase px-6 py-3 rounded text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={cancelNavigation}
                                    >
                                        Hủy
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 z-40 bg-black opacity-40"></div>
                </>
            ) : null}
        </>
    );
}
