/* eslint-disable */
import React from 'react';
import envConstants from '../../../config/key';

const SloganLanding = () => (
    <div className="flex flex-col p-4 bg-blueGray-200 h-full justify-center  min-w-[310px] slogan-landing">
        <div className='w-full'>
            <img
                src={envConstants.CLIENT_URL + 'logo_chungvui.png'}
                className="w-[9rem] mx-auto h-auto "
            />
            {/* <svg
                    className="w-10 h-10 mx-auto text-gray-800 fill-stroke sm:h-20 sm:w-20 md:float-left "
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                    ></path>
                </svg> */}
        </div>
        <h1 className="md:text-2xl text-xl font-bold text-center text-main-500 sm:mb-3 mt-2 sm:mt-10">
            Nền tảng thiệp mời số
            {/* <span className='ml-1 pr-0.5 bg-gradient-to-r from-blue-600 via-red-500 to-indigo-400 inline-block text-transparent bg-clip-text'>
                miễn phí
            </span> */}
        </h1>
        <p className="text-[16px] w-full mx-auto text-black font-medium md:mx-0 text-center">Đẹp - Tiện lợi - Trải nghiệm miễn phí</p>
        {/* <p className="w-full mx-auto text-black md:mx-0 text-center">Hãy tạo thiệp theo cách của bạn</p> */}
    </div>
);

export default SloganLanding;
