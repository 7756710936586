/* eslint-disable */
import IMAGES from '../../../../../assets/images/_index';

const Blank = ({ handleClear }) => {
    return (
        <div className="mt-8 flex flex-col justify-center items-center w-full">
            <img src={IMAGES.NOT_FOUND_EVENT} alt="not found" className="w-[200px] opacity-70" />
            <h4 className="mt-5 text-xl md:text-xl text-center text-blue-900">
                Rất tiếc, chúng tôi không tìm thấy kết quả phù hợp.
            </h4>
            <p className="mt-2 text-sm text-center text-black">
                Vui lòng thử lại với từ khóa khác hoặc{' '}
                <span onClick={handleClear} className="font-bold underline cursor-pointer">
                    bỏ từ khóa tìm kiếm
                </span>{' '}
                để xem tất cả
            </p>
        </div>
    );
};

export default Blank;
