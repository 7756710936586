/* eslint-disable */
import { QRCodeSVG } from 'qrcode.react';
import React from 'react';
import TiltlePage from './components/TitlePage';

export default function SubEvent12({
    data,
    guestTitle,
    guestFullname,
    salutation,
    refFrame,
    urlShare,
}) {
    return (
        <>
            <div
                tabIndex="0"
                ref={refFrame}
                className="relative flex h-auto flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none "
            >
                <div className="border-1 relative h-auto w-auto">
                    <TiltlePage
                        data={data}
                        guestFullname={guestFullname}
                        guestTitle={guestTitle}
                        salutation={salutation}
                        show={false}
                    />
                </div>
                {/** QR code */}
                {urlShare && (
                    <div className="absolute left-4 top-4 lg:w-[15%] w-[80px] border-2 border-white rounded-md bg-white p-1.5 pb-0.5">
                        {
                            <div className="-mx-1.5 -mt-1.5 rounded-t-md bg-black p-3">
                                <QRCodeSVG
                                    value={urlShare}
                                    bgColor={'transparent'}
                                    fgColor={'rgb(254 240 138)'}
                                    className="h-1/2 w-full"
                                />
                            </div>
                        }
                        <div className="mt-1.5 flex">
                            <div className="w-full text-center text-black">
                                <i className="fas fa-camera leading-none text-xl"></i>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
