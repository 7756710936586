/* eslint-disable */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import PAGE from '../config/path.route';
import { useDispatch, useSelector } from 'react-redux';
import storageConstant from '../config/constant/storage.constant';

const RequireAuthUserRoute = ({ children }) => {
    const location = useLocation();
    const [, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isAuthUser, setIsAuthUser] = useState(false);
	const auth = useSelector(state => state.auth);

    useEffect(() => {
		if (!auth?.loggedIn) {
			navigate(PAGE.dashboard);
		}
		else {
			setIsAuthUser(true);
		}
    }, [dispatch, location, setSearchParams, navigate, auth]);
    return isAuthUser && children;
};

export default RequireAuthUserRoute;
