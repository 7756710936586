/* eslint-disable */
import FooterDefault from './components/FooterDefault'
import ModalManagement from '../../ModalManagement/ModalManagement'
import NavbarDefault from './components/NavbarDefault'
import React, { useEffect } from 'react'
import ScrollToTop from '../../ScrollToTop'
import { GoogleLogin } from '@react-oauth/google';
import authActions from '../../../redux/actions/auth.action';
import { useDispatch } from 'react-redux';
import ToastNotify from '../../../utils/Toast'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PAGE from '../../../config/path.route'
import envConstants from '../../../config/key'
import GuestEventNotify from '../../../views/dashboard/components/GuestEventNotify'

// const removeRecaptcha = () => {
// 	const script = document.getElementById('recaptcha-key');
// 	if (script) {
// 		script.remove();
// 	}

// 	const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
// 	if (recaptchaElems.length) {
// 		recaptchaElems[0].remove();
// 	}
// };

const LayoutDefault = ({ children }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const auth = useSelector(state => state.auth);
	useEffect(() => {
		if (!auth?.loggedIn) {
			const interval = setInterval(() => {
				const googleLoginButton = document.querySelector('.S9gUrf-YoZ4jf');
				if (googleLoginButton) {
					googleLoginButton.parentElement.style.display = 'none';
					clearInterval(interval);
				}
			}, 100);
		}
	}, [auth])

	const handleGoogleLogin = async (loginResponse) => {
		try {
			// window.grecaptcha.ready(function () {
			// 	window.grecaptcha.execute(envConstants.SITE_KEY_CAPTCHA, { action: 'submit' })
			// 		.then(function (token) {
			dispatch(authActions.authProviderLogin('google', {
				dataToken: loginResponse.credential,
				tokenCaptcha: ''
			}))
				.then(() => {
					ToastNotify.Success('Đăng nhập thành công.');
					if (
						!window.location.pathname?.includes('mainmenu') &&
						!window.location.pathname?.includes('goi-y') &&
						!window.location.pathname?.includes('config')
					) {
						navigate(PAGE.userEvents);
					}
					// removeRecaptcha();
				})
				.catch((error) => {
					ToastNotify.Error(error?.message || 'Lỗi đăng nhập Google');
				})
			// 		})
			// })
		}
		catch (err) {
			console.log('google login error: ', err);
		}
	};
	return (
		<div className="app__warpper">
			{
				!auth?.loggedIn && (
					<div>
						<GoogleLogin
							onSuccess={handleGoogleLogin}
							onError={() => {
								console.log('Google Login Failed');
							}}
							useOneTap={true}
						/>
					</div>)

			}
			<NavbarDefault />
			<ScrollToTop />
			<div className='h-[100vh]'>
				<div className="max-w-8xl h-full">
					{children}
					<ModalManagement />
					<FooterDefault />
				</div>
				<GuestEventNotify loggedIn={auth?.loggedIn} />
			</div>
		</div>
	)
}

export default LayoutDefault
