/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { getDateOfMonth, getDateOfWeek, getMonthOfYear, getYear } from '../../../utils/Times';

import IMAGES_EVENT from '../assets/_index';
import { QRCodeSVG } from 'qrcode.react';
import envConstants from '../../../config/key';

export default function SubEvent1({
    data,
    guestTitle,
    guestFullname,
    salutation,
    refFrame,
    urlShare,
}) {
    const [pathImgLogo, setPathImgLogo] = useState('');
    const checkIsImageValid = useCallback(
        (str) =>
            !/^blob:/.test(str) && !/^data:image/.test(str) && !/^\/static\/media/.test(str) && str,
        []
    );

    useEffect(() => {
        if (data?.logo.length !== 0) {
            setPathImgLogo(envConstants.SERVER_URL + data?.logo?.[0]);
        }
    }, []);

    console.log(urlShare);

    return (
        <>
            <div
                tabIndex="0"
                ref={refFrame}
                className="relative flex h-auto flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none "
            >
                <div className="relative h-auto w-auto">
                    <div className="mx-auto border-8 border-[#EDB83D] bg-[url('../public/bg.png')] bg-repeat-y">
                        {/* logo */}
                        <div className="mx-auto mt-2">
                            {pathImgLogo !== '' && (
                                <img
                                    className="mx-auto w-[200px]"
                                    src={pathImgLogo}
                                    loading="lazy"
                                    alt="logo"
                                />
                            )}
                        </div>
                        {/* title */}
                        <div className="mx-auto mt-5 w-3/4 text-center text-[#D70026]">
                            <span className="text-4xl font-extrabold">{data.titleEvent}</span>
                        </div>

                        <div className=" mx-auto mb-10 mt-5 w-2/3 border-y border-gray-300 ">
                            <div className="mx-auto mb-3 mt-5 w-full">
                                <p className="font-Comfortaa text-center text-lg font-extrabold uppercase">
                                    {salutation}
                                </p>
                            </div>
                            <div className="font-Comfortaa mx-auto mb-5 mt-3 text-center text-lg text-[#EDB83D]">
                                <p className="break-words uppercase">
                                    {guestTitle ||
                                        '....................................................'}
                                </p>
                                <p className="break-words text-3xl font-extrabold uppercase">
                                    {guestFullname || '............................'}
                                </p>
                            </div>
                        </div>

                        {/* loi moi */}
                        <div className="mx-auto mt-8 w-[150px]">
                            <img src={IMAGES_EVENT.ICON_EVENT_TOP} alt=".." loading="lazy" />
                        </div>

                        <div className=" mx-auto flex w-3/4 justify-center">
                            <div className="mx-auto flex-row text-center">
                                <p className="text-xl text-[#EDB83D]">{data.invitation}</p>
                                <p className="mt-4 text-4xl font-extrabold uppercase text-[#D70026]">
                                    {data.nameEvent}
                                </p>
                            </div>
                        </div>

                        <div className="mx-auto w-[150px]">
                            <img src={IMAGES_EVENT.ICON_EVENT_BOTTOM} alt=".." loading="lazy" />
                        </div>

                        {/* Thoi gian */}
                        <div className="mx-auto mt-4 w-2/3 flex-row justify-center">
                            <div className=" relative flex items-center text-xl ">
                                <div className="mt-3 grow border-b border-gray-400"></div>
                                <span className="mx-1 shrink text-[#EDB83D]">Thời gian</span>
                                <div className="mt-3 grow border-b border-gray-400"></div>
                            </div>

                            <div className="mt-1 text-center">
                                <span className="mt-3 text-lg font-bold text-[#000B29] ">
                                    {getDateOfWeek(data?.date)}

                                    {data.date && ' , ngày ' + getDateOfMonth(data.date)}

                                    {data.date && ' tháng ' + getMonthOfYear(data.date)}

                                    {data.date && ' năm ' + getYear(data.date)}
                                </span>
                            </div>
                            {/* Dia diem */}

                            <div className="relative mt-5 flex items-center text-xl">
                                <div className="mt-3 grow border-b border-gray-400"></div>
                                <span className="mx-1 shrink text-[#EDB83D]">Địa điểm</span>
                                <div className="mt-3 grow border-b border-gray-400"></div>
                            </div>

                            <div className="mx-auto mt-3 flex-row text-center text-lg font-bold text-[#000B29]">
                                <p className=" ">{data.placeEvent && data.placeEvent}</p>
                                <p className=" ">{data.addressEvent && data.addressEvent}</p>
                            </div>

                            <div className="mt-5  grow border-t border-gray-400"></div>
                        </div>

                        {/* Thay mat ban lien lac*/}
                        <div className="mx-auto mt-10 text-center">
                            <p className="text-xl font-extrabold text-[#D70026]">LIÊN HỆ HỖ TRỢ</p>
                            {/* <p className="text-center font-bold mt-4">
                                <i className="fas fa-user-alt text-xl text-[#ffa500] mr-1 "></i>{' '}
                                {data.nameSupporter}
                                <i className="fas fa-phone-alt text-xl text-[#ffa500] mr-1 ml-5"></i>
                                {data.phoneSupporter}
                            </p> */}
                            <div className="flex">
                                <p className={'mt-4 w-1/2 text-right font-bold text-slate-600  '}>
                                    <i className="fas fa-user-alt mr-1 text-2xl text-[#ffa500] "></i>{' '}
                                    {data.nameSupporter ? data.nameSupporter : data?.nameSupporter}
                                </p>
                                <a href={`tel:${data?.phoneSupporter}`} className="w-1/2">
                                    <p
                                        className={
                                            'mt-4 text-left font-bold  text-slate-600 hover:text-black '
                                        }
                                    >
                                        <i className="fas fa-phone-alt ml-5 mr-1.5 text-2xl text-[#ffa500]"></i>
                                        {data.phoneSupporter
                                            ? data.phoneSupporter
                                            : data?.phoneSupporter}
                                    </p>
                                </a>
                            </div>
                        </div>

                        {/* Thay mat ban lien lac*/}
                        <div className="mt-8 text-center italic">
                            <p className="">{data.greeting && data.greeting}</p>
                        </div>

                        {/* Nhac nho*/}
                        {data?.remind && (
                            <div className="mb-5 mt-2 text-center text-sm italic text-gray-400">
                                <p className="">({data?.remind})</p>
                            </div>
                        )}
                    </div>
                </div>
                {/** QR code */}
                {urlShare && (
                    <div className="absolute right-10 top-10 lg:w-[15%] w-[80px] rounded-md bg-gray-700 p-1.5">
                        <div className="rounded-md bg-white p-3">
                            <QRCodeSVG
                                value={urlShare}
                                bgColor={'#ffffff'}
                                fgColor={'#EDB83D'}
                                className="h-1/2 w-full"
                            />
                        </div>

                        <div className="mt-2 flex">
                            <div className="w-full text-center text-white">
                                <i className="fas fa-camera mr-1 text-lg"></i>{' '}
                                <span className="text-sm">Xem chi tiết</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
